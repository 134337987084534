import React, { useState, useEffect, useMemo } from 'react'
import DealTradeStructure from './subpages/DealTradeStructure/DealTradeStructure'
import { Form } from 'react-final-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  useGetTaskDealQuery,
  useLazyAddDealTradeQuery,
  useLazyEditDealTradeQuery,
  useLazyGetCarQuery,
  useGetContactMutation,
  useLazyGetDealTradeDataQuery,
} from '../../../../../services'
import { UspDealTradeAddEdit } from '../../../../../models'
import { transformToRequestValues, transformToFormValues } from './subpages/DealTradeStructure/transform'
import validateFormValues from '../../../../../utils/validateForm'
import { dealTradeAddEditValidation } from '../../../../../validation/dealTradeAddEditValidation'
import { useDispatch } from 'react-redux'
import { appRoute } from '../../../../../constants'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { Box, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { CustomCircular } from '../../../../../ui'
import { applyPhoneMask } from '../../../../../utils/general'

const DealsTradeAddEdit = () => {
  const { dealID, tradeID } = useParams()
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [initFormValues, setInitFormValues] = useState<any>({})
  const [addDealTrade, { isLoading: isLoadingAdd, isSuccess: dealTradeDataIsSuccess }] = useLazyAddDealTradeQuery()
  const [getDealTrade, { data, isSuccess }] = useLazyGetDealTradeDataQuery()
  const [getCar, { data: getCarData, isSuccess: getCarDataIsSuccess }] = useLazyGetCarQuery()
  const [getContacts, { data: getContactsData, isSuccess: getContactsIsSuccess }] = useGetContactMutation()
  const [editDealTrade, { isLoading: isLoadingEdit, isSuccess: isSuccessDealTrade }] = useLazyEditDealTradeQuery()
  const { data: discrepancy } = useGetTaskDealQuery({ dealId: dealID as string })

  useEffect(() => {
    if (tradeID) {
      getDealTrade({ dealId: tradeID as string })
    }
  }, [tradeID, getDealTrade])

  useEffect(() => {
    if (data) {
      getCar({ carId: data?.CarID as any })
      getContacts({ contactId: data?.LienholderContactID as any })
    }
  }, [data, getCar, getContacts])

  useEffect(() => {
    setInitFormValues(transformToFormValues(data, getCarData, getContactsData, params, discrepancy))
  }, [isSuccess, getCarDataIsSuccess, getContactsIsSuccess, data, getCarData, getContactsData, params, discrepancy, tradeID])

  useEffect(() => {
    if (dealTradeDataIsSuccess || isSuccessDealTrade) {
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(
        generatePath(appRoute.DealsTask, {
          dealID,
        }),
      )
    }
  }, [dealTradeDataIsSuccess, isSuccessDealTrade, data])

  const handleSubmit = (values: any) => {
    if (tradeID) {
      editDealTrade({
        dealId: dealID as string,
        tradeId: tradeID as string,
        uspDealTradeAddEdit: transformToRequestValues(values as UspDealTradeAddEdit, data, getContactsData),
      })
    } else {
      addDealTrade({ uspDealTradeAddEdit: transformToRequestValues(values as UspDealTradeAddEdit), dealId: dealID as string })
    }
  }
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={10} spacing={2}>
          <Form
            onSubmit={handleSubmit}
            initialValues={initFormValues}
            validate={validateFormValues(dealTradeAddEditValidation(discrepancy, data, tradeID))}
          >
            {({ handleSubmit }: any) => (
              <form>
                <Box display={'flex'}>
                  <CustomBreadcrumbs
                    heading='Deal'
                    links={[
                      { name: 'List', href: '/deals' },
                      {
                        name: `${discrepancy?.CarDescription} | Cell: ${applyPhoneMask(discrepancy?.PhoneNumber)} | ${
                          discrepancy?.Buyer
                        } | ${`VIN ${discrepancy?.VIN}`} | ${`Stock # ${discrepancy?.StockNumber}`}`,
                      },
                    ]}
                    sx={{
                      mb: { xs: 3, md: 5 },
                    }}
                  />
                  {/* <Label color={creditAppData?.LeadStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
                  {creditAppData?.LeadStatus}
                </Label> */}
                </Box>
                <DealTradeStructure onSubmit={handleSubmit} />
              </form>
            )}
          </Form>
        </Grid>
      </Grid>
      <CustomCircular open={isLoadingAdd || isLoadingEdit} />
    </Container>
  )
}

export default DealsTradeAddEdit
