import { getCurrentDate } from '../../../../utils/general'

export const transformToFormValues = (cashAccountsData?: any, unpaidVendorsData?: any, selectedVendor?: number) => {
  // Find the account where "IsPrimaryBank" is true
  const primaryBankAccount = cashAccountsData?.find((account: any) => account.IsPrimaryBank === true)
  const primaryBankAccountId = primaryBankAccount?.ID

  // Default values
  let vendorAttention = ''
  let vendorName = ''
  let address1 = ''
  let address2 = ''
  let city = ''
  let state = ''
  let postalCode = ''

  if (selectedVendor) {
    const matchedVendor = unpaidVendorsData?.find((vendor: any) => vendor.VendorID === selectedVendor)
    if (matchedVendor) {
      vendorAttention = matchedVendor.Attention || ''
      vendorName = matchedVendor.Name || ''

      const vendorAddress = matchedVendor.AddressData[0]
      if (vendorAddress) {
        address1 = vendorAddress.Address1 || ''
        address2 = vendorAddress.Address2 || ''
        city = vendorAddress.City || ''
        state = vendorAddress.State || ''
        postalCode = vendorAddress.PostalCode || ''
      }
    }
  }

  return {
    BillPayType: 1, // TODO CHECK
    PaymentDate: getCurrentDate(),
    BankAccount: primaryBankAccountId,
    Attention: vendorAttention,
    PayeeName: vendorName,
    Address1: address1,
    Address2: address2,
    City: city,
    State: state,
    ZipCode: postalCode,
    vendor: selectedVendor,
  }
}
