import React, { useEffect, useRef } from 'react'
import { IMaskInput } from 'react-imask'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FilledInput from '@mui/material/FilledInput'
import { Field } from 'react-final-form'
import { OutlinedInput, TextField } from '@mui/material'
import { useIMask } from 'react-imask'

interface Props {
  name: string
  label: string
  variant?: 'standard' | 'outlined' | 'filled'
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  readonly?: boolean
  min?: string
  max?: string
  validate?: (value: any) => any
  [x: string]: any
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

// create a wrapper component around IMaskInput
const IMaskWrapper = ({ onFocus, ...props }: any) => {
  const inputRef = useRef<any>()

  useEffect(() => {
    if (inputRef.current) {
      const inputElem = inputRef?.current?.querySelector('input')
      if (inputElem) inputElem.onfocus = onFocus
    }
  }, [onFocus])

  return (
    <div style={{ display: 'contents' }} ref={inputRef}>
      <IMaskInput {...props} />
    </div>
  )
}

export const AmountMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  const configBlocks = {
    thisInput: {
      mask: Number,
      radix: '.', // fractional delimiter
      scale: 2, // digits after point, 0 for integers
      signed: true, // disallow negative
      thousandsSeparator: ',', // any single char
      padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
      normalizeZeros: true, // appends or removes zeros at ends
      value: '',
      unmask: false, // true|false|'typed'
    },
  }

  return (
    <div style={{ display: 'contents' }}>
      <IMaskWrapper
        {...other}
        blocks={configBlocks}
        mask='thisInput'
        onAccept={(value: any) => {
          console.log('value', value)
          onChange({ target: { name: props.name, value } })
        }}
        // overwrite
      />
    </div>
  )
})

export const AmountField = ({
  name,
  label,
  initialValue,
  defaultValue,
  mask,
  maskChar,
  variant,
  fullWidth,
  startAdornment,
  endAdornment,
  required,
  readonly,
  min,
  max,
  disabled,
  validate,
  onChange,
  ...props
}: Props) => {
  const { ref, maskRef, value, setValue, unmaskedValue, setUnmaskedValue, typedValue, setTypedValue } = useIMask(
    {
      mask: Number,
      radix: '.', // fractional delimiter
      scale: 2, // digits after point, 0 for integers
      signed: true, // disallow negative
      thousandsSeparator: ',', // any single char
      padFractionalZeros: true, // if true, then pads zeros at end to the length of scale
      normalizeZeros: true, // appends or removes zeros at ends
      value: '',
      unmask: false, // true|false|'typed'
    } /* optional {
    onAccept,
    onComplete,
    ref,
    defaultValue,
    defaultUnmaskedValue,
    defaultTypedValue,
  } */,
  )

  return (
    <Field name={name} defaultValue={defaultValue} validate={validate}>
      {({ input, meta: { error, touched } }) => (
        <FormControl variant={variant} fullWidth={fullWidth} error={touched && error ? true : false} required={required}>
          <InputLabel
            htmlFor='formatted-text-mask-input'
            disabled={disabled}
            variant='outlined'
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            {label}
          </InputLabel>
          {variant === 'standard' && (
            <OutlinedInput
              {...input}
              {...props}
              disabled={disabled}
              onChange={(event) => {
                console.log(event.target.value)
                input.onChange(event)
                onChange && onChange(event)
              }}
              inputProps={{
                readOnly: readonly,
                min: min,
                max: max,
              }}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              id='formatted-text-mask-input'
              inputComponent={AmountMaskCustom as any}
              autoComplete='off'
            />
          )}
          {variant === 'filled' && (
            <FilledInput
              {...input}
              {...props}
              inputProps={{
                readOnly: readonly,
                min: min,
                max: max,
              }}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              id='formatted-text-mask-input'
              inputComponent={AmountMaskCustom as any}
              autoComplete='off'
            />
          )}
          {touched && error && (
            <FormHelperText id='my-helper-text' error={touched && error ? true : false}>
              {error}
            </FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  )
}

export default AmountField
