import { Controller, useFormContext } from 'react-hook-form'

import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { RHFSelect, RHFTextField } from '../../../../components/hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Field, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { UspAccountingChartApAccountsView, VendorResponseSp } from '../../../../models'
import TextField from '../../../../ui/TextField'
import { DateField } from '../../../../ui'

// ----------------------------------------------------------------------
interface Props {
  chartOfAccountApData: UspAccountingChartApAccountsView[]
  isFieldsLocked?: boolean
}
export default function InvoiceNewEditStatusDate({ isFieldsLocked, chartOfAccountApData }: Props) {
  const { values, errors } = useFormState()
  const coaApData = chartOfAccountApData?.filter((item) => item?.CompanyType === 'Sales')

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ p: 3, bgcolor: 'background.neutral' }}>
      <TextField disabled={isFieldsLocked} name='BillNumber' label='Bill Number' fullWidth />

      <DropdownFieldSearch
        fullWidth
        disabled={isFieldsLocked}
        name='ChartOfAccountAP'
        label='Chart Of Account (A/P)'
        options={coaApData}
        optionKey={'ID'}
        optionValue={'AccountNumberName'}
      />

      <DateField disabled={isFieldsLocked} name='BillDate' label='Bill Date' fullWidth />
      <DateField disabled={isFieldsLocked} name='DueDate' label='Due Date' fullWidth />
    </Stack>
  )
}
