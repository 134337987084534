import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAddStipulationMutation, useEditStipulationMutation, useLazyGetStipulationDataQuery } from '../../../../services'
import { UspConfigurationStipulationViewResult } from '../../../../models'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationStipulationViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}

const AddEditStipulationsModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addStipulationType, { isLoading: isLoadingStipulationType, isSuccess: isSuccessStipulationType }] = useAddStipulationMutation()
  const [editStipulationType, { isLoading: isLoadingEditStipulationType, isSuccess: isSuccessEditStipulationType }] = useEditStipulationMutation()
  const [getStipulationTypeData, { isLoading: isLoadingGetStipulationType, isSuccess: isSuccessGetStipulationType, data }] =
    useLazyGetStipulationDataQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editStipulationType({
        stipulationId: rowData?.ID?.toString(),
        uspConfigurationStipulationAddEdit: {
          stipulation: values.Description,
          isActive: values.IsActive,
        },
      })
    } else {
      addStipulationType({
        uspConfigurationStipulationAddEdit: {
          stipulation: values.Description,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getStipulationTypeData({
        stipulationId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
      })
    }
    if (isSuccessGetStipulationType && type === 'edit') {
      setInitialValues({
        Description: data?.Stipulation,
        IsActive: data?.IsActive,
      })
    }
  }, [isSuccessGetStipulationType, data, type])

  useEffect(() => {
    if (isSuccessStipulationType || isSuccessEditStipulationType) {
      onClose()
      submit()
    }
  }, [isSuccessStipulationType, isSuccessEditStipulationType])

  const isLoading = useMemo(
    () => isLoadingStipulationType || isLoadingEditStipulationType || isLoadingGetStipulationType,
    [isLoadingStipulationType, isLoadingEditStipulationType, isLoadingGetStipulationType],
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Stipulations</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditStipulationsModal
