import dayjs from 'dayjs'
import * as yup from 'yup'

const today = dayjs().endOf('day')
export const AddCriteriaValidation: yup.SchemaOf<any> = yup
  .object({
    BranchID: yup.number().nullable().notRequired(),
    financialStatement: yup.string().nullable().required(),
    ClosedPeriod: yup.boolean().nullable().notRequired().default(false),
    criteriaStartDate: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
    criteriaEndDate: yup.date().nullable().notRequired().typeError('Invalid Date'),
    ExcludeAccountsWithNoBalance: yup.boolean().nullable().notRequired().default(true),
    GroupByAccounts: yup.boolean().nullable().notRequired().default(false),
    IncludeInactiveAccounts: yup.boolean().nullable().notRequired().default(false),
  })
  .required()
