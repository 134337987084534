import { Box, Button, Card, CardHeader, IconButton, MenuItem, Typography } from '@mui/material'
import Iconify from '../../../../../../components/iconify'
import { UspCarSearchResult, UspGetDataCar } from '../../../../../../models'
import CustomPopover, { usePopover } from '../../../../../../components/custom-popover'
import AddIcon from '@mui/icons-material/Add'

type Props = {
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  carData: any
  setSelectedCar: (car?: UspCarSearchResult) => void
}

const VehicleCard = ({ carData, setCurrentStage, setSelectedCar }: Props) => {
  const popover = usePopover()

  return (
    <>
      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
          p: 4,
          gap: 3,
        }}
      >
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6'>Vehicle</Typography>
          {!carData && (
            <Button onClick={() => setCurrentStage('inventory')} startIcon={<AddIcon />} color='primary'>
              Vehicle
            </Button>
          )}
        </Box>

        {carData && (
          <Card
            variant='outlined'
            sx={{
              pb: 3,
            }}
          >
            <CardHeader
              action={
                <IconButton aria-label='actions' color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
                  <Iconify icon='eva:more-vertical-fill' />
                </IconButton>
              }
              title={
                <>
                  <Typography variant='subtitle1'>{carData?.Vehicle}</Typography>
                  <Typography variant='body2' color='text.secondary'>
                    Stock # {carData?.StockNumber}
                  </Typography>
                  <Typography variant='body2' color='text.secondary'>
                    VIN {carData?.VIN}
                  </Typography>
                </>
              }
            />
          </Card>
        )}
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='right-top' sx={{ width: 160 }}>
        <MenuItem
          onClick={() => {
            setCurrentStage('inventory')
            popover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>

        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={() => {
            setSelectedCar(undefined)
            popover.onClose()
          }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default VehicleCard
