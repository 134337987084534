import React, { useState } from 'react'
import Box from '@mui/material/Box'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { styled } from '@mui/system'
import { appRoute } from '../../../../../constants'
import { Container, Typography, Grid, Button, Modal, Tooltip } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { TextField } from '../../../../../ui'
import { useFormState } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import InventorySearchModal from '../InventorySearchModal/InventorySearchModal'
import SearchIcon from '@mui/icons-material/Search'
import { SearchCarApiResponse } from '../../../../../models'
import { useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'searchBtn', 'btnWrap'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 0),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchBtn}`]: {
    margin: theme.spacing(0, 2, 0, 'auto'),
  },
  [`& .${classes.btnWrap}`]: {
    maxWidth: '42px',
    maxHeight: '42px',
    minWidth: '42px',
    minHeight: '42px',
    padding: '3px',
    marginLeft: '10px',
  },
}))

const classesBox = generateUtilityClasses('Container', ['root', 'vinWrap', 'icon'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '200px',
    padding: theme.spacing(4),
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
  },
  [`& .${classesBox.vinWrap}`]: {
    marginBottom: theme.spacing(4),
  },
  [`& .${classesBox.icon}`]: {
    fontSize: 16,
    color: theme.palette.text.button,
  },
}))

export const InventorySearchBlock = ({
  submit,
  loading,
  searchCarsData,
  initialLoad,
}: {
  submit: (...args: any) => any
  loading: boolean
  searchCarsData: SearchCarApiResponse
  initialLoad: boolean
}) => {
  const [openSearchModal, setOpenSearchModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const { values } = useFormState()
  const userAccess = useSelector(getUserAccessRights)
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true)
  }

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false)
  }

  const open = Boolean(anchorEl)

  const handleAddNew = () => {
    if (values.vinAdd) {
      navigate(appRoute.InventoryAddVin.replace(':vin', values.vinAdd))
    } else {
      navigate(appRoute.InventoryAdd)
    }
  }

  return (
    <StyledFormWrap className={classes.root}>
      <Grid className={classes.titleWrap}>
        <Typography variant='h3'>Inventory</Typography>
      </Grid>

      {/* <Grid className={classes.searchBtn}>
        <Tooltip title='Search'>
          <Button color='primary' variant='contained' className={classes.btnWrap} onClick={handleOpenSearchModal}>
            <SearchIcon />
          </Button>
        </Tooltip>
        {userAccess?.CanAddCar && (
          <Tooltip title='Add'>
            <Button color='primary' variant='contained' className={classes.btnWrap} onClick={handleClick}>
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </Grid> */}
      <InventorySearchModal
        searchCarsData={searchCarsData}
        loading={loading}
        submit={submit}
        open={openSearchModal}
        onClose={handleCloseSearchModal}
        initialLoad={initialLoad}
      />
      <Modal open={open} onClose={handleClose}>
        <StyledBox className={classesBox.root}>
          <Grid className={classesBox.vinWrap}>
            <TextField name='vinAdd' label='VIN (Optional)' fullWidth />
          </Grid>
          <Grid>
            <Button fullWidth variant='contained' color='success' onClick={handleAddNew}>
              Next <ArrowForwardIosIcon className={classesBox.icon} />
            </Button>
          </Grid>
        </StyledBox>
      </Modal>
    </StyledFormWrap>
  )
}

export default InventorySearchBlock
