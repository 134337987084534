import {
  Box,
  generateUtilityClasses,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { TMappingData } from '../../../../../../models'
import { storageGet, storageSet } from '../../../../../../utils/storage'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { useDeleteCreditAppMutation, useDeleteFormMappingMutation } from '../../../../../../services'
import ConfirmDialog from '../../../../../../components/ConfirmDialog'
import { enqueueNotification } from '../../../../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getComparator, stableSort } from '../../../../../../utils/sorting'

const classes = generateUtilityClasses('Grid', ['root', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
}))

interface Props {
  tableType: 'mapped' | 'unmapped'
  tableTitle: string
  tableData: TMappingData[] | string[] | []
  setOpenMappingModal: Dispatch<SetStateAction<boolean>>
  setFormMappingModalData: Dispatch<SetStateAction<string | TMappingData | null>>
  setIsEdit?: Dispatch<SetStateAction<boolean>>
  getFormData?: (e: any) => void
}
const MappedFieldsTableBlock = ({
  getFormData,
  tableTitle,
  tableData,
  setOpenMappingModal,
  setFormMappingModalData,
  tableType,
  setIsEdit,
}: Props) => {
  const dispatch = useDispatch()
  const { formId } = useParams()
  const [deleteFormMapping, { isSuccess: deleteFormMappingIsSuccess }] = useDeleteFormMappingMutation()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ formMappingId: string } | undefined>(undefined)

  const initialOrder = (storageGet('forms_mapped_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('forms_mapped_order_by') || 'FieldName') as keyof TMappingData
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof TMappingData | string>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof TMappingData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('forms_mapped_order', isAsc ? 'desc' : 'asc')
    storageSet('forms_mapped_order_by', property)
  }

  const createSortHandler = (property: keyof TMappingData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleDelete = () => {
    if (deleteConfirmDialog?.formMappingId) {
      deleteFormMapping(deleteConfirmDialog)
    }
    setDeleteConfirmDialog(undefined)
  }

  useEffect(() => {
    if (deleteFormMappingIsSuccess) {
      if (getFormData && formId) {
        getFormData({ formId: formId.toString() })
      }
      dispatch(enqueueNotification({ message: 'Form mapping removed' }))
    }
  }, [deleteFormMappingIsSuccess, dispatch])

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 190px)' }} className={classes.root}>
          <Table aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'FieldName' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'FieldName'}
                    direction={orderBy === 'FieldName' ? order : 'asc'}
                    onClick={createSortHandler('FieldName')}
                  >
                    {tableTitle}
                    {orderBy === 'FieldName' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                {tableType === 'mapped' && (
                  <TableCell align='right' className={classes.tableHead}>
                    Action
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), tableData as TMappingData[]).map((item: TMappingData, index: number) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    className={classes.tableCellName}
                    onClick={() => {
                      setFormMappingModalData(item)
                      setOpenMappingModal(true)
                      if (setIsEdit) {
                        setIsEdit(true)
                      }
                    }}
                  >
                    {tableType === 'unmapped' ? (typeof item === 'string' ? item : 'Invalid item') : item?.FieldName}
                  </TableCell>
                  {tableType !== 'unmapped' && (
                    <TableCell align={'right'}>
                      <Tooltip title='Delete'>
                        <IconButton
                          color='error'
                          onClick={() => {
                            setDeleteConfirmDialog({ formMappingId: item?.FormMappingID?.toString() as string })
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {tableData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Form mapping will be removed'}
      />
    </>
  )
}

export default MappedFieldsTableBlock
