import * as yup from 'yup'

export const productLabels = {
  ProductCost: 'Product Cost',
  ExpirationMileage: 'Expiration Mileage',
  Expires: 'Expires',
  Term: 'Product Term',
}

export const productValidation: yup.SchemaOf<any> = yup
  .object({
    ProductCost: yup.string().label(productLabels.ProductCost).required(),
    ExpirationMileage: yup.string().label(productLabels.ExpirationMileage).nullable(),
    Expires: yup.date().required().label(productLabels.Expires),
    Term: yup.string().label(productLabels.Term),
  })
  .required()
