import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { PercentField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

export const TaxesForm = ({ onDropdownFormChange }: Props) => {
  return (
    <>
      <CardHeader title='Taxes' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} md={6}>
          <PercentField variant='standard' name='StateTaxRate' label='State Tax Rate' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField variant='standard' name='MiscTaxRate' label='Misc Tax Rate' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='IsDestionationTax'
            label='Is Destionation Tax?'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='TradeTaxCredit'
            label='Trade Tax Credit?'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='DefferedSalesTax'
            label='Deffered Sales Tax'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default TaxesForm
