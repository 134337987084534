import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { UspGetDataCreditApp } from '../../models'
import { upsert } from '../../utils/general'

type CreditAppState = {
  creditApp?: Array<UspGetDataCreditApp>
  creditAppID?: number | undefined
  dealID?: number | undefined
}

export const slice = createSlice({
  name: 'creditApps',
  initialState: {
    creditApp: [],
    creditAppID: undefined,
  } as CreditAppState,
  reducers: {
    setCreditApp: (state, { payload }) => {
      upsert(state.creditApp, payload, 'CreditAppID')
    },
    setCreditAppID: (state, { payload }) => {
      state.creditAppID = payload
    },
  },
})
export const getCreditApp =
  (creditAppID: string | undefined): any =>
  (state: RootState) => {
    if (creditAppID) {
      return state?.creditApp?.CreditAppID?.find((creditApp: UspGetDataCreditApp) => String(creditApp?.CreditAppID) === String(creditAppID))
    }
    return undefined
  }

export const getCreditAppID = (state: RootState) => {
  return state?.creditApp?.creditAppID
}
export const { setCreditApp, setCreditAppID } = slice.actions

export default slice.reducer
