import * as yup from 'yup'

export const vehicleInterestedInValidation = yup
  .object({
    YearMin: yup.number().required('Year (min) is required').integer('Year (min) must be an integer'),
    YearMax: yup
      .number()
      .required('Year (max) is required')
      .integer('Year (max) must be an integer')
      .moreThan(yup.ref('YearMin'), 'Year (max) must be greater than Year (min)'),
    Make: yup.string().required('Make is required').nullable(false).typeError('Invalid Input'),
  })
  .required()
