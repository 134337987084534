import { Box, Button, Card, CardHeader, Container, Grid, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import Header from './components/Header/Header'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { appRoute } from '../../../../constants'
import InsuranceForm from './components/InsuranceForm/InsuranceForm'
import SettingsForm from './components/SettingsForm/SettingsForm'
import { withPermissionsDropdown } from '../../../../hoc'
import { useAddInsuranceMutation, useEditInsuranceMutation, useGetContactMutation, useLazyGetInsuranceDataQuery } from '../../../../services'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { transformToFormValues, transformToRequestValues } from './transform'
import { CustomCircular } from '../../../../ui'
import validateFormValues from '../../../../utils/validateForm'
import { addInsuranceValidation } from '../../../../validation/addInsuranceValidation'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import AddressForm from './components/InsuranceForm/addressForm'
import ContactForm from './components/InsuranceForm/contactForm'

const classes = generateUtilityClasses('Box', ['root', 'firstRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.firstRow}`]: {
    minHeight: '300px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    position: 'sticky',
    right: '0px',
    bottom: '0px',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const AddEditInsurance = () => {
  const { insuranceId } = useParams()
  const [initialValues, setInitialValues] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [getInsurance, { data: insuranceData, isLoading: getInsuranceIsLoading }] = useLazyGetInsuranceDataQuery()
  const [getContact, { data: contactData, isLoading: getContactIsLoading }] = useGetContactMutation()
  const [addInsurance, { isSuccess: isSuccessInsuranceDataAdd }] = useAddInsuranceMutation()
  const [editInsurance, { isSuccess: isSuccessInsuranceDataEdit }] = useEditInsuranceMutation()

  useEffect(() => {
    if (insuranceId !== 'add') {
      getInsurance({ insuranceId: insuranceId as string })
    }
  }, [insuranceId])

  useEffect(() => {
    if (insuranceData) {
      getContact({ contactId: insuranceData?.ContactID.toString() as string })
    }
  }, [insuranceData])

  useEffect(() => {
    setInitialValues(transformToFormValues(insuranceData, contactData, insuranceId))
  }, [insuranceData, contactData])

  const handleSubmit = (values: any) => {
    if (insuranceId === 'add') {
      addInsurance({
        uspConfigurationInsuranceAddEdit: transformToRequestValues(values) as any,
      })
    } else {
      editInsurance({
        insuranceId: insuranceId as string,
        uspConfigurationInsuranceAddEdit: transformToRequestValues(values) as any,
      })
    }
  }

  useEffect(() => {
    if (isSuccessInsuranceDataAdd || isSuccessInsuranceDataEdit) {
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(appRoute.SettingsDealsInsurance)
    }
  }, [isSuccessInsuranceDataAdd, isSuccessInsuranceDataEdit])

  const isLoading = useMemo(() => getInsuranceIsLoading || getContactIsLoading, [getInsuranceIsLoading, getContactIsLoading])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Insurance'
        links={[
          {
            name: 'List',
            href: appRoute.SettingsDealsInsurance,
          },
          { name: 'Insurance' },
        ]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(addInsuranceValidation)}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <Card sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column' }}>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <InsuranceForm />
                </Grid>

                <Grid item xs={12}>
                  <AddressForm />
                </Grid>
                <Grid item xs={12}>
                  <ContactForm />
                </Grid>

                <Grid item xs={12}>
                  <SettingsForm />
                </Grid>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'sticky',
                  bottom: 16,
                  right: 20,
                  gap: 1,
                  p: 3,
                  width: 'fit-content',
                  alignSelf: 'flex-end',
                }}
              >
                <Button variant='contained' color='error' onClick={() => navigate(appRoute?.SettingsDealsInsurance)}>
                  Close
                </Button>
                <Button type='submit' className={classes.saveBtn} variant='contained'>
                  Save & Close
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Container>
  )
}

export default withPermissionsDropdown(AddEditInsurance)
