import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import { DateField } from '../../../../../../../ui'
import { TPaymentType } from '../../../../../../../models'
import { useFormState } from 'react-final-form'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { convertDateToString } from '../../../../../../../utils/dateUtils'
import { ParsedDropdownResponse } from '../../../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  paymentType: TPaymentType[]
  paymentMethod: ParsedDropdownResponse[] | undefined
  paymentForm: ParsedDropdownResponse[]
  setActiveStep: (step: number) => void
  setShouldSkip: (shouldSkip: boolean) => void
  setPreviousTypeSetup: (typeSetup: string) => void
  getAccountDueAmount: (accountDetails: { dealId: string; accountDueAmount: { effectiveDate: string | null; isQuote: boolean } }) => void
  CalculationErrorDescription: any
}
const SetupCard = ({ paymentType, paymentMethod, paymentForm, setPreviousTypeSetup, getAccountDueAmount, CalculationErrorDescription }: Props) => {
  const { values } = useFormState<{ TypeSetup?: string; EffectiveDate?: string }>()
  const { dealID } = useParams<{ dealID: string }>()
  const [defaultTypeSetup, setDefaultTypeSetup] = useState('')

  useEffect(() => {
    const effectiveDate = values?.EffectiveDate ? convertDateToString(values?.EffectiveDate?.toString()) : null
    if (effectiveDate && effectiveDate !== 'Invalid Date') {
      getAccountDueAmount({
        dealId: dealID as string,
        accountDueAmount: {
          effectiveDate: effectiveDate as string,
          isQuote: false,
        },
      })
    }
  }, [values?.EffectiveDate])

  // in SetupCard
  useEffect(() => {
    if (values?.TypeSetup) {
      setPreviousTypeSetup(values?.TypeSetup.toLowerCase())
    }
  }, [values?.TypeSetup])

  useEffect(() => {
    if (paymentType?.find((type) => type.PaymentType?.toLowerCase() === 'downpayment')) {
      setDefaultTypeSetup('DownPayment')
    } else if (paymentType?.find((type) => type.PaymentType?.toLowerCase() === 'loan')) {
      setDefaultTypeSetup('Loan')
    }
  }, [paymentType])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateField name='EffectiveDate' label='Effective Date' fullWidth helperText={CalculationErrorDescription} />
        </Grid>
        {paymentType && (
          <Grid item xs={12}>
            <DropdownFieldSearch
              name='TypeSetup'
              label='Type'
              initialValue={defaultTypeSetup}
              options={paymentType}
              autoComplete='off'
              optionKey='PaymentType'
              optionValue='PaymentType'
            />
          </Grid>
        )}

        {paymentMethod && (
          <Grid item xs={12}>
            <DropdownFieldSearch name='Method' label='Method' options={paymentMethod} optionKey='ID' optionValue='PaymentMethod' autoComplete='off' />
          </Grid>
        )}

        {paymentForm && (
          <Grid item xs={12}>
            <DropdownFieldSearch name='Form' label='Form' options={paymentForm} optionKey='ID' optionValue='PaymentForm' autoComplete='off' />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default SetupCard
