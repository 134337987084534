import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { storageGet } from '../utils/storage'

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || 'https://api.elev8dms.com/api/v1'

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndpoint,
    prepareHeaders: (headers, { getState }) => {
      const state: any = getState()
      const auth = state.auth

      if (auth?.access) {
        headers.set('Authorization', `Bearer ${auth?.access}`)
      } else {
        const access = storageGet('access')
        if (access) {
          headers.set('Authorization', `Bearer ${access}`)
        }
      }

      if (auth?.client) {
        headers.set('CLIENT-UID', auth?.client)
      } else {
        const access = storageGet('client')
        if (access) {
          headers.set('CLIENT-UID', auth?.client)
        }
      }

      if (auth?.companyType) {
        headers.set('COMPANY-TYPE', auth?.companyType)
      } else {
        const access = storageGet('companyType')
        if (access) {
          headers.set('COMPANY-TYPE', auth?.companyType)
        }
      }

      if (auth?.branch) {
        headers.set('COMPANY-BRANCH', auth?.branch)
      } else {
        const access = storageGet('branch')
        if (access) {
          headers.set('COMPANY-BRANCH', auth?.branch)
        }
      }

      return headers
    },
  }),
  tagTypes: ['Deals', 'Notes', 'Leads',"Extensions"],
  endpoints: () => ({}),
})
