import { useMemo } from 'react'

import { paths } from '../../routes/paths'

import SvgColor from '../../components/svg-color'

import { appRoute } from '../../constants'

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
)

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  inventory: icon('ic_car'),
  leads: icon('ic_users'),
  deals: icon('ic_course'),
  accounts: icon('ic_user'),
  settings: icon('ic_settings'),
}

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: '',
        items: [
          {
            title: 'Dashboard',
            path: appRoute.Dashboard,
            icon: ICONS.dashboard,
          },
          {
            title: 'Inventory',
            path: appRoute.Inventory,
            icon: ICONS.inventory,
          },
          {
            title: 'Leads',
            path: appRoute.LeadsList,
            icon: ICONS.leads,
          },
          {
            title: 'Deals',
            path: appRoute.DealsList,
            icon: ICONS.deals,
            // children: [{ title: 'Deals Task', path: appRoute.DealsTask }],
          },
          {
            title: 'Accounts',
            path: appRoute.Accounts,
            icon: ICONS.accounts,
          },
          {
            title: 'Collections',
            path: '/collections',
            icon: ICONS.chat,
            children: [
              { title: 'Create a Worklist', path: appRoute.CollectionsCreateWorklist },
              { title: 'My Worklist', path: appRoute.CollectionsMyWorklist },
              { title: 'Log into a Worklist', path: appRoute.CollectionsLogWorklist },
            ],
          },
          {
            title: 'Accounting',
            path: '/accounting',
            icon: ICONS.invoice,
            children: [
              { title: 'Bank Deposits', path: appRoute.AccountingBankDeposits },
              { title: 'Billing', path: appRoute.AccountingBilling },
              { title: 'Bill Pay', path: appRoute.AccountingBillPay },
              { title: 'Chart Of Accounts', path: appRoute.AccountingChartOfAccounts },
              { title: 'Checks', path: appRoute.AccountingChecks },
              { title: 'Financials', path: appRoute.AccountingFinancials },
              { title: 'Journal Entry', path: appRoute.AccountingJournalEntry },
              { title: 'Reconciliation', path: '' },
              { title: 'View Ledger Details', path: appRoute.AccountingList },
            ],
          },
          {
            title: 'Reports',
            path: appRoute.Insights,
            icon: ICONS.analytics,
          },
          {
            title: 'Settings',
            path: '/settings',
            icon: ICONS.settings,
            children: [
              {
                title: 'Admin',
                path: '/settings/admin',
                children: [
                  { path: appRoute.SettingsAdmingGlobalSettings, title: 'Global Settings' },
                  { path: '#', title: 'Branches' },
                  { path: '#', title: 'Companies' },
                  { path: appRoute.SettingsAdminDealers, title: 'Dealers' },
                  { path: appRoute.SettingsAdminIPAddresses, title: 'IP Addresses' },
                  { path: appRoute.LendersList, title: 'Lenders' },
                  { path: appRoute.SettingsAdminLots, title: 'Lots' },
                  { path: appRoute.SettingsAdminRFC, title: 'RFC' },
                  { path: appRoute.SettingsAdminUserRoles, title: 'User Roles' },
                  { path: appRoute.UserList, title: 'Users' },
                  { path: appRoute.SettingsAdminVendorTypes, title: 'Vendor Types' },
                  { path: appRoute.VendorsList, title: 'Vendors' },
                ],
              },
              {
                title: 'Collections',
                path: '/settings/collections',
                children: [{ path: appRoute.SettingsCollectionsRepoTypes, title: 'Repo Types' }],
              },
              {
                title: 'Communication',
                path: '/settings/communications',
                children: [
                  { path: appRoute.SettingsCommunicationsCallOutcome, title: 'Call Outcomes' },
                  { path: '', title: 'SMS Templates' },
                  { path: '', title: 'SMS Template Categories' },
                ],
              },
              {
                title: 'Credit App/Lead',
                path: '/settings/leads',
                children: [
                  { path: appRoute.SettingsLeadsLeadSources, title: 'Lead Sources' },
                  { path: appRoute.SettingsLeadsCreditAppExpenses, title: 'Applicant Expenses' },
                  { path: appRoute.SettingsLeadsCreditAppStatuses, title: 'Credit App Statuses' },
                  { path: appRoute.SettingsLeadsInterestLevels, title: 'Interest Levels' },
                  { path: appRoute.SettingsLeadsReferenceRelationships, title: 'Reference Relationships' },
                  { path: appRoute.SettingsLeadsStipulations, title: 'Stipulations' },
                ],
              },
              {
                title: 'Deal',
                path: '/settings/deals',
                children: [
                  { path: appRoute.SettingsDealsDealStatuses, title: 'Deal Statuses' },
                  { path: appRoute.SettingsDealsDealerReserve, title: 'Dealer Reserves' },
                  { path: '', title: 'Fees (Master)' },
                  { path: '', title: 'Fees (State)' },
                  { path: '', title: 'F&I Products (Master)' },
                  { path: '', title: 'F&I Products (State)' },
                  { path: appRoute.SettingsDealsInsurance, title: 'Insurance' },
                ],
              },
              {
                title: 'Forms',
                path: '/settings/form',
                children: [
                  { path: appRoute.SettingsFormsList, title: 'Form Mapping' },
                  { path: appRoute.SettingsFormsPacksList, title: 'Form Packs' },
                ],
              },
              {
                title: 'Inventory',
                path: '/settings/inventory',
                children: [
                  { title: 'Body Types', path: appRoute.SettingsInventoryBodyTypes },
                  { title: 'Conditions', path: appRoute.SettingsInventoryConditions },
                  { title: 'Drivetrains', path: appRoute.SettingsInventoryDrivetrains },
                  { title: 'Expense Types', path: appRoute.SettingsInventoryExpenses },
                  { title: 'Finders', path: '' },
                  { title: 'Fuel Types', path: appRoute.SettingsInventoryFuelTypes },
                  { title: 'Inventory Statuses', path: appRoute.SettingsInventoryStatus },
                  { title: 'Makes', path: appRoute.SettingsInventoryCarMakes },
                  { title: 'Pack Fees', path: appRoute.SettingsInventoryPackFees },
                  { title: 'Purchase Types', path: appRoute.SettingsInventoryPurchaseTypes },
                  { title: 'Title Types', path: appRoute.SettingsInventoryTitleTypes },
                ],
              },
              {
                title: 'Payment',
                path: '/settings/payment',
                children: [
                  { path: appRoute.SettingsPaymentCheckTypes, title: 'Check Types' },
                  { path: appRoute.SettingsPaymentCreditCardTypes, title: 'Credit Card Types' },
                  { path: appRoute.SettingsPaymentPaymentForms, title: 'Payment Forms' },
                  { path: appRoute.SettingsPaymentPaymentMethods, title: 'Payment Methods' },
                  { path: appRoute.SettingsPaymentPaymentSideNote, title: 'Side Notes' },
                ],
              },
              {
                title: 'State',
                path: '/settings/state',
                children: [
                  { path: appRoute.SettingsStateSettings, title: 'State Settings' },
                  { path: appRoute.SettingsStateCityTaxes, title: 'City Taxes' },
                  { path: appRoute.SettingsStateCountyTaxes, title: 'County Taxes' },
                ],
              },
            ],
          },
        ],
      },

      // { id: uniqueId('Payment', 7), title: 'Payment', Icon: Payments, paths: paymentpaths },
      // { id: uniqueId('State', 8), title: 'State', Icon: State, paths: statepaths },

      // SETTINGS
      // ----------------------------------------------------------------------
      // {
      //   subheader: 'management',
      //   items: [
      //     // USER
      //     {
      //       title: 'user',
      //       path: '',
      //       icon: ICONS.user,
      //       children: [
      //         { title: t('profile'), path: paths.dashboard.user.root },
      //         { title: t('cards'), path: paths.dashboard.user.cards },
      //         { title: t('list'), path: paths.dashboard.user.list },
      //         { title: t('create'), path: paths.dashboard.user.new },
      //         { title: t('edit'), path: paths.dashboard.user.demo.edit },
      //         { title: t('account'), path: paths.dashboard.user.account },
      //       ],
      //     },

      //     // PRODUCT
      //     {
      //       title: t('product'),
      //       path: paths.dashboard.product.root,
      //       icon: ICONS.product,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.product.root },
      //         {
      //           title: t('details'),
      //           path: paths.dashboard.product.demo.details,
      //         },
      //         { title: t('create'), path: paths.dashboard.product.new },
      //         { title: t('edit'), path: paths.dashboard.product.demo.edit },
      //       ],
      //     },

      //     // ORDER
      //     {
      //       title: t('order'),
      //       path: paths.dashboard.order.root,
      //       icon: ICONS.order,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.order.root },
      //         { title: t('details'), path: paths.dashboard.order.demo.details },
      //       ],
      //     },

      //     // INVOICE
      //     {
      //       title: t('invoice'),
      //       path: paths.dashboard.invoice.root,
      //       icon: ICONS.invoice,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.invoice.root },
      //         {
      //           title: t('details'),
      //           path: paths.dashboard.invoice.demo.details,
      //         },
      //         { title: t('create'), path: paths.dashboard.invoice.new },
      //         { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
      //       ],
      //     },

      //     // BLOG
      //     {
      //       title: t('blog'),
      //       path: paths.dashboard.post.root,
      //       icon: ICONS.blog,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.post.root },
      //         { title: t('details'), path: paths.dashboard.post.demo.details },
      //         { title: t('create'), path: paths.dashboard.post.new },
      //         { title: t('edit'), path: paths.dashboard.post.demo.edit },
      //       ],
      //     },

      //     // JOB
      //     {
      //       title: t('job'),
      //       path: paths.dashboard.job.root,
      //       icon: ICONS.job,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.job.root },
      //         { title: t('details'), path: paths.dashboard.job.demo.details },
      //         { title: t('create'), path: paths.dashboard.job.new },
      //         { title: t('edit'), path: paths.dashboard.job.demo.edit },
      //       ],
      //     },

      //     // TOUR
      //     {
      //       title: t('tour'),
      //       path: paths.dashboard.tour.root,
      //       icon: ICONS.tour,
      //       children: [
      //         { title: t('list'), path: paths.dashboard.tour.root },
      //         { title: t('details'), path: paths.dashboard.tour.demo.details },
      //         { title: t('create'), path: paths.dashboard.tour.new },
      //         { title: t('edit'), path: paths.dashboard.tour.demo.edit },
      //       ],
      //     },

      //     // FILE MANAGER
      //     {
      //       title: t('file_manager'),
      //       path: paths.dashboard.fileManager,
      //       icon: ICONS.folder,
      //     },

      //     // MAIL
      //     {
      //       title: t('mail'),
      //       path: paths.dashboard.mail,
      //       icon: ICONS.mail,
      //       info: <Label color='error'>+32</Label>,
      //     },

      //     // CHAT
      //     {
      //       title: t('chat'),
      //       path: paths.dashboard.chat,
      //       icon: ICONS.chat,
      //     },

      //     // CALENDAR
      //     {
      //       title: t('calendar'),
      //       path: paths.dashboard.calendar,
      //       icon: ICONS.calendar,
      //     },

      //     // KANBAN
      //     {
      //       title: t('kanban'),
      //       path: paths.dashboard.kanban,
      //       icon: ICONS.kanban,
      //     },
      //   ],
      // },
    ],
    [],
  )

  return data
}
