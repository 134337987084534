import React, { useMemo, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import TableCard from '../../../../components/TableCard/TableCard'
import { Box, Button, Card, CardHeader, IconButton, MenuItem, Paper, Stack, TableCell, Tooltip, Typography } from '@mui/material'
import {
  useDeleteCreditAppLookingAtMutation,
  useDeleteCreditAppLookingForMutation,
  useGetCreditAppQuery,
  useLazyGetCreditAppQuery,
} from '../../../../services'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import DeleteIcon from '@mui/icons-material/Delete'
import VehicleInterestedInModal from './VehicleInterestedInModal/VehicleInterestedInModal'
import SelectInventoryModal from './SelectInventoryModal/SelectInventoryModal'
import InterestedInConfirmDialog from './InterestedInConfirmDialog/InterestedInConfirmDialog'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import { InterestedInData } from '../../../../models'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import Iconify from '../../../../components/iconify'
import CustomPopover from '../../../../components/custom-popover/custom-popover'
import { usePopover } from '../../../../components/custom-popover'

const InterestedInCard = () => {
  const { leadID } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const popover = usePopover()

  const [openSelectInventoryModal, setOpenSelectInventoryModal] = useState(false)
  const [interestedInEditData, setInterestedInEditData] = useState<InterestedInData | undefined>(undefined)
  const [isInterestedInEdit, setIsInterestedInEdit] = useState(false)
  const [interestedInAdd, setInterestedInAdd] = useState<boolean | undefined>(false)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<boolean | undefined>(undefined)
  const [interestedInAddEditModalOpen, setInterestedInAddEditModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<InterestedInData>()
  const [selected, setSelected] = useState<any>(null)
  const [getCreditApp] = useLazyGetCreditAppQuery()
  const [deleteLookingAt, { isSuccess: isSuccessLookingAt }] = useDeleteCreditAppLookingAtMutation()
  const [deleteLookingFor, { isSuccess: isSuccessLookingFor }] = useDeleteCreditAppLookingForMutation()
  const { data: leadData } = useGetCreditAppQuery({
    creditAppId: leadID as string,
  })

  const handleOpenInterestedIn = () => {
    setInterestedInAddEditModalOpen(true)
    setInterestedInAdd(false)
  }
  const handleSelectInventory = () => {
    setOpenSelectInventoryModal(true)
    setInterestedInAddEditModalOpen(false)
    setInterestedInAdd(false)
  }

  const handleDelete = (item: InterestedInData) => {
    setItemToDelete(item)
    setDeleteConfirmDialog(true)
  }

  const handleConfirmDelete = () => {
    if (itemToDelete?.LookingAtID) {
      deleteLookingAt({
        lookingAtId: itemToDelete?.LookingAtID.toString() as string,
      })
    } else if (itemToDelete?.LookingForID) {
      deleteLookingFor({
        lookingForId: itemToDelete?.LookingForID.toString() as string,
      })
    }

    setDeleteConfirmDialog(false)
  }

  useEffect(() => {
    if (isSuccessLookingAt || isSuccessLookingFor) {
      getCreditApp({ creditAppId: leadID as string })
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [isSuccessLookingAt, isSuccessLookingFor])

  const interestedInRows = useMemo(() => {
    return leadData?.InterestedInData.map((item) => (
      <>
        <Stack
          component={Paper}
          variant='outlined'
          spacing={2}
          alignItems={{ md: 'flex-start' }}
          direction={'row'}
          justifyContent={'space-between'}
          sx={{
            position: 'relative',

            p: 2.5,
            borderRadius: 1,
          }}
        >
          <Stack direction='row' alignItems='center'>
            <Typography variant='subtitle2'>{item.Vehicle} </Typography>
          </Stack>
          <Stack direction='row' alignItems='center'>
            <Tooltip title={item.IsAvailable ? 'Available' : 'Unavailable'}>
              {item.IsAvailable ? (
                <CheckCircleIcon
                  sx={{
                    color: ({ palette }) => palette.success.light,
                  }}
                />
              ) : (
                <></>
              )}
            </Tooltip>
            <IconButton
              color={popover.open ? 'inherit' : 'default'}
              onClick={(e) => {
                popover.onOpen(e)
                setSelected(item)
              }}
              // sx={{ position: 'absolute', top: 8, right: 8 }}
            >
              <Iconify icon='eva:more-vertical-fill' />
            </IconButton>
          </Stack>
        </Stack>
      </>
    ))
  }, [leadData])

  return (
    <>
      <Card>
        <CardHeader
          title='Interested In'
          action={
            <Button
              size='small'
              color='primary'
              onClick={(e) => {
                setInterestedInAdd(true)
                // setOpenStipulationModal(true)
              }}
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Interested
            </Button>
          }
        />
        <Stack spacing={2.5} sx={{ p: 3 }}>
          {interestedInRows}
        </Stack>
      </Card>
      {/* <TableCard
        isOnLeadsTaskPage={leadID ? true : false}
        isLost={leadData?.IsLost}
        isSold={leadData?.IsSold}
        title='Interested In'
        headers={['Description', '']}
        rows={interestedInRows}
        action={() => setInterestedInAdd(true)}
        buttonText='View All'
        viewAll={() => true}
      /> */}
      <InterestedInConfirmDialog
        open={!!interestedInAdd}
        onNo={handleOpenInterestedIn}
        onOk={handleSelectInventory}
        handleClose={() => setInterestedInAdd(false)}
        notification={'Is vehicle in active Inventory?'}
      />
      <VehicleInterestedInModal
        onClose={() => {
          setIsInterestedInEdit(false)
          setInterestedInAddEditModalOpen(false)
        }}
        setInterestedInAddEditModalOpen={setInterestedInAddEditModalOpen}
        isVehicleInterestedInOpenModal={interestedInAddEditModalOpen}
        isInterestedInEdit={isInterestedInEdit}
        interestedInEditData={interestedInEditData}
        setIsInterestedInEdit={setIsInterestedInEdit}
        setInterestedInEditData={setInterestedInEditData}
      />
      <SelectInventoryModal open={openSelectInventoryModal} onClose={setOpenSelectInventoryModal} />
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleConfirmDelete}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Are you sure you wish to delete? This action is irreversible.'}
      />

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        {!selected?.CarID ? (
          <MenuItem
            onClick={() => {
              popover.onClose()
              setIsInterestedInEdit(true)
              handleOpenInterestedIn()
              setInterestedInEditData(selected)
            }}
          >
            <Iconify icon='solar:pen-bold' />
            Edit
          </MenuItem>
        ) : (
          ''
        )}
        <MenuItem
          onClick={() => {
            popover.onClose()
            handleDelete(selected)
            setDeleteConfirmDialog(true)
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default InterestedInCard
