import dayjs from 'dayjs'
import * as yup from 'yup'

export const editInventoryLabels = {
  stockNumber: 'Stock Number',
  vin: 'VIN',
  modelYear: 'Year',
  make: 'Make',
  purchasePrice: 'Purchase Price',
  vendor: 'Vendor',
  purchaseWith: 'Purchase With',
  checkNumber: 'Check Number',
  branch: 'Branch',
}

const today = dayjs().endOf('day')

export const editInventoryValidation: yup.SchemaOf<any> = yup
  .object({
    vIN: yup.string().required().nullable().label(editInventoryLabels.vin),
    modelYear: yup.string().required().nullable().label(editInventoryLabels.modelYear),
    make: yup.string().required().nullable().label(editInventoryLabels.make),
    // purchasePrice: yup.string().required().nullable().label(editInventoryLabels.purchasePrice),
    // vendor: yup.string().required().nullable().label(editInventoryLabels.vendor),
    // purchaseWith: yup.string().required().nullable().label(editInventoryLabels.purchaseWith),
    // checkNumber: yup.string().when('purchaseWith', {
    //   is: 'check',
    //   then: yup.string().required().nullable().label(editInventoryLabels.checkNumber),
    // }),
    // branch: yup.string().required().nullable().label(editInventoryLabels.branch),
    titleIssueDate: yup.date().nullable().notRequired().label('Title Issue Date').max(today, 'Future date not allowed').typeError('Invalid Date'),
    stockDate: yup.date().nullable().notRequired().typeError('Invalid date'),
    soldDate: yup.date().nullable().notRequired().typeError('Invalid date'),
    odometerTypeID: yup.string().required('Odometer Type'),
  })
  .required()
