import React from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
}))

export const Header = () => {
  return (
    <Typography
      variant='h4'
      sx={{
        mb: { xs: 3, md: 5 },
      }}
    >
      Reports
    </Typography>
  )
}

export default Header
