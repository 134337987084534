import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Field } from 'react-final-form'
import { StandardTextFieldProps } from '@mui/material/TextField/TextField'

interface Props extends StandardTextFieldProps {
  name: string
  label: string
}

export const PasswordField = ({ name, label, ...props }: Props) => {
  const [show, setShow] = useState(false)

  const handleClick = () => {
    setShow((prev) => !prev)
  }

  const handleMouseDown = (event: any) => {
    event.preventDefault()
  }

  return (
    <Field name={name}>
      {({ input, meta: { error, touched } }) => (
        <TextField
          label={label}
          {...input}
          {...props}
          error={touched && error}
          helperText={touched && error}
          type={show ? 'text' : 'password'}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility' onClick={handleClick} onMouseDown={handleMouseDown}>
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    </Field>
  )
}

export default PasswordField
