import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  TableSortLabel,
  Tooltip,
  MenuItem,
  TablePagination,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspUserSearchResult } from '../../../../../models'
import TelegramIcon from '@mui/icons-material/Telegram'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { useCreateInvitationMutation } from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import { visuallyHidden } from '@mui/utils'
import { usePopover } from '../../../../../components/custom-popover'
import Iconify from '../../../../../components/iconify'
import CustomPopover from '../../../../../components/custom-popover/custom-popover'
import Label from '../../../../../components/label'
interface Props {
  searchUserData?: UspUserSearchResult[]
  loading: boolean
  deleteUser: (arg: { userId: string }) => void
}

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'loading',
  'spinner',
  'tableHead',
  'tableCellName',
  'telegramIconActive',
  'telegramIconInactive',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

export const UserTableBlock = ({ searchUserData, loading, deleteUser }: Props) => {
  const initialOrder = (storageGet('users_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('users_order_by') || 'Name') as keyof UspUserSearchResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspUserSearchResult>(initialOrderBy)
  const [selected, setSelected] = useState<any>(null)
  const popover = usePopover()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [createInvitation, { isSuccess: createInvitationIsSuccess }] = useCreateInvitationMutation()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = searchUserData

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleInvite = (UserID: number, Username: string) => {
    createInvitation({ sendInvitation: { eas_user_id: UserID, email: Username } })
  }

  const handleNavigate = (userID: string): void => {
    navigate(appRoute.UserAddEdit.replace(':userId', userID))
  }

  useEffect(() => {
    if (createInvitationIsSuccess) {
      dispatch(enqueueNotification({ message: 'Invitation have been sent' }))
    }
  }, [createInvitationIsSuccess])

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspUserSearchResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('users_order', isAsc ? 'desc' : 'asc')
    storageSet('users_order_by', property)
  }

  const createSortHandler = (property: keyof UspUserSearchResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Name' ? order : false}>
                <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                  Name
                  {orderBy === 'Name' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Username' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Username'}
                  direction={orderBy === 'Username' ? order : 'asc'}
                  onClick={createSortHandler('Username')}
                >
                  Username
                  {orderBy === 'Username' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'UserCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'UserCode'}
                  direction={orderBy === 'UserCode' ? order : 'asc'}
                  onClick={createSortHandler('UserCode')}
                >
                  User Code
                  {orderBy === 'UserCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsRegisteredUser' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsRegisteredUser'}
                  direction={orderBy === 'IsRegisteredUser' ? order : 'asc'}
                  onClick={createSortHandler('IsRegisteredUser')}
                >
                  Registered
                  {orderBy === 'IsRegisteredUser' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsActive' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsActive'}
                  direction={orderBy === 'IsActive' ? order : 'asc'}
                  onClick={createSortHandler('IsActive')}
                >
                  Status
                  {orderBy === 'IsActive' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData as any[])
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow key={item?.UserID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className={classes.tableCellName} onClick={() => handleNavigate(item?.UserID?.toString() || '')}>
                    {item.Name}
                  </TableCell>
                  <TableCell>{item?.Username}</TableCell>
                  <TableCell>{item?.UserCode}</TableCell>
                  <TableCell>{item?.IsRegisteredUser ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <Label color={item.IsActive ? 'success' : 'error'}>{item.IsActive ? 'Active' : 'Inactive'}</Label>
                  </TableCell>
                  <TableCell align={'right'}>
                    {/* className={false ? classes.telegramIconActive : classes.telegramIconInactive} */}

                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setSelected(item)
                        popover.onOpen(event)
                      }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {searchUserData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={6} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Paper>
          <TablePagination
            component='div'
            count={searchUserData?.length as number}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          disabled={(!selected?.IsActive as boolean) || (selected?.IsRegisteredUser as unknown as boolean)}
          onClick={(e) => {
            handleInvite(selected?.UserID as number, selected?.Username as string)
            popover.onClose()
          }}
        >
          <TelegramIcon color='success' />
          Invite User
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            handleNavigate(selected?.UserID?.toString() as string)
            popover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          disabled={!selected?.IsActive}
          onClick={() => {
            deleteUser({ userId: selected?.UserID?.toString() as string })
            popover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}
