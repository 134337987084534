import dayjs from 'dayjs'
import * as yup from 'yup'

const today = dayjs().endOf('day')

export const userLabels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  nickName: 'Preferred Name',
  businessName: 'Business Name',
  userCode: 'User Short Code',
  username: 'Username',
  email: 'Email',
}

export const userValidation: yup.SchemaOf<any> = yup
  .object({
    email: yup.string().email().nullable().label(userLabels.email),
    userCode: yup.string().required().max(10).nullable().label(userLabels.userCode),
    username: yup.string().required().email().nullable().label(userLabels.username),
    dateOfBirth: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
    firstName: yup.string().when('accountType', {
      is: 'individual',
      then: yup.string().required().label(userLabels.firstName),
      otherwise: yup.string().nullable().notRequired(),
    }),
    lastName: yup.string().when('accountType', {
      is: 'individual',
      then: yup.string().required().label(userLabels.lastName),
      otherwise: yup.string().nullable().notRequired(),
    }),
    businessName: yup.string().when('accountType', {
      is: 'business',
      then: yup.string().required().label(userLabels.businessName),
      otherwise: yup.string().nullable().notRequired(),
    }),
  })
  .required()
