import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useForm } from 'react-final-form'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../services'
import AddressField from '../../../../../ui/AddressField'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}
const AddressForm = ({}: Props) => {
  const states = useSelector(getStates)
  const form = useForm()
  const addressValue = form.getFieldState('Address1')?.value || ''
  const [getInfoByZipCode, { data: infoByZipCodeData }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue
      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('Address1', street)
      if (city) form.change('City', city)
      if (state) form.change('State', state)
      if (zip) form.change('PostalCode', zip)
      if (infoByZipCodeData) {
        form.change('County', infoByZipCodeData?.County)
      }
    }
  }, [addressValue, form, infoByZipCodeData])

  return (
    <>
      <CardHeader title='Address (Optional)' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <AddressField name='Address1' label='Address Line 1' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Address2' label='Address Line 2' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField name='City' label='City' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='State'
            label='State'
            options={states}
            optionKey='State'
            optionValue='State'
            autoComplete='off'
            // optionNone
          />
        </Grid>
        <Grid item xs={2}>
          <TextField number name='PostalCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField name='County' label='County' options={states} fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default AddressForm
