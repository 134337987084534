import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { AmountField, CustomCircular, DateField, DropdownCheckboxes, TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { useGetAccountingChartOfAccountCurrentMutation } from '../../../../../services'

const classes = generateUtilityClasses('form', ['root', 'row'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    maxWidth: '375px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
}

const FilterModal = ({ open, handleClose }: Props) => {
  const branchData = useSelector(getBranch)
  const [searchChartOfAccounts, { data: chartData }] = useGetAccountingChartOfAccountCurrentMutation()

  const { values } = useFormState()

  useEffect(() => {
    searchChartOfAccounts({
      uspChartOfAccountView: {
        currentID: null,
        isAllowedSelectControlledAccount: true,
        isIncludeAccountsThatCannotBeSelected: true,
        isReturnAllCompanyTypes: false,
      },
    })
  }, [])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Filter</DialogTitle>
      <form>
        <DialogContent>
          <Grid container spacing={2} py={1}>
            <Grid item xs={12}>
              <DropdownFieldSearch
                name='dateRange'
                label='Date'
                options={[
                  { key: 'between', value: 'Between' },
                  { key: 'today', value: 'Today' },
                  { key: 'yesterday', value: 'Yesterday' },
                  { key: 'lastSevenDays', value: 'Last Seven Days' },
                  { key: 'lastThirtyDays', value: 'Last Thirty Days' },
                ]}
              />
            </Grid>
            {values?.dateRange === 'between' && (
              <>
                <Grid item xs={12}>
                  <DateField name='startDate' label='Start Date' fullWidth disableFuture />
                </Grid>
                <Grid item xs={12}>
                  <DateField name='endDate' label='End Date' fullWidth disableFuture />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <DropdownCheckboxes
                name='branch'
                label='Branch'
                labelId='BranchId'
                fullWidth
                options={branchData}
                optionKey='BranchID'
                optionValue='Branch'
              />
            </Grid>
            <Grid item xs={12}>
              <DropdownCheckboxes
                name='chartOfAccount'
                label='Chart Of Account'
                labelId='BranchId'
                fullWidth
                options={chartData as any}
                optionKey='ChartOfAccountID'
                optionValue='AccountNumberName'
              />
            </Grid>

            <Grid item xs={12}>
              <AmountField variant='standard' name='AmountStart' label='Amount (Start)' fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField name='TransactionStart' label='Transaction # (Start)' fullWidth />
            </Grid>
            <Grid item xs={12}>
              <AmountField variant='standard' name='AmountEnd' label='Amount (End)' fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField name='TransactionEnd' label='Transaction # (End)' fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField name='StockNumber' label='Stock Number/VIN' fullWidth />
            </Grid>
            <Grid item xs={12}>
              <TextField name='User' label='User' fullWidth />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' type='submit'>
            Run
          </Button>
        </DialogActions>
        <CustomCircular open={false} />
      </form>
    </Dialog>
  )
}

export default FilterModal
