import { Button, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../constants'
import { Header } from './components/Header'
import AddIcon from '@mui/icons-material/Add'
import { useLazyGetDealCommissionQuery, useLazyGetTaskDealQuery } from '../../../services'
import DealsCommissionsTableBlock from './components/DealsCommissionsTableBlock/DealsCommissionsTableBlock'
import DealsCommissionsAddEdit from './components/DealsCommissionsAddEdit/DealsCommissionsAddEdit'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Grid', ['root', 'btnWrap', 'btnCancelWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.btnWrap}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 3, 0, 3),
  },
  [`& .${classes.btnCancelWrap}`]: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 3, 3, 3),
  },
}))

interface Props {}

const DealsCommissions = ({}: Props) => {
  const { dealID } = useParams()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [getDealCommissions, { data: commissionsData, isLoading }] = useLazyGetDealCommissionQuery()
  const [getTaskDeal, { data: taskDeal }] = useLazyGetTaskDealQuery()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    getTaskDeal({ dealId: dealID as string })
    getDealCommissions({ dealId: dealID as string })
  }, [])

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget)
  }

  const onSuccessCommissionDeal = () => {
    dispatch(enqueueNotification({ message: 'Commission added successfully.' }))
    getDealCommissions({ dealId: dealID as string })
  }

  const onSuccessEditCommissionDeal = () => {
    dispatch(enqueueNotification({ message: 'Commission edited successfully.' }))
    getDealCommissions({ dealId: dealID as string })
  }

  const onDeleteCommissionSuccess = () => {
    dispatch(enqueueNotification({ message: 'Commission deleted successfully.' }))
    getDealCommissions({ dealId: dealID as string })
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <Header taskDeal={taskDeal} />
        <Grid className={classes.btnWrap}>
          <Typography variant='h3'>Commissions</Typography>
          <Button
            variant='contained'
            startIcon={<AddIcon />}
            onClick={(e) => {
              handleSubmit(e)
            }}
          >
            Add new
          </Button>
        </Grid>
        <DealsCommissionsTableBlock
          commissionsData={commissionsData}
          loading={isLoading}
          onSuccessCommissionDeal={onSuccessCommissionDeal}
          onSuccessEditCommissionDeal={onSuccessEditCommissionDeal}
          onDeleteCommissionSuccess={onDeleteCommissionSuccess}
        />
        <Grid item xs={12} className={classes.btnCancelWrap}>
          <Button variant='contained' color='error' onClick={() => navigate(appRoute.DealsTask.replace(':dealID', dealID as string))}>
            Close
          </Button>
        </Grid>
      </StyledGrid>
      <DealsCommissionsAddEdit
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        type='add'
        onSuccessCommissionDeal={onSuccessCommissionDeal}
        onSuccessEditCommissionDeal={onSuccessEditCommissionDeal}
      />
    </>
  )
}

export default DealsCommissions
