import React, { useCallback, useEffect, useState } from 'react'
import { Button, Container, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { useDispatch, useSelector } from 'react-redux'
import { getStates } from '../../../../../../redux/slices/userDropdownSlice'
import SettingsModal from '../SettingsModal/SettingsModal'
import { useForm, useFormState } from 'react-final-form'
import { useUploadFormPDFMutation } from '../../../../../../services'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../../constants'
import { enqueueNotification } from '../../../../../../redux/slices/notificationsSlice'
import { debounce } from 'lodash'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import TextField from '../../../../../../ui/TextField'
import CheckboxField from '../../../../../../ui/CheckboxField'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'searchWrap', 'searchBtn'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 1, 1, 1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    alignItems: 'center',
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },

  [`& .${classes.searchBtn}`]: {
    margin: theme.spacing(0, 2, 0, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minWidth: '90px',
    padding: '10px',
  },
}))

interface Props {
  submit: (...args: any) => any
}

const FormsSearchBlock = ({ submit }: Props) => {
  const form = useForm()
  const { values } = useFormState()
  const dispatch = useDispatch()
  const states = useSelector(getStates)
  const navigate = useNavigate()
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadFile, { data, isSuccess, isLoading }] = useUploadFormPDFMutation()

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file)
      // Remove .pdf extension from file name
      const fileNameWithoutExtension = file.name.replace(/\.pdf$/i, '')

      setOpenSettingsModal(true)
      form.change('Name', fileNameWithoutExtension)
    } else {
      dispatch(enqueueNotification({ message: 'Please select a PDF file.' }))
    }
  }

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false)
  }

  const handleSelectPDF = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement
    if (fileInput) fileInput.click()
  }

  const handleUploadPDF = (values?: any) => {
    uploadFile({
      file: selectedFile,
      dataSource: values?.DataSource,
      formState: values?.FormState,
      name: values?.Name,
      version: values?.Version,
      isActive: true,
    })
  }

  const updateWithDebounce = useCallback(
    debounce(() => {
      submit(values)
    }, 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.SearchField || values?.SearchField !== '') {
      updateWithDebounce()
    }
  }, [values?.SearchField])

  useEffect(() => {
    // search with debounce
    if (values?.State || values?.State !== '') {
      updateWithDebounce()
    }
  }, [values?.State])

  useEffect(() => {
    // search with debounce
    if (values?.DataSourceSearch || values?.DataSourceSearch !== '') {
      updateWithDebounce()
    }
  }, [values?.DataSourceSearch])

  useEffect(() => {
    // search with debounce
    if (values?.IncludeInactive || !values?.IncludeInactive) {
      updateWithDebounce()
    }
  }, [values?.IncludeInactive])

  useEffect(() => {
    if (values?.SearchField || values?.State || values?.IncludeInactive || values?.DataSourceSearch) {
      updateWithDebounce()
    }
  }, [values?.SearchField, values?.State, values?.IncludeInactive, values?.DataSourceSearch])

  useEffect(() => {
    if (isSuccess) {
      navigate(appRoute?.SettingsFormsMapping.replace(':formId', data?.ID?.toString() as string))
    }
  }, [isSuccess])

  return (
    <>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={3}>
          <DropdownFieldSearch name='State' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>

        <Grid item xs={3}>
          <DropdownFieldSearch
            options={[
              { key: 'Deal', value: 'Deal' },
              { key: 'Inventory', value: 'Inventory' },
              { key: 'Lead', value: 'Lead' },
            ]}
            name='DataSourceSearch'
            label='Data Source'
            fullWidth
          />
        </Grid>

        <Grid item xs={3}>
          <TextField name='SearchField' placeholder='Search' fullWidth />
        </Grid>

        <Grid item xs={3}>
          <CheckboxField name='IncludeInactive' label='Include Inactive' />
        </Grid>
        {/* 
        <Button type='button' className={classes.searchBtn} variant='contained' startIcon={<AddIcon />} onClick={handleSelectPDF}>
          Add New
        </Button>
        <input type='file' id='fileInput' style={{ display: 'none' }} accept='.pdf' onChange={handleFileChange} /> */}
      </Grid>
      <SettingsModal
        handleFileChange={handleFileChange}
        handleUploadPDF={handleUploadPDF}
        open={openSettingsModal}
        handleClose={handleCloseSettingsModal}
      />
    </>
  )
}

export default FormsSearchBlock
