import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const TitleForm = ({}: Props) => {
  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Title</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='IncludeinSalespersonDropdowns' label='Include in Salesperson Dropdowns' />
      </Grid>
      <Grid>
        <CheckboxField name='IncludeinFIManagerDropdowns' label='Include in F&I Manager Dropdowns' />
      </Grid>
      <Grid>
        <CheckboxField name='IncludeinCollectorDropdowns' label='Include in Collector Dropdowns' />
      </Grid>
      <Grid>
        <CheckboxField name='IncludeinManagerDropdowns' label='Include in Manager Dropdowns' />
      </Grid>
    </StyledGrid>
  )
}

export default TitleForm
