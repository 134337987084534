import React from 'react'
import { styled } from '@mui/system'
import { Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { TextField } from '../../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const NameForm = ({}: Props) => {
  return (
    <StyledGrid className={classes.root}>
      <Grid className={classes.row}>
        <TextField required name='RoleName' label='Role Name' fullWidth />
      </Grid>
      <Grid className={classes.row}>
        <DropdownFieldSearch
          name='IsActive'
          label='Is Active?'
          fullWidth
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
        />
      </Grid>
    </StyledGrid>
  )
}

export default NameForm
