import { GetCreditAppApiArg, GetCreditAppApiResponse } from '../../models'
import { baseApi } from '../baseApi'
import {
  MarkDealAsLostApiArg,
  MarkDealAsLostApiResponse,
  PullUpCreditDataApiArg,
  PullUpCreditDataApiResponse,
  ReopenLeadApiArg,
  ReopenLeadApiResponse,
} from './types'

const leadsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getCreditApp: build.query<GetCreditAppApiResponse, GetCreditAppApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/${queryArg.creditAppId}/`,
      }),
      providesTags: ['Leads'],
    }),
    markLeadAsLost: build.mutation<MarkDealAsLostApiResponse, MarkDealAsLostApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/lost/${queryArg.leadId}/${queryArg.reasonId}/`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Leads'],
    }),
    leadReopen: build.mutation<ReopenLeadApiResponse, ReopenLeadApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/reopen/${queryArg.leadId}/`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Leads'],
    }),
    pullUpCreditData: build.query<PullUpCreditDataApiResponse, PullUpCreditDataApiArg>({
      query: (queryArg) => ({
        url: queryArg.dealId ? `/700credit/${queryArg.leadId}/${queryArg.dealId}/` : `/700credit/${queryArg.leadId}`,
      }),
    }),
  }),
})

export const { useMarkLeadAsLostMutation, useLazyGetCreditAppQuery, useGetCreditAppQuery, useLeadReopenMutation, useLazyPullUpCreditDataQuery } =
  leadsApi
