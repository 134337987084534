import * as yup from 'yup'
import { SignUpFormType } from '../models'

export const signUpLabels: SignUpFormType = {
  email: 'Email',
  password: 'Password',
  confirmPassword: 'Confirm Password',
}

export const signUpValidation = yup
  .object({
    email: yup.string().nullable().required().email().label(signUpLabels.email),
    password: yup
      .string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      )
      .label(signUpLabels.password),
    confirmPassword: yup
      .string()
      .required('Please confirm your password')
      .when('password', {
        is: (password: any) => (password && password.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref('password')], "Password doesn't match")
          .label(signUpLabels.confirmPassword),
      }),
  })
  .required()
