import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Card, Container, generateUtilityClasses } from '@mui/material'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import Header from './components/Header/Header'
import AddressForm from './components/AddressForm/AddressForm'
import LenderForm from './components/LenderForm/LenderForm'
import ContactForm from './components/ContactForm/ContactForm'
import IdentityForm from './components/IdentityForm/IdentityForm'
import SettingsForm from './components/SettingsForm/SettingsForm'
import FeesForm from './components/FeesForm/FeesForm'
import { useAddLenderMutation, useEditLenderMutation, useGetContactMutation, useLazyGetLenderQuery } from '../../../services'
import { withPermissionsDropdown } from '../../../hoc'
import { transformToFormValues, transformToRequestValues } from './transform'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('Box', ['root', 'firstRow', 'secondRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.firstRow}`]: {
    minHeight: '300px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.secondRow}`]: {
    minHeight: '360px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const AddEditLender = () => {
  const { lenderId } = useParams()
  const [initialValues, setInitialValues] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [getLenderData, { data: lenderData }] = useLazyGetLenderQuery()
  const [getContacts, { data: contactsData }] = useGetContactMutation()
  const [addLender, { isSuccess: isSuccessLenderDataAdd }] = useAddLenderMutation()
  const [editLender, { isSuccess: isSuccessLenderDataEdit }] = useEditLenderMutation()

  const handleSubmit = (values: any) => {
    let finalValues = { ...values }
    if (values?.DiscountType === 'rate') {
      finalValues.DiscountAmount = 0
    } else if (values?.DiscountType === 'amount') {
      finalValues.DiscountRate = 0
    }

    if (lenderId === 'add') {
      addLender({
        uspLenderAdd: transformToRequestValues(finalValues) as any,
      })
    } else {
      editLender({
        lenderId: lenderId as string,
        uspLenderEdit: transformToRequestValues(finalValues) as any,
      })
    }
  }

  useEffect(() => {
    if (lenderId !== 'add') {
      getLenderData({ lenderId: lenderId as string })
    }
  }, [lenderId])

  useEffect(() => {
    if (lenderData) {
      getContacts({ contactId: lenderData?.ContactID.toString() as string })
    }
  }, [lenderData])

  useEffect(() => {
    setInitialValues(transformToFormValues(lenderData, contactsData, lenderId))
  }, [lenderData, contactsData])

  useEffect(() => {
    if (isSuccessLenderDataEdit || isSuccessLenderDataAdd) {
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(appRoute.LendersList)
    }
  }, [isSuccessLenderDataEdit, isSuccessLenderDataAdd])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Lender'
        links={[
          {
            name: 'List',
            href: appRoute.LendersList,
          },
          { name: 'Lender' },
        ]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <Card
              sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}
            >
              <Grid container spacing={2} p={3}>
                {/* <Header /> */}
                <Grid item xs={12}>
                  <LenderForm />
                </Grid>
                <Grid item xs={12}>
                  <AddressForm />
                </Grid>
                <Grid item xs={12}>
                  <ContactForm />
                </Grid>
                <Grid item xs={12}>
                  <IdentityForm />
                </Grid>
                <Grid item xs={12}>
                  <SettingsForm />
                </Grid>
                <Grid item xs={12}>
                  <FeesForm />
                </Grid>
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'sticky',
                  bottom: 16,
                  right: 20,
                  gap: 2,
                  p: 3,
                  width: 'fit-content',
                  alignSelf: 'flex-end',
                }}
              >
                <Button variant='contained' color='error' onClick={() => navigate(appRoute?.LendersList)}>
                  Close
                </Button>
                <Button type='submit' className={classes.saveBtn} variant='contained'>
                  Save & Close
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Form>
      {/* <CustomCircular open={isLoading} /> */}
    </Container>
  )
}

export default withPermissionsDropdown(AddEditLender)
