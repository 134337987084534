import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { DeleteVendorApiArg } from '../../../models'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchVendorMutation, useDeleteVendorMutation } from '../../../services'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import ConfirmDialog from '../../../components/ConfirmDialog'
import VendorSearchBlock from './components/VendorSearchBlock/VendorSearchBlock'
import VendorTableBlock from './components/VendorTableBlock/VendorTableBlock'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../constants'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

type TFormFields = {
  searchString: string
  isIncludeInactive: boolean
}

const VendorList = () => {
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState<TFormFields>()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ vendorId: string } | undefined>(undefined)
  const [searchVendor, { data: searchVendorData, isLoading: searchVendorIsLoading }] = useSearchVendorMutation()
  const [deleteVendor, { isSuccess: deleteVendorIsSuccess }] = useDeleteVendorMutation()

  const handleSubmit = (values: TFormFields) => {
    setFormValues(values)
    searchVendor({
      simpleSearch: {
        searchString: values.searchString || null,
        isIncludeInactive: values.isIncludeInactive,
      },
    })
  }

  const handleDelete = () => {
    deleteVendor(deleteConfirmDialog as DeleteVendorApiArg)
    setDeleteConfirmDialog(undefined)
  }

  useEffect(() => {
    if (deleteVendorIsSuccess) {
      dispatch(enqueueNotification({ message: 'Delete Successful' }))
      searchVendor({
        simpleSearch: {
          searchString: formValues?.searchString || null,
          isIncludeInactive: formValues?.isIncludeInactive,
        },
      })
    }
  }, [deleteVendorIsSuccess])

  useEffect(() => {
    if (!userAccess.CanAccessVendors) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Vendor'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button
            onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
            variant='contained'
            startIcon={<Iconify icon='mingcute:add-line' />}
          >
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ searchString: '', isIncludeInactive: false }} // require to avoid first debounce search
      >
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VendorSearchBlock submit={submit} />
                </Grid>
                <Grid item xs={12}>
                  <VendorTableBlock deleteVendor={setDeleteConfirmDialog} loading={searchVendorIsLoading} searchVendorData={searchVendorData} />
                </Grid>
              </Grid>
            </Card>
            <ConfirmDialog
              open={!!deleteConfirmDialog}
              onOk={handleDelete}
              handleClose={() => setDeleteConfirmDialog(undefined)}
              notification={'Vendor will be removed'}
            />
          </form>
        )}
      </Form>
    </Container>
  )
}

export default VendorList
