import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField, TextField } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { selectDropdownByName } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}
const BankruptcyCard = ({ onDropdownFormChange, disableFields }: Props) => {
  const bankruptcyType = useSelector((state) => selectDropdownByName(state, 'BankruptcyType'))

  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='IsBankruptcy'
          label='Is Bankruptcy?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      {bankruptcyType && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='Type'
            label='Type'
            options={bankruptcyType?.ReturnData}
            optionKey='Chapter'
            optionValue='Description'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='FillingDate' label='Filling Date' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='HearingDate' label='Hearing Date' fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextField disabled={disableFields} name='CaseNumber' label='Case Number' fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextField disabled={disableFields} name='Attorney' label='Attorney' fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextField disabled={disableFields} name='Trustee' label='Trustee' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='DischargeDate' label='Discharge Date' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='DismissalDate' label='Dismissal Date' fullWidth />
      </Grid>
    </Grid>
  )
}

export default BankruptcyCard
