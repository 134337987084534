import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Header } from '../components/Header'
import { TextField, Typography, generateUtilityClasses } from '@mui/material'
import { withCars } from '../../../hoc'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
  },
}))

export const InventoryEditDocuments = (props: any) => {
  const { carData } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formChanged, setFormChanged] = useState(false)

  // useEffect(() => {
  //   dispatch(enqueueNotification({ message: 'Coming soon' }))
  //   navigate(-1)
  // }, [])

  return (
    <StyledGrid className={classes.root}>
      {/* <Header
        modelYear={carData?.ModelYear}
        make={carData?.Make}
        model={carData?.Model}
        vin={carData?.VIN}
        stockNumber={carData?.StockNumber}
        carIsSuccess={props?.carIsSuccess}
        carData={props?.carData}
        getCar={props?.getCar}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        onDropdownFormChange={() => setFormChanged(true)}
      /> */}
      <h1>Documents</h1>
      <Typography variant='h4'>Coming Soon ...</Typography>
    </StyledGrid>
  )
}

export default withCars(InventoryEditDocuments)
