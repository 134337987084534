import * as yup from 'yup'
import { parseToNumber } from '../utils/general'

export const accountingJournalEntriesValidation = (isModalOpen: boolean) => {
  return yup
    .object({
      EntryType: yup.string().required(),
      EntryDate: yup.date().when('EntryType', {
        is: 'Ledger Only',
        then: yup.date().notRequired(),
        otherwise: yup.date().required().typeError('Invalid Date'),
      }),
      Amount: isModalOpen
        ? yup
            .number()
            .transform((value, originalValue) => (originalValue === '' ? null : parseToNumber(originalValue)))
            .min(0.01, 'Amount must be greater than 0.00')
            .typeError('Invalid number')
            .required()
        : yup.mixed().notRequired(),

      ...(isModalOpen
        ? {
            Branch: yup.string().when('EntryType', {
              is: 'Ledger Only',
              then: yup.string().notRequired(),
              otherwise: yup.string().required().label('Branch').typeError('Invalid Input'),
            }),
            ChartOfAccountBill: yup.string().when('EntryType', {
              is: 'Ledger Only',
              then: yup.string().notRequired(),
              otherwise: yup.string().required().label('Chart Of Account').typeError('Invalid Input'),
            }),
          }
        : {}),
    })
    .required()
}
