import * as yup from 'yup'
import dayjs from 'dayjs'


export const accountsAddExtensionValidation = () => {
  let schema = yup.object().shape({})

    schema = schema.shape({
        // extensionNote: yup.string().label('Extension Note').required(),
        extensionDate: yup
        .date()
        .label('Extenstion Date')
        .required(),
        effectiveDate: yup
        .date()
        .label('Effective Date')
        .required()
    })
  
  return schema
}
