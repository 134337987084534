import React from 'react'
import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { TextField } from '../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
}))

const PaymentNoteCard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h6'>Payment Note</Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField multiline name='Comment' label='Comment' fullWidth autoComplete='off' />
      </Grid>
    </Grid>
  )
}

export default PaymentNoteCard
