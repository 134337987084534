import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { styled } from '@mui/system'
import { Grid, IconButton, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import { DateField, PercentField, TextField } from '../../../../../../../ui'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AmountField from '../../../../../../../ui/AmountField'
import Iconify from '../../../../../../../components/iconify'
import FeePopover from '../../../DealStructure/components/FeePopover'
import { useLazyGetListFeeQuery } from '../../../../../../../services'
import { UspGetDataStateDealSettingsResult } from '../../../../../../../models'
import { useParams } from 'react-router-dom'
import PaymentsPopover from '../../../DealStructure/components/PaymentsPopover'
import removeMask from '../../../../../../../utils/removeMask'
import { round } from 'lodash'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'switchWrap', 'optionalText', 'rowBtn', 'printBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.switchWrap}`]: {
    margin: theme.spacing(0, 0, 0, 5),
    padding: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  stateDealSettingsData?: UspGetDataStateDealSettingsResult | null
  feesForm: any[]
  paymentsForm: string[]
  setTotalPickups?: Dispatch<SetStateAction<boolean>>
  formPayments: string[]
  setFormPayments: Dispatch<SetStateAction<string[]>>
  setPaymentsForm: Dispatch<SetStateAction<string[]>>
  setIsDownPaymentDeleteCalled: Dispatch<SetStateAction<boolean>>
}

export const ResultForm = ({
  stateDealSettingsData,
  feesForm,
  paymentsForm,
  setTotalPickups,
  formPayments,
  setFormPayments,
  setPaymentsForm,
  setIsDownPaymentDeleteCalled,
}: Props) => {
  const { values } = useFormState()
  const { change } = useForm()
  const { dealID, leadID } = useParams()

  const [anchorElFee, setAnchorElFee] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElPickup, setAnchorElPickup] = React.useState<HTMLButtonElement | null>(null)
  const [getListFee, { data: listFeeData, isLoading: listFeeIsLoading }] = useLazyGetListFeeQuery()
  useEffect(() => {
    if (leadID === 'add') {
      getListFee({})
    }
  }, [])
  useEffect(() => {
    change('taxOnCashDown', round((Number(removeMask(values.CashDown)) * Number(removeMask(values.total_tax))) / 100, 2).toString())
    let taxOnFee = 0
    Object?.keys(values)?.forEach((item: string) => {
      // get sum of all fee
      // console.log(feesForm.find((el) => el.fieldName == item))?.isTaxable
      if (feesForm.find((el) => el.fieldName == item) && feesForm.find((el) => el.fieldName == item)?.isTaxable) {
        taxOnFee = taxOnFee + Number(removeMask(values[item]))
      }
    })
    change('taxOnFees', round((taxOnFee * Number(removeMask(values.total_tax))) / 100, 2).toString())
    change(
      'totalDue',
      (
        Number(removeMask(values.taxOnFees)) +
        Number(removeMask(values.CashDown)) +
        Number(removeMask(values.taxOnCashDown)) +
        Number(removeMask(values.securityDeposit)) +
        Number(removeMask(values.FeesResult)) +
        Number(removeMask(values.taxOnFees)) +
        Number(removeMask(values.otherFee))
      ).toString(),
    )
  }, [values])

  const handleClickFee = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFee(event?.currentTarget)
  }

  const handleClickPickup = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPickup(event?.currentTarget)
  }

  const computePickupPayments = (values: { [key: string]: any }) => {
    let amount = 0
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('DatePaymentAmount') && values[item]) {
        amount += Number(removeMask(values[item]))
      }
    })
    return amount?.toString()
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid container>
          <Grid className={classes.row}>
            <Typography variant='subtitle1' gutterBottom>
              Due at Signing
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            variant='standard'
            disabled
            name='CashDown'
            label='Cash Down'
            startAdornment={<Iconify icon='iconoir:dollar' style={{ color: '#c4c0c0' }} />}
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            variant='standard'
            disabled
            name='taxOnCashDown'
            label='Tax on Cash Down'
            startAdornment={<Iconify icon='iconoir:dollar' style={{ color: '#c4c0c0' }} />}
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' name='securityDeposit' label='Security Deposit' fullWidth />
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            variant='standard'
            name='FeesResult'
            label='Fees'
            fullWidth
            readonly
            endAdornment={
              <IconButton color='primary' onClick={handleClickFee}>
                <Iconify icon='solar:pen-bold' />
              </IconButton>
            }
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            variant='standard'
            disabled
            name='taxOnFees'
            label='Tax on Fees'
            startAdornment={<Iconify icon='iconoir:dollar' style={{ color: '#c4c0c0' }} />}
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' name='otherFee' label='Other Fee' fullWidth />
        </Grid>
        <Grid className={classes.row}>
          <TextField variant='outlined' name='otherFeeDesc' label='Other Fee Description' fullWidth />
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              change('payingToday', (Number(removeMask(e.target.value)) - Number(removeMask(values.PickupPayments))).toString())
            }}
            variant='standard'
            disabled
            name='totalDue'
            label='Total Due'
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            variant='standard'
            name='PickupPayments'
            label='Pickup Payments'
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              change('payingToday', (Number(removeMask(values.totalDue)) - Number(removeMask(e.target.value))).toString())
            }}
            readonly
            endAdornment={
              <IconButton color='primary' onClick={handleClickPickup}>
                <Iconify icon='solar:pen-bold' />
              </IconButton>
            }
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='payingToday' label='Paying Today' fullWidth />
        </Grid>
      </StyledGrid>
      <FeePopover
        anchorEl={anchorElFee}
        setAnchorEl={setAnchorElFee}
        listFeeData={listFeeData}
        feesForm={feesForm}
        stateDealSettingsData={stateDealSettingsData}
        name={'FeesResult'}
      />
      <PaymentsPopover
        setIsDownPaymentDeleteCalled={setIsDownPaymentDeleteCalled}
        setTotalPickups={setTotalPickups}
        computePickupPayments={computePickupPayments}
        anchorEl={anchorElPickup}
        setAnchorEl={setAnchorElPickup}
        paymentsForm={paymentsForm}
        formPayments={formPayments}
        setFormPayments={setFormPayments}
        setPaymentsForm={setPaymentsForm}
      />
    </>
  )
}

export default ResultForm
