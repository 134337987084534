import React from 'react'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { UspReportTradeLienResult } from '../../../../../models'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { formatNumber } from '../../../../../utils/general'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportTradeLienResult[]
  isLoading: boolean
}

const TradeLiensTableBlock = ({ filterData, isLoading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('reports_trade_liens_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('reports_trade_liens_order_by') || 'Branch') as keyof UspReportTradeLienResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspReportTradeLienResult>(initialOrderBy)

  const handleEdit = (id: number | string) => {
    if (id) {
      navigate(appRoute.InventoryEdit.replace(':carID', String(id)))
    }
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportTradeLienResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('reports_trade_liens_order', isAsc ? 'desc' : 'asc')
    storageSet('reports_trade_liens_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportTradeLienResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock #
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Buyer' ? order : false}>
              <TableSortLabel active={orderBy === 'Buyer'} direction={orderBy === 'Buyer' ? order : 'asc'} onClick={createSortHandler('Buyer')}>
                Buyer
                {orderBy === 'Buyer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Deal' ? order : false}>
              <TableSortLabel active={orderBy === 'Deal'} direction={orderBy === 'Deal' ? order : 'asc'} onClick={createSortHandler('Deal')}>
                Deal
                {orderBy === 'Deal' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Finalized' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Finalized'}
                direction={orderBy === 'Finalized' ? order : 'asc'}
                onClick={createSortHandler('Finalized')}
              >
                Finalized
                {orderBy === 'Finalized' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Lienholder' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Lienholder'}
                direction={orderBy === 'Lienholder' ? order : 'asc'}
                onClick={createSortHandler('Lienholder')}
              >
                Lienholder
                {orderBy === 'Lienholder' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TradeStockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TradeStockNumber'}
                direction={orderBy === 'TradeStockNumber' ? order : 'asc'}
                onClick={createSortHandler('TradeStockNumber')}
              >
                Trade Stock Number
                {orderBy === 'TradeStockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TradeVehicle' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TradeVehicle'}
                direction={orderBy === 'TradeVehicle' ? order : 'asc'}
                onClick={createSortHandler('TradeVehicle')}
              >
                Trade Vehicle
                {orderBy === 'TradeVehicle' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Lien' ? order : false}>
              <TableSortLabel active={orderBy === 'Lien'} direction={orderBy === 'Lien' ? order : 'asc'} onClick={createSortHandler('Lien')}>
                Lien
                {orderBy === 'Lien' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'UnpaidLien' ? order : false}>
              <TableSortLabel
                active={orderBy === 'UnpaidLien'}
                direction={orderBy === 'UnpaidLien' ? order : 'asc'}
                onClick={createSortHandler('UnpaidLien')}
              >
                Unpaid Lien
                {orderBy === 'UnpaidLien' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'GoodUntilDate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'GoodUntilDate'}
                direction={orderBy === 'GoodUntilDate' ? order : 'asc'}
                onClick={createSortHandler('GoodUntilDate')}
              >
                Good Until Date
                {orderBy === 'GoodUntilDate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'PerDiem' ? order : false}>
              <TableSortLabel active={orderBy === 'PerDiem'} direction={orderBy === 'PerDiem' ? order : 'asc'} onClick={createSortHandler('PerDiem')}>
                Per Diem
                {orderBy === 'PerDiem' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className={classes.tableCellName} onClick={() => handleEdit(item.CarID as string)}>
                {item.StockNumber}
              </TableCell>
              <TableCell>{item.Branch}</TableCell>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))}
              >
                {item.Buyer}
              </TableCell>
              <TableCell>{convertDateToDisplay(item.Deal as string)}</TableCell>
              <TableCell>{convertDateToDisplay(item.Finalized as string)}</TableCell>
              <TableCell>{item.Lienholder}</TableCell>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.InventoryEdit.replace(':carID', item?.TradeCarID?.toString() as string))}
              >
                {item.TradeStockNumber}
              </TableCell>
              <TableCell>{item.TradeVehicle}</TableCell>
              <TableCell>{formatNumber(item.Lien as string)}</TableCell>
              <TableCell>{formatNumber(item.UnpaidLien as string)}</TableCell>
              <TableCell>{convertDateToDisplay(item.GoodUntilDate as string)}</TableCell>
              <TableCell>{item.PerDiem}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TradeLiensTableBlock
