import React, { Dispatch, SetStateAction } from 'react'
import { SearchWorklistApiResponse, UspCollectionWorklistAccountViewResult } from '../../../../models'
import {
  Box,
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Checkbox,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../utils/storage'
import { getComparator, stableSort } from '../../../../utils/sorting'
import { appRoute } from '../../../../constants'
import { convertDateToDisplay } from '../../../../utils/dateUtils'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useDispatch } from 'react-redux'
import { setWorklistAccountID, setWorklistID } from '../../../../redux/slices/collectionsSlice'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

const classes = generateUtilityClasses('Grid', ['root', 'loading', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },

  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  loading: boolean
  collectionsData: SearchWorklistApiResponse
  selected: number[]
  setSelected: Dispatch<SetStateAction<number[]>>
}
const CollectionsMyWorklistTableblock = ({ loading, collectionsData, selected, setSelected }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialOrder = (storageGet('collections_worklist_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('collections_worklist_order_by') || 'WorklistName') as keyof UspCollectionWorklistAccountViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspCollectionWorklistAccountViewResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCollectionWorklistAccountViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('collections_worklist_order', isAsc ? 'desc' : 'asc')
    storageSet('collections_worklist_order_by', property)
  }

  const createSortHandler = (property: keyof UspCollectionWorklistAccountViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleSelect = (id: number | null) => {
    if (id === null) return

    const newSelected = selected.includes(id) ? selected.filter((item) => item !== id) : [...selected, id]

    setSelected(newSelected)
  }

  const openInNewTab = (url: string, worklistAccountID: number | undefined, worklistID: number | undefined) => {
    localStorage.setItem('isRedirectedFromWorklist', 'true')
    const urlWithParams = `${url}?worklistAccountID=${worklistAccountID}&worklistID=${worklistID}`
    window.open(urlWithParams, '_blank', 'noopener,noreferrer')
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = collectionsData.map((item) => item.WorklistAccountID) as number[]
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox' className={classes.tableHead}>
                <Checkbox
                  indeterminate={selected?.length > 0 && selected?.length < collectionsData?.length}
                  checked={collectionsData?.length > 0 && selected?.length === collectionsData?.length}
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Name' ? order : false}>
                <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                  Name
                  {orderBy === 'Name' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'PhoneNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PhoneNumber'}
                  direction={orderBy === 'PhoneNumber' ? order : 'asc'}
                  onClick={createSortHandler('PhoneNumber')}
                >
                  Phone Number
                  {orderBy === 'PhoneNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountNumber'}
                  direction={orderBy === 'AccountNumber' ? order : 'asc'}
                  onClick={createSortHandler('AccountNumber')}
                >
                  Account #
                  {orderBy === 'AccountNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountStatus' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountStatus'}
                  direction={orderBy === 'AccountStatus' ? order : 'asc'}
                  onClick={createSortHandler('AccountStatus')}
                >
                  Status
                  {orderBy === 'AccountStatus' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'NextDueDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'NextDueDate'}
                  direction={orderBy === 'NextDueDate' ? order : 'asc'}
                  onClick={createSortHandler('NextDueDate')}
                >
                  Next Due
                  {orderBy === 'NextDueDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'DaysPastDue' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DaysPastDue'}
                  direction={orderBy === 'DaysPastDue' ? order : 'asc'}
                  onClick={createSortHandler('DaysPastDue')}
                >
                  Days Late
                  {orderBy === 'DaysPastDue' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsWorked' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsWorked'}
                  direction={orderBy === 'IsWorked' ? order : 'asc'}
                  onClick={createSortHandler('IsWorked')}
                >
                  Is Worked
                  {orderBy === 'IsWorked' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              {/* <TableCell className={classes.tableHead} align='right'>
                <TableSortLabel>Action</TableSortLabel>
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), collectionsData as any[]).map((item) => (
              <TableRow key={item.WorklistAccountID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    checked={selected.includes(item.WorklistAccountID as number)}
                    onChange={() => handleSelect(item.WorklistAccountID as number)}
                  />
                </TableCell>
                <TableCell
                  className={classes.tableCellName}
                  onClick={() => {
                    localStorage.setItem('isRedirectedFromWorklist', 'true')
                    dispatch(setWorklistAccountID(item?.WorklistAccountID))
                    dispatch(setWorklistID(item?.WorklistID))
                    navigate(appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))
                  }}
                >
                  {item.StockNumber}
                  <IconButton
                    color='primary'
                    sx={{ margin: 0, padding: '0 0 0 10px' }}
                    onClick={(event) => {
                      event?.stopPropagation() // This stops the event from bubbling up
                      openInNewTab(
                        appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string),
                        item?.WorklistAccountID as number,
                        item?.WorklistID as number,
                      )
                    }}
                  >
                    <OpenInNewIcon sx={{ width: '15px', height: '15px' }} />
                  </IconButton>
                </TableCell>
                <TableCell>{item.Name}</TableCell>
                <TableCell>{item.PhoneNumber}</TableCell>
                <TableCell>{item.AccountNumber}</TableCell>
                <TableCell>{item.AccountStatus}</TableCell>
                <TableCell>{convertDateToDisplay(item.NextDueDate as string)}</TableCell>
                <TableCell>{item.DaysPastDue}</TableCell>
                <TableCell>{item.BranchCode}</TableCell>
                <TableCell>{item.IsWorked ? 'Yes' : 'No'}</TableCell>
                {/* <TableCell align={'right'}>
                  <Tooltip title=''>
                    <IconButton color='primary'>
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell> */}
              </TableRow>
            ))}
            {collectionsData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default CollectionsMyWorklistTableblock
