import React from 'react'
import { Button, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'

const classes = generateUtilityClasses('Grid', ['root', 'titleWrap', 'searchBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchBtn}`]: {
    width: '120px',
    margin: theme.spacing(0, 2, 0, 'auto'),
  },
}))

interface Props {
  title: string
  handleOpenModal: () => void
}
const Header = ({ title, handleOpenModal }: Props) => {
  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid item className={classes.titleWrap}>
          <Typography variant='h3'>Form Pack - {title}</Typography>
        </Grid>

        <Button onClick={() => handleOpenModal()} className={classes.searchBtn} variant='contained' startIcon={<AddIcon />}>
          Add new
        </Button>
      </StyledGrid>
    </>
  )
}

export default Header
