import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

const DealPurchase = ({ onDropdownFormChange }: Props) => {
  return (
    <>
      <CardHeader title='When Deal Purchased' />
      <Grid container spacing={2} p={3}>
        <Grid item xs={12}>
          <DropdownFieldSearch
            name='CreateNewAccountNumber'
            label='Create New Account Number?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownFieldSearch
            name='TransferSalesperson'
            label='Transfer Salesperson?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownFieldSearch
            name='TransferFIManager'
            label='Transfer F&I Manager?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownFieldSearch
            name='TransferManager'
            label='Transfer Manager?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownFieldSearch
            name='TranferCollector'
            label='Tranfer Collector?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DealPurchase
