import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseReason, SnackbarKey, useSnackbar } from 'notistack'
import { getNotifications, removeNotification } from '../../redux/slices/notificationsSlice'

let displayed: SnackbarKey[] = []

const Notifier = (): null => {
  const dispatch = useDispatch()
  const notifications = useSelector(getNotifications)
  const { enqueueSnackbar } = useSnackbar()

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id]
  }

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = displayed?.filter((key) => id !== key)
  }

  useEffect(() => {
    notifications.forEach(({ options = {}, message }) => {
      if (displayed.includes(options.key as SnackbarKey)) return
      enqueueSnackbar(message, {
        key: options.key,
        variant: options.variant,
        onClose: (event, reason, key) => {
          if (reason === ('clickaway' as CloseReason)) return
          dispatch(removeNotification(key as SnackbarKey))
          removeDisplayed(key as SnackbarKey)
        },
      })
      storeDisplayed(options.key as SnackbarKey)
    })
  }, [notifications, enqueueSnackbar, dispatch])

  return null
}

export default Notifier
