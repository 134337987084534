import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import { AmountField, PercentField } from '../../../../../ui'
import removeMask from '../../../../../utils/removeMask'
import ValueBox from './ValueBox'
import { round } from 'lodash'
import { formatNumber, formatNumberNoDecimal } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const DTIForm = ({}: Props) => {
  const { values } = useFormState()
  const { change } = useForm()

  useEffect(() => {
    const totalIncomeNumber = Number(removeMask(values?.IncomeTotalIncome)) || 0
    const totalExpensesNumber = Number(removeMask(values?.IncomeTotalExpenses)) || 0
    const totalNetIncome = `${totalIncomeNumber - totalExpensesNumber}`
    change('IncomeNetIncome', totalNetIncome)
    const deptToIncome = totalIncomeNumber !== 0 ? `${(totalExpensesNumber / totalIncomeNumber) * 100}` : ''
    change('IncomeDebtToIncome', deptToIncome)
  }, [values?.IncomeTotalIncome, values?.IncomeTotalExpenses])

  return (
    <Grid container spacing={2} padding={3}>
      <Grid item xs={12} sm={6}>
        <ValueBox title='Total Income' total={`$${formatNumberNoDecimal(values?.IncomeTotalIncome)}`} />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ValueBox title='Total Expenses' total={`$${formatNumberNoDecimal(values?.IncomeTotalExpenses)}`} color='info' />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ValueBox title='Net Income' total={`$${formatNumberNoDecimal(values?.IncomeNetIncome)}`} color='warning' />
      </Grid>

      <Grid item xs={12} sm={6}>
        <ValueBox title='Debt-To-Income' total={`${round(values?.IncomeDebtToIncome, 2)}%`} color='error' />
      </Grid>

      {/* <Grid className={classes.row}>
        <AmountField variant='standard' name='IncomeTotalIncome' label='Total Income' disabled fullWidth />
      </Grid>
      <Grid className={classes.row}>
        <AmountField variant='standard' name='IncomeTotalExpenses' label='Total Expenses' disabled fullWidth />
      </Grid>
      <Grid className={classes.row}>
        <AmountField variant='standard' name='IncomeNetIncome' label='Net Income' disabled fullWidth />
      </Grid>
      <Grid className={classes.row}>
        <PercentField variant='standard' name='IncomeDebtToIncome' label='Debt-To-Income' disabled fullWidth />
      </Grid> */}
    </Grid>
  )
}

export default DTIForm
