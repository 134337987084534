import { Grid, Typography } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'
interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const ValuationForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const carCondition = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarCondition'))
  const bookValueSource = useSelector((state) => selectDropdownByName(state, 'BookValueSource'))

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Valuation
        </Typography>
      </Grid>
      {carCondition && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='conditionID'
            label='Condition'
            options={carCondition}
            autoComplete='off'
            optionKey='ID'
            optionValue='Condition'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}

      {bookValueSource?.ReturnData &&
        bookValueSource?.ReturnData?.map((item, index) => (
          <Grid item xs={6} key={item?.SourceNumber}>
            <AmountField
              variant='standard'
              disabled={disableFields}
              name={`bookValue${index + 1}`}
              label={item?.BookValueSource as string}
              fullWidth
              required
            />
          </Grid>
        ))}
    </Grid>
  )
}

export default ValuationForm
