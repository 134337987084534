import { useEffect, useState, useMemo, Dispatch, SetStateAction, useRef, RefObject } from 'react'
import dayjs from 'dayjs'
import {
  TaxesObjects,
  UspCarSearchResult,
  UspCreditAppSearchResult,
  UspGetDataCar,
  UspGetDataCreditApp,
  UspGetDataDealEntryResult,
  UspGetDataStateDealSettingsResult,
} from '../../../../../models'
import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import { useNavigate, useParams } from 'react-router-dom'
import { Header, StructureForm, DownPaymentsForm, SalesTaxForm, TermsForm, ResultForm, NextButton } from './components'
import Button from '@mui/material/Button'
import { appRoute } from '../../../../../constants'
import {
  useGetCalculationForDealMutation,
  useLazyGetStateDealSettingsQuery,
  useGetTaxesMutation,
  useLazyGetCarQuery,
  useGetMaturityDateForDealMutation,
  useGetDealProfitMutation,
  useEditDealMutation,
} from '../../../../../services'
import { getCurrentDate, parseToNumber } from '../../../../../utils/general'
import validateForm from '../../../../../utils/validateForm'
import { addDealValidation } from '../../../../../validation'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Paper from '@mui/material/Paper'
import removeMask from '../../../../../utils/removeMask'
import { convertDateToString } from '../../../../../utils/dateUtils'
import { CustomCircular } from '../../../../../ui'
import { withPermissionsDropdown } from '../../../../../hoc'
import LoadingButton from '@mui/lab/LoadingButton'
import Container from '@mui/system/Container'
import { useSettingsContext } from '../../../../../components/settings'
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs'
import { Box, Card, CardActions, CardContent, CardHeader, IconButton, MenuItem, Stepper, Typography } from '@mui/material'
import Iconify from '../../../../../components/iconify/iconify'
import CustomPopover, { usePopover } from '../../../../../components/custom-popover'
import VehicleCard from './components/VehicleCard'
import BuyerCard from './components/BuyerCard'
import FormStepper from './components/FormStepper'
import Recap from './components/Recap'
import DealResult from '../DealResult'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const PAYMENTS_PER_YEAR = {
  M: 12,
  S: 24,
  B: 26,
  W: 52,
}
export interface DisplayType {
  downPaymentsBlock: boolean
  salesTaxBlock: boolean
  termsBlock: boolean
  fieldFees: boolean
  fieldProducts: boolean
  fieldPaymentSchedule: boolean
  fieldFirstDueDate: boolean
}

interface Props {
  dealData: UspGetDataDealEntryResult | undefined
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  setSelectedCar: (car?: UspCarSearchResult) => void
  setSelectedCreditApp: (creditApp?: UspCreditAppSearchResult | UspGetDataCreditApp) => void
  carSelected?: any
  creditSelected?: UspCreditAppSearchResult | UspGetDataCreditApp
  setDealStructureFormValues: (values: any) => void
  dealStructureFormValues?: any
  setTotalPickups?: Dispatch<SetStateAction<boolean>>
  formPayments: string[]
  setFormPayments: Dispatch<SetStateAction<string[]>>
  setPaymentsForm: Dispatch<SetStateAction<string[]>>
  paymentsForm: string[]
  isDownPaymentDeleteCalled: boolean
  setIsDownPaymentDeleteCalled: Dispatch<SetStateAction<boolean>>
  setDealType: Dispatch<SetStateAction<string | undefined | null>>
  dealType: string | undefined
  disableFields: boolean
  stage: 'dealStructure' | 'dealResult'
  totalPickups: any
}

export const DealStructure = ({
  dealData,
  setCurrentStage,
  carSelected,
  creditSelected,
  setSelectedCar,
  setSelectedCreditApp,
  setDealStructureFormValues,
  setTotalPickups,
  dealStructureFormValues,
  formPayments,
  setFormPayments,
  setPaymentsForm,
  paymentsForm,
  isDownPaymentDeleteCalled,
  setIsDownPaymentDeleteCalled,
  setDealType,
  dealType,
  disableFields,
  stage,
  totalPickups,
}: Props) => {
  const { dealID } = useParams()
  const navigate = useNavigate()
  const [feesForm, setFeesForm] = useState<any[]>([])
  const [productsForm, setProductsForm] = useState<any[]>([])
  const [disableCalculateBtn, setDisableCalculateBtn] = useState(false)
  const [disableSalesTaxFields, setDisableSalesTaxFields] = useState(false)
  const [getStateDealSettings, { data: stateDealSettingsData, isSuccess: stateDealSettingsIsSuccess, isLoading: stateDealSettingsIsLoading }] =
    useLazyGetStateDealSettingsQuery()
  const [getCalculationForDeal, { data: calculationForDealData, isSuccess: calculationForDealIsSuccess, isLoading: calculationForDealIsLoading }] =
    useGetCalculationForDealMutation()
  const [
    getMaturityDateForDeal,
    { data: maturityDateForDealData, isSuccess: maturityDateForDealIsSuccess, isLoading: maturityDateForDealIsLoading },
  ] = useGetMaturityDateForDealMutation()
  const [getTaxes, { data: taxesData, isSuccess: taxesIsSuccess, isLoading: taxesIsLoading }] = useGetTaxesMutation()
  const [getCar, { data: carData, isSuccess: carIsSuccess }] = useLazyGetCarQuery()
  const [secondClick, setSecondClick] = useState(false)
  const buttonRef = useRef<any>(null)
  const [dealDateValue, setDealDateValue] = useState<string | null>('')
  const [display, setDisplay] = useState<DisplayType>({
    downPaymentsBlock: true,
    salesTaxBlock: true,
    termsBlock: true,
    fieldFees: true,
    fieldProducts: true,
    fieldPaymentSchedule: true,
    fieldFirstDueDate: true,
  })

  const getFeeData = () => {
    // Create an array of StateFeeID from stateDealSettingsData?.StateFeeDataInJson
    const stateFeeIDs = stateDealSettingsData?.StateFeeDataInJson?.map((product) => product?.StateFeeID) || []
    const FeeData: any = {}
    dealData?.FeeData?.forEach((item) => {
      // Only include the fee if its StateFeeID exists in stateFeeIDs
      if (stateFeeIDs.includes(item.StateFeeID)) {
        FeeData[`FeeFieldValue${item.StateFeeID}`] = item.FeeAmount
      }
    })
    return FeeData
  }

  const getProductData = () => {
    // Create an array of StateProductID from stateDealSettingsData?.ProductDataInJson
    const stateProductIDs = stateDealSettingsData?.ProductDataInJson?.map((product) => product?.StateProductID) || []
    const FeeData: any = {}
    dealData?.FeeData?.forEach((item) => {
      // Only include the fee if its StateFeeID exists in stateProductIDs
      if (stateProductIDs.includes(item.StateFeeID)) {
        FeeData[`ProductFieldValue${item.StateFeeID}`] = item.FeeAmount
      }
    })
    return FeeData
  }
  const [initialValues, setInitialValues] = useState<any>({
    DealID: dealData?.DealID,
    DealType: dealData?.DealType,
    State: dealData?.DealState,
    SalesPrice: dealData?.SalesPrice,
    DealDate: dealData?.DealDate,
    DealState: dealData?.DealState,
    ...getFeeData(),
    ...getProductData(),

    DealHasTrade: dealData?.IsHasTrade,
    TradeAllowance: dealData?.TradeAllowance,
    TradeLien: dealData?.TradeLien,
    TradeACV: dealData?.TradeACV,
    CashDown: dealData?.CashDown,

    DealPostalCode: dealData?.DealPostalCode,
    TaxState: dealData?.TaxState,
    TaxCity: dealData?.TaxCityName,
    TaxCounty: dealData?.TaxCountyName,
    StateTaxRate: dealData?.StateTaxRate?.toString(),
    CityTaxRate: dealData?.CityTaxRate?.toString(),
    CountyTaxRate: dealData?.CountyTaxRate?.toString(),
    MiscTaxRate: dealData?.MiscTaxRate?.toString(),

    Schedule: dealData?.PaymentSchedule,
    DueDay1: dealData?.DueDay1,
    DueDay2: dealData?.DueDay2,
    FirstDueDate: dealData?.FirstDueDate,
    Calculate: dealData?.DesiredPayment === '0' ? 'term' : 'payment',
    DesiredTerm: dealData?.DesiredTerm?.toString(),
    DesiredPayment: dealData?.DesiredPayment?.toString(),
    InterestRate: dealData?.InterestRate?.toString(),

    LenderID: dealData?.LenderID,
    SpreadType: dealData?.SpreadRate ? 'rate' : 'amount',
    SpreadAmount: dealData?.SpreadAmount?.toString(),
    SpreadRate: dealData?.SpreadRate?.toString(),
    DiscountType: dealData?.SalesDiscountRate ? 'rate' : 'amount',
    DiscountRate: dealData?.SalesDiscountRate?.toString(),
    DiscountAmount: dealData?.SalesDiscountAmount?.toString(),

    BranchID: dealData?.BranchID,
    DealStatus: dealData?.DealStatusID,
    Salesperson: dealData?.SalespersonUserID,
    Manager: dealData?.FiUserID,
    NumberOfYears: (
      (dealData?.DesiredTerm || 0) / (PAYMENTS_PER_YEAR[(dealData?.PaymentSchedule as keyof typeof PAYMENTS_PER_YEAR) || ''] || 0)
    ).toString(),
    CalculatedTerm: null,
  })

  const getFirstDealDate = (dealDate: string) => {
    if (stateDealSettingsData?.IsDefaultOneTermUntilFirstDue) {
      if (stateDealSettingsData?.DefaultSchedule === 'M') {
        return dayjs(dealDate).add(1, 'months').format('YYYY-MM-DD')
      }
      if (stateDealSettingsData?.DefaultSchedule === 'B') {
        return dayjs(dealDate).add(14, 'days').format('YYYY-MM-DD')
      }
      if (stateDealSettingsData?.DefaultSchedule === 'W') {
        return dayjs(dealDate).add(7, 'days').format('YYYY-MM-DD')
      }
      return undefined
    } else {
      return dayjs(dealDate)
        .add(stateDealSettingsData?.DefaultDaysBeforeFirstDue as number, 'days')
        .format('YYYY-MM-DD')
    }
  }

  const handleSubmit = async (values: any) => {
    const feesList: TaxesObjects[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('FeeFieldValue')) {
        const id = item?.replace('FeeFieldValue', '')
        if (values[`FeeFieldValue${id}`] && Number(removeMask(values[`FeeFieldValue${id}`])) !== 0) {
          feesList.push({
            Amount: Number(removeMask(values[`FeeFieldValue${id}`])) || 0,
            IsTaxable: values[`FeeIsTaxableValue${id}`],
          })
        }
      }
    })

    const productsList: TaxesObjects[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('ProductFieldValue')) {
        const id = item?.replace('ProductFieldValue', '')
        if (values[`ProductFieldValue${id}`] && Number(removeMask(values[`ProductFieldValue${id}`])) !== 0) {
          productsList.push({
            Amount: Number(removeMask(values[`ProductFieldValue${id}`])) || 0,
            IsTaxable: values[`ProductFieldIsActive${id}`],
          })
        }
      }
    })
    getTaxes({
      taxes: {
        type: values?.DealType,
        state: values?.DealState,
        county: values?.TaxCounty,
        sales_price: Number(removeMask(values?.SalesPrice)),
        trade_allowance: Number(removeMask(values?.TradeAllowance)),
        trade_lien: Number(removeMask(values?.TradeLien)),
        cash_down: Number(removeMask(values?.CashDown)),
        pickup_payments: Number(removeMask(values?.PickupPayments)),
        state_tax_rate: Number(removeMask(values?.StateTaxRate)),
        city_tax_rate: Number(removeMask(values?.CityTaxRate)),
        county_tax_rate: Number(removeMask(values?.CountyTaxRate)),
        misc_tax_rate: Number(removeMask(values?.MiscTaxRate)),
        is_trade_tax_credit: values?.IsTradeTaxCredit,
        products_list: productsList,
        fees_list: feesList,
        county_tax_cap: values?.TaxCap,
        doc_stamp_fee_per_100: Number(stateDealSettingsData?.DocStampFeePer100),
      },
    }).then((res: any) => {
      if (res?.data) {
        console.log('do calc')
        if (dealType !== 'Cash' && dealType !== 'Wholesale') {
          console.log('do calc2')

          getCalculationForDeal({
            calculateDeal: {
              amount_financed: res?.data?.AmountFinanced,
              interest_rate: values.InterestRate,
              deal_date: convertDateToString(values.DealDate) as string,
              first_due_date: convertDateToString(values.FirstDueDate) as string,
              desired_term: values.Calculate === 'payment' ? values.DesiredTerm : 0,
              desired_payment_amount: values.Calculate === 'term' ? Number(removeMask(values.DesiredPayment)) : 0,
              payment_schedule: values.Schedule,
            },
          }).then((res: any) => {
            if (res?.data) {
              getMaturityDateForDeal({
                maturityDate: {
                  term: res?.data.term,
                  payment_schedule: values.Schedule,
                  due_day_1: values.DueDay1,
                  due_day_2: values.DueDay2,
                  final_payment_amount: res?.data.final_payment_amount,
                  first_due_date: convertDateToString(values.FirstDueDate) as string,
                },
              }).then((res: any) => {
                setSecondClick(true)
                setDisableCalculateBtn(true)
              })
            }
          })
        } else {
          setDisableCalculateBtn(true)
          setSecondClick(true)
        }
      }
    })
  }

  useEffect(() => {
    if (dealType === 'Wholesale') {
      setDisplay({
        downPaymentsBlock: false,
        salesTaxBlock: false,
        termsBlock: false,
        fieldFees: false,
        fieldProducts: false,
        fieldPaymentSchedule: true,
        fieldFirstDueDate: true,
      })
      setInitialValues({
        ...initialValues,
        CashDown: '0',
        Schedule: 'W',
        FirstDueDate: dealDateValue,
        Calculate: 'payment',
        DesiredTerm: '1',
        InterestRate: '0',
        DealType: 'Wholesale',
        Term: '1',
        StateTaxRate: '0',
        CityTaxRate: '0',
        CountyTaxRate: '0',
        MiscTaxRate: '0',
      })
    } else if (dealType === 'Cash') {
      setDisplay({
        downPaymentsBlock: true,
        salesTaxBlock: true,
        termsBlock: false,
        fieldFees: true,
        fieldProducts: true,
        fieldPaymentSchedule: true,
        fieldFirstDueDate: true,
      })
      setInitialValues({
        ...initialValues,
        Schedule: 'W',
        FirstDueDate: dealDateValue,
        Calculate: 'payment',
        DesiredTerm: '1',
        InterestRate: '0',
        DealType: 'Cash',
        CashDown: '0',
        Term: '1',
        TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
        CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
        CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),
        StateTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.StateTaxRate?.toString(),
      })
    } else if (dealType === 'Financed') {
      setDisplay({
        downPaymentsBlock: true,
        salesTaxBlock: true,
        termsBlock: true,
        fieldFees: true,
        fieldProducts: true,
        fieldPaymentSchedule: false,
        fieldFirstDueDate: true,
      })
      setInitialValues({
        ...initialValues,
        TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
        CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
        CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),
        StateTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.StateTaxRate?.toString(),
        DealType: 'Financed',
      })
    } else {
      setDisplay({
        downPaymentsBlock: true,
        salesTaxBlock: true,
        termsBlock: true,
        fieldFees: true,
        fieldProducts: true,
        fieldPaymentSchedule: false,
        fieldFirstDueDate: true,
      })
    }
  }, [dealType])

  useEffect(() => {
    if (carSelected?.CarID && stateDealSettingsIsSuccess) {
      getCar({ carId: carSelected?.CarID?.toString() as string })
    }
  }, [carSelected?.CarID, stateDealSettingsIsSuccess])

  useEffect(() => {
    // SalesPrice
    if (carIsSuccess) {
      // if (dealID === 'add') {

      // }
      setInitialValues({
        ...initialValues,
        SalesPrice: carData?.StickerPrice?.toString(),
        CashDown: carData?.DownPayment?.toString(),
      })
    }
  }, [carIsSuccess])

  // carSelected

  useEffect(() => {
    let pickupPayment = 0
    let feeInitialSum = 0
    let productsInitialSum = 0
    if (stateDealSettingsIsSuccess) {
      const initialData: any = {}

      // Resetting Fee fields
      feesForm.forEach((fee) => {
        initialData[fee.fieldName] = undefined
        initialData[`FeeIsTaxableValue${fee.id}`] = undefined
        initialData[`FeeFieldIsActive${fee.id}`] = undefined
        initialData[`FeeFieldProfitType${fee.id}`] = undefined
        initialData[`FeeFieldFeeLabel${fee.id}`] = undefined
        initialData[`FeeFieldValue${fee.id}`] = 0
      })

      // Resetting Product fields
      productsForm.forEach((product) => {
        initialData[`ProductFieldValue${product.id}`] = 0
        initialData[`ProductIsTaxableValue${product.id}`] = undefined
        initialData[`ProductFieldIsActive${product.id}`] = undefined
        initialData[`ProductFieldProfitType${product.id}`] = undefined
        initialData[`ProductFieldProfitLabel${product.id}`] = undefined
        initialData[`ProductFieldProfitCostRate${product.id}`] = undefined
        initialData[`ProductFieldProfitCostAmount${product.id}`] = undefined
      })

      if (dealID === 'add') {
        // add deal
        const feeData: any[] = [] // set deal fee data
        stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
          if (item?.IsActive) {
            feeData.push({
              id: item?.StateFeeID,
              fieldName: `FeeFieldValue${item?.StateFeeID}`,
              fieldLabel: item?.FeeLabel,
              fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
              feeAmount: item?.FeeAmount || '0',
              feeInitialAmount: item?.FeeAmount || '0',
              isFixedAmount: item?.IsFixedAmount,
              isTaxable: item?.IsTaxable,
            })
            if (item?.FeeLabel && item?.IsFixedAmount) {
              feeInitialSum += Number(item?.FeeAmount)
            }

            initialData[`FeeFieldValue${item?.StateFeeID}`] = item?.IsFixedAmount ? item?.FeeAmount?.toString() : '0'
            initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
            initialData[`FeeFieldIsActive${item?.StateFeeID}`] = item?.IsFixedAmount
            initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
            initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
          }
        })
        setFeesForm(feeData)

        // set products data
        const productData: any[] = [] // set products fee data
        stateDealSettingsData?.ProductDataInJson?.forEach((item) => {
          if (item?.IsActive) {
            productData.push({
              id: item?.StateProductID,
              fieldName: `ProductFieldValue${item?.StateProductID}`,
              fieldLabel: item?.ProductLabel,
              fieldCheckboxName: `ProductFieldIsActive${item?.StateProductID}`,
              productAmount: item?.ProductPrice || '0',
              productInitialAmount: item?.ProductPrice || '0',
              isFixedAmount: item?.IsFixedPrice,
              isTaxable: item?.IsTaxable,
            })
            // if (item?.ProductLabel) {
            //   productsInitialSum += Number(item?.ProductPrice)
            // }

            initialData[`ProductFieldValue${item?.StateProductID}`] = '0' //item?.ProductPrice?.toString() || '0'
            initialData[`ProductIsTaxableValue${item?.StateProductID}`] = item?.IsTaxable
            initialData[`ProductFieldIsActive${item?.StateProductID}`] = false //item?.IsFixedPrice
            initialData[`ProductFieldProfitType${item?.StateProductID}`] = item?.ProfitType
            initialData[`ProductFieldProfitLabel${item?.StateProductID}`] = item?.ProductLabel
            initialData[`ProductFieldProfitCostRate${item?.StateProductID}`] = item?.CostRate
            initialData[`ProductFieldProfitCostAmount${item?.StateProductID}`] = item?.CostAmount
          }
        })
        setProductsForm(productData)
        // productData
      } else {
        // edit deal
        const feeData: any[] = [] // set deal fee data
        stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
          const feeDataItem = dealData?.FeeData?.find((dealDataItem) => dealDataItem?.StateFeeID === item?.StateFeeID)

          if (item?.IsActive || feeDataItem?.FeeAmount) {
            feeData.push({
              id: item?.StateFeeID,
              fieldName: `FeeFieldValue${item?.StateFeeID}`,
              fieldLabel: item?.FeeLabel || feeDataItem?.FeeLabel,
              fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
              feeAmount: feeDataItem?.FeeAmount || '0',
              feeInitialAmount: item?.FeeAmount || '0',
              isFixedAmount: false, //item?.IsFixedAmount,
            })
            if (feeDataItem?.FeeAmount) {
              feeInitialSum += Number(removeMask(feeDataItem?.FeeAmount))
            }

            initialData[`FeeFieldValue${item?.StateFeeID}`] = feeDataItem?.FeeAmount?.toString() || '0'
            initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
            initialData[`FeeFieldIsActive${item?.StateFeeID}`] = feeDataItem && true // item?.IsFixedAmount
            initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
            initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
          }
        })
        setFeesForm(feeData)

        const productData: any[] = [] // set deal product data
        stateDealSettingsData?.ProductDataInJson?.forEach((item) => {
          const productDataItem = dealData?.ProductData?.find((dealDataItem) => dealDataItem?.StateProductID === item?.StateProductID)
          if (item?.IsActive || productDataItem?.ProductPrice) {
            productData.push({
              id: item?.StateProductID,
              fieldName: `ProductFieldValue${item?.StateProductID}`,
              fieldLabel: item?.ProductLabel || productDataItem?.ProductLabel,
              fieldCheckboxName: `ProductFieldIsActive${item?.StateProductID}`,
              productAmount: productDataItem?.ProductPrice || '0',
              productInitialAmount: item?.ProductPrice || '0',
              isFixedPrice: false,
            })
            if (productDataItem?.ProductPrice) {
              productsInitialSum += Number(removeMask(productDataItem?.ProductPrice))
            }

            initialData[`ProductFieldValue${item?.StateProductID}`] = productDataItem?.ProductPrice?.toString() || '0'
            initialData[`ProductIsTaxableValue${item?.StateProductID}`] = item?.IsTaxable
            initialData[`ProductFieldIsActive${item?.StateProductID}`] = productDataItem && true
            initialData[`ProductFieldProfitType${item?.StateProductID}`] = item?.ProfitType
            initialData[`ProductFieldProfitLabel${item?.StateProductID}`] = item?.ProductLabel
            initialData[`ProductFieldProfitCostRate${item?.StateProductID}`] = item?.CostRate
            initialData[`ProductFieldProfitCostAmount${item?.StateProductID}`] = item?.CostAmount
          }
        })
        setProductsForm(productData)
      }
      initialData['PickupPayments'] = pickupPayment?.toString() || '0'
      initialData['Fees'] = feeInitialSum?.toString() || '0'
      initialData['Products'] = productsInitialSum?.toString() || '0'

      if (dealID !== 'add') {
        if (!isDownPaymentDeleteCalled) {
          // set pickup payments data
          const paymentsForm: string[] = []
          dealData?.PickupPaymentData?.forEach((item) => {
            paymentsForm.push(item?.DealPickupID?.toString())
            pickupPayment += Number(item?.Amount)
            initialData[`DatePaymentAmount${item?.DealPickupID}`] = item?.Amount
            initialData[`DuePaymentDate${item?.DealPickupID}`] = item?.DueDate
            // DatePaymentAmount
          })
          setPaymentsForm(paymentsForm)
        }
      }

      if (dealID === 'add') {
        // set initial date for add deal

        if (!stateDealSettingsData?.IsDestinationTax) {
          setDisableSalesTaxFields(true)
          setInitialValues({
            ...initialValues,
            DealType: 'Financed',
            DealDate: dayjs().startOf('day').toString(),

            DealPostalCode: stateDealSettingsData?.StateCityCountyData?.[0]?.ZipCode,
            TaxCity: stateDealSettingsData?.StateCityCountyData?.[0]?.City,
            TaxCounty: stateDealSettingsData?.StateCityCountyData?.[0]?.County,
            TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
            CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
            CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),
            StateTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.StateTaxRate?.toString(),

            DealState: stateDealSettingsData?.State,
            DesiredTerm: stateDealSettingsData?.DefaultTerm?.toString(),
            InterestRate: stateDealSettingsData?.DefaultInterestRate?.toString(),
            MiscTaxRate: stateDealSettingsData?.MiscTaxRate?.toString(),
            Schedule: stateDealSettingsData?.DefaultSchedule,
            FirstDueDate: getFirstDealDate(dayjs().startOf('day').toString()),
            DueDay1: stateDealSettingsData?.DefaultDueDay1?.toString(),
            DueDay2: stateDealSettingsData?.DefaultDueDay2?.toString(),
            ...initialData,
            DealHasTrade: false,
            TradeAllowance: '0',
            CashDown: '0',
            TradeLien: '0',
            TradeACV: '0',
          })
        } else if (stateDealSettingsData?.IsDestinationTax) {
          setDisableSalesTaxFields(false)
          setInitialValues({
            ...initialValues,
            DealType: 'Financed',
            DealDate: getCurrentDate(),

            DealPostalCode: stateDealSettingsData?.StateCityCountyData?.[0]?.ZipCode,
            TaxCity: stateDealSettingsData?.StateCityCountyData?.[0]?.City,
            TaxCounty: stateDealSettingsData?.StateCityCountyData?.[0]?.County,
            TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
            CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
            CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),
            StateTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.StateTaxRate?.toString(),

            DealState: stateDealSettingsData?.State,
            DesiredTerm: stateDealSettingsData?.DefaultTerm?.toString(),
            InterestRate: stateDealSettingsData?.DefaultInterestRate?.toString(),
            MiscTaxRate: stateDealSettingsData?.MiscTaxRate?.toString(),
            Schedule: stateDealSettingsData?.DefaultSchedule,
            FirstDueDate: getFirstDealDate(getCurrentDate()),
            DueDay1: stateDealSettingsData?.DefaultDueDay1?.toString(),
            DueDay2: stateDealSettingsData?.DefaultDueDay2?.toString(),
            ...initialData,
            DealHasTrade: false,
            TradeAllowance: '0',
            TradeLien: '0',
            TradeACV: '0',
          })
        } else {
          setInitialValues({
            ...initialValues,
            DealType: 'Financed',
            DealDate: getCurrentDate(),

            DealPostalCode: stateDealSettingsData?.StateCityCountyData?.[0]?.ZipCode,
            TaxCity: stateDealSettingsData?.StateCityCountyData?.[0]?.City,
            TaxCounty: stateDealSettingsData?.StateCityCountyData?.[0]?.County,
            TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
            CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
            CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),

            StateTaxRate: stateDealSettingsData?.StateTaxRate?.toString(),
            DealState: stateDealSettingsData?.State,
            DesiredTerm: stateDealSettingsData?.DefaultTerm?.toString(),
            InterestRate: stateDealSettingsData?.DefaultInterestRate?.toString(),
            MiscTaxRate: stateDealSettingsData?.MiscTaxRate?.toString(),
            Schedule: stateDealSettingsData?.DefaultSchedule,
            FirstDueDate: getFirstDealDate(getCurrentDate()),
            DueDay1: stateDealSettingsData?.DefaultDueDay1?.toString(),
            DueDay2: stateDealSettingsData?.DefaultDueDay2?.toString(),
            ...initialData,
            DealHasTrade: false,
            TradeAllowance: '0',
            TradeLien: '0',
            TradeACV: '0',
          })
        }
      } else {
        setInitialValues({
          DealID: dealData?.DealID,
          DealType: dealData?.DealType,
          SalesPrice: dealData?.SalesPrice,
          DealDate: dealData?.DealDate,
          DealHasTrade: dealData?.IsHasTrade,
          TradeAllowance: dealData?.TradeAllowance,
          TradeLien: dealData?.TradeLien,
          TradeACV: dealData?.TradeACV,
          CashDown: dealData?.CashDown,
          DealPostalCode: dealData?.DealPostalCode,
          TaxState: dealData?.TaxState,
          TaxCity: dealData?.TaxCityName,
          TaxCounty: dealData?.TaxCountyName,
          StateTaxRate: dealData?.StateTaxRate?.toString(),
          CityTaxRate: dealData?.CityTaxRate?.toString(),
          CountyTaxRate: dealData?.CountyTaxRate?.toString(),
          MiscTaxRate: dealData?.MiscTaxRate?.toString(),

          Schedule: dealData?.PaymentSchedule,
          DueDay1: dealData?.DueDay1,
          DueDay2: dealData?.DueDay2,
          FirstDueDate: dealData?.FirstDueDate,
          Calculate: dealData?.CalculationBasis.toLowerCase(),
          DesiredTerm: dealData?.DesiredTerm?.toString(),
          DesiredPayment: dealData?.DesiredPayment?.toString(),
          InterestRate: dealData?.InterestRate?.toString(),

          LenderID: dealData?.LenderID,
          SpreadType: dealData?.SpreadRate ? 'rate' : 'amount',
          SpreadAmount: dealData?.SpreadAmount?.toString(),
          SpreadRate: dealData?.SpreadRate?.toString(),
          DiscountType: dealData?.SalesDiscountRate ? 'rate' : 'amount',
          DiscountRate: dealData?.SalesDiscountRate?.toString(),
          DiscountAmount: dealData?.SalesDiscountAmount?.toString(),

          BranchID: dealData?.BranchID,
          DealStatus: dealData?.DealStatusID,
          Salesperson: dealData?.SalespersonUserID,
          Manager: dealData?.FiUserID,
          NumberOfYears: (
            (dealData?.DesiredTerm || 0) / (PAYMENTS_PER_YEAR[(dealData?.PaymentSchedule as keyof typeof PAYMENTS_PER_YEAR) || ''] || 0)
          ).toString(),
          CalculatedTerm: null,
          DealState: stateDealSettingsData?.State || dealData?.DealState,
          ...initialData,
        })
      }
    }
  }, [stateDealSettingsIsSuccess, dealData, isDownPaymentDeleteCalled, stateDealSettingsData])

  useEffect(() => {
    getStateDealSettings({ state: dealStructureFormValues?.DealState || dealData?.DealState || 'XX' })
  }, [dealStructureFormValues])

  const isLoading = useMemo(
    () => stateDealSettingsIsLoading || calculationForDealIsLoading || maturityDateForDealIsLoading || taxesIsLoading,
    [stateDealSettingsIsLoading, calculationForDealIsLoading, maturityDateForDealIsLoading, taxesIsLoading],
  )
  const settings = useSettingsContext()

  const [currentStep, setCurrentStep] = useState(0)

  const [editDeal, { isSuccess: editDealIsSuccess }] = useEditDealMutation()

  useEffect(() => {
    if (dealType !== 'Wholesale' && dealType !== 'Cash' && calculationForDealIsSuccess) {
      console.log('calculationForDealIsSuccess', calculationForDealIsSuccess)
      setTimeout(() => {
        buttonRef?.current?.click && buttonRef?.current?.click()
        setSecondClick(false)
      }, 1000)
    } else if ((dealType == 'Wholesale' || dealType == 'Cash') && taxesIsSuccess) {
      console.log('taxesIsSuccess', taxesIsSuccess)
      setTimeout(() => {
        buttonRef?.current?.click && buttonRef?.current?.click()
        setSecondClick(false)
      }, 1000)
    }
  }, [calculationForDealData, calculationForDealIsSuccess, taxesIsSuccess, taxesData])

  return (
    <>
      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <CustomBreadcrumbs
          heading='Deal'
          links={[
            {
              name: 'List',
              href: appRoute.DealsList,
            },
            {
              name: dealID === 'add' ? 'New' : 'Edit',
            },
          ]}
          sx={{
            mb: {
              xs: 1,
              md: 3,
            },
          }}
        />

        <VehicleCard carData={carSelected} setCurrentStage={setCurrentStage} setSelectedCar={setSelectedCar} />
        <BuyerCard
          setCurrentStage={setCurrentStage}
          creditSelected={creditSelected}
          dealData={dealData}
          setSelectedCreditApp={setSelectedCreditApp}
        />

        <FormStepper activeStep={currentStep} />
        <div style={{ display: stage === 'dealStructure' ? 'block' : 'none' }}>
          <Form onSubmit={handleSubmit} initialValues={dealStructureFormValues || initialValues} validate={validateForm(addDealValidation)}>
            {({ handleSubmit, valid, form }: any) => (
              <form onSubmit={handleSubmit}>
                <Box
                  sx={{
                    flexGrow: { md: 1 },
                    display: { md: 'flex' },
                    flexDirection: { md: 'column' },
                    gap: 3,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Card
                        sx={{
                          flexGrow: { md: 1 },
                          display: { md: 'flex' },
                          flexDirection: { md: 'column' },
                          p: 4,
                          gap: 3,
                        }}
                      >
                        <StructureForm
                          disableFields={disableFields}
                          setDisableSalesTaxFields={setDisableSalesTaxFields}
                          setDealDateValue={setDealDateValue}
                          dealData={dealData}
                          getStateDealSettings={getStateDealSettings}
                          stateDealSettingsData={stateDealSettingsData}
                          setDealType={setDealType}
                          display={display}
                          initialValues={initialValues}
                          feesForm={feesForm}
                          productsForm={productsForm}
                        />
                        {display?.downPaymentsBlock && (
                          <DownPaymentsForm
                            dealType={dealType}
                            disableFields={disableFields}
                            setIsDownPaymentDeleteCalled={setIsDownPaymentDeleteCalled}
                            formPayments={formPayments}
                            setFormPayments={setFormPayments}
                            paymentsForm={paymentsForm}
                            setPaymentsForm={setPaymentsForm}
                            setTotalPickups={setTotalPickups}
                          />
                        )}
                      </Card>
                    </Grid>
                    <Grid item xs={6}>
                      {(display?.salesTaxBlock || display?.termsBlock) && (
                        <Card
                          sx={{
                            flexGrow: { md: 1 },
                            display: { md: 'flex' },
                            flexDirection: { md: 'column' },
                            p: 4,
                            gap: 3,
                          }}
                        >
                          {display?.salesTaxBlock && <SalesTaxForm disableFields={disableFields} disableSalesTaxFields={disableSalesTaxFields} />}

                          {display?.termsBlock && (
                            <TermsForm
                              disableFields={disableFields}
                              dealData={dealData}
                              valid={valid}
                              stateDealSettingsData={stateDealSettingsData}
                            />
                          )}
                        </Card>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                <Box display='flex' justifyContent='flex-end' gap={2} mt={3}>
                  <Recap carData={carData} dealData={dealData} />
                  {/* <Button disabled={disableCalculateBtn} type='submit' variant='outlined'>
                    Calculate
                  </Button> */}
                  <NextButton
                    taxesIsSuccess={taxesIsSuccess}
                    dealType={dealType}
                    // setDisableCalculateBtn={setDisableCalculateBtn}
                    taxesData={taxesData}
                    setCurrentStage={setCurrentStage}
                    calculationForDealData={calculationForDealData}
                    calculationForDealIsSuccess={calculationForDealIsSuccess}
                    calculationForDealIsLoading={calculationForDealIsLoading}
                    setDealStructureFormValues={setDealStructureFormValues}
                    maturityDateForDealData={maturityDateForDealData}
                    maturityDateForDealIsSuccess={maturityDateForDealIsSuccess}
                    setCurrentStep={setCurrentStep}
                    forwardRef={buttonRef}
                    onClick={
                      secondClick == false
                        ? () => {
                            form.submit()
                          }
                        : undefined
                    }
                  />
                </Box>
              </form>
            )}
          </Form>
        </div>
        {stage === 'dealResult' && (
          <DealResult
            dealData={dealData}
            disableFields={disableFields}
            dealType={dealType}
            totalPickups={totalPickups}
            carSelected={carSelected}
            creditSelected={creditSelected}
            setCurrentStage={setCurrentStage}
            setSelectedCar={setSelectedCar}
            setSelectedCreditApp={setSelectedCreditApp}
            dealStructureFormValues={dealStructureFormValues}
            editDeal={editDeal}
            editDealIsSuccess={editDealIsSuccess}
            carData={carData}
          />
        )}
      </Container>

      {/* <CustomCircular open={isLoading} /> */}
    </>
  )
}

export default withPermissionsDropdown(DealStructure)
