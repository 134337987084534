import React from 'react'
import { styled } from '@mui/system'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Container, Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import Typography from '@mui/material/Typography'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { UspGetDataContact, UspGetDataDealTaskResult } from '../../../../../models'
import { applyPhoneMask } from '../../../../../utils/general'
import { ReactComponent as CircleIcon } from '../../../../../assets/icons/circle.svg'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'mainDataWrap', 'secondaryData', 'iconSeparator', 'tabLinkWrap', 'tabLink'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    paddingRight: theme.spacing(2),
  },
  [`& .${classes.mainDataWrap}`]: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  [`& .${classes.secondaryData}`]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.iconSeparator}`]: {
    margin: theme.spacing(0, 1, 0, 1),
    fill: theme.palette.primary.main,
  },
  [`& .${classes.tabLinkWrap}`]: {
    display: 'flex',
    height: '60px',
  },
  [`& .${classes.tabLink}`]: {
    textDecoration: 'none',
    height: '60px',
    minHeight: '60px',
    padding: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    fontSize: '1em',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.background.hoverBtn,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.active,
    },
  },
}))

interface Props {
  handleTabChange: (event: React.SyntheticEvent, newValue: 'applicant' | 'employment') => void
  tabValue: 'applicant' | 'employment'
  errorsInTabs: { applicant: boolean; employment: boolean }
  headerData: UspGetDataContact
  taskDeal?: UspGetDataDealTaskResult
}

export const Header = ({ tabValue, errorsInTabs, handleTabChange, taskDeal }: Props) => {
  return (
    <StyledContainer className={classes.root}>
      <Grid container>
        <Grid className={classes.titleWrap}>
          <Grid container>
            <Grid item>
              <Typography variant='h3' className={classes.mainDataWrap}>
                {taskDeal?.CarDescription}
              </Typography>
            </Grid>
            <Grid container>
              <Grid item>
                <Typography variant='h6' className={classes.secondaryData}>
                  {`Stock # ${taskDeal?.StockNumber}`}
                </Typography>
              </Grid>
              <Grid item>
                <CircleIcon className={classes.iconSeparator} />
              </Grid>
              <Grid item>
                <Typography variant='h6'>{`VIN ${taskDeal?.VIN}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.titleWrap}>
          <Grid container>
            <Grid item>
              <Typography variant='h3' className={classes.mainDataWrap}>
                {taskDeal?.Buyer}
              </Typography>
              <Typography variant='h6' className={classes.secondaryData}>
                {taskDeal?.PhoneNumber && applyPhoneMask(taskDeal?.PhoneNumber)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.tabLinkWrap}>
          <Grid container>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab
                className={classes.tabLink}
                icon={errorsInTabs?.applicant && tabValue !== 'applicant' ? <ErrorOutlineIcon fontSize='small' color='error' /> : undefined}
                iconPosition='end'
                label='Buyer'
                value='applicant'
              />
              <Tab
                className={classes.tabLink}
                icon={errorsInTabs?.employment && tabValue !== 'employment' ? <ErrorOutlineIcon fontSize='small' color='error' /> : undefined}
                iconPosition='end'
                label='Employment'
                value='employment'
              />
            </Tabs>
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default Header
