import { styled } from '@mui/system'
import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm, useFormState } from 'react-final-form'
import TextField from '../../../../../ui/TextField'
import PhoneTextField from '../../../../../ui/PhoneTextField'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import AddressField from '../../../../../ui/AddressField'
import { getPhoneTypes, getStates } from '../../../../../redux/slices/userDropdownSlice'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

const LienHolderForm = ({}: any) => {
  const form = useForm()
  const states = useSelector(getStates)
  const phoneTypes = useSelector(getPhoneTypes)
  const { values } = useFormState()
  const addressValue = form.getFieldState('AddressLine1')?.value || ''
  const [getInfoByZipCode, { data: infoByZipCodeData }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue
      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('AddressLine1', street)
      if (city) form.change('City', city)
      if (state) form.change('State', state)
      if (zip) form.change('PostalCode', zip)
      if (infoByZipCodeData) {
        form.change('County', infoByZipCodeData?.County)
      }
    }
  }, [addressValue, form, infoByZipCodeData, values])

  return (
    <>
      <CardHeader title='Lienholder' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <TextField name='Name' label='Name' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='ContactName' label='Contact Name' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AddressField name='AddressLine1' label='Address Line 1' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField name='AddressLine2' label='Address Line 2' fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextField name='City' label='City' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='State'
            label='State'
            options={states}
            optionKey='State'
            optionValue='State'
            autoComplete='off'
            // optionNone
          />
        </Grid>
        <Grid item xs={2}>
          <TextField number name='PostalCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField name='County' label='County' options={[{ key: '1', value: 'state' }]} fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='PhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Biz'
          />
        </Grid>
        <Grid item xs={values?.PhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='PhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.PhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField name='PhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='SecondaryPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Fax'
          />
        </Grid>
        <Grid item xs={values?.SecondaryPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='SecondaryPhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.SecondaryPhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField name='SecondaryPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default LienHolderForm
