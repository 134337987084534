import React from 'react'
import { Box, Grid, generateUtilityClasses, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { formatNumber } from '../../../../../utils/general'
import { UspReportSalesCommissionsResult } from '../../../../../models'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    minWidth: '170px',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportSalesCommissionsResult[]
  isLoading: boolean
}
const SalesCommissionsTableBlock = ({ filterData, isLoading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('insights_sales_commissions_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('insights_sales_commissions_order_by') || 'StockNumber') as keyof UspReportSalesCommissionsResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspReportSalesCommissionsResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportSalesCommissionsResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('insights_sales_commissions_order', isAsc ? 'desc' : 'asc')
    storageSet('insights_sales_commissions_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportSalesCommissionsResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock #
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DealDate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DealDate'}
                direction={orderBy === 'DealDate' ? order : 'asc'}
                onClick={createSortHandler('DealDate')}
              >
                Deal Date
                {orderBy === 'DealDate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Buyer' ? order : false}>
              <TableSortLabel active={orderBy === 'Buyer'} direction={orderBy === 'Buyer' ? order : 'asc'} onClick={createSortHandler('Buyer')}>
                Buyer
                {orderBy === 'Buyer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Car' ? order : false}>
              <TableSortLabel active={orderBy === 'Car'} direction={orderBy === 'Car' ? order : 'asc'} onClick={createSortHandler('Car')}>
                Car
                {orderBy === 'Car' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'VIN' ? order : false}>
              <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                VIN
                {orderBy === 'VIN' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'FIMgr' ? order : false}>
              <TableSortLabel active={orderBy === 'FIMgr'} direction={orderBy === 'FIMgr' ? order : 'asc'} onClick={createSortHandler('FIMgr')}>
                F&I Manager
                {orderBy === 'FIMgr' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TotalCommission' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TotalCommission'}
                direction={orderBy === 'TotalCommission' ? order : 'asc'}
                onClick={createSortHandler('TotalCommission')}
              >
                Total Commission
                {orderBy === 'TotalCommission' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Salesperson' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Salesperson'}
                direction={orderBy === 'Salesperson' ? order : 'asc'}
                onClick={createSortHandler('Salesperson')}
              >
                Salesperson
                {orderBy === 'Salesperson' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CommissionAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CommissionAmount'}
                direction={orderBy === 'CommissionAmount' ? order : 'asc'}
                onClick={createSortHandler('CommissionAmount')}
              >
                Commission Amount
                {orderBy === 'CommissionAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsPaid' ? order : false}>
              <TableSortLabel active={orderBy === 'IsPaid'} direction={orderBy === 'IsPaid' ? order : 'asc'} onClick={createSortHandler('IsPaid')}>
                Is Paid?
                {orderBy === 'IsPaid' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.InventoryEdit.replace(':carID', item?.CarID?.toString() as string))}
              >
                {item.StockNumber}
              </TableCell>
              <TableCell>{convertDateToDisplay(item?.DealDate as string)}</TableCell>
              <TableCell
                className={classes.tableCellName}
                onClick={() => {
                  if (item?.IsFinalized) {
                    navigate(appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))
                  } else {
                    navigate(appRoute.DealsTask.replace(':dealID', item?.DealID?.toString() as string))
                  }
                }}
              >
                {item?.Buyer}
              </TableCell>
              <TableCell>{item?.Car}</TableCell>
              <TableCell>{item?.VIN}</TableCell>
              <TableCell>{item?.Branch}</TableCell>
              <TableCell>{item?.FIMgr}</TableCell>
              <TableCell>{formatNumber(item?.TotalCommission?.toString() as string)}</TableCell>
              <TableCell>{item?.Salesperson}</TableCell>
              <TableCell>{formatNumber(item?.CommissionAmount?.toString() as string)}</TableCell>
              <TableCell>{item?.IsPaid ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SalesCommissionsTableBlock
