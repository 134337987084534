import * as React from 'react'
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import MenuItem from '@mui/material/MenuItem'
import MenuList from '@mui/material/MenuList'
import { generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/material/styles'

const classesBtn = generateUtilityClasses('ButtonGroup', ['root', 'mainBtn'])

const StyledButtonGroup = styled(ButtonGroup)(() => ({
  [`&.${classesBtn.root}`]: {},
  [`& .${classesBtn.mainBtn}`]: {
    minWidth: '130px',
  },
}))

interface Props {
  options: string[]
  disabled?: boolean
  optionIndex?: number
  functions: Array<(event?: React.MouseEvent<HTMLLIElement | HTMLButtonElement, MouseEvent>, index?: number) => void>
  types: Array<'button' | 'submit' | 'reset' | undefined>
  classes?: {
    button?: string
  }
}

export const SplitButton = ({ optionIndex, options, functions, types, disabled }: Props) => {
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef<HTMLDivElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
    setSelectedIndex(index)
    functions[index](event, index) // immediately execute the selected action
    setOpen(false)
    if (optionIndex !== undefined) {
      setSelectedIndex(optionIndex as number)
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  return (
    <React.Fragment>
      <StyledButtonGroup variant='outlined' className={classesBtn.root} ref={anchorRef} aria-label='split button'>
        <Button
          color='inherit'
          variant='outlined'
          className={classesBtn.mainBtn}
          type={types[selectedIndex] || 'button'}
          onClick={(e) => {
            e.preventDefault()
            functions[selectedIndex](e)
          }}
          disabled={disabled}
        >
          {options[selectedIndex]}
        </Button>
        <Button
          color='inherit'
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label='select merge strategy'
          aria-haspopup='menu'
          onClick={handleToggle}
          disabled={disabled}
        >
          <ArrowDropDownIcon />
        </Button>
      </StyledButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu' autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem key={option} selected={index === selectedIndex} onClick={(event) => handleMenuItemClick(event, index)}>
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default SplitButton
