import dayjs from 'dayjs'
import * as yup from 'yup'
import { parseToNumber } from '../utils/general'

const today = dayjs().endOf('day')

export const accountingChecksValidation: yup.SchemaOf<any> = yup
  .object({
    startDate: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed').label('Start Date'),
    endDate: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed').label('End Date'),
  })
  .required()
export const accountingChecksAddValidation = (isModalOpen: boolean) => {
  return yup.object({
    Payment: yup.string().required(),
    // invoiceTo: yup.object().when('Payment', {
    //   is: 'Vendor',
    //   then: yup.object().required('Vendor is required'),
    //   otherwise: yup.object().nullable().notRequired(),
    // }),
    ...(isModalOpen
      ? {
          Branch: yup.mixed().required().label('Branch').typeError('Invalid Input'),
          ChartOfAccountBill: yup.mixed().required().label('Chart Of Account').typeError('Invalid Input'),
          Amount: yup
            .number()
            .transform((value, originalValue) => (originalValue === '' ? null : parseToNumber(originalValue)))
            .min(0.01, 'Amount must be greater than 0.00')
            .typeError('Invalid Input')
            .required()
            .label('Amount'),
        }
      : {
          Amount: yup.mixed().notRequired(),
        }),
  })
}

export const accountingChecksPaymentDetailsValidation = (checkID: number, stage: string) =>
  yup.object().shape({
    CheckDate: yup.date().nullable().required().typeError('Invalid Date').typeError('Invalid Input'),
    BankAccount: yup.number().required().label('Bank Account').typeError('Invalid Input'),
    BankBranch: yup.number().required().label('Bank Branch').typeError('Invalid Input'),
    BillPayType: yup.number().required().label('Bill Pay Type').typeError('Invalid Input'),
    CheckNumber: yup.string().when('BillPayType', {
      is: (billPayType: number) => billPayType === checkID && stage === 'paymentDetails',
      then: yup.string().required('Check Number is required').typeError('Invalid Input'),
      otherwise: yup.string().notRequired(),
    }),
    PayeeName: yup.string().required().label('Payee Name').typeError('Invalid Input'),
  })
