import { styled } from '@mui/system'
import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import React from 'react'
import { AmountField } from '../../../../../../../../../ui'
const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))
const ValuesForm = () => {
  return (
    <>
      <CardHeader title='Values' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <AmountField variant='standard' name='ACV' label='ACV' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='Allowance' label='Allowance' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='LienPayoff' label='Lien Payoff' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default ValuesForm
