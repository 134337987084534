import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { CarsDropdownResult } from '../../../../../models'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const SpecsForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const carDriveTrain = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarDrivetrain'))
  const carFuelType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarFuelType'))

  return (
    <Grid container spacing={3}>
      <Grid item container spacing={3} xs={6}>
        <Grid item xs={9}>
          <TextField disabled={disableFields} name='engine' label='Engine' fullWidth autoComplete='off' />
        </Grid>

        <Grid item xs={3}>
          <TextField disabled={disableFields} name='cylinders' label='Cylinders' fullWidth autoComplete='off' />
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <TextField disabled={disableFields} name='transmission' label='Transmission' fullWidth autoComplete='off' />
      </Grid>
      {carDriveTrain && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='drivetrainID'
            label='Drivetrain'
            options={carDriveTrain}
            optionKey='ID'
            optionValue='Drivetrain'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
      {carFuelType && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='fuelTypeID'
            label='Fuel Type'
            options={carFuelType}
            optionKey='ID'
            optionValue='FuelType'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}

      <Grid item container spacing={3} xs={6}>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='cityMPG' type='number' label='City MPG' fullWidth autoComplete='off' />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled={disableFields} name='hwyMPG' type='number' label='Highway MPG' fullWidth autoComplete='off' />
        </Grid>
      </Grid>

      <Grid item container spacing={3} xs={6}>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='weight' type='number' label='Weight' fullWidth autoComplete='off' />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled={disableFields} name='numberOfKeys' type='number' label='Number of Keys' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default SpecsForm
