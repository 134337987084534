import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { TextField } from '../../../../../../ui'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

export const SettingsOneForm = ({ onDropdownFormChange }: Props) => {
  return (
    <>
      <CardHeader title='Settings' />

      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={6}>
          <TextField name='ShortCode' label='Short Code' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Contact' label='Contact' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Website' label='Website' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Comments' label='Comments' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IsActive'
            label='Is Active'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsOneForm
