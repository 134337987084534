import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAddCarStatusMutation, useEditCarStatusMutation, useLazyCarStatusDataQuery } from '../../../../services'
import { UspConfigurationCarStatusViewResult } from '../../../../models/apiTypes'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationCarStatusViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditInventoryStatusesModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addCarStatus, { isLoading: isLoadingCarStatus, isSuccess: isSuccessCarStatus }] = useAddCarStatusMutation()
  const [editCarStatus, { isLoading: isLoadingEditCarStatus, isSuccess: isSuccessEditCarStatus }] = useEditCarStatusMutation()
  const [getCarStatusData, { isLoading: isLoadingGetCarStatus, isSuccess: isSuccessGetCarStatus, data }] = useLazyCarStatusDataQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editCarStatus({
        carStatusId: rowData?.ID?.toString(),
        uspConfigurationCarStatusAddEdit: {
          carStatus: values.Description,
          isActive: values.IsActive,
          isForSale: values?.ReadyForSale,
          isInService: values?.CarInService,
        },
      })
    } else {
      addCarStatus({
        uspConfigurationCarStatusAddEdit: {
          carStatus: values.Description,
          isActive: values.IsActive,
          isForSale: values?.ReadyForSale,
          isInService: values?.CarInService,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getCarStatusData({
        carStatusId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
        ReadyForSale: false,
        CarInService: false,
      })
    }
    if (isSuccessGetCarStatus && type === 'edit') {
      setInitialValues({
        Description: data?.CarStatus,
        IsActive: data?.IsActive,
        ReadyForSale: data?.IsForSale,
        CarInService: data?.IsInService,
      })
    }
  }, [isSuccessGetCarStatus, data, type])

  useEffect(() => {
    if (isSuccessCarStatus || isSuccessEditCarStatus) {
      onClose()
      submit()
    }
  }, [isSuccessCarStatus, isSuccessEditCarStatus])

  const isLoading = useMemo(
    () => isLoadingCarStatus || isLoadingEditCarStatus || isLoadingGetCarStatus,
    [isLoadingCarStatus, isLoadingEditCarStatus, isLoadingGetCarStatus],
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Inventory Status</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='ReadyForSale'
                    label='Indicates Car Is Ready For Sale?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='CarInService'
                    label='Indicates Car Is In Service?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditInventoryStatusesModal
