import React, { useCallback, useEffect } from 'react'
import { Button, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { CheckboxField, TextField } from '../../../../ui'
import AddIcon from '@mui/icons-material/Add'
import { useFormState } from 'react-final-form'
import { debounce } from 'lodash'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import { useLocation } from 'react-router-dom'
import { appRoute } from '../../../../constants'
import Iconify from '../../../../components/iconify'

const classes = generateUtilityClasses('Grid', ['root', 'titleWrap', 'searchWrap', 'checkboxWrap', 'searchBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(30),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.checkboxWrap}`]: {
    width: theme.spacing(30),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.searchBtn}`]: {
    width: '120px',
    margin: theme.spacing(0, 2, 0, 'auto'),
  },
}))
interface Props {
  title?: string
  handleOpenModal?: () => void
  submit: (...args: any) => any
}
const Header = ({ title, submit, handleOpenModal }: Props) => {
  const { values } = useFormState()
  const location = useLocation()
  const applicantExpensesUrl = location.pathname.includes(appRoute.SettingsLeadsCreditAppExpenses)

  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.searhString || values?.searhString !== '' || values?.isIncludeInactive || !values?.isIncludeInactive) {
      updateWithDebounce()
    }
  }, [values?.searhString, values?.isIncludeInactive])

  return (
    <Grid container spacing={2} px={3} pt={3} alignItems={'center'}>
      <Grid item>
        <TextField
          name='searhString'
          placeholder='Search'
          fullWidth
          InputProps={{
            id: 'search',
            autoComplete: 'search',
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item>
        <CheckboxField name='isIncludeInactive' label='Include Inactive' />
      </Grid>
    </Grid>
  )
}

export default Header
