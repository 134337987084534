import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { Button, generateUtilityClasses, Grid, Tooltip, Typography } from '@mui/material'
import { useFormState } from 'react-final-form'
import { CheckboxField } from '../../../../../ui'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/printer.svg'

const classes = generateUtilityClasses('Grid', ['root', 'titleWrap', 'checkboxWrap', 'btnWrap', 'printBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    padding: theme.spacing(2, 0, 2, 0),
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(1, 6, 2, 0),
  },
  [`& .${classes.checkboxWrap}`]: {
    margin: theme.spacing(0, 4, 0, 0),
    fontSize: '14px',
  },
  [`& .${classes.btnWrap}`]: {
    margin: theme.spacing(0, 0, 0, 'auto'),
  },
  [`& .${classes.printBtn}`]: {
    width: '30px',
    height: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
}))

interface Props {
  submit: (...args: any) => any
}

export const NoteHistoryHeader = ({ submit }: Props) => {
  const { values } = useFormState()
  useEffect(() => {
    submit(values)
  }, [values?.isIncludeAllHistoryByCar, values?.isIncludeCancelledNotes])

  return (
    <StyledGrid container p={3}>
      <Grid item className={classes.checkboxWrap}>
        <CheckboxField name='isIncludeAllHistoryByCar' label='Include History By VIN' />
      </Grid>
      <Grid item className={classes.checkboxWrap}>
        <CheckboxField name='isIncludeCancelledNotes' label='Include Cancelled Notes' />
      </Grid>
      <Grid item className={classes.btnWrap}>
        <Grid container>
          <Grid item>
            <Tooltip title='Print'>
              <Button color='success' variant='text' className={classes.printBtn}>
                <PrintIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  )
}

export default NoteHistoryHeader
