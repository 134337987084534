import { sub } from 'date-fns'
import { useRef, useMemo, useState, useCallback } from 'react'

import Stack from '@mui/material/Stack'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'

import { IChatParticipant } from './chat'
import Iconify from '../iconify'
import { useMockedUser } from '../../hooks/use-mocked-user'
import { useRouter } from '../../routes/hooks'
import { LoadingButton } from '@mui/lab'

// ----------------------------------------------------------------------

type Props = {
  recipients: IChatParticipant[]
  onAddRecipients: (recipients: IChatParticipant[]) => void
  //
  disabled: boolean
  selectedConversationId: string | null
  sendMessage: any
  isLoading: boolean
  setMessages: any
}

export default function ChatMessageInput({
  recipients,
  onAddRecipients,
  //
  disabled,
  selectedConversationId,
  setMessages,
  isLoading,
  sendMessage,
}: Props) {
  const router = useRouter()

  const { user } = useMockedUser()

  const fileRef = useRef<HTMLInputElement>(null)

  const [message, setMessage] = useState('')

  const myContact = useMemo(
    () => ({
      id: ``,
      role: ``,
      email: ``,
      address: ``,
      name: ``,
      lastActivity: new Date(),
      avatarUrl: ``,
      phoneNumber: ``,
      status: 'online' as 'online' | 'offline' | 'alway' | 'busy',
    }),
    [user],
  )

  const messageData = useMemo(
    () => ({
      id: '',
      attachments: [],
      body: message,
      contentType: 'text',
      createdAt: sub(new Date(), { minutes: 1 }),
      senderId: myContact.id,
    }),
    [message, myContact.id],
  )

  const conversationData = useMemo(
    () => ({
      id: '',
      messages: [messageData],
      participants: [...recipients, myContact],
      type: recipients.length > 1 ? 'GROUP' : 'ONE_TO_ONE',
      unreadCount: 0,
    }),
    [messageData, myContact, recipients],
  )

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click()
    }
  }, [])

  const handleChangeMessage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!isLoading) {
        setMessage(event.target.value)
      }
    },
    [isLoading],
  )

  const handleSendMessage = useCallback(
    async (event: React.KeyboardEvent<HTMLInputElement>) => {
      try {
        if (event.key === 'Enter' && !isLoading) {
          if (message) {
            const res: any = await sendMessage({
              data: {
                prompt: message,
                sessionID: selectedConversationId,
              },
            })
            if (!res.error) {
              if (res?.data && res?.data?.aiResponse.length > 0) {
                setMessages((prevVal: any) => [...prevVal, { prompt: message }, res?.data])
                setMessage('')
              }
            }
          }
          setMessage('')
        }
      } catch (error) {
        console.error(error)
      }
    },
    [conversationData, message, messageData, onAddRecipients, router, selectedConversationId],
  )

  return (
    <>
      <InputBase
        value={message}
        onKeyUp={handleSendMessage}
        onChange={handleChangeMessage}
        placeholder='Type a message'
        disabled={disabled}
        endAdornment={
          <Stack direction='row' sx={{ flexShrink: 0 }}>
            <LoadingButton
              onClick={async () => {
                try {
                  if (message && !isLoading) {
                    const res: any = await sendMessage({
                      data: {
                        prompt: message,
                        sessionID: null,
                      },
                    })
                    if (!res.error) {
                      if (res?.data && res?.data?.aiResponse.length > 0) {
                        setMessages((prevVal: any) => [...prevVal, { prompt: message }, res?.data])
                        setMessage('')
                      }
                    }
                  }
                  setMessage('')
                } catch (error) {
                  console.error(error)
                }
              }}
              variant='contained'
              loading={isLoading}
            >
              <Iconify icon='streamline:send-email' />
            </LoadingButton>
          </Stack>
        }
        sx={{
          px: 1,
          height: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      />

      {/* <input type='file' ref={fileRef} style={{ display: 'none' }} /> */}
    </>
  )
}
