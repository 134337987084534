import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { AmountField, CustomCircular, PercentField, TextField } from '../../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { UspStateCountyTaxRateViewResult } from '../../../../../models/apiTypes'
import { useAddCountyTaxRateMutation, useEditCountyTaxRateMutation, useLazyGetCountyTaxRateQuery } from '../../../../../services'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import removeMask from '../../../../../utils/removeMask'
import { parseToNumber } from '../../../../../utils/general'
import validateFormValues from '../../../../../utils/validateForm'
import { addStateCountyTaxesValidation } from '../../../../../validation/addStateCountyTaxesValidation'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspStateCountyTaxRateViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditCountyTaxes = ({ open, onClose, rowData, type, submit }: Props) => {
  const [formChanged, setFormChanged] = useState(false)
  const states = useSelector(getStates)
  const [initialValues, setInitialValues] = useState({})
  const [addCountyTaxes, { isLoading: isLoadingCountyTaxes, isSuccess: isSuccessCountyTaxes }] = useAddCountyTaxRateMutation()
  const [editCountyTaxes, { isLoading: isLoadingEditCountyTaxes, isSuccess: isSuccessEditCountyTaxes }] = useEditCountyTaxRateMutation()
  const [getCountyTaxesData, { isLoading: isLoadingGetCountyTaxes, isSuccess: isSuccessGetCountyTaxes, data }] = useLazyGetCountyTaxRateQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editCountyTaxes({
        countyTaxRateId: rowData?.ID?.toString(),
        uspStateCountyTaxRateAddEdit: {
          state: values.State,
          county: values.County,
          taxRate: values.TaxRate,
          taxCap: removeMask(values.TaxCapAmount?.toString()) as string,
        },
      })
    } else {
      addCountyTaxes({
        uspStateCountyTaxRateAddEdit: {
          state: values.State,
          county: values.County,
          taxRate: values.TaxRate,
          taxCap: removeMask(values.TaxCapAmount?.toString()) as string,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getCountyTaxesData({
        countyTaxRateId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        State: '',
        County: '',
        TaxRate: '0',
        TaxCapAmount: '0',
        IsCapped: false,
      })
    }
    if (isSuccessGetCountyTaxes && type === 'edit') {
      const taxCap = data?.TaxCap?.toString()
      setInitialValues({
        State: data?.State,
        County: data?.County,
        TaxRate: data?.TaxRate?.toString(),
        TaxCapAmount: taxCap,
        IsCapped: parseToNumber(taxCap as string) > 0 ? true : false,
      })
    }
  }, [isSuccessGetCountyTaxes, data, type])

  useEffect(() => {
    if (isSuccessEditCountyTaxes || isSuccessCountyTaxes) {
      onClose()
      setFormChanged(false)
      submit()
    }
  }, [isSuccessEditCountyTaxes, isSuccessCountyTaxes])

  const isLoading = useMemo(
    () => isLoadingCountyTaxes || isLoadingEditCountyTaxes || isLoadingGetCountyTaxes,
    [isLoadingCountyTaxes, isLoadingEditCountyTaxes, isLoadingGetCountyTaxes],
  )
  return (
    <Dialog
      open={open}
      onClose={() => {
        setFormChanged(false)
        onClose()
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <DialogTitle>County Tax</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(addStateCountyTaxesValidation)}>
        {({ handleSubmit, values, valid }: any) => (
          <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    required
                    name='State'
                    label='State'
                    options={states}
                    optionKey='State'
                    optionValue='State'
                    autoComplete='off'
                    onChange={() => setFormChanged(true)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField required name='County' label='County' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <PercentField variant='standard' required name='TaxRate' label='Tax Rate (%)' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    required
                    name='IsCapped'
                    label='Is Tax Capped?'
                    options={[
                      { key: true, value: 'Yes' },
                      { key: false, value: 'No' },
                    ]}
                    autoComplete='off'
                    onChange={() => setFormChanged(true)}
                  />
                </Grid>

                {values.IsCapped && (
                  <Grid item xs={12}>
                    <AmountField variant='standard' required name='TaxCapAmount' label='Tax Cap Amount ($)' fullWidth />
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions>
              <LoadingButton disabled={!formChanged || !valid} type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditCountyTaxes
