import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { MouseEvent, useEffect, useState } from 'react'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import DeleteIcon from '@mui/icons-material/Delete'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-final-form'
import { visuallyHidden } from '@mui/utils'
import { CheckSearchResponse } from '../../../../../models'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { useAccountingVoidCheckMutation } from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { formatNumber, getCurrentDate } from '../../../../../utils/general'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import Label from '../../../../../components/label/label'
import CustomPopover from '../../../../../components/custom-popover/custom-popover'
import Iconify from '../../../../../components/iconify'
import { usePopover } from '../../../../../components/custom-popover'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'inactiveItem', 'loading', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  data?: CheckSearchResponse[]
  loading: boolean
}

const AccountingChecksTableBlock = ({ data, loading }: Props) => {
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ checkId: string } | undefined>(undefined)
  const initialOrder = (storageGet('accounts_checks_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('accounts_checks_order_by') || 'StockNumber') as keyof CheckSearchResponse
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [selectedItem, setSelectedItem] = useState<any>()
  const [orderBy, setOrderBy] = useState<keyof CheckSearchResponse>(initialOrderBy)
  const [voidTransaction, { isSuccess: voidTransactionIsSuccess }] = useAccountingVoidCheckMutation()
  const { submit } = useForm()
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const popover = usePopover()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = data?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onRequestSort = (event: MouseEvent<unknown>, property: keyof CheckSearchResponse) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('accounts_checks_order', isAsc ? 'desc' : 'asc')
    storageSet('accounts_checks_order_by', property)
  }

  const createSortHandler = (property: keyof CheckSearchResponse) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleDelete = () => {
    if (deleteConfirmDialog?.checkId) {
      voidTransaction({
        checkId: deleteConfirmDialog?.checkId.toString() as string,
        accountsVoidCheck: {
          voidDate: getCurrentDate(),
        },
      })
    }
    setDeleteConfirmDialog(undefined)
  }

  useEffect(() => {
    if (voidTransactionIsSuccess) {
      submit()
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [voidTransactionIsSuccess, dispatch])

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }} className={classes.root}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillPayType' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BillPayType'}
                  direction={orderBy === 'BillPayType' ? order : 'asc'}
                  onClick={createSortHandler('BillPayType')}
                >
                  Type
                  {orderBy === 'BillPayType' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'PayToTheOrderOf' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PayToTheOrderOf'}
                  direction={orderBy === 'PayToTheOrderOf' ? order : 'asc'}
                  onClick={createSortHandler('PayToTheOrderOf')}
                >
                  Paid To
                  {orderBy === 'PayToTheOrderOf' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'VendorCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'VendorCode'}
                  direction={orderBy === 'VendorCode' ? order : 'asc'}
                  onClick={createSortHandler('VendorCode')}
                >
                  Vendor
                  {orderBy === 'VendorCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BankAccount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BankAccount'}
                  direction={orderBy === 'BankAccount' ? order : 'asc'}
                  onClick={createSortHandler('BankAccount')}
                >
                  CoA (Bank)
                  {orderBy === 'BankAccount' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch (Bank)
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'CheckAmount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'CheckAmount'}
                  direction={orderBy === 'CheckAmount' ? order : 'asc'}
                  onClick={createSortHandler('CheckAmount')}
                >
                  Amount
                  {orderBy === 'CheckAmount' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'CheckNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'CheckNumber'}
                  direction={orderBy === 'CheckNumber' ? order : 'asc'}
                  onClick={createSortHandler('CheckNumber')}
                >
                  Number
                  {orderBy === 'CheckNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'CheckDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'CheckDate'}
                  direction={orderBy === 'CheckDate' ? order : 'asc'}
                  onClick={createSortHandler('CheckDate')}
                >
                  Date
                  {orderBy === 'CheckDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsAp' ? order : false}>
                <TableSortLabel active={orderBy === 'IsAp'} direction={orderBy === 'IsAp' ? order : 'asc'} onClick={createSortHandler('IsAp')}>
                  Bill Pay
                  {orderBy === 'IsAp' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsVoid' ? order : false}>
                <TableSortLabel active={orderBy === 'IsVoid'} direction={orderBy === 'IsVoid' ? order : 'asc'} onClick={createSortHandler('IsVoid')}>
                  Status
                  {orderBy === 'IsVoid' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), data as any[])
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow hover key={item.CheckID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className={classes.tableCellName} onClick={() => {}}>
                    {item.BillPayType}
                  </TableCell>
                  <TableCell>{item?.PayToTheOrderOf}</TableCell>
                  <TableCell>{item?.VendorCode}</TableCell>
                  <TableCell>{item?.BankAccount}</TableCell>
                  <TableCell>{item?.BranchCode}</TableCell>
                  <TableCell>{formatNumber(item?.CheckAmount as string)}</TableCell>
                  <TableCell>{item?.CheckNumber}</TableCell>
                  <TableCell>{convertDateToDisplay(item?.CheckDate as string)}</TableCell>
                  <TableCell>{item.IsAp ? <Typography>Yes</Typography> : <Typography>No</Typography>}</TableCell>
                  <TableCell>
                    <Label color={item.IsVoid ? 'error' : 'success'}>{item.IsVoid ? 'Void' : 'Active'}</Label>
                  </TableCell>
                  <TableCell align={'right'}>
                    {userAccess?.CanVoidChecks && (
                      <IconButton
                        color={popover.open ? 'inherit' : 'default'}
                        onClick={(e) => {
                          setSelectedItem(item)
                          popover.onOpen(e)
                        }}
                      >
                        <Iconify icon='eva:more-vertical-fill' />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Paper>
          <TablePagination
            component='div'
            count={data?.length as number}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Are you sure you wish to void this transaction?'}
      />
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='right-top' sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            setDeleteConfirmDialog({ checkId: selectedItem?.CheckID as string })
            popover.onClose()
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default AccountingChecksTableBlock
