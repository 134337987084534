import React from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button, Tooltip } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/printer.svg'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download_icon.svg'
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter_icon.svg'
import { UspReportSalesResult } from '../../../../../models'
import * as XLSX from 'xlsx'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { parseToNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'btnWrap'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 3, 1, 3),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
}))

interface Props {
  handleOpenFilterModal: () => void
  filterData: UspReportSalesResult[]
}

export const Header = ({ handleOpenFilterModal, filterData }: Props) => {
  const transformDataForExport = (data: UspReportSalesResult[]) => {
    const feeLabels = Array?.from(new Set(data?.flatMap((record) => record.FeeData)?.map((fee) => fee.FeeLabel)))
    const productLabels = Array?.from(new Set(data?.flatMap((record) => record.ProductData)?.map((product) => product.ProductLabel)))

    return data.map((item: UspReportSalesResult) => {
      let itemData: any = {
        'Stock #': item.StockNumber,
        Buyer: item.Buyer,
        'Deal Date': convertDateToDisplay(item.DealDate),
        Car: item.Car,
        VIN: item.VIN,
        Branch: item.Branch,
        Lender: item.Lender,
        Salesperson: item.Salesperson,
        'F&I Manager': item.FIMgr,
        Advertisement: item.Advertisement,
        'Deal Type': item.DealType,
        'Deal State': item.DealState,
        'Unit Cost': parseToNumber(item.UnitCost),
        'Sales Price': parseToNumber(item.SalesPrice),
        'Net Trade': parseToNumber(item.NetTrade),
        'Trade Allowance': parseToNumber(item.TradeAllowance),
        'Trade Lien': parseToNumber(item.TradeLien),
        'Trade ACV': parseToNumber(item.TradeACV),
        'Sales Discount': parseToNumber(item.SalesDiscountAmount),
        Spread: parseToNumber(item.SpreadAmount),
        'Sales Commission': parseToNumber(item.SalesCommission),
        'Referral Fee': parseToNumber(item.ReferralFee),
        'Gross Profit': parseToNumber(item.GrossProfit),
        'Net Profit': parseToNumber(item.NetProfit),
        'Cash Down': parseToNumber(item.CashDown),
        Pickups: parseToNumber(item.Pickups),
        'Sales Tax': parseToNumber(item.SalesTax),
        'Tax Rate': item.TaxRate,
        'Amount Financed': parseToNumber(item.AmountFinanced),
        'Finance Charges': parseToNumber(item.FinanceCharges),
        'Total Payments': parseToNumber(item.TotalOfPayments),
        'Finalized Date': convertDateToDisplay(item.FinalizedDate),
        'Full VIN': item.FullVIN,
        'Buyer State': item.BuyerState,
        'Buyer Phone': item.BuyerPhone,
        'Co-Buyer': item.CoBuyer,
        'Tax Zip': item.TaxZip,
        'Tax State': item.TaxState,
        'Tax City': item.TaxCityName,
        'Tax County': item.TaxCountyName,
        'State Tax': parseToNumber(item.StateTaxAmount),
        'City Tax': parseToNumber(item.CityTaxAmount),
        'County Tax': parseToNumber(item.CountyTaxAmount),
        'Misc Tax': parseToNumber(item.MiscTaxAmount),
        'Doc Stamps': parseToNumber(item.DocStamps),
        PPFC: parseToNumber(item.PPFC?.toString()),
        APR: parseToNumber(item.APR?.toString()),
        'Is Finalized': item.IsFinalized ? 'Yes' : 'No',
      }

      // Add fee data
      feeLabels?.forEach((feeLabel) => {
        const fee = item.FeeData?.find((f) => f.FeeLabel === feeLabel)
        itemData[feeLabel] = fee ? parseToNumber(fee.FeeAmount?.toString()) : '-'
      })

      // Add product data
      productLabels?.forEach((productLabel) => {
        const product = item.ProductData?.find((p) => p.ProductLabel === productLabel)
        itemData[`${productLabel} Price`] = product ? parseToNumber(product.ProductPrice?.toString()) : '-'
        itemData[`${productLabel} Cost`] = product ? parseToNumber(product.ProductCost?.toString()) : '-'
      })

      return itemData
    })
  }

  const setColumnWidths = (worksheet: any) => {
    const columnWidths = [
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
    ]

    worksheet['!cols'] = columnWidths
  }

  const exportToExcel = (data: any, fileName: any) => {
    const transformedData = transformDataForExport(data)
    const worksheet = XLSX.utils.json_to_sheet(transformedData)
    setColumnWidths(worksheet)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }

  return (
    <>
      <Grid container justifyContent={'flex-end'} p={3} alignItems={'center'}>
        <Grid item>
          <Tooltip title='Download'>
            <Button variant='text' className={classes.btnWrap} onClick={() => exportToExcel(filterData, 'Sales')}>
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Print'>
            <Button variant='text' className={classes.btnWrap}>
              <PrintIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Filter'>
            <Button onClick={handleOpenFilterModal} variant='text' className={classes.btnWrap}>
              <FilterIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}

export default Header
