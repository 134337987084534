import { convertDateToString } from '../../../utils/dateUtils'

export const transformToFormValues = (values?: any) => {
  const mapTitleSentTo = (titleSentTo: any) => {
    switch (titleSentTo) {
      case 'Customer':
        return 1
      case 'Dealer':
        return 2
      case 'Lender':
        return 3
      case 'Other':
        return 4
      default:
        return titleSentTo
    }
  }

  return {
    TitleAppliedFor: values?.IsTitleAppliedFor,
    DateApplied: values?.DateApplied,
    AppliedForBy: values?.AppliedForByUserID,
    TitleReceived: values?.IsTitleReceived,
    DateReceived: values?.DateTitleReceived,
    TitleNumber: values?.TitleNumber,
    TitleState: values?.TitleState,
    TitleType: values?.TitleTypeID,
    IsTitleSent: values?.IsTitleSent,
    DateSent: values?.DateTitleSent,
    SendTo: mapTitleSentTo(values?.TitleSentTo),
    SentBy: values?.SentByUserID,
    Note: values?.Note,
  }
}
export const transformToRequestValues = (values?: any) => {
  const mapTitleSentTo = (titleSentTo: any) => {
    switch (titleSentTo) {
      case 1:
        return 'Customer'
      case 2:
        return 'Dealer'
      case 3:
        return 'Lender'
      case 4:
        return 'Other'
      default:
        return titleSentTo
    }
  }
  return {
    isTitleAppliedFor: values?.TitleAppliedFor,
    dateApplied: convertDateToString(values?.DateApplied),
    appliedForByUserID: values?.AppliedForBy,
    isTitleReceived: values?.TitleReceived,
    dateTitleReceived: convertDateToString(values?.DateReceived),
    titleNumber: values?.TitleNumber,
    titleState: values?.TitleState,
    titleTypeID: values?.TitleType,
    isTitleSent: values?.IsTitleSent,
    dateTitleSent: convertDateToString(values?.DateSent),
    titleSentTo: mapTitleSentTo(values?.SendTo),
    sentByUserID: values?.SentBy,
    note: values?.Note,
  }
}
