import React, { useEffect, useState } from 'react'
import Header from '../components/Header/Header'
import AdminLotsTable from './components/AdminLotsTable'
import AddEditLotsModal from './components/AddEditLotsModal'
import { Form } from 'react-final-form'
import { useSearchLotsMutation } from '../../../services'
import { UspConfigurationLotViewResult } from '../../../models'
import { Button, Card, Container, Grid } from '@mui/material'
import { usePopover } from '../../../components/custom-popover'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const SettingsAdminLots = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchLots, { data, isLoading }] = useSearchLotsMutation()
  const [lotsData, setLotsData] = useState<UspConfigurationLotViewResult[]>([])
  const [selected, setSelected] = useState<any>(null)
  const popover = usePopover()

  const handleSubmit = (values?: any) => {
    searchLots({
      simpleSearch: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setLotsData(data)
    }
  }, [data])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='Lots'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button onClick={() => handleOpenModal()} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <Card>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='Lots' handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <AdminLotsTable
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                      handleOpenModal={handleOpenModal}
                      loading={isLoading}
                      data={lotsData}
                      setData={setLotsData}
                    />
                  </Grid>
                </Grid>
              </form>
            </Card>
            <AddEditLotsModal
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspConfigurationLotViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsAdminLots
