import Grid from '@mui/material/Grid'
import { Header } from './components'
import { useGetTaskDealQuery, useLazyGetTaskDealQuery } from '../../../services'
import SettingsCard from './components/SettingsCard'
import BuyerCard from './components/BuyerCard'
import CoBuyerCard from './components/CoBuyerCard'
import InsuranceCard from './components/InsuranceCard'
import { useNavigate, useParams } from 'react-router-dom'
import TradesCard from './components/TradesCard'
import PropductsCard from './components/PropductsCard'
import { Box, Container, Stack, Tab, Tabs } from '@mui/material'
import CommissionsCard from './components/CommissionsCard'
import ReferencesCard from './components/ReferencesCard'
import ReferralsCard from './components/ReferralsCard'
import NotesCard from './components/NotesCard'
import { useCallback, useEffect, useState } from 'react'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Label from '../../../components/label/label'
import { LabelColor } from '../../../components/label'
import { useDispatch } from 'react-redux'
import Iconify from '../../../components/iconify'
import DealGeneral from './components/deals-general'
import DealNotes from './components/deals-notes'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'

export const DealsTask = () => {
  const { dealID } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [currentTab, setCurrentTab] = useState('general')
  const { data: dealTask } = useGetTaskDealQuery({ dealId: dealID as string })
  const [getDealTaskData] = useLazyGetTaskDealQuery()

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue)
  }, [])

  useEffect(() => {
    // if redirected from another page (i.e. add references page)
    if (dealID) {
      getDealTaskData({
        dealId: dealID as string,
      })
    }
  }, [dealID])

  useEffect(() => {
    if (dealTask?.IsDeleted) {
      dispatch(enqueueNotification({ message: 'Deal has been deleted', options: { variant: 'warning' } }))
      navigate(appRoute?.DealsList)
    }
    if (dealTask?.IsFinalized) {
      dispatch(enqueueNotification({ message: 'Deal is finalized and cannot be edited.', options: { variant: 'warning' } }))
      navigate(appRoute?.DealsList)
    }
  }, [dealTask])

  const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon='solar:user-id-bold' width={24} />,
    },
    {
      value: 'notes',
      label: 'Notes',
      icon: <Iconify icon='solar:notes-bold' width={24} />,
    },
  ]
  return (
    <>
      {/* <Header />
      <Grid container spacing={2} sx={{ padding: 2 }}>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
          <Box display='flex' flexDirection='column' gap={2}>
            <BuyerCard />
            {dealTask?.IsHasCoBuyer && <CoBuyerCard />}
            <SettingsCard />
            <InsuranceCard />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8} xl={5}>
          <Box display='flex' flexDirection='column' gap={2}>
            {dealTask?.IsHasTrade && <TradesCard />}

            {dealTask?.IsHasProducts && <PropductsCard />}

            <CommissionsCard />

            <ReferencesCard />

            <ReferralsCard />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={8} lg={8} xl={4}>
          <Box display='flex' flexDirection='column' gap={2}>
            <NotesCard />
          </Box>
        </Grid>
      </Grid> */}

      <Container>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack direction={'row'}>
            <CustomBreadcrumbs
              heading='Deal'
              links={[
                { name: 'List', href: '/deals' },
                {
                  name: `${dealTask?.CarDescription} | Stock # ${dealTask?.StockNumber} | VIN : ${dealTask?.VIN} `,
                },
              ]}
              sx={{
                mb: { xs: 3, md: 5 },
              }}
            />
            <Label color={dealTask?.DealStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
              {dealTask?.DealStatus}
            </Label>
          </Stack>

          <Header />
        </Stack>

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
          ))}
        </Tabs>

        {currentTab === 'general' && <DealGeneral data={dealTask} />}

        {currentTab === 'notes' && <DealNotes data={dealTask} />}
      </Container>
    </>
  )
}

export default DealsTask
