import React from 'react'
import { Box, Grid, generateUtilityClasses, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { formatNumber } from '../../../../../utils/general'
import { UspReportAccountsReceivableAgingResult } from '../../../../../models'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    minWidth: '170px',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportAccountsReceivableAgingResult[]
  isLoading: boolean
}

const ReceivablesAgingTableBlock = ({ filterData, isLoading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('reports_receivables_aging_ar_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('reports_receivables_aging_ar_order_by') || 'Customer') as keyof UspReportAccountsReceivableAgingResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspReportAccountsReceivableAgingResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportAccountsReceivableAgingResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('reports_receivables_aging_ar_order', isAsc ? 'desc' : 'asc')
    storageSet('reports_receivables_aging_ar_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportAccountsReceivableAgingResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock #
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Customer' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Customer'}
                direction={orderBy === 'Customer' ? order : 'asc'}
                onClick={createSortHandler('Customer')}
              >
                Customer
                {orderBy === 'Customer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'AccountNumber'}
                direction={orderBy === 'AccountNumber' ? order : 'asc'}
                onClick={createSortHandler('AccountNumber')}
              >
                Account #
                {orderBy === 'AccountNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Dealer' ? order : false}>
              <TableSortLabel active={orderBy === 'Dealer'} direction={orderBy === 'Dealer' ? order : 'asc'} onClick={createSortHandler('Dealer')}>
                Dealer
                {orderBy === 'Dealer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Lender' ? order : false}>
              <TableSortLabel active={orderBy === 'Lender'} direction={orderBy === 'Lender' ? order : 'asc'} onClick={createSortHandler('Lender')}>
                Lender
                {orderBy === 'Lender' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Collector' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Collector'}
                direction={orderBy === 'Collector' ? order : 'asc'}
                onClick={createSortHandler('Collector')}
              >
                Collector
                {orderBy === 'Collector' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'NextDue' ? order : false}>
              <TableSortLabel active={orderBy === 'NextDue'} direction={orderBy === 'NextDue' ? order : 'asc'} onClick={createSortHandler('NextDue')}>
                Next Due
                {orderBy === 'NextDue' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DaysPastDue' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DaysPastDue'}
                direction={orderBy === 'DaysPastDue' ? order : 'asc'}
                onClick={createSortHandler('DaysPastDue')}
              >
                Days Past Due
                {orderBy === 'DaysPastDue' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Schedule' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Schedule'}
                direction={orderBy === 'Schedule' ? order : 'asc'}
                onClick={createSortHandler('Schedule')}
              >
                Schedule
                {orderBy === 'Schedule' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Payment' ? order : false}>
              <TableSortLabel active={orderBy === 'Payment'} direction={orderBy === 'Payment' ? order : 'asc'} onClick={createSortHandler('Payment')}>
                Payment
                {orderBy === 'Payment' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Balance' ? order : false}>
              <TableSortLabel active={orderBy === 'Balance'} direction={orderBy === 'Balance' ? order : 'asc'} onClick={createSortHandler('Balance')}>
                Balance
                {orderBy === 'Balance' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Current' ? order : false}>
              <TableSortLabel active={orderBy === 'Current'} direction={orderBy === 'Current' ? order : 'asc'} onClick={createSortHandler('Current')}>
                Current
                {orderBy === 'Current' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Category1' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Category1'}
                direction={orderBy === 'Category1' ? order : 'asc'}
                onClick={createSortHandler('Category1')}
              >
                1-30 Days
                {orderBy === 'Category1' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Category2' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Category2'}
                direction={orderBy === 'Category2' ? order : 'asc'}
                onClick={createSortHandler('Category2')}
              >
                31-60 Days
                {orderBy === 'Category2' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Category3' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Category3'}
                direction={orderBy === 'Category3' ? order : 'asc'}
                onClick={createSortHandler('Category3')}
              >
                61-90 Days
                {orderBy === 'Category3' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Category4' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Category4'}
                direction={orderBy === 'Category4' ? order : 'asc'}
                onClick={createSortHandler('Category4')}
              >
                91+ Days
                {orderBy === 'Category4' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.InventoryEdit.replace(':carID', item?.CarID?.toString() as string))}
              >
                {item.StockNumber}
              </TableCell>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))}
              >
                {item?.Customer}
              </TableCell>
              <TableCell>{item?.AccountNumber}</TableCell>
              <TableCell>{item?.Branch}</TableCell>
              <TableCell>{item?.Dealer}</TableCell>
              <TableCell>{item?.Lender}</TableCell>
              <TableCell>{item?.Collector}</TableCell>
              <TableCell>{convertDateToDisplay(item?.NextDue as string)}</TableCell>
              <TableCell>{item?.DaysPastDue}</TableCell>
              <TableCell>{item?.Schedule}</TableCell>
              <TableCell>{formatNumber(item?.Payment as string)}</TableCell>
              <TableCell>{formatNumber(item?.Balance as string)}</TableCell>
              <TableCell>{formatNumber(item?.Current as string)}</TableCell>
              <TableCell>{formatNumber(item?.Category1 as string)}</TableCell>
              <TableCell>{formatNumber(item?.Category2 as string)}</TableCell>
              <TableCell>{formatNumber(item?.Category3 as string)}</TableCell>
              <TableCell>{formatNumber(item?.Category4 as string)}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReceivablesAgingTableBlock
