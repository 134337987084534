import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { AmountField } from '../../../../../../../ui'
import { useForm, useFormState } from 'react-final-form'
import { useEffect, useRef } from 'react'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  totalAmount: number | string
}

const FinalizeCard = ({ totalAmount }: Props) => {
  const form = useForm()
  const { values } = useFormState()
  const previousProcessingFee = useRef(values.ProcessingFee)
  const previousTotalPayment = useRef(values.TotalPayment)

  useEffect(() => {
    if (values?.TypeDistribution?.toLowerCase() === 'downpayment') {
      form.change('TotalPayment', values?.Amount)

      let totalAmountStr = values?.Amount
      if (typeof totalAmountStr === 'string') {
        totalAmountStr = totalAmountStr.replace(/,/g, '')
      }
      const totalAmountNumber = Number(totalAmountStr) || 0
      const processingFee = Number((values?.ProcessingFee || '0').replace(/,/g, '')) || 0
      form.change('TotalPayment', (totalAmountNumber + processingFee).toString())
    }

    if (values?.TypeSetup?.toLowerCase() === 'loan') {
      form.change('TotalPayment', totalAmount?.toString())
      const processingFee = Number((values?.ProcessingFee || '0').replace(/,/g, ''))
      const totalAmountStr = typeof totalAmount === 'string' ? totalAmount.replace(/,/g, '') : totalAmount
      const totalAmountNumber = Number(totalAmountStr || '0')
      form.change('TotalPayment', (totalAmountNumber + processingFee).toString())
    }

    if (previousTotalPayment.current !== values.TotalPayment) {
      form.change('AmountTendered', values?.TotalPayment)
      previousTotalPayment.current = values.TotalPayment
    }

    const amountTendered = Number((values?.AmountTendered || '0').replace(/,/g, ''))
    const totalPayment = Number((values?.TotalPayment || '0').replace(/,/g, ''))
    if (amountTendered > totalPayment) {
      let calculate = amountTendered - totalPayment
      calculate = Math.round(calculate * 100) / 100
      form.change('Change', calculate.toString())
    } else {
      form.change('Change', '0')
    }
    previousProcessingFee.current = values.ProcessingFee
  }, [totalAmount, values?.TypeDistribution, values?.ProcessingFee, values?.AmountTendered, values?.TotalPayment])

  return (
    <StyledGrid className={classes.root}>
      <Grid className={classes.row}>
        <AmountField variant='standard' name='ProcessingFee' label='Processing Fee' fullWidth />
      </Grid>
      <Grid className={classes.row}>
        <AmountField variant='standard' disabled name='TotalPayment' label='Total Payment' fullWidth />
      </Grid>
      <Grid className={classes.row}>
        <AmountField
          variant='standard'
          defaultValue={values?.TotalPayment}
          name='AmountTendered'
          label='Amount Tendered'
          fullWidth
          InputProps={{
            inputProps: {
              min: values?.TotalPayment,
            },
          }}
        />
      </Grid>
      <Grid className={classes.row}>
        <AmountField variant='standard' name='Change' label='Change' fullWidth disabled />
      </Grid>
    </StyledGrid>
  )
}

export default FinalizeCard
