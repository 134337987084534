import { Button, Card, Container, Grid, Paper, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import {
  TFullResponse,
  TSelectedSearchAccount,
  UspAccountingChartOfAccountCurrentView,
  UspAccountingReturnControlDataResult,
  UspListCarExpenseTypeResult,
} from '../../../models'
import { Form } from 'react-final-form'
import Header from './components/Header/Header'
import { formatNumber, parseToNumber } from '../../../utils/general'
import {
  useAddAccountingAdjustingEntryMutation,
  useGetAccountingChartOfAccountCurrentMutation,
  useLazyGetAccountingControlDataQuery,
  useLazyListsCarsExpenseTypeQuery,
} from '../../../services'
import { CustomCircular } from '../../../ui'
import EntriesTable from './components/EntriesTable/EntriesTable'
import SettingsForm from './components/SettingsForm/SettingsForm'
import { transformToFormValues, transformToRequestValues } from './transform'
import { withPermissionsDropdown } from '../../../hoc'
import validateFormValues from '../../../utils/validateForm'
import { accountingJournalEntriesValidation } from '../../../validation/accountingJournalEntriesValidation'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import JournalEntryAddEditModal from './components/JournalEntryAddEditModal/JournalEntryAddEditModal'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { appRoute } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import InvoiceNewEditStatusDate from './components/MultilineNewEditStatusDate'
import InvoiceNewEditDetails from './components/MultilineNewEditDetails'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'closeBtn'])

const StyledGrid = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 2, 2),
    display: 'flex',
    alignItems: 'flexStart',
    justifyContent: 'justify-start',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 3),
    marginTop: 'auto',
  },
  [`& .${classes.closeBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const AccountingJournalEntry = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const [totalSum, setTotalSum] = useState<number | string>('0')
  const [tableData, setTableData] = useState<TFullResponse[]>([])
  const [initialValues, setInitialValues] = useState({})
  const [requestDealID, setRequestDealID] = useState<null | number>(null)
  const [requestCarID, setRequestCarID] = useState<null | number>(null)
  const [isFieldsLocked, setIsFieldsLocked] = useState(false)
  const [entryToDelete, setEntryToDelete] = useState<null | TFullResponse>(null)
  const [openAddJournalEntryModal, setOpenAddJournalEntryModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState<TFullResponse | {}>({})
  // State to hold the currently edited items ID
  const [editingItemIndex, setEditingItemIndex] = useState<null | number>(null)
  const [selectedSearchAccount, setSelectedSearchAccount] = useState<TSelectedSearchAccount | {}>({})
  const [isEdit, setIsEdit] = useState(false)
  const [tableHasData, setTableHasData] = useState(false)

  const [getListsCarsExpense, { data: listsCarsExpenseData, isLoading: listsCarsExpenseIsLoading }] = useLazyListsCarsExpenseTypeQuery()
  const [getAccountingControlData, { data: accountingControlData, isLoading: accountingControlDataIsLoading }] =
    useLazyGetAccountingControlDataQuery()
  const [chartOfAccountBill, { data: chartOfAccountBillData, isLoading: chartOfAccountBillIsLoading }] =
    useGetAccountingChartOfAccountCurrentMutation()

  const [addEntry, { isLoading: addEntryIsLoading, isSuccess: addEntryIsSuccess }] = useAddAccountingAdjustingEntryMutation()

  const handleOpenAddJournalEntryModal = () => setOpenAddJournalEntryModal(true)
  const handleCloseAddJournalEntryModal = () => setOpenAddJournalEntryModal(false)

  useEffect(() => {
    setInitialValues(transformToFormValues(modalDefaultValues))
  }, [modalDefaultValues, editingItemIndex, isEdit, openAddJournalEntryModal])

  useEffect(() => {
    chartOfAccountBill({
      uspChartOfAccountView: {
        currentID: null,
        isAllowedSelectControlledAccount: true,
        isIncludeAccountsThatCannotBeSelected: true,
        isReturnAllCompanyTypes: false,
      },
    })
  }, [])

  useEffect(() => {
    const updatedTableData = tableData?.filter((item: TFullResponse) => item?.ID !== entryToDelete?.ID)
    setTableData(updatedTableData)
    handleCloseAddJournalEntryModal()
    setEntryToDelete(null)
  }, [entryToDelete])

  useEffect(() => {
    // Calculate total sum from the table
    const total = tableData?.reduce((acc: number, item: TFullResponse) => {
      const billAmount = item?.BillAmount ? parseToNumber(item?.BillAmount?.toString()) : 0
      return acc + billAmount
    }, 0)
    setTotalSum(total)
  }, [tableData])

  const handleSubmit = (values?: any) => {
    addEntry({
      uspAccountingAdjustingEntry: transformToRequestValues(values, tableData) as any,
    })
  }

  const handleDeleteEntry = (item: TFullResponse) => {
    setEntryToDelete(item)
  }

  useEffect(() => {
    if (tableData?.length === 0) {
      setTableHasData(false)
      setIsFieldsLocked(false)
    }
    if (tableData?.length !== 0) {
      setTableHasData(true)
    }
  }, [tableData])

  useEffect(() => {
    if (addEntryIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setTotalSum('0')
      setTableData([])
      setIsFieldsLocked(false)
      setEntryToDelete(null)
      setRequestDealID(null)
      setRequestCarID(null)
      setModalDefaultValues({})
      setSelectedSearchAccount({})
      setTableHasData(false)
    }
  }, [addEntryIsSuccess])

  const isLoading = useMemo(
    () => listsCarsExpenseIsLoading || accountingControlDataIsLoading || chartOfAccountBillIsLoading || addEntryIsLoading,
    [listsCarsExpenseIsLoading, accountingControlDataIsLoading, chartOfAccountBillIsLoading, addEntryIsLoading],
  )

  const handleSave = (newData: TFullResponse) => {
    const itemWithIndex = {
      ...newData,
    }
    setTableData((prevData) => [...prevData, itemWithIndex])
  }

  // Function to update a specific item in the tables data
  const updateTableItem = (newItem: TFullResponse) => {
    setTableData((prevData) => {
      const updatedData = [...prevData]
      if (editingItemIndex !== null && editingItemIndex >= 0) {
        updatedData[editingItemIndex] = newItem
      }
      return updatedData
    })
  }

  const handleModalSave = (newData: TFullResponse) => {
    if (editingItemIndex !== null) {
      updateTableItem(newData)
    } else {
      handleSave(newData)
    }
    handleCloseAddJournalEntryModal()
    setEditingItemIndex(null) // Reset the editing index
  }

  useEffect(() => {
    if (!userAccess.CanAddJournalEntry) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Journal Entry'
        links={[
          {
            name: 'Journal',
            // href: paths.dashboard.root,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validateFormValues(accountingJournalEntriesValidation(openAddJournalEntryModal))}
      >
        {({ handleSubmit, invalid }: any) => (
          <form onSubmit={handleSubmit}>
            <ReactRouterPrompt when={tableHasData}>
              {({ isActive, onConfirm, onCancel }) => (
                <ConfirmDialog open={!!isActive} onOk={onConfirm} handleClose={onCancel} notification={'Warning: Your changes haven’t been saved.'} />
              )}
            </ReactRouterPrompt>

            <Card>
              <InvoiceNewEditStatusDate userAccess={userAccess} addEntryIsSuccess={addEntryIsSuccess} isFieldsLocked={isFieldsLocked} />

              <InvoiceNewEditDetails
                setIsEdit={setIsEdit}
                handleOpenAddJournalEntryModal={handleOpenAddJournalEntryModal}
                handleDeleteEntry={handleDeleteEntry}
                tableData={tableData}
                setModalDefaultValues={setModalDefaultValues}
                setEditingItemIndex={setEditingItemIndex}
              />
              <Grid container justifyContent='flex-end' p={3}>
                <Button type='submit' variant='contained' disabled={!(tableData?.length > 1 && (totalSum as number) === 0)}>
                  Save
                </Button>
              </Grid>
            </Card>

            {/* <Grid container rowSpacing={1} columnSpacing={1} className={classes.pageBody}>
            <Grid item xs={12} md={4} xl={3} lg={4}>
              <Paper className={classes.formItem}>
                <SettingsForm
                  userAccess={userAccess}
                  addEntryIsSuccess={addEntryIsSuccess}
                  isFieldsLocked={isFieldsLocked}
                  handleOpenAddJournalEntryModal={handleOpenAddJournalEntryModal}
                />
              </Paper>
            </Grid>
            <Grid item xs={12} md={8} xl={9} lg={8}>
              <EntriesTable
                setIsEdit={setIsEdit}
                handleOpenAddJournalEntryModal={handleOpenAddJournalEntryModal}
                handleDeleteEntry={handleDeleteEntry}
                tableData={tableData}
                setModalDefaultValues={setModalDefaultValues}
                setEditingItemIndex={setEditingItemIndex}
              />
            </Grid>
          </Grid>

          <Grid item xs={10} className={classes.buttonWrap} justifyContent='space-between'>
            <Button type='submit' className={classes.closeBtn} variant='contained' disabled={!(tableData?.length > 1 && (totalSum as number) === 0)}>
              Save
            </Button>
            <Paper sx={{ padding: '10px' }}>
              <Typography variant='h3'>Net: {formatNumber(totalSum)}</Typography>
            </Paper>
          </Grid> */}
            <JournalEntryAddEditModal
              tableData={tableData}
              modalDefaultValues={modalDefaultValues}
              setModalDefaultValues={setModalDefaultValues}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              totalSum={totalSum}
              selectedSearchAccount={selectedSearchAccount as TSelectedSearchAccount}
              setSelectedSearchAccount={setSelectedSearchAccount}
              addEntryIsSuccess={addEntryIsSuccess}
              editingItemIndex={editingItemIndex}
              setEditingItemIndex={setEditingItemIndex}
              errors={invalid}
              onSave={handleModalSave}
              requestDealID={requestDealID}
              requestCarID={requestCarID}
              lockFields={() => setIsFieldsLocked(true)}
              open={openAddJournalEntryModal}
              handleClose={handleCloseAddJournalEntryModal}
              listsCarsExpenseData={listsCarsExpenseData as UspListCarExpenseTypeResult[]}
              setRequestCarID={setRequestCarID}
              setRequestDealID={setRequestDealID}
              getListsCarsExpense={getListsCarsExpense}
              getAccountingControlData={getAccountingControlData}
              accountingControlData={accountingControlData as UspAccountingReturnControlDataResult}
              chartOfAccountBillData={chartOfAccountBillData as UspAccountingChartOfAccountCurrentView[]}
            />

            <CustomCircular open={isLoading} />
          </form>
        )}
      </Form>
    </Container>
  )
}

export default withPermissionsDropdown(AccountingJournalEntry)
