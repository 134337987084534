import { storageGet, storageSet } from '../../../../../utils/storage'
import { UspDealAmortizationViewResult } from '../../../../../models'
import React from 'react'
import {
  Box,
  CircularProgress,
  generateUtilityClasses,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { styled } from '@mui/system'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'loading', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  dealAmortizationData: UspDealAmortizationViewResult[]
  loading: boolean
}

const ReferralsTable = ({ dealAmortizationData, loading }: Props) => {
  const initialOrder = (storageGet('accounts_amortization_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('accounts_amortization_order_by') || 'Type') as keyof UspDealAmortizationViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspDealAmortizationViewResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspDealAmortizationViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('accounts_amortization_order', isAsc ? 'desc' : 'asc')
    storageSet('accounts_amortization_order_by', property)
  }

  const createSortHandler = (property: keyof UspDealAmortizationViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: '70vh' }} className={classes.root}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Type' ? order : false}>
                  <TableSortLabel active={orderBy === 'Type'} direction={orderBy === 'Type' ? order : 'asc'} onClick={createSortHandler('Type')}>
                    Type
                    {orderBy === 'Type' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'PaymentNumber' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'PaymentNumber'}
                    direction={orderBy === 'PaymentNumber' ? order : 'asc'}
                    onClick={createSortHandler('PaymentNumber')}
                  >
                    Number
                    {orderBy === 'PaymentNumber' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'DueDate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'DueDate'}
                    direction={orderBy === 'DueDate' ? order : 'asc'}
                    onClick={createSortHandler('DueDate')}
                  >
                    Due
                    {orderBy === 'DueDate' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'GrossBalance' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'GrossBalance'}
                    direction={orderBy === 'GrossBalance' ? order : 'asc'}
                    onClick={createSortHandler('GrossBalance')}
                  >
                    Gross Balance
                    {orderBy === 'GrossBalance' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} sortDirection={orderBy === 'PrincipalBalance' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'PrincipalBalance'}
                    direction={orderBy === 'PrincipalBalance' ? order : 'asc'}
                    onClick={createSortHandler('PrincipalBalance')}
                  >
                    Principal Balance
                    {orderBy === 'PrincipalBalance' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} sortDirection={orderBy === 'PaymentAmount' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'PaymentAmount'}
                    direction={orderBy === 'PaymentAmount' ? order : 'asc'}
                    onClick={createSortHandler('PaymentAmount')}
                  >
                    Payment
                    {orderBy === 'PaymentAmount' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Interest' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Interest'}
                    direction={orderBy === 'Interest' ? order : 'asc'}
                    onClick={createSortHandler('Interest')}
                  >
                    Interest
                    {orderBy === 'Interest' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Principal' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Principal'}
                    direction={orderBy === 'Principal' ? order : 'asc'}
                    onClick={createSortHandler('Principal')}
                  >
                    Principal
                    {orderBy === 'Principal' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'TotalInterest' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'TotalInterest'}
                    direction={orderBy === 'TotalInterest' ? order : 'asc'}
                    onClick={createSortHandler('TotalInterest')}
                  >
                    Total Interest
                    {orderBy === 'TotalInterest' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} sortDirection={orderBy === 'LateCharge' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'LateCharge'}
                    direction={orderBy === 'LateCharge' ? order : 'asc'}
                    onClick={createSortHandler('LateCharge')}
                  >
                    Late Fee
                    {orderBy === 'LateCharge' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} sortDirection={orderBy === 'LateChargeDate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'LateChargeDate'}
                    direction={orderBy === 'LateChargeDate' ? order : 'asc'}
                    onClick={createSortHandler('LateChargeDate')}
                  >
                    Late Date
                    {orderBy === 'LateChargeDate' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), dealAmortizationData as any[])?.map((item: any) => (
                <TableRow key={item?.PaymentNumber} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {<TableCell>{item?.Type}</TableCell>}
                  {<TableCell>{item?.PaymentNumber}</TableCell>}
                  {<TableCell>{convertDateToDisplay(item?.DueDate)}</TableCell>}
                  {<TableCell>{formatNumber(item?.GrossBalance)}</TableCell>}
                  {<TableCell>{formatNumber(item?.PrincipalBalance)}</TableCell>}
                  {<TableCell>{formatNumber(item?.PaymentAmount)}</TableCell>}
                  {<TableCell>{formatNumber(item?.Interest)}</TableCell>}
                  {<TableCell>{formatNumber(item?.Principal)}</TableCell>}
                  {<TableCell>{formatNumber(item?.TotalInterest)}</TableCell>}
                  {<TableCell>{formatNumber(item?.LateCharge)}</TableCell>}
                  {<TableCell>{convertDateToDisplay(item?.LateChargeDate)}</TableCell>}
                </TableRow>
              ))}
              {dealAmortizationData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
    </>
  )
}

export default ReferralsTable
