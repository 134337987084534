import CenteredModal from '../../../../../components/Modal/CenteredModal'
import logo from '../../../../../assets/icons/700credit-logo.svg'
import { CheckboxField } from '../../../../../ui'
import { Form } from 'react-final-form'
import RadioField from '../../../../../ui/RadioField'
import { Box, FormGroup, Grid, Typography } from '@mui/material'
import { useLazyPullUpCreditDataQuery } from '../../../../../services'
import { CreditAppDeal } from '../../../../../models/leadTypes'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { LoadingButton } from '@mui/lab'

type Bureau = {
  Bureau: string
  IsActive: boolean
}

type Props = {
  isPullCreditModalOpen: boolean
  setIsPullCreditModalOpen: (open: boolean) => void
  bureausData: Bureau[]
  dealData: CreditAppDeal[]
}
const PullCreditModal = ({ isPullCreditModalOpen, setIsPullCreditModalOpen, bureausData, dealData }: Props) => {
  const { leadID } = useParams()
  const [pullUpCredit, { data: pullUpCreditData, isSuccess, isLoading }] = useLazyPullUpCreditDataQuery()

  const handleSubmit = () => {
    pullUpCredit({
      leadId: leadID as string,
      dealId: dealData.length === 1 ? dealData[0].DealID : undefined,
    })
  }

  useEffect(() => {
    if (isSuccess && pullUpCreditData) {
      window.open(pullUpCreditData.link, '_blank', 'noreferrer')

      setIsPullCreditModalOpen(false)
    }
  }, [isSuccess, pullUpCreditData])

  return (
    <CenteredModal
      open={isPullCreditModalOpen}
      setOpen={setIsPullCreditModalOpen}
      sx={{
        minWidth: 500,
      }}
    >
      <Box>
        <Box component='img' src={logo} />
        <Form onSubmit={handleSubmit}>
          {({ handleSubmit, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Grid container alignItems='center' mt={2}>
                <Grid item xs={3}>
                  <Typography variant='h3'>Bureaus</Typography>
                </Grid>
                <FormGroup row>
                  {bureausData.map(
                    (item: Bureau) => item.IsActive && <CheckboxField name={item.Bureau} label={item.Bureau} key={item.Bureau} defaultChecked />,
                  )}
                </FormGroup>
              </Grid>
              {/* <Grid container alignItems='center' mt={2}>
                <Grid item xs={3}>
                  <Typography variant='h3'>Type</Typography>
                </Grid>

                <FormGroup>
                  <RadioField
                    defaultValue='CREDIT'
                    name='type'
                    label='Credit'
                    options={[
                      {
                        type: 'Credit',
                        val: 'CREDIT',
                      },
                      {
                        type: 'Soft Pull',
                        val: 'PRESCREEN',
                      },
                      {
                        type: 'Pre Qualify',
                        val: 'PREQUALIFY',
                      },
                    ]}
                    optionKey='val'
                    optionValue='type'
                  />
                </FormGroup>
              </Grid> */}
              <Box display='flex' justifyContent='flex-end'>
                <LoadingButton
                  loading={isLoading}
                  variant='contained'
                  type='submit'
                  sx={{
                    marginTop: 2,
                  }}
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          )}
        </Form>
      </Box>
    </CenteredModal>
  )
}

export default PullCreditModal
