import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { TextField } from '../../../../../ui'
import { ParsedDropdownResponse } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  cardType: ParsedDropdownResponse[]
}

const CreditCard = ({ cardType }: Props) => {
  return (
    <Grid container spacing={2} py={1}>
      <Grid item xs={12}>
        <Typography variant='h6'>Credit Card</Typography>
      </Grid>
      <Grid item xs={12}>
        <DropdownFieldSearch name='CreditType' label='Credit Type' options={cardType} optionKey='ID' optionValue='CardType' autoComplete='off' />
      </Grid>
      <Grid item xs={12}>
        <TextField inputProps={{ maxLength: 4 }} name='Last4ofCreditCard' label='Last 4 of Credit Card' fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField name='AuthorizationNumber' label='Authorization Number' fullWidth />
      </Grid>
    </Grid>
  )
}

export default CreditCard
