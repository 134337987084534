import _ from 'lodash'
import { UspContactFullAddEdit, UspGetDataContact, UspGetDataDealInsuranceResult } from '../../../../models'
import { getCurrentDate, roundAmount } from '../../../../utils/general'
import { convertDateToString } from '../../../../utils/dateUtils'
import removeMask from '../../../../utils/removeMask'

export const transformToFormValues = (values?: UspGetDataContact): any => ({
  ContactID: values?.ContactID,
  ApplicantAccountType: values?.IsBusiness ? 'business' : 'individual',
  ApplicantFirstName: values?.FirstName,
  ApplicantMiddleName: values?.MiddleName,
  ApplicantLastName: values?.LastName,
  ApplicantSuffix: values?.Suffix,
  ApplicantNickName: values?.DisplayName,
  ApplicantBusinessName: values?.BusinessName,
  ApplicantAddress1: _.first(values?.ContactAddressData)?.Address1,
  ApplicantAddress2: _.first(values?.ContactAddressData)?.Address2,
  ApplicantCity: _.first(values?.ContactAddressData)?.City,
  ApplicantState: _.first(values?.ContactAddressData)?.State,
  ApplicantPostalCode: _.first(values?.ContactAddressData)?.PostalCode,
  ApplicantCounty: _.first(values?.ContactAddressData)?.County,
  ApplicantEmail: values?.Email,
  ApplicantContactPhoneID: _.first(values?.ContactPhoneData)?.ContactPhoneID,
  ApplicantPhoneNumber: _.first(values?.ContactPhoneData)?.PhoneNumber,
  ApplicantPhoneType: _.first(values?.ContactPhoneData)?.PhoneType || 'Cell',
  ApplicantPhoneExtension: _.first(values?.ContactPhoneData)?.PhoneExtension,
  ApplicantSecondaryContactPhoneID: _.nth(values?.ContactPhoneData, 2)?.ContactPhoneID,
  ApplicantSecondaryPhoneNumber: _.nth(values?.ContactPhoneData, 2)?.PhoneNumber,
  ApplicantSecondaryPhoneType: _.nth(values?.ContactPhoneData, 2)?.PhoneType || 'Home',
  ApplicantSecondaryPhoneExtension: _.nth(values?.ContactPhoneData, 2)?.PhoneExtension,
  ApplicantTaxIDNumber: values?.TaxIDNumber,
  ApplicantLicenseNumber: values?.DLNumber,
  ApplicantLicenseState: values?.DLState,
  ApplicantLicenseIssuedDate: values?.DLIssuedDate,
  ApplicantLicenseExpirationDate: values?.DLExpireDate,
  EmploymentCurrentEmployerID: values?.EmployerID,
  EmploymentCurrentEmployerContactID: values?.EmployerContactID,
  EmploymentCurrentName: values?.EmployerName,
  EmploymentCurrentOccupation: values?.EmployerOccupation,
  EmploymentCurrentAddressID: _.first(values?.EmployerAddressData)?.AddressID,
  EmploymentCurrentAddress1: _.first(values?.EmployerAddressData)?.Address1,
  EmploymentCurrentAddress2: _.first(values?.EmployerAddressData)?.Address2,
  EmploymentCurrentCity: _.first(values?.EmployerAddressData)?.City,
  EmploymentCurrentState: _.first(values?.EmployerAddressData)?.State,
  EmploymentCurrentPostalCode: _.first(values?.EmployerAddressData)?.PostalCode,
  EmploymentCurrentCounty: _.first(values?.EmployerAddressData)?.County,
  EmploymentCurrentPhone: _.first(values?.EmployerPhoneData)?.PhoneNumber,
  EmploymentCurrentPhoneExtension: _.first(values?.EmployerPhoneData)?.PhoneExtension,
  EmploymentCurrentPhoneType: _.first(values?.EmployerPhoneData)?.PhoneType || 'Cell',
  EmploymentCurrentEmail: values?.EmployerEmail,
  EmploymentCurrentDateStarted: values?.EmployerDateStarted,
  EmploymentCurrentYearlySalary: roundAmount(values?.EmployerSalary),
  EmploymentCurrentHoursPerWeek: values?.EmployerHoursPerWeek,
  EmploymentCurrentHourlyWage: roundAmount(values?.EmployerHourlyWage),
  EmploymentCurrentPaySchedule: values?.EmployerPaySchedule,
  EmploymentCurrentComment: values?.EmployerNote,
  EmploymentPreviousID: values?.PreviousEmployerID,
  EmploymentPreviousContactID: values?.PreviousEmployerContactID,
  EmploymentPreviousAddressID: _.nth(values?.PreviousEmployerAddressData, 1)?.AddressID,
  EmploymentPreviousName: values?.PreviousEmployerName,
  EmploymentPreviousOccupation: values?.PreviousEmployerOccupation,
  EmploymentPreviousAddress1: _.nth(values?.PreviousEmployerAddressData, 1)?.Address1,
  EmploymentPreviousAddress2: _.nth(values?.PreviousEmployerAddressData, 1)?.Address2,
  EmploymentPreviousCity: _.nth(values?.PreviousEmployerAddressData, 1)?.City,
  EmploymentPreviousState: _.nth(values?.PreviousEmployerAddressData, 1)?.State,
  EmploymentPreviousPostalCode: _.nth(values?.PreviousEmployerAddressData, 1)?.PostalCode,
  EmploymentPreviousCounty: _.nth(values?.PreviousEmployerAddressData, 1)?.County,
  EmploymentPreviousPhoneNumber: _.nth(values?.PreviousEmployerPhoneData, 1)?.PhoneNumber,
  EmploymentPreviousPhoneExtension: _.nth(values?.PreviousEmployerPhoneData, 1)?.PhoneExtension,
  EmploymentPreviousPhoneType: _.nth(values?.PreviousEmployerPhoneData, 1)?.PhoneType || 'Cell',
  EmploymentPreviousEmail: values?.PreviousEmployerEmail,
  EmploymentPreviousDateStarted: values?.PreviousEmployerDateStarted,
  EmploymentPreviousDateEnded: values?.EmployerDateEnded || values?.EmployerDateStarted,
  EmploymentPreviousYearlySalary: roundAmount(values?.PreviousEmployerSalary),
  EmploymentPreviousHoursPerWeek: values?.PreviousEmployerHoursPerWeek,
  EmploymentPreviousHourlyWage: roundAmount(values?.PreviousEmployerHourlyWage),
  EmploymentPreviousPaySchedule: values?.PreviousEmployerPaySchedule,
  EmploymentPreviousComment: values?.PreviousEmployerNote,
  IncomeMonthlyIncome: roundAmount(values?.MonthlyIncome),
  IncomeAlimony: roundAmount(values?.MonthlyChildSupportAlimony),
  OtherIncome: roundAmount(values?.MonthlyOtherIncome),
  IncomeOtherIncomeDescription: roundAmount(values?.OtherIncomeDescription),
  IncomeMonthlyHousingExpense: roundAmount(values?.MonthlyHousingExpense),
  IncomeCustomExpense1: roundAmount(values?.MonthlyExpense1),
  IncomeCustomExpense2: roundAmount(values?.MonthlyExpense2),
  IncomeCustomExpense3: roundAmount(values?.MonthlyExpense3),
  IncomeCustomExpense4: roundAmount(values?.MonthlyExpense4),
  IncomeCustomExpense5: roundAmount(values?.MonthlyExpense5),
  IncomeOtherExpenses: roundAmount(values?.MonthlyExpenseOther),
  HistoryNumberOfDependents: values?.NumberOfDependents,
  HistoryHasHadBankruptcy: values?.IsHadBankruptcy || false,
  HistoryBankruptcyType: values?.BankruptcyType,
  HistoryBankruptcyDate: values?.BankruptcyDate,
  HistoryHasHadRepossession: values?.IsHadRepo || false,
  HistoryRepossessionDate: values?.RepoDate,
})

export const transformToRequestValues = (
  values: any,
  leadsId?: string | null | undefined,
  contactType?: string | null | undefined,
): UspContactFullAddEdit => ({
  isCreditApp: true,
  contactOrder: contactType === 'applicant' ? 1 : contactType === 'co-applicant' ? 2 : null, // 1 for applicant 2 for coapplicant, add new 1
  creditAppID: leadsId === 'add' ? undefined : Number(leadsId),
  firstName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantFirstName?.trim() : null, // Name
  middleName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantMiddleName?.trim() : null,
  lastName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantLastName?.trim() : null,
  suffix: values?.ApplicantAccountType === 'individual' ? values?.ApplicantSuffix : null,
  nickName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantNickName?.trim() : null,
  businessName: values?.ApplicantAccountType === 'business' ? values?.ApplicantBusinessName?.trim() : null,
  addressID: values?.EmploymentCurrentAddressID, // Address ID
  address1: values?.ApplicantAddress1?.trim(),
  address2: values?.ApplicantAddress2?.trim(),
  city: values?.ApplicantCity?.trim(),
  state: values?.ApplicantState,
  postalCode: values?.ApplicantPostalCode?.trim(),
  county: values?.ApplicantCounty?.trim(),
  email: values?.ApplicantEmail?.trim(),
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.ApplicantContactPhoneID,
      PhoneNumber: values?.ApplicantPhoneNumber,
      PhoneType: values?.ApplicantPhoneType,
      PhoneExtension: values?.ApplicantPhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
    },
    {
      ContactPhoneID: values?.ApplicantSecondaryContactPhoneID,
      PhoneNumber: values?.ApplicantSecondaryPhoneNumber,
      PhoneType: values?.ApplicantSecondaryPhoneType,
      PhoneExtension: values?.ApplicantSecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
    },
  ],
  appDate: getCurrentDate(),
  dateOfBirth: values?.ApplicantAccountType === 'individual' ? convertDateToString(values?.ApplicantDateOfBirth) : null, // Identity
  taxIDNumber: removeMask(values?.taxIDNumber?.trim()),
  dlState: values?.ApplicantLicenseState,
  dlNumber: values?.ApplicantLicenseNumber?.trim(),
  dlExpireDate: convertDateToString(values?.ApplicantLicenseExpiration),
  // Current Employer

  currentEmployerID: values?.EmploymentCurrentEmployerID, // TODO ADD ID ON EDIT
  currentEmployerBusinessName: values?.EmploymentCurrentName?.trim(),
  currentEmployerOccupation: values?.EmploymentCurrentOccupation?.trim(),
  currentEmployerAddress1: values?.EmploymentCurrentAddress1?.trim(),
  currentEmployerAddress2: values?.EmploymentCurrentAddress2?.trim(),
  currentEmployerCity: values?.EmploymentCurrentCity?.trim(),
  currentEmployerState: values?.EmploymentCurrentState,
  currentEmployerPostalCode: values?.EmploymentCurrentPostalCode?.trim(),
  currentEmployerCounty: values?.EmploymentCurrentCity?.trim(),
  // currentEmployerPhoneExtension: values?.EmploymentCurrentPhoneExtension?.trim(),
  currentEmployerJsonPhoneData: [
    {
      ContactPhoneID: values?.EmploymentCurrentEmployerContactID,
      PhoneNumber: values?.EmploymentCurrentPhoneNumber,
      PhoneType: values?.EmploymentCurrentPhoneType,
      PhoneExtension: values?.EmploymentCurrentPhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
  currentEmployerDateStarted: convertDateToString(values?.EmploymentCurrentDateStarted),
  currentEmployerEmail: values?.EmploymentCurrentWorkEmail?.trim(),
  currentEmployerSalary: values?.CurrentIsSalaried ? removeMask(values?.EmploymentCurrentYearlySalary) : null,
  currentEmployerHourlyWage: values?.CurrentIsSalaried ? null : removeMask(values?.EmploymentCurrentYearlySalary),
  currentEmployerHoursPerWeek: values?.EmploymentCurrentHoursPerWeek,
  currentEmployerPaySchedule: values?.EmploymentCurrentPaySchedule,
  currentEmployerNote: values?.EmploymentCurrentComment?.trim(),
  currentEmployerIsPrimary: true,
  currentEmployerIsAllowedCall: true,
  currentEmployerIsActive: true,
  isCurrentEmployerUpdated: true,
  // Previous Employer
  previousEmployerID: values?.EmploymentPreviousID,
  previousEmployerContactID: values?.EmploymentPreviousContactID,
  previousEmployerAddressID: values?.EmploymentPreviousAddressID,
  previousEmployerBusinessName: values?.EmploymentPreviousName?.trim(),
  previousEmployerOccupation: values?.EmploymentPreviousOccupation?.trim(),
  previousEmployerAddress1: values?.EmploymentPreviousAddress1?.trim(),
  previousEmployerAddress2: values?.EmploymentPreviousAddress2?.trim(),
  previousEmployerCity: values?.EmploymentPreviousCity?.trim(),
  previousEmployerState: values?.EmploymentPreviousState,
  previousEmployerPostalCode: values?.EmploymentPreviousPostalCode?.trim(),
  previousEmployerCounty: values?.EmploymentPreviousCity?.trim(),
  previousEmployerPhoneExtension: values?.EmploymentPreviousPhoneExtension?.trim(),
  previousEmployerJsonPhoneData: [
    {
      ContactPhoneID: values?.PreviousEmployerContactID,
      PhoneNumber: values?.EmploymentPreviousPhoneNumber,
      PhoneType: values?.EmploymentPreviousPhoneType,
      PhoneExtension: values?.EmploymentPreviousPhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
  previousEmployerDateStarted: convertDateToString(values?.EmploymentPreviousDateStarted),
  previousEmployerDateEnded: convertDateToString(values?.EmploymentPreviousDateEnded),
  previousEmployerEmail: values?.EmploymentPreviousWorkEmail?.trim(),
  previousEmployerSalary: values?.PreviousIsSalaried ? removeMask(values?.EmploymentCurrentYearlySalary) : null,
  previousEmployerHourlyWage: values?.PreviousIsSalaried ? null : removeMask(values?.EmploymentCurrentYearlySalary),
  previousEmployerHoursPerWeek: values?.EmploymentPreviousHoursPerWeek,
  previousEmployerPaySchedule: values?.EmploymentPreviousPaySchedule,
  previousEmployerNote: values?.EmploymentPreviousComment?.trim(),
  previousEmployerIsPrimary: false,
  previousEmployerIsAllowedCall: true,
  previousEmployerIsActive: true,
  isPreviousEmployerUpdated: true,
  // Monthly Income
  monthlyIncome: removeMask(values?.IncomeMonthlyIncome),
  monthlyChildSupportAlimony: removeMask(values?.monthlyChildSupportAlimony),
  monthlyOtherIncome: removeMask(values?.IncomeOtherIncome),
  otherIncomeDescription: values?.IncomeOtherIncomeDescription?.trim(),
  // Monthly Expenses
  monthlyHousingExpense: removeMask(values?.IncomeMonthlyHousingExpense),
  monthlyExpense1: removeMask(values?.IncomeCustomExpense1),
  monthlyExpense2: removeMask(values?.IncomeCustomExpense2),
  monthlyExpense3: removeMask(values?.IncomeCustomExpense3),
  monthlyExpense4: removeMask(values?.IncomeCustomExpense4),
  monthlyExpense5: removeMask(values?.IncomeCustomExpense5),
  monthlyExpenseOther: removeMask(values?.IncomeOtherExpenses),
  // History
  numberOfDependents: Number(values?.HistoryNumberOfDependents) || 0,
  isHadBankruptcy: values?.HistoryHasHadBankruptcy,
  bankruptcyType: values?.HistoryBankruptcyType,
  bankruptcyDate: convertDateToString(values?.HistoryBankruptcyDate),
  isHadRepo: values?.HistoryHasHadRepossession,
  repoDate: convertDateToString(values?.HistoryRepossessionDate),
  isContactUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isAddressUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isCurrentEmployerAddressUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isPreviousEmployerContactUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isPreviousEmployerAddressUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isPreviousEmployerPhoneUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isIncomeHistoryUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isCurrentEmployerPhoneUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
})

export const transformDealsInsuranceToRequestValues = (values?: any, getContactsData?: UspGetDataContact, dealInsuranceData?: any) => ({
  addressID: _.first(getContactsData?.ContactAddressData)?.AddressID || null,
  insuranceContactID: dealInsuranceData?.ContactID || null,
  // insuranceCompany: 'Insurance Company', // TODO: REMOVED FOR NOW, MAYBE ADD LATER IF NEEDED
  insuranceAgent: values?.InsuranceAgent,
  policyNumber: values?.PolicyNumber,
  coverageType: values?.CoverageType,
  collisionDeductible: removeMask(values?.CollisionDeductible),
  comprehensiveDeductible: removeMask(values?.ComprehensiveDeductible),
  effectiveDate: convertDateToString(values?.EffectiveDate),
  expirationDate: convertDateToString(values?.ExpirationDate),
  // cancellationDate: convertDateToString(values?.ExpirationDate), // TODO: REMOVED FOR NOW, MAYBE ADD LATER IF NEEDED
  note: values?.Note,
  isCpi: values?.IsCPI,
  businessName: values?.InsuranceCompany,
  // dba: 'dba', // TODO: REMOVED FOR NOW, MAYBE ADD LATER IF NEEDED
  // taxIDNumber: 'taxIDNumber', // TODO: REMOVED FOR NOW, MAYBE ADD LATER IF NEEDED
  // email: 'email@email.com',  // TODO: REMOVED FOR NOW, MAYBE ADD LATER IF NEEDED
  isAllowSMS: true,
  isAllowEmail: true,
  isDoNotSell: false,
  isRestrictCheckAch: false,
  isContactUpdated: true,
  address1: values?.Address1,
  address2: values?.Address2,
  city: values?.City,
  state: values?.State,
  postalCode: values?.PostalCode,
  county: values?.County,
  addressType: 'B',
  isPrimaryAddress: true,
  isAddressActive: true,
  isAddressUpdated: true,
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: _.nth(getContactsData?.ContactPhoneData, -2)?.ContactPhoneID,
      PhoneNumber: values?.PhoneNumber,
      PhoneType: values?.PhoneType,
      PhoneExtension: values?.PhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
    },
    {
      ContactPhoneID: _.last(getContactsData?.ContactPhoneData)?.ContactPhoneID,
      PhoneNumber: values?.SecondaryPhoneNumber,
      PhoneType: values?.SecondaryPhoneType,
      PhoneExtension: values?.SecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
    },
  ],
  isPhoneUpdated: true,
})

export const transformDealsInsuranceToFormValues = (
  getContactsData?: UspGetDataContact,
  dealInsuranceData?: UspGetDataDealInsuranceResult,
  taskDeal?: any,
) => {
  // Initialize fields with default values
  let PhoneNumber, PhoneExtension
  let SecondaryPhoneNumber, SecondaryPhoneExtension
  let PhoneType = 'Biz'
  let SecondaryPhoneType = 'Fax'
  // Iterate over the ContactPhoneData array
  if (getContactsData?.ContactPhoneData) {
    for (const phoneData of getContactsData?.ContactPhoneData) {
      if (phoneData.OrderOfPreference === 1) {
        PhoneNumber = phoneData.PhoneNumber
        PhoneType = phoneData.PhoneType || 'Cell'
        PhoneExtension = phoneData.PhoneExtension
      } else if (phoneData.OrderOfPreference === 2) {
        SecondaryPhoneNumber = phoneData.PhoneNumber
        SecondaryPhoneType = phoneData.PhoneType || 'Home'
        SecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }
  return {
    InsuranceCompany: getContactsData?.BusinessName,
    InsuranceAgent: dealInsuranceData?.InsuranceAgent,
    Address1: _.nth(getContactsData?.ContactAddressData)?.Address1,
    Address2: _.nth(getContactsData?.ContactAddressData)?.Address2,
    City: _.nth(getContactsData?.ContactAddressData)?.City,
    State: _.nth(getContactsData?.ContactAddressData)?.State,
    PostalCode: _.nth(getContactsData?.ContactAddressData)?.PostalCode,
    County: _.nth(getContactsData?.ContactAddressData)?.County,
    PhoneNumber,
    PhoneType,
    PhoneExtension,
    SecondaryPhoneNumber,
    SecondaryPhoneExtension,
    SecondaryPhoneType,
    IsCPI: dealInsuranceData?.IsCPI || false,
    PolicyNumber: dealInsuranceData?.PolicyNumber,
    CoverageType: dealInsuranceData?.CoverageType,
    EffectiveDate: dealInsuranceData?.EffectiveDate || taskDeal?.DealDate,
    ExpirationDate: dealInsuranceData?.ExpirationDate,
    ComprehensiveDeductible: dealInsuranceData?.ComprehensiveDeductible,
    CollisionDeductible: dealInsuranceData?.CollisionDeductible,
    Note: dealInsuranceData?.Note,
  }
}
