import styled from '@emotion/styled'
import { Card, Container, generateUtilityClasses, Paper } from '@mui/material'
import React from 'react'
import Header from '../components/Header/Header'
import StatementReport from '../components/StatementReport/StatementReport'
import { storageGet } from '../../../../utils/storage'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('Container', ['root', 'fieldWrap'])

const StyledContainer = styled(Container)(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.fieldWrap}`]: {
    padding: '20px',
    margin: '20px',
  },
}))

const IncomeStatement = () => {
  const data = storageGet('financialsIncomeStatement')
  return (
    <Container>
      {/* <Header /> */}
      <CustomBreadcrumbs
        heading='Financials'
        links={[
          {
            name: '',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <StatementReport data={JSON.parse(data as string)} />
      </Card>
    </Container>
  )
}

export default IncomeStatement
