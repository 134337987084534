import * as yup from 'yup'

export const accountingMultilineBillingValidation = (isModalOpen: boolean) => {
  return yup.object({
    ...(isModalOpen
      ? {
          Branch: yup.number().required().label('Branch').typeError('Invalid Input'),
          ChartOfAccountBill: yup.number().required().label('Chart Of Account (Bill)').typeError('Invalid Input'),
          Amount: yup.string().required().label('Amount').typeError('Invalid Input'),
        }
      : {}),
  })
}
