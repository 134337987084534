import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  generateUtilityClasses,
  TablePagination,
  MenuItem,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import EditIcon from '@mui/icons-material/Edit'
import { formatNumber } from '../../../../../utils/general'
import { UspStateCountyTaxRateViewResult } from '../../../../../models'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { CustomCircular } from '../../../../../ui'
import CustomPopover from '../../../../../components/custom-popover/custom-popover'
import { usePopover } from '../../../../../components/custom-popover'
import Iconify from '../../../../../components/iconify'

const classes = generateUtilityClasses('Grid', ['root', 'loading', 'tableHead', 'tableCellName', 'lastColumn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.lastColumn}`]: {
    fontWeight: 600,
    minWidth: '150px',
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableHead}`]: {
    minWidth: '150px',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

interface Props {
  data: UspStateCountyTaxRateViewResult[]
  loading: boolean
  setData: Dispatch<SetStateAction<UspStateCountyTaxRateViewResult[]>>
  handleOpenModal: () => void
  setOpenAddEditModal: Dispatch<SetStateAction<boolean>>
  setType: Dispatch<SetStateAction<string>>
  setRowData: Dispatch<SetStateAction<UspStateCountyTaxRateViewResult>>
}
const CountyTaxesTable = ({ data, loading, setOpenAddEditModal, setType, setRowData }: Props) => {
  const initialOrder = (storageGet('county_taxes_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('county_taxes_order_by') || 'County') as keyof UspStateCountyTaxRateViewResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspStateCountyTaxRateViewResult>(initialOrderBy)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [selected, setSelected] = useState<any>(null)
  const popover = usePopover()

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginatedData = data

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspStateCountyTaxRateViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('county_taxes_order', isAsc ? 'desc' : 'asc')
    storageSet('county_taxes_order_by', property)
  }

  const createSortHandler = (property: keyof UspStateCountyTaxRateViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleOpen = () => {
    setType('edit')
    setOpenAddEditModal(true)
  }

  return (
    <>
      <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 170px)' }} className={classes.root}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'County' ? order : false}>
                <TableSortLabel active={orderBy === 'County'} direction={orderBy === 'County' ? order : 'asc'} onClick={createSortHandler('County')}>
                  Name
                  {orderBy === 'County' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} sortDirection={orderBy === 'State' ? order : false}>
                <TableSortLabel active={orderBy === 'State'} direction={orderBy === 'State' ? order : 'asc'} onClick={createSortHandler('State')}>
                  State
                  {orderBy === 'State' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} sortDirection={orderBy === 'TaxRate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'TaxRate'}
                  direction={orderBy === 'TaxRate' ? order : 'asc'}
                  onClick={createSortHandler('TaxRate')}
                >
                  Tax Rate
                  {orderBy === 'TaxRate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} sortDirection={orderBy === 'TaxCap' ? order : false}>
                <TableSortLabel active={orderBy === 'TaxCap'} direction={orderBy === 'TaxCap' ? order : 'asc'} onClick={createSortHandler('TaxCap')}>
                  Tax Cap
                  {orderBy === 'TaxCap' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.lastColumn} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData as any)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow key={item.ID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    className={classes.tableCellName}
                    onClick={() => {
                      handleOpen()
                      setRowData(item as any)
                    }}
                  >
                    {item?.County}
                  </TableCell>
                  <TableCell>{item?.State}</TableCell>
                  <TableCell>{item?.TaxRate}</TableCell>
                  <TableCell>{formatNumber(item?.TaxCap?.toString())}</TableCell>
                  <TableCell align={'right'}>
                    <IconButton
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setSelected(item)
                        popover.onOpen(event)
                      }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {data && data?.length > 50 && (
        <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
          <Paper>
            <TablePagination
              component='div'
              count={data?.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
      <CustomCircular open={loading} />

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(e) => {
            handleOpen()
            setRowData(selected as any)
            popover.onClose()
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default CountyTaxesTable
