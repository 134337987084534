import React from 'react'
import { styled } from '@mui/system'
import { Grid, Tooltip, Typography } from '@mui/material'
import { ReactComponent as SixangleIcon } from '../../../../../assets/icons/sixangle.svg'
import { ReactComponent as AddIcon } from '../../../../../assets/icons/add.svg'
import { ReactComponent as RemoveIcon } from '../../../../../assets/icons/remove.svg'
import { generateUtilityClasses } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import GroupsIcon from '@mui/icons-material/Groups'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import { UspGetDataCreditApp } from '../../../../../models'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { useStatusColorStyles } from '../../../../../hooks/useStatusColorStyles'

const classes = generateUtilityClasses('Grid', [
  'root',
  'row',
  'title',
  'mainIcon',
  'addIcon',
  'userIcon',
  'linkText',
  'rowWrap',
  'statusHighlighted',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },

  [`& .${classes.mainIcon}`]: {
    padding: theme.spacing(0, 2, 0, 0),
    fill: theme.palette.text.primary,
  },
  [`& .${classes.addIcon}`]: {
    width: '45px',
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.userIcon}`]: {
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.rowWrap}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.linkText}`]: {
    width: '110px',
    fontWeight: 600,
    fontSize: '1em',
    cursor: 'pointer',
    lineHeight: '1.1875em',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.statusHighlighted}`]: {
    padding: theme.spacing(0.2, 1),
    height: '22px',
    width: '175px',
    textAlign: 'center',
    color: theme.palette.text.button,
    fontWeight: '600',
    fontSize: '0.85em',
    margin: theme.spacing(1, 0, 1, 1),
    borderRadius: '4px',
  },
}))

interface Props {
  creditAppData?: UspGetDataCreditApp | null
  setIsHasCoApplicant?: any
  isHasCoApplicant?: boolean | null
}

export const TasksForm = ({ creditAppData, setIsHasCoApplicant, isHasCoApplicant }: Props) => {
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { leadID } = useParams()
  const StatusColorDiv = useStatusColorStyles(creditAppData)

  const deleteCoApplicant = () => {
    setIsHasCoApplicant(false)
    setDeleteConfirmDialog(false)
  }

  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.row}>
        <SixangleIcon className={classes.mainIcon} />
        <Typography variant='h1' className={classes.title}>
          Tasks
        </Typography>
      </Grid>
      <Grid className={classes.row} container>
        <Grid item className={classes.addIcon}>
          {!creditAppData?.ContactID2 && (
            <Tooltip title='Add Co-Applicant' arrow>
              <AddIcon
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate(
                    appRoute.LeadsAddEdit.replace(':leadID', creditAppData?.CreditAppID?.toString() as string)
                      .replace(':contactType', 'co-applicant')
                      .replace(':contactID', creditAppData?.ContactID2?.toString() || 'add'),
                  )
                }
              />
            </Tooltip>
          )}
        </Grid>
        <Grid
          item
          onClick={() =>
            navigate(
              appRoute.LeadsAddEdit.replace(':leadID', creditAppData?.CreditAppID?.toString() as string)
                .replace(':contactType', 'applicant')
                .replace(':contactID', creditAppData?.ContactID1?.toString() as string),
            )
          }
          className={classes.rowWrap}
        >
          <Grid container>
            <Grid item>
              <PersonIcon color='primary' className={classes.userIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.linkText}>Applicant</Typography>
            </Grid>
            <Grid item>
              <StatusColorDiv className={`${'applicant'} ${classes.statusHighlighted}`}>{creditAppData?.ApplicantStatus}</StatusColorDiv>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isHasCoApplicant ? (
        <Grid className={classes.row} container>
          <Grid item className={classes.addIcon}>
            {creditAppData?.ContactID2 && (
              <Tooltip title='Remove Co-Applicant' arrow>
                <RemoveIcon style={{ cursor: 'pointer' }} onClick={() => setDeleteConfirmDialog(true)} />
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            onClick={() =>
              navigate(
                appRoute.LeadsAddEdit.replace(':leadID', creditAppData?.CreditAppID?.toString() as string)
                  .replace(':contactType', 'co-applicant')
                  .replace(':contactID', creditAppData?.ContactID2?.toString() || 'add'),
              )
            }
            className={classes.rowWrap}
          >
            <Grid container>
              <Grid item>
                <PeopleAltIcon color='primary' className={classes.userIcon} />
              </Grid>
              <Grid item>
                <Typography className={classes.linkText}>Co-Applicant</Typography>
              </Grid>
              <Grid item>
                <StatusColorDiv className={`${'coApplicant'} ${classes.statusHighlighted}`}>{creditAppData?.CoApplicantStatus}</StatusColorDiv>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      <Grid className={classes.row} container>
        <Grid item className={classes.addIcon} />
        <Grid item>
          <GroupsIcon color='primary' className={classes.userIcon} />
        </Grid>
        <Grid item>
          <Typography
            onClick={() =>
              navigate(
                appRoute.LeadsReference.replace(':leadID', leadID as string).replace(':contactID', creditAppData?.ContactID1?.toString() as string),
              )
            }
            className={classes.linkText}
          >
            References
          </Typography>
        </Grid>
        <Grid item>
          <StatusColorDiv className={`${'reference'} ${classes.statusHighlighted}`}>{creditAppData?.ReferenceStatus}</StatusColorDiv>
        </Grid>
      </Grid>
      <Grid className={classes.row} container>
        <Grid item className={classes.addIcon} />
        <Grid item>
          <ShoppingBagIcon color='primary' className={classes.userIcon} />
        </Grid>
        <Grid item>
          <Typography className={classes.linkText} onClick={() => navigate(appRoute.LeadsStipulations.replace(':leadID', leadID as string))}>
            Stipulations
          </Typography>
        </Grid>
        <Grid item>
          <StatusColorDiv className={`${'stipulation'} ${classes.statusHighlighted}`}>{creditAppData?.StipulationStatus}</StatusColorDiv>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={deleteCoApplicant}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Are you sure you want to remove Co-Applicant?'}
      />
    </StyledGrid>
  )
}

export default TasksForm
