import { Box, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAddLotsMutation, useEditLotsMutation, useLazyGetLotQuery } from '../../../../services'
import { UspConfigurationIpAddressViewResult } from '../../../../models/apiTypes'
import { useDispatch, useSelector } from 'react-redux'
import { getBranch } from '../../../../redux/slices/userDropdownSlice'
import { withPermissionsDropdown } from '../../../../hoc'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

// const StyledBox = styled(Box)(({ theme }) => ({
//   [`&.${classes.root}`]: {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '390px',
//     backgroundColor: theme.palette.background.main,
//     boxShadow: 24,
//     padding: theme.spacing(3),
//   },
//   [`& .${classes.row}`]: {
//     margin: theme.spacing(1, 0, 2, 0),
//   },
//   [`& .${classes.buttonWrap}`]: {
//     display: 'flex',
//     justifyContent: 'flex-start',
//     flexDirection: 'row-reverse',
//     padding: theme.spacing(3, 0, 2, 0),
//     marginTop: 'auto',
//   },
//   [`& .${classes.saveBtn}`]: {
//     marginLeft: theme.spacing(2),
//   },
// }))

interface Props {
  rowData: UspConfigurationIpAddressViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditLotsModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const branchData = useSelector(getBranch)
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})
  const [addLots, { isLoading: isLoadingLots, isSuccess: isSuccessLots }] = useAddLotsMutation()
  const [editLots, { isLoading: isLoadingEditLots, isSuccess: isSuccessEditLots }] = useEditLotsMutation()
  const [getLotsData, { isLoading: isLoadingGetLots, isSuccess: isSuccessGetLots, data }] = useLazyGetLotQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editLots({
        lotId: rowData?.ID?.toString(),
        uspConfigurationLotAddEdit: {
          lot: values.Lot,
          branchID: values.Branch,
          isActive: values.IsActive,
        },
      })
    } else {
      addLots({
        uspConfigurationLotAddEdit: {
          lot: values.Lot,
          branchID: values.Branch,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getLotsData({
        lotId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Lot: '',
        Branch: '',
        IsActive: true,
      })
    }
    if (isSuccessGetLots && type === 'edit') {
      setInitialValues({
        Lot: data?.Lot,
        Branch: data?.BranchID,
        IsActive: data?.IsActive,
      })
    }
  }, [isSuccessGetLots, data, type])

  useEffect(() => {
    if (isSuccessLots || isSuccessEditLots) {
      dispatch(enqueueNotification({ message: 'Success' }))
      onClose()
      submit()
    }
  }, [isSuccessLots, isSuccessEditLots])

  const isLoading = useMemo(() => isLoadingLots || isLoadingEditLots || isLoadingGetLots, [isLoadingLots, isLoadingEditLots, isLoadingGetLots])
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lots</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={3}>
                <Grid item xs={12}>
                  <TextField required name='Lot' label='Lot' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    required
                    name='Branch'
                    label='Branch'
                    labelId='BranchId'
                    fullWidth
                    options={branchData}
                    optionKey='BranchID'
                    optionValue='Branch'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default withPermissionsDropdown(AddEditLotsModal)
