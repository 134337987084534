import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import {
  useAddAccountingChartOfAccountItemMutation,
  useEditAccountingChartMutation,
  useLazyGetAccountingChartOfAccountCurrentDataQuery,
} from '../../../../services'
import { AccountSubTypeResponseData, UspAccountingChartOfAccountCurrentView } from '../../../../models'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'

interface Props {
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  onSuccessfulSubmit: (val?: any) => void
  rowData?: any
  subTypeAccountDataIsLoading: any
  parentAccountDataIsLoading: any
  parentAccountData: any
  subTypeData: any
}

const classes = generateUtilityClasses('Box', ['root', 'row'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

const AddChartOfAccountModal = ({
  open,
  onSuccessfulSubmit,
  handleClose,
  rowData,
  parentAccountDataIsLoading,
  subTypeAccountDataIsLoading,
  parentAccountData,
  subTypeData,
}: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const dispatch = useDispatch()
  const [getAccountData, { data: accountData, isLoading: accountDataIsLoading }] = useLazyGetAccountingChartOfAccountCurrentDataQuery()
  const [addChartItem, { isSuccess }] = useAddAccountingChartOfAccountItemMutation()
  const [editChartItem, { isSuccess: editIsSuccess }] = useEditAccountingChartMutation()
  const [IsSubAccount, setIsSubAccount] = useState(false)

  const handleSubmit = (values?: any) => {
    if (rowData?.ChartOfAccountID) {
      editChartItem({
        chart_of_account_id: rowData?.ChartOfAccountID as string,
        editChartOfAccount: {
          accountSubTypeID: values?.ApplicantSubType,
          accountNumber: values?.AccountNumber,
          accountName: values?.AccountName,
          parentAccountID: IsSubAccount ? values?.ParentAccount : null,
          isBankAccount: values?.IsBankAccount,
          isRestricted: values?.IsRestrictedAccount,
          isActive: values?.IsActive,
        },
      })
    } else {
      addChartItem({
        uspAccountingChartOfAccountingItemView: {
          accountSubTypeID: values?.ApplicantSubType,
          accountNumber: values?.AccountNumber,
          accountName: values?.AccountName,
          parentAccountID: IsSubAccount ? values?.ParentAccount : null,
          isBankAccount: values?.IsBankAccount,
          isRestricted: values?.IsRestrictedAccount,
          isActive: values?.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (isSuccess || editIsSuccess) {
      handleClose(true)
      dispatch(enqueueNotification({ message: isSuccess ? 'Account added successfully' : 'Account edited successfully' }))
      onSuccessfulSubmit()
    }
  }, [isSuccess, editIsSuccess])

  useEffect(() => {
    setInitialValues({
      AccountNumber: accountData?.AccountNumber,
      AccountName: accountData?.AccountName,
      ApplicantSubType: accountData?.AccountSubTypeID,
      IsSubAccount: accountData?.IsSubAccount,
      ParentAccount: accountData?.ParentAccountID,
      IsBankAccount: accountData?.IsBankAccount,
      IsRestrictedAccount: accountData?.IsRestricted,
      IsActive: accountData?.IsActive,
    })
  }, [accountData, IsSubAccount])

  useEffect(() => {
    if (accountData?.IsSubAccount !== undefined || accountData?.IsSubAccount !== null) {
      setIsSubAccount(accountData?.IsSubAccount as boolean)
    }
  }, [accountData])

  useEffect(() => {
    if (rowData?.ChartOfAccountID) {
      getAccountData({ chartOfAccountId: rowData?.ChartOfAccountID as string })
    }
  }, [rowData])

  useEffect(() => {}, [IsSubAccount])

  const isLoading = useMemo(
    () => accountDataIsLoading || parentAccountDataIsLoading || subTypeAccountDataIsLoading,
    [accountDataIsLoading, parentAccountDataIsLoading, subTypeAccountDataIsLoading],
  )

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Account</DialogTitle>
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} py={1}>
                  <Grid item xs={12}>
                    <TextField type='number' required name='AccountNumber' label='Account Number' fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField required name='AccountName' label='Account Name' fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      required={true}
                      name='ApplicantSubType'
                      label='Account Sub-Type'
                      options={subTypeData as AccountSubTypeResponseData[]}
                      optionKey={'ID'}
                      optionValue={'AccountSubType'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='IsSubAccount'
                      onChange={(newValue: any) => setIsSubAccount(newValue)}
                      initialValue={false}
                      label='Is Sub-Account?'
                      options={[
                        { key: true, value: 'Yes' },
                        { key: false, value: 'No' },
                      ]}
                    />
                  </Grid>
                  {IsSubAccount && (
                    <Grid item xs={12}>
                      <DropdownFieldSearch
                        required={true}
                        name='ParentAccount'
                        label='Parent Account'
                        options={parentAccountData as UspAccountingChartOfAccountCurrentView[]}
                        optionKey='ChartOfAccountID'
                        optionValue='AccountNumberName'
                      />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='IsBankAccount'
                      initialValue={false}
                      label='Is Bank Account?'
                      options={[
                        { key: true, value: 'Yes' },
                        { key: false, value: 'No' },
                      ]}
                      optionKey='key'
                      optionValue='value'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='IsRestrictedAccount'
                      initialValue={false}
                      label='Is Restricted Account?'
                      options={[
                        { key: true, value: 'Yes' },
                        { key: false, value: 'No' },
                      ]}
                      optionKey='key'
                      optionValue='value'
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='IsActive'
                      initialValue={true}
                      label='Is Active?'
                      options={[
                        { key: true, value: 'Yes' },
                        { key: false, value: 'No' },
                      ]}
                      optionKey='key'
                      optionValue='value'
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions>
                <Button variant='contained' color='error' onClick={() => handleClose(true)}>
                  Cancel
                </Button>
                <Button variant='contained' color='primary' type='submit'>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Form>
        <CustomCircular open={isLoading} />
      </Dialog>
    </div>
  )
}

export default AddChartOfAccountModal
