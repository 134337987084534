import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useSelector } from 'react-redux'
import { useField, useForm, useFormState } from 'react-final-form'
import {
  TFullResponse,
  TSelectedSearchAccount,
  UspAccountingChartOfAccountCurrentView,
  UspAccountingReturnControlDataResult,
  UspListCarExpenseTypeResult,
  VendorResponseSp,
} from '../../../../models'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { AmountField, TextField } from '../../../../ui'
import { getBranch } from '../../../../redux/slices/userDropdownSlice'
import { formatNumber, parseToNumber } from '../../../../utils/general'
import { useLocation } from 'react-router-dom'
import { appRoute } from '../../../../constants'
import { LoadingButton } from '@mui/lab'

const classes = generateUtilityClasses('form', ['root', 'row'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
    minWidth: '350px',
  },

  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onSave?: (newData: TFullResponse) => void
  open: boolean
  requestCarID?: number | null
  requestDealID?: number | null
  handleClose: Dispatch<SetStateAction<boolean>>
  vendorListData?: VendorResponseSp[]
  getAccountingControlData: (e: { chartOfAccountId: string }) => void
  getListsCarsExpense: (e: { isAddingNewExpense: string }) => void
  accountingControlData: UspAccountingReturnControlDataResult
  chartOfAccountBillData: UspAccountingChartOfAccountCurrentView[]
  setRequestCarID: Dispatch<SetStateAction<null | number>>
  setRequestDealID: Dispatch<SetStateAction<null | number>>
  listsCarsExpenseData: UspListCarExpenseTypeResult[]
  lockFields?: () => void
  addBillIsSuccess?: boolean
  addEntryIsSuccess?: boolean
  errors?: boolean
  editingItemIndex?: number | null
  setEditingItemIndex?: Dispatch<SetStateAction<number | null>>
  setSelectedSearchAccount?: Dispatch<SetStateAction<TSelectedSearchAccount | {}>>
  selectedSearchAccount?: TSelectedSearchAccount
  totalSum?: number | string
  isEdit?: boolean
  setIsEdit?: Dispatch<SetStateAction<boolean>>
}

const AddMultilineBillingModal = ({
  open,
  handleClose,
  setRequestCarID,
  setRequestDealID,
  chartOfAccountBillData,
  getAccountingControlData,
  accountingControlData,
  getListsCarsExpense,
  listsCarsExpenseData,
  lockFields,
  addBillIsSuccess,
  requestDealID,
  requestCarID,
  onSave,
  errors,
  setEditingItemIndex,
  addEntryIsSuccess,
  setSelectedSearchAccount,
  selectedSearchAccount,
  totalSum,
  isEdit,
  setIsEdit,
}: Props) => {
  const location = useLocation()
  const form = useForm()
  const { values } = useFormState()
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [filteredExpenseTypes, setFilteredExpenseTypes] = useState<UspListCarExpenseTypeResult[]>([])
  const [defaultBranchID, setDefaultBranchID] = useState<string | null>(null)
  const branchData = useSelector(getBranch)
  const [currentControlDetails, setCurrentControlDetails] = useState<string | null>(null)
  const [isControlled, setIsControlled] = useState(false)
  const [controlSubType, setControlSubType] = useState<string | null>(null)
  const {
    input: { value: chartOfAccountBillValue },
  } = useField('ChartOfAccountBill')
  const {
    input: { value: searchValue },
  } = useField('Search')

  const {
    input: { value: selectedExpenseType },
  } = useField('ExpenseType')

  useEffect(() => {
    const selectedAccount = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === chartOfAccountBillValue)
    const controlDetails = selectedAccount?.ControlDetails
    const controlSubType = selectedAccount?.ControlSubType
    setRequestCarID(null)
    setRequestDealID(null)
    setControlSubType(controlSubType as string)
    let filteredExpenses
    setCurrentControlDetails(controlDetails as string)

    if (values.EntryType === 'Ledger Only') {
      setIsControlled(false)
    } else {
      setIsControlled(selectedAccount?.IsControlled || false)
    }

    if (controlSubType === 'Sold') {
      filteredExpenses = listsCarsExpenseData?.filter((item) => item?.IsAvailableForSoldInventory)
    } else if (controlSubType === 'Unsold') {
      filteredExpenses = listsCarsExpenseData?.filter((item) => item?.IsAvailableForUnsoldInventory)
    } else {
      filteredExpenses = listsCarsExpenseData
    }

    setFilteredExpenseTypes(filteredExpenses)

    if (location.pathname.includes(appRoute.AccountingJournalEntry)) {
      if (
        (values?.EntryType === 'Ledger & Schedules' && selectedAccount && selectedAccount?.IsControlled) ||
        selectedAccount?.ControlSubType === 'Trade'
      ) {
        getAccountingControlData({
          chartOfAccountId: selectedAccount?.ChartOfAccountID?.toString(),
        })
      }
    }

    if (location.pathname.includes(appRoute.AccountingBillingMultiline) || location.pathname.includes(appRoute.AccountingChecksAdd)) {
      if ((selectedAccount && selectedAccount?.IsControlled) || selectedAccount?.ControlSubType === 'Trade') {
        getAccountingControlData({
          chartOfAccountId: selectedAccount?.ChartOfAccountID?.toString(),
        })
      }
    }

    if (selectedAccount && selectedAccount?.ControlDetails === 'Inventory') {
      getListsCarsExpense({
        isAddingNewExpense: 'true',
      })
    }

    if (!selectedAccount?.IsControlled) {
      form.change('searchResults', [])
      form.change('ExpenseType', null)
      setSearchResults([]) // reset
    }
  }, [chartOfAccountBillValue, chartOfAccountBillData, listsCarsExpenseData, isControlled])

  useEffect(() => {
    if (accountingControlData && accountingControlData?.ReturnData) {
      const returnData = JSON.parse(accountingControlData?.ReturnData)

      const filteredResults = returnData?.filter((item: any) => {
        const customerMatch = item?.Customer?.toLowerCase().includes(searchValue?.toLowerCase())
        const vehicleMatch = item?.Vehicle?.toLowerCase().includes(searchValue?.toLowerCase())
        const tradeVehicleMatch = item?.TradeVehicle?.toLowerCase().includes(searchValue?.toLowerCase())
        return customerMatch || vehicleMatch || tradeVehicleMatch
      })
      setSearchResults(filteredResults)

      if (returnData?.length > 0) {
        setDefaultBranchID(returnData?.[0]?.BranchID)
      } else {
        setDefaultBranchID(null)
        setIsControlled(false)
      }
      if (returnData?.[0]?.BranchID) {
        form.change('Branch', returnData?.[0]?.BranchID)
      }
    }
  }, [searchValue, accountingControlData, isControlled, selectedSearchAccount])

  const isExpenseTypePurchase = useMemo(() => {
    const selectedExpense = filteredExpenseTypes?.find((item) => item?.ExpenseType === selectedExpenseType)

    return selectedExpense?.ExpenseType === 'Purchase'
  }, [filteredExpenseTypes, selectedExpenseType])

  const handleSelection = (selectedItem: number) => {
    const foundItem = searchResults.find(
      (item) => item?.CarID === selectedItem || item?.DealID === selectedItem || item?.DealTradeID === selectedItem,
    )

    if (setSelectedSearchAccount) {
      setSelectedSearchAccount(foundItem)
    }
    const selectedAccount = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === chartOfAccountBillValue)

    if (!isEdit && controlSubType === 'Trade' && selectedSearchAccount) {
      form.change('Amount', selectedSearchAccount?.UnpaidLien?.toString())
    }

    if (selectedAccount && selectedItem) {
      if (location.pathname.includes(appRoute?.AccountingJournalEntry)) {
        if (values.EntryType === 'Ledger & Schedules' && selectedAccount?.ControlDetails === 'Account') {
          setRequestDealID(selectedItem)
        }
        if (values.EntryType === 'Ledger & Schedules' && selectedAccount?.ControlDetails === 'Inventory') {
          setRequestCarID(selectedItem)
        }
      } else {
        if (selectedAccount?.ControlDetails === 'Inventory') {
          setRequestCarID(selectedItem)
        }
        if (selectedAccount?.ControlDetails === 'Account') {
          setRequestDealID(selectedItem)
        }
      }
    }
  }

  useEffect(() => {
    handleSelection(values?.searchResults)
  }, [values?.searchResults, selectedSearchAccount, controlSubType])

  const determineOptionKeyAndValue = (results: any[]) => {
    const firstResult = results?.[0]
    if (firstResult?.DealID) {
      return { optionKey: 'DealID', optionValue: 'Customer' }
    } else if (firstResult?.CarID) {
      return { optionKey: 'CarID', optionValue: 'Vehicle' }
    } else {
      return { optionKey: 'DealTradeID', optionValue: 'TradeVehicle' }
    }
  }

  const { optionKey, optionValue } = useMemo(() => determineOptionKeyAndValue(searchResults), [searchResults])

  useEffect(() => {
    if (addBillIsSuccess || addEntryIsSuccess) {
      handleResetFormValues()
    }
  }, [addBillIsSuccess, addEntryIsSuccess])

  useEffect(() => {
    if (totalSum !== 0) {
      if ((totalSum as number) < 0) {
        form.change('Sign', 'Debit')
      } else {
        form.change('Sign', 'Credit')
      }
      form.change('Amount', Math.abs(totalSum as number).toString())
    }
  }, [totalSum])

  const handleResetFormValues = () => {
    form.change('Sign', null)
    form.change('ChartOfAccountBill', null)
    form.change('searchResults', null)
    form.change('ExpenseType', null)
    form.change('IsFloorplan', false)
    form.change('Branch', null)
    form.change('Amount', null)
    form.change('Note', '')
    if (setEditingItemIndex) {
      setEditingItemIndex(null)
    }
    if (setSelectedSearchAccount) {
      setSelectedSearchAccount({})
    }
    setRequestDealID(null)
    setRequestCarID(null)
    setControlSubType(null)
    setIsControlled(false)
    setSearchResults([])
    setFilteredExpenseTypes([])
    setDefaultBranchID(null)
    setCurrentControlDetails(null)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(true)
        handleResetFormValues()
        if (setIsEdit) setIsEdit(false)
        if (setEditingItemIndex) setEditingItemIndex(null)
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <form>
        <DialogTitle>Line Item</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={3}>
            {location.pathname.includes(appRoute?.AccountingJournalEntry) && (
              <Grid item xs={12}>
                <DropdownFieldSearch
                  name='Sign'
                  label='Sign'
                  options={[
                    { key: 'Debit', value: 'Debit' },
                    { key: 'Credit', value: 'Credit' },
                  ]}
                  fullWidth
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <DropdownFieldSearch
                name='ChartOfAccountBill'
                label={location.pathname.includes(appRoute?.AccountingChecksAdd) ? 'Chart Of Account (Offset)' : 'Chart Of Account (Bill)'}
                options={chartOfAccountBillData}
                optionKey={'ChartOfAccountID'}
                optionValue={'AccountNumberName'}
              />
            </Grid>
            {isControlled && (!location.pathname.includes(appRoute.AccountingJournalEntry) || values?.EntryType === 'Ledger & Schedules') && (
              <Grid item xs={12}>
                <DropdownFieldSearch
                  customDropdownWidth={600}
                  id='search-results'
                  name='searchResults'
                  label='Search Results'
                  options={searchResults}
                  optionKey={optionKey}
                  optionValue={optionValue}
                  getOptionLabel={(option: any) => option[optionKey] || ''}
                  renderInput={(params: any) => <TextField {...params} label='Search Results' />}
                  renderOption={(props: any, option: any) => (
                    <li {...props} key={optionKey}>
                      <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Grid item>
                          <Typography variant='h3'>{option.Customer || option.Vehicle || option.Buyer}</Typography>
                          <Typography>
                            <i style={{ color: '#1570EF' }}>{option.StockNumber}</i> | {option.BranchCode || option.Branch} | {option.TradeVehicle}{' '}
                            {option.TradeVIN && ` | ${option.TradeVIN}`}
                          </Typography>
                        </Grid>
                        <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                          <Typography variant='h6'>
                            ${formatNumber((option?.Balance as number) || (option?.InventoryCost as number) || option?.UnpaidLien)}
                          </Typography>
                          <Typography
                            sx={{
                              color: option?.AccountStatus === 'Active' ? 'green' : 'red',
                              fontWeight: option?.AccountStatus === 'Active' ? 'bold' : 'regular',
                            }}
                          >
                            {option.AccountStatus || option.CarStatus}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                />
              </Grid>
            )}

            {currentControlDetails === 'Inventory' &&
              (!location.pathname.includes(appRoute.AccountingJournalEntry) || values?.EntryType === 'Ledger & Schedules') && (
                <>
                  {isControlled && (
                    <Grid item xs={!isExpenseTypePurchase ? 12 : 6}>
                      <DropdownFieldSearch
                        name='ExpenseType'
                        label='Expense Type'
                        options={filteredExpenseTypes}
                        optionKey='ExpenseType'
                        optionValue='ExpenseType'
                      />
                    </Grid>
                  )}

                  {isExpenseTypePurchase && (
                    <>
                      <Grid item xs={6}>
                        <DropdownFieldSearch
                          name='IsFloorplan'
                          label='Is Floorplan?'
                          options={[
                            { key: true, value: 'Yes' },
                            { key: false, value: 'No' },
                          ]}
                          optionKey='key'
                          optionValue='value'
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            <Grid item xs={12}>
              <DropdownFieldSearch
                initialValue={defaultBranchID}
                disabled={isControlled}
                name='Branch'
                label='Branch'
                options={branchData}
                optionKey='BranchID'
                optionValue='Branch'
              />
            </Grid>
            <Grid item xs={12}>
              <AmountField
                variant='standard'
                validate={() => {
                  // Check if the amount is less than 0
                  if (controlSubType === 'Trade' && parseToNumber(values.Amount?.toString() as string) < 0) {
                    return 'Amount cannot be less than 0.00'
                  }

                  if (location.pathname.includes(appRoute?.AccountingChecksAdd) && controlSubType === 'Trade') {
                    if (parseToNumber(values.Amount?.toString() as string) > parseToNumber(selectedSearchAccount?.UnpaidLien?.toString() as string)) {
                      return `Amount cannot be greater than ${formatNumber(selectedSearchAccount?.UnpaidLien as string)}`
                    }
                  }
                  if (location.pathname.includes(appRoute?.AccountingJournalEntry) && controlSubType === 'Trade') {
                    if (values?.EntryType === 'Ledger & Schedules') {
                      if (values?.Sign === 'Debit') {
                        if (
                          parseToNumber(values.Amount?.toString() as string) > parseToNumber(selectedSearchAccount?.UnpaidLien?.toString() as string)
                        ) {
                          return `Amount cannot be greater than ${formatNumber(selectedSearchAccount?.UnpaidLien as string)}`
                        }
                      }
                      if (values?.Sign === 'Credit') {
                        if (
                          parseToNumber(values.Amount?.toString() as string) > parseToNumber(selectedSearchAccount?.LienPaid?.toString() as string)
                        ) {
                          return `Amount cannot be greater than ${formatNumber(selectedSearchAccount?.LienPaid as string)}`
                        }
                      }
                    }
                  }
                }}
                name='Amount'
                label='Amount'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField name='Note' label='Comments' fullWidth multiline autoComplete='off' />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            variant='contained'
            type='submit'
            disabled={errors}
            onClick={() => {
              if (lockFields) {
                lockFields()
              }

              // This part is used in Accounting -> Add Checks, Journal Entry
              if (onSave) {
                const matchedItem = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === form.getState()?.values?.ChartOfAccountBill)
                const matchedBranch = branchData?.find((item) => item?.BranchID === form.getState()?.values?.Branch)
                const foundItem = chartOfAccountBillData.find((item) => item?.ChartOfAccountID === form.getState()?.values?.ChartOfAccountBill)
                const coaAccountNumberValue = matchedItem?.AccountNumberName
                const matchedBranchName = matchedBranch?.Branch
                const accountSubType = foundItem

                const newData: any = {
                  SelectedSearchAccount: selectedSearchAccount,
                  Sign: form.getState().values.Sign,
                  SelectedCoaItem: accountSubType,
                  AccountNumberName: form.getState().values.ChartOfAccountBill,
                  AccountNumberNameValue: coaAccountNumberValue,
                  BranchName: matchedBranchName,
                  SearchResult: form.getState().values.searchResults,
                  IsFloorplan: form.getState().values.IsFloorplan,
                  BranchCode: form.getState().values.Branch,
                  CarExpenseType: form.getState().values.ExpenseType,
                  StockNumber: selectedSearchAccount?.StockNumber,
                  CarID: requestCarID,
                  DealID: requestDealID,
                  BillAmount: location.pathname.includes(appRoute.AccountingJournalEntry)
                    ? form.getState().values.Sign === 'Debit'
                      ? parseToNumber(form.getState().values.Amount)
                      : parseToNumber(form.getState().values.Amount) * -1
                    : parseToNumber(form.getState().values.Amount),
                  BranchID: form.getState().values.Branch,
                  Description: form.getState().values.Note,
                  ID: Math.floor(Math.random() * 100),
                }
                console.log(newData, 'new')
                onSave(newData)
                handleClose(true)
                handleResetFormValues()
              }
            }}
          >
            {isEdit ? 'Update' : 'Save'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default AddMultilineBillingModal
