import { createSlice } from '@reduxjs/toolkit'
import { VariantType, SnackbarKey } from 'notistack'
import { RootState } from '../store'

export type Notifications = {
  notifications: Array<Notification>
}

export type Notification = {
  key?: string
  message?: string
  options?: {
    key?: SnackbarKey
    variant?: VariantType
  }
}

const snackbarSlice = createSlice({
  name: 'notification',
  initialState: { notifications: [] } as Notifications,
  reducers: {
    enqueueNotification: {
      reducer: (state, { payload }) => {
        state.notifications.push(payload)
      },
      prepare({ message, options }): any {
        const payload = {
          message,
          options: {
            key: options?.key || new Date().getTime().toString(),
            variant: options?.variant || 'success',
          },
        }
        return { payload }
      },
    },
    removeNotification: (state, { payload }) => {
      state.notifications = state.notifications?.filter((notification) => notification?.options?.key !== payload)
    },
  },
})

export const getNotifications = (state: RootState): Array<Notification> => state?.notifications?.notifications || []

export const { enqueueNotification, removeNotification } = snackbarSlice.actions

export default snackbarSlice.reducer
