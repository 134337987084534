import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { generateUtilityClasses } from '@mui/material'
import Paper from '@mui/material/Paper'
import { NameForm, AddressForm, ContactForm, IdentityForm } from '../../components'
import { useFormState } from 'react-final-form'
import { checkIfObjectContainKeysWithPrefix } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'fieldsWrap', 'formItem'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.formItem}`]: {
    minHeight: '340px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
}))

interface Props {
  setErrorsInTabs: (value: any) => void
}

export const Buyer = ({ setErrorsInTabs }: Props) => {
  const { errors, submitFailed } = useFormState()

  useEffect(() => {
    setErrorsInTabs((prev: any) => ({ ...prev, applicant: submitFailed && checkIfObjectContainKeysWithPrefix(errors, 'Applicant') }))
  }, [errors, submitFailed])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NameForm />
      </Grid>
      <Grid item xs={12}>
        <AddressForm />
      </Grid>
      <Grid item xs={12}>
        <ContactForm />
      </Grid>
      <Grid item xs={12}>
        <IdentityForm />
      </Grid>
    </Grid>
  )
}

export default Buyer
