import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  useDealReopenMutation,
  useGetTaskDealQuery,
  useMarkDealAsLostMutation,
  useModifyDealFinalizeUnwindSalesMutation,
} from '../../../../../services'
import { Box, Button, Grid, Popover, Skeleton, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { appRoute } from '../../../../../constants'
import CenteredModal from '../../../../../components/Modal/CenteredModal'
import ModalCards from './ModalCards'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import MarkAsLostModal from '../../../../../components/Pages/MarkAsLostModal'
import ConfirmDialog from '../../../../../components/ConfirmDialog'

export const Header = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { dealID } = useParams()

  // Mutations
  const { data: dealTask, isLoading } = useGetTaskDealQuery({ dealId: dealID as string })
  const [dealFinalizeUnwindSales, { data: dealFinalizeUnwindSalesData, isSuccess: dealFinalizeUnwindSalesIsSuccess }] =
    useModifyDealFinalizeUnwindSalesMutation()
  const userAccess = useSelector(getUserAccessRights)
  const [dealReopen, { isSuccess: dealReopenSuccess }] = useDealReopenMutation()
  const [markDealAsLost, { isSuccess: markDealAsLostSuccess }] = useMarkDealAsLostMutation()

  // Component State
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLostModalOpen, setIsLostModalOpen] = useState(false)
  const [isReopenDialogOpen, setIsReopenDialogOpen] = useState(false)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  useEffect(() => {
    if (dealFinalizeUnwindSalesIsSuccess) {
      dispatch(enqueueNotification({ message: dealFinalizeUnwindSalesData?.Response || 'Finalized' }))
      if (userAccess?.CanAccessAccount) {
        navigate(appRoute?.AccountsOverview.replace(':dealID', dealID as string))
      } else {
        navigate(appRoute?.DealsList)
      }
    }
  }, [dealFinalizeUnwindSalesIsSuccess, userAccess])

  const handleFinilize = () => {
    setAnchorEl(null)
    if (dealTask?.IsAllowedFinalize) {
      dealFinalizeUnwindSales({
        dealId: dealID as string,
        uspDealFinalizeUnwindSales: {
          isUnwind: false,
          isRefinance: false,
          isSystemGenerated: false,
        },
      })
    } else {
      dispatch(
        enqueueNotification({
          message: dealTask?.NotAllowedFinalizeReason,
          options: {
            variant: 'error',
          },
        }),
      )
    }
  }

  const handleMarkAsLostSubmit = ({ reasonId }: { reasonId: number }) => {
    markDealAsLost({
      dealId: dealID as string,
      reasonId,
    })
  }

  useEffect(() => {
    if (markDealAsLostSuccess) {
      dispatch(enqueueNotification({ message: 'Deal marked as lost' }))
      setIsLostModalOpen(false)
    }
  }, [markDealAsLostSuccess, dispatch])

  const handleReopen = () => {
    dealReopen({ dealId: dealID as string })
  }

  useEffect(() => {
    if (dealReopenSuccess) {
      dispatch(enqueueNotification({ message: 'Deal reopened' }))
      setIsReopenDialogOpen(false)
    }
  }, [dealReopenSuccess, dispatch])

  return (
    <>
      <Button
        // sx={{ position: 'absolute', right: 30 }}
        aria-describedby='add-menu-popover'
        variant='outlined'
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={isLoading}
      >
        Actions
      </Button>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() => {
              navigate(
                generatePath(appRoute.PrintForms, {
                  dealID,
                }),
                {
                  state: { redirectedFrom: 'Deals', dealState: dealTask?.DealState },
                },
              )
            }}
          >
            Print Forms
          </Button>
          {/* <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}>Add Follow-Up</Button> */}
          <Button
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            onClick={() =>
              navigate(
                generatePath(appRoute.DealsAddEdit, {
                  dealID,
                }),
              )
            }
          >
            Go to Deal
          </Button>
          {/* <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}>Create Credit App</Button> */}
          <Button
            onClick={() => {
              setAnchorEl(null)
              setIsModalOpen(true)
            }}
            style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
          >
            Deal Summary
          </Button>

          {/* {!dealTask?.IsHasCoBuyer && (
            <Button
              onClick={() => {
                navigate(
                  appRoute.DealEditBuyer.replace(':dealID', dealID as string)
                    .replace(':contactID', 'add')
                    .replace(':contactType', 'co-buyer'),
                )
              }}
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
            >
              Add Co-Buyer
            </Button>
          )} */}
          <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }} onClick={handleFinilize}>
            Finalize
          </Button>

          {!dealTask?.IsLost && (
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                setIsLostModalOpen(true)
                handleClose()
              }}
            >
              Mark Lost
            </Button>
          )}

          {dealTask?.IsLost && (
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                setIsReopenDialogOpen(true)
                handleClose()
              }}
            >
              Reopen
            </Button>
          )}
        </Grid>
      </Popover>

      <CenteredModal open={isModalOpen} setOpen={setIsModalOpen}>
        <ModalCards />
      </CenteredModal>

      <MarkAsLostModal
        handleSubmit={handleMarkAsLostSubmit}
        isLostModalOpen={isLostModalOpen}
        setIsLostModalOpen={setIsLostModalOpen}
        heading='Deal Lost'
      />

      <ConfirmDialog
        open={isReopenDialogOpen}
        onOk={handleReopen}
        handleClose={() => setIsReopenDialogOpen(false)}
        notification={'Deal will be reopened'}
      />
    </>
  )
}

export default Header
