import { Box, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAddDealStatusMutation, useEditDealStatusMutation, useLazyDealStatusDataQuery } from '../../../../services'
import { UspConfigurationDealStatusViewResult } from '../../../../models/apiTypes'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationDealStatusViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}

const AddEditDealStatusesModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addDealStatus, { isLoading: isLoadingDealStatus, isSuccess: isSuccessDealStatus }] = useAddDealStatusMutation()
  const [editDealStatus, { isLoading: isLoadingEditDealStatus, isSuccess: isSuccessEditDealStatus }] = useEditDealStatusMutation()
  const [getDealStatusData, { isLoading: isLoadingGetDealStatus, isSuccess: isSuccessGetDealStatus, data }] = useLazyDealStatusDataQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editDealStatus({
        dealStatusId: rowData?.ID?.toString(),
        uspConfigurationDealStatusMainAddEdit: {
          dealStatus: values.Description,
          isActive: values.IsActive,
          isDeadDeal: values?.IndicatesDealDead,
          isDelivered: values?.IndicatesDealDelivered,
          isUnwound: values?.IndicatesDealUnwound,
        },
      })
    } else {
      addDealStatus({
        uspConfigurationDealStatusMainAddEdit: {
          dealStatus: values.Description,
          isActive: values.IsActive,
          isDeadDeal: values?.IndicatesDealDead,
          isDelivered: values?.IndicatesDealDelivered,
          isUnwound: values?.IndicatesDealUnwound,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getDealStatusData({
        dealStatusId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
        IndicatesDealDead: false,
        IndicatesDealDelivered: false,
        IndicatesDealUnwound: false,
      })
    }
    if (isSuccessGetDealStatus && type === 'edit') {
      setInitialValues({
        Description: data?.DealStatus,
        IsActive: data?.IsActive,
        IndicatesDealDead: data?.IsDeadDeal,
        IndicatesDealDelivered: data?.IsDelivered,
        IndicatesDealUnwound: data?.IsUnwound,
      })
    }
  }, [isSuccessGetDealStatus, data, type])

  useEffect(() => {
    if (isSuccessDealStatus || isSuccessEditDealStatus) {
      onClose()
      submit()
    }
  }, [isSuccessDealStatus, isSuccessEditDealStatus])

  const isLoading = useMemo(
    () => isLoadingDealStatus || isLoadingEditDealStatus || isLoadingGetDealStatus,
    [isLoadingDealStatus, isLoadingEditDealStatus, isLoadingGetDealStatus],
  )
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Deal Status</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IndicatesDealDead'
                    label='Indicates Deal is Dead?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IndicatesDealDelivered'
                    label='Indicates Deal is Delivered?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IndicatesDealUnwound'
                    label='Indicates Deal is Unwound?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditDealStatusesModal
