import React from 'react'
import { default as MuiCheckbox } from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Field } from 'react-final-form'
interface Props {
  sx?: any
  name: string
  label?: string
  defaultChecked?: boolean
  disabled?: boolean
  onChange?: (event: any) => void
}

export const CheckboxField = ({ sx, name, label, defaultChecked, disabled, onChange }: Props) => {
  return (
    <Field name={name} type='checkbox' initialValue={defaultChecked}>
      {({ input }) => {
        return (
          <FormControlLabel
            sx={sx}
            label={label}
            disabled={disabled}
            control={
              <MuiCheckbox
                {...input}
                checked={input?.checked}
                onChange={(event) => {
                  input.onChange(event?.target?.checked)
                  onChange && onChange(event)
                }}
              />
            }
          />
        )
      }}
    </Field>
  )
}

export default CheckboxField
