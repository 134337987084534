import { Box } from '@mui/material'
import { useEffect } from 'react'
import { appRoute } from '../../constants'
import { useNavigate } from 'react-router-dom'

export const Home = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(appRoute.Dashboard)
  }, [])

  return <Box>Home page</Box>
}

export default Home
