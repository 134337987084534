import React, { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { UspGetDataStateDealSettingsResult, UspListConfigurationProductResult } from '../../../../../../../models'
import removeMask from '../../../../../../../utils/removeMask'
import Popover from '@mui/material/Popover'
import Grid from '@mui/material/Grid'
import AmountField from '../../../../../../../ui/AmountField'
import { CheckboxField } from '../../../../../../../ui'
import Button from '@mui/material/Button'
import { generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'

const classesBox = generateUtilityClasses('Container', ['root', 'row', 'btnWrap', 'icon'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '272px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.row}`]: {
    marginBottom: theme.spacing(4),
  },
  [`& .${classesBox.btnWrap}`]: {
    justifyContent: 'space-between',
  },
  [`& .${classesBox.icon}`]: {
    fontSize: 16,
    color: theme.palette.text.button,
  },
}))

interface Props {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (value: HTMLButtonElement | null) => void
  listProductData?: UspListConfigurationProductResult[]
  stateDealSettingsData?: UspGetDataStateDealSettingsResult | null
  productsForm: any[]
}

export const ProductPopover = ({ anchorEl, setAnchorEl, listProductData, stateDealSettingsData, productsForm }: Props) => {
  const open = Boolean(anchorEl)
  const [formValues, setFormValues] = useState<any>({})
  const { values } = useFormState()
  const { change } = useForm()

  const handleClose = () => {
    setAnchorEl(null)

    Object?.keys(formValues)?.forEach((item: string) => {
      change(item, formValues[item])
    })
  }

  const handleSave = () => {
    // save values and
    let product = 0
    const formValues: any = {}
    Object?.keys(values)?.forEach((item: string) => {
      // get sum of all product
      if (item?.includes('ProductFieldValue')) {
        formValues[item] = values[item]
        product = product + Number(removeMask(values[item]))
      }
      if (item?.includes('ProductFieldIsActive')) {
        formValues[item] = values[item]
      }
    })
    change(`Products`, product?.toString())
    setFormValues(formValues)

    setAnchorEl(null)
  }

  useEffect(() => {
    // Set initial values of fields
    const formValues: any = {}
    if (listProductData) {
      productsForm?.forEach((item) => {
        // set initial values of fields
        const stateProductDataItem = stateDealSettingsData?.ProductDataInJson?.find(
          (stateProductItem) => stateProductItem?.StateProductID === item?.ID,
        )
        change(`ProductFieldValue${item.ID}`, `${stateProductDataItem?.ProductPrice}`)
        change(`ProductFieldIsActive${item.ID}`, stateProductDataItem?.IsActive)
        formValues[`ProductFieldValue${item.ID}`] = `${stateProductDataItem?.ProductPrice}`
        formValues[`ProductFieldIsActive${item.ID}`] = stateProductDataItem?.IsActive
      })
    }
    setFormValues(formValues)
  }, [stateDealSettingsData, listProductData])

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box display='flex' flexDirection='column' gap={3} p={3}>
        {productsForm?.map(({ id, fieldName, fieldLabel, productInitialAmount, fieldCheckboxName, isFixedAmount }) => {
          return (
            <Box display='flex' justifyContent='center' gap={3}>
              <AmountField variant='standard' name={fieldName} label={fieldLabel} disabled={!values[fieldCheckboxName] || isFixedAmount} fullWidth />
              <CheckboxField
                sx={{ margin: '0px' }}
                name={fieldCheckboxName}
                onChange={(values) => {
                  if (values?.target?.checked) {
                    change(fieldName, productInitialAmount?.toString())
                  } else {
                    change(fieldName, '0')
                  }
                }}
              />
            </Box>
          )
        })}
        <Grid container spacing={2} justifyContent={'flex-end'}>
          <Grid item>
            <Button variant='contained' color='error' onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default ProductPopover
