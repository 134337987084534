import { TFullResponse } from '../../../models'
import { convertStringToDate } from '../../../utils/dateUtils'
import { getCurrentDate } from '../../../utils/general'

export const transformToRequestValues = (values?: any, tableData?: TFullResponse[]) => {
  const jsonEntriesData = tableData?.map((item: TFullResponse) => {
    return {
      VendorID: null,
      DealerID: null,
      CarDepositID: null,
      BranchID: item?.BranchCode || null,
      CarID: item?.CarID || null,
      DealID: null,
      CarExpenseType: item?.CarExpenseType || null,
      TransactionAmount: item.BillAmount,
      Note: item.Description || null,
      ChartOfAccountID: item?.AccountNumberName || null,
      DealTradeID: item?.SelectedSearchAccount?.DealTradeID || null,
    }
  })
  return {
    transactionType: values.EntryType === 'Ledger & Schedules' ? 'AE' : 'LOAE',
    accountingDate: convertStringToDate(values.EntryDate),
    jsonAdjustingEntryData: jsonEntriesData,
  }
}

export const transformToFormValues = (modalDefaultValues?: any) => {
  return {
    EntryType: modalDefaultValues?.EntryType || 'Ledger & Schedules',
    EntryDate: modalDefaultValues?.EntryDate || getCurrentDate(),
    Sign: modalDefaultValues?.Sign,
    ChartOfAccountBill: modalDefaultValues?.AccountNumberName,
    ExpenseType: modalDefaultValues?.CarExpenseType,
    IsFloorplan: modalDefaultValues?.IsFloorplan,
    Branch: modalDefaultValues?.BranchCode,
    Amount:
      modalDefaultValues?.Sign === 'Debit'
        ? modalDefaultValues?.BillAmount?.toString()
        : modalDefaultValues?.Sign === 'Credit'
        ? (modalDefaultValues?.BillAmount * -1)?.toString()
        : '0',
    Note: modalDefaultValues?.Description,
    searchResults: modalDefaultValues?.SearchResult,
  }
}
