import { Button, Container, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useForm, useFormState } from 'react-final-form'
import { DateField, TextField } from '../../../../../ui'
import { debounce } from 'lodash'
import { UspListAccountingCashAccountsResult } from '../../../../../models'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import AddIcon from '@mui/icons-material/Add'
import { useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'searchWrap', 'searchBtn'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 1, 2, 1),
    alignItems: 'center',
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.searchBtn}`]: {
    // width: '120px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    margin: theme.spacing(0, 1, 0, 'auto'),
  },
}))

interface Props {
  submit: (...args: any) => any
  bankAccounts: UspListAccountingCashAccountsResult[]
  initialLoad: boolean
}

const AccountingChecksSearchBlock = ({ submit, bankAccounts, initialLoad }: Props) => {
  const { values } = useFormState()
  const form = useForm()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))
  const billPayType = useSelector((state) => selectDropdownByName(state, 'BillPayType'))
  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    if (initialLoad) {
      form.change('startDate', null)
      form.change('endDate', null)
    }
  }, [initialLoad])

  useEffect(() => {
    // search with debounce
    if (values?.startDate?.length > 1 || values?.endDate) {
      updateWithDebounce()
    }
  }, [values?.startDate])

  useEffect(() => {
    // search with debounce
    if (values?.endDate?.length > 1 || values?.startDate) {
      updateWithDebounce()
    }
  }, [values?.endDate])

  useEffect(() => {
    if (values?.BillPayType || values?.Number || values?.BankAccount || values?.BankBranch || values?.dateRange || values?.dateRange !== '') {
      submit(values)
    }
  }, [values?.BillPayType, values?.Number, values?.BankAccount, values.BankBranch, values?.dateRange])

  return (
    <Grid container spacing={2} p={3}>
      {billPayType && (
        <Grid item xs={2}>
          <DropdownFieldSearch name='BillPayType' label='Bill Pay Type' options={billPayType?.ReturnData} optionKey='ID' optionValue='BillPayType' />
        </Grid>
      )}

      <Grid item xs={2}>
        <TextField name='Number' label='Number' fullWidth />
      </Grid>
      <Grid item xs={2}>
        <DropdownFieldSearch
          name='BankAccount'
          label='Bank Account'
          options={bankAccounts as UspListAccountingCashAccountsResult[]}
          optionKey='ID'
          optionValue='ChartOfAccount'
        />
      </Grid>
      {branch && (
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='BankBranch'
            label='Bank Branch'
            labelId='BranchId'
            fullWidth
            options={branch}
            optionKey='BranchID'
            optionValue='Branch'
          />
        </Grid>
      )}

      <Grid item xs={2}>
        <DropdownFieldSearch
          name='dateRange'
          label='Date'
          options={[
            { key: 'between', value: 'Between' },
            { key: 'today', value: 'Today' },
            { key: 'yesterday', value: 'Yesterday' },
            { key: 'lastSevenDays', value: 'Last Seven Days' },
            { key: 'lastThirtyDays', value: 'Last Thirty Days' },
          ]}
        />
      </Grid>
      {values?.dateRange === 'between' && (
        <>
          <Grid item xs={2}>
            <DateField name='startDate' label='Start Date' fullWidth disableFuture />
          </Grid>
          <Grid item xs={2}>
            <DateField name='endDate' label='End Date' fullWidth disableFuture />
          </Grid>
        </>
      )}
      {/* {userAccess?.CanIssueChecks && (
        <Grid item className={classes.searchBtn}>
          <Button startIcon={<AddIcon />} variant='contained' onClick={() => navigate(appRoute.AccountingChecksAdd)}>
            Add new
          </Button>
        </Grid>
      )} */}
    </Grid>
  )
}

export default AccountingChecksSearchBlock
