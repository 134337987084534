import * as yup from 'yup'

export const collectionsCreateWorklistValidation: yup.SchemaOf<any> = yup
  .object({
    FilterBy: yup.string().required('Filter By is required'),
    startDate: yup
      .date()
      .typeError('Invalid Date')
      .when('FilterBy', {
        is: (value: string) => value === 'NextScheduledDueDate' || value === 'DueDate',
        then: yup.date().required('Start Date is required').typeError('Invalid input'),
        otherwise: yup.date().notRequired(),
      }),
    endDate: yup
      .date()
      .typeError('Invalid Date')
      .when('FilterBy', {
        is: (value: string) => value === 'NextScheduledDueDate' || value === 'DueDate',
        then: yup.date().required('End Date is required').typeError('Invalid input'),
        otherwise: yup.date().notRequired(),
      }),

    StartingDaysPastDue: yup.number().when('FilterBy', {
      is: 'PastDue',
      then: yup.number().required('Starting Days Past Due is required'),
      otherwise: yup.number().optional(),
    }),
    EndingDaysPastDue: yup.number().when('FilterBy', {
      is: 'PastDue',
      then: yup
        .number()
        .required('Ending Days Past Due is required')
        .test('is-greater-or-equal', 'Ending Days Past Due must be greater or equal to Starting Days Past Due', function (value) {
          const { StartingDaysPastDue } = this.parent
          if (value === undefined || StartingDaysPastDue === undefined) {
            return false
          }
          return value >= StartingDaysPastDue
        }),
      otherwise: yup.number().optional(),
    }),
    PastDue: yup.string().required('Past Due is required').typeError('Invalid Input'),
    WorklistName: yup.string().when('IsPersonalWorklist', {
      is: false,
      then: yup.string().required('Worklist Name is required'),
      otherwise: yup.string().optional(),
    }),
    OrderBy: yup.string().required('Order By is required').typeError('Invalid Input'),
  })
  .required()
