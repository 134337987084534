import dayjs from 'dayjs'
import * as yup from 'yup'

const today = dayjs().endOf('day')
export const accountingBankDepositValidation: yup.SchemaOf<any> = yup
  .object({
    DepositDate: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
    BankBranch: yup.number().required().label('Bank Branch'),
    UndepositedFundsBranch: yup.number().required().label('Undeposited Funds Branch'),
    DepositAmount: yup
      .number()
      .transform((value, originalValue) => {
        const processedValue = typeof originalValue === 'string' ? parseFloat(originalValue.replace(/,/g, '')) : originalValue
        return !isNaN(processedValue) ? processedValue : value
      })
      .label('Amount')
      .required()
      .min(0.1, 'Enter Deposit Amount')
      .typeError('Invalid number'),
  })
  .required()
