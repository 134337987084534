import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { PercentField, TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useFormState } from 'react-final-form'
import { selectDropdownByName } from '../../../../../services'
import { useSelector } from 'react-redux'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

export const InterestForm = ({ onDropdownFormChange }: Props) => {
  const { values } = useFormState()
  const interestMethod = useSelector((state) => selectDropdownByName(state, 'InterestMethod'))
  const interestRebateMethod = useSelector((state) => selectDropdownByName(state, 'InterestRebateMethod'))

  // Filter options for InterestRebateMethod based on the State or Null value
  const interestRebateMethodOptions = interestRebateMethod?.ReturnData?.filter(
    (option) => option?.StateFilter === null || option?.StateFilter === values?.State,
  )
  return (
    <>
      <CardHeader title='Interest' />
      <Grid container spacing={2} px={3} pt={3}>
        {interestMethod && (
          <Grid item xs={12} md={6}>
            <DropdownFieldSearch
              name='InterestMethod'
              label='Interest Method'
              fullWidth
              options={interestMethod?.ReturnData}
              optionKey='InterestMethod'
              optionValue='Description'
              onChange={() => {
                onDropdownFormChange()
              }}
            />
          </Grid>
        )}

        {interestRebateMethodOptions && (
          <Grid item xs={12} md={6}>
            <DropdownFieldSearch
              name='InterestRebateMethod'
              label='Interest Rebate Method'
              fullWidth
              options={interestRebateMethodOptions}
              optionKey='InterestRebateMethod'
              optionValue='Description'
              onChange={() => {
                onDropdownFormChange()
              }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <TextField name='InterestRebateDays' label='Interest Rebate Days' fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField name='FIProductsRebate' label='F&I Product Rebate Days' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField variant='standard' name='MaxInterestRate' label='Max Interest Rate (%)' fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='CalculateInterestAfterMaturity'
            label='Calculate Interest After Maturity Date?'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <PercentField variant='standard' name='InterestRateAfterMaturity' label='Interest Rate After Maturity (%)' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            options={[
              { key: 365, value: '365' },
              { key: 360, value: '360' },
            ]}
            name='InterestDaysPerYear'
            label='Interest Days Per Year'
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='CalculateDealRate'
            label='Calculate Deal Using Add-on Rate?'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default InterestForm
