import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { UspAccountingChartApAccountsView, VendorResponseSp } from '../../../../../models'
import { DropdownCheckboxes } from '../../../../../ui'
import { selectDropdownByNameActiveOnly, useGetAccountingChartApAccountsMutation, useVendorListsMutation } from '../../../../../services'
import { useSelector } from 'react-redux'

const classes = generateUtilityClasses('form', ['root', 'row', 'btnWrap'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
    minWidth: '350px',
    maxWidth: '350px',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.btnWrap}`]: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

interface Props {
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  formValues: any
}

const FilteringModal = ({ open, handleClose, formValues }: Props) => {
  const [chartOfAccountAp, { data: chartOfAccountApData }] = useGetAccountingChartApAccountsMutation()
  const [vendorList, { data: vendorListData }] = useVendorListsMutation()
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  useEffect(() => {
    chartOfAccountAp({})
    vendorList({
      uspVendorLists: {
        currentID: null,
        vendorType: null,
      },
    })
  }, [])

  return (
    <Dialog open={open} onClose={handleClose}>
      <form>
        <DialogTitle>Accounts Payable Aging</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={1}>
            {branch && (
              <Grid item xs={12}>
                <DropdownCheckboxes
                  name='Branch'
                  label='Branch'
                  options={branch}
                  optionKey={'BranchID'}
                  optionValue={'Branch'}
                  fullWidth
                  defaultValue={formValues?.Branch}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <DropdownCheckboxes
                name='Vendor'
                label='Vendor'
                options={vendorListData as VendorResponseSp[]}
                optionKey={'ID'}
                optionValue={'Name'}
                fullWidth
                defaultValue={formValues?.Vendor}
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownFieldSearch
                name='COA'
                label='Chart Of Account (A/P)'
                options={chartOfAccountApData as UspAccountingChartApAccountsView[]}
                optionKey={'ID'}
                optionValue={'AccountNumberName'}
                fullWidth
                defaultValue={formValues?.COA}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' type='submit'>
            Run
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default FilteringModal
