import { useEffect, useState, lazy, Suspense } from 'react'
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import { ProtectedRoute } from './components/ProtectedRoute'
import { SignIn } from './pages/SignIn'
import { SignUp } from './pages/SignUp'
import { ResetPassword } from './pages/ResetPassword'
import { ChangePassword } from './pages/ChangePassword'
import { appRoute } from './constants'
import { SelectSession } from './pages/SelectSession'
import { LeadsList, LeadsAddEdit, LeadsTask, LeadsReference, LeadsStipulations, LeadsReferenceAddEdit } from './pages/Leads'
import {
  InventoryList,
  InventoryAdd,
  InventoryEdit,
  InventoryEditExpenses,
  InventoryEditNotes,
  InventoryEditFloorplan,
  InventoryEditPictures,
  InventoryEditDocuments,
  InventoryEditPrint,
} from './pages/Inventory'
import DealsTrade from './pages/Deals/Trade/DealsTrade'
import { DealsList, DealsAddEdit, DealsTask, DealEditBuyer, DealsInsurance } from './pages/Deals'
import { AccountingList, AccountingDetails } from './pages/Accounting'
import Insights from './pages/Insights/Insights'
import Dashboard from './pages/Dashboard/Dashboard'
import Home from './pages/Home/Home'
import Profile from './pages/Profile/Profile'
import { UserList } from './pages/Users/UserList'
import { ContactList } from './pages/Contacts/ContactList'
//import DashboardLayout from './components/DashboardLayout/DashboardLayout'
import DashboardLayout from './layouts/dashboard'
import './global.css'

import { Box, CircularProgress } from '@mui/material'
import { prepareApp } from './services/app'
import { AddEditUser } from './pages/Users/AddEditUser'
import { useSelector, useDispatch } from 'react-redux'
import { onInit, isAuthorized, isSessionSelected, getAuthInProgress, setUserAccessRights } from './redux/slices/authSlice'
import useMediaQuery from '@mui/material/useMediaQuery'
import AccountingTasks from './pages/Accounting/AccountingTasks/AccountingTasks'
import AccountingFinancials from './pages/Accounting/AccountingFinancials/AccountingFinancials'
import DealsTradeAddEdit from './pages/Deals/Trade/components/DealsTradeAddEdit/DealsTradeAddEdit'
import IncomeStatement from './pages/Accounting/AccountingFinancials/IncomeStatement/IncomeStatement'
import DealsReference from './pages/Deals/DealsReference/DealsReference'
import DealsReferenceAddEdit from './pages/Deals/DealsReferenceAddEdit/DealsReferenceAddEdit'
import DealProducts from './pages/Deals/DealsProducts/DealProducts'
import DealsCommissions from './pages/Deals/DealsCommissions/DealsCommissions'
import DealsReferral from './pages/Deals/DealsReferral/DealsReferral'
import DealsReferralAddEdit from './pages/Deals/DealsReferral/components/DealsReferralAddEdit/DealsReferralAddEdit'
import LendersList from './pages/Lenders/LendersList'
import AddEditLender from './pages/Lenders/AddEditLender/AddEditLender'
import VendorList from './pages/Vendors/VendorList/VendorList'
import AddEditVendor from './pages/Vendors/AddEditVendor/AddEditVendor'
import AccountsList from './pages/Accounts/AccountsList/AccountsList'
import AccountsOverview from './pages/Accounts/AccountsOverview/AccountsOverview'
import AccountsPayHistoryNew from './pages/Accounts/AccountsPayHistoryNew/AccountsPayHistory'
import AccountsNoteHistory from './pages/Accounts/AccountsNoteHistory/AccountsNoteHistory'
import AccountsEditBuyer from './pages/Accounts/AccountsEditBuyer/AccountsEditBuyer'
import AccountsReferences from './pages/Accounts/AccountsReferences/AccountsReferences'
import AccountsReferencesAddEdit from './pages/Accounts/AccountsReferenceAddEdit/AccountsReferencesAddEdit'
import AccountsBankruptcy from './pages/Accounts/AccountsBankruptcy/AccountsBankruptcy'
import AccountsTitle from './pages/Accounts/AccountsTitle/AccountsTitle'
import AccountsRepo from './pages/Accounts/AccountsRepo/AccountsRepo'
import AccountsDeals from './pages/Accounts/AccountsDeals/AccountsDeals'
import ReferralEdit from './pages/Accounts/AccountsDeals/tabs/TabReferrals/subpage/ReferralEdit/ReferralEdit'
import TradeEdit from './pages/Accounts/AccountsDeals/tabs/TabTrades/subpage/TradeEdit/TradeEdit'
import ChartOfAccounts from './pages/Accounting/ChartOfAccounts/ChartOfAccounts'
import AccountingBilling from './pages/Accounting/AccountingBilling/AccountingBilling'
import AccountingBillPay from './pages/Accounting/AccountingBillPay/AccountingBillPay'
import AccountingChecks from './pages/Accounting/AccountingChecks/AccountingChecks'
import GlobalSettings from './pages/Settings/SettingsAdmin/GlobalSettings/GlobalSettings'
import SettingsExpenses from './pages/Settings/SettingsExpenses/SettingsExpenses'
import SettingsLeadsAdvertisements from './pages/Settings/SettingsLeadAdvertisements/SettingsLeadsAdvertisements'
import SettingsLeadsStipulations from './pages/Settings/SettingsLeadsStipulations/SettingsLeadsStipulations'
import SettingsVendors from './pages/Settings/SettingsVendors/SettingsVendors'
import SettingsLeadsInterestLevels from './pages/Settings/SettingsLeadsInterestLevels/SettingsLeadsInterestLevels'
import SettingsLeadsReferenceRelationships from './pages/Settings/SettingsLeadsReferenceRelationships/SettingsLeadsReferenceRelationships'
import SettingsInventoryPurchaseTypes from './pages/Settings/SettingsInventoryPurchaseTypes/SettingsInventoryPurchaseTypes'
import SettingsInventoryMakes from './pages/Settings/SettingsInventoryMakes/SettingsInventoryMakes'
import SettingsInventoryDrivetrains from './pages/Settings/SettingsInventoryDrivetrains/SettingsInventoryDrivetrains'
import SettingsInventoryFuelTypes from './pages/Settings/SettingsInventoryFuelTypes/SettingsInventoryFuelTypes'
import SettingsInventoryTitleTypes from './pages/Settings/SettingsInventoryTitleTypes/SettingsInventoryTitleTypes'
import SettingsInventoryConditions from './pages/Settings/SettingsInventoryConditions/SettingsInventoryConditions'
import SettingsInventoryBodyTypes from './pages/Settings/SettingsInventoryBodyTypes/SettingsInventoryBodyTypes'
import SettingsLeadsCreditAppStatuses from './pages/Settings/SettingsLeadsCreditAppStatuses/SettingsLeadsCreditAppStatuses'
import SettingsDealsDealStatuses from './pages/Settings/SettingsDealsDealStatuses/SettingsDealsDealStatuses'
import SettingsPaymentCheckTypes from './pages/Settings/SettingsPaymentCheckTypes/SettingsPaymentCheckTypes'
import SettingsPaymentPaymentsForm from './pages/Settings/SettingsPaymentPaymentForms/SettingsPaymentPaymentsForm'
import SettingsPaymentPaymentMethods from './pages/Settings/SettingsPaymentPaymentMethods/SettingsPaymentPaymentMethods'
import SettingsPaymentSideNote from './pages/Settings/SettingsPaymentSideNote/SettingsPaymentSideNote'
import SettingsCollectionsRepoTypes from './pages/Settings/SettingsCollectionsRepoTypes/SettingsCollectionsRepoTypes'
import SettingsPaymentCreditCardTypes from './pages/Settings/SettingsPaymentCreditCardTypes/SettingsPaymentCreditCardTypes'
import SettingsLeadsApplicantExpenses from './pages/Settings/SettingsLeadsApplicantExpenses/SettingsLeadsApplicantExpenses'
import SettingsInventoryPackFees from './pages/Settings/SettingsInventoryPackFees/SettingsInventoryPackFees'
import SettingsInventoryStatuses from './pages/Settings/SettingsInventoryStatuses/SettingsInventoryStatuses'
import SettingsAdminIPAddresses from './pages/Settings/SettingsAdminIPAddresses/SettingsAdminIPAddresses'
import CollectionsMyWorklist from './pages/Collections/CollectionsMyWorklist/CollectionsMyWorklist'
import CollectionsCreateWorklist from './pages/Collections/CollectionsCreateWorklist/CollectionsCreateWorklist'
import CollectionsLoginWorklist from './pages/Collections/CollectionsLoginWorklist/CollectionsLoginWorklist'
import AccountingMultilineBilling from './pages/Accounting/AccountingBilling/subpages/AccountingMultilineBilling'
import InventoryAging from './pages/Insights/InventoryAging/InventoryAging'
import AccountingChecksAdd from './pages/Accounting/AccountingChecks/subpage/AccountingChecksAdd/AccountingChecksAdd'
import AccountingJournalEntry from './pages/Accounting/AccountingJournalEntry/AccountingJournalEntry'
import TradeLiens from './pages/Insights/TradeLiens/TradeLiens'
import AccountsPayableAging from './pages/Insights/AccountsPayableAging/AccountsPayableAging'
import SalesTax from './pages/Insights/SalesTax/SalesTax'
import Receivables from './pages/Insights/Receivables/Receivables'
import AccountsReceivableAging from './pages/Insights/AccountsReceivableAging/AccountsReceivableAging'
import AccountsReceivableRecency from './pages/Insights/AccountsReceivableRecency/AccountsReceivableRecency'
import SalesCommissions from './pages/Insights/SalesCommissions/SalesCommissions'
import Sales from './pages/Insights/Sales/Sales'
import SettingsAdminUserRoles from './pages/Settings/SettingsAdminUserRoles/SettingsAdminUserRoles'
import SettingsAdminRFC from './pages/Settings/SettingsAdminRFC/SettingsAdminRFC'
import SettingsAdminDealers from './pages/Settings/SettingsAdminDealers/DealersList/SettingsAdminDealers'
import AddEditDealer from './pages/Settings/SettingsAdminDealers/AddEditDealer/AddEditDealer'
import SettingsStateSettings from './pages/Settings/SettingsStateSettings/SettingsStateSettings'
import CommunicationCallOutcome from './pages/Settings/SettingsCommunicationsCallOutcome/CommunicationCallOutcome'
import SettingsStateCityTaxes from './pages/Settings/SettingsStateCityTaxes/SettingsStateCityTaxes'
import SettingsStateCountyTaxes from './pages/Settings/SettingsStateCountyTaxes/SettingsStateCountyTaxes'
import AddEditAdminUserRoles from './pages/Settings/SettingsAdminUserRoles/AddEditAdminUserRoles/AddEditAdminUserRoles'
import SettingsAdminLots from './pages/Settings/SettingsAdminLots/SettingsAdminLots'
import SettingsDealDealerReserve from './pages/Settings/SettingsDealDealerReserve/SettingsDealDealerReserve'
import SettingsDealsInsurance from './pages/Settings/SettingsDealsInsurance/SettingsDealsInsurance'
import AddEditInsurance from './pages/Settings/SettingsDealsInsurance/AddEditInsurance/AddEditInsurance'
import InventoryEditPayments from './pages/Inventory/InventoryEditPayments/InventoryEditPayments'
import { dropdownsApi, useLazyGetUserRoleAccessRightsQuery } from './services'
import SettingsFormsList from './pages/Settings/SettingsForms/SettingsFormsList/SettingsFormsList'
import SettingsFormsMapping from './pages/Settings/SettingsForms/SettingsFormsMapping/SettingsFormsMapping'
import { SettingsFormsPacksList } from './pages/Settings/SettingsForms/SettingsFormsPacks/SettingsFormsPacksList'
import FormList from './pages/Settings/SettingsForms/SettingsFormsPacks/subpage/FormList'
import PrintForms from './pages/Accounts/Actions/PrintForms/PrintForms'
import { enqueueNotification } from './redux/slices/notificationsSlice'
import AccountsSubsidiaryReconciliation from './pages/Insights/AccountsSubsidiaryReconciliation/AccountsSubsidiaryReconciliation'
import Payments from './pages/Insights/Payments/Payments'
import { SettingsDrawer, SettingsProvider } from './components/settings'
import ThemeProvider from './theme'
import { LoadingScreen } from './components/loading-screen'
import InventoryPage from './pages/Inventory/InventoryPage'
import BankDepositModal from './pages/Accounting/BankDepositModal/BankDepositModal'
import dayjs from 'dayjs'
import { DealsAddEditLease } from './pages/Deals/DealsAddEditLease/DealsAddEditLease'
import AccountsChargeOffs from './pages/Insights/AccountsChargeOffs/AccountsChargeOffs'

function App() {
  const dispatch = useDispatch()
  const isAuth = useSelector(isAuthorized)
  const isSession = useSelector(isSessionSelected)
  const authInProgress = useSelector(getAuthInProgress)
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [getUserAccessRights, { data: userAccessRightsData, isSuccess: userAccessRightsIsSuccess }] = useLazyGetUserRoleAccessRightsQuery()

  const [loading, setLoading] = useState(true)

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const date = new Date().toLocaleString('en-US', { timeZone })
  useEffect(() => {
    async function fetchAndSetUserAccessRights() {
      if (isAuth && isSession) {
        try {
          const response = await getUserAccessRights({})
          if (response?.isSuccess) {
            dispatch(setUserAccessRights(response.data))
            // @ts-ignore: Unreachable code error
            dispatch(dropdownsApi.endpoints.getAllDropdowns.initiate())
          }
        } catch (error) {
          dispatch(enqueueNotification({ message: error }))
        }
      }
    }

    fetchAndSetUserAccessRights()
  }, [isAuth, isSession, getUserAccessRights, dispatch])

  useEffect(() => {
    dispatch(onInit())
    prepareApp().then(() => {
      setLoading && setLoading(false)
    })
  }, [])

  if (loading) {
    return (
      <Box
        sx={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <CircularProgress />
      </Box>
    )
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route
          element={
            <ProtectedRoute
              userAccessRightsIsSuccess={userAccessRightsIsSuccess}
              userAccessRightsData={userAccessRightsData}
              allowed={isAuth}
              redirectTo={appRoute.SignIn}
            />
          }
        >
          <Route
            element={
              <DashboardLayout>
                <Suspense fallback={<LoadingScreen />}>
                  <ProtectedRoute
                    userAccessRightsIsSuccess={userAccessRightsIsSuccess}
                    userAccessRightsData={userAccessRightsData}
                    allowed={isAuth && isSession}
                    redirectTo={appRoute.SelectSession}
                  />
                </Suspense>
              </DashboardLayout>
            }
          >
            <Route path={appRoute.Home} element={<Home />} />
            <Route path={appRoute.Dashboard} element={<InventoryList />} />
            <Route path={appRoute.LeadsList} element={<LeadsList />} />
            <Route path={appRoute.LeadsAddEdit} element={<LeadsAddEdit />} />
            <Route path={appRoute.LeadsTask} element={<LeadsTask />} />
            <Route path={appRoute.LeadsReference} element={<LeadsReference />} />
            <Route path={appRoute.LeadsReferenceAddEdit} element={<LeadsReferenceAddEdit />} />
            <Route path={appRoute.LeadsStipulations} element={<LeadsStipulations />} />
            <Route path={appRoute.Inventory} element={<InventoryPage />} />
            <Route path={appRoute.InventoryAdd} element={<InventoryAdd />} />
            <Route path={appRoute.InventoryAddVin} element={<InventoryAdd />} />
            <Route path={appRoute.InventoryEdit} element={<InventoryEdit />} />
            <Route path={appRoute.InventoryEditExpenses} element={<InventoryEditExpenses />} />
            <Route path={appRoute.InventoryEditNotes} element={<InventoryEditNotes />} />
            <Route path={appRoute.InventoryEditFloorplan} element={<InventoryEditFloorplan />} />
            <Route path={appRoute.InventoryEditPictures} element={<InventoryEditPictures />} />
            <Route path={appRoute.InventoryEditDocuments} element={<InventoryEditDocuments />} />
            <Route path={appRoute.InventoryEditPrint} element={<InventoryEditPrint />} />
            <Route path={appRoute.InventoryEditPayments} element={<InventoryEditPayments />} />
            <Route path={appRoute.DealsList} element={<DealsList />} />
            <Route path={appRoute.DealsAddEdit} element={<DealsAddEdit />} />
            <Route path={appRoute.DealsAddEditLease} element={<DealsAddEditLease />} />
            <Route path={appRoute.DealsTask} element={<DealsTask />} />
            <Route path={appRoute.DealEditBuyer} element={<DealEditBuyer />} />
            <Route path={appRoute.DealsTrade} element={<DealsTrade />} />
            <Route path={appRoute.DealsTradeAdd} element={<DealsTradeAddEdit />} />
            <Route path={appRoute.DealTradeAddVin} element={<DealsTradeAddEdit />} />
            <Route path={appRoute.DealsTradeEdit} element={<DealsTradeAddEdit />} />
            <Route path={appRoute.DealsInsuranceAdd} element={<DealsInsurance />} />
            <Route path={appRoute.DealsInsuranceEdit} element={<DealsInsurance />} />
            <Route path={appRoute.DealsReference} element={<DealsReference />} />
            <Route path={appRoute.DealsReferenceAddEdit} element={<DealsReferenceAddEdit />} />
            <Route path={appRoute.DealsProducts} element={<DealProducts />} />
            <Route path={appRoute.DealsCommissions} element={<DealsCommissions />} />
            <Route path={appRoute.DealsReferral} element={<DealsReferral />} />
            <Route path={appRoute.DealsReferralAddEdit} element={<DealsReferralAddEdit />} />
            <Route path={appRoute.Accounts} element={<AccountsList />} />
            <Route path={appRoute.PrintForms} element={<PrintForms />} />
            <Route path={appRoute.AccountsBuyerEdit} element={<AccountsEditBuyer />} />
            <Route path={appRoute.AccountsReferencesList} element={<AccountsReferences />} />
            <Route path={appRoute.AccountsReferencesAddEdit} element={<AccountsReferencesAddEdit />} />
            <Route path={appRoute.AccountsOverview} element={<AccountsOverview />} />
            <Route path={appRoute.AccountsPayHistory} element={<AccountsPayHistoryNew />} />
            <Route path={appRoute.AccountsNoteHistory} element={<AccountsNoteHistory />} />
            <Route path={appRoute.AccountsTradeEdit} element={<TradeEdit />} />
            <Route path={appRoute.AccountsBankruptcy} element={<AccountsBankruptcy />} />
            <Route path={appRoute.AccountsTitle} element={<AccountsTitle />} />
            <Route path={appRoute.AccountsRepo} element={<AccountsRepo />} />
            {/* <Route path={appRoute.AccountsDeal} element={<AccountsDeals />} /> */}
            <Route path={appRoute.Payments} element={<Payments />} />
            <Route path={appRoute.AccountingChecks} element={<AccountingChecks />} />
            <Route path={appRoute.AccountingChecksAdd} element={<AccountingChecksAdd />} />
            <Route path={appRoute.AccountingTasks} element={<AccountingTasks />} />
            <Route path={appRoute.AccountingList} element={<AccountingList />} />
            <Route path={appRoute.AccountsReferralEdit} element={<ReferralEdit />} />
            <Route path={appRoute.AccountingFinancials} element={<AccountingFinancials />} />
            <Route path={appRoute.AccountingFinancialsIncomeStatement} element={<IncomeStatement />} />
            <Route path={appRoute.AccountingFinancialsBalanceSheet} element={<IncomeStatement />} />
            <Route path={appRoute.AccountingFinancialsTrialBalance} element={<IncomeStatement />} />
            <Route path={appRoute.AccountingDetails} element={<AccountingDetails />} />
            <Route path={appRoute.AccountingChartOfAccounts} element={<ChartOfAccounts />} />
            <Route path={appRoute.AccountingBilling} element={<AccountingBilling />} />
            <Route path={appRoute.AccountingBillingMultiline} element={<AccountingMultilineBilling />} />
            <Route path={appRoute.AccountingBillPay} element={<AccountingBillPay />} />
            <Route path={appRoute.AccountingJournalEntry} element={<AccountingJournalEntry />} />
            <Route path={appRoute.AccountingBankDeposits} element={<BankDepositModal />} />
            <Route path={appRoute.SettingsAdminUserRoles} element={<SettingsAdminUserRoles />} />
            <Route path={appRoute.SettingsAdmingGlobalSettings} element={<GlobalSettings />} />
            <Route path={appRoute.SettingsAdminIPAddresses} element={<SettingsAdminIPAddresses />} />
            <Route path={appRoute.SettingsAdminVendorTypes} element={<SettingsVendors />} />
            <Route path={appRoute.SettingsAdminRFC} element={<SettingsAdminRFC />} />
            <Route path={appRoute.SettingsAdminDealers} element={<SettingsAdminDealers />} />
            <Route path={appRoute.DealerAddEdit} element={<AddEditDealer />} />
            <Route path={appRoute.SettingsInventoryExpenses} element={<SettingsExpenses />} />
            <Route path={appRoute.SettingsInventoryPurchaseTypes} element={<SettingsInventoryPurchaseTypes />} />
            <Route path={appRoute.SettingsInventoryCarMakes} element={<SettingsInventoryMakes />} />
            <Route path={appRoute.SettingsInventoryDrivetrains} element={<SettingsInventoryDrivetrains />} />
            <Route path={appRoute.SettingsLeadsLeadSources} element={<SettingsLeadsAdvertisements />} />
            <Route path={appRoute.SettingsLeadsCreditAppStatuses} element={<SettingsLeadsCreditAppStatuses />} />
            <Route path={appRoute.SettingsLeadsStipulations} element={<SettingsLeadsStipulations />} />
            <Route path={appRoute.SettingsLeadsInterestLevels} element={<SettingsLeadsInterestLevels />} />
            <Route path={appRoute.SettingsLeadsCreditAppExpenses} element={<SettingsLeadsApplicantExpenses />} />
            <Route path={appRoute.SettingsLeadsReferenceRelationships} element={<SettingsLeadsReferenceRelationships />} />
            <Route path={appRoute.SettingsInventoryFuelTypes} element={<SettingsInventoryFuelTypes />} />
            <Route path={appRoute.SettingsInventoryTitleTypes} element={<SettingsInventoryTitleTypes />} />
            <Route path={appRoute.SettingsInventoryConditions} element={<SettingsInventoryConditions />} />
            <Route path={appRoute.SettingsInventoryBodyTypes} element={<SettingsInventoryBodyTypes />} />
            <Route path={appRoute.SettingsInventoryStatus} element={<SettingsInventoryStatuses />} />
            <Route path={appRoute.SettingsDealsDealStatuses} element={<SettingsDealsDealStatuses />} />
            <Route path={appRoute.SettingsPaymentCheckTypes} element={<SettingsPaymentCheckTypes />} />
            <Route path={appRoute.SettingsPaymentPaymentForms} element={<SettingsPaymentPaymentsForm />} />
            <Route path={appRoute.SettingsPaymentPaymentMethods} element={<SettingsPaymentPaymentMethods />} />
            <Route path={appRoute.SettingsPaymentPaymentSideNote} element={<SettingsPaymentSideNote />} />
            <Route path={appRoute.SettingsPaymentCreditCardTypes} element={<SettingsPaymentCreditCardTypes />} />
            <Route path={appRoute.SettingsCollectionsRepoTypes} element={<SettingsCollectionsRepoTypes />} />
            <Route path={appRoute.SettingsInventoryPackFees} element={<SettingsInventoryPackFees />} />
            <Route path={appRoute.SettingsStateSettings} element={<SettingsStateSettings />} />
            <Route path={appRoute.SettingsCommunicationsCallOutcome} element={<CommunicationCallOutcome />} />
            <Route path={appRoute.SettingsStateCityTaxes} element={<SettingsStateCityTaxes />} />
            <Route path={appRoute.SettingsStateCountyTaxes} element={<SettingsStateCountyTaxes />} />
            <Route path={appRoute.SettingsAdminAddEditUserRoles} element={<AddEditAdminUserRoles />} />
            <Route path={appRoute.SettingsAdminLots} element={<SettingsAdminLots />} />
            <Route path={appRoute.SettingsDealsDealerReserve} element={<SettingsDealDealerReserve />} />
            <Route path={appRoute.SettingsDealsInsurance} element={<SettingsDealsInsurance />} />
            <Route path={appRoute.SettingsDealsInsuranceAddEdit} element={<AddEditInsurance />} />
            <Route path={appRoute.SettingsFormsList} element={<SettingsFormsList />} />
            <Route path={appRoute.SettingsFormsMapping} element={<SettingsFormsMapping />} />
            <Route path={appRoute.SettingsFormsPacksList} element={<SettingsFormsPacksList />} />
            <Route path={appRoute.SettingsFormsFormList} element={<FormList />} />

            <Route path={appRoute.CollectionsMyWorklist} element={<CollectionsMyWorklist />} />
            <Route path={appRoute.CollectionsCreateWorklist} element={<CollectionsCreateWorklist />} />
            <Route path={appRoute.CollectionsLogWorklist} element={<CollectionsLoginWorklist />} />

            <Route path={appRoute.Insights} element={<Insights />} />
            <Route path={appRoute.InsightsInventoryAging} element={<InventoryAging />} />
            <Route path={appRoute.InsightsAccountsPayableAging} element={<AccountsPayableAging />} />
            <Route path={appRoute.InsightsAccountsSubsidiaryReconciliation} element={<AccountsSubsidiaryReconciliation />} />
            <Route path={appRoute.InsightsAccountsChargeOffs} element={<AccountsChargeOffs />} />
            <Route path={appRoute.InsightsSalesTax} element={<SalesTax />} />
            <Route path={appRoute.InsightsSales} element={<Sales />} />
            <Route path={appRoute.InsightsTradeLiens} element={<TradeLiens />} />
            <Route path={appRoute.InsightsReceivables} element={<Receivables />} />
            <Route path={appRoute.InsightsReceivablesAging} element={<AccountsReceivableAging />} />
            <Route path={appRoute.InsightsReceivablesRecency} element={<AccountsReceivableRecency />} />
            <Route path={appRoute.InsightsSalesCommissions} element={<SalesCommissions />} />
            <Route path={appRoute.InsightsPayments} element={<Payments />} />
            <Route path={appRoute.Dashboard} element={<Dashboard />} />
            <Route path={appRoute.Profile} element={<Profile />} />
            <Route path={appRoute.UserList} element={<UserList />} />
            <Route path={appRoute.VendorsList} element={<VendorList />} />
            <Route path={appRoute.VendorAddEdit} element={<AddEditVendor />} />
            <Route path={appRoute.LendersList} element={<LendersList />} />
            <Route path={appRoute.LenderAddEdit} element={<AddEditLender />} />
            <Route path={appRoute.UserAddEdit} element={<AddEditUser />} />
            <Route path={appRoute.UsersContacts} element={<ContactList />} />
          </Route>
          <Route element={<ProtectedRoute allowed={isAuth && !isSession} redirectTo={appRoute.Dashboard} />}>
            <Route path={appRoute.SelectSession} element={<SelectSession />} />
          </Route>
        </Route>
        <Route element={<ProtectedRoute allowed={!isAuth || authInProgress} redirectTo={appRoute.Dashboard} />}>
          <Route path={appRoute.SignIn} element={<SignIn />} />
          <Route path={appRoute.SignUp} element={<SignUp />} />
          <Route path={appRoute.ResetPassword} element={<ResetPassword />} />
          <Route path={appRoute.ChangePassword} element={<ChangePassword />} />
        </Route>
        <Route path='*' element={<p>There's nothing here: 404!</p>} />
      </Route>,
    ),
  )

  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: 'light', // 'light' | 'dark'
        themeDirection: 'ltr', //  'rtl' | 'ltr'
        themeContrast: 'default', // 'default' | 'bold'
        themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
        themeColorPresets: 'blue', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        themeStretch: false,
      }}
    >
      <ThemeProvider>
        <RouterProvider router={router} />
        <SettingsDrawer />
      </ThemeProvider>
    </SettingsProvider>
  )
}

export default App
