import React, { useEffect, useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'
import { CustomCircular } from '../../../ui'
import { Form } from 'react-final-form'
import { Header } from './components'
import { withPermissionsDropdown } from '../../../hoc'
import { Applicant, Employment, Income } from './tabs'
import { Box, Card, Container, Tab, Tabs, generateUtilityClasses } from '@mui/material'
import validateForm from '../../../utils/validateForm'
import { addLeadsValidation } from '../../../validation'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../constants'
import { useAddContactFullMutation, useEditContactFullMutation, useLazyGetContactCreditQuery } from '../../../services'
import { UspContactFullAddEdit, UspGetDataContact } from '../../../models'
import { transformToFormValues, transformToRequestValues } from './transform'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { setCreditAppID } from '../../../redux/slices/creditAppSlice'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Label from '../../../components/label'
import Iconify from '../../../components/iconify'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'fieldsWrap', 'formItem', 'formItemFullHeight', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formItemFullHeight}`]: {
    minHeight: '712px',
    padding: theme.spacing(2, 3, 1, 3),
    borderRadius: 0,
  },
  [`& .${classes.formItem}`]: {
    minHeight: '340px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  initialTab?: 'applicant' | 'employment' | 'income'
}

export const LeadsAddEditApplicant = ({ initialTab }: Props) => {
  const [initFormValues, setInitFormValues] = useState<any>({
    ApplicantPhoneType: 'Cell',
    ApplicantSecondaryPhoneType: 'Home',
    EmploymentCurrentPhoneType: 'Biz',
    EmploymentPreviousPhoneType: 'Biz',
    HistoryHasHadBankruptcy: false,
    ApplicantAccountType: 'individual',
    HistoryHasHadRepossession: false,
    EmploymentPreviousIsSalaried: false,
    EmploymentCurrentIsSalaried: false,
  })
  const [tabValue, setTabValue] = React.useState('buyer')
  const [errorsInTabs, setErrorsInTabs] = React.useState<{ applicant: boolean; employment: boolean; income: boolean }>({
    applicant: false,
    employment: false,
    income: false,
  })
  const [formChanged, setFormChanged] = useState(false)
  const [fieldsDisabled, setDisableFields] = useState(false)

  const { leadID, contactType, contactID, dealsAdd } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { state } = useLocation()
  // const { state } = useParams()

  const userAccess = useSelector(getUserAccessRights)
  const disableFields = !userAccess?.CanEditCreditApp && leadID !== 'add'

  const handleTabChange = (event: React.SyntheticEvent, newValue: 'applicant' | 'employment' | 'income') => {
    setTabValue(newValue)
  }
  const [addContactFull, { data: addContactFullData, isSuccess: addContactFullIsSuccess, isLoading: addContactFullIsLoading }] =
    useAddContactFullMutation()
  const [editContactFull, { isSuccess: editContactFullIsSuccess, isLoading: editContactFullIsLoading }] = useEditContactFullMutation()
  const [getContact, { data: searchContactData, isSuccess: getContactIsSuccess, isLoading: getContactIsLoading }] = useLazyGetContactCreditQuery()
  const handleSubmit = (values: any) => {
    setFormChanged(false)
    if (contactID === 'add') {
      addContactFull({ uspContactFullAddEdit: transformToRequestValues(values, leadID, contactType) as UspContactFullAddEdit })
    } else {
      editContactFull({
        contactId: contactID as string,
        uspContactFullAddEdit: transformToRequestValues(values, leadID, contactType) as UspContactFullAddEdit,
      })
    }
  }
  useEffect(() => {
    if (contactID !== 'add') {
      getContact({ contactId: contactID as string, creditAppId: leadID as string })
    }
  }, [contactID])

  useEffect(() => {
    if (getContactIsSuccess) {
      setInitFormValues(transformToFormValues(searchContactData as unknown as UspGetDataContact))
    }
  }, [getContactIsSuccess])

  useEffect(() => {
    if (addContactFullIsSuccess) {
      dispatch(enqueueNotification({ message: 'Added Successfully' }))
      const CreditAppID = addContactFullData?.FullResponse?.CreditAppID
      dispatch(setCreditAppID(CreditAppID))
      if (CreditAppID) {
        if (state?.redirectedFrom === 'DealsSelectCreditApp' && state?.dealID) {
          navigate(appRoute.DealsAddEdit?.replace(':dealID', state?.dealID?.toString() as string), {
            state: { redirectedFrom: 'LeadsSelectCreditApp' },
          })
        } else if (state?.redirectedFrom === 'DealsSelectCreditAppLease' && state?.dealID) {
          navigate(appRoute.DealsAddEditLease?.replace(':dealID', state?.dealID?.toString() as string), {
            state: { redirectedFrom: 'LeadsSelectCreditApp' },
          })
        } else {
          navigate(appRoute.LeadsTask.replace(':leadID', CreditAppID as string))
        }
      } else {
        navigate(appRoute.LeadsList)
      }
    }
  }, [addContactFullIsSuccess])

  useEffect(() => {
    if (editContactFullIsSuccess) {
      getContact({ contactId: contactID as string, creditAppId: leadID as string })
      dispatch(enqueueNotification({ message: 'Updated Successfully' }))
      navigate(appRoute.LeadsTask.replace(':leadID', leadID as string))
    }
  }, [editContactFullIsSuccess])

  const isLoading = useMemo(
    () => addContactFullIsLoading || getContactIsLoading || editContactFullIsLoading,
    [addContactFullIsLoading, getContactIsLoading, editContactFullIsLoading],
  )

  useEffect(() => {
    if (!userAccess.CanAddCreditApp && leadID === 'add') {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  const TABS = [
    {
      value: 'buyer',
      label: 'Buyer',
      icon: <Iconify icon='solar:user-id-bold' width={24} />,
    },
    {
      value: 'employment',
      label: 'Employment',
      icon: <Iconify icon='solar:bell-bing-bold' width={24} />,
    },
    {
      value: 'income',
      label: 'Income/History',
      icon: <VpnKeyIcon />,
    },
  ]
  return (
    <Container>
      <Grid container>
        <Form
          onSubmit={handleSubmit}
          mutators={{
            setValue: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          initialValues={initFormValues}
          validate={validateForm(addLeadsValidation)}
        >
          {({
            handleSubmit,
            form: {
              mutators: { setValue },
            }, // pass custom mutator here
          }: any) => (
            <form
              onSubmit={handleSubmit}
              onChange={() => {
                setFormChanged(true)
              }}
            >
              <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
                {({ isActive, onConfirm, onCancel }) => (
                  <ConfirmDialog
                    open={!!isActive}
                    onOk={onConfirm}
                    handleClose={onCancel}
                    notification={'Warning: Your changes haven’t been saved.'}
                  />
                )}
              </ReactRouterPrompt>

              {/* <Header
                  handleTabChange={handleTabChange}
                  tabValue={tabValue}
                  errorsInTabs={errorsInTabs}
                  headerData={searchContactData as unknown as UspGetDataContact}
                /> */}
              <Box display={'flex'}>
                <CustomBreadcrumbs
                  heading='Lead'
                  links={[
                    { name: 'List', href: '/leads' },
                    {
                      name:
                        contactID == 'add'
                          ? 'New'
                          : `${(searchContactData as unknown as UspGetDataContact)?.DisplayName} | Cell: ${
                              (searchContactData as unknown as UspGetDataContact)?.ContactPhoneData[0]?.PhoneNumber
                            } | ${
                              (searchContactData as unknown as UspGetDataContact)?.Email
                                ? (searchContactData as unknown as UspGetDataContact)?.Email + ' |'
                                : ''
                            } `,
                    },
                  ]}
                  sx={{
                    mb: { xs: 3, md: 5 },
                  }}
                />
                {/* <Label color={creditAppData?.LeadStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
                    {creditAppData?.LeadStatus}
                  </Label> */}
              </Box>
              <Grid item xs={12}>
                <Tabs
                  sx={{
                    mb: { xs: 3, md: 5 },
                  }}
                  value={tabValue}
                  onChange={(e, newValue) => {
                    handleTabChange(e, newValue)
                  }}
                >
                  {TABS.map((tab) => (
                    <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
                  ))}
                </Tabs>
              </Grid>

              <Grid item xs={12} md={10}>
                <Box sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
                  <Card>
                    {tabValue === 'buyer' && (
                      <Applicant
                        contactID={contactID}
                        setValue={setValue}
                        linked={fieldsDisabled}
                        setInitFormValues={setInitFormValues}
                        disableFields={disableFields}
                        setDisableFields={setDisableFields}
                        setErrorsInTabs={setErrorsInTabs}
                        onDropdownFormChange={() => setFormChanged(true)}
                      />
                    )}
                  </Card>
                  {tabValue === 'employment' && (
                    <Employment disableFields={disableFields} setErrorsInTabs={setErrorsInTabs} onDropdownFormChange={() => setFormChanged(true)} />
                  )}
                  {tabValue === 'income' && (
                    <Income disableFields={disableFields} setErrorsInTabs={setErrorsInTabs} onDropdownFormChange={() => setFormChanged(true)} />
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      position: 'sticky',
                      bottom: 16,
                      right: 20,
                      p: 3,
                      width: 'fit-content',
                    }}
                  >
                    <Button variant='contained' color='error' onClick={() => navigate(-1)}>
                      Cancel
                    </Button>
                    <Button disabled={!formChanged} type='submit' variant='contained'>
                      Save & Close
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </form>
          )}
        </Form>
        <CustomCircular open={isLoading} />
      </Grid>
    </Container>
  )
}

export default withPermissionsDropdown(LeadsAddEditApplicant)
