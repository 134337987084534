import React, { useEffect, useMemo, Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { Grid, IconButton, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import FeePopover from '../FeePopover'
import ProductPopover from '../ProductPopover'
import { CustomCircular, DateField, TextField } from '../../../../../../../ui'
import AmountField from '../../../../../../../ui/AmountField'
import { getStates } from '../../../../../../../redux/slices/userDropdownSlice'
import { useSelector } from 'react-redux'
import { DisplayType } from '../../DealStructure'
import EditIcon from '@mui/icons-material/Edit'
import { useLazyGetListFeeQuery, useLazyGetListProductQuery } from '../../../../../../../services'
import { UspGetDataDealEntryResult, UspGetDataStateDealSettingsResult } from '../../../../../../../models'
import { useParams } from 'react-router-dom'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import { convertDateToString } from '../../../../../../../utils/dateUtils'
import Iconify from '../../../../../../../components/iconify'
import removeMask from '../../../../../../../utils/removeMask'
import { round } from 'lodash'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  initialValues: any
  dealData: UspGetDataDealEntryResult | undefined
  getStateDealSettings: (arg: { state: string }) => void
  stateDealSettingsData?: UspGetDataStateDealSettingsResult | null
  feesForm: any[]
  setDealType: Dispatch<SetStateAction<string | undefined | null>>
  display: DisplayType
  productsForm: any[]
  setDealDateValue: Dispatch<SetStateAction<string | null>>
  setDisableSalesTaxFields: Dispatch<SetStateAction<boolean>>
  disableFields: boolean
}

export const StructureForm = ({
  getStateDealSettings,
  setDisableSalesTaxFields,
  stateDealSettingsData,
  feesForm,
  setDealType,
  display,
  productsForm,
  setDealDateValue,
  disableFields,
}: Props) => {
  const [getListFee, { data: listFeeData, isLoading: listFeeIsLoading }] = useLazyGetListFeeQuery()
  const [getListProduct, { data: listProductData, isLoading: listProductIsLoading }] = useLazyGetListProductQuery()
  const [anchorElFee, setAnchorElFee] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElProducts, setAnchorElProducts] = React.useState<HTMLButtonElement | null>(null)
  const { values } = useFormState()
  const { change } = useForm()
  const states = useSelector(getStates)
  const { dealID, leadID } = useParams()

  useEffect(() => {
    if (values?.DealState) {
      getStateDealSettings({ state: values?.DealState })
    }
  }, [values?.DealState])

  useEffect(() => {
    setDealType(values?.DealType)
  }, [values?.DealType])

  useEffect(() => {
    if (setDealDateValue) {
      setDealDateValue(convertDateToString(values.DealDate))
    }
  }, [values.DealDate])

  const handleClickFee = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFee(event?.currentTarget)
  }

  const handleClickProducts = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElProducts(event?.currentTarget)
  }

  useEffect(() => {
    if (leadID === 'add') {
      getListFee({})
      getListProduct({})
    }
  }, [])

  const handleChangeValues = () => {
    const defaultCityCountyData = stateDealSettingsData?.StateCityCountyData?.[0]
    if (dealID === 'add' && stateDealSettingsData?.IsDestinationTax && values?.DealState) {
      setDisableSalesTaxFields(false)
    } else if (!stateDealSettingsData?.IsDestinationTax && values?.DealState) {
      setDisableSalesTaxFields(true)
    }
    if (defaultCityCountyData) {
      const { ZipCode, City, County, State, CityTaxRate, CountyTaxRate, StateTaxRate } = defaultCityCountyData
      change('DealPostalCode', ZipCode)
      change('TaxCity', City)
      change('TaxCounty', County)
      change('TaxState', State)
      change('CityTaxRate', CityTaxRate?.toString())
      change('CountyTaxRate', CountyTaxRate?.toString())
      change('StateTaxRate', StateTaxRate?.toString())
    }
  }

  const isLoading = useMemo(() => listFeeIsLoading || listProductIsLoading, [listFeeIsLoading, listProductIsLoading])

  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid container className={classes.row}>
          <Typography variant='subtitle1' gutterBottom>
            Lease Structure
          </Typography>
        </Grid>
        <Grid className={classes.row}>
          <DropdownFieldSearch
            disabled={disableFields}
            onChange={handleChangeValues}
            name='DealState'
            label='Deal State'
            options={states}
            optionKey='State'
            optionValue='StateName'
          />
        </Grid>
        <Grid className={classes.row}>
          <DateField disabled={disableFields} name='DealDate' label='Lease Date' fullWidth disableFuture />
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              let compute = round((Number(removeMask(e.target.value)) * Number(removeMask(values.Residual))) / 100, 2)
              change('ResidualValue', compute.toString())
            }}
            variant='standard'
            disabled={disableFields}
            name='SalesPrice'
            label='Lease Price'
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <TextField
            variant='outlined'
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              let compute = round((Number(removeMask(values.SalesPrice)) * Number(e.target.value)) / 100, 2)
              change('ResidualValue', compute.toString())
            }}
            disabled={disableFields}
            name='Residual'
            label='Residual %'
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            variant='standard'
            disabled={disableFields}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              if (Number(removeMask(values.SalesPrice)) > 0) {
                let compute = round((Number(removeMask(e.target.value)) / Number(removeMask(values.SalesPrice))) * 100, 2)
                change('Residual', compute.toString())
              }
            }}
            name='ResidualValue'
            label='Residual Value'
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled={disableFields} name='AcquisitionFees' label='Acquisition Fees' fullWidth />
        </Grid>
        {display?.fieldFees && (
          <Grid className={classes.row}>
            <AmountField
              variant='standard'
              disabled={disableFields}
              name='Fees'
              label='Fees (Capitalized)'
              fullWidth
              readonly
              endAdornment={
                <IconButton disabled={disableFields} color='primary' onClick={handleClickFee}>
                  <Iconify icon='solar:pen-bold' />
                </IconButton>
              }
            />
          </Grid>
        )}
        {display?.fieldProducts && (
          <Grid className={classes.row}>
            <AmountField
              variant='standard'
              disabled={disableFields}
              name='Products'
              label='F&I Products' // TODO REMOVE EDITING POSSIBILITY FOR PRODUCTS, FEE, PAYMENTS
              fullWidth
              readonly
              endAdornment={
                <IconButton disabled={disableFields} color='primary' onClick={handleClickProducts}>
                  <Iconify icon='solar:pen-bold' />
                </IconButton>
              }
            />
          </Grid>
        )}
      </StyledGrid>
      <FeePopover
        anchorEl={anchorElFee}
        setAnchorEl={setAnchorElFee}
        listFeeData={listFeeData}
        feesForm={feesForm}
        stateDealSettingsData={stateDealSettingsData}
      />
      <ProductPopover
        anchorEl={anchorElProducts}
        setAnchorEl={setAnchorElProducts}
        listProductData={listProductData}
        productsForm={productsForm}
        stateDealSettingsData={stateDealSettingsData}
      />
      <CustomCircular open={isLoading} />
    </>
  )
}

export default StructureForm
