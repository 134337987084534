import React from 'react'
import { Box, CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', [
  'root',
  'title',
  'titleContainer',
  'leftItemTypography',
  'rightItemTypography',
  'rightItemContainer',
  'leftItemContainer',
])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.titleContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  [`& .${classes.title}`]: {
    textAlign: 'center',
  },
  [`& .${classes.leftItemContainer}`]: {
    width: '45%',
  },
  [`& .${classes.rightItemContainer}`]: {
    width: '45%',
    marginLeft: '15px',
  },

  [`& .${classes.leftItemTypography}`]: {
    textAlign: 'right',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  [`& .${classes.rightItemTypography}`]: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
}))

interface Props {
  salesDiscount: string | number
  spreadAmount: string | number
}

const FinancingCard = ({ salesDiscount, spreadAmount }: Props) => {
  return (
    <>
      <Grid container>
        <CardHeader title='Financing' />
        <Grid item xs={12}>
          <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Sales Discount:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {formatNumber(salesDiscount)}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Spread Amount:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {formatNumber(spreadAmount)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default FinancingCard
