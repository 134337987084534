import React, { useMemo } from 'react'
import { styled } from '@mui/system'
import { Dialog, Divider, generateUtilityClasses } from '@mui/material'
import { CustomCircular } from '../../../../../../../ui'
import Popover from '@mui/material/Popover'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { GetDealProfitApiResponse } from '../../../../../../../models'
import Typography from '@mui/material/Typography'
import _uniqueId from 'lodash/uniqueId'
import { formatNumber, formatObjectKey } from '../../../../../../../utils/general'

const classesBox = generateUtilityClasses('Container', ['root', 'title'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '287px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.title}`]: {
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (value: HTMLButtonElement | null) => void
  data: GetDealProfitApiResponse
  isLoading: boolean
}
export const RecapPopover = ({ anchorEl, setAnchorEl, data, isLoading }: Props) => {
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isDataLoading = useMemo(() => isLoading, [isLoading])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      // anchorOrigin={{
      //   vertical: 'center',
      //   horizontal: 'right',
      // }}
    >
      <StyledBox className={classesBox.root}>
        <Typography variant='h3' className={classesBox.title}>
          Recap
        </Typography>
        {isLoading ? (
          <CustomCircular open={isDataLoading} />
        ) : (
          <>
            {data?.map((item: any, index: any) => (
              <React.Fragment key={index}>
                {Object?.entries(item)?.map(([key, value]: [string, any]) => (
                  <React.Fragment key={_uniqueId(key)}>
                    {Array?.isArray(value)
                      ? value
                          ?.filter((subItem: any) => parseFloat(subItem?.Amount) !== 0)
                          ?.map((subItem: any) => (
                            <React.Fragment key={_uniqueId(subItem.Label)}>
                              <Grid container justifyContent='space-between'>
                                <Typography color='inherit'>{subItem?.Label}</Typography>
                                <Typography>{subItem?.Amount}</Typography>
                              </Grid>
                              <Divider light />
                            </React.Fragment>
                          ))
                      : value !== 0 && (
                          <>
                            <Grid container justifyContent='space-between'>
                              <Typography color='inherit'>{formatObjectKey(key)}</Typography>
                              <Typography>{formatNumber(value)}</Typography>
                            </Grid>
                            <Divider light />
                          </>
                        )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ))}
          </>
        )}
      </StyledBox>
    </Dialog>
  )
}

export default RecapPopover
