import { generateUtilityClasses, Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { Header } from './components/Header'
import TasksList from './components/TasksList/TasksList'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'taskItemContainer'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.taskItemContainer}`]: {
    width: '250px',
  },
}))

const AccountingTasks = () => {
  return (
    <StyledGrid>
      <Header />
      <Grid container className={classes.pageBody}>
        <Grid item>
          <Paper className={classes.taskItemContainer}>
            <TasksList />
          </Paper>
        </Grid>
      </Grid>
    </StyledGrid>
  )
}

export default AccountingTasks
