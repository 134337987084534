import { Box, Button, Link, Link as MLink, Stack, TextField, Typography, useTheme } from '@mui/material'
import logo from '../../assets/logo.svg'
import mail from '../../assets/mail.svg'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import { authForgotPassword } from '../../services/auth'
import { RHFTextField } from '../../components/hook-form'
import { LoadingButton } from '@mui/lab'
import Iconify from '../../components/iconify'
import { RouterLink } from '../../routes/components'
import { appRoute } from '../../constants'
import PasswordIcon from '../../assets/icons/password-icon'
import FormProvider from '../../components/hook-form/form-provider'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useRouter } from '../../routes/hooks'
import { AuthTemplate } from '../../components/AuthTemplate'
import signInBg from '../../assets/signIn-bg.png'

export const ResetPassword = () => {
  const [sent, setSent] = useState(false)
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  })
  const router = useRouter()
  const theme = useTheme()

  const defaultValues = {
    email: '',
  }

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  })

  const {
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      await runAsync({ email: data.email })
      const searchParams = new URLSearchParams({
        email: data.email,
      }).toString()

      setSent(true)
      // const href = `${paths.auth.amplify.newPassword}?${searchParams}`
      // router.push(href)
    } catch (error) {
      console.error(error)
    }
  })

  const { loading, runAsync } = useRequest(authForgotPassword, {
    manual: true,
  })

  const renderForm = (
    <Stack spacing={3} alignItems='center'>
      <RHFTextField name='email' label='Email address' />

      <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
        Send Request
      </LoadingButton>

      <Link
        href={appRoute.SignIn}
        color='inherit'
        variant='subtitle2'
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon='eva:arrow-ios-back-fill' width={16} />
        Return to sign in
      </Link>
    </Stack>
  )

  const renderSent = (
    <>
      <Box
        height='240px'
        sx={{
          background: `url(${mail}) center center no-repeat, linear-gradient(90.57deg, rgba(88, 109, 255, 0.05) 0%, rgba(185, 63, 255, 0.05) 100%)`,
          borderRadius: '16px',
        }}
      ></Box>
      <Typography
        sx={{
          marginTop: 6,
          fontWeight: 500,
          fontSize: '40px',
        }}
        textAlign='center'
      >
        Check Your Inbox
      </Typography>
      <Typography sx={{ marginTop: 3, fontSize: '16px' }} textAlign='center'>
        We sent you the link to reset your password at your email {getValues().email}
      </Typography>
      <Box sx={{ textAlign: 'center' }}>
        <Button type='submit' variant='text' sx={{ marginTop: 5, textTransform: 'none' }} disableRipple disableElevation disableTouchRipple>
          Resend Email
        </Button>
      </Box>
    </>
  )

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ mt: 3, mb: 5 }}>
        <Typography variant='h3'>Forgot your password?</Typography>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Please enter the email address associated with your account and We will email you a link to reset your password.
        </Typography>
      </Stack>
    </>
  )

  return (
    <AuthTemplate
      backgroundImg={signInBg}
      // topRight={<Box sx={{ fontSize: '14px', color: theme.palette.text.secondary }}>Don’t have an account? Contact your administrator.</Box>}
    >
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {sent ? (
          renderSent
        ) : (
          <>
            {renderHead}

            {renderForm}
          </>
        )}
      </FormProvider>
    </AuthTemplate>
  )
}
