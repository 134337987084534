import { Button, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { Dispatch, SetStateAction } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { TextField } from '../../../../../../ui'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { getStates } from '../../../../../../redux/slices/userDropdownSlice'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const classes = generateUtilityClasses('form', ['root', 'row'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    minWidth: '300px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  handleUploadPDF: any // for now
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SettingsModal = ({ open, handleClose, handleUploadPDF, handleFileChange }: Props) => {
  const { values, errors } = useFormState()
  const states = useSelector(getStates)

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledBox className={classes.root}>
        <Grid>
          <Typography variant='h3'>Settings</Typography>
        </Grid>

        <Grid className={classes.row}>
          <DropdownFieldSearch
            options={[
              { key: 'Deal', value: 'Deal' },
              { key: 'Inventory', value: 'Inventory' },
              { key: 'Lead', value: 'Lead' },
            ]}
            name='DataSource'
            label='Data Source'
            fullWidth
          />
        </Grid>

        <Grid className={classes.row}>
          <TextField name='Name' label='Name' fullWidth onClick={() => document.getElementById('hiddenFileInput')?.click()} />
        </Grid>

        <Grid className={classes.row}>
          <TextField name='Version' label='Version' fullWidth />
        </Grid>

        <Grid className={classes.row}>
          <DropdownFieldSearch required name='FormState' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>

        <Grid item sx={{ gap: '10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '40px' }}>
          <Button
            disabled={errors?.FormState || errors?.Version}
            onClick={() => handleUploadPDF(values)}
            variant='contained'
            color='primary'
            type='button'
            endIcon={<ArrowForwardIosIcon />}
          >
            Next
          </Button>
        </Grid>
      </StyledBox>
    </Modal>
  )
}

export default SettingsModal
