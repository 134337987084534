import React from 'react'
import { styled } from '@mui/system'
import { useParams } from 'react-router-dom'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Container, Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import Typography from '@mui/material/Typography'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { applyPhoneMask } from '../../../../../utils/general'
import { UspGetDataContact } from '../../../../../models'
const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'mainDataWrap', 'secondaryData', 'iconSeparator', 'tabLinkWrap', 'tabLink'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    paddingRight: theme.spacing(2),
    minWidth: 200,
  },
  [`& .${classes.mainDataWrap}`]: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  [`& .${classes.secondaryData}`]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.iconSeparator}`]: {
    margin: theme.spacing(0, 1, 0, 1),
    fill: theme.palette.primary.main,
  },
  [`& .${classes.tabLinkWrap}`]: {
    display: 'flex',
    height: '60px',
  },
  [`& .${classes.tabLink}`]: {
    textDecoration: 'none',
    height: '60px',
    minHeight: '60px',
    padding: theme.spacing(0, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'none',
    fontSize: '1em',
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.background.hoverBtn,
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.background.active,
    },
  },
}))

interface Props {
  handleTabChange: (event: React.SyntheticEvent, newValue: 'applicant' | 'employment' | 'income') => void
  tabValue: 'applicant' | 'employment' | 'income'
  errorsInTabs: { applicant: boolean; employment: boolean; income: boolean }
  headerData: UspGetDataContact
}

export const Header = ({ tabValue, errorsInTabs, handleTabChange, headerData }: Props) => {
  const { leadID } = useParams()
  return (
    <StyledContainer className={classes.root}>
      <Grid container>
        <Grid className={classes.titleWrap}>
          {leadID !== 'add' && (
            <>
              <Grid>
                <Typography variant='h3' className={classes.mainDataWrap}>
                  {headerData?.DisplayName}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='h6' className={classes.secondaryData}>
                  {/* TODO: change phone number depending if its used or not (check response data) */}
                  {applyPhoneMask(headerData?.ContactPhoneData[0]?.PhoneNumber)}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
        <Grid className={classes.tabLinkWrap}>
          <Grid container>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab
                className={classes.tabLink}
                icon={errorsInTabs?.applicant && tabValue !== 'applicant' ? <ErrorOutlineIcon fontSize='small' color='error' /> : undefined}
                iconPosition='end'
                label='Applicant'
                value='applicant'
              />
              <Tab
                className={classes.tabLink}
                icon={errorsInTabs?.employment && tabValue !== 'employment' ? <ErrorOutlineIcon fontSize='small' color='error' /> : undefined}
                iconPosition='end'
                label='Employment'
                value='employment'
              />
              <Tab
                className={classes.tabLink}
                icon={errorsInTabs?.income && tabValue !== 'income' ? <ErrorOutlineIcon fontSize='small' color='error' /> : undefined}
                iconPosition='end'
                label='Income/History'
                value='income'
              />
            </Tabs>
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default Header
