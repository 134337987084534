import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'

interface Props {
  onDropdownFormChange: () => void
  setCurrentVin: Dispatch<SetStateAction<string>>
  disableFields: boolean
}

export const AttributesForm = ({ onDropdownFormChange, setCurrentVin, disableFields }: Props) => {
  const carOdometerType = useSelector((state) => selectDropdownByName(state, 'CarOdometerType'))
  const carBodyType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField disabled={disableFields} name='stockNumber' label='Stock Number' fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={6}>
        <TextField
          disabled={disableFields}
          onChange={(e) => {
            setCurrentVin(e.target.value)
          }}
          name='vIN'
          label='VIN'
          fullWidth
          autoComplete='off'
          required
        />
      </Grid>
      <Grid item container xs={6} spacing={3}>
        <Grid item xs={3}>
          <TextField disabled={disableFields} name='modelYear' label='Year' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={9}>
          <TextField disabled={disableFields} name='make' label='Make' fullWidth autoComplete='off' required />
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <TextField disabled={disableFields} name='model' label='Model' fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled={disableFields} name='modelTrim' label='Trim' fullWidth autoComplete='off' />
      </Grid>
      {carBodyType && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='bodyTypeID'
            label='Body'
            options={carBodyType}
            optionKey='ID'
            optionValue='BodyType'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <TextField disabled={disableFields} name='mileage' label='Mileage' fullWidth autoComplete='off' />
      </Grid>
      {carOdometerType && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='odometerTypeID'
            label='Odometer Type'
            fullWidth
            options={carOdometerType?.ReturnData}
            optionKey='ID'
            optionValue='OdometerType'
            initialValue={1}
            disableClearable
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField disabled={disableFields} name='exteriorColor' label='Color' fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled={disableFields} name='interiorColor' label='Interior Color' fullWidth autoComplete='off' />
      </Grid>
    </Grid>
  )
}

export default AttributesForm
