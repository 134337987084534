import React, { useEffect } from 'react'
import { useGetUserPermissionsDropdownMutation } from '../../services'
import { useSelector, useDispatch } from 'react-redux'
import { getUserDropdown, isUserDropdownEmpty, setUserDropdown } from '../../redux/slices/userDropdownSlice'

export function withPermissionsDropdown(Component: any) {
  // GET CAR DATA AND ADD TO REDUX
  return function (props: any) {
    const isDropdownEmpty = useSelector(isUserDropdownEmpty)
    const userDropdown = useSelector(getUserDropdown)

    const dispatch = useDispatch()
    const [
      userPermissionsDropdown,
      { data: userPermissionsDropdownData, isSuccess: userPermissionsDropdownIsSuccess, isLoading: userPermissionsDropdownIsLoading },
    ] = useGetUserPermissionsDropdownMutation()

    useEffect(() => {
      if (isDropdownEmpty) {
        // check if empty
        userPermissionsDropdown({ userPermissionsDropdown: {} })
      }
    }, [])

    useEffect(() => {
      if (userPermissionsDropdownIsSuccess) {
        dispatch(setUserDropdown(userPermissionsDropdownData))
      }
    }, [userPermissionsDropdownIsSuccess])

    return (
      <Component
        userPermissionsDropdown={userPermissionsDropdown}
        userPermissionsDropdownData={userPermissionsDropdownData || userDropdown}
        userPermissionsDropdownIsSuccess={userPermissionsDropdownIsSuccess}
        userPermissionsDropdownIsLoading={userPermissionsDropdownIsLoading}
        {...props}
      />
    )
  }
}
