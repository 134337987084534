import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'
import Stack, { StackProps } from '@mui/material/Stack'
import Label, { LabelColor } from '../../../../components/label'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box } from '@mui/system'
import { Tooltip } from '@mui/material'
// ----------------------------------------------------------------------
type Props = PaperProps &
  StackProps & {
    name?: string
    stock?: string
    vin?: string
    action?: React.ReactNode
  }

export default function VehicleItem({ name, stock, vin, sx, action, ...other }: Props) {
  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-end' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack flexGrow={1} spacing={1}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2'>{name}</Typography>
        </Stack>

        {stock && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Stock #{stock}
          </Typography>
        )}
        {vin && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            VIN {vin}
          </Typography>
        )}
      </Stack>

      {action && action}
    </Stack>
  )
}
