import { styled } from '@mui/system'
import {
  generateUtilityClasses,
  Grid,
  Paper,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  CircularProgress,
  TableSortLabel,
  Box,
  Tooltip,
} from '@mui/material'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { UspDealTradeViewResult } from '../../../../../models'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { formatNumber } from '../../../../../utils/general'

interface Props {
  dealsTradeData: any
  loadingDealsTradeData: boolean
  deleteDealTrade: any
}

const classes = generateUtilityClasses('Grid', ['root', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
}))

const DealsTradeTableBlock = ({ deleteDealTrade, dealsTradeData, loadingDealsTradeData }: Props) => {
  const initialOrder = (storageGet('deals_trade_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('deals_trade_order_by') || 'StockNumber') as keyof UspDealTradeViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspDealTradeViewResult>(initialOrderBy)
  const navigate = useNavigate()
  const { dealID } = useParams()
  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspDealTradeViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('deals_trade_order', isAsc ? 'desc' : 'asc')
    storageSet('deals_trade_order_by', property)
  }

  const createSortHandler = (property: keyof UspDealTradeViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleEdit = (tradeID: number | string) => {
    if (tradeID) {
      navigate(appRoute.DealsTradeEdit.replace(':dealID', String(dealID)).replace(':tradeID', String(tradeID)))
    }
  }

  return (
    <StyledGrid className={classes.root}>
      <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: '30vh' }} className={classes.root}>
        <Table sx={{ minWidth: 650, width: '100%' }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock Number
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Vehicle' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Vehicle'}
                  direction={orderBy === 'Vehicle' ? order : 'asc'}
                  onClick={createSortHandler('Vehicle')}
                >
                  Description
                  {orderBy === 'Vehicle' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'VIN' ? order : false}>
                <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                  VIN
                  {orderBy === 'VIN' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'ACV' ? order : false}>
                <TableSortLabel active={orderBy === 'ACV'} direction={orderBy === 'ACV' ? order : 'asc'} onClick={createSortHandler('ACV')}>
                  ACV
                  {orderBy === 'ACV' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Allowance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'ACV'}
                  direction={orderBy === 'Allowance' ? order : 'asc'}
                  onClick={createSortHandler('Allowance')}
                >
                  Allowance
                  {orderBy === 'Allowance' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead}>
                <TableSortLabel
                  active={orderBy === 'LienAmount'}
                  direction={orderBy === 'LienAmount' ? order : 'asc'}
                  onClick={createSortHandler('LienAmount')}
                >
                  Lien
                  {orderBy === 'LienAmount' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead}>
                <TableSortLabel
                  active={orderBy === 'Lienholder'}
                  direction={orderBy === 'Lienholder' ? order : 'asc'}
                  onClick={createSortHandler('Lienholder')}
                >
                  Lienholder
                  {orderBy === 'Lienholder' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), dealsTradeData)?.map((item: any) => (
              <TableRow key={item?.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{item?.StockNumber}</TableCell>
                <TableCell>{item?.Vehicle}</TableCell>
                <TableCell>{item?.VIN}</TableCell>
                <TableCell>{formatNumber(item?.ACV)}</TableCell>
                <TableCell>{formatNumber(item?.Allowance)}</TableCell>
                <TableCell>{formatNumber(item?.LienAmount)}</TableCell>
                <TableCell>{item?.Lienholder}</TableCell>
                <TableCell align={'right'}>
                  <Tooltip title='Edit'>
                    <IconButton color='primary' onClick={() => handleEdit(item?.TradeID)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Delete'>
                    <IconButton
                      color='error'
                      onClick={() => {
                        deleteDealTrade({ tradeId: item?.TradeID?.toString() as string })
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {dealsTradeData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loadingDealsTradeData && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGrid>
  )
}

export default DealsTradeTableBlock
