import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField } from '../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  disableFields: boolean
}

const InterestSettingsCard = ({ disableFields }: Props) => {
  const { values } = useFormState()
  return (
    <StyledGrid className={classes.root}>
      <Grid className={classes.row}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='InterestDaysPerYear'
          label='Interest Days Per Year'
          options={[
            { key: 365, value: '365' },
            { key: 360, value: '360' },
          ]}
          optionKey='key'
          optionValue='value'
          autoComplete='off'
          fullWidth
        />
      </Grid>
      <Grid className={classes.row}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='StopAccruingInterest'
          label='Stop Accruing Interest?'
          options={[
            { id: true, values: 'Yes' },
            { id: false, values: 'No' },
          ]}
          optionKey='id'
          optionValue='values'
          autoComplete='off'
          fullWidth
        />
      </Grid>
      {values?.StopAccruingInterest && (
        <Grid className={classes.row}>
          <DateField disabled={disableFields} name='StopAccruingInterestDate' label='Stop Accruing Interest As Of Date' fullWidth />
        </Grid>
      )}
    </StyledGrid>
  )
}

export default InterestSettingsCard
