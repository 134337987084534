import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { DealerDeleteApiArg } from '../../../../models'
import { useDispatch, useSelector } from 'react-redux'
import { useDealerSearchMutation, useDealerDeleteMutation } from '../../../../services'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import DealersSearchBlock from './components/DealersSearchBlock/DealersSearchBlock'
import DealersTableBlock from './components/DealersTableBlock/DealersTableBlock'
import { appRoute } from '../../../../constants'
import { getUserAccessRights } from '../../../../redux/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { Button, Card, Container, Grid } from '@mui/material'
import Iconify from '../../../../components/iconify'

type TFormFields = {
  searchString: string
  isIncludeInactive: boolean
}

const SettingsAdminDealers = () => {
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState<TFormFields>()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<{ dealerId: string } | undefined>(undefined)
  const [searchDealer, { data: searchDealerData, isLoading: searchDealerIsLoading }] = useDealerSearchMutation()
  const [deleteDealer, { isSuccess: deleteDealerIsSuccess }] = useDealerDeleteMutation()

  const handleSubmit = (values: TFormFields) => {
    setFormValues(values)
    searchDealer({
      simpleSearch: {
        searchString: values.searchString || null,
        isIncludeInactive: values.isIncludeInactive,
      },
    })
  }

  const handleDelete = () => {
    deleteDealer(deleteConfirmDialog as DealerDeleteApiArg)
    setDeleteConfirmDialog(undefined)
  }

  useEffect(() => {
    if (deleteDealerIsSuccess) {
      dispatch(enqueueNotification({ message: 'Delete Successful' }))
      searchDealer({
        simpleSearch: {
          searchString: formValues?.searchString || null,
          isIncludeInactive: formValues?.isIncludeInactive,
        },
      })
    }
  }, [deleteDealerIsSuccess])

  useEffect(() => {
    if (!userAccess.CanAccessDealers) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Dealers'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button
            onClick={() => navigate(appRoute?.DealerAddEdit.replace(':dealerId', 'add'))}
            variant='contained'
            startIcon={<Iconify icon='mingcute:add-line' />}
          >
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ searchString: '', isIncludeInactive: false }} // require to avoid first debounce search
      >
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DealersSearchBlock submit={submit} />
                </Grid>
                <Grid item xs={12}>
                  <DealersTableBlock deleteDealer={setDeleteConfirmDialog} loading={searchDealerIsLoading} searchDealerData={searchDealerData} />
                </Grid>
              </Grid>
            </Card>
            <ConfirmDialog
              open={!!deleteConfirmDialog}
              onOk={handleDelete}
              handleClose={() => setDeleteConfirmDialog(undefined)}
              notification={'Dealer will be removed'}
            />
          </form>
        )}
      </Form>
    </Container>
  )
}

export default SettingsAdminDealers
