import { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { Box, Button, Container, Grid, Popover } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import Typography from '@mui/material/Typography'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly, useGetCreditAppQuery, useLeadReopenMutation, useMarkLeadAsLostMutation } from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import MarkAsLostModal from '../../../../../components/Pages/MarkAsLostModal'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import PullCreditModal from '../Modals/PullCreditModal'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
const classes = generateUtilityClasses('Container', ['root', 'eyeIcon', 'column'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    padding: theme.spacing(2),
  },
  [`& .${classes.column}`]: {
    width: 'auto',
  },
}))

export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { leadID } = useParams()
  const [isReopenDialogOpen, setIsReopenDialogOpen] = useState(false)

  const [isLostModalOpen, setIsLostModalOpen] = useState(false)
  const [markLeadAsLost, { isSuccess: markLeadAsLostSuccess }] = useMarkLeadAsLostMutation()
  const [leadReopen, { isSuccess: leadReopenSuccces }] = useLeadReopenMutation()
  const { CanPullCreditReport } = useSelector(getUserAccessRights)
  const { data: creditAppData } = useGetCreditAppQuery({ creditAppId: leadID as string })
  const [isPullCreditModalOpen, setIsPullCreditModalOpen] = useState(false)
  const INTEGRATION_VENDOR = '700Credit'
  const leadLostReasonDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Integrations'))
  const integrationVendor = leadLostReasonDropdown?.find(({ IntegrationVendor }) => IntegrationVendor === INTEGRATION_VENDOR)

  const bureausData = useMemo(() => (integrationVendor?.IntegrationData ? JSON.parse(integrationVendor?.IntegrationData) : []), [integrationVendor])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleSubmit = ({ reasonId }: { reasonId: number }) => {
    markLeadAsLost({
      leadId: leadID as string,
      reasonId,
    })
  }

  const handleReopen = () => {
    leadReopen({
      leadId: leadID as string,
    })
  }

  useEffect(() => {
    if (leadReopenSuccces) {
      dispatch(enqueueNotification({ message: 'Lead reopened' }))
      setIsReopenDialogOpen(false)
    }
  }, [leadReopenSuccces])

  useEffect(() => {
    if (markLeadAsLostSuccess) {
      dispatch(enqueueNotification({ message: 'Lead marked as lost' }))
      setIsLostModalOpen(false)
    }
  }, [markLeadAsLostSuccess])

  return (
    <>
      {((!creditAppData?.IsLost && !creditAppData?.IsSold) || creditAppData?.IsLost) && (
        <Button
          sx={{ position: 'absolute', right: 30 }}
          aria-describedby='add-menu-popover'
          variant='outlined'
          onClick={(e) => setAnchorEl(e.currentTarget)}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Actions
        </Button>
      )}
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
          {!creditAppData?.IsLost && !creditAppData?.IsSold && (
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                setIsLostModalOpen(true)
                handleClose()
              }}
            >
              Mark Lost
            </Button>
          )}

          {creditAppData?.IsLost && (
            <Button
              style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
              onClick={() => {
                setIsReopenDialogOpen(true)
                handleClose()
              }}
            >
              Reopen
            </Button>
          )}
        </Grid>
      </Popover>

      <MarkAsLostModal handleSubmit={handleSubmit} isLostModalOpen={isLostModalOpen} setIsLostModalOpen={setIsLostModalOpen} heading='Lead Lost' />

      <ConfirmDialog
        open={isReopenDialogOpen}
        onOk={handleReopen}
        handleClose={() => setIsReopenDialogOpen(false)}
        notification={'Lead will be reopened'}
      />
      {creditAppData && (
        <PullCreditModal
          isPullCreditModalOpen={isPullCreditModalOpen}
          setIsPullCreditModalOpen={setIsPullCreditModalOpen}
          bureausData={bureausData}
          dealData={creditAppData?.DealData}
        />
      )}
    </>
  )
}

export default Header
