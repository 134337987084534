import { useEffect, useState, useMemo, Dispatch, SetStateAction, useRef, RefObject, useCallback } from 'react'
import dayjs from 'dayjs'
import {
  TaxesObjects,
  UspCarSearchResult,
  UspCreditAppSearchResult,
  UspGetDataCar,
  UspGetDataCreditApp,
  UspGetDataDealEntryResult,
  UspGetDataStateDealSettingsResult,
} from '../../../../../models'
import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import { useNavigate, useParams } from 'react-router-dom'
import { Header, StructureForm, DownPaymentsForm, SalesTaxForm, TermsForm, ResultForm, NextButton } from './components'
import Button from '@mui/material/Button'
import { appRoute } from '../../../../../constants'
import {
  useGetCalculationForDealMutation,
  useLazyGetStateDealSettingsQuery,
  useGetTaxesMutation,
  useLazyGetCarQuery,
  useGetMaturityDateForDealMutation,
  useGetDealProfitMutation,
  useEditDealMutation,
} from '../../../../../services'
import { formatNumber, getCurrentDate, parseToNumber } from '../../../../../utils/general'
import validateForm from '../../../../../utils/validateForm'
import { addDealValidation } from '../../../../../validation'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Paper from '@mui/material/Paper'
import removeMask from '../../../../../utils/removeMask'
import { convertDateToString } from '../../../../../utils/dateUtils'
import { CustomCircular } from '../../../../../ui'
import { withPermissionsDropdown } from '../../../../../hoc'
import LoadingButton from '@mui/lab/LoadingButton'
import Container from '@mui/system/Container'
import { useSettingsContext } from '../../../../../components/settings'
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs'
import { Box, Card, CardActions, CardContent, CardHeader, IconButton, MenuItem, Stepper, Typography } from '@mui/material'
import Iconify from '../../../../../components/iconify/iconify'
import CustomPopover, { usePopover } from '../../../../../components/custom-popover'
import VehicleCard from './components/VehicleCard'
import BuyerCard from './components/BuyerCard'
import FormStepper from './components/FormStepper'
import Recap from './components/Recap'
import DealResult from '../DealResult'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { round } from 'lodash'

const PAYMENTS_PER_YEAR = {
  M: 12,
  S: 24,
  B: 26,
  W: 52,
}
export interface DisplayType {
  downPaymentsBlock: boolean
  salesTaxBlock: boolean
  termsBlock: boolean
  fieldFees: boolean
  fieldProducts: boolean
  fieldPaymentSchedule: boolean
  fieldFirstDueDate: boolean
}

interface Props {
  dealData: UspGetDataDealEntryResult | undefined
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  setSelectedCar: (car?: UspCarSearchResult) => void
  setSelectedCreditApp: (creditApp?: UspCreditAppSearchResult | UspGetDataCreditApp) => void
  carSelected?: any
  creditSelected?: UspCreditAppSearchResult | UspGetDataCreditApp
  setDealStructureFormValues: (values: any) => void
  dealStructureFormValues?: any
  setTotalPickups?: Dispatch<SetStateAction<boolean>>
  formPayments: string[]
  setFormPayments: Dispatch<SetStateAction<string[]>>
  setPaymentsForm: Dispatch<SetStateAction<string[]>>
  paymentsForm: string[]
  isDownPaymentDeleteCalled: boolean
  setIsDownPaymentDeleteCalled: Dispatch<SetStateAction<boolean>>
  setDealType: Dispatch<SetStateAction<string | undefined | null>>
  dealType: string | undefined
  disableFields: boolean
  stage: 'dealStructure' | 'dealResult'
  totalPickups: any
}

export const DealStructure = ({
  dealData,
  setCurrentStage,
  carSelected,
  creditSelected,
  setSelectedCar,
  setSelectedCreditApp,
  setDealStructureFormValues,
  setTotalPickups,
  dealStructureFormValues,
  formPayments,
  setFormPayments,
  setPaymentsForm,
  paymentsForm,
  isDownPaymentDeleteCalled,
  setIsDownPaymentDeleteCalled,
  setDealType,
  dealType,
  disableFields,
  stage,
  totalPickups,
}: Props) => {
  const { dealID } = useParams()
  let _Form: any
  const navigate = useNavigate()
  const [feesForm, setFeesForm] = useState<any[]>([])
  const [feesFormResults, setFeesFormResults] = useState<any[]>([])
  const [productsForm, setProductsForm] = useState<any[]>([])
  const [disableCalculateBtn, setDisableCalculateBtn] = useState(false)
  const [disableSalesTaxFields, setDisableSalesTaxFields] = useState(false)
  const [getStateDealSettings, { data: stateDealSettingsData, isSuccess: stateDealSettingsIsSuccess, isLoading: stateDealSettingsIsLoading }] =
    useLazyGetStateDealSettingsQuery()
  const [getCalculationForDeal, { data: calculationForDealData, isSuccess: calculationForDealIsSuccess, isLoading: calculationForDealIsLoading }] =
    useGetCalculationForDealMutation()
  const [
    getMaturityDateForDeal,
    { data: maturityDateForDealData, isSuccess: maturityDateForDealIsSuccess, isLoading: maturityDateForDealIsLoading },
  ] = useGetMaturityDateForDealMutation()
  const [getTaxes, { data: taxesData, isSuccess: taxesIsSuccess, isLoading: taxesIsLoading }] = useGetTaxesMutation()
  const [getCar, { data: carData, isSuccess: carIsSuccess }] = useLazyGetCarQuery()
  const [secondClick, setSecondClick] = useState(false)
  const buttonRef = useRef<any>(null)
  const [dealDateValue, setDealDateValue] = useState<string | null>('')
  const [display, setDisplay] = useState<DisplayType>({
    downPaymentsBlock: true,
    salesTaxBlock: true,
    termsBlock: true,
    fieldFees: true,
    fieldProducts: true,
    fieldPaymentSchedule: true,
    fieldFirstDueDate: true,
  })

  const Results = useCallback((values: any): number => {
    let rentPmt = round(
      (Number(removeMask(values.SalesPrice)) +
        Number(removeMask(values.AcquisitionFees)) +
        Number(removeMask(values.Products)) +
        Number(removeMask(values.Fees)) -
        Number(removeMask(values.CashDown)) +
        Number(removeMask(values.ResidualValue))) *
        Number(removeMask(values.leaseFactor)),
      2,
    )

    let depPmt = round(
      (Number(removeMask(values.SalesPrice)) +
        Number(removeMask(values.AcquisitionFees)) +
        Number(removeMask(values.Products)) +
        Number(removeMask(values.Fees)) -
        Number(removeMask(values.CashDown)) -
        Number(removeMask(values.ResidualValue))) /
        Number(removeMask(values.DesiredTerm)),
      2,
    )
    let amountFinanced = round(
      Number(removeMask(values.SalesPrice)) +
        Number(removeMask(values.AcquisitionFees)) +
        Number(removeMask(values.Products)) +
        Number(removeMask(values.Fees)) -
        Number(removeMask(values.CashDown)) -
        Number(removeMask(values.ResidualValue)),
      2,
    )
    let amountPaymentAmount = rentPmt + depPmt
    return round(values.DesiredTerm * amountPaymentAmount - amountFinanced, 2)
  }, [])

  const getFeeData = () => {
    // Create an array of StateFeeID from stateDealSettingsData?.StateFeeDataInJson
    const stateFeeIDs = stateDealSettingsData?.StateFeeDataInJson?.map((product) => product?.StateFeeID) || []
    const FeeData: any = {}
    dealData?.FeeData?.forEach((item) => {
      // Only include the fee if its StateFeeID exists in stateFeeIDs
      if (stateFeeIDs.includes(item.StateFeeID)) {
        FeeData[`FeeFieldValue${item.StateFeeID}`] = item.FeeAmount
      }
    })
    return FeeData
  }

  const getProductData = () => {
    // Create an array of StateProductID from stateDealSettingsData?.ProductDataInJson
    const stateProductIDs = stateDealSettingsData?.ProductDataInJson?.map((product) => product?.StateProductID) || []
    const FeeData: any = {}
    dealData?.FeeData?.forEach((item) => {
      // Only include the fee if its StateFeeID exists in stateProductIDs
      if (stateProductIDs.includes(item.StateFeeID)) {
        FeeData[`ProductFieldValue${item.StateFeeID}`] = item.FeeAmount
      }
    })
    return FeeData
  }
  const [initialValues, setInitialValues] = useState<any>({
    DealID: dealData?.DealID,
    DealType: dealData?.DealType,
    State: dealData?.DealState,
    SalesPrice: dealData?.SalesPrice,
    DealDate: dealData?.DealDate,
    DealState: dealData?.DealState,
    ...getFeeData(),
    ...getProductData(),

    DealHasTrade: dealData?.IsHasTrade,
    TradeAllowance: dealData?.TradeAllowance,
    TradeLien: dealData?.TradeLien,
    TradeACV: dealData?.TradeACV,
    CashDown: dealData?.CashDown,

    DealPostalCode: dealData?.DealPostalCode,
    TaxState: dealData?.TaxState,
    TaxCity: dealData?.TaxCityName,
    TaxCounty: dealData?.TaxCountyName,
    StateTaxRate: dealData?.StateTaxRate?.toString(),
    CityTaxRate: dealData?.CityTaxRate?.toString(),
    CountyTaxRate: dealData?.CountyTaxRate?.toString(),
    MiscTaxRate: dealData?.MiscTaxRate?.toString(),

    Schedule: dealData?.PaymentSchedule,
    DueDay1: dealData?.DueDay1,
    DueDay2: dealData?.DueDay2,
    FirstDueDate: dealData?.FirstDueDate,
    Calculate: dealData?.DesiredPayment === '0' ? 'term' : 'payment',
    DesiredTerm: dealData?.DesiredTerm?.toString(),
    DesiredPayment: dealData?.DesiredPayment?.toString(),
    InterestRate: dealData?.InterestRate?.toString(),

    LenderID: dealData?.LenderID,
    SpreadType: dealData?.SpreadRate ? 'rate' : 'amount',
    SpreadAmount: dealData?.SpreadAmount?.toString(),
    SpreadRate: dealData?.SpreadRate?.toString(),
    DiscountType: dealData?.SalesDiscountRate ? 'rate' : 'amount',
    DiscountRate: dealData?.SalesDiscountRate?.toString(),
    DiscountAmount: dealData?.SalesDiscountAmount?.toString(),

    BranchID: dealData?.BranchID,
    DealStatus: dealData?.DealStatusID,
    Salesperson: dealData?.SalespersonUserID,
    Manager: dealData?.FiUserID,
    NumberOfYears: (
      (dealData?.DesiredTerm || 0) / (PAYMENTS_PER_YEAR[(dealData?.PaymentSchedule as keyof typeof PAYMENTS_PER_YEAR) || ''] || 0)
    ).toString(),
    CalculatedTerm: null,
  })

  const getFirstDealDate = (dealDate: string) => {
    if (stateDealSettingsData?.IsDefaultOneTermUntilFirstDue) {
      if (stateDealSettingsData?.DefaultSchedule === 'M') {
        return dayjs(dealDate).add(1, 'months').format('YYYY-MM-DD')
      }
      if (stateDealSettingsData?.DefaultSchedule === 'B') {
        return dayjs(dealDate).add(14, 'days').format('YYYY-MM-DD')
      }
      if (stateDealSettingsData?.DefaultSchedule === 'W') {
        return dayjs(dealDate).add(7, 'days').format('YYYY-MM-DD')
      }
      return undefined
    } else {
      return dayjs(dealDate)
        .add(stateDealSettingsData?.DefaultDaysBeforeFirstDue as number, 'days')
        .format('YYYY-MM-DD')
    }
  }

  const handleSubmit = async (values: any) => {
    const feesList: TaxesObjects[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('FeeFieldValue')) {
        const id = item?.replace('FeeFieldValue', '')
        if (values[`FeeFieldValue${id}`] && Number(removeMask(values[`FeeFieldValue${id}`])) !== 0) {
          feesList.push({
            Amount: Number(removeMask(values[`FeeFieldValue${id}`])) || 0,
            IsTaxable: values[`FeeIsTaxableValue${id}`],
          })
        }
      }
    })

    const productsList: TaxesObjects[] = []
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('ProductFieldValue')) {
        const id = item?.replace('ProductFieldValue', '')
        if (values[`ProductFieldValue${id}`] && Number(removeMask(values[`ProductFieldValue${id}`])) !== 0) {
          productsList.push({
            Amount: Number(removeMask(values[`ProductFieldValue${id}`])) || 0,
            IsTaxable: values[`ProductFieldIsActive${id}`],
          })
        }
      }
    })
    getMaturityDateForDeal({
      maturityDate: {
        term: values.DesiredTerm,
        payment_schedule: values.Schedule,
        due_day_1: values.DueDay1,
        due_day_2: values.DueDay2,
        final_payment_amount: 0,
        first_due_date: convertDateToString(values.FirstDueDate) as string,
      },
    }).then((res: any) => {
      setSecondClick(true)

      setDisableCalculateBtn(true)
    })

    // getMaturityDateForDeal({
    //   maturityDate: {
    //     term: values.Term,
    //     payment_schedule: values.Schedule,
    //     due_day_1: values.DueDay1,
    //     due_day_2: values.DueDay2,
    //     final_payment_amount: 0,
    //     first_due_date: convertDateToString(values.FirstDueDate) as string,
    //   },
    // }).then((res: any) => {
    //   setDisableCalculateBtn(true)
    // })
  }

  useEffect(() => {
    if (dealType === 'Wholesale') {
      setDisplay({
        downPaymentsBlock: false,
        salesTaxBlock: false,
        termsBlock: false,
        fieldFees: false,
        fieldProducts: false,
        fieldPaymentSchedule: true,
        fieldFirstDueDate: true,
      })
      setInitialValues({
        ...initialValues,
        Schedule: 'W',
        FirstDueDate: dealDateValue,
        Calculate: 'payment',
        DesiredTerm: '1',
        InterestRate: '0',
        DealType: 'Wholesale',
        Term: '1',
        StateTaxRate: '0',
        CityTaxRate: '0',
        CountyTaxRate: '0',
        MiscTaxRate: '0',
      })
    } else if (dealType === 'Cash') {
      setDisplay({
        downPaymentsBlock: true,
        salesTaxBlock: true,
        termsBlock: false,
        fieldFees: true,
        fieldProducts: true,
        fieldPaymentSchedule: true,
        fieldFirstDueDate: true,
      })
      setInitialValues({
        ...initialValues,
        Schedule: 'W',
        FirstDueDate: dealDateValue,
        Calculate: 'payment',
        DesiredTerm: '1',
        InterestRate: '0',
        DealType: 'Cash',
        Term: '1',
      })
    } else if (dealType === 'Financed') {
      setDisplay({
        downPaymentsBlock: true,
        salesTaxBlock: true,
        termsBlock: true,
        fieldFees: true,
        fieldProducts: true,
        fieldPaymentSchedule: false,
        fieldFirstDueDate: true,
      })
      setInitialValues({
        ...initialValues,
        DealType: 'Financed',
      })
    } else {
      setDisplay({
        downPaymentsBlock: true,
        salesTaxBlock: true,
        termsBlock: true,
        fieldFees: true,
        fieldProducts: true,
        fieldPaymentSchedule: false,
        fieldFirstDueDate: true,
      })
    }
  }, [dealType])

  useEffect(() => {
    if (carSelected?.CarID && stateDealSettingsIsSuccess) {
      getCar({ carId: carSelected?.CarID?.toString() as string })
    }
  }, [carSelected?.CarID, stateDealSettingsIsSuccess])

  useEffect(() => {
    // SalesPrice
    if (carIsSuccess && carData) {
      // if (dealID === 'add') {
      // }
      // setInitialValues({
      // ...initialValues,
      // SalesPrice: carData?.StickerPrice?.toString(),
      // CashDown: carData?.DownPayment?.toString(),
      // })
      // _Form.mutators.setFormAttribute('SalesPrice', carData?.StickerPrice?.toString())
    }
  }, [carData, carIsSuccess])

  // carSelected

  useEffect(() => {
    let pickupPayment = 0
    let feeInitialSum = 0
    let feeInitialResultSum = 0
    let productsInitialSum = 0
    if (stateDealSettingsIsSuccess) {
      const initialData: any = {}

      // Resetting Fee fields
      feesForm.forEach((fee) => {
        initialData[fee.fieldName] = undefined
        initialData[`FeeIsTaxableValue${fee.id}`] = undefined
        initialData[`FeeFieldIsActive${fee.id}`] = undefined
        initialData[`FeeFieldProfitType${fee.id}`] = undefined
        initialData[`FeeFieldFeeLabel${fee.id}`] = undefined
        initialData[`FeeFieldValue${fee.id}`] = 0
      })

      // Resetting Product fields
      productsForm.forEach((product) => {
        initialData[`ProductFieldValue${product.id}`] = 0
        initialData[`ProductIsTaxableValue${product.id}`] = undefined
        initialData[`ProductFieldIsActive${product.id}`] = undefined
        initialData[`ProductFieldProfitType${product.id}`] = undefined
        initialData[`ProductFieldProfitLabel${product.id}`] = undefined
        initialData[`ProductFieldProfitCostRate${product.id}`] = undefined
        initialData[`ProductFieldProfitCostAmount${product.id}`] = undefined
      })

      if (dealID === 'add') {
        // add deal
        let feeData: any[] = [] // set deal fee data
        stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
          if (item?.IsActive && item?.IsLeaseDueAtSigning == false) {
            feeData.push({
              id: item?.StateFeeID,
              fieldName: `FeeFieldValue${item?.StateFeeID}`,
              fieldLabel: item?.FeeLabel,
              fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
              feeAmount: item?.FeeAmount || '0',
              feeInitialAmount: item?.FeeAmount || '0',
              isFixedAmount: item?.IsFixedAmount,
              isTaxable: item?.IsTaxable,
              IsLeaseDueAtSigning: item?.IsLeaseDueAtSigning,
            })
            if (item?.FeeLabel && item?.IsFixedAmount) {
              feeInitialSum += Number(item?.FeeAmount)
            }

            initialData[`FeeFieldValue${item?.StateFeeID}`] = item?.IsFixedAmount ? item?.FeeAmount?.toString() : '0'
            initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
            initialData[`FeeFieldIsActive${item?.StateFeeID}`] = item?.IsFixedAmount
            initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
            initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
          }
        })
        setFeesForm(feeData)
        feeData = [] // set deal fee data
        stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
          if (item?.IsActive && item?.IsLeaseDueAtSigning) {
            feeData.push({
              id: item?.StateFeeID,
              fieldName: `FeeFieldValue${item?.StateFeeID}`,
              fieldLabel: item?.FeeLabel,
              fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
              feeAmount: item?.FeeAmount || '0',
              feeInitialAmount: item?.FeeAmount || '0',
              isFixedAmount: item?.IsFixedAmount,
              isTaxable: item?.IsTaxable,
              IsLeaseDueAtSigning: item?.IsLeaseDueAtSigning,
            })
            if (item?.FeeLabel && item?.IsFixedAmount) {
              feeInitialResultSum += Number(item?.FeeAmount)
            }

            initialData[`FeeFieldValue${item?.StateFeeID}`] = item?.IsFixedAmount ? item?.FeeAmount?.toString() : '0'
            initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
            initialData[`FeeFieldIsActive${item?.StateFeeID}`] = item?.IsFixedAmount
            initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
            initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
          }
        })
        setFeesFormResults(feeData)

        // set products data
        const productData: any[] = [] // set products fee data
        stateDealSettingsData?.ProductDataInJson?.forEach((item) => {
          if (item?.IsActive) {
            productData.push({
              id: item?.StateProductID,
              fieldName: `ProductFieldValue${item?.StateProductID}`,
              fieldLabel: item?.ProductLabel,
              fieldCheckboxName: `ProductFieldIsActive${item?.StateProductID}`,
              productAmount: item?.ProductPrice || '0',
              productInitialAmount: item?.ProductPrice || '0',
              isFixedAmount: item?.IsFixedPrice,
              isTaxable: item?.IsTaxable,
            })
            // if (item?.ProductLabel) {
            //   productsInitialSum += Number(item?.ProductPrice)
            // }

            initialData[`ProductFieldValue${item?.StateProductID}`] = '0'
            initialData[`ProductIsTaxableValue${item?.StateProductID}`] = item?.IsTaxable
            initialData[`ProductFieldProfitType${item?.StateProductID}`] = item?.ProfitType
            initialData[`ProductFieldProfitLabel${item?.StateProductID}`] = item?.ProductLabel
            initialData[`ProductFieldProfitCostRate${item?.StateProductID}`] = item?.CostRate
            initialData[`ProductFieldProfitCostAmount${item?.StateProductID}`] = item?.CostAmount
          }
        })
        setProductsForm(productData)
        // productData
      } else {
        // edit deal

        let feeData: any[] = [] // set deal fee data
        stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
          const feeDataItem = dealData?.FeeData?.find((dealDataItem) => dealDataItem?.StateFeeID === item?.StateFeeID)

          if (item?.IsActive && item?.IsLeaseDueAtSigning == false) {
            feeData.push({
              id: item?.StateFeeID,
              fieldName: `FeeFieldValue${item?.StateFeeID}`,
              fieldLabel: item?.FeeLabel || feeDataItem?.FeeLabel,
              fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
              feeAmount: feeDataItem?.FeeAmount || '0',
              feeInitialAmount: item?.FeeAmount || '0',
              isFixedAmount: item?.IsFixedAmount,
              isTaxable: item?.IsTaxable,
              IsLeaseDueAtSigning: item?.IsLeaseDueAtSigning,
            })
            if (feeDataItem?.FeeAmount) {
              feeInitialSum += Number(feeDataItem?.FeeAmount)
            }

            initialData[`FeeFieldValue${item?.StateFeeID}`] = feeDataItem?.FeeAmount?.toString() || '0'
            initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
            initialData[`FeeFieldIsActive${item?.StateFeeID}`] = feeDataItem && true // item?.IsFixedAmount
            initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
            initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
          }
        })
        setFeesForm(feeData)
        console.log('feeData', feeData)

        feeData = [] // set deal fee data
        stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
          const feeDataItem = dealData?.FeeData?.find((dealDataItem) => dealDataItem?.StateFeeID === item?.StateFeeID)

          if (item?.IsActive && item?.IsLeaseDueAtSigning) {
            feeData.push({
              id: item?.StateFeeID,
              fieldName: `FeeFieldValue${item?.StateFeeID}`,
              fieldLabel: item?.FeeLabel || feeDataItem?.FeeLabel,
              fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
              feeAmount: feeDataItem?.FeeAmount || '0',
              feeInitialAmount: item?.FeeAmount || '0',
              isFixedAmount: item?.IsFixedAmount,
              isTaxable: item?.IsTaxable,
              IsLeaseDueAtSigning: item?.IsLeaseDueAtSigning,
            })
            if (feeDataItem?.FeeAmount) {
              feeInitialResultSum += Number(feeDataItem?.FeeAmount)
            }

            initialData[`FeeFieldValue${item?.StateFeeID}`] = feeDataItem?.FeeAmount?.toString() || '0'
            initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
            initialData[`FeeFieldIsActive${item?.StateFeeID}`] = item?.IsFixedAmount
            initialData[`FeeFieldIsActive${item?.StateFeeID}`] = feeDataItem && true // item?.IsFixedAmount
            initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
            initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
          }
        })
        console.log('feeData', feeData)
        setFeesFormResults(feeData)

        const productData: any[] = [] // set deal product data
        stateDealSettingsData?.ProductDataInJson?.forEach((item) => {
          const productDataItem = dealData?.ProductData?.find((dealDataItem) => dealDataItem?.StateProductID === item?.StateProductID)
          if (item?.IsActive || item?.ProductPrice) {
            productData.push({
              id: item?.StateProductID,
              fieldName: `ProductFieldValue${item?.StateProductID}`,
              fieldLabel: item?.ProductLabel || productDataItem?.ProductLabel,
              fieldCheckboxName: `ProductFieldIsActive${item?.StateProductID}`,
              productAmount: productDataItem?.ProductPrice || '0',
              productInitialAmount: item?.ProductPrice || '0',
              isFixedPrice: false,
            })
            if (productDataItem?.ProductPrice) {
              productsInitialSum += Number(removeMask(productDataItem?.ProductPrice))
            }

            initialData[`ProductFieldValue${item?.StateProductID}`] = productDataItem?.ProductPrice?.toString() || '0'
            initialData[`ProductIsTaxableValue${item?.StateProductID}`] = item?.IsTaxable
            initialData[`ProductFieldIsActive${item?.StateProductID}`] = productDataItem && true
            initialData[`ProductFieldProfitType${item?.StateProductID}`] = item?.ProfitType
            initialData[`ProductFieldProfitLabel${item?.StateProductID}`] = item?.ProductLabel
            initialData[`ProductFieldProfitCostRate${item?.StateProductID}`] = item?.CostRate
            initialData[`ProductFieldProfitCostAmount${item?.StateProductID}`] = item?.CostAmount
          }
        })
        setProductsForm(productData)

        // const feeData: any[] = [] // set deal fee data
        // stateDealSettingsData?.StateFeeDataInJson?.forEach((item) => {
        //   const feeDataItem = dealData?.FeeData?.find((dealDataItem) => dealDataItem?.StateFeeID === item?.StateFeeID)

        //   if (item?.IsActive || feeDataItem?.FeeAmount) {
        //     feeData.push({
        //       id: item?.StateFeeID,
        //       fieldName: `FeeFieldValue${item?.StateFeeID}`,
        //       fieldLabel: item?.FeeLabel || feeDataItem?.FeeLabel,
        //       fieldCheckboxName: `FeeFieldIsActive${item?.StateFeeID}`,
        //       feeAmount: feeDataItem?.FeeAmount || '0',
        //       feeInitialAmount: item?.FeeAmount || '0',
        //       isFixedAmount: false, //item?.IsFixedAmount,
        //     })
        //     if (feeDataItem?.FeeAmount) {
        //       feeInitialSum += Number(removeMask(feeDataItem?.FeeAmount))
        //     }

        //     initialData[`FeeFieldValue${item?.StateFeeID}`] = feeDataItem?.FeeAmount?.toString() || '0'
        //     initialData[`FeeIsTaxableValue${item?.StateFeeID}`] = item?.IsTaxable
        //     initialData[`FeeFieldIsActive${item?.StateFeeID}`] = feeDataItem && true // item?.IsFixedAmount
        //     initialData[`FeeFieldProfitType${item?.StateFeeID}`] = item?.ProfitType
        //     initialData[`FeeFieldFeeLabel${item?.StateFeeID}`] = item?.FeeLabel
        //   }
        // })
        // setFeesForm(feeData)

        // const productData: any[] = [] // set deal product data
        // stateDealSettingsData?.ProductDataInJson?.forEach((item) => {
        //   const productDataItem = dealData?.ProductData?.find((dealDataItem) => dealDataItem?.StateProductID === item?.StateProductID)
        //   if (item?.IsActive || productDataItem?.ProductPrice) {
        //     productData.push({
        //       id: item?.StateProductID,
        //       fieldName: `ProductFieldValue${item?.StateProductID}`,
        //       fieldLabel: item?.ProductLabel || productDataItem?.ProductLabel,
        //       fieldCheckboxName: `ProductFieldIsActive${item?.StateProductID}`,
        //       productAmount: productDataItem?.ProductPrice || '0',
        //       productInitialAmount: item?.ProductPrice || '0',
        //       isFixedPrice: false,
        //     })
        //     if (productDataItem?.ProductPrice) {
        //       productsInitialSum += Number(removeMask(productDataItem?.ProductPrice))
        //     }

        //     initialData[`ProductFieldValue${item?.StateProductID}`] = productDataItem?.ProductPrice?.toString() || '0'
        //     initialData[`ProductIsTaxableValue${item?.StateProductID}`] = item?.IsTaxable
        //     initialData[`ProductFieldIsActive${item?.StateProductID}`] = productDataItem && true
        //     initialData[`ProductFieldProfitType${item?.StateProductID}`] = item?.ProfitType
        //     initialData[`ProductFieldProfitLabel${item?.StateProductID}`] = item?.ProductLabel
        //     initialData[`ProductFieldProfitCostRate${item?.StateProductID}`] = item?.CostRate
        //     initialData[`ProductFieldProfitCostAmount${item?.StateProductID}`] = item?.CostAmount
        //   }
        // })
        // setProductsForm(productData)
      }

      console.log('feeInitialResultSum', feeInitialResultSum)
      initialData['PickupPayments'] = pickupPayment?.toString() || '0'
      initialData['Fees'] = feeInitialSum?.toString() || '0'
      initialData['FeesResult'] = feeInitialResultSum?.toString() || '0'
      initialData['Products'] = productsInitialSum?.toString() || '0'

      if (dealID !== 'add') {
        if (!isDownPaymentDeleteCalled) {
          // set pickup payments data
          const paymentsForm: string[] = []
          dealData?.PickupPaymentData?.forEach((item) => {
            paymentsForm.push(item?.DealPickupID?.toString())
            pickupPayment += Number(item?.Amount)
            initialData[`DatePaymentAmount${item?.DealPickupID}`] = item?.Amount
            initialData[`DuePaymentDate${item?.DealPickupID}`] = item?.DueDate
            // DatePaymentAmount
          })
          setPaymentsForm(paymentsForm)
        }
      }

      if (dealID === 'add') {
        // set initial date for add deal

        if (!stateDealSettingsData?.IsDestinationTax) {
          setDisableSalesTaxFields(true)
          setInitialValues({
            ...initialValues,
            DealType: 'Financed',
            // DealDate: dayjs().startOf('day'),
            DealDate: getCurrentDate(),

            DealPostalCode: stateDealSettingsData?.StateCityCountyData?.[0]?.ZipCode,
            TaxCity: stateDealSettingsData?.StateCityCountyData?.[0]?.City,
            TaxCounty: stateDealSettingsData?.StateCityCountyData?.[0]?.County,
            TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
            CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
            CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),
            StateTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.StateTaxRate?.toString(),

            DealState: stateDealSettingsData?.State,
            DesiredTerm: stateDealSettingsData?.DefaultTerm?.toString(),
            InterestRate: stateDealSettingsData?.DefaultInterestRate?.toString(),
            MiscTaxRate: stateDealSettingsData?.MiscTaxRate?.toString(),
            Schedule: stateDealSettingsData?.DefaultSchedule,
            FirstDueDate: getFirstDealDate(getCurrentDate()),
            DueDay1: stateDealSettingsData?.DefaultDueDay1?.toString(),
            DueDay2: stateDealSettingsData?.DefaultDueDay2?.toString(),
            ...initialData,
            DealHasTrade: false,
            TradeAllowance: '0',
            TradeLien: '0',
            TradeACV: '0',
          })
        } else if (stateDealSettingsData?.IsDestinationTax) {
          setDisableSalesTaxFields(false)
          setInitialValues({
            ...initialValues,
            DealType: 'Financed',
            // DealDate: dayjs().startOf('day'),
            DealDate: getCurrentDate(),

            DealPostalCode: stateDealSettingsData?.StateCityCountyData?.[0]?.ZipCode,
            TaxCity: stateDealSettingsData?.StateCityCountyData?.[0]?.City,
            TaxCounty: stateDealSettingsData?.StateCityCountyData?.[0]?.County,
            TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
            CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
            CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),
            StateTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.StateTaxRate?.toString(),
            SalesPrice: '0',
            Residual: '0',
            ResidualValue: '0',
            AcquisitionFees: '0',
            CashDown: '0',
            leaseFactor: '0.1',
            SalesTaxPerPayment: '0',
            securityDeposit: '0',
            otherFee: '0',
            totalDue: '0',
            payingToday: '0',
            taxOnFees: '0',
            DealState: stateDealSettingsData?.State,
            DesiredTerm: stateDealSettingsData?.DefaultTerm?.toString(),
            InterestRate: stateDealSettingsData?.DefaultInterestRate?.toString(),
            MiscTaxRate: stateDealSettingsData?.MiscTaxRate?.toString(),
            Schedule: stateDealSettingsData?.DefaultSchedule,
            FirstDueDate: getFirstDealDate(getCurrentDate()),
            DueDay1: stateDealSettingsData?.DefaultDueDay1?.toString(),
            DueDay2: stateDealSettingsData?.DefaultDueDay2?.toString(),
            ...initialData,
            DealHasTrade: false,
            TradeAllowance: '0',
            TradeLien: '0',
            TradeACV: '0',
          })
        } else {
          setInitialValues({
            ...initialValues,
            DealType: 'Financed',
            DealDate: getCurrentDate(),

            DealPostalCode: stateDealSettingsData?.StateCityCountyData?.[0]?.ZipCode,
            TaxCity: stateDealSettingsData?.StateCityCountyData?.[0]?.City,
            TaxCounty: stateDealSettingsData?.StateCityCountyData?.[0]?.County,
            TaxState: stateDealSettingsData?.StateCityCountyData?.[0]?.State,
            CityTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CityTaxRate?.toString(),
            CountyTaxRate: stateDealSettingsData?.StateCityCountyData?.[0]?.CountyTaxRate?.toString(),

            StateTaxRate: stateDealSettingsData?.StateTaxRate?.toString(),
            DealState: stateDealSettingsData?.State,
            DesiredTerm: stateDealSettingsData?.DefaultTerm?.toString(),
            InterestRate: stateDealSettingsData?.DefaultInterestRate?.toString(),
            MiscTaxRate: stateDealSettingsData?.MiscTaxRate?.toString(),
            Schedule: stateDealSettingsData?.DefaultSchedule,
            FirstDueDate: getFirstDealDate(getCurrentDate()),
            DueDay1: stateDealSettingsData?.DefaultDueDay1?.toString(),
            DueDay2: stateDealSettingsData?.DefaultDueDay2?.toString(),
            ...initialData,
            DealHasTrade: false,
            TradeAllowance: '0',
            TradeLien: '0',
            TradeACV: '0',
          })
        }
      } else {
        setInitialValues({
          DealID: dealData?.DealID,
          DealType: dealData?.DealType,
          DealDate: dealData?.DealDate,
          DealHasTrade: dealData?.IsHasTrade,
          TradeAllowance: dealData?.TradeAllowance,
          TradeLien: dealData?.TradeLien,
          TradeACV: dealData?.TradeACV,
          DealPostalCode: dealData?.DealPostalCode,
          TaxState: dealData?.TaxState,
          TaxCity: dealData?.TaxCityName,
          TaxCounty: dealData?.TaxCountyName,
          StateTaxRate: dealData?.StateTaxRate?.toString(),
          CityTaxRate: dealData?.CityTaxRate?.toString(),
          CountyTaxRate: dealData?.CountyTaxRate?.toString(),
          MiscTaxRate: dealData?.MiscTaxRate?.toString(),
          SalesPrice: dealData?.SalesPrice?.toString(),
          Residual: '0',
          ResidualValue: dealData?.ResidualValue?.toString(),
          AcquisitionFees: dealData?.AcquisitionFee?.toString(),
          CashDown: dealData?.CapCostReduction?.toString(),
          leaseFactor: dealData?.LeaseFactor?.toString(),
          SalesTaxPerPayment: dealData?.SalesTaxPerPayment?.toString(),
          securityDeposit: dealData?.SecurityDeposit?.toString(),
          otherFee: dealData?.OtherDueAtSigning?.toString(),
          otherFeeDesc: dealData?.OtherDueAtSigningDescription,
          totalDue: dealData?.TotalDueAtSigning?.toString(),
          payingToday: dealData?.TotalPaidAtSigning?.toString(),
          taxOnFees: dealData?.TaxOnFeesAtSigning?.toString(),
          Schedule: dealData?.PaymentSchedule,
          DueDay1: dealData?.DueDay1,
          DueDay2: dealData?.DueDay2,
          FirstDueDate: dealData?.FirstDueDate,
          Calculate: dealData?.CalculationBasis.toLowerCase(),
          DesiredTerm: dealData?.DesiredTerm?.toString(),
          DesiredPayment: dealData?.DesiredPayment?.toString(),
          InterestRate: dealData?.InterestRate?.toString(),

          LenderID: dealData?.LenderID,
          SpreadType: dealData?.SpreadRate ? 'rate' : 'amount',
          SpreadAmount: dealData?.SpreadAmount?.toString(),
          SpreadRate: dealData?.SpreadRate?.toString(),
          DiscountType: dealData?.SalesDiscountRate ? 'rate' : 'amount',
          DiscountRate: dealData?.SalesDiscountRate?.toString(),
          DiscountAmount: dealData?.SalesDiscountAmount?.toString(),
          LeaseID: dealData?.LeaseID,
          BranchID: dealData?.BranchID,
          DealStatus: dealData?.DealStatusID,
          Salesperson: dealData?.SalespersonUserID,
          Manager: dealData?.FiUserID,
          NumberOfYears: (
            (dealData?.DesiredTerm || 0) / (PAYMENTS_PER_YEAR[(dealData?.PaymentSchedule as keyof typeof PAYMENTS_PER_YEAR) || ''] || 0)
          ).toString(),
          CalculatedTerm: null,
          DealState: stateDealSettingsData?.State || dealData?.DealState,
          ...initialData,
        })
      }
    }
  }, [stateDealSettingsIsSuccess, dealData, isDownPaymentDeleteCalled, stateDealSettingsData])

  useEffect(() => {
    if (maturityDateForDealIsSuccess) {
      setTimeout(() => {
        buttonRef?.current?.click && buttonRef?.current?.click()
        setSecondClick(false)
      }, 1000)
    }
  }, [maturityDateForDealData, maturityDateForDealIsSuccess])

  useEffect(() => {
    getStateDealSettings({ state: dealStructureFormValues?.DealState || dealData?.DealState || 'XX' })
  }, [dealStructureFormValues])

  const isLoading = useMemo(
    () => stateDealSettingsIsLoading || calculationForDealIsLoading || maturityDateForDealIsLoading || taxesIsLoading,
    [stateDealSettingsIsLoading, calculationForDealIsLoading, maturityDateForDealIsLoading, taxesIsLoading],
  )
  const settings = useSettingsContext()

  const [currentStep, setCurrentStep] = useState(0)

  const [editDeal, { isSuccess: editDealIsSuccess }] = useEditDealMutation()

  // useEffect(() => {
  //   if (taxesIsSuccess) {
  //     setTimeout(() => {
  //       buttonRef?.current?.click && buttonRef?.current?.click()
  //       setSecondClick(false)
  //     }, 1000)
  //   }
  // }, [taxesIsSuccess])

  return (
    <>
      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <CustomBreadcrumbs
          heading='Deal'
          links={[
            {
              name: 'List',
              href: appRoute.DealsList,
            },
            {
              name: dealID === 'add' ? 'New' : 'Edit',
            },
          ]}
          sx={{
            mb: {
              xs: 1,
              md: 3,
            },
          }}
        />

        <VehicleCard carData={carSelected} setCurrentStage={setCurrentStage} setSelectedCar={setSelectedCar} />
        <BuyerCard
          setCurrentStage={setCurrentStage}
          creditSelected={creditSelected}
          dealData={dealData}
          setSelectedCreditApp={setSelectedCreditApp}
        />

        <FormStepper activeStep={currentStep} />

        <div style={{ display: stage === 'dealStructure' ? 'block' : 'none' }}>
          <Form
            onSubmit={handleSubmit}
            initialValues={dealStructureFormValues || initialValues}
            validate={validateForm(addDealValidation)}
            mutators={{
              setFormAttribute: ([fieldName, fieldVal], state, { changeValue }) => {
                changeValue(state, fieldName, () => fieldVal)
              },
            }}
          >
            {({ handleSubmit, valid, form, values }: any) => {
              _Form = form
              return (
                <form onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      flexGrow: { md: 1 },
                      display: { md: 'flex' },
                      flexDirection: { md: 'column' },
                      gap: 3,
                    }}
                  >
                    <Grid columns={16} container spacing={2}>
                      <Grid item xs={6}>
                        <Card
                          sx={{
                            flexGrow: { md: 1 },
                            display: { md: 'flex' },
                            flexDirection: { md: 'column' },
                            p: 4,
                            gap: 3,
                          }}
                        >
                          <StructureForm
                            disableFields={disableFields}
                            setDisableSalesTaxFields={setDisableSalesTaxFields}
                            setDealDateValue={setDealDateValue}
                            dealData={dealData}
                            getStateDealSettings={getStateDealSettings}
                            stateDealSettingsData={stateDealSettingsData}
                            feesForm={feesForm}
                            setDealType={setDealType}
                            display={display}
                            initialValues={initialValues}
                            productsForm={productsForm}
                          />
                          <DownPaymentsForm
                            disableFields={disableFields}
                            setIsDownPaymentDeleteCalled={setIsDownPaymentDeleteCalled}
                            formPayments={formPayments}
                            setFormPayments={setFormPayments}
                            paymentsForm={paymentsForm}
                            setPaymentsForm={setPaymentsForm}
                            setTotalPickups={setTotalPickups}
                          />
                        </Card>
                      </Grid>
                      <Grid item xs={6}>
                        <Card
                          sx={{
                            flexGrow: { md: 1 },
                            display: { md: 'flex' },
                            flexDirection: { md: 'column' },
                            p: 4,
                            gap: 3,
                          }}
                        >
                          {display?.salesTaxBlock && <SalesTaxForm disableFields={disableFields} disableSalesTaxFields={disableSalesTaxFields} />}

                          {display?.termsBlock && (
                            <TermsForm
                              disableFields={disableFields}
                              dealData={dealData}
                              valid={valid}
                              stateDealSettingsData={stateDealSettingsData}
                              setValue={form.mutators.setFormAttribute}
                            />
                          )}
                        </Card>
                      </Grid>
                      <Grid item xs={4}>
                        <Card
                          sx={{
                            flexGrow: { md: 1 },
                            display: { md: 'flex' },
                            flexDirection: { md: 'column' },
                            p: 4,
                            gap: 3,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Typography variant='subtitle1' gutterBottom>
                                Results
                              </Typography>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Gross Cap Cost:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.SalesPrice && values.AcquisitionFees && values.Products && values.Fees
                                    ? formatNumber(
                                        Number(removeMask(values.SalesPrice)) +
                                          Number(removeMask(values.AcquisitionFees)) +
                                          Number(removeMask(values.Products)) +
                                          Number(removeMask(values.Fees)),
                                      )
                                    : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Cap Cost Reduction:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.CashDown ? formatNumber(Number(removeMask(values.CashDown))) : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Adjusted Cap Cost:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.SalesPrice && values.AcquisitionFees && values.Products && values.Fees && values.CashDown
                                    ? formatNumber(
                                        Number(removeMask(values.SalesPrice)) +
                                          Number(removeMask(values.AcquisitionFees)) +
                                          Number(removeMask(values.Products)) +
                                          Number(removeMask(values.Fees)) -
                                          Number(removeMask(values.CashDown)),
                                      )
                                    : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Depreciation:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.AcquisitionFees && values.Products && values.Fees && values.CashDown
                                    ? formatNumber(
                                        Number(removeMask(values.SalesPrice)) +
                                          Number(removeMask(values.AcquisitionFees)) +
                                          Number(removeMask(values.Products)) +
                                          Number(removeMask(values.Fees)) -
                                          Number(removeMask(values.CashDown)) -
                                          Number(removeMask(values.ResidualValue)),
                                      )
                                    : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Rent Charges:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.AcquisitionFees && values.Products && values.Fees && values.CashDown
                                    ? formatNumber(Results(values))
                                    : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Base Payment:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.AcquisitionFees && values.Products && values.Fees && values.CashDown
                                    ? formatNumber(
                                        (Number(removeMask(values.SalesPrice)) +
                                          Number(removeMask(values.AcquisitionFees)) +
                                          Number(removeMask(values.Products)) +
                                          Number(removeMask(values.Fees)) -
                                          Number(removeMask(values.CashDown)) -
                                          Number(removeMask(values.ResidualValue))) /
                                          Number(removeMask(values.DesiredTerm)) +
                                          (Number(removeMask(values.SalesPrice)) +
                                            Number(removeMask(values.AcquisitionFees)) +
                                            Number(removeMask(values.Products)) +
                                            Number(removeMask(values.Fees)) -
                                            Number(removeMask(values.CashDown)) +
                                            Number(removeMask(values.ResidualValue))) *
                                            Number(removeMask(values.leaseFactor)),
                                      )
                                    : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Sales Tax:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.AcquisitionFees && values.Products && values.Fees && values.CashDown
                                    ? formatNumber(
                                        (Number(removeMask(values.total_tax)) / 100) *
                                          ((Number(removeMask(values.SalesPrice)) +
                                            Number(removeMask(values.AcquisitionFees)) +
                                            Number(removeMask(values.Products)) +
                                            Number(removeMask(values.Fees)) -
                                            Number(removeMask(values.CashDown)) -
                                            Number(removeMask(values.ResidualValue))) /
                                            Number(removeMask(values.DesiredTerm)) +
                                            (Number(removeMask(values.SalesPrice)) +
                                              Number(removeMask(values.AcquisitionFees)) +
                                              Number(removeMask(values.Products)) +
                                              Number(removeMask(values.Fees)) -
                                              Number(removeMask(values.CashDown)) +
                                              Number(removeMask(values.ResidualValue))) *
                                              Number(removeMask(values.leaseFactor))),
                                      )
                                    : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container item xs={12} justifyContent={'flex-end'} spacing={2} wrap='nowrap'>
                              <Grid item>
                                <Typography variant='body2'>Total Payment:</Typography>
                              </Grid>
                              <Grid item>
                                <Typography variant='body2'>
                                  {values.AcquisitionFees && values.Products && values.Fees && values.CashDown
                                    ? formatNumber(
                                        (Number(removeMask(values.total_tax)) / 100) *
                                          ((Number(removeMask(values.SalesPrice)) +
                                            Number(removeMask(values.AcquisitionFees)) +
                                            Number(removeMask(values.Products)) +
                                            Number(removeMask(values.Fees)) -
                                            Number(removeMask(values.CashDown)) -
                                            Number(removeMask(values.ResidualValue))) /
                                            Number(removeMask(values.DesiredTerm)) +
                                            (Number(removeMask(values.SalesPrice)) +
                                              Number(removeMask(values.AcquisitionFees)) +
                                              Number(removeMask(values.Products)) +
                                              Number(removeMask(values.Fees)) -
                                              Number(removeMask(values.CashDown)) +
                                              Number(removeMask(values.ResidualValue))) *
                                              Number(removeMask(values.leaseFactor))) +
                                          ((Number(removeMask(values.SalesPrice)) +
                                            Number(removeMask(values.AcquisitionFees)) +
                                            Number(removeMask(values.Products)) +
                                            Number(removeMask(values.Fees)) -
                                            Number(removeMask(values.CashDown)) -
                                            Number(removeMask(values.ResidualValue))) /
                                            Number(removeMask(values.DesiredTerm)) +
                                            (Number(removeMask(values.SalesPrice)) +
                                              Number(removeMask(values.AcquisitionFees)) +
                                              Number(removeMask(values.Products)) +
                                              Number(removeMask(values.Fees)) -
                                              Number(removeMask(values.CashDown)) +
                                              Number(removeMask(values.ResidualValue))) *
                                              Number(removeMask(values.leaseFactor))),
                                      )
                                    : formatNumber(0)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box display='flex' justifyContent='flex-end' gap={2} mt={3}>
                    <Recap carData={carData} dealData={dealData} />
                    {/* <Button disabled={disableCalculateBtn} type='submit' variant='outlined'>
                    Calculate
                  </Button> */}
                    <NextButton
                      taxesIsSuccess={taxesIsSuccess}
                      dealType={dealType}
                      // setDisableCalculateBtn={setDisableCalculateBtn}
                      taxesData={taxesData}
                      setCurrentStage={setCurrentStage}
                      calculationForDealData={calculationForDealData}
                      calculationForDealIsSuccess={calculationForDealIsSuccess}
                      setDealStructureFormValues={setDealStructureFormValues}
                      maturityDateForDealData={maturityDateForDealData}
                      maturityDateForDealIsSuccess={maturityDateForDealIsSuccess}
                      setCurrentStep={setCurrentStep}
                      forwardRef={buttonRef}
                      onClick={
                        secondClick == false
                          ? () => {
                              form.submit()
                            }
                          : undefined
                      }
                    />
                  </Box>
                </form>
              )
            }}
          </Form>
        </div>

        {stage === 'dealResult' && (
          <DealResult
            dealData={dealData}
            setSecondClick={setSecondClick}
            disableFields={disableFields}
            dealType={dealType}
            totalPickups={totalPickups}
            carSelected={carSelected}
            creditSelected={creditSelected}
            setCurrentStage={setCurrentStage}
            setSelectedCar={setSelectedCar}
            setSelectedCreditApp={setSelectedCreditApp}
            dealStructureFormValues={dealStructureFormValues}
            editDeal={editDeal}
            stateDealSettingsData={stateDealSettingsData}
            feesForm={feesFormResults}
            editDealIsSuccess={editDealIsSuccess}
            carData={carData}
            setIsDownPaymentDeleteCalled={setIsDownPaymentDeleteCalled}
            formPayments={formPayments}
            setFormPayments={setFormPayments}
            paymentsForm={paymentsForm}
            setPaymentsForm={setPaymentsForm}
            setTotalPickups={setTotalPickups}
            setStructureInititalValues={setDealStructureFormValues}
          />
        )}
      </Container>

      {/* <CustomCircular open={isLoading} /> */}
    </>
  )
}

export default withPermissionsDropdown(DealStructure)
