export const transformToFormValues = (values?: any, isEdit?: any) => {
  if (isEdit) {
    return {
      ID: values?.ID,
      IsActive: values?.IsActive,
      FieldName: values?.FieldName,
      MappingType: values?.MappingType,
      FieldValue: values?.Value,
      ...(values?.MappingType === 'Token' && { Token: values?.Value }),
    }
  } else {
    return {}
  }
}
