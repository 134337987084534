import dayjs from 'dayjs'

export function upsert(array: any, element: any, idKey: string) {
  const i = array.findIndex((_element: any) => _element[idKey] === element[idKey])
  if (i > -1) array[i] = element // (2)
  else array.push(element)
}

export function getCurrentDate(): string {
  const today = new Date()
  let dd: number = today.getDate()
  let mm: number = today.getMonth() + 1
  let yyyy: number = today.getFullYear()

  let ddStr: string = dd < 10 ? `0${dd}` : `${dd}`
  let mmStr: string = mm < 10 ? `0${mm}` : `${mm}`
  
  return `${yyyy}-${mmStr}-${ddStr}`
}

export function getEarlierDate(daysBefore: number): string {
  const date = new Date() // today
  date.setDate(date.getDate() - daysBefore) // set date {daysBefore} days ago
  let dd: any = date.getDate()
  let mm: any = date.getMonth() + 1
  let yyyy: any = date.getFullYear()

  if (dd < 10) {
    dd = '0' + dd
  }

  if (mm < 10) {
    mm = '0' + mm
  }

  return `${yyyy}-${mm}-${dd}`
}

export function objectKeysToLowerCase(obj: any): any {
  if (obj) {
    let entries = Object.entries(obj)
    let capsEntries = entries.map((entry) => [entry[0][0].toLowerCase() + entry[0].slice(1), entry[1]])
    return Object.fromEntries(capsEntries)
  } else {
    return obj
  }
}

export function checkIfObjectContainKeysWithPrefix(validationErrors: any = {}, keyNamePrefix: string): boolean {
  let containKeys = false
  Object?.keys(validationErrors)?.forEach((item) => {
    if (item?.includes(keyNamePrefix)) {
      containKeys = true
    }
  })
  return containKeys
}

export const formatObjectKey = (key: string): string => {
  return key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function applyPhoneMask(phone?: string | null) {
  if (!phone) {
    return phone
  }
  return phone
    .replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/^(\(\d{3})(\d)/, '$1) $2')
    .replace(/(\d{3})(\d{1,5})/, '$1-$2')
    .replace(/(-\d{5})\d+?$/, '$1');
}

export function roundAmount(amount?: string | null): string | null | undefined {
  if (!amount) {
    return amount
  }
  return Number(amount).toFixed(2)?.toString()
}

export function parseToNumber(str: string) {
  const parsedNumber = parseFloat(str?.replaceAll(',', ''))
  return isNaN(parsedNumber) ? 0 : parsedNumber
}

export const formatNumberNoDecimal = (num: number | string | undefined) => {
  if (num === undefined) {
    return '0'
  }
  const formattedNumber = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0,
  }).format(Number(num))

  return formattedNumber === '-0' ? '0' : formattedNumber
}

export const formatNumber = (num: number | string | undefined) => {
  if (num === undefined) {
    return '0.00'
  }
  const formattedNumber = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(num))

  return formattedNumber === '-0.00' ? '0.00' : formattedNumber
}

export const uniqueId = (text: string, index: number | string) => `${text.replace(/\s+/g, '')}-${index}`
