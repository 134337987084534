import { Button, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import Header from './components/Header/Header'
import { useNavigate, useParams } from 'react-router-dom'
import DealsProductsTableBlock from './components/DealsProductsTableBlock/DealsProductsTableBlock'
import { appRoute } from '../../../constants'
import { useLazyGetTaskDealQuery } from '../../../services'
const classes = generateUtilityClasses('Grid', ['root', 'btnWrap', 'btnCancelWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.btnWrap}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 3, 0, 3),
  },
  [`& .${classes.btnCancelWrap}`]: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 3, 3, 3),
  },
}))

interface Props {}

const DealProducts = ({}: Props) => {
  const { dealID } = useParams()
  const navigate = useNavigate()
  const [getTaskDeal, { data: taskDeal }] = useLazyGetTaskDealQuery()

  useEffect(() => {
    getTaskDeal({ dealId: dealID as string })
  }, [])

  return (
    <>
      <StyledGrid className={classes.root}>
        <Header taskDeal={taskDeal} />
        <Grid className={classes.btnWrap}>
          <Typography variant='h3'>F&I Products</Typography>
        </Grid>
        <DealsProductsTableBlock />
        <Grid item xs={12} className={classes.btnCancelWrap}>
          <Button variant='contained' color='error' onClick={() => navigate(appRoute.DealsTask.replace(':dealID', dealID as string))}>
            Close
          </Button>
        </Grid>
      </StyledGrid>
    </>
  )
}

export default DealProducts
