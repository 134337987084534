import * as yup from 'yup'

export const accountsDealSettingsValidation: yup.SchemaOf<any> = yup
  .object({
    LateChargeMax: yup.number().transform((value, originalValue) => {
      const processedValue = typeof originalValue === 'string' ? parseFloat(originalValue.replace(/,/g, '')) : originalValue
      return !isNaN(processedValue) ? processedValue : value
    }),
    LateChargeMin: yup
      .number()
      .transform((value, originalValue) => {
        const processedValue = typeof originalValue === 'string' ? parseFloat(originalValue.replace(/,/g, '')) : originalValue
        return !isNaN(processedValue) ? processedValue : value
      })
      .required()
      .test('is-greater', 'Minimum late charge cannot be greater than maximum late charge.', function (value) {
        const { LateChargeMax } = this.parent
        if (typeof value === 'number' && typeof LateChargeMax === 'number') {
          return value <= LateChargeMax
        }
        return false
      }),
    LateGraceDays: yup.number().required().label('Late Grace Days'),
    LateChargeRate: yup.number().required().label('Late Charge Rate'),
    StopAccruingInterest: yup.boolean().required().label('Stop Accruing Interest'),
  })
  .required()
