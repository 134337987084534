import { LoadingButton } from '@mui/lab'
import { Button, Card, CardHeader, Container, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { Box, styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Form, FormRenderProps } from 'react-final-form'
import DropdownFieldSearch from '../../../ui/DropdownFieldSearch'
import { AmountField, DateField, TextField } from '../../../ui'
import {
  selectDropdownByName,
  selectDropdownByNameActiveOnly,
  useAddAccountingBankDepositMutation,
  useLazyGetAccountingCashAccountsQuery,
} from '../../../services'
import validateFormValues from '../../../utils/validateForm'
import { accountingBankDepositValidation } from '../../../validation/accountingBankDepositValidation'
import { getCurrentDate } from '../../../utils/general'
import { UspListAccountingCashAccountsResult } from '../../../models'
import removeMask from '../../../utils/removeMask'
import { convertDateToString } from '../../../utils/dateUtils'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
let formVar: any
const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(4, 0, 0, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
}

interface InitialValues {
  DepositDate: string
  BankBranch?: number
  UndepositedFundsBranch?: number
  BankAccount?: number
  UndepositedFundsAccount?: number
  DepositAmount?: string
  Comments?: string
}

const BankDepositModal = () => {
  const [initialValue, setInitialValues] = useState<InitialValues | undefined>()
  const [addBankDeposit, { isSuccess }] = useAddAccountingBankDepositMutation()
  const [getCashAccounts, { data: cashAccountsData }] = useLazyGetAccountingCashAccountsQuery()
  const dispatch = useDispatch()
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  useEffect(() => {
    getCashAccounts({})
  }, [])

  const defaultValues: InitialValues = {
    DepositDate: getCurrentDate(),
  }
  useEffect(() => {
    // If there's only one branch, set it as default
    if (branch?.length === 1) {
      defaultValues.BankBranch = branch?.[0]?.BranchID
      defaultValues.UndepositedFundsBranch = branch?.[0]?.BranchID
    }

    // If there's only one cash account, set it as default
    if (cashAccountsData?.length === 1) {
      defaultValues.BankAccount = cashAccountsData[0].ID
      defaultValues.UndepositedFundsAccount = cashAccountsData[0].ID
    }

    // Find primary bank account and undeposited funds account from cashAccountsData
    if (cashAccountsData?.length) {
      cashAccountsData.forEach((account) => {
        if (account.IsPrimaryBank) {
          defaultValues.BankAccount = account.ID
        }
        if (account.IsPrimaryUndepositedFunds) {
          defaultValues.UndepositedFundsAccount = account.ID
        }
      })
    }

    setInitialValues(defaultValues)
  }, [branch, cashAccountsData])

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Transaction saved successfully' }))
      // setInitialValues(defaultValues)
      if (formVar) formVar.restart()
    }
  }, [isSuccess])

  const handleSubmit = (values: InitialValues) => {
    addBankDeposit({
      bankDeposit: {
        bankCoaID: values.BankAccount as number,
        cashCoaID: values.UndepositedFundsAccount as number,
        cashBranchID: values.UndepositedFundsBranch as number,
        bankBranchID: values.BankBranch as number,
        depositDate: convertDateToString(values.DepositDate) as string,
        depositAmount: removeMask(values.DepositAmount) as string,
        note: values.Comments as string,
      },
    })
  }

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Bank Deposits'
        links={[]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValue}
          validate={validateFormValues(accountingBankDepositValidation)}
          subscription={{ values: true }}
          render={({ handleSubmit, form, values }: FormRenderProps<InitialValues>) => {
            formVar = form
            // If BankBranch has been updated and UndepositedFundsBranch is null, set UndepositedFundsBranch to BankBranch
            if (values.BankBranch && !values.UndepositedFundsBranch) {
              form.change('UndepositedFundsBranch', values.BankBranch)
            }

            // If UndepositedFundsBranch has been updated and BankBranch is null, set BankBranch to UndepositedFundsBranch
            if (values.UndepositedFundsBranch && !values.BankBranch) {
              form.change('BankBranch', values.UndepositedFundsBranch)
            }

            return (
              <form onSubmit={handleSubmit}>
                <Grid container p={3} spacing={2}>
                  <Grid item xs={6}>
                    <DateField name='DepositDate' label='Deposit Date' fullWidth disableFuture />
                  </Grid>
                  <Grid item xs={6}>
                    <AmountField variant='standard' name='DepositAmount' label='Deposit Amount' fullWidth />
                  </Grid>
                  <Grid item xs={6}>
                    <DropdownFieldSearch
                      name='BankAccount'
                      label='Bank Account (debit)'
                      options={cashAccountsData as UspListAccountingCashAccountsResult[]}
                      optionKey={'ID'}
                      optionValue={'ChartOfAccount'}
                    />
                  </Grid>
                  {branch && (
                    <Grid item xs={6}>
                      <DropdownFieldSearch name='BankBranch' label='Bank Branch' options={branch} optionKey='BranchID' optionValue='Branch' />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <DropdownFieldSearch
                      name='UndepositedFundsAccount'
                      label='Undeposited Funds Account (credit)'
                      options={cashAccountsData as UspListAccountingCashAccountsResult[]}
                      optionKey={'ID'}
                      optionValue={'ChartOfAccount'}
                    />
                  </Grid>
                  {branch && (
                    <Grid item xs={6}>
                      <DropdownFieldSearch
                        name='UndepositedFundsBranch'
                        label='Undeposited Funds Branch'
                        options={branch}
                        optionKey='BranchID'
                        optionValue='Branch'
                      />
                    </Grid>
                  )}

                  <Grid item xs={6}>
                    <TextField name='Comments' label='Comments' fullWidth />
                  </Grid>

                  <Grid container item xs={12} justifyContent={'flex-end'}>
                    <Grid item>
                      <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                        Save
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        />
      </Card>
    </Container>
  )
}

export default BankDepositModal
