import React, { useEffect, useState, useMemo, Dispatch, SetStateAction, MutableRefObject, RefObject } from 'react'
import Button from '@mui/material/Button'
import { useForm, useFormState } from 'react-final-form'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { CalculateDealResult, MaturityDateResult, TaxesResult } from '../../../../../../../models'
import { Ref } from 'react-hook-form'
import { LoadingButton } from '@mui/lab'

interface Props {
  valid?: boolean
  taxesData?: TaxesResult
  calculationForDealData?: CalculateDealResult
  calculationForDealIsSuccess: boolean
  calculationForDealIsLoading: boolean

  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  setDealStructureFormValues: (values: any) => void
  maturityDateForDealData?: MaturityDateResult | null
  maturityDateForDealIsSuccess: boolean
  setDisableCalculateBtn?: Dispatch<SetStateAction<boolean>>
  dealType: string | undefined
  taxesIsSuccess: boolean
  setCurrentStep: Dispatch<SetStateAction<number>>
  onClick?: () => void
  forwardRef?: any
}

export const NextButton = ({
  taxesData,
  calculationForDealData,
  calculationForDealIsSuccess,
  calculationForDealIsLoading,
  setCurrentStage,
  setDealStructureFormValues,
  maturityDateForDealData,
  maturityDateForDealIsSuccess,
  setDisableCalculateBtn,
  dealType,
  taxesIsSuccess,
  setCurrentStep,
  onClick,
  forwardRef,
}: Props) => {
  const { change } = useForm()
  const { values } = useFormState()
  const [calculatedValues, setCalculatedValues] = useState<any>({})

  const handleSetCalculatedValues = () => {
    setCalculatedValues(values)
  }

  const arrFieldsToCompare = [
    'DealType',
    'DealState',
    'SalesPrice',
    'DealDate',
    'Fees',
    'Products',
    'TradeAllowance',
    'TradeLien',
    'TradeACV',
    'CashDown',
    'PickupPayments',
    'DealPostalCode',
    'TaxCity',
    'TaxCounty',
    'StateTaxRate',
    'CityTaxRate',
    'CountyTaxRate',
    'MiscTaxRate',
    'DealHasTrade',
    'TradeAllowance',
    'TradeLien',
    'TradeACV',
    'CashDown',
    'PickupPayments',
    'Schedule',
    'FirstDueDay',
    'SecondDueDay',
    'FirstDueDate',
    'Calculate',
    'DesiredPayment',
    'Term',
    'InterestRate',
    'DesiredTerm',
    'NumberOfYears',
  ]
  const compareFieldsOfObjects = (obj1: any, obj2: any) => {
    let objectIsEqualInFeilds = true
    arrFieldsToCompare?.forEach((field) => {
      if (obj1[field] !== obj2[field]) {
        objectIsEqualInFeilds = false
      }
    })

    return objectIsEqualInFeilds
  }

  useEffect(() => {
    const dealDate = values?.DealDate
    if ((dealType === 'Cash' && taxesIsSuccess) || (dealType === 'Wholesale' && taxesIsSuccess)) {
      change('AmountFinanced', taxesData?.AmountFinanced?.toFixed(2)?.toString())
      change('StateTaxAmount', taxesData?.StateTax?.toFixed(2)?.toString())
      change('CityTaxAmount', taxesData?.CityTax?.toFixed(2)?.toString())
      change('CountyTaxAmount', taxesData?.CountyTax?.toFixed(2)?.toString())
      change('MiscTaxAmount', taxesData?.MiscTax?.toFixed(2)?.toString())
      change('AmountSalesTax', taxesData?.TotalTax?.toFixed(2)?.toString())
      change('DocStampsAmount', taxesData?.DocStamps?.toFixed(2)?.toString())
      change('AmountFinalPayment', '0')
      change('APRRate', '0')
      change('AmountFinalTerm', '1')
      change('AmountFinanceCharges', '0')
      change('AmountPaymentAmount', taxesData?.AmountFinanced?.toFixed(2)?.toString())
      change('TotalOfPayments', taxesData?.AmountFinanced?.toFixed(2)?.toString())
      change('MaturityDate', dealDate?.toString())
      // forwardRef?.current?.click && forwardRef?.current?.click()
    }
  }, [dealType, taxesIsSuccess])

  useEffect(() => {
    if (calculationForDealIsSuccess) {
      change('AmountFinanced', taxesData?.AmountFinanced?.toFixed(2)?.toString())
      change('StateTaxAmount', taxesData?.StateTax?.toFixed(2)?.toString())
      change('CityTaxAmount', taxesData?.CityTax?.toFixed(2)?.toString())
      change('CountyTaxAmount', taxesData?.CountyTax?.toFixed(2)?.toString())
      change('MiscTaxAmount', taxesData?.MiscTax?.toFixed(2)?.toString())
      change('AmountSalesTax', taxesData?.TotalTax?.toFixed(2)?.toString())
      change('DocStampsAmount', taxesData?.DocStamps?.toFixed(2)?.toString())

      change('AmountPaymentAmount', calculationForDealData?.payment_amount?.toString())
      change('AmountFinalPayment', calculationForDealData?.final_payment_amount?.toString())
      change('AmountFinalTerm', calculationForDealData?.term?.toString())
      change('AmountFinanceCharges', calculationForDealData?.finance_charges?.toString())
      change('TotalOfPayments', calculationForDealData?.total_of_payments?.toString())
      change('APRRate', calculationForDealData?.apr_calculated?.toString())
      change('FinalPaymentAmount', calculationForDealData?.final_payment_amount?.toString())
      change('calculateTerm', calculationForDealData?.term?.toString())
      // change('DesiredPayment', calculationForDealData?.payment_amount?.toString())
      // change('DesiredTerm', calculationForDealData?.term?.toString())
    }
  }, [calculationForDealIsSuccess])

  useEffect(() => {
    if (maturityDateForDealIsSuccess) {
      change('MaturityDate', maturityDateForDealData?.MaturityDate?.toString())

      handleSetCalculatedValues()
    }
    if (taxesIsSuccess) {
      handleSetCalculatedValues()
    }
  }, [maturityDateForDealIsSuccess, taxesIsSuccess])

  const isCalculatedValueChangedMemorized = useMemo(() => compareFieldsOfObjects(values, calculatedValues), [values, calculatedValues])

  useEffect(() => {
    setDisableCalculateBtn && setDisableCalculateBtn(isCalculatedValueChangedMemorized)
  }, [isCalculatedValueChangedMemorized])

  const handleNext = () => {
    console.log('next clicked')
    if (onClick) {
      onClick()
      return
    }
    setDealStructureFormValues(values)
    setCurrentStage('dealResult')
    setCurrentStep((prev) => prev + 1)
  }

  return (
    <LoadingButton ref={forwardRef} endIcon={<ArrowForwardIosIcon />} loading={calculationForDealIsLoading} onClick={handleNext}>
      Next
    </LoadingButton>
  )
}

export default NextButton
