import React from 'react'
import { Grid, Typography, generateUtilityClasses, Tooltip, Button } from '@mui/material'
import { styled } from '@mui/system'
import { ReactComponent as CogIcon } from '../../../../../../assets/icons/cog_icon.svg'
import { ReactComponent as PapersIcon } from '../../../../../../assets/icons/papers_icon.svg'
import { UspGetDataFormResult } from '../../../../../../models'

const classes = generateUtilityClasses('Grid', ['root', 'titleWrap', 'btnWrap', 'iconWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
    justifyContent: 'space-between',
  },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    alignItems: 'center',
  },
  [`& .${classes.iconWrap}`]: {},
  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    color: theme.palette.text.primary,
  },
}))
interface Props {
  data?: UspGetDataFormResult
}
const Header = ({ data }: Props) => {
  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid item className={classes.titleWrap}>
          <Typography variant='h3'>Form Mapping - {data?.Name}</Typography>
        </Grid>

        <Grid item className={classes.iconWrap}>
          <Tooltip title='Copy'>
            <Button variant='text' type='button' color='success' className={classes.btnWrap}>
              <PapersIcon />
            </Button>
          </Tooltip>
          <Tooltip title='Settings'>
            <Button className={classes.btnWrap} variant='text' type='button' color='success'>
              <CogIcon />
            </Button>
          </Tooltip>
        </Grid>
      </StyledGrid>
    </>
  )
}

export default Header
