import {
  Box,
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAddIpAddressMutation, useEditIpAddressMutation, useLazyIpAddressDataQuery } from '../../../../services'
import { UspConfigurationIpAddressViewResult } from '../../../../models/apiTypes'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationIpAddressViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditIpAddressesModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addIPAddresse, { isLoading: isLoadingIPAddresse, isSuccess: isSuccessIPAddresse }] = useAddIpAddressMutation()
  const [editIPAddresse, { isLoading: isLoadingEditIPAddresse, isSuccess: isSuccessEditIPAddresse }] = useEditIpAddressMutation()
  const [getIPAddresseData, { isLoading: isLoadingGetIPAddresse, isSuccess: isSuccessGetIPAddresse, data }] = useLazyIpAddressDataQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editIPAddresse({
        ipAddressId: rowData?.ID?.toString(),
        uspConfigurationIpAddressAddEdit: {
          ipAddressStart: values.IPAddressRangeStart,
          ipAddressEnd: values.IPAddressRangeEnd,
          isActive: values.IsActive,
        },
      })
    } else {
      addIPAddresse({
        uspConfigurationIpAddressAddEdit: {
          ipAddressStart: values.IPAddressRangeStart,
          ipAddressEnd: values.IPAddressRangeEnd,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getIPAddresseData({
        ipAddressId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
      })
    }
    if (isSuccessGetIPAddresse && type === 'edit') {
      setInitialValues({
        IPAddressRangeStart: data?.IPAddressStart,
        IPAddressRangeEnd: data?.IPAddressEnd,
        IsActive: data?.IsActive,
      })
    }
  }, [isSuccessGetIPAddresse, data, type])

  useEffect(() => {
    if (isSuccessIPAddresse || isSuccessEditIPAddresse) {
      onClose()
      submit()
    }
  }, [isSuccessIPAddresse, isSuccessEditIPAddresse])

  const isLoading = useMemo(
    () => isLoadingIPAddresse || isLoadingEditIPAddresse || isLoadingGetIPAddresse,
    [isLoadingIPAddresse, isLoadingEditIPAddresse, isLoadingGetIPAddresse],
  )
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>IP Addresses</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='IPAddressRangeStart' label='IP Address Range (Start)' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField required name='IPAddressRangeEnd' label='IP Address Range (End)' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditIpAddressesModal
