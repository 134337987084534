import React, { useEffect, useMemo } from 'react'
import { AuthTemplate } from '../../components/AuthTemplate'
import signInBg from '../../assets/signIn-bg.png'
import { Typography, useTheme } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useLazyGetInvitationRegistrationQuery, useAcceptInvitationRegistrationMutation } from '../../services'
import { enqueueNotification } from '../../redux/slices/notificationsSlice'
import { signUpLabels, signUpValidation } from '../../validation'

import { appRoute } from '../../constants'
import { useDispatch } from 'react-redux'
import validateForm from '../../utils/validateForm'
import { TextField, PasswordField, CustomCircular } from '../../ui'
import { SignUpFormType } from '../../models'

export const SignUp = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { inviteID } = useParams()

  const [getInvitationRegistration, { isLoading: invitationRegistrationIsLoading }] = useLazyGetInvitationRegistrationQuery()
  const [acceptInvitationRegistration, { isSuccess: acceptInvitationRegistrationIsSuccess, isLoading: acceptInvitationRegistrationIsLoading }] =
    useAcceptInvitationRegistrationMutation()

  const handleSubmit = async (values: SignUpFormType): Promise<void> => {
    acceptInvitationRegistration({
      invitationId: inviteID as string,
      invitationRegistration: {
        email: values.email,
        password: values.password,
      },
    })
  }

  useEffect(() => {
    getInvitationRegistration({ invitationId: inviteID as string })
  }, [])

  useEffect(() => {
    if (acceptInvitationRegistrationIsSuccess) {
      dispatch(enqueueNotification({ message: 'Register Success' }))
      navigate(appRoute.SignIn)
    }
  }, [acceptInvitationRegistrationIsSuccess])

  const isLoading = useMemo(() => invitationRegistrationIsLoading, [invitationRegistrationIsLoading])

  return (
    <AuthTemplate>
      <Typography
        sx={{
          marginTop: 6,
          fontWeight: 500,
          fontSize: '40px',
          color: theme.palette.text.primary,
        }}
        textAlign='center'
      >
        Sign Up
      </Typography>
      <Typography sx={{ marginTop: 3, fontSize: '16px', color: theme.palette.text.secondary }} textAlign='center'>
        {/*Log in to your account.*/}
      </Typography>
      <Form onSubmit={handleSubmit} validate={validateForm(signUpValidation)}>
        {({ handleSubmit, submitting, invalid }: any) => (
          <form onSubmit={handleSubmit}>
            <TextField
              name='email'
              label={signUpLabels.email}
              fullWidth
              autoFocus
              sx={{ marginTop: 6 }}
              InputProps={{
                sx: { borderRadius: '8px' },
                id: 'userEmail',
                autoComplete: 'email',
              }}
            />
            <PasswordField
              sx={{ marginTop: 3, marginBottom: 1 }}
              InputProps={{
                sx: { borderRadius: '8px' },
                id: 'userPassword',
                autoComplete: 'password',
              }}
              label={signUpLabels.password}
              fullWidth
              name='password'
            />
            <PasswordField
              sx={{ marginTop: 3, marginBottom: 1 }}
              InputProps={{
                sx: { borderRadius: '8px' },
                id: 'userRepeatPassword',
              }}
              label={signUpLabels.confirmPassword}
              fullWidth
              name='confirmPassword'
            />
            <LoadingButton
              type='submit'
              sx={{
                borderRadius: '8px',
                background: '#1570EF',
                marginTop: 5,
                paddingY: '12px',
              }}
              color='primary'
              variant='contained'
              fullWidth
              disabled={invalid || submitting}
              loading={acceptInvitationRegistrationIsLoading}
            >
              Register
            </LoadingButton>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </AuthTemplate>
  )
}
