import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField, PhoneTextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getPhoneTypes, getStates } from '../../../../../redux/slices/userDropdownSlice'
import { useForm, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../services'
import AddressField from '../../../../../ui/AddressField'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const PreviousEmployerNameForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const { values } = useFormState()
  const states = useSelector(getStates)
  const form = useForm()
  const phoneTypes = useSelector(getPhoneTypes)
  const addressValue = form.getFieldState('EmploymentPreviousAddress1')?.value || ''
  const [getInfoByZipCode, { data: infoByZipCodeData }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue
      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('EmploymentPreviousAddress1', street)
      if (city) form.change('EmploymentPreviousCity', city)
      if (state) form.change('EmploymentPreviousState', state)
      if (zip) form.change('EmploymentPreviousPostalCode', zip)
      if (infoByZipCodeData) {
        form.change('EmploymentPreviousCounty', infoByZipCodeData?.County)
      }
    }
  }, [addressValue, form, infoByZipCodeData])

  return (
    <>
      <CardHeader title='Previous Employer (optional)' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentPreviousName' label='Name' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentPreviousOccupation' label='Occupation' fullWidth autoComplete='off' /> {/*dlNumber*/}
        </Grid>
        <Grid item xs={6}>
          <AddressField disabled={disableFields} name='EmploymentPreviousAddress1' label='Address Line 1' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentPreviousAddress2' label='Address Line 2' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField disabled={disableFields} name='EmploymentPreviousCity' label='City' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='EmploymentPreviousState'
            label='State'
            options={states}
            optionKey='State'
            optionValue='State'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField disabled={disableFields} name='EmploymentPreviousPostalCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={3}>
          <TextField disabled={disableFields} name='EmploymentPreviousCounty' label='County' options={states} fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='EmploymentPreviousPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneTextField disabled={disableFields} name='EmploymentPreviousPhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.EmploymentPreviousPhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField disabled={disableFields} name='EmploymentPreviousPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentPreviousWorkEmail' label='Work Email' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default PreviousEmployerNameForm
