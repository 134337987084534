import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Form } from 'react-final-form'
import { Header } from './components/Header'
import { FirstForm } from './components/FirstForm'
import { SecondForm } from './components/SecondForm'
import { Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getAccountingLedger, setAccountingLedgers } from '../../../redux/slices/accountingLedgersSlice'
import { useGetAccountingLedgerMutation } from '../../../services'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'fieldsWrap', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.fieldsWrap}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {}

export const AccountingDetails = ({}: Props) => {
  const [getAccountingLedgers, { data: accountingLedgerData }] = useGetAccountingLedgerMutation()
  const { accountingLedgerDetailID } = useParams()
  const dispatch = useDispatch()
  const accountingLedger = useSelector(getAccountingLedger(accountingLedgerDetailID))

  useEffect(() => {
    if (!accountingLedger) {
      getAccountingLedgers({
        uspAccountingLedgerView: {},
      })
    }
  }, [accountingLedger])

  useEffect(() => {
    if (accountingLedgerData) {
      dispatch(setAccountingLedgers(accountingLedgerData))
    }
  }, [accountingLedgerData])

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Ledger Details'
        links={[
          {
            name: '',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />

      <Grid container>
        <Grid item xs={12} md={10}>
          <Card
            sx={{
              flexGrow: { md: 1 },
              display: { md: 'flex' },
              flexDirection: { md: 'column' },
            }}
          >
            <Form onSubmit={() => {}} initialValues={accountingLedger}>
              {({ handleSubmit }: any) => (
                <form onSubmit={handleSubmit}>
                  {/* <Header /> */}
                  {/* <CardHeader title='Ledger Details' /> */}
                  <Grid container spacing={2} p={3}>
                    <Grid item xs={12}>
                      <FirstForm />
                    </Grid>
                    <Grid item xs={12}>
                      <SecondForm />
                    </Grid>
                  </Grid>
                </form>
              )}
            </Form>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AccountingDetails
