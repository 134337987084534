import React, { useEffect } from 'react'
import { useLazyGetAccountOverviewQuery } from '../../services'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getAccounts as getAccountsSelector, setAccounts } from '../../redux/slices/accountsSlice'

export function withAccounts(Component: any) {
  return function (props: any) {
    const { dealID } = useParams()
    const dispatch = useDispatch()
    const accounts = useSelector(getAccountsSelector(dealID))
    const [getAccountsOverview, { data: accountsData, isSuccess: accountsIsSuccess, isLoading: accountsIsLoading }] = useLazyGetAccountOverviewQuery()

    useEffect(() => {
      if (!accounts) {
        getAccountsOverview({ dealId: dealID as string })
      }
    }, [])

    useEffect(() => {
      if (accountsIsSuccess) {
        dispatch(setAccounts(accountsData))
      }
    }, [accountsIsSuccess])

    return (
      <Component
        getAccountsOverview={getAccountsOverview}
        accountsData={accountsData || accounts}
        accountsIsSuccess={accountsIsSuccess}
        accountsIsLoading={accountsIsLoading}
        {...props}
      />
    )
  }
}
