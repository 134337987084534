import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const SettingsForm = ({}: Props) => {
  const { values } = useFormState()
  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Settings</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessConfigurationSettings' label='Access Configuration Settings' />
      </Grid>
      {values.AccessConfigurationSettings && (
        <>
          <Grid>
            <CheckboxField name='AccessDealers' label='Access Dealers' />
          </Grid>
          <Grid>
            <CheckboxField name='AccessLenders' label='Access Lenders' />
          </Grid>
          <Grid>
            <CheckboxField name='AccessVendors' label='Access Vendors' />
          </Grid>
          <Grid>
            <CheckboxField name='AccessUsers' label='Access Users' />
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

export default SettingsForm
