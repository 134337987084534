import * as yup from 'yup'

export const addDealerValidation: yup.SchemaOf<any> = yup
  .object({
    ShortCode: yup.string().required().nullable().label('Short Code'),
    DiscountEarningMethod: yup.string().required().nullable().label('Discount Earning Method'),
    Name: yup.string().required().label('Name'),
    SalesBranch: yup.number().when('IsRelated', {
      is: true,
      then: yup.number().required().label('Sales Branch'),
      otherwise: yup.number().nullable(),
    }),
  })
  .required()
