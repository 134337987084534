import { Controller, useFormContext } from 'react-hook-form'

import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { RHFSelect, RHFTextField } from '../../../../components/hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Field, useForm, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { UspAccountingChartApAccountsView, VendorResponseSp } from '../../../../models'
import TextField from '../../../../ui/TextField'
import { DateField } from '../../../../ui'
import { useEffect } from 'react'
import { getCurrentDate } from '../../../../utils/general'

// ----------------------------------------------------------------------
interface Props {
  userAccess: any
  addEntryIsSuccess: any
  isFieldsLocked: any
}
export default function InvoiceNewEditStatusDate({ userAccess, addEntryIsSuccess, isFieldsLocked }: Props) {
  const form = useForm()
  useEffect(() => {
    if (addEntryIsSuccess) {
      form.change('EntryType', 'Ledger & Schedules')
      form.change('EntryDate', getCurrentDate())
    }
  }, [addEntryIsSuccess])

  let entryTypeOptions: any[]

  if (userAccess?.CanAddLedgerOnlyJournalEntry) {
    entryTypeOptions = [
      { key: 'Ledger & Schedules', value: 'Ledger & Schedules' },
      { key: 'Ledger Only', value: 'Ledger Only' },
    ]
  } else {
    entryTypeOptions = [{ key: 'Ledger & Schedules', value: 'Ledger & Schedules' }]
  }

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ p: 3, bgcolor: 'background.neutral' }}>
      <DropdownFieldSearch name='EntryType' fullWidth label='Entry Type' options={entryTypeOptions} disabled={isFieldsLocked} />

      <DateField name='EntryDate' label='Entry Date' fullWidth />
    </Stack>
  )
}
