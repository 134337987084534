import * as yup from 'yup'
import dayjs from 'dayjs'

const today = dayjs().endOf('day')

export const accountsAddNoteValidation = (tabValue: number) => {
  let schema = yup.object().shape({})

  if (tabValue === 1) {
    schema = schema.shape({
      Note: yup.string().label('Note').required(),
      PTPAmount: yup.string().when('isCreatePTP', {
        is: true,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
      PTPDueDate: yup
        .date()
        .label('PTP Due Date')
        .when('isCreatePTP', {
          is: true,
          then: yup.date().required().min(today, 'Prior date not allowed').typeError('Invalid Date'),
          otherwise: yup.date().notRequired(),
        }),
      followUpTypeID: yup
        .number()
        .label('Follow-up Type')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.number().required().nullable().typeError('Invalid Follow-up Type'),
          otherwise: yup.number().notRequired(),
        }),
      assignedToUserID: yup
        .number()
        .label('Assign To')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.number().required().nullable().typeError('Invalid Follow-up Type'),
          otherwise: yup.number().notRequired(),
        }),
      followUpDateTime: yup
        .date()
        .label('Follow-Up Date Time')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.date().required().min(today, 'Prior date not allowed').typeError('Invalid Follow-up Type'),
          otherwise: yup.date().notRequired(),
        }),
    })
  }
  if (tabValue === 4) {
    schema = schema.shape({
      Note: yup.string().label('Note').required('Message is a required field'),
      PTPAmount: yup.string().when('isCreatePTP', {
        is: true,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
      PTPDueDate: yup
        .date()
        .label('PTP Due Date')
        .when('isCreatePTP', {
          is: true,
          then: yup.date().required().min(today, 'Prior date not allowed').typeError('Invalid Date'),
          otherwise: yup.date().notRequired(),
        }),
      followUpTypeID: yup
        .number()
        .label('Follow-up Type')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.number().required().nullable().typeError('Invalid Follow-up Type'),
          otherwise: yup.number().notRequired(),
        }),
      assignedToUserID: yup
        .number()
        .label('Assign To')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.number().required().nullable().typeError('Invalid Follow-up Type'),
          otherwise: yup.number().notRequired(),
        }),
      followUpDateTime: yup
        .date()
        .label('Follow-Up Date Time')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.date().required().min(today, 'Prior date not allowed').typeError('Invalid Follow-up Type'),
          otherwise: yup.date().notRequired(),
        }),
      PhoneNumber: yup.string()
        .label('To').nullable()
        .required("Phone Number is required"),
    })
  }

  if (tabValue === 2) {
    schema = schema.shape({
      CallOutcome: yup
        .string()
        .label('Outcome')
        .when('CallCallType', {
          is: 'Outbound',
          then: yup.string().required('Outcome is required.').typeError('Invalid Input').nullable(),
          otherwise: yup.string().typeError('Invalid Input').typeError('Invalid Input').nullable(),
        }),
      CallNote: yup.string().label('Note').required().typeError('Invalid Input'),
      PTPAmount: yup.string().when('isCreatePTP', {
        is: true,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
      }),
      PTPDueDate: yup
        .date()
        .label('PTP Due Date')
        .when('isCreatePTP', {
          is: true,
          then: yup.date().required().min(today, 'Prior date not allowed').typeError('Invalid Date'),
          otherwise: yup.date().notRequired(),
        }),
      followUpTypeID: yup
        .number()
        .label('Follow-up Type')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.number().required().nullable().typeError('Invalid Follow-up Type'),
          otherwise: yup.number().notRequired(),
        }),
      assignedToUserID: yup
        .number()
        .label('Assign To')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.number().required().nullable().typeError('Invalid Follow-up Type'),
          otherwise: yup.number().notRequired(),
        }),
      followUpDateTime: yup
        .date()
        .label('Follow-Up Date Time')
        .when('isCreateFollowUp', {
          is: true,
          then: yup.date().required().min(today, 'Prior date not allowed').typeError('Invalid Follow-up Type'),
          otherwise: yup.date().notRequired(),
        }),
    })
  }

  return schema
}
