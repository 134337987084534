import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { AmountField, TextField } from '../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

const SettingsForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Settings' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <AmountField variant='standard' defaultValue='0' name='ReferralFee' label='Referral Fee' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IsW9Completed'
            label='Is W9 Completed?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField multiline rows={4} name='Note' label='Comments' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsForm
