import * as yup from "yup";
import { SignInFormType } from "../models";

export const signInLabels: SignInFormType = {
  email: "Email",
  password: "Password",
};

export const signInValidation: yup.SchemaOf<SignInFormType> = yup
  .object({
    email: yup.string().required().email().label(signInLabels.email),
    password: yup.string().required().label(signInLabels.password),
  })
  .required();
