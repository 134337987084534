import * as yup from 'yup'

export const accountingBillPayValidation = (checkID: number, stage: string) =>
  yup
    .object({
      BillPayType: yup.number().when([], () => {
        if (stage === 'bill-pay-summary') {
          return yup.number().required('Bill Pay Type is required').typeError('Invalid Input')
        }
        return yup.number().typeError('Invalid Input')
      }),
      BankBranch: yup.number().when([], () => {
        if (stage === 'bill-pay-summary') {
          return yup.number().required('Bank Branch is required').typeError('Invalid Input')
        }
        return yup.number().typeError('Invalid Input')
      }),
      CheckNumber: yup.string().when('BillPayType', {
        is: (billPayType: number) => billPayType === checkID && stage === 'bill-pay-summary',
        then: yup.string().required('Check Number is required').typeError('Invalid Input'),
        otherwise: yup.string().notRequired(),
      }),
    })
    .required()
