import { Form } from 'react-final-form'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import FilteringModal from './components/FilteringModal/FilteringModal'
import { useReportsAccountingPaymentsMutation } from '../../../services'
import { getCurrentDate } from '../../../utils/general'
import { convertDateToString } from '../../../utils/dateUtils'
import Header from './components/Header/Header'
import { UspReportPaymentResult } from '../../../models'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import AccountingPaymentsTableBlock from './components/AccountingPaymentsTableBlock/AccountingPaymentsTableBlock'
import validateFormValues from '../../../utils/validateForm'
import { accountingInsightsPaymentsValidation } from '../../../validation/accountingInsightsPaymentsValidation'
import { Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { appRoute } from '../../../constants'

const Payments = () => {
  const dispatch = useDispatch()
  const [showTable, setShowTable] = useState(false)
  const [openFilterModal, setOpenFilterModal] = useState(true)
  const [formValues, setFormValues] = useState<any>(null)
  const [filterPayments, { data: filterData, isSuccess: filterIsSuccess, isLoading: filterIsLoading }] = useReportsAccountingPaymentsMutation()

  const handleOpenFilterModal = () => setOpenFilterModal(true)
  const handleCloseFilterModal = () => setOpenFilterModal(false)

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.Branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    const jsonUserList = values?.Cashier?.map((item: any) => ({ ID: item?.UserID }))
    const jsonPaymentFormList = values?.PaymentForm?.map((item: any) => ({ ID: item?.ID }))
    const jsonPaymentMethodList = values?.PaymentMethod?.map((item: any) => ({ ID: item?.ID }))

    filterPayments({
      uspReportPayment: {
        asOfDate: getCurrentDate(),
        startDate: convertDateToString(values.StartDate) as string,
        endDate: convertDateToString(values.EndDate) as string,
        isFilterByCashierBranch: values.FilterByCashier,
        jsonUserList: jsonUserList || null,
        jsonBranchList: jsonBranchList || null,
        jsonPaymentFormList: jsonPaymentFormList || null,
        jsonPaymentMethodList: jsonPaymentMethodList || null,
      },
    })
    setFormValues(values)
  }

  useEffect(() => {
    if (filterIsSuccess) {
      handleCloseFilterModal()
      setShowTable(true)
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [filterIsSuccess])

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Reports'
        links={[
          {
            href: appRoute.Insights,
            name: 'List',
          },
          {
            name: 'Payments',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          height: { xs: 800, md: 2 },
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Form
          initialValues={{ FilterByCashier: false }}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          validate={validateFormValues(accountingInsightsPaymentsValidation(openFilterModal))}
        >
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              {showTable && <Header handleOpenFilterModal={handleOpenFilterModal} filterData={filterData as UspReportPaymentResult[]} />}
              <FilteringModal formValues={formValues} open={openFilterModal} handleClose={handleCloseFilterModal} />
              {showTable && <AccountingPaymentsTableBlock filterData={filterData as UspReportPaymentResult[]} isLoading={filterIsLoading} />}
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default Payments
