import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'
import removeMask from '../../../../../utils/removeMask'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { selectDropdownByName } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  disableFields: boolean
}

export const MonthlyExpensesForm = ({ disableFields }: Props) => {
  const { values } = useFormState()
  const { change } = useForm()
  const creditAppExpenseType = useSelector((state) => selectDropdownByName(state, 'CreditAppExpenseType'))

  useEffect(() => {
    const totalExpensesMemorized = `${
      (Number(removeMask(values?.IncomeMonthlyHousingExpense)) || 0) +
      (Number(removeMask(values?.IncomeCustomExpense1)) || 0) +
      (Number(removeMask(values?.IncomeCustomExpense2)) || 0) +
      (Number(removeMask(values?.IncomeCustomExpense3)) || 0) +
      (Number(removeMask(values?.IncomeCustomExpense4)) || 0) +
      (Number(removeMask(values?.IncomeCustomExpense5)) || 0) +
      (Number(removeMask(values?.IncomeOtherExpenses)) || 0)
    }`
    change('IncomeTotalExpenses', totalExpensesMemorized)
  }, [
    values?.IncomeMonthlyHousingExpense,
    values?.IncomeCustomExpense1,
    values?.IncomeCustomExpense2,
    values?.IncomeCustomExpense3,
    values?.IncomeCustomExpense4,
    values?.IncomeCustomExpense5,
    values?.IncomeOtherExpenses,
  ])

  return (
    <>
      <CardHeader title='Monthly Expenses (optional)' />

      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <AmountField variant='standard' disabled={disableFields} name='IncomeMonthlyHousingExpense' label='Housing' fullWidth />
        </Grid>
        {creditAppExpenseType?.ReturnData?.map((item, index) => (
          <Grid item xs={12} key={item?.ExpenseNumber}>
            <AmountField
              variant='standard'
              disabled={disableFields}
              name={`IncomeCustomExpense${index + 1}`}
              label={item?.ExpenseLabel as string}
              fullWidth
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <AmountField variant='standard' disabled={disableFields} name='IncomeOtherExpenses' label='Other Expenses' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default MonthlyExpensesForm
