import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField, PhoneTextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getPhoneTypes } from '../../../../../redux/slices/userDropdownSlice'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import { useForm, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../services'
import AddressField from '../../../../../ui/AddressField'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const InsuranceForm = ({}: Props) => {
  const states = useSelector(getStates)
  const phoneTypes = useSelector(getPhoneTypes)
  const { values } = useFormState()
  const form = useForm()
  const addressValue = form.getFieldState('Address1')?.value || ''
  const [getInfoByZipCode, { data: infoByZipCodeData }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue
      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('Address1', street)
      if (city) form.change('City', city)
      if (state) form.change('State', state)
      if (zip) form.change('PostalCode', zip)
      if (infoByZipCodeData) {
        form.change('County', infoByZipCodeData?.County)
      }
    }
  }, [addressValue, form, infoByZipCodeData])

  return (
    <>
      <CardHeader title='Insurance' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <TextField name='InsuranceCompany' required label='Insurance Company' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField name='InsuranceAgent' label='Insurance Agent' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <AddressField name='Address1' label='Address Line 1' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Address2' label='Address Line 2' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField name='City' label='City' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch name='State' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <TextField number name='PostalCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField name='County' label='County' options={states} fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='PhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Biz'
          />
        </Grid>
        <Grid item xs={values?.PhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='PhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.PhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField name='PhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='SecondaryPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue='Fax'
          />
        </Grid>
        <Grid item xs={values?.SecondaryPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='SecondaryPhoneNumber' label='Secondary Phone' fullWidth />
        </Grid>
        {values?.SecondaryPhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField name='SecondaryPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

export default InsuranceForm
