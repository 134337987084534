import * as yup from 'yup'
export const addExpensesLabels = {
  vendor: 'Vendor',
  carExpenseType: 'Expense Type',
  purchasePrice: 'Purchase Price',
  costAmount: 'Amount',
  sourceNumber: 'Number',
  description: 'Description',
}

export const addExpensesValidation = yup
  .object()
  .shape({
    vendorID: yup.string().required().label(addExpensesLabels.vendor),
    carExpenseType: yup.string().required().nullable().label(addExpensesLabels.carExpenseType),
    costAmount: yup.string().required().nullable().label(addExpensesLabels.costAmount),
    sourceNumber: yup.string().required().nullable().label(addExpensesLabels.sourceNumber),

    description: yup.string().when('carExpenseType', {
      is: 'Purchase',
      then: yup.string().nullable().label('Description (optional)'),
      otherwise: yup.string().required().label(addExpensesLabels.description),
    }),
  })
  .required()
