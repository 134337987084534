import removeMask from '../../../../utils/removeMask'

export const transformToFormValues = (values?: any) => {
  return {
    SupportEmail: values?.SupportEmail,
    ActiveSession: values?.ActiveSessionTime,
    TwoFactorAuthentication: values?.IsTwoFactorAuthentication,
    DisplayNames: values?.OrderNameBy,
    RequireApproval: values?.IsBillRequireApprovalToPay,
    MarkUpRate: values?.CarMarkupPercentage?.toString(),
    MarkUpAmount: values?.CarMarkupAmount as string,

    DaysUntilDealDead: values?.DaysAfterDealConsideredDead,
    AccrueCommissions: values?.IsAccrueSalesCommissionAtFinalizeDeal,
    CreateAccount: values?.IsCreateAccountNumberAtSaveDeal,
    RequireAllowance: values?.IsRequireAllowancePerTrade,
    ReduceNetProfit: values?.IsIncludeTradeLienInNetProfit,
    ReduceGrossProfit: values?.IsIncludeFloorplanInterestInGrossProfit,
    RebatePC: values?.IsAlwaysRebateInterestByMonths,
    EarnPC: values?.IsAlwaysCalculateInterestAsIB,
    CapPC: values?.IsCapInterestEarnedAtPaymentCollectedForPC,
    SatisfyPTPRate: values?.SatisfyPromiseRate?.toString(),
    ApllySideNotePayment: values?.IsApplySideNotePaymentTowardsPromise,
    ApplyPickup: values?.IsApplyPickupPaymentTowardsPromise,
    AutoAssignStockNumber: values?.IsAssignStockNumberFromVIN,
    NumberOfCharacters: values?.NumberOfCharactersToUseFromVIN,
  }
}

export const transformToRequestValues = (values?: any) => {
  return {
    supportEmail: values?.SupportEmail,
    activeSessionTime: values?.ActiveSession,
    isTwoFactorAuthentication: values?.TwoFactorAuthentication,
    orderNameBy: values?.DisplayNames,
    isBillRequireApprovalToPay: values?.RequireApproval,
    carMarkupPercentage: Number(values?.MarkUpRate),
    carMarkupAmount: removeMask(values?.MarkUpAmount) as string,

    daysAfterDealConsideredDead: values?.DaysUntilDealDead,
    isAccrueSalesCommissionAtFinalizeDeal: values?.AccrueCommissions,
    isCreateAccountNumberAtSaveDeal: values?.CreateAccount,
    isRequireAllowancePerTrade: values?.RequireAllowance,
    isIncludeTradeLienInNetProfit: values?.ReduceNetProfit,
    isIncludeFloorplanInterestInGrossProfit: values?.ReduceGrossProfit,
    isAlwaysRebateInterestByMonths: values?.RebatePC,
    isAlwaysCalculateInterestAsIB: values?.EarnPC,
    isCapInterestEarnedAtPaymentCollectedForPC: values?.CapPC,
    satisfyPromiseRate: Number(values?.SatisfyPTPRate),
    isApplySideNotePaymentTowardsPromise: values?.ApllySideNotePayment,
    isApplyPickupPaymentTowardsPromise: values?.ApplyPickup,
    isAssignStockNumberFromVIN: values?.AutoAssignStockNumber,
    numberOfCharactersToUseFromVIN: Number(values?.NumberOfCharacters),
  }
}
