import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { UspAccountingLedgerViewResult } from '../../models'

type CarState = {
  accountingLedgers?: UspAccountingLedgerViewResult[]
}

export const slice = createSlice({
  name: 'accountingLedgers',
  initialState: {
    accountingLedgers: [],
  } as CarState,
  reducers: {
    setAccountingLedgers: (state, { payload }) => {
      state.accountingLedgers = payload
    },
  },
})
export const getAccountingLedger =
  (AccountingLedgerDetailID: string | undefined): any =>
  (state: RootState) => {
    if (AccountingLedgerDetailID) {
      return state?.accountingLedgers?.accountingLedgers?.find(
        (accountingLedger: UspAccountingLedgerViewResult) =>
          accountingLedger?.AccountingLedgerDetailID?.toString() === AccountingLedgerDetailID?.toString(),
      )
    }
    return undefined
  }
export const { setAccountingLedgers } = slice.actions

export default slice.reducer
