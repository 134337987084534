import React, { useState } from 'react'
import {
  Box,
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspAccountingLedgerViewResult } from '../../../../../models'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import { visuallyHidden } from '@mui/utils'
import { formatNumber } from '../../../../../utils/general'

interface Props {
  accountingLedgerData?: UspAccountingLedgerViewResult[]
  loading: boolean
}

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'loading',
  'spinner',
  'tableHead',
  'tableCellName',
  'telegramIconActive',
  'telegramIconInactive',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.telegramIconActive}`]: {
    color: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

export const AccountingTableBlock = ({ accountingLedgerData, loading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('accounting_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('accounting_order_by') || 'TransactionNumber') as keyof UspAccountingLedgerViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspAccountingLedgerViewResult>(initialOrderBy)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const handleEdit = (id: number | string) => {
    if (id) {
      navigate(appRoute.AccountingDetails.replace(':accountingLedgerDetailID', String(id)))
    }
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspAccountingLedgerViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('accounting_order', isAsc ? 'desc' : 'asc')
    storageSet('accounting_order_by', property)
  }

  const createSortHandler = (property: keyof UspAccountingLedgerViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginatedData = accountingLedgerData

  return (
    <>
      <TableContainer id='pdfdiv' sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 170px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'TransactionNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'TransactionNumber'}
                  direction={orderBy === 'TransactionNumber' ? order : 'asc'}
                  onClick={createSortHandler('TransactionNumber')}
                >
                  Transaction
                  {orderBy === 'TransactionNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountingDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountingDate'}
                  direction={orderBy === 'AccountingDate' ? order : 'asc'}
                  onClick={createSortHandler('AccountingDate')}
                >
                  Date
                  {orderBy === 'AccountingDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'UserCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'UserCode'}
                  direction={orderBy === 'UserCode' ? order : 'asc'}
                  onClick={createSortHandler('UserCode')}
                >
                  User
                  {orderBy === 'UserCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountName' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountName'}
                  direction={orderBy === 'AccountName' ? order : 'asc'}
                  onClick={createSortHandler('AccountName')}
                >
                  Account
                  {orderBy === 'AccountName' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'TransactionAmount' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'TransactionAmount'}
                  direction={orderBy === 'TransactionAmount' ? order : 'asc'}
                  onClick={createSortHandler('TransactionAmount')}
                >
                  Amount
                  {orderBy === 'TransactionAmount' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Name' ? order : false}>
                <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                  Name
                  {orderBy === 'Name' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BillNumber'}
                  direction={orderBy === 'BillNumber' ? order : 'asc'}
                  onClick={createSortHandler('BillNumber')}
                >
                  Check/Bill #
                  {orderBy === 'BillNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'TransactionDescription' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'TransactionDescription'}
                  direction={orderBy === 'TransactionDescription' ? order : 'asc'}
                  onClick={createSortHandler('TransactionDescription')}
                >
                  Description
                  {orderBy === 'TransactionDescription' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Note' ? order : false}>
                <TableSortLabel active={orderBy === 'Note'} direction={orderBy === 'Note' ? order : 'asc'} onClick={createSortHandler('Note')}>
                  Note
                  {orderBy === 'Note' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item: any, index) => (
                <TableRow hover key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className={classes.tableCellName} onClick={() => handleEdit(item.AccountingLedgerDetailID)}>
                    {item.TransactionNumber}
                  </TableCell>
                  <TableCell>{convertDateToDisplay(item?.AccountingDate)}</TableCell>
                  <TableCell>{item?.UserCode}</TableCell>
                  <TableCell>{item?.BranchCode}</TableCell>
                  <TableCell>{item?.AccountName}</TableCell>
                  <TableCell>{formatNumber(item?.TransactionAmount)}</TableCell>
                  <TableCell>{item?.Name}</TableCell>
                  <TableCell>{item?.BillNumber}</TableCell>
                  <TableCell>{item?.StockNumber}</TableCell>
                  <TableCell>{item?.TransactionDescription}</TableCell>
                  <TableCell>{item?.Note}</TableCell>
                </TableRow>
              ))}
            {accountingLedgerData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {accountingLedgerData && accountingLedgerData?.length > 50 && (
        <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
          <Paper>
            <TablePagination
              component='div'
              count={accountingLedgerData?.length as number}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
    </>
  )
}
