import React, { useEffect, useState, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import { generateUtilityClasses, Grid, useTheme } from '@mui/material'
import Header from './components/Header/Header'
import MappedFieldsTableBlock from './components/MappedFieldsTableBlock/MappedFieldsTableBlock'
import FormMappingModal from './components/FormMappingModal/FormMappingModal'
import CustomCircular from '../../../../ui/CustomCircular'
import { TMappingData } from '../../../../models'
import { transformToFormValues } from './transform'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import validateFormValues from '../../../../utils/validateForm'

import {
  useAddFormMappingMutation,
  useEditFormMappingMutation,
  useLazyGetFormDataQuery,
  useLazyGetFormMappingDataQuery,
  useLazyGetFormTokenDataQuery,
} from '../../../../services'
import { addEditMappedFieldsValidation } from '../../../../validation/addEditMappedFieldsValidation'
import Button from '@mui/material/Button'
import { appRoute } from '../../../../constants'
import { styled } from '@mui/material/styles'

const classes = generateUtilityClasses('div', ['root', 'buttonWrap'])

const StyledForm = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
}))

const SettingsFormsMapping = () => {
  const { formId } = useParams()
  const dispatch = useDispatch()
  const theme = useTheme()
  const navigate = useNavigate()

  const [isEdit, setIsEdit] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [formMappingModalData, setFormMappingModalData] = useState<string | TMappingData | null>(null)
  const [openMappingModal, setOpenMappingModal] = useState(false)

  const [getFormData, { data: formData, isSuccess: formDataIsSuccess, isLoading: formDataIsLoading }] = useLazyGetFormDataQuery()
  const [getFormTokenData, { data: formTokenData, isLoading: formTokenDataIsLoading }] = useLazyGetFormTokenDataQuery()
  const [addFormMapping, { isSuccess: addFormMappingIsSuccess, isLoading: addFormMappingIsLoading }] = useAddFormMappingMutation()
  const [editFormMapping, { isSuccess: editFormMappingIsSuccess, isLoading: editFormMappingIsLoading }] = useEditFormMappingMutation()
  const [getFormMappingData, { data: formMappingData, isSuccess: formMappingDataIsSuccess, isLoading: formMappingIsLoading }] =
    useLazyGetFormMappingDataQuery()

  useEffect(() => {
    if (formId) {
      getFormData({ formId: formId.toString() })
    }
  }, [formId, getFormData])

  useEffect(() => {
    if (formDataIsSuccess && formData) {
      getFormTokenData({ dataSource: formData.DataSource })
    }
  }, [formDataIsSuccess, formData, getFormTokenData])

  useEffect(() => {
    if (addFormMappingIsSuccess || editFormMappingIsSuccess) {
      handleCloseMappingModal()
      dispatch(enqueueNotification({ message: 'Success' }))
      if (addFormMappingIsSuccess) {
        getFormData({ formId: formId?.toString() as string })
      }
    }
  }, [addFormMappingIsSuccess, editFormMappingIsSuccess, dispatch, formId, getFormData])

  useEffect(() => {
    if (openMappingModal && formMappingModalData && typeof formMappingModalData !== 'string') {
      getFormMappingData({ formMappingId: formMappingModalData.FormMappingID?.toString() })
    }
  }, [openMappingModal, formMappingModalData, getFormMappingData])

  useEffect(() => {
    if (formMappingDataIsSuccess) {
      setInitialValues(transformToFormValues(formMappingData, isEdit) as any)
    }
  }, [formMappingDataIsSuccess, formMappingData, openMappingModal, isEdit])

  const handleSubmit = (values?: any) => {
    const payload = isEdit
      ? {
          formMappingId: values?.ID?.toString(),
          uspFormMappingAddEdit: {
            formID: parseInt(formId as string) as number,
            fieldName: values?.FieldName,
            mappingType: values?.MappingType,
            value: values?.MappingType === 'Token' ? values?.Token : values?.FieldValue,
            isActive: values?.IsActive,
          },
        }
      : {
          uspFormMappingAddEdit: {
            formID: parseInt(formId as string) as number,
            fieldName: formMappingModalData as string,
            mappingType: values.MappingType,
            value: values.FieldValue,
            isActive: true,
          },
        }

    if (isEdit) {
      editFormMapping(payload)
    } else {
      addFormMapping(payload)
    }
  }

  const handleCloseMappingModal = () => {
    setOpenMappingModal(false)
    setIsEdit(false)
  }

  const isLoading = useMemo(
    () => addFormMappingIsLoading || editFormMappingIsLoading || formTokenDataIsLoading || formDataIsLoading || formMappingIsLoading,
    [addFormMappingIsLoading, editFormMappingIsLoading, formDataIsLoading, formMappingIsLoading],
  )

  const notMappedFieldsData = formData?.NotMappedFields
  const mappedFieldsData = formData?.MappingData

  // Sort Mapped Fields Data
  const sortedMappedFieldsData = [...(mappedFieldsData ?? [])]?.sort((a, b) => a.FieldName.localeCompare(b.FieldName))

  // Sort Unmapped Fields Data (since it's an array of strings)
  const sortedNotMappedFieldsData = [...(notMappedFieldsData ?? [])]?.sort((a, b) => a.localeCompare(b))

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit} validate={validateFormValues(addEditMappedFieldsValidation)}>
      {({ handleSubmit }) => (
        <StyledForm className={classes.root}>
          <FormMappingModal
            addFormMappingIsSuccess={addFormMappingIsSuccess}
            editFormMappingIsSuccess={editFormMappingIsSuccess}
            formTokenData={formTokenData}
            handleSubmit={handleSubmit}
            formMappingModalData={formMappingModalData || ''}
            open={openMappingModal}
            onClose={handleCloseMappingModal}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
          <Header data={formData} />
          <Grid container>
            <Grid item xs={5}>
              <MappedFieldsTableBlock
                tableType='unmapped'
                setFormMappingModalData={setFormMappingModalData}
                setOpenMappingModal={setOpenMappingModal}
                tableData={sortedNotMappedFieldsData || []}
                tableTitle='Unmapped Fields'
              />
            </Grid>
            <Grid item xs={5}>
              <MappedFieldsTableBlock
                getFormData={getFormData}
                setIsEdit={setIsEdit}
                tableType='mapped'
                setFormMappingModalData={setFormMappingModalData}
                setOpenMappingModal={setOpenMappingModal}
                tableData={sortedMappedFieldsData || []}
                tableTitle='Mapped Fields'
              />
            </Grid>
          </Grid>
          <CustomCircular open={isLoading} />
          <Grid item xs={10} className={classes.buttonWrap}>
            <Button variant='contained' color='error' onClick={() => navigate(appRoute.SettingsFormsList)}>
              Close
            </Button>
          </Grid>
        </StyledForm>
      )}
    </Form>
  )
}

export default SettingsFormsMapping
