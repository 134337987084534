import { generateUtilityClasses, Grid, Modal, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useForm, useFormState } from 'react-final-form'
import { TextField } from '../../../../../../ui'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import { TMappingData } from '../../../../../../models'

const classes = generateUtilityClasses('form', ['root', 'row', 'buttonWrap', 'saveBtn', 'formulaStyle'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.formulaStyle}`]: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
}))

type TParsedTokenData = {
  Token: string
  DataType: string
}

interface Props {
  rowData?: any
  open: boolean
  onClose: () => void
  formMappingModalData: string | TMappingData
  handleSubmit: any
  formTokenData: any
  addFormMappingIsSuccess: boolean
  editFormMappingIsSuccess: boolean
  isEdit: boolean
  setIsEdit: Dispatch<SetStateAction<boolean>>
}
const FormMappingModal = ({
  isEdit,
  addFormMappingIsSuccess,
  editFormMappingIsSuccess,
  formTokenData,
  handleSubmit,
  open,
  onClose,
  formMappingModalData,
  setIsEdit,
}: Props) => {
  const { values } = useFormState()
  const form = useForm()

  const [formula, setFormula] = useState('')
  const [areOperationsDisabled, setAreOperationsDisabled] = useState(true)

  const handleFormulaButtonClick = (symbol: string) => {
    // Append the symbol to the formula and update the states
    const newFormula = `${formula} ${symbol} `
    setFormula(newFormula)
    form.change('FieldValue', newFormula)
  }

  const handleTokenSelect = (token: string) => {
    if (token) {
      // Append the token wrapped in {} to the formula, enable operation buttons
      const newFormula = values.MappingType === 'Formula' ? `${formula}{${token}}` : `${formula}${token}`
      setFormula(newFormula)
      form.change('FieldValue', newFormula)
      setAreOperationsDisabled(false)
    }
  }

  const handleDeleteLast = () => {
    // Remove the last token or operator from the formula
    const tokens = formula.trim().split(/\s+/)
    tokens.pop() // Remove the last element
    const newFormula = tokens.join(' ')
    setFormula(newFormula)
    form.change('FieldValue', newFormula)
  }

  useEffect(() => {
    setFormula('')
    setAreOperationsDisabled(values.MappingType !== 'Formula')
    if (!isEdit) {
      form.change('FieldValue', '')
    }
  }, [values.MappingType, form])

  const parsedFormTokenData = useMemo(() => {
    if (!formTokenData) return null
    const parsedData = JSON?.parse(formTokenData?.ReturnData)

    // Filter tokens based on MappingType value
    if (values.MappingType === 'Formula') {
      return parsedData?.filter((item: TParsedTokenData) => item?.DataType === 'int' || item?.DataType === 'float' || item?.DataType === 'money')
    } else {
      // When MappingType is not 'Formula', return all tokens without filtering
      return parsedData
    }
  }, [formTokenData, values.MappingType])

  useEffect(() => {
    if (addFormMappingIsSuccess || editFormMappingIsSuccess) {
      form.reset()
      setIsEdit(false)
    }
  }, [addFormMappingIsSuccess, editFormMappingIsSuccess])

  return (
    <Modal open={open} onClose={onClose}>
      <StyledBox className={classes.root}>
        <Grid>
          <Typography variant='h3'>{typeof formMappingModalData === 'string' ? formMappingModalData : formMappingModalData?.FieldName}</Typography>
        </Grid>

        <Grid className={classes.row}>
          <DropdownFieldSearch
            name='MappingType'
            label='Mapping Type'
            options={[
              { key: 'Token', values: 'Token' },
              { key: 'Formula', values: 'Formula' },
              { key: 'Fixed Value', values: 'Fixed Value' },
              { key: 'Prompt', values: 'Prompt' },
            ]}
            optionKey='key'
            optionValue='values'
          />
        </Grid>
        {values.MappingType !== 'Fixed Value' && values.MappingType !== 'Prompt' && (
          <Grid className={classes.row}>
            <DropdownFieldSearch
              name='Token'
              label='Token'
              options={parsedFormTokenData}
              optionKey='Token'
              optionValue='Token'
              onChange={handleTokenSelect}
            />
          </Grid>
        )}

        {values.MappingType === 'Formula' && (
          <Grid className={classes.row}>
            {/* Render buttons for formula operations */}
            {['+', '-', '*', '/', '(', ')'].map((symbol) => (
              <Button sx={{ fontSize: '25px' }} key={symbol} onClick={() => handleFormulaButtonClick(symbol)} disabled={areOperationsDisabled}>
                {symbol}
              </Button>
            ))}
            <Button sx={{ fontSize: '25px' }} onClick={handleDeleteLast} disabled={areOperationsDisabled || formula === ''}>
              <DeleteIcon />
            </Button>
          </Grid>
        )}
        {values.MappingType !== 'Token' && (
          <Grid className={classes.row}>
            <TextField
              name='FieldValue'
              label={values.MappingType === 'Formula' ? 'Formula' : 'Value'}
              fullWidth
              className={values?.MappingType === 'Formula' && classes.formulaStyle}
              multiline
            />
          </Grid>
        )}

        <Grid className={classes.buttonWrap} container>
          <Grid item>
            <LoadingButton onClick={handleSubmit} type='button' className={classes.saveBtn} variant='contained'>
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </StyledBox>
    </Modal>
  )
}

export default FormMappingModal
