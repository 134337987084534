import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField, PhoneTextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getPhoneTypes } from '../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import { userLabels } from '../../../../../validation'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const ContactForm = ({}: Props) => {
  const { values } = useFormState()
  const phoneTypes = useSelector(getPhoneTypes)

  return (
    <>
      <CardHeader title='Contact (optional)' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='ApplicantPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue={values?.ApplicantAccountType === 'individual' ? 'Cell' : 'Biz'}
          />
        </Grid>
        <Grid item xs={values?.ApplicantPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='ApplicantPhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.ApplicantPhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField name='ApplicantPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='ApplicantSecondaryPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            initialValue={values?.ApplicantAccountType === 'individual' ? 'Home' : 'Cell'}
          />
        </Grid>
        <Grid item xs={values?.ApplicantSecondaryPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField name='ApplicantSecondaryPhoneNumber' label='Secondary Phone' fullWidth />
        </Grid>
        {values?.ApplicantSecondaryPhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField name='ApplicantSecondaryPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField name='ApplicantEmail' label={userLabels.email} fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default ContactForm
