import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, generateUtilityClasses } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import Typography from '@mui/material/Typography'
import { AmountField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { selectDropdownByNameIsSalespersonOnly, useAddDealCommissionMutation, useEditDealCommissionMutation } from '../../../../../services'
import { useParams } from 'react-router-dom'
import { transformToFormValues, transformToRequestValues } from './transform'
import validateFormValues from '../../../../../utils/validateForm'
import { dealCommissionAddEditValidation } from '../../../../../validation/dealCommissionAddEditValidation'
import { useSelector } from 'react-redux'

const classesBox = generateUtilityClasses('Container', ['root', 'title', 'row', 'btnWrap', 'icon'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '287px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.title}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.row}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.btnWrap}`]: {
    justifyContent: 'space-between',
  },
  [`& .${classesBox.icon}`]: {
    margin: theme.spacing(3, 0),
  },
}))

interface Props {
  anchorEl: HTMLButtonElement | EventTarget | HTMLLIElement | null
  setAnchorEl: (value: HTMLButtonElement | null) => void
  rowData?: any
  type: string
  onSuccessCommissionDeal?: () => void
  onSuccessEditCommissionDeal?: () => void
}

const DealsCommissionsAddEdit = ({ anchorEl, setAnchorEl, rowData, type, onSuccessCommissionDeal, onSuccessEditCommissionDeal }: Props) => {
  const { dealID } = useParams()
  const open = Boolean(anchorEl)
  const [initialValues, setInitialValues] = useState({})
  const [addCommissionDeal, { isSuccess: isSuccessCommissionDeal }] = useAddDealCommissionMutation()
  const [editCommissionDeal, { isSuccess: isSuccessEditCommissionDeal }] = useEditDealCommissionMutation()
  const salesPerson = useSelector((state) => selectDropdownByNameIsSalespersonOnly()(state, 'UserAll'))

  useEffect(() => {
    setInitialValues(transformToFormValues(rowData, salesPerson))
  }, [open])

  useEffect(() => {
    if (isSuccessCommissionDeal) {
      if (onSuccessCommissionDeal) {
        onSuccessCommissionDeal()
      }
      handleClose()
    }
  }, [isSuccessCommissionDeal])

  useEffect(() => {
    if (isSuccessEditCommissionDeal) {
      if (onSuccessEditCommissionDeal) {
        onSuccessEditCommissionDeal()
      }
      handleClose()
    }
  }, [isSuccessEditCommissionDeal])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleSubmit = (values: any) => {
    if (type === 'add') {
      addCommissionDeal({
        dealId: dealID as string,
        uspDealCommissionAddEdit: transformToRequestValues(values) as any,
      })
    } else {
      editCommissionDeal({
        dealId: dealID as string,
        commissionId: rowData?.CommissionID as string,
        uspDealCommissionAddEdit: transformToRequestValues(values) as any,
      })
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(dealCommissionAddEditValidation)}>
        {({ handleSubmit, valid }: any) => (
          <StyledBox onSubmit={handleSubmit} className={classesBox.root}>
            <Typography variant='h3' className={classesBox.title}>
              Commission
            </Typography>
            {salesPerson && (
              <Grid className={classesBox.row}>
                <DropdownFieldSearch
                  disabled={type !== 'add' ? true : false}
                  name='Salesperson'
                  label='Salesperson'
                  options={salesPerson}
                  optionKey='UserID'
                  optionValue='Name'
                />
              </Grid>
            )}
            <Grid className={classesBox.row}>
              <AmountField variant='standard' name='CommissionAmount' label='Commission Amount' fullWidth autoComplete='off' />
            </Grid>
            <Grid className={classesBox.btnWrap} container>
              <Button variant='contained' color='error' onClick={handleClose} tabIndex={1}>
                Cancel
              </Button>
              <Button variant='contained' type='submit' disabled={!valid}>
                Save
              </Button>
            </Grid>
          </StyledBox>
        )}
      </Form>
    </Dialog>
  )
}

export default DealsCommissionsAddEdit
