import { converToUtc, convertDateToString } from "../../../../utils/dateUtils"
import removeMask from "../../../../utils/removeMask"

export const transformToFormValues = (): any => ({})

export const transformToRequestValues = (values?: any, dealID?: any, tabValue?: any, leadID?: string,textData? :any): any => {
  console.log(values)
  return {
    dealID: dealID && dealID ,
    creditAppID: leadID && leadID,
    note: tabValue === 2 ?values.CallNote: values.Note  ,
    isCreatePTP: values.isCreatePTP,
    ptpAmount: removeMask(values.PTPAmount),
    noteTypeID: tabValue,
    ptpDueDate: convertDateToString(values.PTPDueDate),
    contactID: values.note,
    callOutcomeID: values.CallOutcome,
    isInbound: values.CallCallType === 'Inbound' ? true : false,
    phonevalues: values.note,
    sentToEmails: values.note,
    replyToEmail: values.note,
    sentByEmail: values.note,
    emailSubject: values.note,
    followUpDateTime: converToUtc(values.followUpDateTime),
    isCreateFollowUp: values.isCreateFollowUp,
    followUpTypeID: values.followUpTypeID,
    assignedToUserID: values.assignedToUserID,
    twilioPhoneNumber: tabValue === 4?textData.PhoneNumber:null,
    phoneNumber: tabValue === 4 ? values.PhoneNumber :null
  }
}
