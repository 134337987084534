import { converToUtc, convertDateToString } from '../../../../../utils/dateUtils'
import removeMask from '../../../../../utils/removeMask'

export const transformToFormValues = (): any => ({})

export const transformToRequestValues = (values?: any, dealID?: any, tabValue?: any, leadID?: string): any => {
  return {
    dealID: dealID as string,
    creditAppID: leadID && leadID,
    note: tabValue === 1 ? values.Note : values.CallNote,
    isCreatePTP: values.isCreatePTP,
    ptpAmount: removeMask(values.PTPAmount),
    noteTypeID: tabValue,
    ptpDueDate: convertDateToString(values.PTPDueDate),
    contactID: values.note,
    callOutcomeID: values.CallOutcome,
    isInbound: values.CallCallType === 'Inbound' ? true : false,
    phonevalues: values.note,
    sentToEmails: values.note,
    replyToEmail: values.note,
    sentByEmail: values.note,
    emailSubject: values.note,
    followUpDateTime: converToUtc(values.followUpDateTime),
    isCreateFollowUp: values.isCreateFollowUp,
    followUpTypeID: values.followUpTypeID,
    assignedToUserID: values.assignedToUserID,
  }
}
