import { ReactElement } from 'react'
import { Box, Grid, Paper, Typography } from '@mui/material'
import InfoCardLoading from './InfoCardLoading'

type CardRow = {
  title: string
  value?: string | JSX.Element | null | number
}

type Props = {
  data: CardRow[]
  action?: ReactElement
  info?: ReactElement
  heading: string
  isLoading?: boolean
}

const InfoCard = ({ data, action, info, heading, isLoading }: Props) => {
  return (
    <Paper
      sx={{
        padding: 2,
      }}
    >
      {!isLoading && data && (
        <>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Box display='flex' gap={2} alignItems='center'>
              {info && info}
              <Typography variant='h6'>{heading}</Typography>
            </Box>
            {action && action}
            {!action && <Box />}
          </Grid>

          <Grid container sx={{ marginTop: 1 }} rowSpacing={1}>
            {data.map(({ title, value }, i) => {
              return (
                value && (
                  <Grid item container key={i} columnSpacing={2}>
                    {!!title.length && (
                      <Grid item xs={5}>
                        <Typography variant='body1'>{title}:</Typography>
                      </Grid>
                    )}
                    <Grid item>
                      {typeof value === 'string' && <Typography variant='body1'>{value}</Typography>}
                      {typeof value !== 'string' && value}
                    </Grid>
                  </Grid>
                )
              )
            })}
          </Grid>
        </>
      )}

      {isLoading && <InfoCardLoading />}
    </Paper>
  )
}

export default InfoCard
