import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField, TextField } from '../../../../../ui'
import AmountField from '../../../../../ui/AmountField'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  disableFields: boolean
}

const FeeSettingsCard = ({ disableFields }: Props) => {
  const { values } = useFormState()
  return (
    <StyledGrid className={classes.root}>
      <Grid className={classes.row}>
        <TextField disabled={disableFields} name='LateGraceDays' label='Late Grace Days' fullWidth autoComplete='off' />
      </Grid>
      <Grid className={classes.row}>
        <TextField disabled={disableFields} name='LateChargeRate' label='Late Charge Rate (%)' fullWidth />
      </Grid>

      <Grid className={classes.row}>
        <AmountField variant='standard' disabled={disableFields} name='LateChargeMax' label='Late Charge Max ($)' fullWidth />
      </Grid>

      <Grid className={classes.row}>
        <AmountField variant='standard' disabled={disableFields} name='LateChargeMin' label='Late Charge Min ($)' fullWidth />
      </Grid>

      <Grid className={classes.row}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='StopAccruingLateFees'
          label='Stop Accruing Late Fees?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          optionKey='key'
          optionValue='value'
          autoComplete='off'
          fullWidth
        />
      </Grid>
      {values?.StopAccruingLateFees && (
        <Grid className={classes.row}>
          <DateField disabled={disableFields} name='StopAccruingLateFeeAsOfDate' label='Stop Accruing Late Fee As Of Date' fullWidth />
        </Grid>
      )}
      <Grid className={classes.row}>
        <AmountField variant='standard' disabled={disableFields} name='ChargebackFee' label='Chargeback Fee ($)' fullWidth />
      </Grid>
    </StyledGrid>
  )
}

export default FeeSettingsCard
