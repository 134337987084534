import React from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { withPermissionsDropdown } from '../../../../../hoc'
import { Details } from '../Details'
import { UspAccountingLedgerViewResult } from '../../../../../models'

const classes = generateUtilityClasses('Container', ['root', 'wrap', 'typographyWrap'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.wrap}`]: {
    margin: theme.spacing(0, 2, 0, 2),
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  [`& .${classes.typographyWrap}`]: {
    minWidth: '200px',
  },
}))

interface Props {
  accountingLedgerData?: UspAccountingLedgerViewResult[]
  getAccountingLedgers: any
  isSuccess: boolean
}

export const Header = ({ accountingLedgerData, getAccountingLedgers, isSuccess }: Props) => {
  return (
    <Grid container justifyContent={'flex-end'}>
      <Details isSuccess={isSuccess} accountingLedgerData={accountingLedgerData} getAccountingLedgers={getAccountingLedgers} />
    </Grid>
  )
}

export default withPermissionsDropdown(Header)
