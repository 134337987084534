import { Button, generateUtilityClasses, Grid, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../constants'
import { useDeleteDealTradeMutation, useGetTaskDealQuery, useLazyGetDealTradeQuery, useLazyGetTaskDealQuery } from '../../../services'
import DealsTradeAdd from './components/DealsTradeAdd/DealsTradeAdd'
import DealsTradeTableBlock from './components/DealsTradeTableBlock/DealsTradeTableBlock'
import Header from './components/Header/Header'
import TradesCardDetails from './components/TradesCardDetails/TradesCardDetails'
import { useDispatch } from 'react-redux'
import { setDiscrepancyData } from '../../../redux/slices/dealsTradeSlice'
import { DeleteDealTradeApiArg } from '../../../models'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
const classes = generateUtilityClasses('Grid', [
  'root',
  'tradesCardContainer',
  'dealsTradeAddContainer',
  'dealsTradeTableContainer',
  'buttonWrap',
  'wrapper',
])

const StyledContainer = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.tradesCardContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  [`& .${classes.dealsTradeAddContainer}`]: {
    width: '100%',
    margin: '24px 0',
  },
  [`& .${classes.dealsTradeTableContainer}`]: {
    width: '100%',
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    // padding: theme.spacing(2, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.wrapper}`]: {
    padding: theme.spacing(2),
  },
}))

interface TradeData {
  StockNumber: string
  Vehicle: string
  VIN: string | null
  ACV: string
  Allowance: string
  LienAmount: string
  Lienholder: string
  CarID: number
  TradeID: number
}

interface CardTypes {
  TradeAllowance: string
  TradeLien: string
  TradeACV: string
}

const DealsTrade = () => {
  const { dealID } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [getTaskDeal, { data: taskDeal }] = useLazyGetTaskDealQuery()
  const [getDealTrade, { data: dealTradeData, isLoading }] = useLazyGetDealTradeQuery()
  const { data: taskDealData } = useGetTaskDealQuery({ dealId: dealID as string })
  const [deleteDealTrade, { isSuccess: deleteDealTradeIsSuccess }] = useDeleteDealTradeMutation()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ tradeId: string } | undefined>(undefined)
  const [tradesEntered, setTradesEntered] = useState<CardTypes>({
    TradeAllowance: '0.0000',
    TradeLien: '0.0000',
    TradeACV: '0.0000',
  })
  const [discrepancy, setDiscrepancy] = useState<CardTypes>({
    TradeAllowance: '0.0000',
    TradeLien: '0.0000',
    TradeACV: '0.0000',
  })

  function calculateSums(tradeDataArray: TradeData[]) {
    let tradeAllowance = 0
    let tradeLien = 0
    let tradeACV = 0

    tradeDataArray?.forEach((tradeData) => {
      tradeAllowance += parseFloat(tradeData.Allowance)
      tradeLien += parseFloat(tradeData.LienAmount)
      tradeACV += parseFloat(tradeData.ACV)
    })

    return {
      TradeAllowance: tradeAllowance.toFixed(4),
      TradeLien: tradeLien.toFixed(4),
      TradeACV: tradeACV.toFixed(4),
    }
  }

  function calculateDiscrepancy(tradesOnDeal: CardTypes, tradesEntered: CardTypes): CardTypes {
    return {
      TradeAllowance: (parseFloat(tradesOnDeal.TradeAllowance) - parseFloat(tradesEntered.TradeAllowance)).toFixed(4),
      TradeLien: (parseFloat(tradesOnDeal.TradeLien) - parseFloat(tradesEntered.TradeLien)).toFixed(4),
      TradeACV: (parseFloat(tradesOnDeal.TradeACV) - parseFloat(tradesEntered.TradeACV)).toFixed(4),
    }
  }

  useEffect(() => {
    getDealTrade({
      dealId: dealID as string,
    })
    getTaskDeal({ dealId: dealID as string })
  }, [])

  useEffect(() => {
    if (dealTradeData) {
      const result = calculateSums(dealTradeData as any)
      setTradesEntered(result)
    }
  }, [dealTradeData])

  useEffect(() => {
    if (taskDealData) {
      const discrepancy = calculateDiscrepancy(
        {
          TradeAllowance: taskDealData?.DealTradeAllowance as string,
          TradeLien: taskDealData?.DealTradeLien as string,
          TradeACV: taskDealData?.DealTradeACV as string,
        },
        tradesEntered,
      )

      setDiscrepancy(discrepancy)
      dispatch(setDiscrepancyData(discrepancy)) // Dispatch the action to update the Redux store
    }
  }, [taskDealData, tradesEntered, dispatch])

  useEffect(() => {
    if (deleteDealTradeIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      getDealTrade({
        dealId: dealID as string,
      })
    }
  }, [deleteDealTradeIsSuccess])

  const handleDelete = () => {
    deleteDealTrade(deleteConfirmDialog as DeleteDealTradeApiArg)
    setDeleteConfirmDialog(undefined)
  }

  return (
    <Form onSubmit={() => {}} initialValues={{ vinAdd: '' }}>
      {({ handleSubmit }: any) => (
        <form onSubmit={handleSubmit}>
          <StyledContainer container className={classes.root}>
            <Header taskDeal={taskDeal} />
            <Grid container columnGap={2} rowGap={2} className={`${classes.tradesCardContainer}  ${classes.wrapper}`}>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <TradesCardDetails
                  dealTradeACV={taskDealData?.DealTradeACV as string}
                  dealTradeAllowance={taskDealData?.DealTradeAllowance as string}
                  dealTradeLien={taskDealData?.DealTradeLien as string}
                  cardTitle='Trades on Deal'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <TradesCardDetails
                  dealTradeACV={tradesEntered?.TradeACV as string}
                  dealTradeAllowance={tradesEntered?.TradeAllowance as string}
                  dealTradeLien={tradesEntered?.TradeLien as string}
                  cardTitle='Trades Entered'
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                <TradesCardDetails
                  dealTradeACV={discrepancy.TradeACV}
                  dealTradeAllowance={discrepancy.TradeAllowance}
                  dealTradeLien={discrepancy.TradeLien}
                  cardTitle='Discrepancy'
                />
              </Grid>
            </Grid>
            <Grid container className={classes.wrapper}>
              <Grid item className={classes.dealsTradeAddContainer}>
                <DealsTradeAdd />
              </Grid>
              <Grid item className={classes.dealsTradeTableContainer}>
                <DealsTradeTableBlock deleteDealTrade={setDeleteConfirmDialog} dealsTradeData={dealTradeData} loadingDealsTradeData={isLoading} />
                <ConfirmDialog
                  open={!!deleteConfirmDialog}
                  onOk={handleDelete}
                  handleClose={() => setDeleteConfirmDialog(undefined)}
                  notification={'Remove trade from deal?'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className={`${classes.buttonWrap} ${classes.wrapper}`}>
              <Button variant='contained' color='error' onClick={() => navigate(appRoute.DealsTask.replace(':dealID', dealID as string))}>
                Close
              </Button>
            </Grid>
          </StyledContainer>
        </form>
      )}
    </Form>
  )
}

export default DealsTrade
