import { Box } from "@mui/system";
import React from "react";
type Props = {};

function Dashboard({}: Props) {
  return (
    <Box sx={{ display: "flex", minHeight: "100vh", background: "#F5F7FA" , gap:"20px"}}>
   <Box sx={{ flex: 1, background: "#FFFFFF" }}>
      <Box sx={{ paddingX: 8, paddingTop: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            1
          </Box>
        </Box>
      </Box>
    </Box>
    <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1, background: "#FFFFFF", flexDirection: "column", gap:"20px" }}>
      <Box sx={{minHeight: "50vh", gap:"20px", flex: 0.5}}>4</Box>
      <Box sx={{minHeight: "50vh"}}>2</Box>
    </Box>
  </Box>
  )
}

export default Dashboard;
