import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAddCarExpenseMutation, useEditCarExpenseMutation, useLazyCarExpenseDataQuery } from '../../../../services'
import { UspConfigurationCarExpenseTypeView } from '../../../../models/apiTypes'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationCarExpenseTypeView
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}

const AddEditExpenseTypesModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addCarExpenseType, { isLoading: isLoadingCarExpenseType, isSuccess: isSuccessCarExpenseType }] = useAddCarExpenseMutation()
  const [editCarExpenseType, { isLoading: isLoadingEditCarExpenseType, isSuccess: isSuccessEditCarExpenseType }] = useEditCarExpenseMutation()
  const [getCarExpenseTypeData, { isLoading: isLoadingGetCarExpenseType, isSuccess: isSuccessGetCarExpenseType, data }] = useLazyCarExpenseDataQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editCarExpenseType({
        carExpenseId: rowData?.ID?.toString(),
        uspConfigurationCarExpenseTypeAddEdit: {
          expenseType: values.Description,
          isActive: values.IsActive,
          isIncludeInPurchasePrice: values.IncludeInPurchasePrice,
          isAvailableForUnsoldInventory: values.AvailableForUnSoldInventory,
          isAvailableForSoldInventory: values.AvailableForSoldInventory,
        },
      })
    } else {
      addCarExpenseType({
        uspConfigurationCarExpenseTypeAddEdit: {
          expenseType: values.Description,
          isActive: values.IsActive,
          isIncludeInPurchasePrice: values.IncludeInPurchasePrice,
          isAvailableForUnsoldInventory: values.AvailableForUnSoldInventory,
          isAvailableForSoldInventory: values.AvailableForSoldInventory,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getCarExpenseTypeData({
        carExpenseId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
        IncludeInPurchasePrice: false,
        AvailableForSoldInventory: true,
        AvailableForUnSoldInventory: true,
      })
    }
    if (isSuccessGetCarExpenseType && type === 'edit') {
      setInitialValues({
        Description: data?.ExpenseType,
        IsActive: data?.IsActive,
        IncludeInPurchasePrice: data?.IsIncludeInPurchasePrice,
        AvailableForSoldInventory: data?.IsAvailableForSoldInventory,
        AvailableForUnSoldInventory: data?.IsAvailableForUnsoldInventory,
      })
    }
  }, [isSuccessGetCarExpenseType, data, type])

  useEffect(() => {
    if (isSuccessCarExpenseType || isSuccessEditCarExpenseType) {
      onClose()
      submit()
    }
  }, [isSuccessCarExpenseType, isSuccessEditCarExpenseType])

  const isLoading = useMemo(
    () => isLoadingCarExpenseType || isLoadingEditCarExpenseType || isLoadingGetCarExpenseType,
    [isLoadingCarExpenseType, isLoadingEditCarExpenseType, isLoadingGetCarExpenseType],
  )
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Expense Type</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IncludeInPurchasePrice'
                    label='Include In Purchase Price?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='AvailableForSoldInventory'
                    label='Available For Sold Inventory'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='AvailableForUnSoldInventory'
                    label='Available For Unsold Inventory'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditExpenseTypesModal
