import React, { useState } from 'react'
import { Button, Grid, IconButton, Paper, Popover, Tooltip, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { convertDateTimeToDisplay } from '../../../../utils/dateUtils'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ReactComponent as NoteIcon } from '../../../../assets/icons/note_icon.svg'
import { ReactComponent as OutboundTextIcon } from '../../../../assets/icons/outbound_text_icon.svg'
import { ReactComponent as InboundIcon } from '../../../../assets/icons/inbound_email_icon.svg'
import { ReactComponent as OutboundCallIcon } from '../../../../assets/icons/outbound_call_icon.svg'
import { ReactComponent as NoteReadIcon } from '../../../../assets/icons/note_read_icon.svg'
import { ReactComponent as SystemIcon } from '../../../../assets/icons/system_icon.svg'
import { ReactComponent as PinIcon } from '../../../../assets/icons/pin_icon.svg'
import { convertDateToDisplay } from '../../../../utils/dateUtils'
import { applyPhoneMask, formatNumber } from '../../../../utils/general'
import { AccountNotesResult } from '../../../../models'
import { useNotePinMutation } from '../../../../services'

const classes = generateUtilityClasses('Grid', [
  'root',
  'cardBody',
  'cardSubHeader',
  'cardHeader',
  'headerDetails',
  'wrapper',
  'deletedItem',
  'satisfiedItem',
  'moreBtnWrapper',
])

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
    margin: theme.spacing(2, 2, 2, 2),
  },

  [`& .${classes.wrapper}`]: {
    width: '100%',
  },

  [`&.${classes.cardBody}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  [`& .${classes.cardHeader}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  [`& .${classes.headerDetails}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  [`& .${classes.cardSubHeader}`]: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginBottom: '20px',
    flexWrap: 'wrap',
  },
  [`& .${classes.deletedItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.satisfiedItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.moreBtnWrapper}`]: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}))

interface Props {
  data: AccountNotesResult
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
}

const NoteHistoryCard = ({ data, setRefresh }: Props) => {
  const [emailAnchorEl, setEmailAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [anchorElPin, setAnchorElPin] = React.useState<HTMLButtonElement | null>(null)
  const [notePin] = useNotePinMutation()
  const [isPinned, setIsPinned] = useState<boolean>(data?.IsPinned)

  const emailCount = data?.SentToEmails ? data?.SentToEmails?.split(';')?.length : 0
  const open = Boolean(emailAnchorEl)
  const id = open ? 'simple-popover' : undefined
  const openPin = Boolean(anchorElPin)
  const idPin = open ? 'pin-popover' : undefined

  const handleEmailPopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setEmailAnchorEl(event.currentTarget)
  }

  const handleEmailPopoverClose = () => {
    setEmailAnchorEl(null)
  }

  const handleNoteUnPin = () => {
    notePin({
      noteId: data?.NoteID,
      notePin: {
        isPinNote: false,
      },
    }).then(() => {
      setIsPinned(false)
      setAnchorElPin(null)
      setRefresh((prevValue) => !prevValue)
    })
  }

  const handleNotePin = () => {
    notePin({
      noteId: data?.NoteID,
      notePin: {
        isPinNote: true,
      },
    }).then(() => {
      setIsPinned(true)
      setAnchorElPin(null)
      setRefresh((prevValue) => !prevValue)
    })
  }

  const handleOpenMorePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElPin(event.currentTarget)
  }

  const handleCloseMorePopover = () => {
    setAnchorElPin(null)
  }

  return (
    <StyledPaper className={classes.root} elevation={4}>
      <Grid item className={classes.wrapper}>
        <Grid container className={classes.cardHeader}>
          <Grid item className={classes.headerDetails}>
            {data?.NoteType.toLowerCase() === 'text' && !data?.IsInbound ? (
              <OutboundTextIcon />
            ) : data?.NoteType.toLowerCase() === 'email' && data?.IsInbound ? (
              <InboundIcon />
            ) : data?.NoteType.toLowerCase() === 'call' && !data?.IsInbound ? (
              <OutboundCallIcon />
            ) : data?.NoteType.toLowerCase() === 'system' ? (
              <SystemIcon />
            ) : data?.NoteType.toLowerCase() === 'text' && data?.IsInbound ? (
              <OutboundTextIcon />
            ) : data?.NoteType.toLowerCase() === 'call' && data?.IsInbound ? (
              <OutboundCallIcon />
            ) : data?.NoteType.toLowerCase() === 'email' && !data?.IsInbound ? (
              <InboundIcon />
            ) : (
              <NoteIcon />
            )}
            <Typography variant='h6'>
              {data?.NoteType.toLowerCase() === 'call' || data?.NoteType.toLowerCase() === 'text' || data?.NoteType.toLowerCase() === 'email'
                ? data?.IsInbound
                  ? 'Inbound'
                  : 'Outbound'
                : ''}{' '}
              {data?.NoteType}
            </Typography>
          </Grid>
          <Grid item className={classes.headerDetails}>
            {data?.IsDeleted && <Typography className={classes.deletedItem}>{data?.IsDeleted && 'Deleted'}</Typography>}
            {data?.IsPTP && data?.IsPtpSatisfied && <Typography className={classes.satisfiedItem}>{data?.IsPtpSatisfied && 'Satisfied'}</Typography>}
            {data?.IsFollowUpCompleted && <Typography className={classes.satisfiedItem}>{data?.IsFollowUpCompleted && 'Completed'}</Typography>}
            {data?.NoteType.toLowerCase() === 'text' && !data?.IsTextRead && (
              <Tooltip title='Mark as read'>
                <IconButton>
                  <NoteReadIcon />
                </IconButton>
              </Tooltip>
            )}
            {data?.NoteType.toLowerCase() === 'email' && !data?.IsEmailRead && (
              <Tooltip title='Mark as read'>
                <IconButton>
                  <NoteReadIcon />
                </IconButton>
              </Tooltip>
            )}
            {isPinned && (
              <Tooltip title='Unpin'>
                <IconButton onClick={() => handleNoteUnPin()}>
                  <PinIcon />
                </IconButton>
              </Tooltip>
            )}
            <>
              <IconButton onClick={(e) => handleOpenMorePopover(e)} aria-describedby={idPin}>
                <MoreVertIcon />
              </IconButton>
              {!isPinned && (
                <Popover
                  id={idPin}
                  open={openPin}
                  anchorEl={anchorElPin}
                  onClose={handleCloseMorePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Button onClick={() => handleNotePin()} sx={{ textTransform: 'capitalize', justifyContent: 'flex-start' }}>
                    Pin Note
                  </Button>
                </Popover>
              )}
            </>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item className={classes.cardSubHeader}>
            <Typography>Created: {convertDateTimeToDisplay(data?.SystemDate)}</Typography>
            <Typography>Created by: {data?.UserCode}</Typography>

            {data?.IsPTP && (
              <>
                <Typography>Due: {convertDateToDisplay(data?.PtpDueDate)}</Typography>
                <Typography>Due Amount: {formatNumber(data?.PtpAmount)}</Typography>
              </>
            )}
            {data?.IsFollowUp && (
              <>
                <Typography>Due: {convertDateTimeToDisplay(data?.FollowUpDueDateTime)}</Typography>
              </>
            )}
            {data?.FollowUpAssignedTo && (
              <>
                <Typography>Assigned To: {data?.FollowUpAssignedTo}</Typography>
              </>
            )}
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            {data?.NoteType.toLowerCase() === 'call' && (
              <>
                <Typography>Outcome: {data?.Outcome}</Typography>
                <br />
              </>
            )}
            {data?.NoteType.toLowerCase() === 'text' && (
              <>
                <Typography>Phone: {applyPhoneMask(data?.PhoneNumber)}</Typography>
                <br />
              </>
            )}
            {data?.NoteType.toLowerCase() === 'email' && (
              <>
                <Typography>From: {data?.SentByEmail}</Typography>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={emailAnchorEl}
                  onClose={handleEmailPopoverClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography sx={{ p: 2 }}>{data?.SentToEmails ? data?.SentToEmails.split(';').join(', ') : ''}</Typography>
                </Popover>

                <Typography>
                  <Typography>
                    To: {data?.SentToEmails ? data?.SentToEmails.split(';')[0] : ''}{' '}
                    {emailCount > 2 ? (
                      <span className={classes.moreBtnWrapper} onClick={handleEmailPopoverClick} style={{ cursor: 'pointer' }}>
                        +{emailCount - 2} More
                      </span>
                    ) : (
                      ''
                    )}
                  </Typography>
                </Typography>

                <Typography>Subject: {data?.Subject}</Typography>
                <br />
              </>
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.cardBody}>
            <Typography>{data?.Note}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

export default NoteHistoryCard
