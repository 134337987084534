import { Typography } from '@mui/material'
import { AddressData } from '../../models'

type Props = {
  addressData?: AddressData
}

const Address = ({ addressData }: Props) => {
  return (
    <>
      {addressData && (
        <>
          {addressData.Address1 && <Typography variant='body1'>{addressData.Address1}</Typography>}
          {addressData.Address2 && <Typography variant='body1'>{addressData.Address2}</Typography>}
          <Typography variant='body1'>
            {addressData.City}, {addressData.State} {addressData.PostalCode}
          </Typography>
        </>
      )}
    </>
  )
}

export default Address
