import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

export const DealsForm = ({ onDropdownFormChange }: Props) => {
  return (
    <>
      <CardHeader title='Deals' />

      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} md={6}>
          <TextField name='DefaultTerm' label='Default Term' fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='DefaultPaymentSchedule'
            label='Default Payment Schedule'
            fullWidth
            options={[
              { key: 'W', value: 'Weekly' },
              { key: 'B', value: 'Bi-Weekly' },
              { key: 'S', value: 'Semi-Monthly' },
              { key: 'M', value: 'Monthly' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='Default1Term'
            label='Default 1 Term to 1st Due Date'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField name='DefaultDaysUntilDueDate' label='Default Days Until 1st Due Date' fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField name='Default1stDueDay' label='Default 1st Due Day' fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField name='Default2ndDueDay' label='Default 2nd Due Day' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default DealsForm
