import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const SettingsForm = ({}: Props) => {
  const referenceRelationship = useSelector((state) => selectDropdownByNameActiveOnly(state, 'ReferenceRelationship'))

  return (
    <>
      <CardHeader title='Settings (Optional)' />
      <Grid container spacing={2} padding={3}>
        {referenceRelationship && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              required
              name='RelationshipTypeID'
              label='Relationship'
              options={referenceRelationship}
              optionKey='ID'
              optionValue='RelationshipType'
              autoComplete='off'
              optionNone
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IsVerified'
            label='Is Verified?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IsAllowCall'
            label='Is Allowed To Call?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField multiline name='Note' label='Comments' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsForm
