import CenteredModal from '../../../../../components/Modal/CenteredModal'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, generateUtilityClasses, Grid, Typography } from '@mui/material'
import { Form } from 'react-final-form'
import { LoadingButton } from '@mui/lab'
import { styled } from '@mui/system'
import { TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectDropdownByNameActiveOnly,
  useAddCreditAppLookingForMutation,
  useEditCreditAppLookingForMutation,
  useLazyGetCreditAppLookingForQuery,
  useLazyGetCreditAppQuery,
} from '../../../../../services'
import { useParams } from 'react-router-dom'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { vehicleInterestedInValidation } from '../../../../../validation/vehicleInterestedInValidation'
import validateForm from '../../../../../utils/validateForm'
import { InterestedInData } from '../../../../../models'
import AmountField from '../../../../../ui/AmountField'
import removeMask from '../../../../../utils/removeMask'
import NumberField from '../../../../../ui/NumberField'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(4, 0, 0, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  isVehicleInterestedInOpenModal: boolean
  onClose: () => void
  setIsInterestedInEdit: any
  isInterestedInEdit: boolean
  interestedInEditData: InterestedInData | undefined
  setInterestedInEditData: Dispatch<SetStateAction<InterestedInData | undefined>>
  setInterestedInAddEditModalOpen: Dispatch<SetStateAction<boolean>>
}
const VehicleInterestedInModal = ({
  isVehicleInterestedInOpenModal,
  onClose,
  isInterestedInEdit,
  setIsInterestedInEdit,
  interestedInEditData,
  setInterestedInEditData,
  setInterestedInAddEditModalOpen,
}: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const { leadID } = useParams()
  const dispatch = useDispatch()
  const [getCreditApp] = useLazyGetCreditAppQuery()
  const [addLookingFor, { isSuccess: addIsSuccess }] = useAddCreditAppLookingForMutation()
  const [editLookingFor, { isSuccess: editIsSuccess }] = useEditCreditAppLookingForMutation()
  const [getLookingFor, { isSuccess: getIsSuccess, data }] = useLazyGetCreditAppLookingForQuery()
  const makeDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarMake'))
  const bodyTypeDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))

  const handleSubmit = (values?: any) => {
    if (isInterestedInEdit) {
      editLookingFor({
        creditAppId: leadID?.toString() as string,
        lookingForId: interestedInEditData?.LookingForID?.toString() as string,
        uspCreditAppLookingForAddEdit: {
          modelYearStart: values.YearMin,
          modelYearEnd: values.YearMax,
          make: values.Make,
          model: values.Model,
          modelTrim: values.Trim,
          bodyTypeID: values.Body,
          exteriorColor: values.Color,
          mileageMax: removeMask(values.MaxMileage) as string,
        },
      })
    } else {
      addLookingFor({
        creditAppId: leadID?.toString() as string,
        uspCreditAppLookingForAddEdit: {
          modelYearStart: values.YearMin,
          modelYearEnd: values.YearMax,
          make: values.Make,
          model: values.Model,
          modelTrim: values.Trim,
          bodyTypeID: values.Body,
          exteriorColor: values.Color,
          mileageMax: removeMask(values.MaxMileage) as string,
        },
      })
    }
  }

  useEffect(() => {
    if (interestedInEditData) {
      getLookingFor({
        creditAppId: leadID?.toString() as string,
        lookingForId: interestedInEditData?.LookingForID?.toString() as string,
      })
    }
  }, [interestedInEditData])

  useEffect(() => {
    if (editIsSuccess || addIsSuccess) {
      onClose()
      dispatch(enqueueNotification({ message: 'Success' }))
      setInterestedInEditData(undefined)
      setIsInterestedInEdit(false)
      getCreditApp({ creditAppId: leadID as string })
    }
  }, [addIsSuccess, editIsSuccess, dispatch])

  useEffect(() => {
    if (getIsSuccess && isInterestedInEdit) {
      setInitialValues({
        YearMin: data?.ModelYearStart,
        YearMax: data?.ModelYearEnd,
        Make: data?.Make,
        Model: data?.Model,
        Trim: data?.ModelTrim,
        Body: data?.BodyTypeID,
        Color: data?.ExteriorColor,
        MaxMileage: data?.MileageMax?.toString(),
      })
    } else {
      setInitialValues({})
    }
  }, [getIsSuccess, data, isInterestedInEdit])

  return (
    <Dialog
      // setOpen={() => {
      //   setIsInterestedInEdit(false)
      //   setInterestedInAddEditModalOpen(false)
      // }}
      onClose={onClose}
      open={isVehicleInterestedInOpenModal}
    >
      <DialogTitle>Interested In</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        subscription={{ values: true }}
        initialValues={initialValues}
        validate={validateForm(vehicleInterestedInValidation)}
        render={({ handleSubmit }: any) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2} py={1}>
                  <Grid item xs={12}>
                    <TextField fullWidth type='number' name='YearMin' label='Year (min)' />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField fullWidth type='number' name='YearMax' label='Year (max)' />
                  </Grid>

                  {makeDropdown && (
                    <Grid item xs={12}>
                      <DropdownFieldSearch name='Make' label='Make' options={makeDropdown} optionKey='Make' optionValue='Make' />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField name='Model' label='Model (optional)' fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name='Trim' label='Trim (optional)' fullWidth />
                  </Grid>

                  {bodyTypeDropdown && (
                    <Grid item xs={12}>
                      <DropdownFieldSearch name='Body' label='Body (optional)' options={bodyTypeDropdown} optionKey='ID' optionValue='BodyType' />
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <TextField name='Color' label='Color (optional)' fullWidth />
                  </Grid>

                  <Grid item xs={12}>
                    <NumberField variant='standard' name='MaxMileage' label='Max Mileage (optional)' fullWidth />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                  Save
                </LoadingButton>
                <Button variant='contained' color='error' onClick={onClose}>
                  Cancel
                </Button>
              </DialogActions>
            </form>
          )
        }}
      />
    </Dialog>
  )
}

export default VehicleInterestedInModal
