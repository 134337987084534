import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { userLabels } from '../../../../../validation'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const UserSettingsForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='User Settings' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={6}>
          <TextField name='userCode' label={userLabels.userCode} required fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='username' label={userLabels.username} required fullWidth />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='isAllowedViewEmployeeAccounts'
            label='Can View Employee Accounts?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='isRestrictedByIPAddress'
            label='Login Restricted By IP Address?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='isActive'
            label='Is Active?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            initialValue={true}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default UserSettingsForm
