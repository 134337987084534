import { appRoute } from '../../constants'

export const insightsCardData = [
  {
    id: 1,
    category: 'Inventory',
    title: 'Inventory Aging',
    description: 'This report shows your inventory aging, either currently or historically',
    link: appRoute?.InsightsInventoryAging,
    isFavorite: false,
  },

  {
    id: 2,
    category: 'Sales',
    title: 'Sales',
    description: 'This report shows all sales by date range',
    link: appRoute.InsightsSales,
    isFavorite: false,
  },
  {
    id: 3,
    category: 'Sales',
    title: 'Sales Commissions',
    description: 'This report shows salesperson commissions',
    link: appRoute.InsightsSalesCommissions,
    isFavorite: false,
  },
  {
    id: 4,
    category: 'Sales',
    title: 'Trade Liens',
    description: 'This report shows trade liens',
    link: appRoute.InsightsTradeLiens,
    isFavorite: false,
  },
  {
    id: 5,
    category: 'Sales',
    title: 'Sales Tax',
    description: 'This report shows sales taxes',
    link: appRoute.InsightsSalesTax,
    isFavorite: false,
  },

  {
    id: 6,
    category: 'Accounts',
    title: 'Receivables',
    description: 'This report shows all customer receivable balances',
    link: appRoute.InsightsReceivables,
    isFavorite: false,
  },
  {
    id: 7,
    category: 'Accounts',
    title: 'Accounts Receivable Aging',
    description: 'This report shows your aging receivables',
    link: appRoute.InsightsReceivablesAging,
    isFavorite: false,
  },
  {
    id: 8,
    category: 'Accounts',
    title: 'Accounts Receivable Recency',
    description: 'This report shows your recency receivables',
    link: appRoute.InsightsReceivablesRecency,
    isFavorite: false,
  },
  {
    id: 9,
    category: 'Accounts',
    title: 'Charge Offs',
    description: 'This report shows your Charge Offs',
    link: appRoute.InsightsAccountsChargeOffs,
    isFavorite: false,
  },
  {
    id: 10,
    category: 'Collections',
    title: 'Promise-To-Pays',
    description: 'This report shows Promise-To-Pays along with their statuses',
    link: null,
    isFavorite: false,
  },
  {
    id: 11,
    category: 'Accounting',
    title: 'Accounts Payable Aging',
    description: 'This report shows all accounts',
    link: appRoute.InsightsAccountsPayableAging,
    isFavorite: false,
  },
  {
    id: 12,
    category: 'Accounting',
    title: 'Subsidiary Reconciliation',
    description: 'This report shows all accounts',
    link: appRoute.InsightsAccountsSubsidiaryReconciliation,
    isFavorite: false,
  },

  {
    id: 13,
    category: 'Accounting',
    title: 'Payments',
    description: 'This report shows all payments',
    link: appRoute.InsightsPayments,
    isFavorite: false,
  },
]
