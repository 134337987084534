import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Header from '../components/Header/Header'
import { useSearchDealStatusMutation } from '../../../services'
import { UspConfigurationDealStatusViewResult } from '../../../models'
import DealsDealStatusesTable from './components/DealsDealStatusesTable'
import AddEditDealStatusesModal from './components/AddEditDealStatusesModal'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const SettingsDealsDealStatuses = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchDealStatuses, { data, isLoading }] = useSearchDealStatusMutation()
  const [dealStatusData, setDealStatusData] = useState<UspConfigurationDealStatusViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchDealStatuses({
      simpleSearch: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setDealStatusData(data)
    }
  }, [data])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Deal Statuses'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button onClick={handleOpenModal} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='' handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <DealsDealStatusesTable
                      loading={isLoading}
                      data={dealStatusData}
                      setData={setDealStatusData}
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditDealStatusesModal
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspConfigurationDealStatusViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsDealsDealStatuses
