import * as yup from 'yup'
import { formatNumber, parseToNumber } from '../utils/general'

export const accountsAddPaymentValidation = (
  maxDownPayment: number | string,
  sumMaxAmountLateChargeDue: number,
  checkID: number,
  creditCardID: number,
): yup.SchemaOf<any> => {
  return yup.object().shape({
    AmountTendered: yup
      .number()
      .transform((value, originalValue) => (originalValue ? parseToNumber(originalValue) : 0))
      .required()
      .test('is-greater', 'Cannot be less than Total Payment', function (value) {
        const sanitizedValue = value ? parseToNumber(value.toString()) : 0
        const sanitizedTotalPayment = parseToNumber((this.parent.TotalPayment || '0').toString())
        return sanitizedValue >= sanitizedTotalPayment
      }),
    EffectiveDate: yup.date().nullable().notRequired().typeError('Invalid Date'),
    AmountAdd: yup
      .number()
      .transform((value, originalValue) => (originalValue ? parseToNumber(originalValue) : 0))
      .when('TypeSetup', {
        is: 'Loan',
        then:
          sumMaxAmountLateChargeDue && !isNaN(sumMaxAmountLateChargeDue)
            ? yup
                .number()
                .min(0, 'Amount must be greater than 0')
                .max(sumMaxAmountLateChargeDue as number, `Amount cannot be greater that ${formatNumber(sumMaxAmountLateChargeDue)}`)
            : yup.number().notRequired(),
        otherwise: yup.number().notRequired(),
      }),

    Amount: yup
      .number()
      .transform((value, originalValue) => (originalValue ? parseToNumber(originalValue) : 0))
      .when('TypeSetup', {
        is: 'DownPayment',
        then: yup
          .number()
          .required('Amount is required')
          .min(0, 'Amount must be greater than 0')
          .max(maxDownPayment as number, `Amount cannot exceed Down Payment Balance ${maxDownPayment}`),
        otherwise: yup.number().notRequired(),
      }),
    TypeSetup: yup.string().required().typeError('Select a valid type'),
    Method: yup.string().required().typeError('Select a valid method'),
    Form: yup.string().required().typeError('Select a valid form'),
    CreditType: yup.string().when('Form', {
      is: (val: any) => val === creditCardID,
      then: yup.string().required().typeError('Select a valid credit type'),
      otherwise: yup.string().notRequired(),
    }),
    Last4ofCreditCard: yup.string().when('Form', {
      is: (val: any) => val === creditCardID,
      then: yup.string().required('Last 4 of Credit Card is required').min(4, 'Must be exactly 4 digits').max(4, 'Must be exactly 4 digits'),
      otherwise: yup.string().notRequired(),
    }),
    AuthorizationNumber: yup.string().when('Form', {
      is: (val: any) => val === creditCardID,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
    CheckType: yup.string().when('Form', {
      is: (val: any) => val === checkID,
      then: yup.string().required().typeError('Select a valid check type'),
      otherwise: yup.string().notRequired(),
    }),
    CheckNumber: yup.string().when('Form', {
      is: (val: any) => val === checkID,
      then: yup.string().required(),
      otherwise: yup.string().notRequired(),
    }),
  })
}
