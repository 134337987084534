import React, { useEffect } from 'react'
import Header from './components/Header'
import LeadsStipulationTableBlock from './components/LeadsStipulationTableBlock'
import AddEditStipulationsFormModal from './components/AddEditStipulationsFormModal'
import { useLazyGetCreditAppStipulationQuery } from '../../../services'
import { withCreditApps } from '../../../hoc'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { UspCreditAppStipulationViewResult, UspGetDataCreditApp } from '../../../models'
import AddIcon from '@mui/icons-material/Add'
import { appRoute } from '../../../constants'
import { generateUtilityClasses, Typography } from '@mui/material'
import { styled } from '@mui/system'

const classes = generateUtilityClasses('Grid', ['root', 'btnWrap', 'btnCancelWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.btnWrap}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 3, 0, 3),
  },
  [`& .${classes.btnCancelWrap}`]: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 3, 3, 3),
  },
}))

interface Props {
  creditAppData: UspGetDataCreditApp
  creditAppIsLoading: boolean
}

export const LeadsStipulations = ({ creditAppData }: Props) => {
  const { leadID } = useParams()
  const navigate = useNavigate()
  const [getContactsStipulation, { data: contactsStipulationData, isLoading: contactsStipulationLoading }] = useLazyGetCreditAppStipulationQuery()
  const [addEditModalOpen, setAddModalOpen] = React.useState(false)
  const [editStipulation, setEditStipulation] = React.useState<UspCreditAppStipulationViewResult | null>(null)

  const handleAddModalOpen = () => setAddModalOpen(true)
  const handleAddModalClose = () => {
    setEditStipulation(null)
    setAddModalOpen(false)
  }

  const handleStipulation = (stipulation: UspCreditAppStipulationViewResult | null) => {
    setEditStipulation(stipulation)
    setAddModalOpen(true)
  }

  useEffect(() => {
    getContactsStipulation({ creditAppId: leadID as string })
  }, [])

  return (
    <StyledGrid className={classes.root}>
      <Header creditAppData={creditAppData} />
      <Grid className={classes.btnWrap}>
        <Typography variant='h3'>Stipulations</Typography>
        <Button variant='contained' startIcon={<AddIcon />} onClick={handleAddModalOpen}>
          Add new
        </Button>
      </Grid>
      <LeadsStipulationTableBlock
        loading={contactsStipulationLoading}
        handleStipulation={handleStipulation}
        contactsStipulationData={contactsStipulationData}
        getContactsStipulation={getContactsStipulation}
      />
      <AddEditStipulationsFormModal
        getContactsStipulation={getContactsStipulation}
        open={addEditModalOpen}
        editStipulation={editStipulation}
        onClose={handleAddModalClose}
      />
      <Grid item xs={12} className={classes.btnCancelWrap}>
        <Button variant='contained' color='error' onClick={() => navigate(appRoute.LeadsTask.replace(':leadID', leadID as string))}>
          Close
        </Button>
      </Grid>
    </StyledGrid>
  )
}

export default withCreditApps(LeadsStipulations)
