import { Card, CardHeader, Container, generateUtilityClasses, Grid, Paper } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import Criteria from './components/Criteria/Criteria'
import { Header } from './components/Header'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('Grid', ['pageBody', 'criteriaForm'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.criteriaForm}`]: {
    width: '305px',
    minHeight: '605px',
  },
}))

const AccountingFinancials = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)

  useEffect(() => {
    if (!userAccess.CanAccessFinancials) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Financials'
        links={[]}
        sx={{
          mb: { xs: 3 },
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} md={10}>
          <Card>
            <CardHeader title='Criteria' />
            <Criteria />
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default AccountingFinancials
