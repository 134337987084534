import React from 'react'
import { IMaskInput } from 'react-imask'
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  TextField,
  OutlinedInput,
  Popper,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { Field } from 'react-final-form'
import FilledInput from '@mui/material/FilledInput'
import { convertDateToDisplay } from '../../utils/dateUtils'

interface Props {
  name: string
  label: string
  variant?: 'standard' | 'outlined' | 'filled'
  [x: string]: any
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const validateFEIN = (value: string) => {
  if (!value || value.trim() === '') {
    return undefined
  }
  const numericValue = value?.replace(/-/g, '')
  if (numericValue?.length !== 9) {
    return 'FEIN must be exactly 9 digits'
  }
}

export const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return <IMaskInput {...other} mask='00-0000000' onAccept={(value: any) => onChange({ target: { name: props.name, value } })} overwrite />
})

export const FEINTextField = ({ name, label, mask, maskChar, variant = 'standard', fullWidth, data, setValue, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <>
      <Field name={name} validate={validateFEIN}>
        {({ input, meta }) => (
          <FormControl variant={variant} fullWidth={fullWidth} error={meta.error && meta.touched}>
            {/* <InputLabel htmlFor='formatted-text-mask-input'>{label}</InputLabel> */}
            {variant === 'standard' && (
              <TextField
                label={label}
                {...input}
                {...props}
                variant='outlined'
                id='formatted-text-mask-input'
                onBlur={(event) => {
                  input.onBlur(event)
                  setTimeout(() => {
                    setAnchorEl(null)
                  }, 400)
                }}
                InputProps={{
                  inputComponent: TextMaskCustom as any,
                  onChange: (event) => {
                    setAnchorEl(event.currentTarget)
                    input.onChange(event)
                  },
                  onFocus: (event) => {
                    setAnchorEl(event.currentTarget)
                    event.target.select()
                  },
                }}
              />
            )}
            {variant === 'filled' && <FilledInput {...input} {...props} id='formatted-text-mask-input' inputComponent={TextMaskCustom as any} />}

            {meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
          </FormControl>
        )}
      </Field>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-start'}>
        <Card>
          <Table>
            <TableBody>
              {data?.map((el: any) => (
                <TableRow
                  hover
                  onClick={() => {
                    setValue(el)
                    setAnchorEl(null)
                  }}
                >
                  <TableCell>{el?.Name}</TableCell>
                  <TableCell>{convertDateToDisplay(el?.DateOfBirth)}</TableCell>
                  <TableCell>{el?.phoneNumber}</TableCell>
                  <TableCell>{el?.AddressData?.[0]?.Address1 ?? el?.AddressData?.[0]?.Address1}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Popper>
    </>
  )
}

export default FEINTextField
