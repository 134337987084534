import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Container, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField, PhoneTextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getPhoneTypes } from '../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import { userLabels } from '../../../../../validation'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

export const ContactForm = ({ onDropdownFormChange }: Props) => {
  const { values } = useFormState()
  const phoneTypes = useSelector(getPhoneTypes)

  return (
    <>
      <CardHeader title='Contact Info (Optional)' />

      <Grid container spacing={2} padding={3}>
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='PrimaryPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={values?.PrimaryPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField
            onChange={() => {
              onDropdownFormChange()
            }}
            name='PrimaryPhoneNumber'
            label='Phone'
            fullWidth
          />
        </Grid>
        {values?.PrimaryPhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField
                onChange={() => {
                  onDropdownFormChange()
                }}
                name='PrimaryPhoneExtension'
                label='Ext'
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='SecondaryPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={values?.SecondaryPhoneType === 'Biz' ? 2 : 4}>
          <PhoneTextField
            onChange={() => {
              onDropdownFormChange()
            }}
            name='SecondaryPhoneNumber'
            label='Secondary Phone'
            fullWidth
          />
        </Grid>
        {values?.SecondaryPhoneType === 'Biz' && (
          <>
            <Grid item xs={2}>
              <TextField
                onChange={() => {
                  onDropdownFormChange()
                }}
                name='SecondaryPhoneExtension'
                label='Ext'
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField
            onChange={() => {
              onDropdownFormChange()
            }}
            name='Email'
            label={userLabels.email}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ContactForm
