import { useState, useCallback } from 'react'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton'
import Iconify from '../../../../../components/iconify'
import SearchNotFound from '../../../../../components/search-not-found/search-not-found'
import Label from '../../../../../components/label'

// ----------------------------------------------------------------------

type Props = {
  title?: string
  list: any[]
  action?: React.ReactNode
  //
  open: boolean
  onClose: VoidFunction
  //
  selected: (selectedId: string) => boolean
  onSelect: (address: any | null) => void
}

export default function AddressListDialog({
  title = 'Address Book',
  list,
  action,
  //
  open,
  onClose,
  //
  selected,
  onSelect,
}: Props) {
  const [searchAddress, setSearchAddress] = useState('')

  const dataFiltered = applyFilter({
    inputData: list,
    query: searchAddress,
  })

  const notFound = !dataFiltered?.length && !!searchAddress

  const handleSearchAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchAddress(event.target.value)
  }, [])

  const handleSelectAddress = useCallback(
    (address: any | null) => {
      onSelect(address)
      setSearchAddress('')
      onClose()
    },
    [onClose, onSelect],
  )

  const renderList = (
    <Stack
      spacing={0.5}
      sx={{
        p: 0.5,
        maxHeight: 80 * 8,
        overflowX: 'hidden',
      }}
    >
      {dataFiltered?.map((address) => (
        <Stack
          key={address.ID}
          spacing={0.5}
          component={ListItemButton}
          selected={selected(`${address.ID}`)}
          onClick={() => handleSelectAddress(address)}
          sx={{
            py: 1,
            px: 1.5,
            borderRadius: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            [`&.${listItemButtonClasses.selected}`]: {
              bgcolor: 'action.selected',
              '&:hover': {
                bgcolor: 'action.selected',
              },
            },
          }}
        >
          <Stack direction='row' alignItems='center' spacing={1}>
            <Typography variant='subtitle2'>{address.Name}</Typography>

            {/* {address.primary && <Label color='info'>Default</Label>} */}
          </Stack>

          {/* {address.company && <Box sx={{ color: 'primary.main', typography: 'caption' }}>{address.company}</Box>} */}

          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Vendor Type: {address.VendorType}
          </Typography>

          {address.VendorCode && (
            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
              Vendor Code: {address.VendorCode}
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  )

  return (
    <Dialog fullWidth maxWidth='xs' open={open} onClose={onClose}>
      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{ p: 3, pr: 1.5 }}>
        <Typography variant='h6'> {title} </Typography>

        {action && action}
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
        <TextField
          value={searchAddress}
          onChange={handleSearchAddress}
          placeholder='Search...'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {notFound ? <SearchNotFound query={searchAddress} sx={{ px: 3, pt: 5, pb: 10 }} /> : renderList}
    </Dialog>
  )
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, query }: { inputData: any[]; query: string }) {
  if (query) {
    return inputData.filter(
      (address) =>
        address.Name.toLowerCase().indexOf(query.toLowerCase()) !== -1 || address.VendorCode.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    )
  }

  return inputData
}
