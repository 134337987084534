import _ from 'lodash'
import { UspGetDataContact } from '../../../models'

export const transformToRequestValues = (values?: any) => ({
  lenderCode: values?.ShortCode || null,
  lenderTypeID: values?.ServicedBy,
  contactID: values?.ContactID || null, // FIX
  addressType: values?.AddressType, // FIX
  isDiscountIncludePickups: values?.DiscountPickups,
  contact: values?.Contact || null,
  website: values?.Website || null,
  note: values?.Note || null,
  manager: null,
  discountRate: values?.DiscountRate,
  discountAmount: values?.DiscountAmount,
  spreadRate: values?.SpreadRate,
  isActive: values?.IsActive,
  businessName: values?.Name,
  dba: values?.DBA,
  taxIDNumber: values?.TaxIDNumber,
  email: values?.Email,
  isContactUpdated: true,
  addressID: values?.AddressID,
  address1: values?.Address1,
  address2: values?.Address2,
  city: values?.City,
  state: values?.State,
  postalCode: values?.PostalCode,
  county: values?.County,
  isPrimaryAddress: true,
  isAddressActive: true,
  isAddressUpdated: true,
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.ContactPhoneID || null,
      PhoneNumber: values?.PhoneNumber,
      PhoneType: values?.PhoneType,
      PhoneExtension: values?.PhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
    {
      ContactPhoneID: values?.SecondaryContactPhoneID || null,
      PhoneNumber: values?.SecondaryPhoneNumber,
      PhoneType: values?.SecondaryPhoneType,
      PhoneExtension: values?.SecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
  isPhoneUpdated: true,
})

export const transformToFormValues = (lenderData?: any, contactsData?: UspGetDataContact, lenderId?: any) => {
  // Initialize fields with default values
  let ContactPhoneID, PhoneNumber, PhoneExtension
  let SecondaryContactPhoneID, SecondaryPhoneNumber, SecondaryPhoneExtension
  let PhoneType = 'Biz'
  let SecondaryPhoneType = 'Fax'
  // Iterate over the ContactPhoneData array
  if (contactsData?.ContactPhoneData) {
    for (const phoneData of contactsData.ContactPhoneData) {
      if (phoneData.OrderOfPreference === 1) {
        ContactPhoneID = phoneData.ContactPhoneID
        PhoneNumber = phoneData.PhoneNumber
        PhoneType = phoneData.PhoneType || 'Biz'
        PhoneExtension = phoneData.PhoneExtension
      } else if (phoneData.OrderOfPreference === 2) {
        SecondaryContactPhoneID = phoneData.ContactPhoneID
        SecondaryPhoneNumber = phoneData.PhoneNumber
        SecondaryPhoneType = phoneData.PhoneType || 'Fax'
        SecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }

  let DiscountType = 'rate'
  if (lenderData?.DiscountAmount > 0) {
    DiscountType = 'amount'
  }

  return {
    Name: contactsData?.BusinessName,
    DBA: contactsData?.DBA,

    AddressID: _.first(contactsData?.ContactAddressData)?.AddressID,
    Address1: _.first(contactsData?.ContactAddressData)?.Address1,
    Address2: _.first(contactsData?.ContactAddressData)?.Address2,
    City: _.first(contactsData?.ContactAddressData)?.City,
    County: _.first(contactsData?.ContactAddressData)?.County,
    State: _.first(contactsData?.ContactAddressData)?.State,
    PostalCode: _.first(contactsData?.ContactAddressData)?.PostalCode,
    Email: contactsData?.Email,
    TaxIDNumber: contactsData?.TaxIDNumber,

    ContactID: lenderData?.ContactID,
    IsActive: lenderId === 'add' ? true : lenderData?.IsActive,
    Contact: lenderData?.Contact,
    Website: lenderData?.Website,
    Note: lenderData?.Note,
    ContactPhoneID,
    SecondaryContactPhoneID,
    PhoneType,
    PhoneNumber,
    PhoneExtension,
    SecondaryPhoneNumber,
    SecondaryPhoneExtension,
    SecondaryPhoneType,
    ShortCode: lenderData?.LenderCode,
    ServicedBy: lenderData?.LenderTypeID,
    DiscountType,
    DiscountRate: lenderData?.DiscountRate.toString() as string,
    DiscountAmount: lenderData?.DiscountAmount.toString() as string,
    DiscountPickups: lenderData?.IsDiscountIncludePickups,
    SpreadRate: lenderData?.SpreadRate.toString() as string,
  }
}
