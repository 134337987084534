import React, { useEffect } from 'react'
import { Form } from 'react-final-form'
import ChartOfAccountsSearchBlock from './components/ChartOfAccountsSearchBlock/ChartOfAccountsSearchBlock'
import ChartOfAccountsTableBlock from './components/ChartOfAccountsTableBlock/ChartOfAccountsTableBlock'
import {
  useAddAccountingChartOfAccountMutation,
  useGetAccountingChartOfAccountCurrentMutation,
  useLazyGetAccountSubTypeQuery,
} from '../../../services'
import { getCurrentDate } from '../../../utils/general'
import AddChartOfAccountModal from './AddChartOfAccountsModal/AddChartOfAccountModal'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { Button, Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

export const ChartOfAccounts = () => {
  const [open, setOpen] = React.useState(false)
  const [searchChartOfAccounts, { data, isLoading }] = useAddAccountingChartOfAccountMutation()
  const currentDate = getCurrentDate()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const [getParentAccountData, { data: parentAccountData, isLoading: parentAccountDataIsLoading }] = useGetAccountingChartOfAccountCurrentMutation()
  const [getAccountSubType, { data: subTypeData, isLoading: subTypeAccountDataIsLoading }] = useLazyGetAccountSubTypeQuery({})
  const branch = localStorage.getItem('branch')

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    searchChartOfAccounts({
      uspAccountingChartOfAccountingView: {
        jsonBranchList: jsonBranchList || null,
        financialStatement: 'C',
        isExcludeZeroBalance: false,
        endDate: currentDate,
        isGroupByAccount: true,
        isIncludeInactiveAccounts: values?.IncludeInactive,
      },
    })
  }

  useEffect(() => {
    getAccountSubType({})
    getParentAccountData({
      uspChartOfAccountView: {
        currentID: null,
        isAllowedSelectControlledAccount: true,
        isIncludeAccountsThatCannotBeSelected: true,
        isReturnAllCompanyTypes: false,
      },
    })
  }, [])

  useEffect(() => {
    if (!userAccess.CanAccessChartOfAccounts) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  const handleClose = () => setOpen(false)

  return (
    <>
      <Container
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CustomBreadcrumbs
          heading='Chart of Accounts'
          links={[
            {
              name: 'List',
            },
          ]}
          action={
            <Button variant='contained' onClick={(e) => setOpen(true)} startIcon={<Iconify icon='mingcute:add-line' />}>
              Add New
            </Button>
          }
          sx={{
            mb: {
              xs: 3,
              md: 5,
            },
          }}
        />

        <Card
          sx={{
            flexGrow: { md: 1 },
            display: { md: 'flex' },
            flexDirection: { md: 'column' },
          }}
        >
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              jsonBranchList: null,
              financialStatement: 'C',
              isExcludeZeroBalance: false,
              endDate: '',
              isGroupByAccount: true,
            }} // require to avoid first debounce search
          >
            {({ handleSubmit, form: { submit } }: any) => (
              <form onSubmit={handleSubmit}>
                <ChartOfAccountsSearchBlock submit={submit} setOpen={setOpen} data={data} />
                <ChartOfAccountsTableBlock
                  subTypeAccountDataIsLoading={subTypeAccountDataIsLoading}
                  parentAccountDataIsLoading={parentAccountDataIsLoading}
                  parentAccountData={parentAccountData}
                  subTypeData={subTypeData}
                  loading={isLoading}
                  data={data}
                  onSuccessfulSubmit={handleSubmit}
                />
              </form>
            )}
          </Form>
          <AddChartOfAccountModal
            subTypeAccountDataIsLoading={subTypeAccountDataIsLoading}
            parentAccountDataIsLoading={parentAccountDataIsLoading}
            parentAccountData={parentAccountData}
            subTypeData={subTypeData}
            open={open}
            handleClose={handleClose}
            onSuccessfulSubmit={handleSubmit}
          />
        </Card>
      </Container>
    </>
  )
}

export default ChartOfAccounts
