import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const DealsForm = ({}: Props) => {
  const { values } = useFormState()

  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Deals</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessDeals' label='Access Deals' />
      </Grid>
      {values.AccessDeals && (
        <>
          <Grid>
            <CheckboxField name='AddDeals' label='Add Deals' />
          </Grid>
          <Grid>
            <CheckboxField name='DeleteDeals' label='Delete Deals' />
          </Grid>
          <Grid>
            <CheckboxField name='EditDeals' label='Edit Deals' />
          </Grid>
          <Grid>
            <CheckboxField name='FinalizeDeals' label='Finalize Deals' />
          </Grid>
          <Grid>
            <CheckboxField name='UnwindDeals' label='Unwind Deals' />
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

export default DealsForm
