import { Grid, Typography } from '@mui/material'
import { TextField, DateField } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const TitleForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const states = useSelector(getStates)
  const carTitleType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarTitleType'))

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Title
        </Typography>
      </Grid>
      <Grid xs={6} item container spacing={3}>
        <Grid xs={6} item>
          <DropdownFieldSearch
            disabled={disableFields}
            name='isTitleInHand'
            label='Title-In-Hand?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid xs={6} item>
          <DropdownFieldSearch
            disabled={disableFields}
            name='titleState'
            label='Title State'
            options={states}
            optionKey='State'
            optionValue='StateName'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      </Grid>

      {carTitleType && (
        <Grid xs={6} item>
          <DropdownFieldSearch
            disabled={disableFields}
            name='titleTypeID'
            label='Title Type'
            options={carTitleType}
            optionKey='ID'
            optionValue='TitleType'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
      <Grid xs={6} item>
        <TextField disabled={disableFields} name='titleNumber' label='Title Number' fullWidth autoComplete='off' />
      </Grid>
      <Grid xs={6} item>
        <DateField
          disabled={disableFields}
          onDropdownFormChange={onDropdownFormChange}
          name='titleIssueDate'
          label='Title Issued Date'
          disableFuture
          fullWidth
        />
      </Grid>
    </Grid>
  )
}

export default TitleForm
