import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const AccountsForm = ({}: Props) => {
  const { values } = useFormState()

  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Accounts</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessAccounts' label='Access Accounts' />
      </Grid>
      {values.AccessAccounts && (
        <>
          <Grid>
            <CheckboxField name='EditAccounts' label='Edit Accounts' />
          </Grid>
          <Grid>
            <CheckboxField name='AddPayments' label='Add Payments' />
          </Grid>
          <Grid>
            <CheckboxField name='WriteOffPayments' label='Write Off Payments' />
          </Grid>
          <Grid>
            <CheckboxField name='VoidPayments' label='Void Payments' />
          </Grid>
          <Grid>
            <CheckboxField name='ChargebackPayments' label='Chargeback Payments' />
          </Grid>
          <Grid>
            <CheckboxField name='AdjustLateFees' label='Adjust Late Fees' />
          </Grid>
          <Grid>
            <CheckboxField name='WaiveChargebackFees' label='Waive Chargeback Fees' />
          </Grid>
          <Grid>
            <CheckboxField name='AddSideNotes' label='Add Side Notes' />
          </Grid>
          <Grid>
            <CheckboxField name='ReverseSideNotes' label='Reverse Side Notes' />
          </Grid>
          <Grid>
            <CheckboxField name='CancelPTP' label='Cancel PTP' />
          </Grid>
          <Grid>
            <CheckboxField name='EditAccountTracking' label='Edit Account Tracking' />
          </Grid>
          <Grid>
            <CheckboxField name='ProcessChargeOff' label='Process Charge Off' />
          </Grid>
          <Grid>
            <CheckboxField name='ReverseChargeOff' label='Reverse Charge Off' />
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

export default AccountsForm
