import { Box, Card, Grid, IconButton, Paper, Typography, generateUtilityClasses, useTheme } from '@mui/material'
import React, { Dispatch, SetStateAction } from 'react'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'

const classes = generateUtilityClasses('Container', ['titleWrap', 'firstRow'])

interface Report {
  id: number
  category: string
  title: string
  description: string
  link: null | string
  isFavorite: boolean
}

type OrganizedReports = {
  [category: string]: Report[]
}

interface Props {
  report: any
  updateReport: any
  setOrganizedReportsState: Dispatch<SetStateAction<OrganizedReports>>
}

const InsightsCard = ({ report, updateReport, setOrganizedReportsState }: Props) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const handleBookmarkClick = (reportName: string) => {
    updateReport({
      uspReportFavoriteUpdate: {
        reportName: reportName,
        isFavorite: !report.isFavorite,
      },
    }).then(() => {
      setOrganizedReportsState((prevOrganizedReports) => {
        const updatedOrganizedReports = { ...prevOrganizedReports }
        const updatedReports = updatedOrganizedReports[report.category].map((r) => {
          if (r.title === reportName) {
            return { ...r, isFavorite: !r.isFavorite }
          }
          return r
        })
        updatedOrganizedReports[report.category] = updatedReports
        return updatedOrganizedReports
      })
    })
  }

  const handleRedirect = (link: string) => {
    navigate(link)
  }

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        p: 4,
        gap: 1,
        height: '100%',
      }}
    >
      <Typography variant='subtitle1' fontWeight={700} sx={{ cursor: 'pointer' }} onClick={() => handleRedirect(report.link)}>
        {report.title}
      </Typography>
      <Typography sx={{ margin: '10px 0' }}>{report.description}</Typography>
      <Box display='flex' justifyContent='flex-end' sx={{ position: 'absolute', bottom: 10, right: 10 }}>
        <IconButton type='button' onClick={() => handleBookmarkClick(report.title)}>
          {report.isFavorite ? <BookmarkIcon sx={{ color: theme.palette.primary.main }} /> : <BookmarkBorderIcon sx={{ color: '#1570EF' }} />}
        </IconButton>
      </Box>
    </Card>
  )
}

export default InsightsCard
