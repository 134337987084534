import { UspDealProductDataViewResult } from '../../../../../models'
import { convertDateToString } from '../../../../../utils/dateUtils'
import removeMask from '../../../../../utils/removeMask'

export const transformToRequestValues = (values?: any): any => ({
  productCost: removeMask(values?.ProductCost),
  expirationMiles: values?.ExpirationMileage || null,
  expirationDate: convertDateToString(values?.Expires) as string,
  productTerm: parseFloat(values?.Term),
  vendorID: values?.Vendors,
})

export const transformToFormValues = (values?: UspDealProductDataViewResult): any => {
  return {
    Vendors: values?.VendorID,
    ProductPrice: values?.ProductPrice,
    ProductCost: values?.ProductCost,
    ExpirationMileage: values?.ExpirationMiles,
    Term: values?.ProductTerm?.toString() || '',
    Expires: values?.ExpirationDate,
  }
}
