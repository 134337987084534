import {
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { Box, styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-final-form'
import { visuallyHidden } from '@mui/utils'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { formatNumber } from '../../../../../utils/general'
import { useDeleteAccountingChartMutation } from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import AddChartOfAccountModal from '../../AddChartOfAccountsModal/AddChartOfAccountModal'
import Label from '../../../../../components/label/label'
import CustomPopover from '../../../../../components/custom-popover/custom-popover'
import { appRoute } from '../../../../../constants'
import { usePopover } from '../../../../../components/custom-popover'
import Iconify from '../../../../../components/iconify'

const classes = generateUtilityClasses('Grid', ['root', 'loading', 'activeItem', 'inactiveItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },

  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
}))

interface Props {
  data: any
  loading: boolean
  onSuccessfulSubmit: (val?: any) => void
  subTypeAccountDataIsLoading: any
  parentAccountDataIsLoading: any
  parentAccountData: any
  subTypeData: any
}
const ChartOfAccountsTableBlock = ({
  data,
  loading,
  onSuccessfulSubmit,
  parentAccountDataIsLoading,
  subTypeAccountDataIsLoading,
  parentAccountData,
  subTypeData,
}: Props) => {
  const popover = usePopover()
  const initialOrder = (storageGet('leads_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('leads_order_by') || 'StockNumber') as keyof any
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof any>(initialOrderBy)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<{ chart_of_account_id: string } | undefined>(undefined)
  const dispatch = useDispatch()
  const { submit } = useForm()
  const [open, setOpen] = useState(false)
  const [rowData, setRowData] = useState({})
  const [selectedItem, setSelectedItem] = useState<any>(null)
  const [deleteChartOfAccount, { isSuccess: deleteChartOfAccountIsSuccess }] = useDeleteAccountingChartMutation()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = data

  const handleClose = () => setOpen(false)

  const handleEdit = (item: any) => {
    setRowData(item)
    setOpen(true)
  }

  const handleDelete = () => {
    if (deleteConfirmDialog?.chart_of_account_id) {
      deleteChartOfAccount(deleteConfirmDialog)
    }
    setDeleteConfirmDialog(undefined)
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('leads_order', isAsc ? 'desc' : 'asc')
    storageSet('leads_order_by', property)
  }

  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  useEffect(() => {
    if (deleteChartOfAccountIsSuccess) {
      submit()
      dispatch(enqueueNotification({ message: 'Account removed' }))
    }
  }, [deleteChartOfAccountIsSuccess, dispatch])

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountNumber'}
                  direction={orderBy === 'AccountNumber' ? order : 'asc'}
                  onClick={createSortHandler('AccountNumber')}
                >
                  Number
                  {orderBy === 'AccountNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountName' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountName'}
                  direction={orderBy === 'AccountName' ? order : 'asc'}
                  onClick={createSortHandler('AccountName')}
                >
                  Name
                  {orderBy === 'AccountName' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'InterAccountSubTypeestLevel' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountSubType'}
                  direction={orderBy === 'AccountSubType' ? order : 'asc'}
                  onClick={createSortHandler('AccountSubType')}
                >
                  Sub-Type
                  {orderBy === 'AccountSubType' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'ClosingBalance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'ClosingBalance'}
                  direction={orderBy === 'ClosingBalance' ? order : 'asc'}
                  onClick={createSortHandler('ClosingBalance')}
                >
                  Closing
                  {orderBy === 'ClosingBalance' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Activity' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Activity'}
                  direction={orderBy === 'Activity' ? order : 'asc'}
                  onClick={createSortHandler('Activity')}
                >
                  Activity
                  {orderBy === 'Activity' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Balance' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Balance'}
                  direction={orderBy === 'Balance' ? order : 'asc'}
                  onClick={createSortHandler('Balance')}
                >
                  Current
                  {orderBy === 'Balance' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Status' ? order : false}>
                <TableSortLabel active={orderBy === 'Status'} direction={orderBy === 'Status' ? order : 'asc'} onClick={createSortHandler('Status')}>
                  Status
                  {orderBy === 'Status' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), data as any[])
              // ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item: any, index: number) => (
                <TableRow hover key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className={classes.tableCellName} onClick={() => handleEdit(item)}>
                    {item.AccountNumber}
                  </TableCell>
                  <TableCell>{item?.AccountName}</TableCell>
                  <TableCell>{item?.AccountSubType}</TableCell>
                  <TableCell>{formatNumber(item?.ClosingBalance)}</TableCell>
                  <TableCell>{formatNumber(item?.Activity)}</TableCell>
                  <TableCell>{formatNumber(item?.Balance)}</TableCell>
                  <TableCell>
                    <Label color={item.IsActive ? 'success' : 'error'}>{item.IsActive ? 'Active' : 'Inactive'}</Label>
                  </TableCell>
                  <TableCell align={'right'}>
                    <IconButton
                      color={popover.open ? 'inherit' : 'default'}
                      onClick={(e) => {
                        popover.onOpen(e)
                        setSelectedItem(item)
                      }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {data?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* {data && data?.length > 20 && (
        <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
          <Paper>
            <TablePagination
              component='div'
              count={data?.length as number}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )} */}

      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Are you sure you wish to deactivate this account?'}
      />
      <AddChartOfAccountModal
        subTypeAccountDataIsLoading={subTypeAccountDataIsLoading}
        parentAccountDataIsLoading={parentAccountDataIsLoading}
        parentAccountData={parentAccountData}
        subTypeData={subTypeData}
        onSuccessfulSubmit={onSuccessfulSubmit}
        rowData={rowData}
        open={open}
        handleClose={handleClose}
      />
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            handleEdit(selectedItem)
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => setDeleteConfirmDialog({ chart_of_account_id: selectedItem?.ChartOfAccountID?.toString() as string })}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </>
  )
}

export default ChartOfAccountsTableBlock
