import _ from 'lodash'
import { UspGetDataContact } from '../../../../models'
import removeMask from '../../../../utils/removeMask'

export const transformToRequestValues = (values?: any) => ({
  contactID: values?.ContactID || null,
  dealerCode: values?.ShortCode || null,
  salesBranchID: values?.SalesBranch || null,
  isRelatedDealer: values?.IsRelated,
  vendorID: values?.Vendor || null,
  website: values?.Website,
  accountNumber: values?.AccountNumber,
  contact: values?.Contact || null,
  note: values?.Comments || null,
  discountRate: values?.DiscountRate,
  discountAmount: removeMask(values?.DiscountAmount),
  discountEarningMethod: values?.DiscountEarningMethod,
  reserveAmount1: removeMask(values?.ReserveAmount1),
  reserveAmount2: removeMask(values?.ReserveAmount2),
  reserveAmount3: removeMask(values?.ReserveAmount3),
  reserveAmount4: removeMask(values?.ReserveAmount4),
  servicingRate: values?.ServicingRate,
  manager: values?.Manager || null,
  isActive: values?.IsActive,
  businessName: values?.Name,
  dba: values?.DBA,
  taxIDNumber: values?.TaxIDNumber,
  email: values?.Email,
  isContactUpdated: true,
  addressID: values?.AddressID,
  address1: values?.Address1,
  address2: values?.Address2,
  city: values?.City,
  state: values?.State,
  postalCode: values?.PostalCode,
  county: values?.County,
  addressType: values?.AddressType,
  isPrimaryAddress: true,
  isAddressActive: true,
  isAddressUpdated: true,
  isPhoneUpdated: true,
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.ContactPhoneID || null,
      PhoneNumber: values?.PhoneNumber,
      PhoneType: values?.PhoneType,
      PhoneExtension: values?.PhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
    {
      ContactPhoneID: values?.SecondaryContactPhoneID || null,
      PhoneNumber: values?.SecondaryPhoneNumber,
      PhoneType: values?.SecondaryPhoneType,
      PhoneExtension: values?.SecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
})

export const transformToFormValues = (dealerData?: any, contactData?: UspGetDataContact, dealerId?: any) => {
  // Initialize fields with default values
  let ContactPhoneID, PhoneNumber, PhoneExtension
  let SecondaryContactPhoneID, SecondaryPhoneNumber, SecondaryPhoneExtension
  let PhoneType = 'Biz'
  let SecondaryPhoneType = 'Fax'
  // Iterate over the ContactPhoneData array
  if (contactData?.ContactPhoneData) {
    for (const phoneData of contactData?.ContactPhoneData) {
      if (phoneData?.OrderOfPreference === 1) {
        ContactPhoneID = phoneData.ContactPhoneID
        PhoneNumber = phoneData.PhoneNumber
        PhoneType = phoneData.PhoneType || 'Biz'
        PhoneExtension = phoneData.PhoneExtension
      } else if (phoneData?.OrderOfPreference === 2) {
        SecondaryContactPhoneID = phoneData.ContactPhoneID
        SecondaryPhoneNumber = phoneData.PhoneNumber
        SecondaryPhoneType = phoneData.PhoneType || 'Fax'
        SecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }

  let DiscountType = 'rate'
  if (dealerData?.DiscountAmount > 0) {
    DiscountType = 'amount'
  }

  return {
    AccountType: 'business',
    Name: contactData?.BusinessName,
    DBA: contactData?.DBA,
    FirstName: contactData?.FirstName,
    MiddleName: contactData?.MiddleName,
    LastName: contactData?.LastName,
    Suffix: contactData?.Suffix,
    Nickname: contactData?.NickName,
    AddressID: _.first(contactData?.ContactAddressData)?.AddressID,
    Address1: _.first(contactData?.ContactAddressData)?.Address1,
    Address2: _.first(contactData?.ContactAddressData)?.Address2,
    City: _.first(contactData?.ContactAddressData)?.City,
    County: _.first(contactData?.ContactAddressData)?.County,
    State: _.first(contactData?.ContactAddressData)?.State,
    PostalCode: _.first(contactData?.ContactAddressData)?.PostalCode,
    Email: contactData?.Email,
    TaxIDNumber: contactData?.TaxIDNumber,
    ContactPhoneID,
    SecondaryContactPhoneID,
    PhoneType,
    PhoneNumber,
    PhoneExtension,
    SecondaryPhoneNumber,
    SecondaryPhoneExtension,
    SecondaryPhoneType,
    Attention: dealerData?.Attention,
    VendorType: dealerData?.VendorTypeID,
    NetDaysDue: dealerData?.NetDaysDue,
    IsStopPayment: dealerData?.IsStopPayment,
    Vendor1099: dealerId === 'add' ? null : dealerData?.IRS1099MiscType === null ? false : true,
    Type1099: dealerData?.IRS1099MiscType,
    Manager: dealerData?.Manager,

    ContactID: dealerData?.ContactID,
    ShortCode: dealerData?.DealerCode,
    IsActive: dealerId === 'add' ? true : dealerData?.IsActive,
    Contact: dealerData?.Contact,
    Website: dealerData?.Website,
    Comments: dealerData?.Note,

    AccountNumber: dealerData?.AccountNumber,
    Vendor: dealerData?.VendorID,
    IsRelated: dealerData?.IsRelatedDealer,
    SalesBranch: dealerData?.SalesBranchID,

    DiscountType,
    DiscountRate: dealerData?.DiscountRate?.toString(),
    DiscountAmount: dealerData?.DiscountAmount?.toString(),
    DiscountEarningMethod: dealerData?.DiscountEarningMethod,
    ServicingRate: dealerData?.ServicingRate?.toString(),

    ReserveAmount1: dealerData?.ReserveAmount1,
    ReserveAmount2: dealerData?.ReserveAmount2,
    ReserveAmount3: dealerData?.ReserveAmount3,
    ReserveAmount4: dealerData?.ReserveAmount4,
  }
}
