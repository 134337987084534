import React, { useEffect } from 'react'
import { Header } from './components/Header'
import { AccountingTableBlock } from './components/AccountingTableBlock'
import { useDispatch } from 'react-redux'
import { useGetAccountingLedgerMutation } from '../../../services'
import { setAccountingLedgers } from '../../../redux/slices/accountingLedgersSlice'
import { getCurrentDate } from '../../../utils/general'
import { Button, Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

export const AccountingList = () => {
  const dispatch = useDispatch()
  const [getAccountingLedgers, { data: accountingLedgerData, isSuccess, isLoading: accountingLedgerIsLoading }] = useGetAccountingLedgerMutation()

  useEffect(() => {
    getAccountingLedgers({
      uspAccountingLedgerView: {
        startDate: getCurrentDate(),
        endDate: getCurrentDate(),
      },
    })
  }, [])

  useEffect(() => {
    if (accountingLedgerData) {
      dispatch(setAccountingLedgers(accountingLedgerData))
    }
  }, [accountingLedgerData])

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Ledger View'
        links={[
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          height: { xs: 800, md: 2 },
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Header accountingLedgerData={accountingLedgerData} getAccountingLedgers={getAccountingLedgers} isSuccess={isSuccess} />
        <AccountingTableBlock loading={accountingLedgerIsLoading} accountingLedgerData={accountingLedgerData} />
      </Card>
    </Container>
  )
}

export default AccountingList
