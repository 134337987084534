import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '700px',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

const InclusionsCard = () => {
  const { values } = useFormState()

  return (
    <>
      <CardHeader title='Inclusions' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IncludePickupPayments'
            label='Include Pickup Payments?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
          />
        </Grid>
        {values.IncludePickupPayments && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='StrictlyPickupPayments'
              label='Strictly Pickup Payments?'
              options={[
                { key: true, value: 'Yes' },
                { key: false, value: 'No' },
              ]}
              autoComplete='off'
            />
          </Grid>
        )}

        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IncludeLender'
            label='Include Lender Serviced Accounts?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IncludeAccounts'
            label='Include Accounts with Future Promises?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='IncludeOutRepo'
            label='Include Out-For-Repo?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            autoComplete='off'
          />
        </Grid>
        {values.IncludeOutRepo && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='StrictlyOut'
              label='Strictly Out-For-Repo?'
              options={[
                { key: true, value: 'Yes' },
                { key: false, value: 'No' },
              ]}
              autoComplete='off'
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default InclusionsCard
