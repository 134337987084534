import { storageGet, storageSet } from '../../../../../utils/storage'
import { UspReportPaymentResult } from '../../../../../models'
import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  generateUtilityClasses,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { formatNumber } from '../../../../../utils/general'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    // width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportPaymentResult[]
  isLoading: boolean
}

const AccountingPaymentsTableBlock = ({ filterData, isLoading }: Props) => {
  const initialOrder = (storageGet('reports_accounting_payments_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('reports_accounting_payments_order_by') || 'Cashier') as keyof UspReportPaymentResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspReportPaymentResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportPaymentResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('reports_accounting_payments_order', isAsc ? 'desc' : 'asc')
    storageSet('reports_accounting_payments_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportPaymentResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Cashier' ? order : false}>
              <TableSortLabel active={orderBy === 'Cashier'} direction={orderBy === 'Cashier' ? order : 'asc'} onClick={createSortHandler('Cashier')}>
                Cashier
                {orderBy === 'Cashier' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Date' ? order : false}>
              <TableSortLabel active={orderBy === 'Date'} direction={orderBy === 'Date' ? order : 'asc'} onClick={createSortHandler('Date')}>
                Date
                {orderBy === 'Date' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Receipt' ? order : false}>
              <TableSortLabel active={orderBy === 'Receipt'} direction={orderBy === 'Receipt' ? order : 'asc'} onClick={createSortHandler('Receipt')}>
                Receipt
                {orderBy === 'Receipt' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Payee' ? order : false}>
              <TableSortLabel active={orderBy === 'Payee'} direction={orderBy === 'Payee' ? order : 'asc'} onClick={createSortHandler('Payee')}>
                Payee
                {orderBy === 'Payee' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock Number
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'PaymentType' ? order : false}>
              <TableSortLabel
                active={orderBy === 'PaymentType'}
                direction={orderBy === 'PaymentType' ? order : 'asc'}
                onClick={createSortHandler('PaymentType')}
              >
                Payment Type
                {orderBy === 'PaymentType' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Form' ? order : false}>
              <TableSortLabel active={orderBy === 'Form'} direction={orderBy === 'Form' ? order : 'asc'} onClick={createSortHandler('Form')}>
                Form
                {orderBy === 'Form' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Method' ? order : false}>
              <TableSortLabel active={orderBy === 'Method'} direction={orderBy === 'Method' ? order : 'asc'} onClick={createSortHandler('Method')}>
                Method
                {orderBy === 'Method' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Reference' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Reference'}
                direction={orderBy === 'Reference' ? order : 'asc'}
                onClick={createSortHandler('Reference')}
              >
                Reference
                {orderBy === 'Reference' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Authorization' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Authorization'}
                direction={orderBy === 'Authorization' ? order : 'asc'}
                onClick={createSortHandler('Authorization')}
              >
                Authorization
                {orderBy === 'Authorization' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Amount' ? order : false}>
              <TableSortLabel active={orderBy === 'Amount'} direction={orderBy === 'Amount' ? order : 'asc'} onClick={createSortHandler('Amount')}>
                Amount
                {orderBy === 'Amount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Processing' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Processing'}
                direction={orderBy === 'Processing' ? order : 'asc'}
                onClick={createSortHandler('Processing')}
              >
                Processing
                {orderBy === 'Processing' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Tendered' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Tendered'}
                direction={orderBy === 'Tendered' ? order : 'asc'}
                onClick={createSortHandler('Tendered')}
              >
                Tendered
                {orderBy === 'Tendered' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Change' ? order : false}>
              <TableSortLabel active={orderBy === 'Change'} direction={orderBy === 'Change' ? order : 'asc'} onClick={createSortHandler('Change')}>
                Change
                {orderBy === 'Change' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Transaction' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Transaction'}
                direction={orderBy === 'Transaction' ? order : 'asc'}
                onClick={createSortHandler('Transaction')}
              >
                Transaction
                {orderBy === 'Transaction' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{item.Cashier}</TableCell>
              <TableCell>{convertDateToDisplay(item.Date)}</TableCell>
              <TableCell>{item.Branch}</TableCell>
              <TableCell>{item.Receipt}</TableCell>
              <TableCell>{item.Payee}</TableCell>
              <TableCell>{item.StockNumber}</TableCell>
              <TableCell>{item.PaymentType}</TableCell>
              <TableCell>{item.Form}</TableCell>
              <TableCell>{item.Method}</TableCell>
              <TableCell>{item.Reference}</TableCell>
              <TableCell>{item.Authorization}</TableCell>
              <TableCell>{formatNumber(item.Amount)}</TableCell>
              <TableCell>{formatNumber(item.Processing)}</TableCell>
              <TableCell>{formatNumber(item.Tendered)}</TableCell>
              <TableCell>{formatNumber(item.Change)}</TableCell>
              <TableCell>{item.Transaction}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AccountingPaymentsTableBlock
