import React from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button, Tooltip } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/printer.svg'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download_icon.svg'
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter_icon.svg'
import { UspReportReceivablesResult } from '../../../../../models'
import * as XLSX from 'xlsx'
import { parseToNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'btnWrap'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 3, 1, 3),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
}))

interface Props {
  handleOpenFilterModal: () => void
  filterData: UspReportReceivablesResult[]
}

export const Header = ({ handleOpenFilterModal, filterData }: Props) => {
  const transformDataForExport = (data: UspReportReceivablesResult[]) => {
    return data?.map((item: UspReportReceivablesResult) => ({
      'Stock #': item.StockNumber,
      Customer: item.Customer,
      'Account Number': item.AccountNumber,
      Branch: item.Branch,
      Dealer: item.Dealer,
      Lender: item.Lender,
      Collector: item.Collector,
      Balance: parseToNumber(item.Balance),
      Pickups: parseToNumber(item.BalancePickups),
      'Side Notes': parseToNumber(item.BalanceSideNotes),
      Chargebacks: parseToNumber(item.BalanceChargeback),
      'Late Charges': parseToNumber(item.BalanceLateCharge),
      'Unearned Interest': parseToNumber(item.UnearnedInterest),
      'Unearned Discount': parseToNumber(item.UnearnedDiscount),
      Total: parseToNumber(item.Total),
    }))
  }

  const setColumnWidths = (worksheet: any) => {
    const columnWidths = [
      { width: 20 }, // Stock
      { width: 20 }, // Customer
      { width: 15 }, // Account
      { width: 10 }, // Branch
      { width: 10 }, // Dealer
      { width: 15 }, // Lender
      { width: 15 }, // Collector
      { width: 15 }, // Balance
      { width: 15 }, // Pickups
      { width: 15 }, // Side Notes
      { width: 15 }, // Chargebacks
      { width: 15 }, // Late Charges
      { width: 20 }, // Unearned Interest
      { width: 20 }, // Unearned Discount
      { width: 15 }, // Total
    ]

    worksheet['!cols'] = columnWidths
  }

  const exportToExcel = (data: any, fileName: any) => {
    const transformedData = transformDataForExport(data)
    const worksheet = XLSX.utils.json_to_sheet(transformedData)
    setColumnWidths(worksheet)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }
  return (
    <>
      <Grid container justifyContent={'flex-end'} p={3} alignItems={'center'}>
        <Grid item>
          <Tooltip title='Download'>
            <Button variant='text' className={classes.btnWrap} onClick={() => exportToExcel(filterData, 'Receivables')}>
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Print'>
            <Button variant='text' className={classes.btnWrap}>
              <PrintIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Filter'>
            <Button onClick={handleOpenFilterModal} variant='text' className={classes.btnWrap}>
              <FilterIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  )
}

export default Header
