import { httpGet, httpPost } from './http'

export const createApi = (endpoint: string, headersInit?: HeadersInit) => {
  const headers = new Headers(headersInit)

  return {
    get: <T>(path: string) => httpGet<T>({ url: endpoint + path, headers }),
    post: <T>(path: string, data?: unknown) => httpPost<T>({ url: endpoint + path, data, headers }),
    put: (path: string) => httpGet({ url: endpoint + path, headers }),
    patch: (path: string) => httpGet({ url: endpoint + path, headers }),
    delete: (path: string) => httpGet({ url: endpoint + path, headers }),
    setHeader: (name: string, value: string) => headers.set(name, value),
    removeHeader: (name: string) => headers.delete(name),
  }
}
