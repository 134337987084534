import React, { useEffect, useState, useMemo, Dispatch, SetStateAction, MutableRefObject, RefObject } from 'react'
import Button from '@mui/material/Button'
import { useForm, useFormState } from 'react-final-form'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { CalculateDealResult, MaturityDateResult, TaxesResult } from '../../../../../../../models'
import { Ref } from 'react-hook-form'
import removeMask from '../../../../../../../utils/removeMask'
import { round } from 'lodash'

interface Props {
  valid?: boolean
  taxesData?: TaxesResult
  calculationForDealData?: CalculateDealResult
  calculationForDealIsSuccess: boolean
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  setDealStructureFormValues: (values: any) => void
  maturityDateForDealData?: MaturityDateResult | null
  maturityDateForDealIsSuccess: boolean
  setDisableCalculateBtn?: Dispatch<SetStateAction<boolean>>
  dealType: string | undefined
  taxesIsSuccess: boolean
  setCurrentStep: Dispatch<SetStateAction<number>>
  onClick?: () => void
  forwardRef?: any
}

export const NextButton = ({
  taxesData,
  calculationForDealData,
  calculationForDealIsSuccess,
  setCurrentStage,
  setDealStructureFormValues,
  maturityDateForDealData,
  maturityDateForDealIsSuccess,
  setDisableCalculateBtn,
  dealType,
  taxesIsSuccess,
  setCurrentStep,
  onClick,
  forwardRef,
}: Props) => {
  const { change } = useForm()
  const { values } = useFormState()
  const [calculatedValues, setCalculatedValues] = useState<any>({})

  const handleSetCalculatedValues = () => {
    setCalculatedValues(values)
  }

  const arrFieldsToCompare = [
    'DealType',
    'DealState',
    'SalesPrice',
    'DealDate',
    'Fees',
    'Products',
    'TradeAllowance',
    'TradeLien',
    'TradeACV',
    'CashDown',
    'PickupPayments',
    'DealPostalCode',
    'TaxCity',
    'TaxCounty',
    'StateTaxRate',
    'CityTaxRate',
    'CountyTaxRate',
    'MiscTaxRate',
    'DealHasTrade',
    'TradeAllowance',
    'TradeLien',
    'TradeACV',
    'CashDown',
    'PickupPayments',
    'Schedule',
    'FirstDueDay',
    'SecondDueDay',
    'FirstDueDate',
    'Calculate',
    'DesiredPayment',
    'Term',
    'InterestRate',
    'DesiredTerm',
    'NumberOfYears',
  ]
  const compareFieldsOfObjects = (obj1: any, obj2: any) => {
    let objectIsEqualInFeilds = true
    arrFieldsToCompare?.forEach((field) => {
      if (obj1[field] !== obj2[field]) {
        objectIsEqualInFeilds = false
      }
    })

    return objectIsEqualInFeilds
  }

  useEffect(() => {
    if (maturityDateForDealIsSuccess) {
      console.log('maturityDateForDealData', maturityDateForDealData)
      change('MaturityDate', maturityDateForDealData?.MaturityDate?.toString())
      handleSetCalculatedValues()
    }
    if (taxesIsSuccess) {
      handleSetCalculatedValues()
    }
  }, [maturityDateForDealData, maturityDateForDealIsSuccess, taxesIsSuccess])

  const isCalculatedValueChangedMemorized = useMemo(() => compareFieldsOfObjects(values, calculatedValues), [values, calculatedValues])

  useEffect(() => {
    let amountFinanced = round(
      Number(removeMask(values.SalesPrice)) +
        Number(removeMask(values.AcquisitionFees)) +
        Number(removeMask(values.Products)) +
        Number(removeMask(values.Fees)) -
        Number(removeMask(values.CashDown)) -
        Number(removeMask(values.ResidualValue)),
      2,
    )

    let rentPmt = round(
      (Number(removeMask(values.SalesPrice)) +
        Number(removeMask(values.AcquisitionFees)) +
        Number(removeMask(values.Products)) +
        Number(removeMask(values.Fees)) -
        Number(removeMask(values.CashDown)) +
        Number(removeMask(values.ResidualValue))) *
        Number(removeMask(values.leaseFactor)),
      2,
    )

    let depPmt = round(
      (Number(removeMask(values.SalesPrice)) +
        Number(removeMask(values.AcquisitionFees)) +
        Number(removeMask(values.Products)) +
        Number(removeMask(values.Fees)) -
        Number(removeMask(values.CashDown)) -
        Number(removeMask(values.ResidualValue))) /
        Number(removeMask(values.DesiredTerm)),
      2,
    )

    let amountPaymentAmount = rentPmt + depPmt
    let amountFinanceCharges = round(values.DesiredTerm * amountPaymentAmount - amountFinanced, 2)
    let totalOfPayments = amountFinanced + amountFinanceCharges
    let salesTaxPerPayment = round((amountPaymentAmount * Number(removeMask(values.total_tax))) / 100, 2)

    change('AmountFinanced', amountFinanced?.toString())
    change('StateTaxAmount', '0')
    change('CityTaxAmount', '0')
    change('CountyTaxAmount', '0')
    change('MiscTaxAmount', '0')
    change('AmountSalesTax', '0')
    change('DocStampsAmount', '0')

    change('AmountPaymentAmount', round(amountPaymentAmount, 2)?.toString())
    change('AmountFinalPayment', '0')
    change('AmountFinalTerm', values.DesiredTerm?.toString())
    change('AmountFinanceCharges', amountFinanceCharges?.toString())
    change('SalesTaxPerPayment', salesTaxPerPayment?.toString())
    change('TotalOfPayments', round(totalOfPayments, 2)?.toString())
    change('APRRate', '0')
    change('FinalPaymentAmount', '0')
  }, [values])

  useEffect(() => {
    setDisableCalculateBtn && setDisableCalculateBtn(isCalculatedValueChangedMemorized)
  }, [isCalculatedValueChangedMemorized])

  const handleNext = () => {
    if (onClick) {
      onClick()
      return
    }
    setDealStructureFormValues(values)
    setCurrentStage('dealResult')
    setCurrentStep((prev) => prev + 1)
  }

  return (
    <Button
      ref={forwardRef}
      endIcon={<ArrowForwardIosIcon />}
      // disabled={!isCalculatedValueChangedMemorized}
      onClick={handleNext}
    >
      Next
    </Button>
  )
}

export default NextButton
