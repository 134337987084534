import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { AmountField, PercentField, TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap', 'titleWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(5, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

const AdminForm = ({ onDropdownFormChange }: Props) => {
  const { values } = useFormState()
  return (
    <Grid container item spacing={2} p={3}>
      <Grid item xs={12} md={6}>
        <TextField required name='SupportEmail' label='Support Email' fullWidth />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextField required name='ActiveSession' label='Active Session Time (minutes)' fullWidth />
      </Grid>

      <Grid item xs={12} md={6}>
        <DropdownFieldSearch
          required
          name='TwoFactorAuthentication'
          label='Two Factor Authentication?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          optionKey='key'
          optionValue='value'
          optionNone
          fullWidth
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DropdownFieldSearch
          required
          name='DisplayNames'
          label='Display Names By'
          options={[
            { key: 'F', value: 'First Middle Last' },
            { key: 'L', value: 'Last First Middle' },
          ]}
          optionKey='key'
          optionValue='value'
          optionNone
          fullWidth
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DropdownFieldSearch
          required
          name='RequireApproval'
          label='Require Approval to Pay Bills?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          optionKey='key'
          optionValue='value'
          optionNone
          fullWidth
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <PercentField variant='standard' required name='MarkUpRate' label='Mark-up Rate (%)' fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <AmountField variant='standard' required name='MarkUpAmount' label='Mark-up Amount ($)' fullWidth />
      </Grid>
      <Grid item xs={12} md={6}>
        <DropdownFieldSearch
          name='AutoAssignStockNumber'
          label='Auto-Assign Stock Number using VIN?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          optionKey='key'
          optionValue='value'
          optionNone
          fullWidth
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      {values.AutoAssignStockNumber && (
        <Grid item xs={12} md={6}>
          <TextField type='number' name='NumberOfCharacters' label='Number of Characters to use from VIN' fullWidth />
        </Grid>
      )}
    </Grid>
  )
}

export default AdminForm
