import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import RadioField from '../../../../../ui/RadioField'
import { getContactSuffixes } from '../../../../../redux/slices/userDropdownSlice'
import { userLabels } from '../../../../../validation/userValidation'
import { Field, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const NameForm = ({}: Props) => {
  const { values } = useFormState()
  const contactSuffixes = useSelector(getContactSuffixes)
  const accountOptions: any[] = [
    { key: 'individual', value: 'Individual' },
    { key: 'business', value: 'Business' },
  ]

  return (
    <>
      <CardHeader title='Name' />
      <Grid container spacing={2} px={3} pt={3}>
        <Field name='accountType'>
          {(props) => (
            <div>
              <FormControlLabel
                sx={{ m: 2, top: 18, position: 'absolute', left: 80 }}
                control={
                  <Switch
                    name={props.input.name}
                    checked={props.input.value === 'business'}
                    onChange={(e) => {
                      props.input.onChange(e.target.checked ? 'business' : 'individual')
                    }}
                  />
                }
                label='Business'
              />
            </div>
          )}
        </Field>
        {values?.accountType === 'individual' ? (
          <>
            <Grid item xs={6}>
              <TextField name='firstName' label={userLabels.firstName} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextField name='middleName' label='Middle Name' fullWidth />
            </Grid>
            <Grid item xs={4}>
              <TextField name='lastName' label={userLabels.lastName} fullWidth />
            </Grid>
            <Grid item xs={2}>
              <DropdownFieldSearch name='suffix' label='Suffix' options={contactSuffixes} optionKey='Suffix' optionValue='Suffix' optionNone />
            </Grid>{' '}
            <Grid item xs={6}>
              <TextField name='nickName' label={userLabels.nickName} fullWidth />
            </Grid>
          </>
        ) : (
          <Grid item xs={6}>
            <TextField name='businessName' label={userLabels.businessName} fullWidth />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default NameForm
