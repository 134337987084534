import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'
import Stack, { StackProps } from '@mui/material/Stack'
import Label, { LabelColor } from '../../../../components/label'
import { formatNumber } from '../../../../utils/general'

// ----------------------------------------------------------------------
type Props = PaperProps &
  StackProps & {
    name?: string
    AddedBy?: string
    Amount?: string | null
    ReferralStatus?: string
    ReferralStatusColor?: string
    action?: React.ReactNode
  }

type StatusMapping = {
  'Requirements Met': LabelColor
}

export default function ReferralItem({ name, AddedBy, Amount, sx, action, ReferralStatus, ReferralStatusColor, ...other }: Props) {
  const statusMapping: StatusMapping = {
    'Requirements Met': 'success',
  }
  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-end' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack flexGrow={1} spacing={1}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2'>{name}</Typography>
          {/* {ReferralStatus && (
            <Label color={ReferralStatusColor as unknown as LabelColor} sx={{ ml: 1 }}>
              {ReferralStatus}
            </Label>
          )} */}
        </Stack>

        {AddedBy && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Added By {AddedBy}
          </Typography>
        )}
        {Amount && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Fee: ${formatNumber(Amount)}
          </Typography>
        )}
      </Stack>

      {action && action}
    </Stack>
  )
}
