import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import BillPaySearchBlock from './BillPaySearchBlock/BillPaySearchBlock'
import BillPayTableBlock from './BillPayTableBlock/BillPayTableBlock'
import {
  selectDropdownByName,
  useAccountingCheckBillMutation,
  useLazyGetAccountingCashAccountsQuery,
  useLazyGetAccountingUnpaidBillsQuery,
  useLazyGetAccountingUnpaidVendorsQuery,
} from '../../../services'
import validateFormValues from '../../../utils/validateForm'
import { accountingBillPayValidation } from '../../../validation/accountingBillPayValidation'
import BillPaySummary from './BillPaySummary/BillPaySummary'
import { withPermissionsDropdown } from '../../../hoc'
import { transformToFormValues } from './BillPaySummary/transform'
import { convertStringToDate } from '../../../utils/dateUtils'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { AccountingUnpaidBillsData } from '../../../models'
import { CustomCircular } from '../../../ui'
import { appRoute } from '../../../constants'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Container, Grid, Typography } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { formatNumber } from '../../../utils/general'

const AccountingBillPay = () => {
  const [getUnpaidBills, { data: unpaidBillsData, isSuccess: unpaidBillsIsSuccess, isLoading: unpaidBillsIsLoading }] =
    useLazyGetAccountingUnpaidBillsQuery()
  const [getUnpaidVendors, { data: unpaidVendorsData, isLoading: unpaidVendorsIsLoading }] = useLazyGetAccountingUnpaidVendorsQuery()
  const [addBillPay, { isSuccess: addBillPayIsSuccess, isLoading: addBillPayIsLoading }] = useAccountingCheckBillMutation()
  const [totalSum, setTotalSum] = useState(0)
  const userAccess = useSelector(getUserAccessRights)
  const navigate = useNavigate()
  const [summaryData, setSummaryData] = useState<AccountingUnpaidBillsData[]>([])
  const [initialValues, setInitialValues] = useState({})
  const [stage, setCurrentStage] = useState<'bill-pay-table' | 'bill-pay-summary' | null>('bill-pay-table')
  const [getCashAccounts, { data: cashAccountsData, isLoading: cashAccountsIsLoading }] = useLazyGetAccountingCashAccountsQuery()
  const [selectedVendor, setSelectedVendor] = useState<number | undefined>(undefined)
  const [displayedBillsData, setDisplayedBillsData] = useState<AccountingUnpaidBillsData[]>([])

  const dispatch = useDispatch()
  const billPayType = useSelector((state) => selectDropdownByName(state, 'BillPayType'))

  useEffect(() => {
    setDisplayedBillsData(unpaidBillsData || [])
  }, [unpaidBillsData])

  useEffect(() => {
    getUnpaidVendors({})
    getCashAccounts({})
  }, [addBillPayIsSuccess])

  useEffect(() => {
    setInitialValues(transformToFormValues(cashAccountsData, unpaidVendorsData, selectedVendor))
  }, [cashAccountsData, unpaidVendorsData, selectedVendor])

  const transformSummaryData = (summaryData: any) => {
    return summaryData.map((data: any) => {
      return {
        BillID: data.BillID,
        PaidAmount: data.Amount,
      }
    })
  }

  const handleSubmit = (values?: any) => {
    if (stage === 'bill-pay-table') {
      getUnpaidBills({ vendorId: values?.vendor as string })
    }
    if (stage === 'bill-pay-summary') {
      const jsonBillData = transformSummaryData(summaryData)

      addBillPay({
        accountingCheckBill: {
          checkDate: convertStringToDate(values?.PaymentDate) as string,
          vendorID: selectedVendor as number,
          checkNumber: values?.CheckNumber || null,
          bankCoaID: values?.BankAccount || null,
          bankBranchID: values?.BankBranch || null,
          checkMemo: values?.Memo || null,
          payToTheOrderOff: values?.PayeeName || null,
          payeeAddress1: values?.Address1 || null,
          payeeAddress2: values?.Address2 || null,
          payeeCity: values?.City || null,
          payeeState: values?.State || null,
          payeePostalCode: values?.ZipCode || null,
          attention: values?.Attention || null,
          jsonBillData: jsonBillData,
          billPayTypeID: values?.BillPayType || null,
        },
      })
    }
  }

  useEffect(() => {
    if (addBillPayIsSuccess) {
      setSelectedVendor(undefined)
      setCurrentStage('bill-pay-table')
      setTotalSum(0)
      setSummaryData([])
      setDisplayedBillsData([]) // Clear the displayed bills data
      dispatch(enqueueNotification({ message: 'Bills paid successfully.' }))
    }
  }, [addBillPayIsSuccess])

  useEffect(() => {
    setSummaryData([])
  }, [selectedVendor])

  useEffect(() => {
    if (!userAccess.CanPayBills) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])
  const isLoading = useMemo(
    () => unpaidBillsIsLoading || unpaidVendorsIsLoading || addBillPayIsLoading || cashAccountsIsLoading,
    [unpaidBillsIsLoading, unpaidVendorsIsLoading, addBillPayIsLoading, cashAccountsIsLoading],
  )
  const checkID = billPayType?.ReturnData?.find((item) => item?.BillPayType === 'Check')?.ID ?? null
  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Bill Pay'
        links={[
          {
            name: 'List',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />

      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validateFormValues(accountingBillPayValidation(checkID as number, stage as string))}
      >
        {({ handleSubmit, errors, form: { submit } }: any) => (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
            }}
          >
            <Card
              sx={{
                flexGrow: { md: 1 },
                display: { md: 'flex' },
                flexDirection: { md: 'column' },
              }}
            >
              <BillPaySearchBlock
                setSelectedVendor={setSelectedVendor}
                stage={stage as string}
                submit={submit}
                unpaidVendorsData={unpaidVendorsData}
              />
              {stage === 'bill-pay-table' && (
                <>
                  <BillPayTableBlock
                    totalSum={totalSum}
                    setTotalSum={setTotalSum}
                    setCurrentStage={setCurrentStage}
                    setSummaryData={setSummaryData}
                    summaryData={summaryData}
                    unpaidBillsIsSuccess={unpaidBillsIsSuccess}
                    loading={unpaidBillsIsLoading}
                    unpaidBillsData={displayedBillsData}
                    errors={errors}
                  />

                  <Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'} p={3}>
                    <Grid item>
                      <Typography variant='h6'>Total: {formatNumber(totalSum)}</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        type='button'
                        variant='contained'
                        disabled={totalSum <= 0 || Object.keys(errors).length > 0}
                        onClick={() => {
                          setCurrentStage('bill-pay-summary')
                        }}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Card>

            {stage === 'bill-pay-summary' && (
              <Card
                sx={{
                  flexGrow: { md: 1 },
                  display: { md: 'flex' },
                  flexDirection: { md: 'column' },
                }}
              >
                <BillPaySummary
                  checkID={checkID as number}
                  cashAccountsData={cashAccountsData}
                  summaryData={summaryData}
                  setCurrentStage={setCurrentStage}
                  setDisplayedBillsData={setDisplayedBillsData}
                />
              </Card>
            )}
            <CustomCircular open={isLoading} />
          </form>
        )}
      </Form>
    </Container>
  )
}

export default withPermissionsDropdown(AccountingBillPay)
