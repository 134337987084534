import * as yup from "yup";
import { SessionFormFields } from "../models";

export const sessionLabels: SessionFormFields = {
  branch: "Branch",
  companyType: "Company Type",
  client: "Client",
};

export const sessionValidation: yup.SchemaOf<SessionFormFields> = yup
  .object({
    branch: yup.string().required().label(sessionLabels.branch),
    companyType: yup.string().required().label(sessionLabels.companyType),
    client: yup.string().required().label(sessionLabels.client),
  })
  .required();
