import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Header } from '../components/Header'
import { generateUtilityClasses } from '@mui/material'
import { withCars } from '../../../hoc'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
}))

export const InventoryEditPrint = (props: any) => {
  const { carData } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [formChanged, setFormChanged] = useState(false)

  useEffect(() => {
    dispatch(enqueueNotification({ message: 'Coming soon' }))
    navigate(-1)
  }, [])

  return (
    <StyledGrid className={classes.root}>
      <Header
        modelYear={carData?.ModelYear}
        make={carData?.Make}
        model={carData?.Model}
        vin={carData?.VIN}
        stockNumber={carData?.StockNumber}
        carIsSuccess={props?.carIsSuccess}
        carData={props?.carData}
        getCar={props?.getCar}
        formChanged={formChanged}
        setFormChanged={setFormChanged}
        onDropdownFormChange={() => setFormChanged(true)}
      />
      <h1>Print</h1>
    </StyledGrid>
  )
}

export default withCars(InventoryEditPrint)
