import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { generateUtilityClasses, Typography } from '@mui/material'
import { styled } from '@mui/system'
import Header from '../Header/Header'
import PrintIcon from '@mui/icons-material/Print'
import { CheckboxField } from '../../../ui'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import { AccountsOverviewResult, UspDealFlagViewResult } from '../../../models'
import { withAccounts } from '../../../hoc/withAccounts'
import AccountsNoteHistoryTableBlock from './components/AccountsNoteHistoryTableBlock'
import { useAddDealsFlagMutation, useGetAccountNotesHistoryMutation, useWorklistNextAccountMutation } from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { getWorklistAccountID, getWorklistID } from '../../../redux/slices/collectionsSlice'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Grid', ['root', 'subHeaderWrap', 'printBtn', 'titleWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.subHeaderWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(3, 3, 3, 3),
  },
  // [`& .${classes.printBtn}`]: {
  //   backgroundColor: theme.palette.background.purple,
  // },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
}))

interface Props {
  accountsData: AccountsOverviewResult
  accountsIsLoading: boolean
  accountsIsSuccess: boolean
}

const AccountsNoteHistory = ({ accountsData }: Props) => {
  const { dealID } = useParams()
  const dispatch = useDispatch()
  const worklistAccountID = useSelector(getWorklistAccountID)
  const worklistID = useSelector(getWorklistID)
  const [includeDeletedNotes, setIncludeDeletedNotes] = useState(false)
  const [getAccountNotesHistory, { data, isLoading }] = useGetAccountNotesHistoryMutation()
  const [nextAccount, { data: nextAccountData, isSuccess: nextAccountIsSuccess }] = useWorklistNextAccountMutation()
  const [isAddEditFlagModalOpen, setIsAddEditFlagModalOpen] = useState(false)
  const [addAccountFlags, { isSuccess: addAccountFlagIsSuccess, isLoading: addAccountFlagsIsLoading }] = useAddDealsFlagMutation()
  const [selectedFlag, setSelectedFlag] = useState<UspDealFlagViewResult | undefined>()
  const [refresh, setRefresh] = useState(false)
  const userAccess = useSelector(getUserAccessRights)
  const disableEditIcons = userAccess?.CanEditAccount

  useEffect(() => {
    getAccountNotesHistory({
      accountNotesHistory: {
        dealID: parseInt(dealID as string),
        isIncludeDeletedNotes: includeDeletedNotes,
        isImportantMessagesOnly: false,
        creditAppID: null,
        contactID: null,
      },
    })
  }, [includeDeletedNotes])

  useEffect(() => {
    if (addAccountFlagIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setIsAddEditFlagModalOpen(false)
    }
  }, [addAccountFlagIsSuccess])

  const handleNextAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: true,
        },
      })
    }
  }

  const handlePreviousAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: false,
        },
      })
    }
  }

  const handleSubmit = () => {}

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {({}: any) => (
          <>
            <StyledGrid className={classes.root}>
              <Header
                disableEditIcons={disableEditIcons}
                model={accountsData?.CarDescription}
                vin={accountsData?.VIN}
                stockNumber={accountsData?.StockNumber}
                buyerName={accountsData?.BuyerName}
                coBuyerName={accountsData?.CoBuyerName}
                getAccountNotesHistory={getAccountNotesHistory}
                includeDeletedNotes={includeDeletedNotes}
                branchID={accountsData?.BranchID}
                isAccountActive={accountsData?.IsAccountActive}
                isDeficiency={accountsData?.IsDeficiency}
                worklistAccountID={worklistAccountID}
                worklistID={worklistID}
                nextAccountData={nextAccountData}
                nextAccountIsSuccess={nextAccountIsSuccess}
                handleNextAccount={handleNextAccount}
                handlePreviousAccount={handlePreviousAccount}
                contactID1={accountsData?.ContactID1}
                contactID2={accountsData?.ContactID2}
                buyerAddress={accountsData?.BuyerAddressData}
                coBuyerAddress={accountsData?.CoBuyerAddressData}
                buyerPhone={accountsData?.BuyerPhoneData}
                coBuyerPhone={accountsData?.CoBuyerPhoneData}
                coBuyerWorkPhone={accountsData?.CoBuyerWorkPhone}
                isAddEditFlagModalOpen={isAddEditFlagModalOpen}
                setIsAddEditFlagModalOpen={setIsAddEditFlagModalOpen}
                addAccountFlags={addAccountFlags}
                selectedFlag={selectedFlag}
                setSelectedFlag={setSelectedFlag}
              />
              <Grid container className={classes.subHeaderWrap}>
                <Grid item className={classes.titleWrap}>
                  <Typography variant='h3'>Note History</Typography>
                  <CheckboxField
                    name='includeCancelledNotes'
                    label='Include Cancelled Notes'
                    onChange={(e) => setIncludeDeletedNotes(e?.target?.checked)}
                  />
                </Grid>
                <Grid item>
                  <Button className={classes.printBtn} variant='contained' startIcon={<PrintIcon />}>
                    Notes
                  </Button>
                </Grid>
              </Grid>
              <AccountsNoteHistoryTableBlock setRefresh={setRefresh} loading={isLoading} accountNoteHistoryData={data as any} />
            </StyledGrid>
          </>
        )}
      </Form>
    </>
  )
}

export default withAccounts(AccountsNoteHistory)
