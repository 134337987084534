import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Tooltip, Button } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useDispatch } from 'react-redux'
import { useForm, useFormState } from 'react-final-form'
import { withPermissionsDropdown } from '../../../../../../hoc'
import { AccountsOverviewResult, FormsPromptsApiResponse, UserPermissionsDropdown, UspGetDataDealTaskResult } from '../../../../../../models'
import { ReactComponent as PrintIcon } from '../../../../../../assets/icons/printer.svg'
import { enqueueNotification } from '../../../../../../redux/slices/notificationsSlice'
import PromptsModal from '../PromptsModal/PromptsModal'
import { applyPhoneMask } from '../../../../../../utils/general'
import { ReactComponent as CircleIcon } from '../../../../../../assets/icons/circle.svg'
import { useParams } from 'react-router-dom'

const classes = generateUtilityClasses('Container', [
  'root',
  'titleWrap',
  'searchWrap',
  'checkboxWrap',
  'searchBtn',
  'popoverWrap',
  'btnWrap',
  'titleWrap',
  'mainDataWrap',
  'secondaryData',
  'iconSeparator',
  'iconBtn',
])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 1, 2, 1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  [`& .${classes.checkboxWrap}`]: {
    margin: theme.spacing(0, 4, 0, 0),
    fontSize: '14px',
  },
  [`& .${classes.searchBtn}`]: {
    width: '120px',
    margin: theme.spacing(0, 2, 0, 'auto'),
  },
  [`& .${classes.popoverWrap}`]: {
    width: '3000px',
    padding: theme.spacing(4),
  },
  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },

  [`& .${classes.titleWrap}`]: {
    // paddingRight: theme.spacing(2),
  },
  [`& .${classes.mainDataWrap}`]: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  [`& .${classes.secondaryData}`]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.iconSeparator}`]: {
    margin: theme.spacing(0, 1, 0, 1),
    fill: theme.palette.primary.main,
  },
  [`& .${classes.iconBtn}`]: {
    padding: theme.spacing(0.5),
  },
}))
interface Props {
  submit: (...args: any) => any
  userPermissionsDropdown?: UserPermissionsDropdown
  isAnyRecordChecked: boolean
  formsPrompts: (e: any) => void
  formsBilling: (e: any) => void
  selectedRows: []
  formsPromptsIsSuccess: boolean
  formsPromptsData: FormsPromptsApiResponse
  headerData: AccountsOverviewResult | UspGetDataDealTaskResult
  printForm: (e: any) => void
  printFormIsLoading: boolean
  printFormIsSuccess: boolean
  additionalData: any
}

export const PrintFormsSearchBlock = ({
  submit,
  isAnyRecordChecked,
  formsPrompts,
  selectedRows,
  formsBilling,
  formsPromptsIsSuccess,
  formsPromptsData,
  headerData,
  printForm,
  printFormIsLoading,
  printFormIsSuccess,
  additionalData,
}: Props) => {
  const { values } = useFormState()
  const { dealID } = useParams()
  const dispatch = useDispatch()
  const form = useForm()
  const [isPromptsModalOpen, setIsPromptsModalOpen] = useState(false)
  console.log(additionalData)
  useEffect(() => {
    if (values?.State !== '' || values?.State !== null) {
      submit(values)
    }
  }, [values?.State])

  useEffect(() => {
    if (values?.FormPack) {
      submit(values)
    }
  }, [values.FormPack])

  const handleSubmitFormsPrompts = () => {
    formsPrompts({
      uspGetDataFormPrompts: {
        formIDData: selectedRows,
      },
    })
  }

  const handleAfterFormPrompt = (additionalData: any) => {
    const formValues = form.getState().values

    const printFormDeal = selectedRows
      ?.filter((row: any) => formValues[`AmountToPayCheck${row.ID}`]) // Filter out only checked rows
      ?.map((row: any) => {
        const copies = formValues[`Amount${row.ID}`] || 0 // Get the number of copies from the form state

        // Map the prompts for each row
        const prompts = formsPromptsData?.map((item: any, index: any) => ({
          Name: item.Prompt,
          Value: formValues['value' + index] || '', // Retrieve the value from the text field
        }))

        return {
          FormID: row.ID,
          Copies: parseInt(copies, 10),
          Prompts: prompts,
          ...additionalData,
        }
      })

    printForm({
      dealId: dealID?.toString() as string,
      printFormDeal,
    })
  }

  useEffect(() => {
    if (formsPromptsIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      if (formsPromptsData?.length >= 1) {
        setIsPromptsModalOpen(true)
      } else {
        handleAfterFormPrompt(additionalData)
        formsBilling({
          dealId: dealID?.toString() as string,
          uspForBilling: {
            formIDData: selectedRows,
          },
        })
        form.reset()
      }
    }
  }, [formsPromptsIsSuccess, formsPromptsData])

  const handleClosePromptsModal = () => {
    setIsPromptsModalOpen(false)
  }

  return (
    <StyledFormWrap className={classes.root}>
      {/* <Grid item className={classes.searchWrap}>
        <Tooltip title='Print'>
          <Button
            color='success'
            variant='text'
            className={classes.btnWrap}
            disabled={!isAnyRecordChecked}
            onClick={() => handleSubmitFormsPrompts()}
          >
            <PrintIcon />
          </Button>
        </Tooltip>
      </Grid> */}
      <PromptsModal
        printFormIsSuccess={printFormIsSuccess}
        printFormIsLoading={printFormIsLoading}
        printForm={printForm}
        selectedRows={selectedRows}
        formsPromptsData={formsPromptsData}
        open={isPromptsModalOpen}
        onClose={handleClosePromptsModal}
        formsBilling={formsBilling}
        additionalData={additionalData}
      />
    </StyledFormWrap>
  )
}
export default withPermissionsDropdown(PrintFormsSearchBlock)
