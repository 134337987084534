import dayjs from 'dayjs'
import * as yup from 'yup'

const today = dayjs().endOf('day')

export const DealInsuranceValidation: yup.SchemaOf<any> = yup
  .object({
    EffectiveDate: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed'),
  })
  .required()
