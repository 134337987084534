import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button, Tooltip } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField, DropdownCheckboxes } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import AddIcon from '@mui/icons-material/Add'
import { withPermissionsDropdown } from '../../../../../hoc'
import { UserPermissionsDropdown, UspAccountingChartOfAccountView } from '../../../../../models'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/printer.svg'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download_icon.svg'
import { parseToNumber } from '../../../../../utils/general'
import * as XLSX from 'xlsx'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'searchWrap', 'btnWrap', 'searchBtn', 'checkboxWrap'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    color: theme.palette.text.primary,
  },
  [`& .${classes.searchBtn}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: theme.spacing(0, 2, 0, 'auto'),
    gap: '10px',
  },
  [`& .${classes.checkboxWrap}`]: {
    margin: theme.spacing(0, 4, 0, 0),
    fontSize: '14px',
  },
}))

interface Props {
  submit: (...args: any) => any
  userPermissionsDropdown?: UserPermissionsDropdown
  userPermissionsDropdownData?: any
  setOpen: Dispatch<SetStateAction<boolean>>
  data: UspAccountingChartOfAccountView[]
}

export const ChartOfAccountsSearchBlock = ({ submit, userPermissionsDropdownData, setOpen, data }: Props) => {
  const branchData = useSelector(getBranch)
  const { values } = useFormState()

  useEffect(() => {
    if (values?.filterBy || values?.branch || values?.company || values?.endDate || values?.IncludeInactive) {
      submit(values)
    }
  }, [values?.branch, values.filterBy, values?.company, values?.endDate, values?.IncludeInactive])

  const transformDataForExport = (data: any[]) => {
    return data?.map((item: any) => ({
      Number: item.AccountNumber,
      Name: item.AccountName,
      'Sub-Type': item.AccountSubType,
      Open: parseToNumber(item?.Balance),
      Activity: parseToNumber(item?.Activity),
      Closing: parseToNumber(item?.ClosingBalance),
      Status: item.IsActive ? 'Active' : 'Inactive',
    }))
  }

  const setColumnWidths = (worksheet: any) => {
    const columnWidths = [{ width: 20 }, { width: 25 }, { width: 25 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }]
    worksheet['!cols'] = columnWidths
  }

  const exportToExcel = (data: any, fileName: any) => {
    const transformedData = transformDataForExport(data)
    const worksheet = XLSX.utils.json_to_sheet(transformedData)
    setColumnWidths(worksheet)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }

  return (
    <Grid container spacing={2} p={3} alignItems={'center'}>
      <Grid container item xs={10} spacing={2} alignItems={'center'}>
        <Grid item xs={3}>
          <DropdownFieldSearch
            name='filterBy'
            label='Filter By'
            options={[
              { key: 'branch', value: 'Branch' },
              { key: 'company', value: 'Company' },
            ]}
            optionKey='key'
            optionValue='value'
            initialValue='branch'
          />
        </Grid>
        {values.filterBy === 'branch' && branchData?.length > 0 && (
          <Grid item xs={3}>
            <DropdownCheckboxes
              name='branch'
              label='Branch'
              labelId='BranchId'
              fullWidth
              options={branchData}
              optionKey='BranchID'
              optionValue='Branch'
            />
          </Grid>
        )}
        {values.filterBy === 'company' && branchData?.length > 0 && (
          <Grid item xs={3}>
            <DropdownCheckboxes
              name='company'
              label='Company'
              fullWidth
              options={userPermissionsDropdownData?.CompanyTypes}
              optionKey='CompanyType'
              optionValue='CompanyType'
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <CheckboxField name='IncludeInactive' label='Include Inactive Accounts' />
        </Grid>
      </Grid>
      <Grid container item xs={2} alignItems={'center'} justifyContent={'flex-end'}>
        <Grid item>
          <Tooltip title='Print'>
            <Button className={classes.btnWrap} variant='text' type='button'>
              <PrintIcon />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title='Download'>
            <Button variant='text' type='button' className={classes.btnWrap} onClick={() => exportToExcel(data, 'Chart Of Accounts')}>
              <DownloadIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withPermissionsDropdown(ChartOfAccountsSearchBlock)
