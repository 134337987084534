import * as React from 'react'
import { CircularProgress } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Backdrop } from '@mui/material'

export interface DialogProps {
  open?: boolean
}

const classes = generateUtilityClasses('Dialog', ['root', 'content', 'title'])

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  [`&.${classes.root}`]: {
    color: '#fff',
    zIndex: theme.zIndex.drawer + 1,
  },
  [`& .${classes.content}`]: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.title}`]: {
    color: '#fff',
    fontSize: '2rem',
  },
}))

export default function CustomCircular({ open }: DialogProps): React.ReactElement {
  return (
    <StyledBackdrop className={classes.root} open={!!open}>
      <div className={classes.content}>
        <CircularProgress color='primary' />
      </div>
    </StyledBackdrop>
  )
}
