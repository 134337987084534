import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  useGetAccountingChartOfAccountCurrentMutation,
  useAddSideNoteMutation,
  useEditSideNoteMutation,
  useLazySideNoteDataQuery,
  useLazyGetActiveCompanyTypeQuery,
} from '../../../../services'
import { UspConfigurationSideNoteViewResult } from '../../../../models/apiTypes'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationSideNoteViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}

const AddEditPaymentSideNoteModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addSideNote, { isLoading: isLoadingSideNote, isSuccess: isSuccessSideNote }] = useAddSideNoteMutation()
  const [editSideNote, { isLoading: isLoadingEditSideNote, isSuccess: isSuccessEditSideNote }] = useEditSideNoteMutation()
  const [getSideNoteData, { isLoading: isLoadingGetSideNote, isSuccess: isSuccessGetSideNote, data }] = useLazySideNoteDataQuery()
  const [searchChartOfAccounts, { data: chartData, isSuccess: isSuccessSearchChartOfAccount, isLoading: isLoadingSearchChartOfAccounts }] =
    useGetAccountingChartOfAccountCurrentMutation()
  const [getActiveCompanyTypes, { data: activeCompanyTypes, isLoading: isLoadingActiveCompanyTypes }] = useLazyGetActiveCompanyTypeQuery()

  // Filter chartData for Sales and Finance
  const chartDataSales = chartData?.filter((item) => item.CompanyType === 'Sales') || []
  const chartDataFinance = chartData?.filter((item) => item.CompanyType === 'Finance') || []

  // Check if CompanyTypes include 'Sales' and 'Finance'
  const hasFinanceType = activeCompanyTypes && activeCompanyTypes.filter((item) => item.CompanyType === 'Finance')
  const hasSalesType = activeCompanyTypes && activeCompanyTypes.filter((item) => item.CompanyType === 'Sales')

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editSideNote({
        sideNoteId: rowData?.ID?.toString(),
        uspConfigurationSideNoteAddEdit: {
          salesOffsetChartOfAccountID: values.ChartOfAccountSales,
          financeOffsetChartOfAccountID: values?.ChartOfAccounFinance,
          sideNoteLabel: values?.Description,
          isActive: values.IsActive,
        },
      })
    } else {
      addSideNote({
        uspConfigurationSideNoteAddEdit: {
          salesOffsetChartOfAccountID: values.ChartOfAccountSales,
          financeOffsetChartOfAccountID: values?.ChartOfAccounFinance,
          sideNoteLabel: values?.Description,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open) {
      searchChartOfAccounts({
        uspChartOfAccountView: {
          currentID: null,
          isAllowedSelectControlledAccount: false,
          isIncludeAccountsThatCannotBeSelected: false,
          isReturnAllCompanyTypes: true,
        },
      })
      getActiveCompanyTypes({})
    }
  }, [open])

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getSideNoteData({
        sideNoteId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
      })
    }

    if (isSuccessGetSideNote && type === 'edit') {
      setInitialValues({
        Description: data?.SideNoteLabel,
        IsActive: data?.IsActive,
        ChartOfAccountSales: data?.SalesOffsetChartOfAccountID,
        ChartOfAccounFinance: data?.FinanceOffsetChartOfAccountID,
      })
    }
  }, [isSuccessGetSideNote, data, type, isSuccessSearchChartOfAccount])

  useEffect(() => {
    if (isSuccessSideNote || isSuccessEditSideNote) {
      onClose()
      submit()
    }
  }, [isSuccessSideNote, isSuccessEditSideNote])

  const isLoading = useMemo(
    () => isLoadingSideNote || isLoadingEditSideNote || isLoadingGetSideNote || isLoadingSearchChartOfAccounts || isLoadingActiveCompanyTypes,
    [isLoadingSideNote, isLoadingEditSideNote, isLoadingGetSideNote, isLoadingSearchChartOfAccounts, isLoadingActiveCompanyTypes],
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Side Note</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>

                {hasSalesType && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='ChartOfAccountSales'
                      label='Chart of Account - Sales (credit)'
                      options={chartDataSales}
                      optionKey='ChartOfAccountID'
                      optionValue='AccountNumberName'
                    />
                  </Grid>
                )}

                {hasFinanceType && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='ChartOfAccounFinance'
                      label='Chart of Account - Finance (credit)'
                      options={chartDataFinance}
                      optionKey='ChartOfAccountID'
                      optionValue='AccountNumberName'
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditPaymentSideNoteModal
