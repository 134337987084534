import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { appRoute } from '../../constants'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../redux/slices/notificationsSlice'

interface ProtectedRouteProps {
  children?: JSX.Element
  allowed: boolean
  redirectTo: string
  userAccessRightsData?: any // fix
  userAccessRightsIsSuccess?: boolean
}
//user roles
const accessRightsToPaths = {
  CanAccessCar: appRoute.Inventory,
  CanAccessDeal: appRoute.DealsList,
  CanAccessCreditApp: appRoute.LeadsList,
  CanAccessAccount: appRoute.Accounts,
  CanAccessInsights: appRoute.Insights,
  CanAccessAccounting: appRoute.AccountingTasks,
  CanAccessConfigurationSettings: '/settings',
  CanAccessCollections: '/collections',
}

export const ProtectedRoute = ({ children, redirectTo, allowed, userAccessRightsData, userAccessRightsIsSuccess }: ProtectedRouteProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!userAccessRightsIsSuccess) {
      Object.entries(accessRightsToPaths).forEach(([right, path]) => {
        if (userAccessRightsData?.[right] && location.pathname.includes(path)) {
          navigate(appRoute.Home)
          dispatch(
            enqueueNotification({
              message:
                'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
              options: { variant: 'error' },
            }),
          )
        }
      })
    }
  }, [allowed, userAccessRightsData, location, navigate, dispatch, userAccessRightsIsSuccess])

  if (!allowed) {
    return <Navigate to={redirectTo} />
  }
  return children ? children : <Outlet />
}
