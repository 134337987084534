import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import Header from '../components/Header/Header'
import { useSearchReferencesRelationshipsTypesMutation } from '../../../services'
import { UspConfigurationReferenceRelationshipTypeViewResult } from '../../../models'
import LeadsReferenceRelationshipsTable from './components/LeadsReferenceRelationshipsTable'
import AddEditReferenceRelationshipModal from './components/AddEditReferenceRelationshipModal'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const SettingsLeadsReferenceRelationships = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchReferenceRelationships, { data, isLoading }] = useSearchReferencesRelationshipsTypesMutation()
  const [RelationshipsData, setRelationshipsData] = useState<UspConfigurationReferenceRelationshipTypeViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchReferenceRelationships({
      uspConfigurationReferenceRelationshipTypeView: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setRelationshipsData(data)
    }
  }, [data])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Reference Relationship Types'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button onClick={handleOpenModal} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='Reference Relationship Types' handleOpenModal={handleOpenModal} />
                  </Grid>

                  <Grid item xs={12}>
                    <LeadsReferenceRelationshipsTable
                      data={RelationshipsData}
                      loading={isLoading}
                      setData={setRelationshipsData}
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditReferenceRelationshipModal
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as any}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsLeadsReferenceRelationships
