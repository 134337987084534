import React, { useEffect, useMemo } from 'react'
import {
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  Box,
  Tooltip,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspCreditAppStipulationViewResult } from '../../../../../models'
import { useDeleteCreditAppStipulationMutation } from '../../../../../services'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/system'
import { useParams } from 'react-router-dom'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { CustomCircular } from '../../../../../ui'
import { useDispatch } from 'react-redux'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
interface Props {
  getContactsStipulation: (arg: { creditAppId: string }) => void
  contactsStipulationData?: UspCreditAppStipulationViewResult[]
  handleStipulation: (stipulation: UspCreditAppStipulationViewResult | null) => void
  loading: boolean
}

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'loading',
  'spinner',
  'tableHead',
  'tableCellName',
  'telegramIconActive',
  'telegramIconInactive',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.telegramIconActive}`]: {
    color: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

export const LeadsStipulationTableBlock = ({ getContactsStipulation, contactsStipulationData, loading, handleStipulation }: Props) => {
  const dispatch = useDispatch()
  const { leadID } = useParams()
  const [deleteData, setDeleteData] = React.useState({ creditAppId: '', creditAppStipulationId: '' })
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<boolean>(false)
  const initialOrder = (storageGet('leads_stipulation_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('leads_stipulation_by') || 'Applicant') as keyof UspCreditAppStipulationViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspCreditAppStipulationViewResult>(initialOrderBy)

  const handleDelete = () => {
    if (deleteData?.creditAppId && deleteData?.creditAppStipulationId) {
      deleteCreditAppStipulation({
        creditAppId: deleteData?.creditAppId?.toString(),
        creditAppStipulationId: deleteData?.creditAppStipulationId?.toString(),
      })
      setDeleteConfirmDialog(false)
    }
  }

  const [deleteCreditAppStipulation, { isSuccess: deleteCreditAppStipulationIsSuccess, isLoading: deleteCreditAppStipulationIsLoading }] =
    useDeleteCreditAppStipulationMutation()

  useEffect(() => {
    if (deleteCreditAppStipulationIsSuccess) {
      dispatch(enqueueNotification({ message: 'Deleted' }))
      getContactsStipulation({ creditAppId: leadID as string })
    }
  }, [deleteCreditAppStipulationIsSuccess])

  const isLoading = useMemo(() => deleteCreditAppStipulationIsLoading, [deleteCreditAppStipulationIsLoading])

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCreditAppStipulationViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('leads_stipulation_order', isAsc ? 'desc' : 'asc')
    storageSet('leads_stipulation_by', property)
  }

  const createSortHandler = (property: keyof UspCreditAppStipulationViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }} className={classes.root}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Applicant' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Applicant'}
                    direction={orderBy === 'Applicant' ? order : 'asc'}
                    onClick={createSortHandler('Applicant')}
                  >
                    Applicant
                    {orderBy === 'Applicant' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'AddedBy' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'AddedBy'}
                    direction={orderBy === 'AddedBy' ? order : 'asc'}
                    onClick={createSortHandler('AddedBy')}
                  >
                    Added By
                    {orderBy === 'AddedBy' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Stipulation' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Stipulation'}
                    direction={orderBy === 'Stipulation' ? order : 'asc'}
                    onClick={createSortHandler('Stipulation')}
                  >
                    Stipulation
                    {orderBy === 'Stipulation' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  className={classes.tableHead}
                  sortDirection={orderBy === 'Note' ? order : false}
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <TableSortLabel active={orderBy === 'Note'} direction={orderBy === 'Note' ? order : 'asc'} onClick={createSortHandler('Note')}>
                    Comments
                    {orderBy === 'Note' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsVerified' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'IsVerified'}
                    direction={orderBy === 'IsVerified' ? order : 'asc'}
                    onClick={createSortHandler('IsVerified')}
                  >
                    Verified
                    {orderBy === 'IsVerified' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} align={'right'}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), contactsStipulationData as any[])?.map((item: any) => (
                <TableRow key={item.StipulationID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    className={classes.tableCellName}
                    onClick={() => {
                      handleStipulation(item)
                    }}
                  >
                    {item.Applicant}
                  </TableCell>
                  <TableCell>{item?.AddedBy}</TableCell>
                  <TableCell>{item?.Stipulation}</TableCell>
                  <TableCell
                    sx={{
                      maxWidth: 400,
                    }}
                  >
                    {item?.Note}
                  </TableCell>
                  <TableCell>{item?.IsVerified ? 'Yes' : 'No'}</TableCell>
                  <TableCell align={'right'}>
                    <Tooltip title='Edit'>
                      <IconButton
                        color='primary'
                        onClick={() => {
                          handleStipulation(item)
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <IconButton
                        color='error'
                        onClick={() => {
                          setDeleteConfirmDialog(true)
                          setDeleteData({ creditAppId: item?.ContactID, creditAppStipulationId: item?.CreditAppStipulationID })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {contactsStipulationData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <CustomCircular open={isLoading} />
      </StyledGrid>
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Stipulation will be removed'}
      />
    </>
  )
}

export default LeadsStipulationTableBlock
