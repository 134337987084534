import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface Props {
  open: boolean
  onOk: () => void
  handleClose: () => void
  disagreeText?: string
  notificationTitle?: string
  notification: string
}
const ConfirmDialog = ({ open, onOk, handleClose, disagreeText, notificationTitle, notification }: Props) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      {notificationTitle && <DialogTitle id='alert-dialog-title'>{notificationTitle}</DialogTitle>}
      <DialogContent sx={{ p: 3 }}>
        <DialogContentText id='alert-dialog-description'>{notification}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{disagreeText ? disagreeText : 'Cancel'}</Button>
        <Button onClick={onOk} autoFocus>
          {disagreeText ? disagreeText : 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
