import React, { useEffect, useMemo } from 'react'
import { styled } from '@mui/system'
import { Card, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, generateUtilityClasses } from '@mui/material'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { TextField } from '../../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { UspListCreditAppApplicantResult } from '../../../../../models'
import {
  useCreditAppListApplicantQuery,
  useAddCreditAppStipulationMutation,
  useEditCreditAppStipulationMutation,
  selectDropdownByNameActiveOnly,
} from '../../../../../services'
import { useParams } from 'react-router-dom'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

interface Props {
  open: boolean
  editStipulation: any
  onClose: () => void
  getContactsStipulation?: (arg: { creditAppId: string }) => void
}

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(6, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

export const AddEditStipulationsFormModal = ({ open, onClose, editStipulation, getContactsStipulation }: Props) => {
  const dispatch = useDispatch()
  const [addCreditAppStipulation, { isSuccess: addCreditAppStipulationIsSuccess, isLoading: addCreditAppStipulationIsLoading }] =
    useAddCreditAppStipulationMutation()
  const [editCreditAppStipulation, { isSuccess: editCreditAppStipulationIsSuccess, isLoading: editCreditAppStipulationIsLoading }] =
    useEditCreditAppStipulationMutation()
  const { leadID } = useParams()
  const { data: creditAppListApplicantData, isLoading: creditAppListApplicantIsLoading } = useCreditAppListApplicantQuery({
    creditAppId: leadID as string,
  })
  const stipulation = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Stipulation'))

  const isLoading = useMemo(
    () => creditAppListApplicantIsLoading || editCreditAppStipulationIsLoading || addCreditAppStipulationIsLoading,
    [creditAppListApplicantIsLoading, editCreditAppStipulationIsLoading, addCreditAppStipulationIsLoading],
  )

  const handleSubmit = (values: any) => {
    if (editStipulation) {
      editCreditAppStipulation({
        creditAppStipulationId: editStipulation?.CreditAppStipulationID?.toString() as string,
        creditAppId: leadID as string,
        uspCreditAppStipulationAddEdit: {
          contactID: values.Applicant,
          stipulationID: values.StipulationID,
          note: values.Note || null,
          isVerified: values.IsVerified,
        },
      })
    } else {
      addCreditAppStipulation({
        creditAppId: leadID as string,
        uspCreditAppStipulationAddEdit: {
          contactID: values.Applicant,
          stipulationID: values.StipulationID,
          note: values.Note || null,
          isVerified: values.IsVerified,
        },
      })
    }
  }

  useEffect(() => {
    if (addCreditAppStipulationIsSuccess) {
      dispatch(enqueueNotification({ message: 'Stipulation created' }))
      getContactsStipulation && getContactsStipulation({ creditAppId: leadID as string })
      onClose()
    }
  }, [addCreditAppStipulationIsSuccess])

  useEffect(() => {
    if (editCreditAppStipulationIsSuccess) {
      dispatch(enqueueNotification({ message: 'Stipulation updated' }))
      getContactsStipulation && getContactsStipulation({ creditAppId: leadID as string })
      onClose()
    }
  }, [editCreditAppStipulationIsSuccess])

  const getInitialData = () => {
    if (editStipulation) {
      return {
        Applicant: editStipulation?.ContactID,
        StipulationID: editStipulation?.StipulationID,
        IsVerified: editStipulation?.IsVerified,
        Note: editStipulation?.Note,
      }
    } else {
      return { IsVerified: false }
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Stipulation</DialogTitle>

      {/* <Card sx={{ padding: 2 }}> */}
      <Form onSubmit={handleSubmit} initialValues={getInitialData()}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent sx={{ width: 500 }}>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    required
                    name='Applicant'
                    label='Applicant'
                    disabled={editStipulation ? true : false}
                    options={creditAppListApplicantData as UspListCreditAppApplicantResult[]}
                    optionKey={'ContactID'}
                    optionValue={'Applicant'}
                    initialValue={creditAppListApplicantData?.length === 1 ? creditAppListApplicantData[0]?.ContactID : undefined}
                  />
                </Grid>
                {stipulation && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      required
                      name='StipulationID'
                      label='Stipulation'
                      options={stipulation}
                      optionKey={'StipulationID'}
                      optionValue={'Stipulation'}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsVerified'
                    label='Is Verified?'
                    options={[
                      { key: true, value: 'Yes' },
                      { key: false, value: 'No' },
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline name='Note' label='Note' fullWidth />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
              <LoadingButton type='submit' variant='contained' loading={isLoading}>
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Form>
      {/* </Card> */}
    </Dialog>
  )
}

export default AddEditStipulationsFormModal
