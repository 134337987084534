import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const AccountingForm = ({}: Props) => {
  const { values } = useFormState()

  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Accounting</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessAccounting' label='Access Accounting' />
      </Grid>
      {values.AccessAccounting && (
        <>
          <Grid>
            <CheckboxField name='AddBankDeposits' label='Add Bank Deposits' />
          </Grid>
          <Grid>
            <CheckboxField name='AddJournalEntry' label='Add Journal Entry' />
          </Grid>
          <Grid>
            <CheckboxField name='AddLedgerOnlyJournalEntry' label='Add Ledger Only Journal Entry' />
          </Grid>
          <Grid>
            <CheckboxField name='AddBills' label='Add Bills' />
          </Grid>
          <Grid>
            <CheckboxField name='DeleteBills' label='Delete Bills' />
          </Grid>
          <Grid>
            <CheckboxField name='NewBillsareApprovedToPay' label='New Bills are Approved To Pay' />
          </Grid>
          <Grid>
            <CheckboxField name='CanApproveBillsforPayment' label='Can Approve Bills for Payment' />
          </Grid>
          <Grid>
            <CheckboxField name='IssueChecks' label='Issue Checks' />
          </Grid>
          <Grid>
            <CheckboxField name='PayBills' label='Pay Bills' />
          </Grid>
          <Grid>
            <CheckboxField name='PayUnapprovedBills' label='Pay Unapproved Bills' />
          </Grid>
          <Grid>
            <CheckboxField name='VoidChecks' label='Void Checks' />
          </Grid>
          <Grid>
            <CheckboxField name='AccessChartofAccounts' label='Access Chart of Accounts' />
          </Grid>
          <Grid>
            <CheckboxField name='ChooseRestrictedChartofAccounts' label='Choose Restricted Chart of Accounts' />
          </Grid>
          <Grid>
            <CheckboxField name='AccessReconciliations' label='Access Reconciliations' />
          </Grid>
          <Grid>
            <CheckboxField name='AccessFinancials' label='Access Financials' />
          </Grid>
          <Grid>
            <CheckboxField name='ClosePeriods' label='Close Periods' />
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

export default AccountingForm
