import { configureStore } from '@reduxjs/toolkit'
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore'
import { AnyAction, Middleware } from 'redux'
import authReducer from '../slices/authSlice'
import carsReducer from '../slices/carsSlice'
import accountsReducer from '../slices/accountsSlice'
import dealsReducer from '../slices/dealsSlice'
import formPacksReducer from '../slices/formPacksSlice'
import collectionReducer from '../slices/collectionsSlice'
import accountingLedgersReducer from '../slices/accountingLedgersSlice'
import inventoryAddType from '../slices/inventorySlice'
import notificationsReducer from '../slices/notificationsSlice'
import userDropdownReducer from '../slices/userDropdownSlice'
import creditAppReducer from '../slices/creditAppSlice'
import thunkMiddleware from 'redux-thunk'
import { rtkQueryErrorLogger } from './errorHandling'
import financialsIncomeStatementReducer from '../slices/financialsIncomeStatementSlice'
import dealsTradeReducer from '../slices/dealsTradeSlice'
import { baseApi } from '../../services/baseApi'

const store: EnhancedStore<any, AnyAction, Array<Middleware>> = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authReducer,
    cars: carsReducer,
    collections: collectionReducer,
    accounts: accountsReducer,
    accountingLedgers: accountingLedgersReducer,
    creditApp: creditAppReducer,
    inventoryAddType: inventoryAddType,
    userDropdown: userDropdownReducer,
    notifications: notificationsReducer,
    financialsIncomeStatement: financialsIncomeStatementReducer,
    dealsTradeData: dealsTradeReducer,
    deals: dealsReducer,
    formPacks: formPacksReducer,
  },
  middleware: [thunkMiddleware, rtkQueryErrorLogger, baseApi.middleware],
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
