import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { AmountField } from '../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}
const DepositCard = ({}: Props) => {
  return (
    <Grid container spacing={2} py={1}>
      <Grid item xs={12}>
        <Typography variant='h6'>Deposit</Typography>
      </Grid>
      <Grid item xs={12}>
        <AmountField variant='standard' name='Amount' label='Amount' autoComplete='off' fullWidth />
      </Grid>
    </Grid>
  )
}

export default DepositCard
