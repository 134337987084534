import { appApi, HeaderKey, StorageKey } from '../constants'
import { fetchClients, IApiClientItem } from './client'
import { authEnterSession, authRefresh } from './auth'
import { CURRENT_USER } from '../states/app'

export const prepareApp = async () => {
  let access = localStorage.getItem(StorageKey.access) || undefined
  let refresh = localStorage.getItem(StorageKey.refresh) || undefined
  const email = localStorage.getItem(StorageKey.email) || undefined
  const clientID = localStorage.getItem(StorageKey.clientID) || undefined
  const companyType = localStorage.getItem(StorageKey.companyType) || undefined
  const branchID = localStorage.getItem(StorageKey.branchID) || undefined
  if (!email || !access) return

  appApi.setHeader(HeaderKey.Authorization, `Bearer ${access}`)

  let clients: IApiClientItem[] = []

  try {
    clients = await fetchClients()
  } catch (e) {
    if (refresh) {
      try {
        const res = await authRefresh({ refresh })
        clients = await fetchClients()
        access = res.access
        refresh = res.refresh
      } catch (e) {
        appApi.removeHeader(HeaderKey.Authorization)
        return
      }
    }
  }

  CURRENT_USER.set({
    access,
    refresh,
    email,
  })

  if (!clientID || !companyType || !branchID) {
    return
  }

  const clientExists = clients?.find((item) => item.client_uid === clientID)

  if (!clientExists) {
    return
  }

  try {
    await authEnterSession({ clientID, companyType, branchID })
  } catch (e) {
    return
  }
}
