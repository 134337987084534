import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import UserRolesSearchBlock from './components/UserRolesSearchBlock/UserRolesSearchBlock'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { useDeleteUserRoleMutation, useSearchUserRoleMutation } from '../../../services'
import UserRolesTableBlock from './components/UserRolesTableBlock/UserRolesTableBlock'
import { appRoute } from '../../../constants'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

interface Props {}

const SettingsAdminUserRoles = ({}: Props) => {
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const navigate = useNavigate()
  const [searchUserRole, { data: searchUserRoleData, isLoading: searchUserRoleIsLoading }] = useSearchUserRoleMutation()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<{ userRoleId: string } | undefined>(undefined)
  const [deleteUserRole, { isSuccess: deleteUserRoleIsSuccess }] = useDeleteUserRoleMutation()

  const handleSubmit = (values?: any) => {
    searchUserRole({
      simpleSearch: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleDelete = () => {
    deleteUserRole(deleteConfirmDialog as any)
    setDeleteConfirmDialog(undefined)
  }

  useEffect(() => {
    if (deleteUserRoleIsSuccess) {
      dispatch(enqueueNotification({ message: 'Delete Successful' }))
      searchUserRole({
        simpleSearch: {
          searchString: null,
          isIncludeInactive: false,
        },
      })
    }
  }, [deleteUserRoleIsSuccess])

  useEffect(() => {
    if (!userAccess.CanAccessUsers) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='User Roles'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          userAccess?.CanAddDeal && (
            <Button
              onClick={() => navigate(appRoute?.SettingsAdminAddEditUserRoles.replace(':userRoleId', 'add'))}
              variant='contained'
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Add new
            </Button>
          )
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ searchString: '', isIncludeInactive: false }} // require to avoid first debounce search
      >
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <UserRolesSearchBlock submit={submit} />
                </Grid>
                <Grid item xs={12}>
                  <UserRolesTableBlock
                    loading={searchUserRoleIsLoading}
                    searchUserRolesData={searchUserRoleData}
                    setDeleteConfirmDialog={setDeleteConfirmDialog}
                  />
                </Grid>
              </Grid>
            </Card>

            <ConfirmDialog
              open={!!deleteConfirmDialog}
              onOk={handleDelete}
              handleClose={() => setDeleteConfirmDialog(undefined)}
              notification={'User role will be removed'}
            />
          </form>
        )}
      </Form>
    </Container>
  )
}

export default SettingsAdminUserRoles
