import { styled } from '@mui/system'

export const useStatusColorStyles = (reseponseColor: any) => {
  const StatusColorDiv = styled('div')(() => ({
    '&.applicant': {
      backgroundColor: reseponseColor?.ApplicantStatusColor || 'inherit',
    },
    '&.coApplicant': {
      backgroundColor: reseponseColor?.CoApplicantStatusColor || 'inherit',
    },
    '&.reference': {
      backgroundColor: reseponseColor?.ReferenceStatusColor || 'inherit',
    },
    '&.stipulation': {
      backgroundColor: reseponseColor?.StipulationStatusColor || 'inherit',
    },
    '&.settings': {
      backgroundColor: reseponseColor?.SettingsStatusColor || 'inherit',
    },
    // Deals COLORS
    '&.settingsDeals': {
      backgroundColor: reseponseColor?.SettingsStatusColor || 'inherit',
    },
    '&.buyer': {
      backgroundColor: reseponseColor?.BuyerStatusColor || 'inherit',
    },
    '&.coBuyer': {
      backgroundColor: reseponseColor?.CoBuyerStatusColor || 'inherit',
    },
    '&.referenceDeals': {
      backgroundColor: reseponseColor?.ReferenceStatusColor || 'inherit',
    },
    '&.trade': {
      backgroundColor: reseponseColor?.TradeStatusColor || 'inherit',
    },
    '&.insurance': {
      backgroundColor: reseponseColor?.InsuranceStatusColor || 'inherit',
    },
    '&.product': {
      backgroundColor: reseponseColor?.ProductStatusColor || 'inherit',
    },
    '&.commision': {
      backgroundColor: reseponseColor?.CommissionStatusColor || 'inherit',
    },
    '&.referral': {
      backgroundColor: reseponseColor?.ReferralStatusColor || 'inherit',
    },
  }))

  return StatusColorDiv
}
