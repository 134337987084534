import {
  CardHeader,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { formatNumber, parseToNumber } from '../../../../../../utils/general'
import { TFullResponse } from '../../../../../../models'

const classes = generateUtilityClasses('Grid', ['root', 'titleRow', 'row', 'rowLast', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '400px',
  },
  [`& .${classes.titleRow}`]: {
    margin: theme.spacing(0.5, 0, 1, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },

  [`& .${classes.rowLast}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    margin: theme.spacing(1, 0, 2, 0),
  },
}))
interface Props {
  summaryData: TFullResponse[]
}
const SummaryFormDetails = ({ summaryData }: Props) => {
  const totalAmount = summaryData?.reduce((sum: number, row) => {
    const sumAmount = row?.BillAmount ? parseToNumber(row?.BillAmount?.toString()) : 0
    return sum + sumAmount
  }, 0)

  return (
    <>
      <CardHeader title='Summary' />
      <Grid container spacing={2} p={3}>
        <Grid item xs={12}>
          <TableContainer sx={{ overflow: 'auto', maxHeight: '25vh' }}>
            <Table aria-label='simple table' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead} align='left'>
                    Chart of Account
                  </TableCell>
                  <TableCell className={classes.tableHead} align='right'>
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {summaryData?.map((row: TFullResponse) => (
                  <TableRow hover key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align='left'>{row?.AccountNumberNameValue}</TableCell>
                    <TableCell align='right'>{formatNumber(row?.BillAmount)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid container spacing={2} justifyContent={'flex-end'}>
          <Grid item>
            <Typography variant='h6'>Total</Typography>
          </Grid>
          <Grid item>
            <Typography variant='h6'>{formatNumber(totalAmount)}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default SummaryFormDetails
