import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import AccountingChecksSearchBlock from './components/AccountingChecksSearchBlock/AccountingChecksSearchBlock'
import { useAccountingCheckSearchMutation, useLazyGetAccountingCashAccountsQuery } from '../../../services'
import { UspListAccountingCashAccountsResult } from '../../../models'
import { convertDateToString } from '../../../utils/dateUtils'
import { getCurrentDate, getEarlierDate } from '../../../utils/general'
import AccountingChecksTableBlock from './components/AccountingChecksTableBlock/AccountingChecksTableBlock'
import { accountingChecksValidation } from '../../../validation/accountingChecksValidation'
import validateFormValues from '../../../utils/validateForm'
import { Button, Card, Container } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'
import { appRoute } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { useSelector } from 'react-redux'

const AccountingChecks = () => {
  const [initialLoad, setInitialLoad] = useState(true)
  const [searchAccountingChecks, { data, isLoading }] = useAccountingCheckSearchMutation()
  const [getCashAccounts, { data: cashAccountsData }] = useLazyGetAccountingCashAccountsQuery()
  const [bankAccounts, setBankAccounts] = useState<UspListAccountingCashAccountsResult[]>([])
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)

  useEffect(() => {
    getCashAccounts({})
  }, [])

  useEffect(() => {
    if (cashAccountsData) {
      const filteredBankAccounts = cashAccountsData.filter((account: UspListAccountingCashAccountsResult) => account.IsBankAccount)
      setBankAccounts(filteredBankAccounts)
    }
  }, [cashAccountsData])

  const handleSubmit = (values?: any) => {
    // Determine start and end dates based on the initial load or user input
    let startDate = initialLoad ? getEarlierDate(7) : null
    let endDate = initialLoad ? getCurrentDate() : null

    if (!initialLoad) {
      startDate = convertDateToString(values?.startDate)
      endDate = convertDateToString(values?.endDate)
    }

    // update the initial load flag after the first search
    if (initialLoad) {
      setInitialLoad(false)
    }

    if (values?.dateRange === 'today') {
      startDate = getCurrentDate() || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'yesterday') {
      startDate = getEarlierDate(1) || null
      endDate = getEarlierDate(1) || null
    } else if (values?.dateRange === 'lastSevenDays') {
      startDate = getEarlierDate(7) || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'lastThirtyDays') {
      startDate = getEarlierDate(30) || null
      endDate = getCurrentDate() || null
    }
    if (startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
      searchAccountingChecks({
        accountingCheckSearch: {
          startDate: startDate as string,
          endDate: endDate as string,
          checkNumber: values?.Number || null,
          billPayTypeID: values?.BillPayType || null,
          bankCoaID: values?.BankAccount || null,
          bankBranchID: values?.BankBranch || null,
        },
      })
    }
  }

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Checks'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          userAccess?.CanIssueChecks && (
            <Button onClick={() => navigate(appRoute.AccountingChecksAdd)} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
              Add new
            </Button>
          )
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Form
          onSubmit={handleSubmit}
          validate={validateFormValues(accountingChecksValidation)}
          initialValues={{ startDate: getEarlierDate(7), endDate: getCurrentDate(), dateRange: '' }} // require to avoid first debounce search
        >
          {({ handleSubmit, form: { submit } }: any) => (
            <form onSubmit={handleSubmit}>
              <AccountingChecksSearchBlock initialLoad={initialLoad} bankAccounts={bankAccounts} submit={submit} />
              <AccountingChecksTableBlock loading={isLoading} data={data} />
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default AccountingChecks
