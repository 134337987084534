import { Box, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { borderRadius, styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useEditDealerReserveMutation, useLazyGetDealerReserveQuery } from '../../../../services'
import { UspConfigurationDealerReserveViewResult } from '../../../../models/apiTypes'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import ReactRouterPrompt from 'react-router-prompt'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
    overflow: 'hidden',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationDealerReserveViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const EditDealerReserve = ({ open, onClose, rowData, type, submit }: Props) => {
  const dispatch = useDispatch()
  const [formChanged, setFormChanged] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [editDealerReserve, { isLoading: isLoadingEditDealerReserve, isSuccess: isSuccessEditDealerReserve }] = useEditDealerReserveMutation()
  const [getDealerReserveData, { isLoading: isLoadingGetDealerReserve, isSuccess: isSuccessGetDealerReserve, data }] = useLazyGetDealerReserveQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editDealerReserve({
        dealerReserveId: rowData?.ID?.toString(),
        uspConfigurationDealerReserveEdit: {
          reserveDescription: values?.Description,
          isTrackAtDealerLevel: values?.IsTracked,
          isReduceWriteOff: values?.IsUsed,
          isRefundable: values?.IsRefundable,
          isActive: values?.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getDealerReserveData({
        dealerReserveId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (isSuccessGetDealerReserve && type === 'edit') {
      setInitialValues({
        Description: data?.ReserveDescription,
        IsTracked: data?.IsTrackAtDealerLevel,
        IsUsed: data?.IsReduceWriteOff,
        IsRefundable: data?.IsRefundable,
        IsActive: data?.IsActive,
      })
    }
  }, [isSuccessGetDealerReserve, data, type])

  useEffect(() => {
    if (isSuccessEditDealerReserve) {
      dispatch(enqueueNotification({ message: 'Success' }))
      onClose()
      submit()
      setFormChanged(false)
    }
  }, [isSuccessEditDealerReserve])

  const isLoading = useMemo(() => isLoadingEditDealerReserve || isLoadingGetDealerReserve, [isLoadingEditDealerReserve, isLoadingGetDealerReserve])
  return (
    <Dialog
      open={open}
      onClose={() => {
        setFormChanged(false)
        onClose()
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <DialogTitle>Dealer Reserve</DialogTitle>
      <DialogContent>
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
              <ReactRouterPrompt when={formChanged}>
                {({ isActive, onConfirm, onCancel }) => (
                  <ConfirmDialog
                    open={!!isActive}
                    onOk={onConfirm}
                    handleClose={onCancel}
                    notification={'Warning: Your changes haven’t been saved.'}
                  />
                )}
              </ReactRouterPrompt>

              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsTracked'
                    label='Is Tracked At Dealer Level?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                    onChange={() => setFormChanged(true)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsUsed'
                    label='Is Used To Reduce Write Off?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                    onChange={() => setFormChanged(true)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsRefundable'
                    label='Is Refundable?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                    onChange={() => setFormChanged(true)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                    onChange={() => setFormChanged(true)}
                  />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
        <CustomCircular open={isLoading} />
      </DialogContent>
      <DialogActions>
        <LoadingButton disabled={!formChanged} type='submit' className={classes.saveBtn} variant='contained'>
          Save
        </LoadingButton>
        <Button variant='contained' color='error' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditDealerReserve
