import { Button, Card, Container, Grid, IconButton, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import Header from '../components/Header/Header'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import SettingsFormDetails from '../components/SettingsFormDetails/SettingsFormDetails'
import PayFormDetails from '../components/PayFormDetails/PayFormDetails'
import SummaryFormDetails from '../components/SummaryFormDetails/SummaryFormDetails'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { selectDropdownByName, useLazyGetAccountingCashAccountsQuery, useLazyGetAccountingUnpaidVendorsQuery } from '../../../../../services'
import { transformToFormValues, transformToRequestValues } from '../transform'
import { CustomCircular } from '../../../../../ui'
import { TFullResponse, UspListAccountingCashAccountsResult } from '../../../../../models'
import validateFormValues from '../../../../../utils/validateForm'
import { accountingChecksPaymentDetailsValidation } from '../../../../../validation/accountingChecksValidation'
import { useSelector } from 'react-redux'
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { useTheme } from '@mui/material/styles'
import AttachFileIcon from '@mui/icons-material/AttachFile'
const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'backBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formItem}`]: {
    minHeight: '514px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 3),
    marginTop: 'auto',
  },
  [`& .${classes.backBtn}`]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
}))

interface Props {
  tableData: TFullResponse[]
  totalSum: number | string
  setCurrentStage: (stage: 'settings' | 'paymentDetails' | null) => void
  selectedVendor: number | null
  addCheck: (e: any) => void
  addCheckIsLoading: boolean
  setTableHasData: Dispatch<SetStateAction<boolean>>
  stage: string
}

const AccountingChecksPaymentDetails = ({
  addCheck,
  addCheckIsLoading,
  selectedVendor,
  setCurrentStage,
  tableData,
  totalSum,
  stage,
  setTableHasData,
}: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [getUnpaidVendors, { data: unpaidVendorsData }] = useLazyGetAccountingUnpaidVendorsQuery()
  const [getCashAccounts, { data: cashAccountsData, isLoading: cashAccountsIsLoading }] = useLazyGetAccountingCashAccountsQuery()
  const billPayType = useSelector((state) => selectDropdownByName(state, 'BillPayType'))
  const checkID = billPayType?.ReturnData?.find((item) => item?.BillPayType === 'Check')?.ID ?? null
  const theme = useTheme()

  const navigate = useNavigate()
  const handleSubmit = (values?: any) => {
    setTableHasData(false) // allows page to be redirected after submit
    addCheck({
      uspAccountingCheck: transformToRequestValues(values, tableData, selectedVendor) as any,
    })
  }

  useEffect(() => {
    getCashAccounts({})
    getUnpaidVendors({})
  }, [])

  useEffect(() => {
    setInitialValues(transformToFormValues(cashAccountsData, selectedVendor, unpaidVendorsData, tableData))
  }, [cashAccountsData, selectedVendor, unpaidVendorsData, tableData])

  const isLoading = useMemo(() => addCheckIsLoading || cashAccountsIsLoading, [addCheckIsLoading, cashAccountsIsLoading])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='Create a new check'
        links={[
          {
            name: 'List',
            href: appRoute.AccountingChecks,
          },
          {
            name: 'New',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Card>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validateFormValues(accountingChecksPaymentDetailsValidation(checkID as number, stage as string))}
        >
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <SettingsFormDetails
                    billPayType={billPayType}
                    checkID={checkID}
                    stage={stage}
                    tableData={tableData}
                    cashAccountsData={cashAccountsData as UspListAccountingCashAccountsResult[]}
                  />
                </Grid>

                <Grid item xs={12}>
                  <PayFormDetails />
                </Grid>
                <Grid item xs={12}>
                  <SummaryFormDetails summaryData={tableData} />
                </Grid>
              </Grid>

              <Grid container justifyContent='flex-end' spacing={2} p={3} alignItems={'center'}>
                <Grid item>
                  <Button
                    className={classes.backBtn}
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => setCurrentStage('settings')}
                    variant='contained'
                    color='warning'
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant='contained' color='error' onClick={() => navigate(appRoute.AccountingChecks)}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <IconButton>
                    <AttachFileIcon />
                  </IconButton>
                </Grid>

                <Grid item>
                  <Button disabled={(totalSum as number) <= 0 ? true : false} variant='contained' type='submit'>
                    Issue Payment
                  </Button>
                </Grid>
              </Grid>
              <CustomCircular open={isLoading} />
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default AccountingChecksPaymentDetails
