import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const FeeForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Fee' />
      <Grid container spacing={2} p={3}>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanAdminFee' label='Admin Fee' fullWidth required />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanSetupFee' label='Setup Fee' fullWidth required />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanAdditionalFee' label='Additional Fee' fullWidth required />
        </Grid>
      </Grid>
    </>
  )
}

export default FeeForm
