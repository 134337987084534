import React, { useEffect, useState } from 'react'
import { Form, useForm, useFormState } from 'react-final-form'
import FormsSearchBlock from './components/FormsSearchBlock/FormsSearchBlock'
import { withPermissionsDropdown } from '../../../../hoc'
import FormsTableBlock from './components/FormsTableBlock/FormsTableBlock'
import { useSearchFormsMutation, useUploadFormPDFMutation } from '../../../../services'
import { UspFormViewResult } from '../../../../models'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../../components/iconify'
import { getStates } from '../../../../redux/slices/userDropdownSlice'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import SettingsModal from './components/SettingsModal/SettingsModal'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const SettingsFormsList = () => {
  const [formsListData, setFormsListData] = useState<UspFormViewResult[]>([])
  const [searchForms, { data: searchFormsData, isLoading: searchFormsIsLoading }] = useSearchFormsMutation()
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [uploadFile, { data, isSuccess, isLoading }] = useUploadFormPDFMutation()
  const dispatch = useDispatch()
  const states = useSelector(getStates)
  const navigate = useNavigate()

  const handleUploadPDF = (values?: any) => {
    uploadFile({
      file: selectedFile,
      dataSource: values?.DataSource,
      formState: values?.FormState,
      name: values?.Name,
      version: values?.Version,
      isActive: true,
    })
  }

  const handleSubmit = (values: any) => {
    searchForms({
      state: values?.State || '',
      searchString: values?.SearchField || '',
      dataSource: values?.DataSourceSearch || '',
      isIncludeInactive: values?.IncludeInactive,
    })
  }

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false)
  }

  useEffect(() => {
    if (searchFormsData) {
      setFormsListData(searchFormsData)
    }
  }, [searchFormsData])

  const handleSelectPDF = () => {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement
    if (fileInput) fileInput.click()
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>, form: any) => {
    const file = event.target.files?.[0]
    if (file && file.type === 'application/pdf') {
      setSelectedFile(file)
      // Remove .pdf extension from file name
      const fileNameWithoutExtension = file.name.replace(/\.pdf$/i, '')

      setOpenSettingsModal(true)
      form.change('Name', fileNameWithoutExtension)
    } else {
      dispatch(enqueueNotification({ message: 'Please select a PDF file.' }))
    }
  }
  return (
    <Form
      onSubmit={handleSubmit}
      // validate={validateFormValues(settingsFormsValidation)} // DISABLED FOR NOW
      initialValues={{ State: '', SearchField: '', IncludeInactive: false, DataSource: 'Deal' }}
    >
      {({ handleSubmit, form, form: { submit } }: any) => {
        return (
          <Container>
            <CustomBreadcrumbs
              heading='Form Mapping'
              links={[
                {
                  name: 'List',
                },
              ]}
              action={
                <>
                  <Button onClick={handleSelectPDF} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
                    Add new
                  </Button>
                  <input type='file' id='fileInput' style={{ display: 'none' }} accept='.pdf' onChange={(e) => handleFileChange(e, form)} />
                </>
              }
              sx={{
                mb: {
                  xs: 3,
                  md: 5,
                },
              }}
            />
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <FormsSearchBlock submit={submit} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormsTableBlock loading={searchFormsIsLoading} setFormsListData={setFormsListData} formsListData={formsListData} />
                  </Grid>
                </Grid>
              </Card>
              <SettingsModal
                handleFileChange={(e) => handleFileChange(e, form)}
                handleUploadPDF={handleUploadPDF}
                open={openSettingsModal}
                handleClose={handleCloseSettingsModal}
              />
            </form>
          </Container>
        )
      }}
    </Form>
  )
}

export default withPermissionsDropdown(SettingsFormsList)
