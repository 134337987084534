import { useReportsAccountingSubsidiaryReconciliationMutation } from '../../../services'
import { useEffect } from 'react'
import { getCurrentDate } from '../../../utils/general'
import AccountsSubsidiaryReconciliationTableBlock from './components/AccountsSubsidiaryReconciliationTableBlock/AccountsSubsidiaryReconciliationTableBlock'
import { UspReportSubsidiaryReconciliationResult } from '../../../models'
import Header from './components/Header/Header'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { converToUtc, formatDate } from '../../../utils/dateUtils'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { Card, Container } from '@mui/material'

const AccountsSubsidiaryReconciliation = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)

  const [getReconData, { data: reconData, isLoading: reconIsLoading }] = useReportsAccountingSubsidiaryReconciliationMutation()

  useEffect(() => {
    getReconData({
      uspReportSubsidiaryReconciliation: { asOfDate: formatDate(converToUtc(getCurrentDate() as string) as string) },
    })
  }, [])

  useEffect(() => {
    if (!userAccess.CanAccessAccountingInsights) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Reports'
        links={[
          {
            href: appRoute.Insights,
            name: 'List',
          },
          {
            name: 'Subsidiary Reconciliation',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Header reconData={reconData as UspReportSubsidiaryReconciliationResult[]} />
        <AccountsSubsidiaryReconciliationTableBlock reconData={reconData as UspReportSubsidiaryReconciliationResult[]} isLoading={reconIsLoading} />
      </Card>
    </Container>
  )
}

export default AccountsSubsidiaryReconciliation
