import { Step, StepConnector, StepIconProps, StepLabel, Stepper, Typography, stepConnectorClasses, styled } from '@mui/material'
import Check from '@mui/icons-material/Check'

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.success.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: theme.palette.success.main,
  }),
  '& .QontoStepIcon-completedIcon': {
    color: theme.palette.success.main,
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}))

function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? <Check className='QontoStepIcon-completedIcon' /> : <div className='QontoStepIcon-circle' />}
    </QontoStepIconRoot>
  )
}

const FormStepper = ({ activeStep }: { activeStep: number }) => {
  return (
    <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
      <Step key={1}>
        <StepLabel StepIconComponent={QontoStepIcon}>
          <Typography variant='subtitle2'>Step 1</Typography>
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel StepIconComponent={QontoStepIcon}>
          <Typography variant='subtitle2'>Step 2</Typography>
        </StepLabel>
      </Step>
    </Stepper>
  )
}

export default FormStepper
