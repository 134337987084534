import React from 'react'
import { Box, CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { DealItemizationDataType } from '../../../../../models'
import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', [
  'root',
  'title',
  'titleContainer',
  'leftItemTypography',
  'rightItemTypography',
  'rightItemContainer',
  'leftItemContainer',
])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.titleContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  [`& .${classes.title}`]: {
    textAlign: 'center',
  },
  [`& .${classes.leftItemContainer}`]: {
    width: '45%',
  },
  [`& .${classes.rightItemContainer}`]: {
    width: '45%',
    marginLeft: '15px',
  },

  [`& .${classes.leftItemTypography}`]: {
    textAlign: 'right',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  [`& .${classes.rightItemTypography}`]: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
}))

interface Props {
  itemizationData: any
}

const ItemizationCard = ({ itemizationData }: Props) => {
  return (
    <>
      <Grid container>
        <CardHeader title='Itemization' />
        <Grid item xs={12}>
          <Box sx={{ p: 3, pb: 0 }} display={'flex'} flexDirection={'column'}>
            {itemizationData?.map((item: DealItemizationDataType, index: number) => (
              <Box display={'flex'} alignItems={'baseline'}>
                <Typography sx={{ py: 1 }} variant='body2'>
                  {item?.Name} :
                </Typography>
                <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                  {formatNumber(item?.Amount)}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ItemizationCard
