import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

interface CardTypes {
  TradeAllowance: string
  TradeLien: string
  TradeACV: string
}

interface DealsTradeState {
  discrepancy: CardTypes
}

const initialState: DealsTradeState = {
  discrepancy: {
    TradeAllowance: '0.0000',
    TradeLien: '0.0000',
    TradeACV: '0.0000',
  },
}

export const setDiscrepancyData = createAction<CardTypes>('dealsTrade/setDiscrepancy')

export const slice = createSlice({
  name: 'dealsTrade',
  initialState,
  reducers: {
    setDiscrepancy: (state, action: PayloadAction<CardTypes>) => {
      state.discrepancy = action.payload
    },
  },
})

export const selectDiscrepancy = (state: RootState) => state.dealsTrade.discrepancy

export const { setDiscrepancy } = slice.actions
export default slice.reducer
