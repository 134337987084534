import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Box, Button, Grid, Popover, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import { DateField, PercentField, TextField, AmountField } from '../../../../../../../ui'
import dayjs from 'dayjs'
import { UspGetDataDealEntryResult, UspGetDataStateDealSettingsResult } from '../../../../../../../models'
import { useParams } from 'react-router-dom'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'rowBtn', 'btn', 'optionalText'])
const popoverClasses = generateUtilityClasses('div', ['root'])

const StyledPopover = styled('div')(() => ({
  [`&.${popoverClasses.root}`]: {
    padding: '20px',
    gap: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.rowBtn}`]: {
    margin: theme.spacing('auto', 0, 2, 0),
    justifyContent: 'center',
  },
  [`& .${classes.btn}`]: {
    justifySelf: 'center',
    alightSelf: 'center',
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  valid: boolean
  disableFields: boolean
  stateDealSettingsData?: UspGetDataStateDealSettingsResult
  dealData?: UspGetDataDealEntryResult | undefined
  setValue?: any
}

const PAYMENTS_PER_YEAR = {
  M: 12,
  S: 24,
  B: 26,
  W: 52,
}

export const TermsForm = ({ stateDealSettingsData, dealData, disableFields, setValue }: Props) => {
  const { dealID } = useParams()
  const { values, touched } = useFormState()
  const { change } = useForm()
  const dispatch = useDispatch()
  const [openPopover, setOpenPopover] = useState<HTMLButtonElement | null>(null)
  const [initialDesiredTermNumeric, setInitialDesiredTermNumeric] = useState<number | null>(null)

  const open = Boolean(openPopover)
  const id = open ? 'calculate-popover' : undefined

  const getFirstDealDate = (dealDate: string) => {
    if (stateDealSettingsData?.IsDefaultOneTermUntilFirstDue) {
      if (values?.Schedule === 'M') {
        return dayjs(dealDate).add(1, 'months').format('YYYY-MM-DD')
      }
      if (values?.Schedule === 'B') {
        return dayjs(dealDate).add(14, 'days').format('YYYY-MM-DD')
      }
      if (values?.Schedule === 'W') {
        return dayjs(dealDate).add(7, 'days').format('YYYY-MM-DD')
      }
      return undefined
    } else {
      return dayjs(dealDate)
        .add(stateDealSettingsData?.DefaultDaysBeforeFirstDue as number, 'days')
        .format('YYYY-MM-DD')
    }
  }

  const handleCalculation = (e: React.FocusEvent<HTMLInputElement>) => {
    const val = parseFloat(e?.target?.value)
    const paymentSchedule = values?.Schedule as keyof typeof PAYMENTS_PER_YEAR
    const paymentsPerYear = PAYMENTS_PER_YEAR[paymentSchedule]

    if (isNaN(val) || paymentsPerYear === undefined) {
      dispatch(enqueueNotification({ message: 'Input not valid.' }))
      return
    }

    const calculatedTerm = Math.ceil(val * paymentsPerYear).toString()
    change('CalculatedTerm', calculatedTerm)
  }

  const paymentSchedule = dealData?.PaymentSchedule as keyof typeof PAYMENTS_PER_YEAR
  let desiredTermNumeric: number | null = parseFloat(values?.DesiredTerm)

  const handleScheduleChange = (value: string) => {
    const newPaymentSchedule = value as keyof typeof PAYMENTS_PER_YEAR
    const newPaymentsPerYear = PAYMENTS_PER_YEAR[newPaymentSchedule]

    let currentDesiredTerm = parseFloat(values.DesiredTerm)

    // Get current payments per year before the dropdown is changed
    const currentPaymentSchedule = values?.Schedule as keyof typeof PAYMENTS_PER_YEAR
    const paymentsPerYear = PAYMENTS_PER_YEAR[currentPaymentSchedule]

    if (currentDesiredTerm && paymentsPerYear && newPaymentsPerYear) {
      const numberOfYears = currentDesiredTerm / paymentsPerYear
      const newDesiredTerm = Math.round(numberOfYears * newPaymentsPerYear)
      change('DesiredTerm', newDesiredTerm)
      let val = values.InterestRate / (paymentsPerYear * 200)
      change('leaseFactor', val)
    } else {
      dispatch(enqueueNotification({ message: 'Cannot perform calculations.' }))
    }
  }

  const handleUpdateCalculation = () => {
    setOpenPopover(null)
    change('CalculatedTerm', null)
    change('DesiredTerm', values?.CalculatedTerm)
  }

  const openCalculatePopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenPopover(event.currentTarget)
  }

  const closeCalculatePopover = () => {
    change('CalculatedTerm', null)
    setOpenPopover(null)
  }

  const validateFirstDueDate = (firstDueDate: string, dealDate: string) => {
    if (dayjs(firstDueDate).isBefore(dayjs(dealDate))) {
      return 'First Due Date should be on or after Deal Date.'
    }
  }

  useEffect(() => {
    if (desiredTermNumeric && initialDesiredTermNumeric === null) {
      setInitialDesiredTermNumeric(desiredTermNumeric)
    }
  }, [paymentSchedule])

  useEffect(() => {
    if (values?.DesiredTerm && values?.Schedule) {
      const newNumOfYears = parseFloat(values?.DesiredTerm) / PAYMENTS_PER_YEAR[values?.Schedule as keyof typeof PAYMENTS_PER_YEAR]
      change('NumberOfYears', newNumOfYears.toString())
      const paymentSchedule = values?.Schedule as keyof typeof PAYMENTS_PER_YEAR
      const paymentsPerYear = PAYMENTS_PER_YEAR[paymentSchedule]
      let val = values.InterestRate / (paymentsPerYear * 200)
      change('leaseFactor', val)
      console.log(val)
      // console.log(val)
      // console.log(values.InterestRate)
      // console.log(paymentsPerYear)
    }
  }, [values?.DesiredTerm, values?.Schedule])

  useEffect(() => {
    if (dealID === 'add' && !touched?.FirstDueDate && stateDealSettingsData) {
      change('FirstDueDate', getFirstDealDate(values.DealDate))
    }
  }, [values.DealDate, values.Schedule])

  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid container className={classes.row}>
          <Typography variant='subtitle1' gutterBottom>
            Terms
          </Typography>
        </Grid>
        <Grid className={classes.row}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='Schedule'
            onChange={(value: string) => handleScheduleChange(value)}
            label='Payment Schedule'
            options={[
              { key: 'W', value: 'Weekly' },
              { key: 'B', value: 'Bi-Weekly' },
              { key: 'S', value: 'Semi-Monthly' },
              { key: 'M', value: 'Monthly' },
            ]}
          />
        </Grid>
        {values.Schedule === 'S' && (
          <Grid container className={classes.row} columns={{ sm: 13 }}>
            <Grid item xs={6}>
              <TextField disabled={disableFields} name='DueDay1' label='First Due Day' fullWidth />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={6}>
              <TextField disabled={disableFields} name='DueDay2' label='Second Due Day' fullWidth />
            </Grid>
          </Grid>
        )}
        <Grid className={classes.row}>
          <DateField
            disabled={disableFields}
            name='FirstDueDate'
            label='First Due Date'
            fullWidth
            validate={(value) => validateFirstDueDate(value, values.DealDate)}
          />
        </Grid>
        {/* <Grid className={classes.row}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='Calculate'
            label='Calculate'
            options={[
              { key: 'payment', value: 'Payment' },
              { key: 'term', value: 'Term' },
            ]}
          />
        </Grid> */}
        {values.Calculate === 'term' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled={disableFields} name='DesiredPayment' label='Desired Payment' fullWidth autoComplete='off' />
          </Grid>
        )}
        {values.Calculate === 'payment' && (
          <Grid className={classes.row}>
            <TextField
              disabled={disableFields}
              type='number'
              isCalculate={values?.Calculate}
              openPopover={openCalculatePopover}
              name='DesiredTerm'
              label='Term'
              fullWidth
              autoComplete='off'
            />
          </Grid>
        )}
        <Grid className={classes.row}>
          <PercentField
            onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              let mapping = {
                W: 52,
                B: 26,
                S: 24,
                M: 12,
              }
              let val = Number(event.target.value) / (mapping[values.Schedule as unknown as keyof typeof mapping] * 200)
              change('leaseFactor', val)
            }}
            variant='standard'
            disabled={disableFields}
            name='InterestRate'
            label='Interest Rate'
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <TextField disabled type='number' name='leaseFactor' label='Lease Factor' fullWidth autoComplete='off' />
        </Grid>
      </StyledGrid>
      <Popover
        id={id}
        open={open}
        anchorEl={openPopover}
        onClose={closeCalculatePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box display={'flex'} flexDirection={'column'} gap={2} p={3}>
          <Typography variant='h6'>Calculate Term</Typography>
          <AmountField
            variant='standard'
            disabled={disableFields}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => handleCalculation(e)}
            name='NumberOfYears'
            label='Number Of Years'
            fullWidth
            autoComplete='off'
          />

          <TextField type='number' disabled name='CalculatedTerm' label='Calculated Term' fullWidth autoComplete='off' />
          <Button variant='contained' disabled={!values?.CalculatedTerm} onClick={handleUpdateCalculation}>
            Update
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default TermsForm
