import dayjs from 'dayjs'
import * as yup from 'yup'

const today = dayjs().startOf('day')

export const accountsAddSideNoteValidation: yup.SchemaOf<any> = yup
  .object({
    Amount: yup
      .number()
      .transform((value, originalValue) => {
        const processedValue = typeof originalValue === 'string' ? parseFloat(originalValue.replace(/,/g, '')) : originalValue
        return !isNaN(processedValue) ? processedValue : value
      })
      .label('Amount')
      .required()
      .min(0.1, 'Amount must be greater than 0')
      .typeError('Invalid number'),
    FirstDueDate: yup.date().label('First Due Date').required().nullable().min(today, 'Prior date not allowed').typeError('Invalid Date'),
    Note: yup.string().label('Note').notRequired(),
    Type: yup.string().label('Type').required(),
  })
  .required()
