import * as yup from 'yup'
export const accountsRepoChargeOffValidation = yup
  .object()
  .shape({
    ProcessType: yup.number().required('Type is required'),
    CreditCode: yup.string().required('Credit Code is required'),
    RepoACV: yup.string().when('ProcessType', {
      is: 1,
      then: yup
        .string()
        .required('Repo ACV is required')
        .test('is-greater-than-zero', 'Repo ACV must be greater than 0', (value) => {
          const numberValue = parseFloat(value as string)
          return numberValue > 0
        }),
      otherwise: yup.string().notRequired(),
    }),
    CurrentMileage: yup.string().when('ProcessType', {
      is: 1,
      then: yup.string().required('Current Mileage is required'),
      otherwise: yup.string().notRequired(),
    }),
    GoingToBranch: yup.string().when('ProcessType', {
      is: 1,
      then: yup.string().required('Going To Branch is required'),
      otherwise: yup.string().notRequired(),
    }),
  })
  .required()
