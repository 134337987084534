import { styled } from '@mui/system'
import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import React from 'react'
import AmountField from '../../../../../ui/AmountField'

const classes = generateUtilityClasses('Grid', ['row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))
const ValuesForm = () => {
  return (
    <>
      <CardHeader title='Values' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <AmountField variant='standard' disabled name='ACV' label='ACV' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' disabled name='Allowance' label='Allowance' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' disabled name='LienPayoff' label='Lien Payoff' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default ValuesForm
