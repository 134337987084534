import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../../../redux/slices/userDropdownSlice'
import { withPermissionsDropdown } from '../../../../../../../hoc'
import { selectDropdownByNameIsManagerOnly, selectDropdownByNameIsSalespersonOnly, useGetListDealStatusQuery } from '../../../../../../../services'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  disableFields: boolean
  managerID?: number | null
  salesPersonID?: number | null
}

export const SettingsForm = ({ disableFields, managerID, salesPersonID }: Props) => {
  const branchData = useSelector(getBranch)
  const { data: ListDealStatusDate } = useGetListDealStatusQuery({})
  const salesPerson = useSelector((state) => selectDropdownByNameIsSalespersonOnly(salesPersonID)(state, 'UserAll'))
  const manager = useSelector((state) => selectDropdownByNameIsManagerOnly(managerID)(state, 'UserAll'))

  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.row}>
        <Typography variant='subtitle1' gutterBottom>
          Settings
        </Typography>
      </Grid>
      <Grid className={classes.row}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='BranchID'
          fullWidth
          optionKey={'BranchID'}
          optionValue={'Branch'}
          label={'Branch'}
          options={branchData}
        />
      </Grid>
      <Grid className={classes.row}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='DealStatus'
          label='Deal Status'
          options={ListDealStatusDate || []}
          optionKey='ID'
          optionValue='Status'
        />
      </Grid>
      {salesPerson && (
        <Grid className={classes.row}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='Salesperson'
            label='Salesperson'
            options={salesPerson}
            optionKey={'UserID'}
            optionValue={'Name'}
          />
        </Grid>
      )}
      {manager && (
        <Grid className={classes.row}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='Manager'
            label='F&I Manager'
            options={manager}
            optionKey={'UserID'}
            optionValue={'Name'}
          />
        </Grid>
      )}
    </StyledGrid>
  )
}

export default withPermissionsDropdown(SettingsForm)
