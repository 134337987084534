import { useParams } from 'react-router-dom'
import { useGetTaskDealQuery } from '../../../../../services'
import { Grid } from '@mui/material'
import InfoCard from '../../../../../components/InfoCard/InfoCard'
import { useMemo } from 'react'
import { formatNumber } from '../../../../../utils/general'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'

const ModalCards = () => {
  const { dealID } = useParams()
  const { data: dealTask, isLoading } = useGetTaskDealQuery({ dealId: dealID as string })

  const itemizationData = useMemo(() => {
    const dealItemizationData = dealTask
      ? dealTask?.DealItemizationData.map((item) => ({
          title: item.Name,
          value: formatNumber(item.Amount),
        }))
      : []
    return [
      { title: 'Type', value: dealTask?.DealType },
      { title: 'State', value: dealTask?.DealState },
      { title: 'Deal Date', value: convertDateToDisplay(dealTask?.DealDate) },
      ...dealItemizationData,
    ]
  }, [dealTask])

  const termsData = useMemo(
    () => [
      { title: 'Pay Schedule', value: dealTask?.PaymentSchedule },
      { title: 'Term', value: dealTask?.Term },
      { title: 'Payment', value: dealTask?.PaymentAmount && formatNumber(dealTask?.PaymentAmount) },
      {
        title: 'Final Payment',
        value: dealTask?.FinalPaymentAmount && parseInt(dealTask?.FinalPaymentAmount) > 0 ? formatNumber(dealTask?.FinalPaymentAmount) : '',
      },
      { title: 'Interest Rate', value: dealTask?.InterestRate },
      { title: 'APR', value: dealTask?.APR },
    ],
    [dealTask],
  )

  const recapData = useMemo(
    () => (dealTask ? dealTask.DealProfitData.map((profit) => ({ title: profit.Name, value: profit.Amount && formatNumber(profit.Amount) })) : []),
    [dealTask],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <InfoCard heading='Itemization' data={itemizationData} />
      </Grid>
      <Grid item xs={12} lg={4}>
        {!(dealTask?.DealType === 'Cash' || dealTask?.DealType === 'Wholesale') && <InfoCard heading='Terms' data={termsData} />}
      </Grid>
      <Grid item xs={12} lg={4}>
        <InfoCard heading='Recap' data={recapData} />
      </Grid>
    </Grid>
  )
}

export default ModalCards
