import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import MenuItem from '@mui/material/MenuItem'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import { Table, TableBody } from '@mui/material'
import React, { ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { TableEmptyRows, TableHeadCustom, emptyRows, useTable } from '../../../../../components/table'
import Iconify from '../../../../../components/iconify'
import { convertDateTimeToDisplay, convertDateToDisplay } from '../../../../../utils/dateUtils'
import { formatNumber } from '../../../../../utils/general'
import Label from '../../../../../components/label'
import { useBoolean } from '../../../../../hooks/use-boolean'
import { usePopover } from '../../../../../components/custom-popover'

// ----------------------------------------------------------------------

type Props = {
  row: any
  selected?: boolean
  data?: any
  onViewRow?: VoidFunction
  onSelectRow?: VoidFunction
  onDeleteRow?: VoidFunction
}
type actionType = 'Chargeback/NSF' | 'Void/Reverse'

export default function OrderTableRow({ row, data, selected, onViewRow, onSelectRow, onDeleteRow }: Props) {
  const { AccountName, BranchID, ClosingBalance, Activity, Balance } = row
  const [actionType, setActionType] = useState<actionType>()
  const [openPopoverId, setOpenPopoverId] = useState<null | number>(null)
  const { dealID } = useParams()
  const table = useTable()
  const confirm = useBoolean()
  const [confirmDialog, setConfirmDialog] = useState<undefined | actionType>(undefined)

  const collapse = useBoolean()

  const popover = usePopover()

  const TABLE_HEAD = [
    { id: 'DistributionType', label: 'Distribution' },
    { id: 'DueDate', label: 'Due Date' },
    { id: 'LateDays', label: 'Late Days' },
    { id: 'Amount', label: 'Amount' },
    { id: 'Principal', label: 'Principal' },
    { id: 'Interest', label: 'Interest' },
    { id: 'LateFee', label: 'Late Fee' },
    { id: 'Chargeback', label: 'Chargeback' },
  ]

  const renderPrimary = (
    <TableRow hover>
      <TableCell sx={{ padding: '16px', width: '211px' }}>
        <Box>{AccountName}</Box>
      </TableCell>

      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
        <ListItemText
          primary={BranchID ? BranchID : '-'}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
          }}
        />
      </TableCell>
      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
        <ListItemText
          primary={formatNumber(ClosingBalance)}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
        <ListItemText
          primary={formatNumber(Activity)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
          }}
        />
      </TableCell>

      <TableCell sx={{ padding: '16px ', width: '211px' }} align='right'>
        {formatNumber(Balance)}
      </TableCell>
    </TableRow>
  )

  // const renderSecondary = (
  //   <TableRow>
  //     <TableCell sx={{ p: 0, border: 'none' }} colSpan={10}>
  //       <Collapse in={collapse.value} timeout='auto' unmountOnExit sx={{ bgcolor: 'background.neutral' }}>
  //         <Stack component={Paper} sx={{ m: 1.5 }}>
  //           <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
  //             <TableHeadCustom
  //               order={table.order}
  //               orderBy={table.orderBy}
  //               headLabel={TABLE_HEAD}
  //               rowCount={DistributionDataInJson?.length}
  //               numSelected={table.selected.length}
  //               onSort={table.onSort}
  //             />
  //             <TableBody>
  //               {DistributionDataInJson?.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage).map(
  //                 (row: {
  //                   DistributionType:
  //                     | string
  //                     | number
  //                     | boolean
  //                     | ReactElement<any, string | JSXElementConstructor<any>>
  //                     | Iterable<ReactNode>
  //                     | ReactPortal
  //                     | null
  //                     | undefined
  //                   DueDate: unknown
  //                   DaysLate:
  //                     | string
  //                     | number
  //                     | boolean
  //                     | ReactElement<any, string | JSXElementConstructor<any>>
  //                     | Iterable<ReactNode>
  //                     | ReactPortal
  //                     | null
  //                     | undefined
  //                   IsShowLateFee: any
  //                   AppliedAmount: string | number | undefined
  //                   Principal: string | number | undefined
  //                   InterestCollected: string | number | undefined
  //                   LateChargeCollected: string | number | undefined
  //                   ChargebackCollected: string | number | undefined
  //                 }) => (
  //                   <TableRow>
  //                     <TableCell> {row.DistributionType} </TableCell>
  //                     <TableCell> {convertDateToDisplay(row.DueDate as unknown as string)} </TableCell>
  //                     <TableCell> {row.DaysLate} </TableCell>
  //                     <TableCell> {formatNumber(row.AppliedAmount)} </TableCell>
  //                     <TableCell> {formatNumber(row.Principal)} </TableCell>
  //                     <TableCell> {formatNumber(row.InterestCollected)} </TableCell>
  //                     <TableCell> {formatNumber(row.LateChargeCollected)} </TableCell>
  //                     <TableCell> {formatNumber(row.ChargebackCollected)} </TableCell>
  //                   </TableRow>
  //                 ),
  //               )}

  //               <TableEmptyRows emptyRows={emptyRows(table.page, table.rowsPerPage, DistributionDataInJson?.length)} />
  //             </TableBody>
  //           </Table>
  //         </Stack>
  //       </Collapse>
  //     </TableCell>
  //   </TableRow>
  // )

  return (
    <>
      {renderPrimary}

      {/* {renderSecondary} */}
    </>
  )
}
