import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { Box, Button, Card, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField, TextField } from '../../../../../ui'
import { UspAccountingChartApAccountsView, VendorResponseSp } from '../../../../../models'
import { useForm, useFormState } from 'react-final-form'
import dayjs from 'dayjs'
import AddIcon from '@mui/icons-material/Add'
import { useLocation } from 'react-router-dom'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'detailsBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.detailsBtn}`]: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))

interface Props {
  vendorData: VendorResponseSp[]
  chartOfAccountApData: UspAccountingChartApAccountsView[]
  handleOpenAddMultilineLineBillModal?: () => void
  isFieldsLocked?: boolean
}

export const SettingsForm = ({ vendorData, chartOfAccountApData, handleOpenAddMultilineLineBillModal, isFieldsLocked }: Props) => {
  const { values } = useFormState()
  const form = useForm()
  const location = useLocation()
  const companyType = localStorage.getItem('companyType')

  useEffect(() => {
    const selectedVendor = vendorData?.find((v) => v?.ID === values?.Vendor)

    const netDaysDue = selectedVendor ? parseInt(selectedVendor?.NetDaysDue as string, 10) : 0
    const billDate = values.BillDate
    if (billDate && selectedVendor && !isNaN(netDaysDue)) {
      const newDueDate = dayjs(billDate).add(netDaysDue, 'day').format('YYYY-MM-DD')
      form.change('DueDate', newDueDate)
    }

    if (selectedVendor && companyType) {
      const vendorCompanyDetails = JSON?.parse(selectedVendor?.VendorCompanyDetailData || '[]')
      const matchingDetail = vendorCompanyDetails?.find((detail: VendorResponseSp) => detail?.CompanyType === companyType)

      if (matchingDetail) {
        form.change('ChartOfAccountBill', matchingDetail?.ChartOfAccountID)
        form.change('ChartOfAccountAP', matchingDetail?.ApChartOfAccountID)
      }
    }
  }, [values.Vendor, values.BillDate, form, vendorData, companyType])

  const coaApData = chartOfAccountApData?.filter((item) => item?.CompanyType === 'Sales')

  // Custom check to make sure all required fields are filled
  const areRequiredFieldsFilled = values.Vendor && values.BillDate && values.DueDate && values.BillNumber && values.ChartOfAccountAP

  return (
    <Grid container item spacing={2}>
      <Grid item xs={12}>
        <DropdownFieldSearch
          disabled={isFieldsLocked}
          name='Vendor'
          fullWidth
          label='Vendor'
          options={vendorData}
          optionKey='ID'
          optionValue='Name'
        />
      </Grid>
      <Grid item xs={12}>
        <DateField disabled={isFieldsLocked} name='BillDate' label='Bill Date' fullWidth />
      </Grid>
      <Grid item xs={12}>
        <DateField disabled={isFieldsLocked} name='DueDate' label='Due Date' fullWidth />
      </Grid>
      <Grid item xs={12}>
        <TextField disabled={isFieldsLocked} name='BillNumber' label='Bill Number' fullWidth />
      </Grid>
      <Grid item xs={12}>
        <DropdownFieldSearch
          disabled={isFieldsLocked}
          name='ChartOfAccountAP'
          label='Chart Of Account (A/P)'
          options={coaApData}
          optionKey={'ID'}
          optionValue={'AccountNumberName'}
        />
      </Grid>
      {location.pathname.includes('/billing/multiline') && (
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              disabled={!areRequiredFieldsFilled}
              startIcon={<AddIcon />}
              color='primary'
              variant='contained'
              onClick={handleOpenAddMultilineLineBillModal}
            >
              Detail
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default SettingsForm
