import React from 'react'
import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { TextField } from '../../../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
}))

const PaymentNoteCard = () => {
  return (
    <StyledGrid container className={classes.root}>
      <TextField multiline name='Comment' label='Comment' fullWidth autoComplete='off' />
    </StyledGrid>
  )
}

export default PaymentNoteCard
