import React from 'react'
import { styled } from '@mui/system'
import { Grid } from '@mui/material'
import { TextField, DateField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const FirstForm = ({}: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField disabled name='AccountName' label='Account Name' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='AccountNumber' label='Account Number' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <DateField disabled name='AccountingDate' label='Accounting Date' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='AccountingLedgerID' label='Accounting Ledger ID' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='BillNumber' label='Bill Number' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='BranchCode' label='Branch Code' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='ChartOfAccountID' label='Chart Of Account ID' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='CheckNumber' label='Check Number' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='CompanyType' label='Company Type' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='Name' label='Name' fullWidth />
      </Grid>
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='Note' label='Note'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='ProgramDescription' label='Program Description'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='StockNumber' label='Stock Number'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <AmountField variant="standard" name='TransactionAmount' label='Transaction Amount'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='TransactionDescription' label='Transaction Description'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='Transaction Number' label='Transaction Number'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='TransactionType' label='Transaction Type'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='UserCode' label='User Code'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='VIN' label='VIN'  fullWidth />*/}
      {/*</Grid>*/}
      {/*<Grid item xs={6}>*/}
      {/*  <TextField name='VendorCode' label='Vendor Code'  fullWidth />*/}
      {/*</Grid>*/}
    </Grid>
  )
}

export default FirstForm
