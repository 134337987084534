import { Button, Grid } from '@mui/material'
import { TextField } from '../../../../../ui'
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import { useLazyGetDealFlagDataQuery } from '../../../../../services'
import validateForm from '../../../../../utils/validateForm'
import { accountsFlagValidation } from '../../../../../validation/accountsFlagValidation'
import { UspDealFlagViewResult } from '../../../../../models'

interface Props {
  isAddEditFlagModalOpen: boolean
  addAccountFlags: (e: any) => void
  editAccountFlags: (e: any) => void
  selectedFlag: UspDealFlagViewResult | undefined
  setIsAddEditFlagModalOpen: React.Dispatch<React.SetStateAction<boolean>> | undefined
}

const AddEditFlagModal = ({ addAccountFlags, isAddEditFlagModalOpen, selectedFlag, editAccountFlags, setIsAddEditFlagModalOpen }: Props) => {
  const { dealID } = useParams()
  const [initialValues, setInitialValues] = useState({})
  const [flagData, { isSuccess, data }] = useLazyGetDealFlagDataQuery()

  const handleSubmit = (values?: any) => {
    if (selectedFlag) {
      editAccountFlags({
        dealId: dealID?.toString(),
        flagId: selectedFlag?.FlagID.toString() as string,
        uspEditDealFlag: { flag: values?.Flag },
      })
    } else {
      addAccountFlags({
        dealId: dealID?.toString(),
        uspEditDealFlag: { flag: values?.Flag },
      })
    }
    if (setIsAddEditFlagModalOpen) setIsAddEditFlagModalOpen(!isAddEditFlagModalOpen)
  }

  useEffect(() => {
    if (isAddEditFlagModalOpen && selectedFlag?.FlagID) {
      flagData({ flagId: selectedFlag?.FlagID.toString() })
    }
  }, [isAddEditFlagModalOpen, selectedFlag?.FlagID])

  useEffect(() => {
    if (isSuccess) {
      setInitialValues({
        Flag: data?.Flag,
      })
    }
  }, [isSuccess, data])

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateForm(accountsFlagValidation)}>
      {({ handleSubmit }: any) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} p={1}>
            <Grid item xs={12}>
              <TextField name='Flag' label='Flag' fullWidth multiline autoComplete='off' />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                flexDirection: 'row-reverse',
              }}
            >
              <Button variant='contained' type='submit'>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
}
export default AddEditFlagModal
