import React, { useEffect } from 'react'
import { IMaskInput } from 'react-imask'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import FilledInput from '@mui/material/FilledInput'
import { Field } from 'react-final-form'
import { OutlinedInput } from '@mui/material'

interface Props {
  name: string
  label: string
  variant?: 'standard' | 'outlined' | 'filled'
  [x: string]: any
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const AmountMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  const inputRef = React.createRef<HTMLInputElement>()

  const configBlocks = {
    thisInput: {
      mask: 'num',
      blocks: {
        num: {
          mask: Number,
          max: 100,
          min: 0,
          scale: 2,
          radix: '.',
          mapToRadix: [','],
        },
      },
    },
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('focus', () => {
        setTimeout(() => inputRef.current?.select(), 0)
      })
    }
  }, [])

  return (
    <IMaskInput
      {...other}
      blocks={configBlocks}
      mask='thisInput'
      onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
      inputRef={inputRef}
      overwrite
    />
  )
})

export const PercentField = ({ name, label, mask, maskChar, variant, fullWidth, endAdornment, disabled, onChange, ...props }: Props) => {
  return (
    <Field name={name}>
      {({ input, meta: { error, touched } }) => (
        <FormControl variant={variant} fullWidth={fullWidth} error={touched && error}>
          <InputLabel
            htmlFor='formatted-text-mask-input'
            disabled={disabled}
            variant='outlined'
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          >
            {label}
          </InputLabel>
          {variant === 'standard' && (
            <OutlinedInput
              {...input}
              {...props}
              onChange={(event) => {
                input.onChange(event)
                onChange && onChange(event)
              }}
              id='formatted-text-mask-input'
              inputComponent={AmountMaskCustom as any}
              endAdornment={endAdornment || '%'}
              disabled={disabled}
              autoComplete='off'
            />
          )}
          {variant === 'filled' && (
            <FilledInput
              {...input}
              {...props}
              id='formatted-text-mask-input'
              inputComponent={AmountMaskCustom as any}
              endAdornment={endAdornment || '%'}
              disabled={disabled}
              autoComplete='off'
            />
          )}
          {touched && error && <FormHelperText id='my-helper-text'>{error}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  )
}

export default PercentField
