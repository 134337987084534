import React, { useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { UspGetDataStateDealSettingsResult, UspListConfigurationFeeResult } from '../../../../../../../models'
import removeMask from '../../../../../../../utils/removeMask'
import Popover from '@mui/material/Popover'
import Grid from '@mui/material/Grid'
import AmountField from '../../../../../../../ui/AmountField'
import { CheckboxField } from '../../../../../../../ui'
import Button from '@mui/material/Button'
import { generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'

interface Props {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (value: HTMLButtonElement | null) => void
  listFeeData?: UspListConfigurationFeeResult[]
  stateDealSettingsData?: UspGetDataStateDealSettingsResult | null
  feesForm: any[]
}

const classesBox = generateUtilityClasses('Container', ['root', 'row', 'btnWrap', 'icon'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '272px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.row}`]: {
    marginBottom: theme.spacing(4),
  },
  [`& .${classesBox.btnWrap}`]: {
    justifyContent: 'space-between',
  },
  [`& .${classesBox.icon}`]: {
    fontSize: 16,
    color: theme.palette.text.button,
  },
}))

export const FeePopover = ({ anchorEl, setAnchorEl, feesForm }: Props) => {
  const open = Boolean(anchorEl)
  const [formValues, setFormValues] = useState<any>({})
  const { values } = useFormState()
  const { change } = useForm()

  const handleClose = () => {
    setAnchorEl(null)

    Object?.keys(formValues)?.forEach((item: string) => {
      change(item, formValues[item])
    })
  }

  const handleSave = () => {
    // save values and
    let fee = 0
    const formValues: any = {}
    Object?.keys(values)?.forEach((item: string) => {
      // get sum of all fee
      if (item?.includes('FeeFieldValue')) {
        formValues[item] = values[item]
        fee = fee + Number(removeMask(values[item]))
      }
      if (item?.includes('FeeFieldIsActive')) {
        formValues[item] = values[item]
      }
    })
    change(`Fees`, fee?.toString())
    setFormValues(formValues)

    setAnchorEl(null)
  }

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box display='flex' flexDirection='column' gap={3} p={3}>
        {feesForm?.map(({ id, fieldName, fieldLabel, feeInitialAmount, fieldCheckboxName, isFixedAmount }) => {
          return (
            <Box display='flex' justifyContent='center' gap={3}>
              <AmountField variant='standard' name={fieldName} label={fieldLabel} disabled={!values[fieldCheckboxName] || isFixedAmount} fullWidth />
              <CheckboxField
                sx={{ margin: 0 }}
                name={fieldCheckboxName}
                label=''
                onChange={(values) => {
                  if (values?.target?.checked) {
                    change(fieldName, feeInitialAmount?.toString())
                  } else {
                    change(fieldName, '0')
                  }
                }}
              />
            </Box>
          )
        })}
        <Grid container justifyContent={'flex-end'} spacing={2}>
          <Grid item>
            <Button variant='contained' color='error' onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant='contained' onClick={handleSave}>
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Popover>
  )
}

export default FeePopover
