import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField, DropdownCheckboxes } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly, selectDropdownByNameIsCollectorOnly } from '../../../../../services'

const classes = generateUtilityClasses('form', ['root', 'row', 'btnWrap'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
    minWidth: '350px',
    maxWidth: '350px',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.btnWrap}`]: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

interface Props {
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  formValues: any
}

const FilteringModal = ({ open, handleClose, formValues }: Props) => {
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))
  const lender = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Lender'))
  const dealer = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Dealer'))
  const collector = useSelector((state) => selectDropdownByNameIsCollectorOnly()(state, 'UserAll'))

  return (
    <Dialog open={open} onClose={handleClose}>
      <form>
        <DialogTitle>Charge Offs</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} py={1}>
            <Grid item xs={12}>
              <DateField name='startDate' label='Start Date' />
            </Grid>
            <Grid item xs={12}>
              <DateField name='endDate' label='End Date' />
            </Grid>
            {branch && (
              <Grid item xs={12}>
                <DropdownCheckboxes
                  name='Branch'
                  label='Branch'
                  options={branch}
                  optionKey={'BranchID'}
                  optionValue={'Branch'}
                  fullWidth
                  defaultValue={formValues?.Branch}
                />
              </Grid>
            )}

            {dealer && (
              <Grid item xs={12}>
                <DropdownCheckboxes
                  name='Dealer'
                  label='Dealer'
                  options={dealer}
                  optionKey={'ID'}
                  optionValue={'Name'}
                  fullWidth
                  defaultValue={formValues?.Dealer}
                />
              </Grid>
            )}

            {collector && (
              <Grid item xs={12}>
                <DropdownCheckboxes
                  name='Collector'
                  label='Collector'
                  options={collector}
                  optionKey={'ID'}
                  optionValue={'Name'}
                  fullWidth
                  defaultValue={formValues?.Collector}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' type='submit'>
            Run
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default FilteringModal
