import Stack from '@mui/material/Stack'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup'
import Iconify from '../iconify'

// ----------------------------------------------------------------------

export default function ChatHeaderDetail() {
  const renderSingle = (
    <Stack flexGrow={1} direction='row' alignItems='center' spacing={2}>
      <Badge variant={'online'} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Iconify icon='mingcute:ai-fill' />
      </Badge>

      <ListItemText primary={'Ella AI'} />
    </Stack>
  )

  return <> {renderSingle}</>
}
