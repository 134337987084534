import React, { useEffect, useState } from 'react'
import AccountingChecksSettings from '../AccountingChecksSettings/AccountingChecksSettings'
import { CustomCircular } from '../../../../../ui'
import AccountingChecksPaymentDetails from '../AccountingChecksPaymentDetails/AccountingChecksPaymentDetails'
import { TFullResponse, TSelectedSearchAccount } from '../../../../../models'
import { useAddAccountingCheckMutation } from '../../../../../services'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import { Container, Grid } from '@mui/material'

const AccountingChecksAdd = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)

  const [stage, setCurrentStage] = useState<'settings' | 'paymentDetails' | null>(null)
  const [totalSum, setTotalSum] = useState<number | string>('0')
  const [tableData, setTableData] = useState<TFullResponse[]>([])
  const [selectedVendor, setSelectedVendor] = useState<number | null>(null)
  const [selectedSearchAccount, setSelectedSearchAccount] = useState<TSelectedSearchAccount | {}>({})
  const [tableHasData, setTableHasData] = useState(false)

  const [addCheck, { isLoading: addCheckIsLoading, isSuccess: addCheckIsSuccess }] = useAddAccountingCheckMutation()

  useEffect(() => {
    setCurrentStage('settings')
  }, [])

  const settingsStyle: React.CSSProperties = stage === 'settings' ? {} : { display: 'none' }
  const paymentDetailsStyle: React.CSSProperties = stage === 'paymentDetails' ? {} : { display: 'none' }

  useEffect(() => {
    if (addCheckIsSuccess) {
      // Reset the state
      setTableHasData(false)
      setTotalSum('0')
      setTableData([])
      setSelectedVendor(null)
      setSelectedSearchAccount({})

      navigate(appRoute.AccountingChecks)
      dispatch(enqueueNotification({ message: 'Accounting Check Added Successfully' }))
    }
  }, [addCheckIsSuccess, navigate, dispatch])

  useEffect(() => {
    if (!userAccess.CanIssueChecks) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <>
      <Grid container spacing={2} p={3}>
        <Grid item xs={12} md={12}>
          <div style={settingsStyle}>
            <AccountingChecksSettings
              setTableHasData={setTableHasData}
              tableHasData={tableHasData}
              setTableData={setTableData}
              tableData={tableData}
              setCurrentStage={setCurrentStage}
              setTotalSum={setTotalSum}
              totalSum={totalSum}
              setSelectedVendor={setSelectedVendor}
              selectedSearchAccount={selectedSearchAccount}
              setSelectedSearchAccount={setSelectedSearchAccount}
              addCheckIsSuccess={addCheckIsSuccess}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={paymentDetailsStyle}>
            <AccountingChecksPaymentDetails
              stage={stage as string}
              setTableHasData={setTableHasData}
              addCheck={addCheck}
              addCheckIsLoading={addCheckIsLoading}
              selectedVendor={selectedVendor}
              totalSum={totalSum as string}
              tableData={tableData}
              setCurrentStage={setCurrentStage}
            />
          </div>
        </Grid>
      </Grid>
      {stage === null && <CustomCircular open={false} />}
    </>
  )
}

export default AccountingChecksAdd
