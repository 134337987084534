import React from 'react'
import { Box, Grid, generateUtilityClasses, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { formatNumber } from '../../../../../utils/general'
import { UspReportSalesResult } from '../../../../../models'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    minWidth: '190px',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportSalesResult[]
  isLoading: boolean
}

const SalesTableBlock = ({ filterData, isLoading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('insights_sales_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('insights_sales_order_by') || 'StockNumber') as keyof UspReportSalesResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspReportSalesResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportSalesResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('insights_sales_order', isAsc ? 'desc' : 'asc')
    storageSet('insights_sales_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportSalesResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  // Gather unique fee labels from all records
  const feeLabels = Array?.from(new Set(filterData?.flatMap((record) => record.FeeData)?.map((fee) => fee.FeeLabel)))
  const productLabels = Array?.from(new Set(filterData?.flatMap((record) => record.ProductData)?.map((product) => product.ProductLabel)))

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock #
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Buyer' ? order : false}>
              <TableSortLabel active={orderBy === 'Buyer'} direction={orderBy === 'Buyer' ? order : 'asc'} onClick={createSortHandler('Buyer')}>
                Buyer
                {orderBy === 'Buyer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DealDate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DealDate'}
                direction={orderBy === 'DealDate' ? order : 'asc'}
                onClick={createSortHandler('DealDate')}
              >
                Deal Date
                {orderBy === 'DealDate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Car' ? order : false}>
              <TableSortLabel active={orderBy === 'Car'} direction={orderBy === 'Car' ? order : 'asc'} onClick={createSortHandler('Car')}>
                Car
                {orderBy === 'Car' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'VIN' ? order : false}>
              <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                VIN
                {orderBy === 'VIN' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Lender' ? order : false}>
              <TableSortLabel active={orderBy === 'Lender'} direction={orderBy === 'Lender' ? order : 'asc'} onClick={createSortHandler('Lender')}>
                Lender
                {orderBy === 'Lender' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Salesperson' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Salesperson'}
                direction={orderBy === 'Salesperson' ? order : 'asc'}
                onClick={createSortHandler('Salesperson')}
              >
                Salesperson
                {orderBy === 'Salesperson' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'FIMgr' ? order : false}>
              <TableSortLabel active={orderBy === 'FIMgr'} direction={orderBy === 'FIMgr' ? order : 'asc'} onClick={createSortHandler('FIMgr')}>
                F&I Manager
                {orderBy === 'FIMgr' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Advertisement' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Advertisement'}
                direction={orderBy === 'Advertisement' ? order : 'asc'}
                onClick={createSortHandler('Advertisement')}
              >
                Advertisement
                {orderBy === 'Advertisement' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DealType' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DealType'}
                direction={orderBy === 'DealType' ? order : 'asc'}
                onClick={createSortHandler('DealType')}
              >
                Deal Type
                {orderBy === 'DealType' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DealState' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DealState'}
                direction={orderBy === 'DealState' ? order : 'asc'}
                onClick={createSortHandler('DealState')}
              >
                Deal State
                {orderBy === 'DealState' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'UnitCost' ? order : false}>
              <TableSortLabel
                active={orderBy === 'UnitCost'}
                direction={orderBy === 'UnitCost' ? order : 'asc'}
                onClick={createSortHandler('UnitCost')}
              >
                Unit Cost
                {orderBy === 'UnitCost' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SalesPrice' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SalesPrice'}
                direction={orderBy === 'SalesPrice' ? order : 'asc'}
                onClick={createSortHandler('SalesPrice')}
              >
                Sales Price
                {orderBy === 'SalesPrice' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            {feeLabels?.map((feeLabel) => (
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'FeeData' ? order : false} key={feeLabel}>
                <TableSortLabel
                  active={orderBy === 'FeeData'}
                  direction={orderBy === 'FeeData' ? order : 'asc'}
                  onClick={createSortHandler('FeeData')}
                >
                  {feeLabel}
                </TableSortLabel>
              </TableCell>
            ))}

            {productLabels?.map((productLabel) => (
              <>
                <TableCell sortDirection={orderBy === 'ProductData' ? order : false} key={productLabel + ' Price'} className={classes.tableHead}>
                  <TableSortLabel
                    active={orderBy === 'ProductData'}
                    direction={orderBy === 'ProductData' ? order : 'asc'}
                    onClick={createSortHandler('ProductData')}
                  >
                    {productLabel} Price
                  </TableSortLabel>
                </TableCell>
                <TableCell sortDirection={orderBy === 'ProductData' ? order : false} key={productLabel + ' Cost'} className={classes.tableHead}>
                  <TableSortLabel
                    active={orderBy === 'ProductData'}
                    direction={orderBy === 'ProductData' ? order : 'asc'}
                    onClick={createSortHandler('ProductData')}
                  >
                    {productLabel} Cost
                  </TableSortLabel>
                </TableCell>
              </>
            ))}

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'NetTrade' ? order : false}>
              <TableSortLabel
                active={orderBy === 'NetTrade'}
                direction={orderBy === 'NetTrade' ? order : 'asc'}
                onClick={createSortHandler('NetTrade')}
              >
                Net Trade
                {orderBy === 'NetTrade' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TradeAllowance' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TradeAllowance'}
                direction={orderBy === 'TradeAllowance' ? order : 'asc'}
                onClick={createSortHandler('TradeAllowance')}
              >
                Trade Allowance
                {orderBy === 'TradeAllowance' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TradeLien' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TradeLien'}
                direction={orderBy === 'TradeLien' ? order : 'asc'}
                onClick={createSortHandler('TradeLien')}
              >
                Trade Lien
                {orderBy === 'TradeLien' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TradeACV' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TradeACV'}
                direction={orderBy === 'TradeACV' ? order : 'asc'}
                onClick={createSortHandler('TradeACV')}
              >
                Trade ACV
                {orderBy === 'TradeACV' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SalesDiscountAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SalesDiscountAmount'}
                direction={orderBy === 'SalesDiscountAmount' ? order : 'asc'}
                onClick={createSortHandler('SalesDiscountAmount')}
              >
                Sales Discount
                {orderBy === 'SalesDiscountAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SpreadAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SpreadAmount'}
                direction={orderBy === 'SpreadAmount' ? order : 'asc'}
                onClick={createSortHandler('SpreadAmount')}
              >
                Spread
                {orderBy === 'SpreadAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SalesCommission' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SalesCommission'}
                direction={orderBy === 'SalesCommission' ? order : 'asc'}
                onClick={createSortHandler('SalesCommission')}
              >
                Sales Commission
                {orderBy === 'SalesCommission' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'ReferralFee' ? order : false}>
              <TableSortLabel
                active={orderBy === 'ReferralFee'}
                direction={orderBy === 'ReferralFee' ? order : 'asc'}
                onClick={createSortHandler('ReferralFee')}
              >
                Referral Fee
                {orderBy === 'ReferralFee' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'GrossProfit' ? order : false}>
              <TableSortLabel
                active={orderBy === 'GrossProfit'}
                direction={orderBy === 'GrossProfit' ? order : 'asc'}
                onClick={createSortHandler('GrossProfit')}
              >
                Gross Profit
                {orderBy === 'GrossProfit' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'NetProfit' ? order : false}>
              <TableSortLabel
                active={orderBy === 'NetProfit'}
                direction={orderBy === 'NetProfit' ? order : 'asc'}
                onClick={createSortHandler('NetProfit')}
              >
                Net Profit
                {orderBy === 'NetProfit' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CashDown' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CashDown'}
                direction={orderBy === 'CashDown' ? order : 'asc'}
                onClick={createSortHandler('CashDown')}
              >
                Cash Down
                {orderBy === 'CashDown' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Pickups' ? order : false}>
              <TableSortLabel active={orderBy === 'Pickups'} direction={orderBy === 'Pickups' ? order : 'asc'} onClick={createSortHandler('Pickups')}>
                Pickups
                {orderBy === 'Pickups' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SalesTax' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SalesTax'}
                direction={orderBy === 'SalesTax' ? order : 'asc'}
                onClick={createSortHandler('SalesTax')}
              >
                Sales Tax
                {orderBy === 'SalesTax' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxRate' ? order : false}>
              <TableSortLabel active={orderBy === 'TaxRate'} direction={orderBy === 'TaxRate' ? order : 'asc'} onClick={createSortHandler('TaxRate')}>
                Tax Rate
                {orderBy === 'TaxRate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'AmountFinanced' ? order : false}>
              <TableSortLabel
                active={orderBy === 'AmountFinanced'}
                direction={orderBy === 'AmountFinanced' ? order : 'asc'}
                onClick={createSortHandler('AmountFinanced')}
              >
                Amount Financed
                {orderBy === 'AmountFinanced' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'FinanceCharges' ? order : false}>
              <TableSortLabel
                active={orderBy === 'FinanceCharges'}
                direction={orderBy === 'FinanceCharges' ? order : 'asc'}
                onClick={createSortHandler('FinanceCharges')}
              >
                Finance Charges
                {orderBy === 'FinanceCharges' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TotalOfPayments' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TotalOfPayments'}
                direction={orderBy === 'TotalOfPayments' ? order : 'asc'}
                onClick={createSortHandler('TotalOfPayments')}
              >
                Total Payments
                {orderBy === 'TotalOfPayments' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'FinalizedDate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'FinalizedDate'}
                direction={orderBy === 'FinalizedDate' ? order : 'asc'}
                onClick={createSortHandler('FinalizedDate')}
              >
                Finalized Date
                {orderBy === 'FinalizedDate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'FullVIN' ? order : false}>
              <TableSortLabel active={orderBy === 'FullVIN'} direction={orderBy === 'FullVIN' ? order : 'asc'} onClick={createSortHandler('FullVIN')}>
                Full VIN
                {orderBy === 'FullVIN' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BuyerState' ? order : false}>
              <TableSortLabel
                active={orderBy === 'BuyerState'}
                direction={orderBy === 'BuyerState' ? order : 'asc'}
                onClick={createSortHandler('BuyerState')}
              >
                Buyer State
                {orderBy === 'BuyerState' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BuyerPhone' ? order : false}>
              <TableSortLabel
                active={orderBy === 'BuyerPhone'}
                direction={orderBy === 'BuyerPhone' ? order : 'asc'}
                onClick={createSortHandler('BuyerPhone')}
              >
                Buyer Phone
                {orderBy === 'BuyerPhone' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CoBuyer' ? order : false}>
              <TableSortLabel active={orderBy === 'CoBuyer'} direction={orderBy === 'CoBuyer' ? order : 'asc'} onClick={createSortHandler('CoBuyer')}>
                Co-Buyer
                {orderBy === 'CoBuyer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxZip' ? order : false}>
              <TableSortLabel active={orderBy === 'TaxZip'} direction={orderBy === 'TaxZip' ? order : 'asc'} onClick={createSortHandler('TaxZip')}>
                Tax Zip
                {orderBy === 'TaxZip' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxState' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TaxState'}
                direction={orderBy === 'TaxState' ? order : 'asc'}
                onClick={createSortHandler('TaxState')}
              >
                Tax State
                {orderBy === 'TaxState' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxCityName' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TaxCityName'}
                direction={orderBy === 'TaxCityName' ? order : 'asc'}
                onClick={createSortHandler('TaxCityName')}
              >
                Tax City
                {orderBy === 'TaxCityName' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxCountyName' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TaxCountyName'}
                direction={orderBy === 'TaxCountyName' ? order : 'asc'}
                onClick={createSortHandler('TaxCountyName')}
              >
                Tax County
                {orderBy === 'TaxCountyName' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StateTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StateTaxAmount'}
                direction={orderBy === 'StateTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('StateTaxAmount')}
              >
                State Tax
                {orderBy === 'StateTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CityTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CityTaxAmount'}
                direction={orderBy === 'CityTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('CityTaxAmount')}
              >
                City Tax
                {orderBy === 'CityTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CountyTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CountyTaxAmount'}
                direction={orderBy === 'CountyTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('CountyTaxAmount')}
              >
                County Tax
                {orderBy === 'CountyTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'MiscTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'MiscTaxAmount'}
                direction={orderBy === 'MiscTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('MiscTaxAmount')}
              >
                Misc Tax
                {orderBy === 'MiscTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DocStamps' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DocStamps'}
                direction={orderBy === 'DocStamps' ? order : 'asc'}
                onClick={createSortHandler('DocStamps')}
              >
                Doc Stamps
                {orderBy === 'DocStamps' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'PPFC' ? order : false}>
              <TableSortLabel active={orderBy === 'PPFC'} direction={orderBy === 'PPFC' ? order : 'asc'} onClick={createSortHandler('PPFC')}>
                PPFC
                {orderBy === 'PPFC' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'APR' ? order : false}>
              <TableSortLabel active={orderBy === 'APR'} direction={orderBy === 'APR' ? order : 'asc'} onClick={createSortHandler('APR')}>
                APR
                {orderBy === 'APR' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsFinalized' ? order : false}>
              <TableSortLabel
                active={orderBy === 'IsFinalized'}
                direction={orderBy === 'IsFinalized' ? order : 'asc'}
                onClick={createSortHandler('IsFinalized')}
              >
                Is Finalized
                {orderBy === 'IsFinalized' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.InventoryEdit.replace(':carID', item?.CarID?.toString() as string))}
              >
                {item.StockNumber}
              </TableCell>
              <TableCell
                className={classes.tableCellName}
                onClick={() => {
                  if (item?.IsFinalized) {
                    navigate(appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))
                  } else {
                    navigate(appRoute.DealsTask.replace(':dealID', item?.DealID?.toString() as string))
                  }
                }}
              >
                {item?.Buyer}
              </TableCell>
              <TableCell>{convertDateToDisplay(item?.DealDate?.toString() as string)}</TableCell>
              <TableCell>{item?.Car}</TableCell>
              <TableCell>{item?.VIN}</TableCell>
              <TableCell>{item?.Branch}</TableCell>
              <TableCell>{item?.Lender}</TableCell>
              <TableCell>{item?.Salesperson}</TableCell>
              <TableCell>{item?.FIMgr}</TableCell>
              <TableCell>{item?.Advertisement}</TableCell>
              <TableCell>{item?.DealType}</TableCell>
              <TableCell>{item?.DealState}</TableCell>
              <TableCell>{formatNumber(item?.UnitCost?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.SalesPrice?.toString() as string)}</TableCell>
              {feeLabels?.map((feeLabel: any) => {
                // @ts-ignore
                const fee = item?.FeeData?.find((f: any) => f?.FeeLabel === feeLabel) as any
                return <TableCell key={feeLabel}>{fee ? formatNumber(fee.FeeAmount.toString()) : '-'}</TableCell>
              })}
              {productLabels?.map((productLabel) => {
                // @ts-ignore
                const product = item?.ProductData?.find((p) => p?.ProductLabel === productLabel)
                return (
                  <>
                    <TableCell key={productLabel + ' Price'}>{product ? formatNumber(product?.ProductPrice.toString()) : '-'}</TableCell>
                    <TableCell key={productLabel + ' Cost'}>{product ? formatNumber(product?.ProductCost.toString()) : '-'}</TableCell>
                  </>
                )
              })}
              <TableCell>{formatNumber(item?.NetTrade?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.TradeAllowance?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.TradeLien?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.TradeACV?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.SalesDiscountAmount?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.SpreadAmount?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.SalesCommission?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.ReferralFee?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.GrossProfit?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.NetProfit?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.CashDown?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.Pickups?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.SalesTax?.toString() as string)}</TableCell>
              <TableCell>{item?.TaxRate}</TableCell>
              <TableCell>{formatNumber(item?.AmountFinanced?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.FinanceCharges?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.TotalOfPayments?.toString() as string)}</TableCell>
              <TableCell>{convertDateToDisplay(item?.FinalizedDate?.toString() as string)}</TableCell>
              <TableCell>{item?.FullVIN}</TableCell>
              <TableCell>{item?.BuyerState}</TableCell>
              <TableCell>{item?.BuyerPhone}</TableCell>
              <TableCell>{item?.CoBuyer}</TableCell>
              <TableCell>{item?.TaxZip}</TableCell>
              <TableCell>{item?.TaxState}</TableCell>
              <TableCell>{item?.TaxCityName}</TableCell>
              <TableCell>{item?.TaxCountyName}</TableCell>
              <TableCell>{formatNumber(item?.StateTaxAmount?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.CityTaxAmount?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.CountyTaxAmount?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.MiscTaxAmount?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.DocStamps?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.PPFC?.toString() as string)}</TableCell>
              <TableCell>{formatNumber(item?.APR?.toString() as string)}</TableCell>
              <TableCell>{item?.IsFinalized ? 'Yes' : 'No'}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SalesTableBlock
