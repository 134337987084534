import _ from 'lodash'
import { UspGetDataContact } from '../../../../models'

export const transformToRequestValues = (values?: any) => ({
  contactID: values.ContactID || null,
  insuranceAgent: values.Agent,
  note: values.Comments,
  isCPI: values.IsCPI,
  isActive: values.IsActive,
  businessName: values.InsuranceCompany,
  email: values.Email,
  addressID: values?.AddressID,
  address1: values?.Address1,
  address2: values?.Address2,
  city: values?.City,
  state: values?.State,
  postalCode: values?.PostalCode,
  county: values?.County,
  addressType: values?.AddressType,
  isPrimaryAddress: true,
  isContactUpdated: true,
  isAddressActive: true,
  isAddressUpdated: true,
  isPhoneUpdated: true,
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.ContactPhoneID || null,
      PhoneNumber: values?.PhoneNumber,
      PhoneType: values?.PhoneType,
      PhoneExtension: values?.PhoneExtension || null,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
    {
      ContactPhoneID: values?.SecondaryContactPhoneID || null,
      PhoneNumber: values?.SecondaryPhoneNumber,
      PhoneType: values?.SecondaryPhoneType,
      PhoneExtension: values?.SecondaryPhoneExtension || null,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
})

export const transformToFormValues = (insuranceData?: any, contactData?: UspGetDataContact, insuranceId?: any) => {
  // Initialize fields with default values
  let ContactPhoneID, PhoneNumber, PhoneExtension
  let SecondaryContactPhoneID, SecondaryPhoneNumber, SecondaryPhoneExtension
  let PhoneType = 'Biz'
  let SecondaryPhoneType = 'Fax'
  // Iterate over the ContactPhoneData array
  if (contactData?.ContactPhoneData) {
    for (const phoneData of contactData?.ContactPhoneData) {
      if (phoneData?.OrderOfPreference === 1) {
        ContactPhoneID = phoneData.ContactPhoneID
        PhoneNumber = phoneData.PhoneNumber
        PhoneType = phoneData.PhoneType || 'Biz'
        PhoneExtension = phoneData.PhoneExtension
      } else if (phoneData?.OrderOfPreference === 2) {
        SecondaryContactPhoneID = phoneData.ContactPhoneID
        SecondaryPhoneNumber = phoneData.PhoneNumber
        SecondaryPhoneType = phoneData.PhoneType || 'Fax'
        SecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }

  return {
    ContactID: insuranceData?.ContactID,
    InsuranceCompany: insuranceData?.Name,
    Agent: insuranceData?.InsuranceAgent,
    AddressID: _.first(contactData?.ContactAddressData)?.AddressID,
    Address1: _.first(contactData?.ContactAddressData)?.Address1,
    Address2: _.first(contactData?.ContactAddressData)?.Address2,
    City: _.first(contactData?.ContactAddressData)?.City,
    County: _.first(contactData?.ContactAddressData)?.County,
    State: _.first(contactData?.ContactAddressData)?.State,
    PostalCode: _.first(contactData?.ContactAddressData)?.PostalCode,
    Email: contactData?.Email,
    IsCPI: insuranceData?.IsCPI,
    Comments: insuranceData?.Note,
    IsActive: insuranceId === 'add' ? true : insuranceData?.IsActive,
    ContactPhoneID,
    SecondaryContactPhoneID,
    PhoneType,
    PhoneNumber,
    PhoneExtension,
    SecondaryPhoneNumber,
    SecondaryPhoneExtension,
    SecondaryPhoneType,
  }
}
