import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'
import DateField from '../../../../../ui/DateField'
import TextField from '../../../../../ui/TextField'

const classes = generateUtilityClasses('Grid', ['row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

const LienForm = () => {
  return (
    <>
      <CardHeader title='Lien' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <AmountField variant='standard' name='PerDiem' label='Per Diem' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <DateField name='PayoffGoodUntil' label='Payoff Good Until' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='AccountNumber' label='Account Number' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Note' label='Comments' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default LienForm
