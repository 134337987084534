import React from 'react'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import AmountField from '../../../../../ui/AmountField'
import Divider from '@mui/material/Divider'
import { Grid, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material'
import { TextField, DateField } from '../../../../../ui'
import { GetCarApiResponse } from '../../../../../models'
interface Props {
  carData: GetCarApiResponse
  onDropdownFormChange: () => void
  disableFields: boolean
  hideTotalCost: boolean
}

export const PurchaseForm = ({ carData, onDropdownFormChange, disableFields, hideTotalCost }: Props) => {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={6}>
        <DateField disabled={disableFields} name='stockDate' label='Stock Date' disableFuture fullWidth onDropdownFormChange={onDropdownFormChange} />
      </Grid>
      <Grid item xs={6}>
        <DateField name='soldDate' label='Sold Date' disableFuture fullWidth disabled onDropdownFormChange={onDropdownFormChange} />
      </Grid>
      <Grid item xs={6}>
        <TextField name='boughtFrom' label='Purchased From' disabled fullWidth autoComplete='off' />
      </Grid>
      <Grid item xs={6}>
        <TextField name='purchaseType' label='Purchase Type' disabled fullWidth autoComplete='off' />
      </Grid>
      {!hideTotalCost && (
        <Grid item xs={6}>
          <AmountField
            variant='standard'
            disabled
            name='totalCost'
            label='Total Cost'
            endAdornment={
              <Tooltip
                placement='left-start'
                title={
                  <>
                    {carData?.CostData?.map((cost, index) => (
                      <React.Fragment key={index}>
                        <Grid
                          container
                          sx={{
                            minWidth: 250,
                          }}
                        >
                          <Grid xs={7} item>
                            <Typography p={1}>{cost?.ExpenseType}</Typography>
                          </Grid>
                          <Grid xs={5} item>
                            <Typography p={1}>{cost?.Total}</Typography>
                          </Grid>
                        </Grid>
                        <Divider />
                      </React.Fragment>
                    ))}
                  </>
                }
              >
                <VisibilityOutlinedIcon />
              </Tooltip>
            }
            fullWidth
          />
        </Grid>
      )}

      <Grid item xs={6}>
        <AmountField variant='standard' disabled={disableFields} name='softPack' label='Soft Pack' fullWidth required />
      </Grid>
    </Grid>
  )
}

export default PurchaseForm
