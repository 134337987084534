import isEqual from 'lodash/isEqual'
import React, { useState, useEffect, useCallback } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import {
  DataGrid,
  GridColDef,
  GridToolbarExport,
  GridActionsCellItem,
  GridToolbarContainer,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid'

import { paths } from '../../../routes/paths'
import { useRouter } from '../../../routes/hooks'
import { RouterLink } from '../../../routes/components'

import { useBoolean } from '../../../hooks/use-boolean'

import Iconify from '../../../components/iconify'
import { useSnackbar } from '../../../components/snackbar'
import EmptyContent from '../../../components/empty-content'
import { ConfirmDialog } from '../../../components/custom-dialog'
import { useSettingsContext } from '../../../components/settings'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs'

// import { IProductItem, IProductTableFilters, IProductTableFilterValue } from 'src/types/product'

import ProductTableToolbar from './product-table-toolbar'
import ProductTableFiltersResult from './product-table-filters-result'
import { RenderCellStock, RenderCellPrice, RenderCellPublish, RenderCellProduct, RenderCellCreatedAt } from './product-table-row'
import { useSearchCarMutation } from '../../../services'
import { SearchCarApiResponse, UspCarSearchResult } from '../../../models'
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../constants'
import CenteredModal from '../../../components/Modal/CenteredModal'
import { convertDateToDisplay } from '../../../utils/dateUtils'
import { getComparator, stableSort } from '../../../utils/sorting'
import Label from '../../../components/label/label'
import { storageGet, storageSet } from '../../../utils/storage'
import CustomPopover from '../../../components/custom-popover/custom-popover'
import { usePopover } from '../../../components/custom-popover'
import { Form } from 'react-final-form'
import { InventorySearchBlock } from '../../Deals/DealsAddEdit/subpages/SelectInventory/components/InventorySearchBlock'

// ----------------------------------------------------------------------

const PRODUCT_STOCK_OPTIONS = [
  { value: 'in stock', label: 'In stock' },
  { value: 'low stock', label: 'Low stock' },
  { value: 'out of stock', label: 'Out of stock' },
]

const PUBLISH_OPTIONS = [
  { value: 'published', label: 'Published' },
  { value: 'draft', label: 'Draft' },
]

const defaultFilters = {
  publish: [],
  stock: [],
}

const HIDE_COLUMNS = {
  category: false,
}

const HIDE_COLUMNS_TOGGLABLE = ['category', 'actions']

// ----------------------------------------------------------------------

export default function InventoryPage() {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const [addNewModalOpen, setAddNewModalOpen] = useState(false)

  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()

  const confirmRows = useBoolean()

  const router = useRouter()

  const settings = useSettingsContext()

  const [searchCars, { data: searchCarsData, isLoading: searchCarsIsLoading }] = useSearchCarMutation()

  const [tableData, setTableData] = useState<UspCarSearchResult[]>([])

  const [filters, setFilters] = useState(defaultFilters)

  const [selectedRowIds, setSelectedRowIds] = useState<GridRowSelectionModel>([])

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(HIDE_COLUMNS)
  const initialOrder = (storageGet('select_inventory_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('select_inventory_order_by') || 'StockNumber') as keyof UspCarSearchResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspCarSearchResult>(initialOrderBy)

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = searchCarsData
  const [vin, setVin] = useState('')
  const [selectedItem, setSelectedItem] = React.useState<any>(null)
  const popover = usePopover()
  const handleEdit = (id: number | string) => {
    navigate(appRoute.InventoryEdit.replace(':carID', String(id)))
    // if the user is redirected from Leads-Task->Interested In
    // if (id && leadID && lookingAtRequest) {
    //   lookingAtRequest({
    //     creditAppId: leadID?.toString() as string,
    //     carId: id?.toString() as string,
    //   })
    // } else {
    // }
  }

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    searchCars({
      uspCarSearch: {
        searchString: values.searchString,
        jsonBranchList: jsonBranchList,
        isIncludeSoldCars: false,
        isPendingTradeOnly: false,
      },
    })
  }

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCarSearchResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('select_inventory_order', isAsc ? 'desc' : 'asc')
    storageSet('select_inventory_order_by', property)
  }

  const createSortHandler = (property: keyof UspCarSearchResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleAddNew = useCallback(() => {
    if (!!vin.length) {
      navigate(appRoute.InventoryAddVin.replace(':vin', vin))
    } else {
      navigate(appRoute.InventoryAdd)
    }
  }, [vin, navigate])

  useEffect(() => {
    searchCars({
      uspCarSearch: {},
    })
  }, [])

  useEffect(() => {
    if (searchCarsData && searchCarsData?.length) {
      setTableData(searchCarsData)
    }
  }, [searchCarsData])

  const columns: GridColDef[] = [
    {
      field: 'StockNumber',
      headerName: 'Stock #',
      filterable: false,
    },
    {
      field: 'Vehicle',
      headerName: 'Description',
      filterable: false,
      hideable: false,
      flex: 1,
    },
    {
      field: 'FullVIN',
      headerName: 'VIN',
      filterable: false,
      flex: 2,
    },
    {
      field: 'Color',
      headerName: 'Color',
      filterable: false,
    },
    {
      field: 'Mileage',
      headerName: 'Mileage',
      filterable: false,
    },
    {
      field: 'CarStatus',
      headerName: 'Status',
      filterable: false,
    },
    {
      field: 'StockDate',
      headerName: 'Stocked',
      filterable: false,
      renderCell: (params) => <div>{convertDateToDisplay(params.row.StockDate)}</div>,
    },
    {
      field: 'BranchCode',
      headerName: 'Branch',
      filterable: false,
    },
    {
      field: 'IsAvailable',
      headerName: 'Available',
      filterable: false,
      width: 80,
      renderCell: (params) => <RenderCellPublish params={params} />,
    },
    {
      type: 'actions',
      field: 'actions',
      headerName: ' ',
      align: 'right',
      headerAlign: 'right',
      width: 80,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      getActions: (params) => [
        <GridActionsCellItem showInMenu icon={<Iconify icon='solar:pen-bold' />} label='Edit' onClick={() => handleEdit(params.row.CarID)} />,
      ],
    },
  ]

  return (
    <>
      <Container
        maxWidth={settings.themeStretch ? false : 'lg'}
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CustomBreadcrumbs
          heading='Inventory'
          links={[
            {
              name: 'List',
            },
          ]}
          action={
            <Button onClick={() => setAddNewModalOpen(true)} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
              Add new
            </Button>
          }
          sx={{
            mb: {
              xs: 3,
              md: 5,
            },
          }}
        />
        <Form
          onSubmit={handleSubmit}
          initialValues={{ searchString: null }} // require to avoid first debounce search
        >
          {({ handleSubmit, form: { submit } }: any) => (
            <form onSubmit={handleSubmit}>
              <Card
                sx={{
                  // height: { xs: 800, md: 2 },
                  flexGrow: { md: 1 },
                  display: { md: 'flex' },
                  flexDirection: { md: 'column' },
                }}
              >
                <InventorySearchBlock submit={submit} />
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'StockNumber'}
                            direction={orderBy === 'StockNumber' ? order : 'asc'}
                            onClick={createSortHandler('StockNumber')}
                          >
                            Stock #
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'Vehicle'}
                            direction={orderBy === 'Vehicle' ? order : 'asc'}
                            onClick={createSortHandler('Vehicle')}
                          >
                            Description
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                            VIN
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'Color'}
                            direction={orderBy === 'Color' ? order : 'asc'}
                            onClick={createSortHandler('Color')}
                          >
                            Color
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'Mileage'}
                            direction={orderBy === 'Mileage' ? order : 'asc'}
                            onClick={createSortHandler('Mileage')}
                          >
                            Mileage
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'CarStatus'}
                            direction={orderBy === 'CarStatus' ? order : 'asc'}
                            onClick={createSortHandler('CarStatus')}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'StockDate'}
                            direction={orderBy === 'StockDate' ? order : 'asc'}
                            onClick={createSortHandler('StockDate')}
                          >
                            Stocked
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'BranchCode'}
                            direction={orderBy === 'BranchCode' ? order : 'asc'}
                            onClick={createSortHandler('BranchCode')}
                          >
                            Branch
                          </TableSortLabel>
                        </TableCell>
                        <TableCell>
                          <TableSortLabel
                            active={orderBy === 'IsAvailable'}
                            direction={orderBy === 'IsAvailable' ? order : 'asc'}
                            onClick={createSortHandler('IsAvailable')}
                          >
                            Available
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align={'right'}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(getComparator(order, orderBy), paginatedData as any)
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((item) => (
                          <TableRow key={item.CarID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }} hover>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{item.StockNumber}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{item?.Vehicle}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{item?.FullVIN}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{item?.Color}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{item?.Mileage}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{item?.CarStatus}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{convertDateToDisplay(item?.StockDate as string)}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>{item?.BranchCode}</TableCell>
                            <TableCell onClick={() => handleEdit(item.CarID)}>
                              {item.IsAvailable ? <Label color='success'>Yes</Label> : <Label color='error'>No</Label>}
                            </TableCell>
                            <TableCell align={'right'}>
                              <IconButton
                                onClick={(e) => {
                                  setSelectedItem(item)
                                  popover.onOpen(e)
                                }}
                              >
                                <Iconify icon='eva:more-vertical-fill' />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      {searchCarsData?.length === 0 && (
                        <TableRow>
                          <TableCell colSpan={10} align='center'>
                            No results
                          </TableCell>
                        </TableRow>
                      )}
                      {searchCarsIsLoading && (
                        <TableRow>
                          <TableCell colSpan={10} align='center'>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
                  <Paper>
                    <TablePagination
                      component='div'
                      count={searchCarsData?.length as number}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </Paper>
                </Box>
              </Card>
            </form>
          )}
        </Form>
      </Container>

      <CenteredModal
        open={addNewModalOpen}
        setOpen={setAddNewModalOpen}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          minWidth: 300,
        }}
      >
        <>
          <Grid>
            <TextField value={vin} onChange={(e) => setVin(e.target.value)} name='vinAdd' label='VIN (Optional)' fullWidth />
          </Grid>
          <Grid container item justifyContent={'flex-end'}>
            <Button
              variant='contained'
              onClick={handleAddNew}
              sx={{
                mt: 5,
              }}
            >
              Next <ArrowForwardIosIcon />
            </Button>
          </Grid>
        </>
      </CenteredModal>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140, p: 0 }}>
        <MenuItem onClick={() => handleEdit(selectedItem.CarID)}>
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
      </CustomPopover>
    </>
  )
}
