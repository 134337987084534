import { Card, Grid, Paper, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { motion } from 'framer-motion'

const classes = generateUtilityClasses('Grid', ['root', 'title', 'subTitle', 'wrapper', 'data'])

const StyledCard = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.wrapper}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  [`& .${classes.title}`]: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  [`& .${classes.data}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.subTitle}`]: {
    fontSize: '20px',
    fontWeight: 500,
  },
}))

interface Props {
  title: string
  subTitle: string
  icon: React.ReactElement
  color: string
}

const InventoryCardOne = ({ title, subTitle, icon, color }: Props) => {
  return (
    <motion.div
      className='box'
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <StyledCard className={classes.root}>
        <Grid container className={classes.wrapper}>
          <Grid item className={classes.data}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.subTitle}>{subTitle}</Typography>
          </Grid>
          <Grid item>{React.cloneElement(icon, { style: { fontSize: '50px', color } })}</Grid>
        </Grid>
      </StyledCard>
    </motion.div>
  )
}

export default InventoryCardOne
