import removeMask from '../../../utils/removeMask'

export const transformToRequestValues = (values?: any) => ({
  isCalculateDealUsingAddOnMethod: values?.CalculateDealRate,
  stateTaxRate: values?.StateTaxRate,
  miscTaxRate: values?.MiscTaxRate,
  isDestinationTax: values?.IsDestionationTax,
  isTradeTaxCredit: values?.TradeTaxCredit,
  defaultTerm: values?.DefaultTerm,

  defaultSchedule: values?.DefaultPaymentSchedule,
  defaultDueDay1: values?.Default1stDueDay,
  defaultDueDay2: values?.Default2ndDueDay,
  isDefaultOneTermUntilFirstDue: values?.Default1Term,

  defaultDaysBeforeFirstDue: values?.DefaultDaysUntilDueDate?.toString(),
  maxInterestRate: values?.MaxInterestRate,

  lateGraceDays: values?.LateChargeGracePeriod,
  lateChargeRate: values?.LateChargeRate,
  lateChargeMax: removeMask(values?.LateChargeMax),
  lateChargeMin: removeMask(values?.LateChargeMin),

  isLateChargeBasedOnDueAmount: values?.LateChargeBased,
  chargebackFee: removeMask(values?.ChargebackFee),
  docStampFeePer100: removeMask(values?.DocStamps),

  interestMethod: values?.InterestMethod,
  interestRebateMethod: values?.InterestRebateMethod,

  isInterestAfterMaturity: values?.CalculateInterestAfterMaturity,
  interestAfterMaturityRate: values?.InterestRateAfterMaturity,

  daysPerYear: values?.InterestDaysPerYear,
  advanceDueDateRate: values?.AdvanceDueDateRate,

  isFeesTowardAdvance: values?.ApplyFees,

  processingFee: removeMask(values?.PaymentProcessingFee),
  isAllowProcessingFeeOnCC: values?.ProcessingFeeOnCreditCardPayment,
  isTxSalesTaxDeferred: values?.DefferedSalesTax,
  rebateInterestDays: values?.InterestRebateDays,
  rebateProductDays: values?.FIProductsRebate,
  defaultInterestRate: values?.DefaultInterestRate,
})

export const transformToFormValues = (data?: any) => {
  return {
    StateTaxRate: data?.StateTaxRate?.toString(),
    MiscTaxRate: data?.MiscTaxRate?.toString(),
    IsDestionationTax: data?.IsDestinationTax,
    TradeTaxCredit: data?.IsTradeTaxCredit,
    DefferedSalesTax: data?.IsTxSalesTaxDeferred,

    LateChargeGracePeriod: data?.LateGraceDays?.toString(),
    LateChargeRate: data?.LateChargeRate?.toString(),
    LateChargeMax: data?.LateChargeMax?.toString(),
    LateChargeMin: data?.LateChargeMin?.toString(),
    LateChargeBased: data?.IsLateChargeBasedOnDueAmount,
    ChargebackFee: data?.ChargebackFee?.toString(),
    AdvanceDueDateRate: data?.AdvanceDueDateRate?.toString(),

    ApplyFees: data?.IsFeesTowardAdvance,
    PaymentProcessingFee: data?.ProcessingFee?.toString(),
    ProcessingFeeOnCreditCardPayment: data?.IsAllowProcessingFeeOnCC,
    DocStamps: data?.DocStampFeePer100?.toString(),
    State: data?.State?.toString(),

    DefaultTerm: data?.DefaultTerm?.toString(),
    DefaultPaymentSchedule: data?.DefaultSchedule?.toString(),
    Default1Term: data?.IsDefaultOneTermUntilFirstDue,

    DefaultDaysUntilDueDate: data?.DefaultDaysBeforeFirstDue?.toString(),
    DefaultInterestRate: data?.DefaultInterestRate?.toString(),

    Default1stDueDay: data?.DefaultDueDay1?.toString(),
    Default2ndDueDay: data?.DefaultDueDay2?.toString(),

    InterestMethod: data?.InterestMethod?.toString(),
    InterestRebateMethod: data?.InterestRebateMethod?.toString(),
    InterestRebateDays: data?.RebateInterestDays?.toString(),
    FIProductsRebate: data?.RebateProductDays?.toString(),
    MaxInterestRate: data?.MaxInterestRate?.toString(),

    CalculateInterestAfterMaturity: data?.IsInterestAfterMaturity,
    InterestDaysPerYear: data?.DaysPerYear,
    InterestRateAfterMaturity: data?.InterestAfterMaturityRate?.toString(),
    CalculateDealRate: data?.IsCalculateDealUsingAddOnMethod, //
  }
}
