import { Button, Grid, IconButton, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { Dispatch, SetStateAction } from 'react'
import SettingsForm from './components/SettingsForm'
import PayForm from './components/PayForm'
import Summary from './components/Summary'
import { AccountingUnpaidBillsData, UspListAccountingCashAccountsResult } from '../../../../models'
import AttachFileIcon from '@mui/icons-material/AttachFile'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'fieldsWrap', 'formItem', 'formItemFullHeight', 'buttonWrap'])

const StyledForm = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 80px)',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formItemFullHeight}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    borderRadius: 0,
  },
  [`& .${classes.formItem}`]: {
    minHeight: '340px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    marginTop: 'auto',
  },
}))

interface Props {
  setCurrentStage: Dispatch<SetStateAction<'bill-pay-table' | 'bill-pay-summary' | null>>
  summaryData: AccountingUnpaidBillsData[]
  cashAccountsData: UspListAccountingCashAccountsResult[] | undefined
  setDisplayedBillsData: Dispatch<SetStateAction<AccountingUnpaidBillsData[]>>
  checkID: number
}

const BillPaySummary = ({ setCurrentStage, summaryData, cashAccountsData, checkID }: Props) => {
  return (
    <>
      {/* <form> */}
      <Grid container>
        <Grid item xs={12}>
          <SettingsForm checkID={checkID} cashAccountsData={cashAccountsData} />
        </Grid>
        <Grid item xs={12}>
          <PayForm />
        </Grid>
        <Grid item xs={12}>
          <Summary summaryData={summaryData} />
        </Grid>
      </Grid>
      <Grid container justifyContent={'flex-end'} spacing={2} p={3} alignItems={'center'}>
        <Grid item>
          <Button
            type='button'
            variant='contained'
            color='warning'
            onClick={() => {
              setCurrentStage('bill-pay-table')
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item>
          <IconButton>
            <AttachFileIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Button type='submit' variant='contained'>
            Pay Bills
          </Button>
        </Grid>
      </Grid>
      {/* </form> */}
    </>
  )
}

export default BillPaySummary
