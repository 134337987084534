import React, { ComponentProps, ComponentType } from 'react'

import { useFormContext } from 'react-hook-form'

export const HookedField = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  component: Component,
  ...componentProps
}: {
  component: TComponent
  helperText?: string
} & Partial<Omit<TProps, 'component'>>) => {
  const { register, getFieldState } = useFormContext()
  // @ts-ignore
  const { error } = getFieldState(componentProps.name)

  return (
    // @ts-ignore
    <Component
      {...componentProps}
      // @ts-ignore
      {...register(componentProps.name)}
      error={error}
    />
  )
}
