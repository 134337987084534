import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

interface Props {
  open: boolean
  onOk: () => void
  onNo: () => void
  handleClose: () => void
  notificationTitle?: string
  notification: string
}
const InterestedInConfirmDialog = ({ open, onOk, onNo, handleClose, notificationTitle, notification }: Props) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      {notificationTitle && <DialogTitle id='alert-dialog-title'>{notificationTitle}</DialogTitle>}
      <DialogContent sx={{ pt: 3 }}>
        <DialogContentText id='alert-dialog-description'>{notification}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo}>No</Button>
        <Button autoFocus onClick={onOk}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InterestedInConfirmDialog
