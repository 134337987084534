import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Unstable_Grid2'
import LoadingButton from '@mui/lab/LoadingButton'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { Box, CardHeader, Checkbox, FormGroup, IconButton, InputBase, Tab, Tabs, Typography, alpha } from '@mui/material'
import { Key, useCallback, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useSelector } from 'react-redux'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import { selectDropdownByName, selectDropdownByNameActiveOnly, useAddNotesMutation, useGetAccountNotesHistoryMutation } from '../../../services'
import Iconify from '../../../components/iconify'
import validateFormValues from '../../../utils/validateForm'
import { accountsAddNoteValidation } from '../../../validation/accountsAddNote'
import DropdownFieldSearch from '../../../ui/DropdownFieldSearch'
import { AmountField, CheckboxField, DateField, TextField } from '../../../ui'
import NotesItem from '../../Leads/LeadsList/components/notes-item'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import DateTimeField from '../../../ui/DateTimeField'
import { transformToRequestValues } from './transform'
import { convertDateToString } from '../../../utils/dateUtils'
import dayjs from 'dayjs'
import { useFetchTemplateDealMutation, useSearchTemplateMutation } from '../../../services/Templates/TemplateApi'

// ----------------------------------------------------------------------

export default function AccountNotes({ data }: any) {
  const { dealID, leadID } = useParams()
  const [tabValue, setTabValue] = useState(1)
  const [addNotes, { isSuccess }] = useAddNotesMutation()
  const assignedToDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'UserAll'))
  const callOutcomeDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CallOutcome'))
  const followUpTypeDropdown = useSelector((state) => selectDropdownByName(state, 'FollowUpType'))
  const { UserID } = useSelector(getUserAccessRights)
  const [refresh, setRefresh] = useState(false)
  const [getAccountNotesHistory, { data: accountNotesHistoryData, isLoading: accountNotesHistoryIsLoading }] = useGetAccountNotesHistoryMutation()

  const twilioDropdown = useSelector((state) => selectDropdownByName(state, 'Twilio'))
  const [searchTemplate, { data: templateData, isSuccess: searchTemplateSuccess, isLoading }] = useSearchTemplateMutation()
  const [fetchTemplate, { data: fetchTemplateData, isSuccess: fetchTemplateSuccess }] = useFetchTemplateDealMutation()
  let formVar: any

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }, [])
  console.log('data', data)

  const handleSubmit = (values: any, form: any) => {
    addNotes({
      addNotes: transformToRequestValues(
        values,
        dealID,
        tabValue,
        leadID,
        twilioDropdown?.ReturnData.find((el) => el.BranchID === data.BranchID),
      ),
    })
    form.restart()
  }

  useEffect(() => {
    if (dealID) {
      getAccountNotesHistory({
        accountNotesHistory: {
          dealID: parseInt(dealID as string),
          isIncludeDeletedNotes: false,
          isImportantMessagesOnly: true,
          creditAppID: null,
          contactID: null,
        },
      })
    }
    searchTemplate({
      data: {
        dataSource: 'Deal',
        isIncludeInactive: false,
        searchString: null,
      },
    })
  }, [dealID])

  useEffect(() => {
    if (fetchTemplateSuccess) {
      formVar.change('Note', fetchTemplateData.SmsMessage)
    }
  }, [fetchTemplateData])

  useEffect(() => {
    if (fetchTemplateSuccess) {
      formVar.change('Note', fetchTemplateData.SmsMessage)
    }
  }, [fetchTemplateData])

  useEffect(() => {
    if (dealID) {
      getAccountNotesHistory({
        accountNotesHistory: {
          dealID: parseInt(dealID as string),
          isIncludeDeletedNotes: false,
          isImportantMessagesOnly: false,
          creditAppID: null,
          contactID: null,
        },
      })
    }
  }, [isSuccess])

  const TABS = [
    {
      value: 1,
      label: 'Note',
      icon: <TextSnippetIcon />,
    },
    {
      value: 2,
      label: 'Call',
      icon: <Iconify icon='fluent:call-24-filled' width={24} />,
    },
    {
      value: 3,
      label: 'Email',
      disabled: true,
      icon: <Iconify icon='ic:baseline-email' width={24} />,
    },
    {
      value: 4,
      label: 'Text',
      disabled: twilioDropdown && twilioDropdown.ReturnData.find((el) => el.BranchID === data.BranchID)?.IsActive ? false : true,
      icon: <Iconify icon='heroicons:chat-bubble-oval-left-ellipsis-solid' width={24} />,
    },
  ]

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validateFormValues(accountsAddNoteValidation(tabValue))}
      initialValues={{ CallCallType: 'Outbound', assignedToUserID: UserID, PhoneNumber: data?.CellPhoneData?.[0]?.PhoneNumber }}
    >
      {({ handleSubmit, values, form }: any) => {
        formVar = form
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid xs={12} md={8}>
                <Card sx={{ p: 3 }}>
                  <Box sx={{ mb: 3 }}>
                    <Box
                      sx={{
                        p: 1,
                        mb: 3,
                        borderRadius: 1,
                        // width: 'fit-content',
                        border: (theme) => `solid 1px ${alpha(theme.palette.grey[400], 0.2)}`,
                      }}
                    >
                      <Tabs
                        // sx={{
                        //   width: 'fit-content',
                        //   '& .MuiTabs-flexContainer': {
                        //     width: 'fit-content',
                        //   },
                        // }}
                        value={tabValue}
                        onChange={(e, newValue) => {
                          handleChangeTab(e, newValue)
                          form.restart()
                        }}
                        variant='fullWidth'
                      >
                        {TABS.map((tab) => (
                          <Tab key={tab.value} disabled={tab.disabled} label={tab.label} icon={tab.icon} value={tab.value} />
                        ))}
                      </Tabs>
                    </Box>
                  </Box>

                  <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'flex-end'} width={'100%'}>
                    {/* {tabValue == 1 && (
                    <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
                      <TextField
                        multiline
                        fullWidth
                        label='Note'
                        name='Note'
                        rows={4}
                        placeholder='Enter note here...'
                        sx={{
                          mb: 3,
                        }}
                      />
                      <CheckboxField name='isCreateFollowUp' label='Create Follow-Up' />
                      {values.isCreateFollowUp && (
                        <Grid container xs={12} columnSpacing={2}>
                          <Grid xs={4}>
                            {followUpTypeDropdown?.ReturnData && (
                              <DropdownFieldSearch
                                name='followUpTypeID'
                                label='Follow-up Type'
                                options={followUpTypeDropdown?.ReturnData}
                                optionKey='ID'
                                optionValue='FollowUpType'
                                fullWidth
                              />
                            )}
                          </Grid>
                          {assignedToDropdown && (
                            <Grid xs={4}>
                              <DropdownFieldSearch
                                name='assignedToUserID'
                                label='Assign To'
                                options={assignedToDropdown}
                                optionKey='UserID'
                                optionValue='Name'
                                fullWidth
                              />
                            </Grid>
                          )}

                          <Grid xs={4}>
                            <DateTimeField name='followUpDateTime' label='Follow-Up Date Time' disablePast />
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  )} */}

                    {/* {tabValue == 2 && ( */}
                    <Box display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
                      <Grid container spacing={2}>
                        {tabValue == 2 && (
                          <>
                            <Grid xs={3}>
                              <DropdownFieldSearch
                                name='CallCallType'
                                label='Call Type'
                                options={[
                                  { key: 'Outbound', value: 'Outbound' },
                                  { key: 'Inbound', value: 'Inbound' },
                                ]}
                                optionKey='key'
                                optionValue='value'
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={3}>
                              {values.CallCallType === 'Outbound' && callOutcomeDropdown && (
                                <DropdownFieldSearch
                                  name='CallOutcome'
                                  label='Outcome'
                                  options={callOutcomeDropdown}
                                  optionKey='ID'
                                  optionValue='Outcome'
                                  fullWidth
                                />
                              )}
                            </Grid>
                          </>
                        )}
                        {tabValue == 4 && data.CellPhoneData?.length > 0 && (
                          <>
                            <Grid xs={6}>
                              <DropdownFieldSearch
                                name='PhoneNumber'
                                label='To'
                                options={data.CellPhoneData.map((el: any) => {
                                  return {
                                    Name: `${el.Name} <${el.FormattedPhoneNumber}>`,
                                    PhoneNumber: el.PhoneNumber,
                                  }
                                })}
                                optionKey='PhoneNumber'
                                optionValue='Name'
                                fullWidth
                              />
                            </Grid>
                            <Grid xs={6}>
                              {values.PhoneNumber && (
                                <DropdownFieldSearch
                                  name='template'
                                  label='Template'
                                  options={templateData?.map((el: any) => {
                                    return {
                                      Name: `${el.TemplateName} <${el.TemplateCategory}>`,
                                      ID: el.ID,
                                    }
                                  })}
                                  onChange={(value) => {
                                    if (value)
                                      fetchTemplate({
                                        sms_template_id: value,
                                        data: {
                                          contactID: data.CellPhoneData.find((el: { PhoneNumber: any }) => el.PhoneNumber == values.PhoneNumber)
                                            .ContactID,
                                          dealID: parseInt(dealID as string),
                                          effectiveDate: convertDateToString(dayjs().toString()),
                                        },
                                      })
                                  }}
                                  optionKey='ID'
                                  optionValue='Name'
                                  fullWidth
                                />
                              )}
                            </Grid>
                          </>
                        )}
                        <Grid xs={12}>
                          <TextField
                            multiline
                            fullWidth
                            label={tabValue == 4 ? 'Message' : 'Note'}
                            name={tabValue == 2 ? 'CallNote' : 'Note'}
                            variant='outlined'
                            rows={4}
                            placeholder={tabValue == 4 ? 'Enter message here...' : 'Enter note here...'}
                            sx={{
                              borderRadius: 1,
                              // border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.2)}`,
                            }}
                          />
                        </Grid>
                        <Grid xs={12}>{data.IsAccountActive && <CheckboxField name='isCreatePTP' label='Create Promise-To-Pay' />}</Grid>
                        {values.isCreatePTP && (
                          <Grid container>
                            <Grid xs={6}>
                              <AmountField variant='standard' name='PTPAmount' label='PTP Amount' />
                            </Grid>

                            <Grid xs={6}>
                              <DateField name='PTPDueDate' label='PTP Due Date' disablePast />
                            </Grid>
                          </Grid>
                        )}

                        <Grid xs={12}>
                          <CheckboxField name='isCreateFollowUp' label='Create Follow-Up' />
                        </Grid>

                        {values.isCreateFollowUp && (
                          <Grid container xs={12} columnSpacing={2}>
                            <Grid xs={3}>
                              {followUpTypeDropdown?.ReturnData && (
                                <DropdownFieldSearch
                                  name='followUpTypeID'
                                  label='Follow-up Type'
                                  options={followUpTypeDropdown?.ReturnData}
                                  optionKey='ID'
                                  optionValue='FollowUpType'
                                  fullWidth
                                />
                              )}
                            </Grid>
                            {assignedToDropdown && (
                              <Grid xs={3}>
                                <DropdownFieldSearch
                                  name='assignedToUserID'
                                  label='Assign To'
                                  options={assignedToDropdown}
                                  optionKey='UserID'
                                  optionValue='Name'
                                  fullWidth
                                />
                              </Grid>
                            )}

                            <Grid xs={6}>
                              <DateTimeField name='followUpDateTime' label='Follow-Up Date Time' disablePast />
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                    {/* )} */}
                  </Box>
                  <Stack direction='row' alignItems='center' justifyContent={'flex-end'} sx={{ pt: 1 }}>
                    <LoadingButton type='submit' variant='contained' size='medium'>
                      Post
                    </LoadingButton>
                  </Stack>
                </Card>
              </Grid>
              {/* <Grid xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardHeader title='Standing' />
                <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Branch: Porsche of Lexington
                  </Typography>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Lead Source: CarGurus
                  </Typography>
                </Box>
              </Card>
            </Grid> */}
              <Grid xs={12} md={8}>
                <Card>
                  <CardHeader title='Notes' />
                  <Stack spacing={2.5} sx={{ p: 3 }}>
                    {accountNotesHistoryData?.map(
                      (note: {
                        FollowUpID: string | undefined
                        NoteID: string | number
                        NoteType: string | undefined
                        IsPTP: boolean | undefined
                        IsFollowUpCompleted: boolean | undefined
                        IsPinned: boolean | undefined
                        Subject: string | undefined
                        PhoneNumber: string | undefined
                        IsInbound: boolean | undefined
                        SystemDate: string | undefined
                        SentByEmail: string | undefined
                        Outcome: string | undefined
                        SentToEmails: string | undefined
                        IsFollowUp: boolean | undefined
                        PtpDueDate: string | null | undefined
                        PtpAmount: string | undefined
                        FollowUpAssignedTo: string | undefined
                        FollowUpDueDateTime: string | undefined
                        UserCode: string | undefined
                        Note: string | undefined
                      }) => (
                        <NotesItem
                          key={note.NoteID}
                          NoteID={note.NoteID}
                          NoteType={note.NoteType}
                          IsPTP={note.IsPTP}
                          IsFollowUpCompleted={note.IsFollowUpCompleted}
                          IsPinned={note.IsPinned}
                          Subject={note.Subject}
                          phoneNumber={note.PhoneNumber}
                          IsInbound={note.IsInbound}
                          date={note.SystemDate}
                          SentByEmail={note.SentByEmail}
                          Outcome={note.Outcome}
                          SentToEmails={note.SentToEmails}
                          IsFollowUp={note.IsFollowUp}
                          PtpDueDate={note.PtpDueDate}
                          FollowUpID={note.FollowUpID}
                          PtpAmount={note.PtpAmount}
                          FollowUpAssignedTo={note.FollowUpAssignedTo}
                          FollowUpDueDateTime={note.FollowUpDueDateTime}
                          user={note.UserCode}
                          note={note.Note}
                          name={note.NoteType}
                          setRefresh={setRefresh}
                          variant='outlined'
                          sx={{
                            p: 2.5,
                            borderRadius: 1,
                          }}
                        />
                      ),
                    )}
                  </Stack>
                </Card>
              </Grid>
              {/* <Grid xs={12} md={4}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  title='Settings'
                  action={
                    <IconButton>
                     <Iconify icon='solar:pen-bold' />
                    </IconButton>
                  }
                />
                <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Branch: Porsche of Lexington
                  </Typography>
                  <Typography sx={{ py: 1 }} variant='body2'>
                    Lead Source: CarGurus
                  </Typography>
                </Box>
              </Card>
            </Grid> */}
            </Grid>
          </form>
        )
      }}
    </Form>
  )
}
