import { converToUtc, convertDateToString } from '../../../../../utils/dateUtils'
import removeMask from '../../../../../utils/removeMask'

export const transformToFormValues = (): any => ({})

export const transformToRequestValues = (values?: any): any => {
  return {
    effectiveDate:  convertDateToString(values.effectiveDate),
    extensionDate:  convertDateToString(values.extensionDate),
    extensionNote:  values.extensionNote,
  }
}
