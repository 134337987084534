import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import Header from '../Header/Header'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import CommentsForm from './components/CommentsCard/CommentsCard'
import Button from '@mui/material/Button'
import { appRoute } from '../../../constants'
import { withAccounts } from '../../../hoc/withAccounts'
import { AccountsOverviewResult, UspDealFlagViewResult } from '../../../models'
import { Box, Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useNavigate, useParams } from 'react-router-dom'
import RepoForm from './components/RepoForm/RepoForm'
import VehicleForm from './components/VehicleForm/VehicleForm'
import RedemptionForm from './components/RedemptionForm/RedemptionForm'
import { useDispatch, useSelector } from 'react-redux'
import { useAddDealsFlagMutation, useEditAccountRepoMutation, useLazyGetAccountRepoQuery, useWorklistNextAccountMutation } from '../../../services'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { transformToFormValues, transformToRequestValues } from './transform'
import { CustomCircular } from '../../../ui'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { getWorklistAccountID, getWorklistID } from '../../../redux/slices/collectionsSlice'
import ReactRouterPrompt from 'react-router-prompt'
import { getUserAccessRights } from '../../../redux/slices/authSlice'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'saveBtn', 'chargeOffBtnWrapper'])

const StyledGrid = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },

  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 3, 3, 3),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.chargeOffBtnWrapper}`]: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const AccountsRepo = ({ setDirty }: { setDirty: any }) => {
  const { dealID } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const worklistAccountID = useSelector(getWorklistAccountID)
  const worklistID = useSelector(getWorklistID)
  const userAccess = useSelector(getUserAccessRights)
  const disableEditIcons = userAccess?.CanEditAccount
  const [initialValues, setInitialValues] = useState({})
  const [formChanged, setFormChanged] = useState(false)
  const [nextAccount, { data: nextAccountData, isSuccess: nextAccountIsSuccess }] = useWorklistNextAccountMutation()

  const [getRepoData, { data, isLoading: getRepoDataIsLoading, isSuccess: getRepoDataIsSuccess }] = useLazyGetAccountRepoQuery()
  const [editRepoData, { isSuccess: editRepoSuccess, isLoading: editRepoDataIsLoading }] = useEditAccountRepoMutation()
  const [isAddEditFlagModalOpen, setIsAddEditFlagModalOpen] = useState(false)
  const [addAccountFlags, { isSuccess: addAccountFlagIsSuccess, isLoading: addAccountFlagsIsLoading }] = useAddDealsFlagMutation()
  const [selectedFlag, setSelectedFlag] = useState<UspDealFlagViewResult | undefined>()

  const disableFields = !userAccess?.CanEditAccountTracking

  useEffect(() => {
    getRepoData({ dealId: dealID as string })
  }, [])

  useEffect(() => {
    setInitialValues(transformToFormValues(data))
  }, [data])

  useEffect(() => {
    if (getRepoDataIsSuccess) {
    }
  }, [getRepoDataIsSuccess, data, dispatch])

  useEffect(() => {
    if (editRepoSuccess) {
      setFormChanged(false)
      dispatch(enqueueNotification({ message: 'Success.' }))
      navigate(appRoute?.AccountsRepo.replace(':dealID', dealID as string))
      getRepoData({ dealId: dealID as string })
    }
  }, [editRepoSuccess])

  useEffect(() => {
    if (addAccountFlagIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setFormChanged(false)
      setIsAddEditFlagModalOpen(false)
    }
  }, [addAccountFlagIsSuccess])

  const handleNextAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: true,
        },
      })
    }
  }

  const handlePreviousAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: false,
        },
      })
    }
  }

  const handleSubmit = (values: any) => {
    if (dealID) {
      editRepoData({
        dealId: dealID as string,
        editRepo: transformToRequestValues(values),
      })
    }
  }

  const isLoading = useMemo(() => getRepoDataIsLoading || editRepoDataIsLoading, [getRepoDataIsLoading, editRepoDataIsLoading])

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={10} spacing={2}>
          <Form onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit, valid, dirty }: any) => {
              if (setDirty) setDirty(dirty)
              return (
                <>
                  <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
                    <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
                      {({ isActive, onConfirm, onCancel }) => (
                        <ConfirmDialog
                          open={!!isActive}
                          onOk={onConfirm}
                          handleClose={onCancel}
                          notification={'Warning: Your changes haven’t been saved.'}
                        />
                      )}
                    </ReactRouterPrompt>
                    <Card sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', height: '100%' }}>
                      <Grid item xs={12}>
                        <CardHeader title='Out For Repo' />
                        <RepoForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                      </Grid>
                      <Grid item xs={12}>
                        <CardHeader title='Vehicle Picked Up' />

                        <VehicleForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                      </Grid>
                      <Grid item xs={12}>
                        <CardHeader title='Redemption' />
                        <RedemptionForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                      </Grid>
                      <CommentsForm disableFields={disableFields} />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 2,
                          position: 'sticky',
                          bottom: 16,
                          right: 20,
                          p: 3,
                          width: 'fit-content',
                          alignSelf: 'flex-end',
                        }}
                      >
                        <Button type='submit' disabled={!(valid && dirty)} className={classes.saveBtn} variant='contained'>
                          Save
                        </Button>
                      </Box>
                    </Card>
                  </form>
                </>
              )
            }}
          </Form>
          <CustomCircular open={isLoading} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default withAccounts(AccountsRepo)
