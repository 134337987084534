import React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Field } from 'react-final-form'
import { generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import FormHelperText from '@mui/material/FormHelperText'

interface Props {
  name: string
  label?: string
  labelId?: string
  options: any[]
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  optionKey?: string
  optionValue?: string
  optionNone?: boolean
  disabled?: boolean
  onChange?: (value: any) => void
  initialValue?: any
  [x: string]: any
}

const classes = generateUtilityClasses('FormControl', ['formControl', 'title', 'selectedAll', 'indeterminateColor', 'selectAllText'])

const StyledFormControl = styled(FormControl)(() => ({
  [`&.${classes.formControl}`]: {},
  [`& .${classes.indeterminateColor}`]: {
    color: '#f50057',
  },
  [`& .${classes.selectAllText}`]: {
    fontWeight: 500,
  },
  [`& .${classes.selectAllText}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))

export const DropdownField = ({
  name,
  label,
  labelId,
  options = [],
  variant = 'standard',
  initialValue,
  required,
  onChange,
  optionKey = 'key',
  optionValue = 'value',
  optionNone,
  disabled,
  ...props
}: Props) => {
  return (
    <Field name={name} initialValue={initialValue}>
      {({ input, meta: { error, touched } }) => (
        <StyledFormControl fullWidth variant={variant} required={required} disabled={disabled} className={classes.formControl}>
          <InputLabel htmlFor={labelId} id={labelId} variant={variant} error={touched && error}>
            {label}
          </InputLabel>
          <Select
            {...props}
            {...input}
            labelId={labelId}
            variant={variant}
            onChange={(e) => {
              if (onChange) {
                const formValue = e?.target?.value
                onChange(formValue)
              }
              input.onChange(e)
            }}
            error={touched && error}
          >
            {optionNone && (
              <MenuItem key='none' value=''>
                None
              </MenuItem>
            )}
            {options.map((option: any) => (
              <MenuItem value={option[optionKey]} key={option[optionKey]}>
                {option[optionValue]}
              </MenuItem>
            ))}
          </Select>
          {touched && error && (
            <FormHelperText error={error} id='my-helper-text'>
              {error}
            </FormHelperText>
          )}
        </StyledFormControl>
      )}
    </Field>
  )
}

export default DropdownField
