import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { withPermissionsDropdown } from '../../../../hoc'
import { useSearchFormsPacksMutation } from '../../../../services'
import FormsPacksSearchBlock from './components/FormsPacksSearchBlock/FormsPacksSearchBlock'
import FormsPacksTableBlock from './components/FormsPacksTableBlock/FormsPacksTableBlock'
import { UspFormPackViewResult } from '../../../../models'
import AddEditFormsPacksModal from './components/AddEditFormPacksModal/AddEditFormPacksModal'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../../components/iconify'

export const SettingsFormsPacksList = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchFormsPacks, { data: searchFormsPacksData, isLoading: searchFormsPacksIsLoading }] = useSearchFormsPacksMutation()
  const [formPackData, setFormPackData] = useState<UspFormPackViewResult[]>([])

  const handleSubmit = (values: any) => {
    searchFormsPacks({
      uspFormPackView: {
        state: values?.State || null,
        searchString: values?.SearchField || null,
        isIncludeInactive: values?.IncludeInactive,
      },
    })
  }
  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (searchFormsPacksData) {
      setFormPackData(searchFormsPacksData as any)
    }
  }, [searchFormsPacksData])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Form Packs'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <>
            <Button onClick={handleOpenModal} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
              Add new
            </Button>
          </>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ State: null, SearchField: null, IncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <FormsPacksSearchBlock submit={submit} handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormsPacksTableBlock
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                      handleOpenModal={handleOpenModal}
                      loading={searchFormsPacksIsLoading}
                      data={formPackData}
                      setData={setFormPackData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditFormsPacksModal
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspFormPackViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default withPermissionsDropdown(SettingsFormsPacksList)
