import React, { useState } from 'react'
import { storageGet, storageSet } from '../../../../../utils/storage'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { UspReportSubsidiaryReconciliationResult } from '../../../../../models'
import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
}))

interface Props {
  reconData: UspReportSubsidiaryReconciliationResult[]
  isLoading: boolean
}

const AccountsSubsidiaryReconciliationTableBlock = ({ reconData, isLoading }: Props) => {
  const initialOrder = (storageGet('insights_accounts_subsidiary_reconciliation_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('insights_accounts_subsidiary_reconciliation_order_by') ||
    'Account') as keyof UspReportSubsidiaryReconciliationResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspReportSubsidiaryReconciliationResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportSubsidiaryReconciliationResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('insights_accounts_subsidiary_reconciliation_order', isAsc ? 'desc' : 'asc')
    storageSet('insights_accounts_subsidiary_reconciliation_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportSubsidiaryReconciliationResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Account' ? order : false}>
              <TableSortLabel active={orderBy === 'Account'} direction={orderBy === 'Account' ? order : 'asc'} onClick={createSortHandler('Account')}>
                Account
                {orderBy === 'Account' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountingBalance' ? order : false}>
              <TableSortLabel
                active={orderBy === 'AccountingBalance'}
                direction={orderBy === 'AccountingBalance' ? order : 'asc'}
                onClick={createSortHandler('AccountingBalance')}
              >
                Accounting Balance
                {orderBy === 'AccountingBalance' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SubsidiaryBalance' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SubsidiaryBalance'}
                direction={orderBy === 'SubsidiaryBalance' ? order : 'asc'}
                onClick={createSortHandler('SubsidiaryBalance')}
              >
                Subsidiary Balance
                {orderBy === 'SubsidiaryBalance' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Variance' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Variance'}
                direction={orderBy === 'Variance' ? order : 'asc'}
                onClick={createSortHandler('Variance')}
              >
                Variance
                {orderBy === 'Variance' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), reconData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell>{item.Account}</TableCell>
              <TableCell>{formatNumber(item.AccountingBalance)}</TableCell>
              <TableCell>{formatNumber(item.SubsidiaryBalance)}</TableCell>
              <TableCell>{formatNumber(item.Variance)}</TableCell>
            </TableRow>
          ))}
          {reconData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default AccountsSubsidiaryReconciliationTableBlock
