import * as yup from 'yup'

export const adminGlobalLabels = {
  NumberOfCharacters: 'Number of Characters to use from VIN',
}

export const adminGlobalSettingsValidation = yup
  .object({
    NumberOfCharacters: yup
      .number()
      .label(adminGlobalLabels.NumberOfCharacters)
      .required()
      .min(4, 'Value must be between 4 to 8')
      .max(8, 'Value must be between 4 to 8'),
  })
  .required()
