import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'

const classes = generateUtilityClasses('Grid', ['root', 'title', 'linkText'])

const StyledCard = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: '20px',
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.linkText}`]: {
    fontWeight: 600,
    fontSize: '1em',
    cursor: 'pointer',
    lineHeight: '1.1875em',
    color: theme.palette.primary.main,
    padding: '10px 0',
  },
}))

const TasksList = () => {
  const navigate = useNavigate()
  return (
    <>
      <StyledCard className={classes.root}>
        <Grid container>
          <Typography variant='h1' className={classes.title}>
            Task
          </Typography>
        </Grid>
        <Typography className={classes.linkText}>Bank Deposits</Typography>
        <Typography className={classes.linkText}>Billing</Typography>
        <Typography className={classes.linkText}>Bill Pay</Typography>
        <Typography className={classes.linkText}>Chart of Accounts</Typography>
        <Typography className={classes.linkText}>Checks</Typography>
        <Typography className={classes.linkText} onClick={() => navigate(appRoute.AccountingFinancials)}>
          Financials
        </Typography>
        <Typography className={classes.linkText}>Journal Entry</Typography>
        <Typography className={classes.linkText}>Reconciliation</Typography>
        <Typography className={classes.linkText}>Tools</Typography>
        <Typography className={classes.linkText} onClick={() => navigate(appRoute.AccountingList)}>
          View Ledger Details
        </Typography>
      </StyledCard>
    </>
  )
}

export default TasksList
