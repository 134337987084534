import React, { useEffect } from 'react'
import Header from './components/Header/Header'
import DealsReferenceTableBlock from './components/DealsReferenceTableBlock/DealsReferenceTableBlock'
import { useLazyContactsReferenceGetQuery, useLazyGetTaskDealQuery } from '../../../services'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, generateUtilityClasses, Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/system'
import { appRoute } from '../../../constants'

const classes = generateUtilityClasses('Grid', ['root', 'btnWrap', 'btnCancelWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.btnWrap}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 3, 0, 3),
  },
  [`& .${classes.btnCancelWrap}`]: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 3, 3, 3),
  },
}))

interface Props {}

const DealsReference = ({}: Props) => {
  const [getTaskDeal, { data: taskDeal }] = useLazyGetTaskDealQuery()
  const [contactsReference, { data: contactsReferenceData, isLoading: contactsReferenceLoading }] = useLazyContactsReferenceGetQuery()
  const navigate = useNavigate()
  const { dealID, contactID } = useParams()

  useEffect(() => {
    getTaskDeal({ dealId: dealID as string })
    contactsReference({ contactId: contactID?.toString() as string })
  }, [])

  return (
    <StyledGrid className={classes.root}>
      <Header taskDeal={taskDeal} />
      <Grid className={classes.btnWrap}>
        <Typography variant='h3'>References</Typography>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => {
            navigate(
              appRoute.DealsReferenceAddEdit.replace(':dealID', String(dealID))
                .replace(':contactID', String(contactID))
                .replace(':referenceID', 'add'),
            )
          }}
        >
          Add new
        </Button>
      </Grid>
      <DealsReferenceTableBlock
        contactsReference={contactsReference}
        loading={contactsReferenceLoading}
        contactsReferenceData={contactsReferenceData}
      />
      <Grid item xs={12} className={classes.btnCancelWrap}>
        <Button variant='contained' color='error' onClick={() => navigate(appRoute.DealsTask.replace(':dealID', dealID as string))}>
          Close
        </Button>
      </Grid>
    </StyledGrid>
  )
}

export default DealsReference
