export enum HttpMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Patch = 'PATCH',
  Delete = 'delete',
}

interface HttpProps {
  method: HttpMethod
  url: string
  data?: any
  headers?: Headers
  params?: any
}

export const http = async <T = any>({
  method,
  url,
  data,
  headers,
  params,
}: HttpProps): Promise<{ response: Response; result: T }> => {
  const reqHeader = new Headers(headers)
  let body = null
  if (data) {
    reqHeader.append('Accept', 'application/json')
    reqHeader.append('Content-Type', 'application/json')
    body = JSON.stringify(data)
  }
  const finalUrl = params ? `${url}?${new URLSearchParams(params)?.toString()}` : url

  const response: Response = await fetch(finalUrl, {
    method,
    body,
    headers: reqHeader,
  })

  let result

  try {
    result = await response.json()
  } catch (e) {
    console.log(e)
  }

  return { response, result }
}

interface IHttpPayload {
  headers?: Headers
  params?: Record<string, string | number>
  data?: unknown
  url: string
}

export const httpGet = <T>({ headers, url, params }: IHttpPayload) => {
  return http<T>({ method: HttpMethod.Get, url, headers, params })
}

export const httpPost = <T>({ url, data, headers }: IHttpPayload) => {
  return http<T>({ method: HttpMethod.Post, url, data, headers })
}

export const httpPut = <T>({ url, data, headers }: IHttpPayload) => {
  return http<T>({ method: HttpMethod.Put, url, data, headers })
}

export const httpPatch = <T>({ url, data, headers }: IHttpPayload) => {
  return http<T>({ method: HttpMethod.Patch, url, data, headers })
}

export const httpDelete = <T>({ url, data, headers }: IHttpPayload) => {
  return http<T>({ method: HttpMethod.Delete, url, headers })
}
