import React from 'react'
import { styled } from '@mui/system'
import { Grid, Tooltip, Typography } from '@mui/material'
import { ReactComponent as SixangleIcon } from '../../../../../assets/icons/sixangle.svg'
import { ReactComponent as AddIcon } from '../../../../../assets/icons/add.svg'
import { ReactComponent as RemoveIcon } from '../../../../../assets/icons/remove.svg'
import { ReactComponent as CommisionIcon } from '../../../../../assets/icons/commision.svg'

import CarRentalIcon from '@mui/icons-material/CarRental'
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import { generateUtilityClasses } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import GroupsIcon from '@mui/icons-material/Groups'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SpatialAudioOffIcon from '@mui/icons-material/SpatialAudioOff'
import { UspGetDataDealTaskResult } from '../../../../../models'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { useStatusColorStyles } from '../../../../../hooks/useStatusColorStyles'

const classes = generateUtilityClasses('Grid', [
  'root',
  'row',
  'title',
  'mainIcon',
  'addIcon',
  'userIcon',
  'linkText',
  'rowWrap',
  'statusHighlighted',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.mainIcon}`]: {
    padding: theme.spacing(0, 2, 0, 0),
    fill: theme.palette.text.primary,
  },
  [`& .${classes.addIcon}`]: {
    width: '45px',
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.userIcon}`]: {
    padding: theme.spacing(1.5, 1, 1.5, 1),
    fill: theme.palette.primary.main,
  },
  [`& .${classes.rowWrap}`]: {
    cursor: 'pointer',
  },
  [`& .${classes.linkText}`]: {
    width: '110px',
    fontWeight: 600,
    fontSize: '1em',
    cursor: 'pointer',
    lineHeight: '1.1875em',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.statusHighlighted}`]: {
    padding: theme.spacing(0.2, 1),
    width: '175px',
    height: '22px',
    textAlign: 'center',
    color: theme.palette.text.button,
    fontWeight: '600',
    fontSize: '0.85em',
    margin: theme.spacing(1, 0, 1, 1),
    borderRadius: '4px',
  },
}))

interface Props {
  taskDeal?: UspGetDataDealTaskResult
  setIsHasCoBuyer?: any
  isHasCobuyer?: boolean | null
}

export const TasksForm = ({ taskDeal, setIsHasCoBuyer, isHasCobuyer }: Props) => {
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const { dealID } = useParams()
  const StatusColorDiv = useStatusColorStyles(taskDeal)

  const deleteCoBuyer = () => {
    setIsHasCoBuyer(false)
    setDeleteConfirmDialog(false)
  }

  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.row}>
        <SixangleIcon className={classes.mainIcon} />
        <Typography variant='h1' className={classes.title}>
          Tasks
        </Typography>
      </Grid>
      <Grid className={classes.row} container>
        <Grid item className={classes.addIcon}>
          {taskDeal?.CoBuyerStatus === 'Not Started' && (
            <Tooltip title='Add Co-Buyer' arrow>
              <AddIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate(
                    appRoute.DealEditBuyer.replace(':dealID', dealID as string)
                      .replace(':contactID', taskDeal?.ContactID2?.toString() || 'add')
                      .replace(':contactType', 'co-buyer'),
                  )
                }}
              />
            </Tooltip>
          )}
        </Grid>
        <Grid
          item
          onClick={() => {
            if (taskDeal?.ContactID1 === null) {
              navigate(appRoute.DealAddBuyer.replace(':dealID', dealID as string))
            } else {
              navigate(
                appRoute.DealEditBuyer.replace(':dealID', dealID as string)
                  .replace(':contactID', taskDeal?.ContactID1?.toString() || 'add')
                  .replace(':contactType', 'buyer'),
              )
            }
          }}
          className={classes.rowWrap}
        >
          <Grid container>
            <Grid item>
              <PersonIcon fill='primary' className={classes.userIcon} />
            </Grid>
            <Grid item>
              <Typography className={classes.linkText}>Buyer</Typography>
            </Grid>
            <Grid item>
              <StatusColorDiv className={`${'buyer'} ${classes.statusHighlighted}`}>{taskDeal?.BuyerStatus}</StatusColorDiv>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {isHasCobuyer && taskDeal?.ContactID2 ? (
        <Grid className={classes.row} container>
          <Grid item className={classes.addIcon}>
            {taskDeal?.ContactID2 && (
              <Tooltip title='Remove Co-Buyer' arrow>
                <RemoveIcon style={{ cursor: 'pointer' }} onClick={() => setDeleteConfirmDialog(true)} />
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            // onClick={() =>
            //   navigate(
            //     appRoute.LeadsAddEdit.replace(':leadID', taskDeal?.CreditAppID?.toString() as string)
            //       .replace(':contactType', 'co-applicant')
            //       .replace(':contactID', taskDeal?.ContactID1?.toString() || 'add'),
            //   )
            // }
            className={classes.rowWrap}
          >
            <Grid container>
              <Grid item>
                <PeopleAltIcon color='primary' className={classes.userIcon} />
              </Grid>
              <Grid item>
                <Typography
                  className={classes.linkText}
                  onClick={() => {
                    navigate(
                      appRoute.DealEditCoBuyer.replace(':dealID', dealID as string)
                        .replace(':contactID', taskDeal?.ContactID2?.toString() || 'add')
                        .replace(':contactType', 'co-buyer'),
                    )
                  }}
                >
                  Co-Buyer
                </Typography>
              </Grid>
              <Grid item>
                <StatusColorDiv className={`${'coBuyer'} ${classes.statusHighlighted}`}>{taskDeal?.CoBuyerStatus}</StatusColorDiv>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
      {taskDeal?.IsHasTrade && (
        <Grid className={classes.row} container>
          <Grid item className={classes.addIcon} />
          <Grid item>
            <CarRentalIcon color='primary' className={classes.userIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.linkText} onClick={() => navigate(appRoute.DealsTrade.replace(':dealID', dealID as string))}>
              Trade
            </Typography>
          </Grid>
          <Grid item>
            <StatusColorDiv className={`${'trade'} ${classes.statusHighlighted}`}>{taskDeal?.TradeStatus}</StatusColorDiv>
          </Grid>
        </Grid>
      )}
      {taskDeal?.DealType.toLowerCase() === 'wholesale' ? null : (
        <Grid className={classes.row} container>
          <Grid item className={classes.addIcon} />
          <Grid item>
            <HealthAndSafetyIcon color='primary' className={classes.userIcon} />
          </Grid>
          <Grid item>
            <Typography
              className={classes.linkText}
              onClick={() => {
                if (taskDeal?.InsuranceContactID) {
                  navigate(
                    appRoute.DealsInsuranceEdit.replace(':dealID', dealID as string).replace(':insuranceID', taskDeal?.InsuranceID?.toString()),
                  )
                } else {
                  navigate(appRoute.DealsInsuranceAdd.replace(':dealID', dealID as string))
                }
              }}
            >
              Insurance
            </Typography>
          </Grid>
          <Grid item>
            <StatusColorDiv className={`${'insurance'} ${classes.statusHighlighted}`}>{taskDeal?.InsuranceStatus}</StatusColorDiv>
          </Grid>
        </Grid>
      )}
      {taskDeal?.IsHasProducts && (
        <Grid className={classes.row} container>
          <Grid item className={classes.addIcon} />
          <Grid item>
            <ShoppingCartIcon color='primary' className={classes.userIcon} />
          </Grid>
          <Grid item>
            <Typography className={classes.linkText} onClick={() => navigate(appRoute.DealsProducts.replace(':dealID', dealID as string))}>
              F&I Products
            </Typography>
          </Grid>
          <Grid item>
            <StatusColorDiv className={`${'product'} ${classes.statusHighlighted}`}>{taskDeal?.ProductStatus}</StatusColorDiv>
          </Grid>
        </Grid>
      )}
      {taskDeal?.DealType.toLowerCase() === 'wholesale' ? null : (
        <Grid className={classes.row} container>
          <Grid item className={classes.addIcon} />
          <Grid item>
            <GroupsIcon color='primary' className={classes.userIcon} />
          </Grid>
          <Grid item>
            <Typography
              onClick={() =>
                navigate(
                  appRoute.DealsReference.replace(':dealID', dealID as string).replace(':contactID', taskDeal?.ContactID1?.toString() as string),
                )
              }
              className={classes.linkText}
            >
              References
            </Typography>
          </Grid>
          <Grid item>
            <StatusColorDiv className={`${'referenceDeals'} ${classes.statusHighlighted}`}>{taskDeal?.ReferenceStatus}</StatusColorDiv>
          </Grid>
        </Grid>
      )}
      <Grid className={classes.row} container>
        <Grid item className={classes.addIcon} />
        <Grid item>
          <CommisionIcon className={classes.userIcon} />
        </Grid>
        <Grid item>
          <Typography onClick={() => navigate(appRoute.DealsCommissions.replace(':dealID', dealID as string))} className={classes.linkText}>
            Commisions
          </Typography>
        </Grid>
        <Grid item>
          <StatusColorDiv className={`${'commision'} ${classes.statusHighlighted}`}>{taskDeal?.CommissionStatus}</StatusColorDiv>
        </Grid>
      </Grid>
      <Grid className={classes.row} container>
        <Grid item className={classes.addIcon} />
        <Grid item>
          <SpatialAudioOffIcon className={classes.userIcon} />
        </Grid>
        <Grid item>
          <Typography onClick={() => navigate(appRoute.DealsReferral.replace(':dealID', dealID as string))} className={classes.linkText}>
            Referral
          </Typography>
        </Grid>
        <Grid item>
          <StatusColorDiv className={`${'referral'} ${classes.statusHighlighted}`}>{taskDeal?.ReferralStatus}</StatusColorDiv>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={deleteCoBuyer}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Are you sure you want to remove Co-buyer?'}
      />
    </StyledGrid>
  )
}

export default TasksForm
