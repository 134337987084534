import React, { useEffect } from 'react'
import { useLazyGetCreditAppQuery } from '../../services'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getCreditApp as getCreditAppSelector, setCreditApp } from '../../redux/slices/creditAppSlice'

export function withCreditApps(Component: any) {
  // GET CAR DATA AND ADD TO REDUX
  return function (props: any) {
    const { leadID, dealID } = useParams()
    const dispatch = useDispatch()
    const creditApp = useSelector(getCreditAppSelector(leadID || dealID))
    const [getCreditApp, { data: creditAppData, isSuccess: creditAppIsSuccess, isLoading: creditAppIsLoading }] = useLazyGetCreditAppQuery()

    useEffect(() => {
      if (!creditApp) {
        getCreditApp({ creditAppId: (leadID as string) || (dealID as string) })
      }
    }, [])

    useEffect(() => {
      if (creditAppIsSuccess) {
        dispatch(setCreditApp(creditAppData))
      }
    }, [creditAppIsSuccess])

    return (
      <Component
        getCreditApp={getCreditApp}
        creditAppData={creditAppData || creditApp}
        carIsSuccess={creditAppIsSuccess}
        carIsLoading={creditAppIsLoading}
        {...props}
      />
    )
  }
}
