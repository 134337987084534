import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { styled } from '@mui/system'
import { UspLisDealStatusResult } from '../../../../../models'
import { selectDropdownByNameActiveOnly, useGetListDealStatusQuery } from '../../../../../services'
import { useSelector } from 'react-redux'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  disableFields: boolean
}

const DealSettingsCard = ({ disableFields }: Props) => {
  const { data: listDealStatus } = useGetListDealStatusQuery({})
  const advertisement = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Advertisement'))

  return (
    <StyledGrid className={classes.root}>
      <Grid className={classes.row}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='Status'
          label='Status'
          options={listDealStatus as UspLisDealStatusResult[]}
          optionKey='ID'
          optionValue='Status'
          autoComplete='off'
          fullWidth
        />
      </Grid>
      {advertisement && (
        <Grid className={classes.row}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='Advertisement'
            label='Lead Source'
            options={advertisement}
            optionKey={'ID'}
            optionValue={'Advertisement'}
            autoComplete='off'
            fullWidth
          />
        </Grid>
      )}

      <Grid className={classes.row}>
        <TextField disabled={disableFields} multiline name='Mileage' label='Mileage (when sold)' fullWidth autoComplete='off' />
      </Grid>
    </StyledGrid>
  )
}

export default DealSettingsCard
