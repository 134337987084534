import React, { useCallback, useEffect, useState } from 'react'
import { useSearchCreditAppMutation } from '../../../services'
import { convertDateToString } from '../../../utils/dateUtils'
import { getCurrentDate, getEarlierDate } from '../../../utils/general'
import { Button, Card, Container, IconButton, Tab, Table, TableBody, TableContainer, Tabs, Tooltip, alpha } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs'
import Iconify from '../../../components/iconify'
import validateFormValues from '../../../utils/validateForm'
import { leadsSearchValidation } from '../../../validation/leadsSearchValidation'
import { LeadsTableBlock } from './components/LeadsTableBlock'
import { LeadsSearchBlock } from './components/LeadsSearchBlock'
import { Form } from 'react-final-form'
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  useTable,
} from '../../../components/table'
import { getComparator } from '../../../utils/sorting'
import Label from '../../../components/label'
import LeadsTableToolbar from './components/leadsTableToolbar'
import LeadsTableFiltersResult from './components/leadsTableFilterResults'
import Scrollbar from '../../../components/scrollbar'
import LeadsTableRow from './components/leadsTableRow'
import { useBoolean } from '../../../hooks/use-boolean'
import { isEqual } from 'lodash'
import { RouterLink } from '../../../routes/components'
import { appRoute } from '../../../constants'
import { isAfter } from '../../../utils/format-time'
import { ref } from 'yup'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'

const defaultFilters: any = {
  name: '',
  branch: [],
  assigned: '',
  status: 'all',
}

export const LeadsList = () => {
  const table = useTable()
  const denseHeight = table.dense ? 56 : 56 + 20
  const [searchCreditApp, { data: searchCreditAppData, isLoading: searchCreditAppIsLoading }] = useSearchCreditAppMutation()
  const [tableData, setTableData] = useState<any>([])
  const [filters, setFilters] = useState(defaultFilters)
  const [refresh, setRefresh] = useState<boolean>(false)
  const canReset = !isEqual(defaultFilters, filters)
  const dateError = isAfter(filters.startDate, filters.endDate)
  const dispatch = useDispatch()

  function applyFilter({
    inputData,
    comparator,
    filters,
    dateError,
  }: {
    inputData: any[]
    comparator: (a: any, b: any) => number
    filters: any
    dateError: boolean
  }) {
    const { name, status } = filters

    const stabilizedThis = inputData?.map((el, index) => [el, index] as const)

    stabilizedThis?.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })

    inputData = stabilizedThis?.map((el) => el[0])

    if (status !== 'all') {
      inputData = inputData?.filter((user) => user[status] === true)
    }

    return inputData
  }

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  })

  const notFound = (!dataFiltered?.length && canReset) || !dataFiltered?.length

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters)
  }, [])
  useEffect(() => {
    setTableData(
      searchCreditAppData?.map((el: any) => {
        return {
          id: el.CreditAppID,
          name: el.Applicant,
          status: el.Status,
          email: el?.BuyerEmail,
          phoneNumber: el?.PhoneNumber,
          interest: el.InterestLevel,
          salesperson: el.Salesperson,
          added: el.DateAdded,
          IsSold: el.IsSold,
          IsLost: el.IsLost,
          updated: el.DateUpdated,
          branch: el.Branch,
        }
      }),
    )
  }, [searchCreditAppData])
  useEffect(() => {
    handleSubmit(filters)
  }, [filters, refresh])

  const STATUS_OPTIONS = [
    { value: 'all', label: 'All' },
    { value: 'IsSold', label: 'Sold' },
    { value: 'IsLost', label: 'Lost' },
  ]

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', width: 300 },
    { id: 'status', label: 'Status', width: 120 },
    { id: 'interest', label: 'Interest Level', width: 180 },
    { id: 'salesperson', label: 'Salesperson', width: 220 },
    { id: 'added', label: 'Added', width: 180 },
    { id: 'updated', label: 'Updated', width: 180 },
    { id: 'branch', label: 'Branch', width: 100 },
    { id: '', width: 88 },
  ]

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item }))
    let startDate = convertDateToString(values?.startDate)
    let endDate = convertDateToString(values?.endDate)

    if (values?.date === 'today') {
      startDate = getCurrentDate() || null
      endDate = getCurrentDate() || null
    } else if (values?.date === 'yesterday') {
      startDate = getEarlierDate(1) || null
      endDate = getEarlierDate(1) || null
    } else if (values?.date === 'lastSevenDays') {
      startDate = getEarlierDate(7) || null
      endDate = getCurrentDate() || null
    } else if (values?.date === 'lastThirtyDays') {
      startDate = getEarlierDate(30) || null
      endDate = getCurrentDate() || null
    }
    if (startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
      searchCreditApp({
        uspCreditAppSearch: {
          searchString: values?.name || null,
          startDate: startDate || null,
          endDate: endDate || null,
          jsonBranchList: jsonBranchList || null,
          jsonCompanyList: null,
          jsonStatusList: null,
          assignedTo: values?.assigned || 'All',
        },
      })
    }
  }
  const handleFilters = useCallback(
    (name: string, value: any) => {
      table.onResetPage()
      setFilters((prevState: any) => ({
        ...prevState,
        [name]: value,
      }))
    },
    [table],
  )
  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue)
    },
    [handleFilters],
  )

  return (
    <Container>
      <Form
        onSubmit={handleSubmit}
        validate={validateFormValues(leadsSearchValidation)}
        initialValues={{ searchString: null, dateRange: '', assigned: 'All' }} // require to avoid first debounce search
      >
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit}>
            {/* <LeadsSearchBlock submit={submit} /> */}
            {/* <LeadsTableBlock loading={searchCreditAppIsLoading} searchCreditAppData={searchCreditAppData} /> */}
            <CustomBreadcrumbs
              heading='Leads'
              links={[{ name: 'List', href: './' }]}
              action={
                <Button
                  component={RouterLink}
                  href={appRoute.LeadsAddEdit.replace(':leadID', 'add').replace(':contactID', 'add').replace(':contactType', 'applicant')}
                  variant='contained'
                  startIcon={<Iconify icon='mingcute:add-line' />}
                >
                  New Lead
                </Button>
              }
              sx={{
                mb: { xs: 3, md: 5 },
              }}
            />
            <Card>
              <Tabs
                value={filters?.status}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab
                    key={tab.value}
                    iconPosition='end'
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label
                        variant={((tab.value === 'all' || tab.value === filters?.status) && 'filled') || 'soft'}
                        color={(tab.value === 'IsSold' && 'success') || (tab.value === 'IsLost' && 'error') || 'default'}
                      >
                        {['IsSold', 'IsLost'].includes(tab.value)
                          ? tableData?.filter((el: any) => el?.[tab.value] === true)?.length
                          : tableData?.length}
                      </Label>
                    }
                  />
                ))}
              </Tabs>

              <LeadsTableToolbar filters={filters} onFilters={handleFilters} dateError={dateError} />

              {canReset && (
                <LeadsTableFiltersResult
                  filters={filters}
                  onFilters={handleFilters}
                  //
                  onResetFilters={handleResetFilters}
                  //
                  results={dataFiltered?.length}
                  sx={{ p: 2.5, pt: 0 }}
                />
              )}

              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  dense={table.dense}
                  numSelected={table.selected.length}
                  rowCount={dataFiltered?.length}
                  onSelectAllRows={(checked: boolean) =>
                    table.onSelectAllRows(
                      checked,
                      dataFiltered.map((row) => row.id),
                    )
                  }
                  action={
                    <Tooltip title='Delete'>
                      <IconButton color='primary'>
                        <Iconify icon='solar:trash-bin-trash-bold' />
                      </IconButton>
                    </Tooltip>
                  }
                />

                <Scrollbar>
                  <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                    <TableHeadCustom
                      order={table.order}
                      orderBy={table.orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={dataFiltered?.length}
                      numSelected={table.selected.length}
                      onSort={table.onSort}
                    />

                    <TableBody>
                      {dataFiltered?.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage).map((row) => (
                        <LeadsTableRow
                          key={row.id}
                          row={row}
                          selected={table.selected.includes(row.id)}
                          onSelectRow={() => table.onSelectRow(row.id)}
                          onDeleteRow={() => {
                            setRefresh(!refresh)
                            dispatch(enqueueNotification({ message: 'Lead removed' }))
                          }}
                          // onEditRow={() => handleEditRow(row.id)}
                        />
                      ))}

                      <TableEmptyRows height={denseHeight} emptyRows={emptyRows(table.page, table.rowsPerPage, dataFiltered?.length)} />

                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={dataFiltered?.length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
                //
                dense={table.dense}
                onChangeDense={table.onChangeDense}
              />
            </Card>
          </form>
        )}
      </Form>
    </Container>

    // <Container>
    //   <CustomBreadcrumbs
    //     heading='Leads'
    //     links={[{ name: 'List', href: './' }]}
    //     sx={{
    //       mb: { xs: 3, md: 5 },
    //     }}
    //   />

    //   <Tabs
    //     value={currentTab}
    //     onChange={handleChangeTab}
    //     sx={{
    //       mb: { xs: 3, md: 5 },
    //     }}
    //   >
    //     {TABS.map((tab) => (
    //       <Tab key={tab.value} label={tab.label} icon={tab.icon} value={tab.value} />
    //     ))}
    //   </Tabs>

    //   {currentTab === 'general' && <LeadGeneral />}

    //   {currentTab === 'notes' && <LeadtNotes />}
    // </Container>
  )
}

export default LeadsList
