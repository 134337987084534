import { useEffect } from 'react'
import { withAccounts } from '../../../hoc/withAccounts'
import { useGetDealAmortizationMutation } from '../../../services'
import TabAmortization from '../AccountsDeals/tabs/TabAmortization/TabAmortization'
import { useParams } from 'react-router-dom'

const AccountsAmorization = () => {
  const { dealID } = useParams()

  const [getDealAmortization, { data: dealAmortizationData, isLoading: getDealAmortizationIsLoading }] = useGetDealAmortizationMutation()

  useEffect(() => {
    getDealAmortization({
      dealId: dealID as string,
      uspDealAmortization: {
        isInsertIntoTable: false,
        isIncludePickups: false,
        isDueDatesOnly: false,
        closedByPaymentID: null,
        isExtensionReversal: false,
      },
    })
  }, [])

  return (
    <>
      <TabAmortization dealAmortizationData={dealAmortizationData as any} loading={getDealAmortizationIsLoading} />
    </>
  )
}

export default withAccounts(AccountsAmorization)
