import { generateUtilityClasses, Grid } from '@mui/material'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../../redux/slices/userDropdownSlice'
import { UspFormPackViewResult } from '../../../../../../models'

const classes = generateUtilityClasses('div', ['root', 'stateFieldWrap', 'formPackFieldWrap'])

const StyledSearchWrap = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.stateFieldWrap}`]: {
    width: theme.spacing(20),
  },
  [`& .${classes.formPackFieldWrap}`]: {
    width: theme.spacing(30),
  },
}))

interface Props {
  searchFormsPacksData: UspFormPackViewResult
  searchFormList: (e: any) => void
  setIsFormPackSelected: Dispatch<SetStateAction<boolean>>
}

const SearchForms = ({ searchFormsPacksData, setIsFormPackSelected, searchFormList }: Props) => {
  const states = useSelector(getStates)
  return (
    <>
      <Grid container spacing={2} p={3}>
        <Grid item xs={3}>
          <DropdownFieldSearch name='State' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' fullWidth />
        </Grid>
        <Grid item xs={3}>
          <DropdownFieldSearch
            name='FormPack'
            label='Form Pack'
            options={searchFormsPacksData as any}
            optionKey='FormPackID'
            optionValue='Name'
            onChange={(v) => {
              if (v) {
                searchFormList({ formPackId: v })
                setIsFormPackSelected(true)
              } else {
                setIsFormPackSelected(false)
              }
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  )
}

export default SearchForms
