import React, { useCallback, useEffect, useMemo } from 'react'
import { Box, styled } from '@mui/system'
import { Grid, IconButton, Popover, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import { AmountField, PercentField, TextField } from '../../../../../../../ui'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../../../services'
import { debounce } from 'lodash'
import EditIcon from '@mui/icons-material/Edit'
import Iconify from '../../../../../../../components/iconify'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  disableSalesTaxFields?: boolean
  disableFields: boolean
}

export const SalesTaxForm = ({ disableSalesTaxFields, disableFields }: Props) => {
  const { values } = useFormState()
  const { change } = useForm()
  const [getInfoByZipCode, { data: infoByZipCodeData, isSuccess: infoByZipCodeIsSuccess }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (infoByZipCodeIsSuccess) {
      change('TaxState', infoByZipCodeData?.State)
      change('TaxCity', infoByZipCodeData?.City)
      change('TaxCounty', infoByZipCodeData?.County)
      change('StateTaxRate', infoByZipCodeData?.StateTaxRate?.toString())
      change('MiscTaxRate', infoByZipCodeData?.MiscTaxRate?.toString())
      change('CityTaxRate', infoByZipCodeData?.CityTaxRate?.toString())
      change('CountyTaxRate', infoByZipCodeData?.CountyTaxRate?.toString())
      change('TaxCap', infoByZipCodeData?.TaxCap?.toString())
    }
  }, [infoByZipCodeIsSuccess, infoByZipCodeData])

  const updateWithDebounce = useCallback(
    debounce((zipCode: string) => {
      if (zipCode?.length > 4) {
        getInfoByZipCode({ zipCode: zipCode?.toString() })
      }
    }, 500),
    [],
  )

  // useEffect(() => {
  //   if (values?.DealPostalCode) {
  //     updateWithDebounce(values?.DealPostalCode)
  //   }
  // }, [values?.DealPostalCode])

  const [anchorElTax, setAnchorElTax] = React.useState<HTMLButtonElement | null>(null)

  const handleClickTax = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElTax(event?.currentTarget)
  }

  const open = Boolean(anchorElTax)

  useEffect(() => {
    // console.log(parseFloat(values?.StateTaxRate), parseFloat(values?.CityTaxRate), parseFloat(values?.CountyTaxRate), parseFloat(values?.MiscTaxRate))
    if (typeof values?.StateTaxRate === 'string') {
      change(
        'total_tax',
        parseFloat(values?.StateTaxRate) + parseFloat(values?.CityTaxRate) + parseFloat(values?.CountyTaxRate) + parseFloat(values?.MiscTaxRate),
      )
    }
  }, [values, change])

  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.row}>
        <Typography variant='subtitle1' gutterBottom>
          Sales Tax
        </Typography>
      </Grid>
      <Grid className={classes.row}>
        <TextField
          onChange={(e) => {
            updateWithDebounce(e.target.value)
          }}
          disabled={disableSalesTaxFields || disableFields}
          name='DealPostalCode'
          label='Tax Zip Code'
          fullWidth
          autoComplete='off'
        />
      </Grid>
      <Grid className={classes.row}>
        <TextField disabled={disableSalesTaxFields || disableFields} name='TaxCity' label='Tax City' fullWidth autoComplete='off' />
      </Grid>
      <Grid className={classes.row}>
        <TextField disabled={disableSalesTaxFields || disableFields} name='TaxCounty' label='Tax County' fullWidth autoComplete='off' />
      </Grid>
      <Grid className={classes.row}>
        <TextField
          readonly
          variant='outlined'
          name='total_tax'
          label='Total Tax Rate (%)'
          fullWidth
          InputProps={{
            endAdornment: (
              <IconButton disabled={disableFields} color='primary' onClick={handleClickTax}>
                <Iconify icon='solar:pen-bold' />
              </IconButton>
            ),
          }}
        />
      </Grid>

      <Popover
        open={open}
        anchorEl={anchorElTax}
        onClose={() => setAnchorElTax(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grid container spacing={3} p={3} width={200}>
          <Grid item xs={12}>
            <PercentField variant='standard' disabled={disableFields} name='StateTaxRate' label='State Tax Rate' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <PercentField variant='standard' disabled={disableFields} name='CityTaxRate' label='City Tax Rate' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <PercentField variant='standard' disabled={disableFields} name='CountyTaxRate' label='County Tax Rate' fullWidth />
          </Grid>
          <Grid item xs={12}>
            <PercentField variant='standard' disabled={disableFields} name='MiscTaxRate' label='Misc Tax Rate' fullWidth />
          </Grid>
        </Grid>
      </Popover>
    </StyledGrid>
  )
}

export default SalesTaxForm
