import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField } from '../../../../../ui'
import TextField from '../../../../../ui/TextField'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

const SentForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const user = useSelector((state) => selectDropdownByNameActiveOnly(state, 'UserAll'))

  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='IsTitleSent'
          label='Is Title Sent?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          optionKey='key'
          optionValue='value'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='DateSent' label='Date Sent' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled={disableFields} name='TitleNumber' label='Title Number' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='SendTo'
          label='Sent To'
          options={[
            { id: 1, value: 'Customer' },
            { id: 2, value: 'Dealer' },
            { id: 3, value: 'Lender' },
            { id: 4, value: 'Other' },
          ]}
          optionKey='id'
          optionValue='value'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      {user && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            onChange={() => {
              onDropdownFormChange()
            }}
            name='SentBy'
            label='Sent By'
            options={user}
            optionKey='UserID'
            optionValue='UserCode'
          />
        </Grid>
      )}
    </Grid>
  )
}

export default SentForm
