import Box from '@mui/material/Box'

import { useMessagesScroll } from './hooks'
import ChatMessageItem from './chat-message-item'
import { IChatMessage, IChatParticipant } from './chat'
import Scrollbar from '../scrollbar'

// ----------------------------------------------------------------------

type Props = {
  messages: any[]
  participants: IChatParticipant[]
}

export default function ChatMessageList({ messages = [], participants }: Props) {
  const { messagesEndRef } = useMessagesScroll(messages)

  const slides = messages.filter((message) => message.contentType === 'image').map((message) => ({ src: message.body }))

  // const lightbox = useLightBox(slides)

  return (
    <>
      <Scrollbar ref={messagesEndRef} sx={{ px: 3, py: 2, height: 1, overscrollBehavior: 'contain' }}>
        <Box>
          <ChatMessageItem
            message={{ start: 'Hi, I’m Ella, your personal database assistant. Let me know how I can help you today!' }}
            participants={participants}
            // onOpenLightbox={() => lightbox.onOpen(message.body)}
          />
          {messages.map((message) => (
            <ChatMessageItem
              message={message}
              participants={participants}
              // onOpenLightbox={() => lightbox.onOpen(message.body)}
            />
          ))}
        </Box>
      </Scrollbar>

      {/* <Lightbox index={lightbox.selected} slides={slides} open={lightbox.open} close={lightbox.onClose} /> */}
    </>
  )
}
