import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useSearchDealerReserveMutation } from '../../../services'
import { UspConfigurationDealerReserveViewResult } from '../../../models'
import EditDealerReserve from './components/EditDealerReserveModal'
import DealerReserveTable from './components/DealerReserveTable'
import Header from './components/Header'
import { Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const SettingsDealDealerReserve = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchDealerReserve, { data, isLoading }] = useSearchDealerReserveMutation()
  const [dealerReserveData, setDealerReserveData] = useState<UspConfigurationDealerReserveViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchDealerReserve({
      simpleSearch: {
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setDealerReserveData(data)
    }
  }, [data])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='Dealer Reserves'
        links={[
          {
            name: 'List',
          },
        ]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='' />
                  </Grid>
                  <Grid item xs={12}>
                    <DealerReserveTable
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                      handleOpenModal={handleOpenModal}
                      loading={isLoading}
                      data={dealerReserveData}
                      setData={setDealerReserveData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <EditDealerReserve
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspConfigurationDealerReserveViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsDealDealerReserve
