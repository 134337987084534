import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import { Form, FormSpy } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { AmountField, DateField, TextField } from '../../../../../ui'
import { transformToFormValues, transformToRequestValues } from './transform'
import { useParams } from 'react-router-dom'
import validateFormValues from '../../../../../utils/validateForm'
import { accountsRepoChargeOffValidation } from '../../../../../validation/accountsRepoChargeOffValidation'
import { getCurrentDate } from '../../../../../utils/general'
import { setRepoChargeOff } from '../../../../../redux/slices/accountsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'
const classesBox = generateUtilityClasses('Container', ['root', 'title', 'row', 'btnWrap'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '300px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.title}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.row}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.btnWrap}`]: {
    justifyContent: 'space-between',
  },
}))

interface Props {
  openModal: boolean | undefined
  editChargeOff: (e: any) => void
  onSuccessEditChargeOff: () => void
  editChargeOffSuccess: boolean
  deficiency: boolean
}

type DropdownResponse = {
  ListName: string
  ReturnData: string
}

const ChargeOffModal = ({ openModal, editChargeOff, onSuccessEditChargeOff, editChargeOffSuccess, deficiency }: Props) => {
  const open = Boolean(openModal)
  const { dealID } = useParams()
  const dispatch = useDispatch()
  const creditCode = useSelector((state) => selectDropdownByName(state, 'CreditCode'))
  const creditIndicator = useSelector((state) => selectDropdownByName(state, 'CreditIndicator'))
  const creditSpecialComment = useSelector((state) => selectDropdownByName(state, 'CreditSpecialComment'))
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  const [processType, setProcessType] = useState(null)
  const [formValues, setFormValues] = useState<Record<string, any>>({
    EffectiveDate: getCurrentDate(),
  })
  const [creditCodes, setCreditCodes] = useState<any[]>([])

  const handleClose = () => {
    dispatch(setRepoChargeOff(undefined))
  }
  const handleSubmit = (values: any) => {
    setFormValues(values)
    transformToFormValues()
    editChargeOff({
      dealId: dealID as string,
      accountsChargeOff: transformToRequestValues(values as any, dealID, deficiency),
    })
  }

  useEffect(() => {
    if (editChargeOffSuccess) {
      onSuccessEditChargeOff()
      setFormValues({
        EffectiveDate: getCurrentDate(),
      })
      handleClose()
    }
  }, [editChargeOffSuccess])

  useEffect(() => {
    if (creditCode?.ReturnData) {
      const filteredCreditCodes = creditCode?.ReturnData?.filter((code) => code?.IsAllowSelect)
      setCreditCodes(filteredCreditCodes)
    }
  }, [creditCode?.ReturnData])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Process Charge Off</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={formValues} validate={validateFormValues(accountsRepoChargeOffValidation)}>
        {({ handleSubmit, valid }: any) => (
          <form onSubmit={handleSubmit}>
            <FormSpy
              onChange={(state) => {
                setFormValues(state?.values)
              }}
            />
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='ProcessType'
                    label='Type'
                    options={[
                      { id: 1, values: 'Repossession' },
                      { id: 2, values: 'Charge Off' },
                    ]}
                    optionKey='id'
                    optionValue='values'
                    onChange={(value) => setProcessType(value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <DateField name='EffectiveDate' label='Effective Date' fullWidth />
                </Grid>
                {processType !== (2 as number) && (
                  <>
                    <Grid item xs={12}>
                      <AmountField variant='standard' name='RepoACV' label='Repo ACV' fullWidth autoComplete='off' />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name='NewStock' label='New Stock # (Optional)' fullWidth autoComplete='off' />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name='CurrentMileage' label='Current Mileage' fullWidth autoComplete='off' />
                    </Grid>
                    {branch && (
                      <Grid item xs={12}>
                        <DropdownFieldSearch
                          name='GoingToBranch'
                          label='Going To Branch'
                          options={branch}
                          optionKey='BranchID'
                          optionValue='Branch'
                        />
                      </Grid>
                    )}
                  </>
                )}

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='CreditCode'
                    label='Credit Code'
                    options={creditCodes}
                    optionKey='CreditCode'
                    optionValue='Description'
                    customDropdownWidth={600}
                  />
                </Grid>
                {creditIndicator && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='Indicator'
                      label='Indicator (Optional)'
                      options={creditIndicator?.ReturnData}
                      optionKey='Indicator'
                      optionValue='Description'
                      customDropdownWidth={600}
                    />
                  </Grid>
                )}

                {creditSpecialComment && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='SpecialComment'
                      label='Special Comment (Optional)'
                      options={creditSpecialComment?.ReturnData}
                      optionKey='SpecialComment'
                      optionValue='Description'
                      customDropdownWidth={600}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button variant='contained' type='submit' disabled={!valid}>
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default ChargeOffModal
