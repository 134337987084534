import { Form } from 'react-final-form'
import React, { useEffect, useState } from 'react'
import Header from './components/Header/Header'
import { useLazySearchFormListQuery } from '../../../../../services'
import FormListTableBlock from './components/FormListTableBlock/FormListTableBlock'
import { UspFormPackDetailViewResult } from '../../../../../models'
import AddEditFormPackModal from './components/AddEditFormPackModal/AddEditFormPackModal'
import { useSelector } from 'react-redux'
import { getFormPacks } from '../../../../../redux/slices/formPacksSlice'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, useTheme } from '@mui/material'
import { appRoute } from '../../../../../constants'


const FormList = () => {
  const theme = useTheme()
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchFormList, { data, isLoading }] = useLazySearchFormListQuery()
  const formPackDetail = useSelector(getFormPacks)
  const navigate = useNavigate()

  useEffect(() => {
    if (formPackDetail) {
      searchFormList({ formPackId: formPackDetail?.FormPackID })
    } else {
      navigate(-1)
    }
  }, [formPackDetail])

  const handleSubmit = (values?: any) => {
    if (formPackDetail) {
      searchFormList({ formPackId: formPackDetail?.FormPackID })
    }
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  return (
    <Form onSubmit={handleSubmit}>
      {({ handleSubmit, form: { submit } }: any) => (
        <Grid container sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}>
          <Grid item>
            <form onSubmit={handleSubmit}>
              <Header title={formPackDetail?.Name as string} handleOpenModal={handleOpenModal} />
              <FormListTableBlock
                submit={submit}
                loading={isLoading}
                data={data as UspFormPackDetailViewResult[]}
                setOpenAddEditModal={setOpenAddEditModal}
                setType={setType}
                setRowData={setRowData}
              />
            </form>
          </Grid>
          <Grid item sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            flexDirection: 'row-reverse',
            marginTop: 'auto',
            padding: theme.spacing(2),
          }}>
            <Button type='submit' variant='contained' color='error' onClick={() => navigate(appRoute.SettingsFormsPacksList)}>
              Close
            </Button>
          </Grid>
          <AddEditFormPackModal
            formPackDetail={formPackDetail}
            submit={submit}
            open={openAddEditModal}
            onClose={handleCloseModal}
            type={type}
            rowData={rowData as UspFormPackDetailViewResult}
          />
        </Grid>
      )}
    </Form >
  )
}

export default FormList
