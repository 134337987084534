import { Button, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import { AmountField } from '../../../../../../../ui'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { TDistribution, TDistributionTypeData } from '../../../../../../../models'
import { formatNumber, parseToNumber } from '../../../../../../../utils/general'
import { useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../../../../../redux/slices/authSlice'
import removeMask from '../../../../../../../utils/removeMask'
import { convertDateToDisplay } from '../../../../../../../utils/dateUtils'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  type: string
  paymentType?: TDistributionTypeData[]
  onAdd: (data: TDistribution) => void
  setTypeOptionsData: Dispatch<SetStateAction<TDistributionTypeData[]>>
  isDeleted: boolean
  setIsDeleted: Dispatch<SetStateAction<boolean>>
  setSumMaxAmountLateChargeDue: Dispatch<SetStateAction<number | undefined>>
  errors: any
}

const DistributionAddCard = ({ paymentType, onAdd, setTypeOptionsData, isDeleted, setIsDeleted, setSumMaxAmountLateChargeDue, errors }: Props) => {
  const form = useForm()
  const { values } = useFormState()
  const [MaxAmountPlusLateFee, setMaxAmountPlusLateFee] = useState(0)
  const [showLateFee, setShowLateFee] = useState(false)
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true)
  const [amountFieldDisabled, setAmountFieldDisabled] = useState(false)
  const [selectedItem, setSelectedItem] = useState<TDistributionTypeData>()
  const userAccess = useSelector(getUserAccessRights)
  const handleAdd = () => {
    const selectedType = form?.getFieldState('TypeDistributionAdd')?.value
    const selectedItem = paymentType?.find((item) => item.DistributionType === selectedType)

    onAdd({
      Type: values?.TypeDistributionAdd,
      LateFee: values?.LateFee,
      AmountAdd: values?.AmountAdd,
    })

    if (selectedItem && selectedItem.IsRelated) {
      // Filter out options that have IsRelated = true
      setTypeOptionsData((prevState) => (prevState ? prevState?.filter((type) => !type.IsRelated) : []))
    } else if (selectedItem && selectedItem.IsPayoffRelated) {
      // Filter out options that have IsPayoffRelated = true
      setTypeOptionsData((prevState) => (prevState ? prevState?.filter((type) => !type.IsPayoffRelated) : []))
    } else {
      setTypeOptionsData((prevState) => (prevState ? prevState?.filter((type) => type.DistributionType !== selectedType) : []))
    }

    form.change('LateFee', '')
    form.change('AmountAdd', '')
    setMaxAmountPlusLateFee(0)
    setIsAddButtonDisabled(true)
  }

  const handleTypeChange = (selectedValue: string) => {
    if (selectedValue === undefined || selectedValue === null) {
      form.change('TypeDistributionAdd', null)
      form.change('LateFee', '')
      form.change('AmountAdd', '')
    }
    const selectedType = paymentType?.find((item) => item?.DistributionType === selectedValue)
    setSelectedItem(selectedType)
    if (selectedType) {
      const lateFee = selectedType?.LateChargeDue ? parseToNumber(selectedType.LateChargeDue) : 0
      const max = selectedType?.MaxAmount ? parseToNumber(selectedType.MaxAmount) : 0
      const maxAmount = selectedType?.PastDueAmount ? parseToNumber(selectedType.PastDueAmount) : 0

      setMaxAmountPlusLateFee(maxAmount)
      setShowLateFee(selectedType?.IsShowLateFee)

      form.change('LateFee', lateFee?.toString())
      form.change('AmountAdd', maxAmount?.toString())

      if (selectedType.IsPayoff) {
        setAmountFieldDisabled(true)
      } else {
        setAmountFieldDisabled(false)
      }
      // Enable Add button if a type is selected
      setIsAddButtonDisabled(false)
    } else {
      // Disable Add button if no type is selected
      setIsAddButtonDisabled(true)
    }
  }

  useEffect(() => {
    if (isDeleted) {
      // Clear the 'TypeDistributionAdd' field state
      form.change('TypeDistributionAdd', null)
      form.change('LateFee', '')
      form.change('AmountAdd', '')
      // Reset isDeleted to false
      setIsDeleted(false)
    }
  }, [isDeleted])

  useEffect(() => {
    if (paymentType?.find((item) => item?.DistributionType === 'Installment')) {
      form.change('TypeDistributionAdd', 'Installment')
      handleTypeChange('Installment')
    }
  }, [paymentType])

  useEffect(() => {
    if (values?.LateFee) {
      const maxAmount = selectedItem?.MaxAmount ? parseToNumber(selectedItem?.MaxAmount as string) : 0
      const pastDueAmount = selectedItem?.PastDueAmount ? parseToNumber(selectedItem?.PastDueAmount as string) : 0
      const lateChargeDue = selectedItem?.LateChargeDue ? parseToNumber(selectedItem?.LateChargeDue as string) : 0
      const lateFee = values?.LateFee ? parseToNumber(values?.LateFee) : 0

      let maxAmountCalcForValidation = maxAmount - lateChargeDue + parseToNumber(removeMask(values?.LateFee) as string)
      setSumMaxAmountLateChargeDue(maxAmountCalcForValidation)

      let calculate = pastDueAmount - lateChargeDue + lateFee

      calculate = Math.round(calculate * 100) / 100
      form.change('AmountAdd', calculate?.toString())
    }
  }, [selectedItem, values?.LateFee])

  function createLabel(option: any) {
    return `${option['DistributionType']} ${
      option['DueDate'] || (option['IsShowLateFee'] && option['LateChargeDue']) || option['PastDueAmount'] > 0 ? '<' : ''
    }${option['DueDate'] ? 'Due Date: ' + convertDateToDisplay(option['DueDate']) + ' - ' : ''}${
      option['IsShowLateFee'] && option['LateChargeDue'] > 0 ? 'Late Fee: $' + formatNumber(option['LateChargeDue']) + ' - ' : ''
    }${option['PastDueAmount'] > 0 ? 'Amount Due: $' + formatNumber(option['PastDueAmount']) : ''}${
      option['DueDate'] || (option['IsShowLateFee'] && option['LateChargeDue']) || option['PastDueAmount'] > 0 ? '>' : ''
    }`
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DropdownFieldSearch
          name='TypeDistributionAdd'
          label='Type'
          options={(paymentType as TDistributionTypeData[]) || []}
          getOptionLabel={createLabel}
          optionKey='DistributionType'
          optionValue='DistributionType'
          autoComplete='off'
          onChange={handleTypeChange}
        />
      </Grid>
      {showLateFee && (
        <Grid item xs={12}>
          <AmountField variant='standard' disabled={!userAccess?.CanAdjustLateFee} name='LateFee' label='Late Fee' fullWidth />
        </Grid>
      )}
      <Grid item xs={12}>
        <AmountField disabled={amountFieldDisabled} variant='standard' name='AmountAdd' label='Amount' fullWidth />
      </Grid>
      <Grid container item justifyContent={'flex-end'} xs={12}>
        <Grid item>
          <Button
            type='button'
            variant='contained'
            fullWidth
            onClick={handleAdd}
            disabled={isAddButtonDisabled || (paymentType !== undefined && paymentType.length === 0) || errors?.AmountAdd}
          >
            + Add
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DistributionAddCard
