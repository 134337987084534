import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import { RouterLink } from '../../routes/components'
import { bgGradient } from '../../theme/css'
import { useResponsive } from '../../hooks/use-responsive'
import Logo from '../logo-new/logo'
import overlay_2 from '../../assets/background/overlay_2.jpg'
// ----------------------------------------------------------------------

type Props = {
  title?: string
  backgroundImg?: string
  children: React.ReactNode
}

export function AuthTemplate({ children, backgroundImg, title }: Props) {
  const theme = useTheme()

  const mdUp = useResponsive('up', 'md')

  const renderLogo = (
    <Logo
      sx={{
        zIndex: 9,
        position: 'absolute',
        m: { xs: 2, md: 5 },
      }}
    />
  )

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 550,
        px: { xs: 2, md: 8 },
        pt: { xs: 15, md: 20 },
        pb: { xs: 15, md: 0 },
      }}
    >
      {children}
    </Stack>
  )

  const renderSection = (
    <Stack
      // flexGrow={1}
      spacing={10}
      alignItems='center'
      justifyContent='center'
      sx={{
        pt: 12,
        px: 3,
        pb: 3,
        ...bgGradient({
          color: alpha(theme.palette.background.default, theme.palette.mode === 'light' ? 0.88 : 0.94),
          imgUrl: overlay_2,
        }),
      }}
    >
      <Typography variant='h4' sx={{ maxWidth: 480, textAlign: 'center' }}>
        {title || 'Hi, Welcome back'}
      </Typography>

      <Box
        component='img'
        alt='auth'
        src={backgroundImg || '/assets/signIn-bg.png'}
        sx={{
          maxWidth: {
            xs: 480,
            lg: 560,
            xl: 720,
          },
        }}
      />
    </Stack>
  )

  return (
    <Stack
      component='main'
      direction='row'
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderLogo}

      {renderContent}
      {mdUp && renderSection}
    </Stack>
  )
}
