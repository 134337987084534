import sum from 'lodash/sum'
import { useEffect, useCallback, Dispatch, SetStateAction } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import InputAdornment from '@mui/material/InputAdornment'
import { inputBaseClasses } from '@mui/material/InputBase'
import { RHFSelect, RHFTextField } from '../../../../components/hook-form'
import { formatNumber } from '../../../../utils/general'
import Iconify from '../../../../components/iconify'
import { useFormState } from 'react-final-form'
import { TBillData, TFullResponse } from '../../../../models'
import { IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material'

// ----------------------------------------------------------------------

interface Props {
  tableData: TFullResponse[]
  addBill: (e: any) => void
  setOperationType: Dispatch<SetStateAction<'add' | 'delete'>>
  handleDeleteBill: (e: TFullResponse) => void
  setIsEdit: Dispatch<SetStateAction<boolean>>
  handleOpenAddMultilineLineBillModal: any
}
export default function InvoiceNewEditDetails({ tableData, handleDeleteBill, setIsEdit, handleOpenAddMultilineLineBillModal }: Props) {
  const { values, errors } = useFormState()
  const areRequiredFieldsFilled = values.invoiceTo && values.BillDate && values.DueDate && values.BillNumber && values.ChartOfAccountAP

  const totalOnRow = values.items?.map((item: any) => item.quantity * item.price)

  const subTotal = sum(totalOnRow)

  const totalAmount = subTotal - values.discount - values.shipping + values.taxes

  //   const handleSelectService = useCallback(
  //     (index: number, option: string) => {
  //       setValue(`items[${index}].price`, INVOICE_SERVICE_OPTIONS.find((service: any) => service.name === option)?.price)
  //       setValue(`items[${index}].total`, values.items?.map((item: any) => item.quantity * item.price)[index])
  //     },
  //     [setValue, values.items],
  //   )

  const renderTotal = (
    <Stack spacing={2} alignItems='flex-end' sx={{ mt: 3, textAlign: 'right', typography: 'body2', px: 3 }}>
      <Stack direction='row' sx={{ typography: 'subtitle1' }}>
        <Box>Total</Box>
        <Box sx={{ width: 160 }}>
          $ {formatNumber(tableData?.map((item) => Number(item?.BillAmount.replaceAll(',', '')))?.reduce((a, b) => a + b, 0)) || '-'}
        </Box>
      </Stack>
    </Stack>
  )

  return (
    <Box sx={{ py: 3 }}>
      <Typography variant='h6' sx={{ color: 'text.disabled', mb: 3, px: 3 }}>
        Details:
      </Typography>

      <Stack spacing={3}>
        <TableContainer sx={{ overflow: 'auto' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel>CoA (Bill)</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Stock #</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Branch</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Amount</TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel>Comment</TableSortLabel>
                </TableCell>
                <TableCell align={'right'}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((item, index) => (
                <TableRow hover key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{item.AccountNumberName}</TableCell>
                  <TableCell>{item?.StockNumber}</TableCell>
                  <TableCell>{item?.BranchCode}</TableCell>
                  <TableCell>{item?.BillAmount}</TableCell>
                  <TableCell>{item?.Description}</TableCell>
                  <TableCell align={'right'}>
                    <Button
                      size='small'
                      color='error'
                      startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}
                      onClick={() => {
                        handleDeleteBill(item)
                        setIsEdit(true)
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Divider sx={{ px: 3, my: 3, borderStyle: 'dashed' }} />

      <Stack spacing={3} sx={{ px: 3 }} direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-end', md: 'center' }}>
        <Button
          size='small'
          color='primary'
          disabled={!areRequiredFieldsFilled}
          startIcon={<Iconify icon='mingcute:add-line' />}
          onClick={handleOpenAddMultilineLineBillModal}
          sx={{ flexShrink: 0 }}
        >
          Add Item
        </Button>
      </Stack>

      {renderTotal}
    </Box>
  )
}
