import React, { useMemo } from 'react'
import Typography from '@mui/material/Typography'
import { CustomCircular } from '../../../../../ui'
import Grid from '@mui/material/Grid'
import { generateUtilityClasses, IconButton } from '@mui/material'
import Popover from '@mui/material/Popover'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import EditIcon from '@mui/icons-material/Edit'
import { applyPhoneMask } from '../../../../../utils/general'
import { appRoute } from '../../../../../constants'
import { useNavigate, useParams } from 'react-router-dom'

const classesBox = generateUtilityClasses('Container', [
  'root',
  'title',
  'gridItem',
  'leftItemContainer',
  'rightItemContainer',
  'leftItemTypography',
  'rightItemTypography',
  'titleContainer',
])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '350px',
    padding: theme.spacing(2),
  },
  [`& .${classesBox.title}`]: {
    textAlign: 'center',
  },
  [`& .${classesBox.titleContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  [`& .${classesBox.gridItem}`]: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  [`& .${classesBox.leftItemContainer}`]: {
    width: '25%',
  },
  [`& .${classesBox.rightItemContainer}`]: {
    width: '60%',
    marginLeft: '5px',
  },

  [`& .${classesBox.leftItemTypography}`]: {
    textAlign: 'right',
    width: '100%',
    display: 'flex',
    alignItems: 'left',
    justifyContent: 'right',
  },
  [`& .${classesBox.rightItemTypography}`]: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
}))

interface Props {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (value: HTMLButtonElement | null) => void
  buyerData?: any
  coBuyerData?: any
  isLoading?: boolean
  activeData?: 'buyer' | 'coBuyer' | undefined
  disableEditIcons?: boolean
}

const HeaderDetailsModal = ({ anchorEl, setAnchorEl, buyerData, coBuyerData, isLoading, activeData, disableEditIcons }: Props) => {
  const { dealID, leadID } = useParams()
  const navigate = useNavigate()

  const buyerCell = buyerData?.BuyerPhoneData?.find((phone: any) => phone?.IsPrimary && phone?.OrderOfPreference === 1)
  const buyerHome = buyerData?.BuyerPhoneData?.find((phone: any) => !phone?.IsPrimary && phone?.OrderOfPreference === 2)
  const coBuyerCell = coBuyerData?.CoBuyerPhoneData?.find((phone: any) => phone?.IsPrimary && phone?.OrderOfPreference === 1)
  const coBuyerHome = coBuyerData?.CoBuyerPhoneData?.find((phone: any) => !phone?.IsPrimary && phone?.OrderOfPreference === 2)

  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClick = (type: 'buyer' | 'coBuyer') => {
    navigate(
      appRoute?.AccountsBuyerEdit.replace(':dealID', (dealID as string) || (leadID as string))
        .replace(':contactID', type === 'buyer' ? (buyerData?.ContactID1.toString() as string) : (coBuyerData?.ContactID2.toString() as string))
        .replace(':contactType', type === 'buyer' ? 'buyer' : 'co-buyer'),
    )
    handleClose()
  }

  const isDataLoading = useMemo(() => isLoading, [isLoading])

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      <StyledBox className={classesBox.root}>
        <Grid item className={classesBox.titleContainer}>
          {disableEditIcons && (
            <IconButton color='primary' onClick={() => handleClick(activeData === 'buyer' ? 'buyer' : 'coBuyer')}>
              <EditIcon />
            </IconButton>
          )}
          <Typography variant='h3' className={classesBox.title}>
            {activeData === 'buyer' ? 'Buyer' : 'Co-Buyer'}
          </Typography>
        </Grid>
        {isLoading ? (
          <CustomCircular open={isDataLoading} />
        ) : (
          <>
            {activeData === 'buyer' && buyerData && (
              <Grid container>
                {buyerData?.BuyerName && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>Name:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{buyerData?.BuyerName}</Typography>
                    </Grid>
                  </>
                )}
                {buyerData?.BuyerAddressData && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>Address:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{buyerData?.BuyerAddressData}</Typography>
                      <br />
                      {buyerData?.BuyerAddressData2 && (
                        <>
                          <Typography className={classesBox.rightItemTypography}>{buyerData?.BuyerAddressData2}</Typography>
                          <br />
                        </>
                      )}
                      <Typography className={classesBox.rightItemTypography}>
                        {buyerData?.BuyerCity}, {buyerData?.BuyerState} {buyerData?.BuyerPostalCode}
                      </Typography>
                    </Grid>
                  </>
                )}

                {buyerCell?.PhoneNumber && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>{buyerCell?.PhoneType}:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{applyPhoneMask(buyerCell?.PhoneNumber)}</Typography>
                    </Grid>
                  </>
                )}

                {buyerHome?.PhoneNumber && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>{buyerHome?.PhoneType}:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{applyPhoneMask(buyerHome?.PhoneNumber)}</Typography>
                    </Grid>
                  </>
                )}
                {buyerData?.BuyerWorkPhone && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>Work:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{applyPhoneMask(buyerData?.BuyerWorkPhone)}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            {activeData === 'coBuyer' && coBuyerData && (
              <Grid container>
                {coBuyerData?.CoBuyerName && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>Name:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{coBuyerData?.CoBuyerName}</Typography>
                    </Grid>
                  </>
                )}
                {coBuyerData?.CoBuyerAddressData && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>Address:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{coBuyerData?.CoBuyerAddressData}</Typography>
                      <br />
                      {coBuyerData?.CoBuyerAddressData2 && (
                        <>
                          <Typography className={classesBox.rightItemTypography}>{coBuyerData?.CoBuyerAddressData2}</Typography>
                          <br />
                        </>
                      )}
                      <Typography className={classesBox.rightItemTypography}>
                        {coBuyerData?.CoBuyerCity}, {coBuyerData?.CoBuyerState} {coBuyerData?.CoBuyerPostalCode}
                      </Typography>
                    </Grid>
                  </>
                )}
                {coBuyerCell?.PhoneNumber && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>{coBuyerCell?.PhoneType}:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{applyPhoneMask(coBuyerCell?.PhoneNumber)}</Typography>
                    </Grid>
                  </>
                )}
                {coBuyerHome?.PhoneNumber && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>{coBuyerHome?.PhoneType}:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{applyPhoneMask(coBuyerHome?.PhoneNumber)}</Typography>
                    </Grid>
                  </>
                )}
                {coBuyerData?.CoBuyerWorkPhone && (
                  <>
                    <Grid container className={classesBox.leftItemContainer}>
                      <Typography className={classesBox.leftItemTypography}>Work:</Typography>
                    </Grid>
                    <Grid container className={classesBox.rightItemContainer}>
                      <Typography className={classesBox.rightItemTypography}>{applyPhoneMask(coBuyerData?.CoBuyerWorkPhone)}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </>
        )}
      </StyledBox>
    </Popover>
  )
}

export default HeaderDetailsModal
