import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import AmountField from '../../../../../ui/AmountField'
import removeMask from '../../../../../utils/removeMask'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  disableFields: boolean
}

export const MonthlyIncomeForm = ({ disableFields }: Props) => {
  const { values } = useFormState()
  const { change } = useForm()

  useEffect(() => {
    const totalIncomeMemorized = `${
      (Number(removeMask(values?.IncomeMonthlyIncome)) || 0) +
      (Number(removeMask(values?.IncomeAlimony)) || 0) +
      Number(removeMask(values?.IncomeOtherIncome) || 0)
    }`
    change('IncomeTotalIncome', totalIncomeMemorized)
  }, [values?.IncomeMonthlyIncome, values?.IncomeAlimony, values?.IncomeOtherIncome])

  return (
    <>
      <CardHeader title='Monthly Income (optional)' />

      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <AmountField variant='standard' disabled={disableFields} name='IncomeMonthlyIncome' label='Income' fullWidth />
        </Grid>
        <Grid item xs={12}>
          <AmountField variant='standard' disabled={disableFields} name='IncomeAlimony' label='Alimony/Child Support' fullWidth />
        </Grid>
        <Grid item xs={12}>
          <AmountField variant='standard' disabled={disableFields} name='IncomeOtherIncome' label='Other Income' fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextField disabled={disableFields} name='IncomeOtherIncomeDescription' label='Source of Other Income' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default MonthlyIncomeForm
