import { GetAccountingCashAccountsDataResponse, GetAccountingUnpaidVendorsApiResponse, TFullResponse } from '../../../../models'
import { convertDateToString } from '../../../../utils/dateUtils'
import { getCurrentDate } from '../../../../utils/general'
import removeMask from '../../../../utils/removeMask'

export const transformToRequestValues = (values?: any, tableData?: TFullResponse[], selectedVendor?: any) => {
  const jsonCheckOffsetData = tableData?.map((item: TFullResponse) => {
    return {
      OffsetCoaID: item?.AccountNumberName || null,
      OffsetAmount: removeMask(item.BillAmount),
      BranchID: item?.BranchCode || null,
      CarExpenseType: item?.CarExpenseType || null,
      CarID: item?.CarID || null,
      DealID: null,
      Description: item.Description || null,
      DealTradeID: item?.SelectedSearchAccount?.DealTradeID || null,
      CarDepositID: null,
      ConsumerLoanPayableID: null,
      SideNoteID: null,
      ReferralID: null,
    }
  })
  return {
    checkDate: convertDateToString(values.CheckDate),
    vendorID: selectedVendor?.ID,
    jsonCheckOffsetData: jsonCheckOffsetData,
    checkNumber: values.CheckNumber || null,
    billPayTypeID: values.BillPayType || null,
    bankCoaID: values.BankAccount || null,
    bankBranchID: values.BankBranch || null,
    payToTheOrderOff: values.PayeeName,
    payeeAddress1: values.Address1 || null,
    payeeAddress2: values.Address2 || null,
    payeeCity: values.City || null,
    payeeState: values.State || null,
    payeePostalCode: values.PostalCode || null,
    attention: values.Attention || null,
    checkMemo: values.Memo || null,
    isSystemGenerated: false,
  }
}

export const transformToFormValues = (
  cashAccountsData?: GetAccountingCashAccountsDataResponse,
  selectedVendor?: any,
  unpaidVendorsData?: GetAccountingUnpaidVendorsApiResponse,
  tableData?: any,
) => {
  // Default values
  let vendorAttention = ''
  let vendorName = ''
  let address1 = ''
  let address2 = ''
  let city = ''
  let state = ''
  let postalCode = ''
  let memoData = ''

  // Find the account where "IsPrimaryBank" is true
  const primaryBankAccount = cashAccountsData?.find((account) => account?.IsPrimaryBank === true)
  const primaryBankAccountId = primaryBankAccount?.ID

  // Check if any item in tableData contains SelectedCoaItem.ControlSubType = “Trade”
  const tradeItem = tableData
    ?.slice()
    .reverse()
    .find((item: any) => item.SelectedCoaItem.ControlSubType === 'Trade')

  if (tradeItem) {
    const selectedSearchAccount = tradeItem.SelectedSearchAccount
    vendorAttention = selectedSearchAccount?.Attention || ''
    vendorName = selectedSearchAccount?.Lienholder || ''
    memoData = selectedSearchAccount?.TradeVehicle + ' ' + selectedSearchAccount?.TradeVIN || ''

    if (selectedSearchAccount?.AddressData) {
      try {
        const parsedAddress = JSON.parse(selectedSearchAccount.AddressData)
        const addressData = parsedAddress?.[0]
        if (addressData) {
          address1 = addressData.Address1 || ''
          address2 = addressData.Address2 || ''
          city = addressData.City || ''
          state = addressData.State || ''
          postalCode = addressData.PostalCode || ''
        }
      } catch (e) {
        console.error('Error parsing AddressData:', e)
      }
    }
  } else {
    if (selectedVendor) {
      const matchedVendor = unpaidVendorsData?.find((vendor) => vendor?.VendorID === selectedVendor.ID)
      console.log('matchedVendor',matchedVendor)
      if (matchedVendor) {
        vendorAttention = matchedVendor.Attention || ''
        vendorName = matchedVendor.Name || ''

        const vendorAddress = matchedVendor?.AddressData?.[0]
        if (vendorAddress) {
          address1 = vendorAddress.Address1 || ''
          address2 = vendorAddress.Address2 || ''
          city = vendorAddress.City || ''
          state = vendorAddress.State || ''
          postalCode = vendorAddress.PostalCode || ''
        }
      }
    }
  }

  return {
    BillPayType: 1, // default Check
    CheckDate: getCurrentDate(),
    BankAccount: primaryBankAccountId,
    Attention: vendorAttention,
    PayeeName: vendorName,
    Address1: address1,
    Address2: address2,
    City: city,
    State: state,
    PostalCode: postalCode,
    Memo: memoData,
  }
}
