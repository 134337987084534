import { Box, Button, Container, generateUtilityClasses, Grid, Modal, TextField, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import AddIcon from '@mui/icons-material/Add'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Field, useFormState } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../constants'

const classes = generateUtilityClasses('Container', ['root'])

const StyledContainer = styled(Container)(() => ({
  [`&.${classes.root}`]: {
    margin: 0,
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
}))

const classesBox = generateUtilityClasses('Container', ['root', 'vinWrap', 'icon', 'popoverTitle'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '200px',
    padding: theme.spacing(4),
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
  },
  [`& .${classesBox.vinWrap}`]: {
    marginBottom: theme.spacing(4),
  },
  [`& .${classesBox.icon}`]: {
    fontSize: 16,
    color: theme.palette.text.button,
  },
  [`& .${classesBox.popoverTitle}`]: {
    marginBottom: '10px',
  },
}))

const DealsTradeAdd = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const navigate = useNavigate()
  const { values } = useFormState()
  const { dealID } = useParams()

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmit = () => {
    if (values.vinAdd === '') {
      navigate(appRoute.DealsTradeAdd.replace(':dealID', dealID as string))
    } else {
      navigate(appRoute.DealTradeAddVin.replace(':dealID', dealID as string).replace(':vin', values.vinAdd))
    }
  }

  return (
    <StyledContainer className={classes.root}>
      <Typography variant='h3'>Trades</Typography>
      <Button variant='contained' startIcon={<AddIcon />} onClick={handleClick}>
        Add new
      </Button>
      <Modal open={open} onClose={handleClose}>
        <StyledBox className={classesBox.root}>
          <Grid className={classesBox.vinWrap}>
            <Typography variant='h3' className={classesBox.popoverTitle}>
              Trade Vehicle
            </Typography>
            <Field name='vinAdd'>{({ input }: any) => <TextField {...input} name='vinAdd' label='VIN' fullWidth />}</Field>
          </Grid>
          <Grid>
            <Button type='submit' fullWidth variant='contained' color='success' onClick={handleSubmit}>
              Next <ArrowForwardIosIcon className={classesBox.icon} />
            </Button>
          </Grid>
        </StyledBox>
      </Modal>
    </StyledContainer>
  )
}

export default DealsTradeAdd
