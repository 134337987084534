import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import {
  Card,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import SetupCard from './components/SetupCard/SetupCard'
import CheckCard from './components/CheckCard/CheckCard'
import { Form } from 'react-final-form'
import {
  ParsedDropdownResponse,
  selectDropdownByName,
  useAddPaymentsTransactionMutation,
  useGetAccountDueAmountMutation,
} from '../../../../../services'
import { useLocation, useParams } from 'react-router-dom'
import { transformToFormValues, transformToRequestValues } from './components/transform'
import { AccountsDropdownResult, TDistribution, TDistributionTypeData, TPaymentCheckType, TPaymentType } from '../../../../../models'
import CreditCard from './components/CreditCard/CreditCard'
import DistributionCard from './components/DistributionCard/DistributionCard'
import validateFormValues from '../../../../../utils/validateForm'
import { accountsAddPaymentValidation } from '../../../../../validation/accountsAddPaymentValidation'
import DistributionAddCard from './components/DistributionAddCard/DistributionAddCard'
import ItemizationCard from './components/ItemizationCard/ItemizationCard'
import FinalizeCard from './components/FinalizeCard/FinalizeCard'
import PaymentNoteCard from './components/PaymentNoteCard/PaymentNoteCard'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { CustomCircular } from '../../../../../ui'
import { getCurrentDate } from '../../../../../utils/general'

const classes = generateUtilityClasses('form', ['root', 'formItem', 'formTable'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    backgroundColor: theme.palette.background.paper,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '800px',
    boxShadow: 24,
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    maxWidth: '204px',
  },
  [`& .${classes.formTable}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
}))

interface Props {
  handleClose: () => void
  openModal: boolean
  getAccountsOverview: (args: { dealId: string }) => void
  getAccountDueAmountRequest: (e: any) => void
  getAccountsPayHistory: (args: { dealId: string; uspPaymentView: { isIncludeReversals: boolean } }) => void

  branchID: string
  carID: string
  contactID1: string
}

const steps = ['Setup', 'Distribution', 'Finalize']

const AddPaymentModal = ({
  handleClose,
  openModal,
  getAccountsOverview,
  getAccountsPayHistory,
  getAccountDueAmountRequest,
  branchID,
  carID,
  contactID1,
}: Props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { dealID } = useParams()
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())
  const [getAccountDueAmount, { data: accountDueAmountData, isSuccess, isLoading: accountDueAmountIsLoading }] = useGetAccountDueAmountMutation()
  const [initialValues, setInitialValues] = useState({})
  const [totalAmount, setTotalAmount] = useState(0)
  const [shouldSkip, setShouldSkip] = useState(false)
  const [previousTypeSetup, setPreviousTypeSetup] = useState<string | null>(null)
  const [addPaymentTransaction, { isSuccess: addPaymentIsSuccess }] = useAddPaymentsTransactionMutation()
  const [typeOptionsData, setTypeOptionsData] = useState<TDistributionTypeData[]>([])
  const [distributions, setDistributions] = useState<TDistribution[]>([])
  const [originalTypeOptions, setOriginalTypeOptions] = useState<TDistributionTypeData[]>([])
  const [isDeleted, setIsDeleted] = useState(false)
  const [relatedOptions, setRelatedOptions] = useState<TDistributionTypeData[]>([])
  const [sumMaxAmountLateChargeDue, setSumMaxAmountLateChargeDue] = useState<number | undefined>()
  const paymentForm = useSelector((state) => selectDropdownByName(state, 'PaymentForm'))
  const paymentMethod = useSelector((state) => selectDropdownByName(state, 'PaymentMethod'))
  const paymentCheckType = useSelector((state) => selectDropdownByName(state, 'PaymentCheckType'))
  const paymentCreditCardType = useSelector((state) => selectDropdownByName(state, 'PaymentCreditCardType'))

  useEffect(() => {
    if (isSuccess && accountDueAmountData) {
      const related = accountDueAmountData?.DistributionTypeData?.filter((option) => option.IsRelated)
      setRelatedOptions(related)
      setTypeOptionsData(accountDueAmountData?.DistributionTypeData)
      setOriginalTypeOptions(accountDueAmountData?.DistributionTypeData) // set the original data
    }
  }, [isSuccess, accountDueAmountData])

  const handleDelete = (type: any) => {
    // Find the distribution to delete
    const deletedDistribution = distributions.find((distribution) => distribution.Type === type)

    // Remove the distribution from the array
    setDistributions(distributions.filter((distribution) => distribution.Type !== type))

    // If the distribution was found
    if (deletedDistribution) {
      // Find the original option corresponding to the deleted distribution
      const originalOption = originalTypeOptions.find((option) => option.DistributionType === deletedDistribution.Type)

      // If the original option was found
      if (originalOption) {
        // Add the deleted distribution back to type options data with its current values
        setTypeOptionsData((prevTypeOptionsData) => {
          // Check if the original option is already in the dropdown data
          if (!prevTypeOptionsData.some((option) => option.DistributionType === originalOption.DistributionType)) {
            return [
              ...prevTypeOptionsData,
              {
                ...originalOption,
                Type: deletedDistribution.Type,
                LateFee: deletedDistribution.LateFee,
                AmountAdd: deletedDistribution.AmountAdd,
              },
            ]
          } else {
            return prevTypeOptionsData
          }
        })

        // If the deleted distribution was related, add back all other related options
        if (originalOption.IsRelated) {
          setTypeOptionsData((prevTypeOptionsData) => {
            const newOptions = [...prevTypeOptionsData]
            relatedOptions.forEach((relatedOption) => {
              // Only add the related option if it does not already exist in the dropdown data
              if (!newOptions.some((option) => option.DistributionType === relatedOption.DistributionType)) {
                newOptions.push(relatedOption)
              }
            })
            return newOptions
          })
        }

        setIsDeleted(true)
      }
    }
  }

  useEffect(() => {}, [typeOptionsData])
  const isStepOptional = (step: number) => {
    return step === 1
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    setIsDeleted(false)
    setTypeOptionsData([])
    if (activeStep === 1) {
      setIsDeleted(true) // this will remove selected item from TypeDistributionAdd if item is inside of table, but not deleted
      setDistributions([])
      setTotalAmount(0)
    }
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  const handleSubmit = (values?: any) => {
    console.log('branchID', branchID)
    addPaymentTransaction({
      paymentsTransaction: transformToRequestValues(values, branchID, dealID, carID, contactID1, accountDueAmountData, distributions),
    })
  }

  useEffect(() => {
    setInitialValues(transformToFormValues())
  }, [accountDueAmountData])

  useEffect(() => {
    if (addPaymentIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      if (location.pathname.includes(`/accounts/${dealID}/overview`)) {
        getAccountsOverview({ dealId: dealID as string })
        getAccountDueAmountRequest({
          dealId: dealID as string,
          accountDueAmount: {
            effectiveDate: getCurrentDate(),
            isQuote: false,
          },
        })
      }
      getAccountsPayHistory({
        dealId: dealID as string,
        uspPaymentView: {
          isIncludeReversals: false,
        },
      })
      setActiveStep(0)
      setIsDeleted(true)
      setDistributions([])
      handleClose()
    }
  }, [addPaymentIsSuccess])

  const isLoading = useMemo(() => accountDueAmountIsLoading, [accountDueAmountIsLoading])
  const checkID = paymentForm?.ReturnData?.find((item) => item?.PaymentForm === 'Check')?.ID ?? null
  const creditCardID = paymentForm?.ReturnData?.find((item) => item?.PaymentForm === 'Credit Card')?.ID ?? null

  return (
    <Dialog open={openModal} onClose={handleClose}>
      <DialogTitle />

      <DialogContent>
        <Form
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validate={validateFormValues(
            accountsAddPaymentValidation(
              accountDueAmountData?.DownPaymentBalance as string,
              sumMaxAmountLateChargeDue as number,
              checkID as number,
              creditCardID as number,
            ),
          )}
        >
          {({ handleSubmit, values, errors }: any) => (
            <>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {}
                  const labelProps: {
                    optional?: React.ReactNode
                  } = {}
                  if (isStepSkipped(index)) {
                    stepProps.completed = false
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  )
                })}
              </Stepper>
              {activeStep === steps?.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid container py={3} spacing={2}>
                    {activeStep === 0 && (
                      <>
                        <CardHeader title='Setup' />
                        <Grid item xs={12}>
                          {paymentMethod && paymentForm && (
                            <SetupCard
                              getAccountDueAmount={getAccountDueAmount as any}
                              setActiveStep={setActiveStep}
                              paymentForm={paymentForm?.ReturnData as ParsedDropdownResponse[]}
                              paymentMethod={paymentMethod?.ReturnData as ParsedDropdownResponse[]}
                              paymentType={accountDueAmountData?.PaymentTypeData as TPaymentType[]}
                              CalculationErrorDescription={accountDueAmountData?.CalculationErrorDescription}
                              setShouldSkip={setShouldSkip}
                              setPreviousTypeSetup={setPreviousTypeSetup}
                            />
                          )}
                        </Grid>
                        {values?.Form === checkID && paymentCheckType && (
                          <>
                            <CardHeader title='Check' />
                            <Grid item xs={12}>
                              <CheckCard checkType={paymentCheckType?.ReturnData as ParsedDropdownResponse[]} />
                            </Grid>
                          </>
                        )}
                        {values?.Form === creditCardID && paymentCreditCardType && (
                          <>
                            <CardHeader title='Credit Card' />

                            <Grid item xs={12}>
                              <CreditCard cardType={paymentCreditCardType?.ReturnData as ParsedDropdownResponse[]} />
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                    {activeStep === 1 && values?.TypeSetup?.toLowerCase() !== 'loan' && (
                      <>
                        <CardHeader title='Distribution' />
                        <Grid item xs={12}>
                          <DistributionCard
                            accountDueAmountData={accountDueAmountData}
                            paymentType={accountDueAmountData?.PaymentTypeData as TPaymentType[]}
                          />
                        </Grid>
                      </>
                    )}
                    {activeStep === 1 && values?.TypeSetup?.toLowerCase() === 'loan' && (
                      <>
                        <CardHeader title='Distribution' />
                        <Grid item xs={12}>
                          <DistributionAddCard
                            onAdd={(data) => setDistributions([...distributions, data])}
                            type={values?.Type}
                            setTypeOptionsData={setTypeOptionsData}
                            paymentType={(typeOptionsData as TDistributionTypeData[]) || []}
                            isDeleted={isDeleted}
                            setIsDeleted={setIsDeleted}
                            setSumMaxAmountLateChargeDue={setSumMaxAmountLateChargeDue}
                            errors={errors}
                          />
                        </Grid>

                        <CardHeader title='Itemization' />
                        <Grid item xs={12}>
                          <ItemizationCard distributions={distributions} onDelete={handleDelete} setTotalAmount={setTotalAmount} />
                        </Grid>
                      </>
                    )}

                    {activeStep === 2 && (
                      <>
                        <CardHeader title='Finalize' />

                        <Grid item xs={12}>
                          <FinalizeCard totalAmount={totalAmount} />
                        </Grid>
                        <CardHeader title='Payment Note' />
                        <Grid item xs={12}>
                          <PaymentNoteCard />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, alignItems: 'center', justifyContent: 'flex-end' }}>
                    <Button tabIndex={2} variant='contained' color='warning' disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                      Back
                    </Button>
                    {isStepOptional(activeStep) && (
                      <Button
                        tabIndex={1}
                        variant='contained'
                        color='error'
                        onClick={() => {
                          handleClose()
                          setActiveStep(0)
                          setIsDeleted(true)
                          setTypeOptionsData([])
                          setDistributions([])
                          setTotalAmount(0)
                        }}
                        sx={{ mr: 1 }}
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      tabIndex={0}
                      variant='contained'
                      type='button'
                      onClick={activeStep === steps?.length - 1 ? handleSubmit : handleNext}
                      disabled={
                        (activeStep === 0 && errors?.CheckType) ||
                        (activeStep === 0 && errors?.CheckNumber) ||
                        (activeStep === 0 && errors?.CreditType) ||
                        (activeStep === 0 && errors?.Last4ofCreditCard) ||
                        (activeStep === 0 && errors?.AuthorizationNumber) ||
                        (activeStep === 0 && errors?.Method) ||
                        (activeStep === 0 && errors?.Form) ||
                        (activeStep === 1 && values?.TypeSetup?.toLowerCase() === 'downpayment' && values?.Amount <= 0) ||
                        (activeStep === 1 && values?.TypeSetup?.toLowerCase() === 'loan' && !(totalAmount > 0)) ||
                        (activeStep === 2 && totalAmount <= 0 && values?.TypeSetup?.toLowerCase() === 'loan')
                        // (activeStep === 2 && errors?.Amount)
                      }
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </>
          )}
        </Form>
        <CustomCircular open={isLoading} />
      </DialogContent>
      <DialogActions />
    </Dialog>
  )
}

export default AddPaymentModal
