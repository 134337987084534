import React, { useEffect, useState } from 'react'
import Checkbox from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { Field } from 'react-final-form'
import { OutlinedInput, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'

interface Props {
  name: string
  label?: string
  labelId?: string
  options: any[]
  variant?: 'standard' | 'outlined' | 'filled'
  optionKey: string
  defaultValue?: string
  optionValue: string
  onChange?: () => void
  [x: string]: any
}

const classes = generateUtilityClasses('FormControl', ['formControl', 'title', 'selectedAll', 'indeterminateColor', 'selectAllText'])

const StyledFormControl = styled(FormControl)(() => ({
  [`&.${classes.formControl}`]: {},
  [`& .${classes.indeterminateColor}`]: {
    color: '#f50057',
  },
  [`& .${classes.selectAllText}`]: {
    fontWeight: 500,
  },
  [`& .${classes.selectAllText}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))

export const DropdownCheckboxes = ({
  name,
  label,
  labelId,
  options = [],
  variant = 'standard',
  defaultValue,
  optionKey,
  optionValue,
  fullWidth,
  onChange,
  ...props
}: Props) => {
  const [selected, setSelected] = useState<any[]>([])
  const isAllSelected = options?.length > 0 && selected?.length === options.length

  useEffect(() => {
    if (defaultValue && Array.isArray(defaultValue) && defaultValue?.length) {
      const defaultOptions = options?.filter((option) => defaultValue.some((def) => def[optionKey] === option[optionKey]))
      setSelected(defaultOptions)
    }
  }, [defaultValue])

  const handleChange = (event: any) => {
    const value = event.target.value
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options.length ? [] : options)
      return selected.length === options.length ? [] : options
    }
    setSelected(value)
    return value
  }

  const handleRenderValue = (select: any) => {
    if (select.length === options.length) {
      return 'All'
    } else {
      const nameStrings: string[] = []
      select?.forEach((item: any) => {
        nameStrings.push(item[optionValue])
      })
      return nameStrings.join(', ')
    }
  }

  const isChecked = (option: any) => !!selected?.find((item) => item[optionKey] === option[optionKey])

  return (
    <Field name={name}>
      {({ input }) => (
        <FormControl
          fullWidth
          sx={{
            flexShrink: 0,
            // width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>{label}</InputLabel>

          <Select
            {...props}
            input={<OutlinedInput label={label} />}
            labelId={labelId}
            multiple
            variant={variant}
            value={selected}
            onChange={(e) => {
              onChange && onChange()
              input.onChange(handleChange(e))
            }}
            renderValue={handleRenderValue}
          >
            <MenuItem
              value='all'
              classes={{
                root: isAllSelected ? classes.selectedAll : '',
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={selected.length > 0 && selected.length < options.length}
                />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.selectAllText }} primary='Select All' />
            </MenuItem>
            {options.map((option: any) => (
              <MenuItem key={option[optionKey]} value={option}>
                <ListItemIcon>
                  <Checkbox checked={isChecked(option)} />
                </ListItemIcon>
                <ListItemText primary={option[optionValue]} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Field>
  )
}

export default DropdownCheckboxes
