import { useForm } from 'react-hook-form'

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import LoadingButton from '@mui/lab/LoadingButton'
import LaunchIcon from '@mui/icons-material/Launch'
import FormProvider from '../../../../components/hook-form/form-provider'
import { Button, CardHeader, Dialog, Divider, Grid, IconButton, MenuItem, Typography } from '@mui/material'
import Iconify from '../../../../components/iconify'
import BuyerItem from './buyer-item'
import Label from '../../../../components/label/label'
import EditIcon from '@mui/icons-material/Edit'
import { Icon } from '@iconify/react'
import StipulationItem from './stipulations-item'
import { Key, useEffect, useMemo, useState } from 'react'
import { SettingsForm } from '../../LeadsTask/components'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../constants'
import CustomPopover from '../../../../components/custom-popover/custom-popover'
import { usePopover } from '../../../../components/custom-popover'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import {
  selectDropdownByNameActiveOnly,
  useDeleteCreditAppCoAplicantMutation,
  useDeleteCreditAppStipulationMutation,
  useDeleteReferencesMutation,
} from '../../../../services'
import { CreditAppReference } from '../../../../models/leadTypes'
import AddEditStipulationsFormModal from '../../LeadsStipulations/components/AddEditStipulationsFormModal'
import PullCreditModal from '../../LeadsTask/components/Modals/PullCreditModal'
import { useSelector } from 'react-redux'
import { DealsCard } from '../../LeadsTask/components/DealsCard'
import DealItem from './deal-item'
import InterestedInCard from '../../LeadsTask/components/InterestedInCard'
import { convertDateToDisplay } from '../../../../utils/dateUtils'
import { formatNumber } from '../../../../utils/general'
export default function LeadGeneral({ data, setRefetch }: any) {
  const methods = useForm({})
  const [openSettingsModal, setOpenSettingsModal] = useState(false)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false)
  const [referenceDeleteConfirmDialog, setReferenceDeleteConfirmDialog] = useState(false)
  const [stipulationOpenDeleteDialog, setStipulationOpenDeleteDialog] = useState(false)
  const [openStipulationModal, setOpenStipulationModal] = useState(false)
  const [stipulationToEdit, setStipulationToEdit] = useState<any>()
  const [seletedReference, setSelectedReference] = useState<any>()
  const [selectedStipulation, setSelectedStipulation] = useState<any>()
  const [isPullCreditModalOpen, setIsPullCreditModalOpen] = useState(false)
  const [userSelected, setUserSelected] = useState(0)
  const navigate = useNavigate()
  const { leadID } = useParams()
  const [deleteCoApplicant, { isSuccess: deleteCoApplicantSuccess }] = useDeleteCreditAppCoAplicantMutation()
  const [deleteReferences, { isSuccess: deleteReferencesIsSuccess }] = useDeleteReferencesMutation()
  const [deleteCreditAppStipulation, { isSuccess: deleteCreditAppStipulationIsSuccess }] = useDeleteCreditAppStipulationMutation()
  const popover = usePopover()
  const referencePopover = usePopover()
  const stipulationPopover = usePopover()
  const INTEGRATION_VENDOR = '700Credit'
  const leadLostReasonDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Integrations'))
  const integrationVendor = leadLostReasonDropdown?.find(({ IntegrationVendor }) => IntegrationVendor === INTEGRATION_VENDOR)
  const bureausData = useMemo(() => (integrationVendor?.IntegrationData ? JSON.parse(integrationVendor?.IntegrationData) : []), [integrationVendor])
  console.log('integrationVendor', integrationVendor)
  console.log('bureausData', bureausData)
  const handleDelete = () => {
    if (data?.CreditAppID && data?.ContactID2) {
      deleteCoApplicant({
        leadId: data?.CreditAppID,
        coApplicantId: data?.ContactID2,
      })
    }
    popover.onClose()
    setDeleteConfirmDialog(false)
  }

  const handleReferenceDelete = () => {
    if (seletedReference) {
      deleteReferences({
        referenceId: seletedReference.ReferenceID.toString(),
      })
      referencePopover.onClose()
      setReferenceDeleteConfirmDialog(false)
    }
  }

  const handleStipulationDelete = () => {
    if (selectedStipulation) {
      deleteCreditAppStipulation({
        creditAppId: `${leadID}`,
        creditAppStipulationId: `${selectedStipulation.CreditAppStipulationID}`,
      })
      stipulationPopover.onClose()
      setStipulationOpenDeleteDialog(false)
    }
  }

  useEffect(() => {
    setRefetch((prevValue: boolean) => !prevValue)
  }, [deleteCoApplicantSuccess, deleteReferencesIsSuccess, deleteCreditAppStipulationIsSuccess])
  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const onSubmit = handleSubmit(async (data) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500))
      console.info('DATA', data)
    } catch (error) {
      console.error(error)
    }
  })
  const settingsCardData = useMemo(
    () =>
      data
        ? [
            { title: 'Branch', value: data.BranchCode },
            { title: 'Salesperson', value: data.Salesperson },
            { title: 'Lead Source', value: data.Advertisement },
            { title: 'App Status', value: data.Status },
            { title: 'Interest Level', value: data.InterestLevel },
            {
              title: 'Credit App',
              value: (
                <Typography
                  variant='body2'
                  sx={{
                    ml: 1,
                    color: (theme) => (data.IsPaperApplicationSigned ? theme.palette.success.main : theme.palette.error.main),
                  }}
                >
                  {data.IsPaperApplicationSigned ? 'Signed' : 'Not Signed'}
                </Typography>
              ),
            },
            {
              title: 'Privacy Policy',
              value: (
                <Typography
                  variant='body2'
                  sx={{
                    ml: 1,
                    color: (theme) => (data.IsPrivacyDisclosureProvided ? theme.palette.success.main : theme.palette.error.main),
                  }}
                >
                  {data.IsPrivacyDisclosureProvided ? 'Provided' : 'Not Provided'}
                </Typography>
              ),
            },
          ]
        : [],
    [data],
  )
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid container item xs={12} md={8} spacing={3} direction={'column'}>
          <Grid item>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title='Buyer'
                action={
                  data?.CoApplicantName ? (
                    ''
                  ) : (
                    <Button
                      size='small'
                      color='primary'
                      onClick={() =>
                        navigate(
                          appRoute.LeadsAddEdit.replace(':leadID', leadID as string)
                            .replace(':contactType', 'co-applicant')
                            .replace(':contactID', 'add'),
                        )
                      }
                      startIcon={<Iconify icon='mingcute:add-line' />}
                    >
                      New Buyer
                    </Button>
                  )
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                <BuyerItem
                  variant='outlined'
                  name={data?.ApplicantName}
                  address={data?.ApplicantAddressData?.[0]}
                  phoneNumber={data?.ApplicantPhone}
                  email={data?.Email}
                  buyerStatus={data?.ApplicantStatus}
                  buyerStatusColor={data?.ApplicantStatusColor}
                  action={
                    <IconButton
                      color={popover.open ? 'inherit' : 'default'}
                      onClick={(e) => {
                        popover.onOpen(e)
                        setUserSelected(data.ContactID1)
                      }}
                      sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  }
                  sx={{
                    p: 2.5,
                    borderRadius: 1,
                  }}
                />
                {data?.CoApplicantName && (
                  <BuyerItem
                    variant='outlined'
                    name={data?.CoApplicantName}
                    address={data?.CoApplicantAddressData[0]}
                    phoneNumber={data?.CoApplicantPhone}
                    buyerStatus={data?.CoApplicantStatus}
                    email={data?.CoApplicantEmail}
                    buyerStatusColor={data?.CoApplicantStatusColor}
                    action={
                      <IconButton
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          popover.onOpen(event)

                          setUserSelected(data?.ContactID2)
                          // console.log(buyer.id)
                        }}
                        sx={{ position: 'absolute', top: 8, right: 8 }}
                      >
                        <Iconify icon='eva:more-vertical-fill' />
                      </IconButton>
                    }
                    sx={{
                      p: 2.5,
                      borderRadius: 1,
                    }}
                  />
                )}
              </Stack>
            </Card>
          </Grid>

          <Grid item>
            <Card>
              <CardHeader
                title='References'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      navigate(
                        appRoute.LeadsReferenceAddEdit.replace(':leadID', `${leadID}`)
                          .replace(':contactID', `${data?.ContactID1}`)
                          .replace(':referenceID', 'add'),
                      )
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    Reference
                  </Button>
                }
              />
              <Stack spacing={2.5} sx={{ p: 3 }}>
                {data?.ReferenceData?.map(
                  (reference: {
                    ReferenceID: Key | null | undefined
                    Name: string | undefined
                    address: any
                    PhoneNumber: string | undefined
                    RelationshipType: string | undefined
                    IsVerified: boolean | undefined
                    id: any
                  }) => (
                    <BuyerItem
                      key={reference.ReferenceID}
                      variant='outlined'
                      name={reference.Name}
                      address={reference.address}
                      phoneNumber={reference.PhoneNumber}
                      relationship={reference.RelationshipType}
                      IsVerified={reference.IsVerified}
                      isReference={true}
                      action={
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            console.log(reference.id)
                            setSelectedReference(reference)
                            referencePopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      }
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                      }}
                    />
                  ),
                )}
              </Stack>
            </Card>
          </Grid>

          <Grid item>
            <Card>
              <CardHeader
                title='Stipulations'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={(e) => {
                      setOpenStipulationModal(true)
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    Stipulation
                  </Button>
                }
              />
              <Label color={data?.StipulationStatusColor} sx={{ m: 3, position: 'absolute', top: 3, left: 120 }}>
                {data?.StipulationStatus}
              </Label>
              <Stack spacing={2.5} sx={{ p: 3 }}>
                {data?.StipulationData?.map(
                  (stipulation: {
                    id: Key | null | undefined
                    Stipulation: string | undefined
                    AddedBy: string
                    Buyer: any
                    IsVerified: boolean
                  }) => (
                    <StipulationItem
                      key={stipulation.id}
                      variant='outlined'
                      name={stipulation.Stipulation}
                      addedBy={stipulation.AddedBy}
                      IsVerified={stipulation.IsVerified}
                      type={stipulation.Buyer == 1 ? 'Buyer' : 'Co-Buyer'}
                      stipulationStatus={data.StipulationStatus}
                      action={
                        <IconButton
                          onClick={(event: React.MouseEvent<HTMLElement>) => {
                            setSelectedStipulation(stipulation)
                            stipulationPopover.onOpen(event)
                          }}
                          sx={{ position: 'absolute', top: 8, right: 8 }}
                        >
                          <Iconify icon='eva:more-vertical-fill' />
                        </IconButton>
                      }
                      sx={{
                        p: 2.5,
                        borderRadius: 1,
                      }}
                    />
                  ),
                )}
              </Stack>
            </Card>
          </Grid>
          <Grid item>
            <InterestedInCard />
          </Grid>
          <Grid item>
            {data?.DealData.length > 0 ? (
              <Card>
                <CardHeader
                  title='Deals'
                  // action={
                  //   <Button
                  //     size='small'
                  //     color='primary'
                  //     onClick={(e) => {
                  //       setOpenStipulationModal(true)
                  //     }}
                  //     startIcon={<Iconify icon='mingcute:add-line' />}
                  //   >
                  //     Stipulation
                  //   </Button>
                  // }
                />

                <Stack spacing={2.5} sx={{ p: 3 }}>
                  {data?.DealData?.map(
                    (deal: {
                      id: Key | null | undefined
                      CarDescription: string | undefined
                      DealID: string | undefined
                      StockNumber: string | undefined
                      VIN: string | undefined
                      DealDate: string | undefined
                      SalesPrice: string | undefined
                      CashDown: string | undefined
                      TradeAllowance: string | undefined
                      AmountFinanced: string | undefined
                      DealStatus: string | undefined
                      CanAccessDeal: string | undefined
                    }) => (
                      <DealItem
                        key={deal.id}
                        variant='outlined'
                        CarDescription={deal?.CarDescription}
                        DealID={deal?.DealID}
                        StockNumber={deal?.StockNumber}
                        VIN={deal?.VIN}
                        DealDate={convertDateToDisplay(deal?.DealDate) ?? ''}
                        SalesPrice={formatNumber(deal?.SalesPrice)}
                        CashDown={formatNumber(deal?.CashDown)}
                        TradeAllowance={formatNumber(deal?.TradeAllowance)}
                        AmountFinanced={formatNumber(deal?.AmountFinanced)}
                        DealStatus={deal?.DealStatus}
                        CanAccessDeal={deal?.CanAccessDeal}
                        sx={{
                          p: 2.5,
                          borderRadius: 1,
                        }}
                      />
                    ),
                  )}
                </Stack>
              </Card>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid container item xs={12} md={4} spacing={3} direction={'column'}>
          <Grid item>
            <Card>
              <CardHeader
                title='Settings'
                action={
                  <IconButton onClick={() => setOpenSettingsModal(true)}>
                    <Iconify icon='solar:pen-bold' />
                  </IconButton>
                }
              />
              <Label color={data?.SettingsStatusColor} sx={{ m: 3, position: 'absolute', top: 3, left: 80 }}>
                {data?.SettingsStatus}
              </Label>
              <Box sx={{ p: 3 }} display={'flex'} flexDirection={'column'}>
                {settingsCardData.map(
                  (el) =>
                    el?.value && (
                      <Box display={'flex'} alignItems={'baseline'}>
                        <Typography sx={{ py: 1 }} variant='body2'>
                          {el?.title} :
                        </Typography>

                        {typeof el?.value === 'string' ? (
                          <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                            {el?.value}
                          </Typography>
                        ) : (
                          el?.value
                        )}
                      </Box>
                    ),
                )}
              </Box>
            </Card>
          </Grid>

          <Grid item>
            <Card>
              <CardHeader
                title='Credit History'
                action={
                  <Button
                    size='small'
                    color='primary'
                    onClick={() => {
                      setIsPullCreditModalOpen(true)
                    }}
                    startIcon={<Iconify icon='mingcute:add-line' />}
                  >
                    Credit Report
                  </Button>
                }
              />
              <Box display={'flex'} flexDirection={'column'} sx={{ mb: 3 }}>
                {/* {CREDIT_HISTORY.map((historyItem, index) => (
                <>
                  <Box
                    display={'flex'}
                    sx={{
                      px: 2,
                      borderBottom: (theme) => {
                        return index !== CREDIT_HISTORY.length - 1 ? `dashed 2px ${theme.palette.background.neutral}` : ''
                      },
                    }}
                  >
                    <Box display={'flex'} flexDirection='column' sx={{ p: 1 }}>
                      <Typography variant='body2'>{historyItem.name}</Typography>
                      <Typography sx={{ color: 'text.secondary' }} variant='body2'>
                        {historyItem.date}
                      </Typography>
                    </Box>
                  </Box>
                </>
              ))} */}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {data && (
        <PullCreditModal
          isPullCreditModalOpen={isPullCreditModalOpen}
          setIsPullCreditModalOpen={setIsPullCreditModalOpen}
          bureausData={bureausData}
          dealData={data.DealData}
        />
      )}
      <Dialog open={openSettingsModal} onClose={() => setOpenSettingsModal(false)}>
        <SettingsForm creditAppData={data} closeModal={() => setOpenSettingsModal(false)} />
      </Dialog>
      <AddEditStipulationsFormModal
        open={openStipulationModal}
        onClose={() => {
          setStipulationToEdit(undefined)
          setOpenStipulationModal(false)
          stipulationPopover.onClose()
        }}
        editStipulation={stipulationToEdit}
      />
      <ConfirmDialog
        open={deleteConfirmDialog}
        onOk={handleDelete}
        notificationTitle={'Delete Co-Buyer'}
        handleClose={() => setDeleteConfirmDialog(false)}
        notification={'Co-Buyer will be removed'}
      />

      <ConfirmDialog
        open={referenceDeleteConfirmDialog}
        onOk={handleReferenceDelete}
        handleClose={() => setReferenceDeleteConfirmDialog(false)}
        notificationTitle={'Delete Reference'}
        notification={'Reference will be removed'}
      />

      <ConfirmDialog
        open={stipulationOpenDeleteDialog}
        onOk={handleStipulationDelete}
        handleClose={() => setStipulationOpenDeleteDialog(false)}
        notificationTitle={'Delete Stipulation'}
        notification={'Stipulation will be removed'}
      />
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            navigate(
              appRoute?.LeadsAddEdit.replace(':leadID', leadID as string)
                .replace(':contactID', userSelected?.toString() as string)
                .replace(':contactType', `${userSelected === data?.ContactID2 ? 'co-applicant' : 'applicant'}`),
            )
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        {userSelected === data?.ContactID2 && (
          <MenuItem onClick={() => setDeleteConfirmDialog(true)} sx={{ color: 'error.main' }}>
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        )}
      </CustomPopover>
      <CustomPopover open={referencePopover.open} onClose={referencePopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={() =>
            navigate(
              appRoute.LeadsReferenceAddEdit.replace(':leadID', leadID as string)
                .replace(':contactID', `${data.ContactID1}`)
                .replaceAll(':referenceID', `${seletedReference.ReferenceID}`),
            )
          }
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem onClick={() => setReferenceDeleteConfirmDialog(true)} sx={{ color: 'error.main' }}>
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={stipulationPopover.open} onClose={stipulationPopover.onClose} arrow='top-right' sx={{ width: 140 }}>
        <MenuItem
          onClick={() => {
            if (selectedStipulation) {
              setStipulationToEdit(selectedStipulation)
            }
            setOpenStipulationModal(true)
          }}
        >
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem onClick={() => setStipulationOpenDeleteDialog(true)} sx={{ color: 'error.main' }}>
          <Iconify icon='solar:trash-bin-trash-bold' />
          Delete
        </MenuItem>
      </CustomPopover>
    </FormProvider>
  )
}
