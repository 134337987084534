import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { CustomCircular } from '../../../ui'
import { Form } from 'react-final-form'
import { Header } from './components'
import { withCreditApps, withPermissionsDropdown } from '../../../hoc'
import { Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../constants'
import { CreditAppDropdownsApiResponse, UspGetDataContact, UspGetDataCreditApp } from '../../../models'
import { useDispatch } from 'react-redux'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { NameForm, AddressForm, ContactForm, SettingsForm } from './components'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import { useLazyGetReferencesQuery, useAddReferencesMutation, useEditReferencesMutation, useGetContactMutation } from '../../../services'
import { convertDateToString } from '../../../utils/dateUtils'
import validateForm from '../../../utils/validateForm'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { addLeadsReferenceValidation } from '../../../validation/addLeadsReferenceValidation'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { applyPhoneMask } from '../../../utils/general'

const classes = generateUtilityClasses('form', [
  'root',
  'pageBody',
  'fieldsWrap',
  'formItem',
  'formItemFullHeight',
  'buttonWrap',
  'saveBtn',
  'btnCancelWrap',
])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  [`& .${classes.formItemFullHeight}`]: {
    minHeight: '712px',
    padding: theme.spacing(2, 3, 1, 3),
    borderRadius: 0,
  },
  [`& .${classes.formItem}`]: {
    minHeight: '340px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
  [`& .${classes.btnCancelWrap}`]: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 3, 3, 3),
  },
}))

interface Props {
  creditAppDropdownsData?: CreditAppDropdownsApiResponse
  creditAppDropdownsIsLoading: boolean
}

interface Props {
  creditAppData: UspGetDataCreditApp
  creditAppIsLoading: boolean
}

export const LeadsReferenceAddEdit = ({ creditAppData }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [phoneValues, setPhoneValues] = useState<{}>([])
  const { leadID, contactID, referenceID } = useParams()
  const [formChanged, setFormChanged] = useState(false)

  const [applicantAccountType, setApplicantAccountType] = useState('individual')
  const [getContacts, { data: contactsData, isLoading: getContactsIsLoading }] = useGetContactMutation()
  const [getReferences, { data: referencesData, isLoading: referenceDataIsLoading, isSuccess: referenceDataIsSuccess }] = useLazyGetReferencesQuery()
  const [addReferences, { isSuccess: addReferencesIsSuccess, isError: addReferencesIsError }] = useAddReferencesMutation()
  const [editReferences, { isSuccess: editReferencesIsSuccess, isError: editReferencesIsError }] = useEditReferencesMutation()

  useEffect(() => {
    contactPhoneDefaultValues(referenceID as string)

    if (contactsData?.IsBusiness) {
      setApplicantAccountType('business')
    } else {
      setApplicantAccountType('individual')
    }
  }, [contactsData, referenceID])

  const isLoading = useMemo(() => referenceDataIsLoading || getContactsIsLoading, [referenceDataIsLoading, getContactsIsLoading])
  const handleSubmit = (values: any) => {
    setFormChanged(false)

    const uspContactReferenceAddEdit = {
      contactID: Number(contactID) as number,
      referenceOrder: 1,
      relationshipTypeID: values.RelationshipTypeID,
      referenceContactID: referencesData?.ReferenceContactID,
      note: values?.Note,
      isAllowCall: values?.IsAllowCall,
      isVerified: values?.IsVerified,
      firstName: values?.AccountType === 'individual' ? values?.FirstName?.trim() : null, // Name
      middleName: values?.AccountType === 'individual' ? values?.MiddleName?.trim() : null,
      lastName: values?.AccountType === 'individual' ? values?.LastName?.trim() : null,
      suffix: values?.AccountType === 'individual' ? values?.Suffix : null,
      nickName: values?.AccountType === 'individual' ? values?.NickName?.trim() : null,
      businessName: values?.AccountType === 'business' ? values?.BusinessName?.trim() : null,
      addressID: values?.ContactAddressData ? values?.ContactAddressData[0]?.AddressID : null,
      address1: values?.ContactAddressData ? values?.ContactAddressData[0]?.Address1?.trim() : null,
      address2: values?.ContactAddressData ? values?.ContactAddressData[0]?.Address2?.trim() : null,
      city: values?.ContactAddressData ? values?.ContactAddressData[0]?.City?.trim() : null,
      state: values?.ContactAddressData ? values?.ContactAddressData[0]?.State : null,
      postalCode: values?.ContactAddressData ? values?.ContactAddressData[0]?.PostalCode?.trim() : null,
      county: values?.ContactAddressData ? values?.ContactAddressData[0]?.County?.trim() : null,
      email: values?.Email?.trim(),
      dateOfBirth: values?.AccountType === 'individual' ? convertDateToString(values?.DateOfBirth) : null, // Identity
      jsonPhoneData: [
        {
          // Contact Info
          ContactPhoneID: values?.ContactPhoneID,
          PhoneNumber: values?.PrimaryPhoneNumber,
          PhoneType: values?.PrimaryPhoneType ? values?.PrimaryPhoneType : null,
          PhoneExtension: values?.PrimaryPhoneExtension,
          OrderOfPreference: 1,
          IsPreferred: true,
          IsPrimary: true,
        },
        {
          ContactPhoneID: values?.SecondaryContactPhoneID,
          PhoneNumber: values?.SecondaryPhoneNumber,
          PhoneType: values?.SecondaryPhoneType ? values?.SecondaryPhoneType : null,
          PhoneExtension: values?.SecondaryPhoneExtension,
          OrderOfPreference: 2,
          IsPreferred: false,
          IsPrimary: false,
        },
      ],

      isAllowEmail: true, // TODO NO VALUES ON FRONTEND CHECK IF REQUIRED
      isContactUpdated: true, // TODO FOR LATER OPTIMIZATION
      isPrimaryAddress: true, // TODO NO VALUES ON FRONTEND CHECK IF REQUIRED
      isAddressActive: true, // TODO FOR LATER IMPROVEMENT
      isAddressUpdated: true, // TODO FOR LATER OPTIMIZATION
      isPhoneUpdated: true, // TODO FOR LATER OPTIMIZATION
    }

    if (referenceID === 'add') {
      addReferences({
        uspContactReferenceAddEdit: uspContactReferenceAddEdit,
      })
    } else {
      editReferences({
        referenceId: referenceID as string,
        uspContactReferenceAddEdit: uspContactReferenceAddEdit,
      })
    }
  }

  useEffect(() => {
    if (referenceID !== 'add') {
      getReferences({ referenceId: referenceID?.toString() as string })
    }
    if (referenceDataIsSuccess) {
      getContacts({ contactId: referencesData?.ReferenceContactID?.toString() as string })
    }
  }, [referencesData])

  useEffect(() => {
    if (addReferencesIsSuccess) {
      dispatch(enqueueNotification({ message: 'Reference added successfully.' }))
      // setFormChanged(false)
      navigate(appRoute?.LeadsTask.replace(':leadID', leadID as string))
    }
    if (editReferencesIsSuccess) {
      dispatch(enqueueNotification({ message: 'Reference edit successful.' }))
      // setFormChanged(false)

      navigate(appRoute?.LeadsTask.replace(':leadID', leadID as string))
    }
    if (addReferencesIsError || editReferencesIsError) {
      dispatch(enqueueNotification({ message: 'Reference error.' }))
    }
  }, [addReferencesIsSuccess, editReferencesIsSuccess, editReferencesIsError, addReferencesIsError])

  const contactPhoneDefaultValues = (refID: string) => {
    if (refID === 'add') {
      setPhoneValues([{ PhoneType: 'Cell' }, { PhoneType: 'Home' }])
    } else {
      setPhoneValues([{ PhoneType: 'Cell' }, { PhoneType: 'Home' }])
    }
  }

  const primaryPhone: any = contactsData?.ContactPhoneData?.find((phone) => phone.OrderOfPreference === 1) || {}
  const secondaryPhone: any = contactsData?.ContactPhoneData?.find((phone) => phone.OrderOfPreference === 2) || {}

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={10} spacing={2}>
          <Form
            onSubmit={handleSubmit}
            initialValues={{
              AccountType: applicantAccountType,
              IsVerified: false,
              IsAllowCall: true,
              IsAllowedToCall: true,
              ContactPhoneData: phoneValues,
              ContactPhoneID: primaryPhone?.ContactPhoneID,
              SecondaryContactPhoneID: secondaryPhone?.ContactPhoneID,
              ...contactsData,
              ...referencesData,
              PrimaryPhoneType: primaryPhone.PhoneType || 'Cell',
              PrimaryPhoneNumber: primaryPhone.PhoneNumber,
              PrimaryPhoneExtension: primaryPhone.PhoneExtension,
              SecondaryPhoneType: secondaryPhone.PhoneType || 'Home',
              SecondaryPhoneNumber: secondaryPhone.PhoneNumber,
              SecondaryPhoneExtension: secondaryPhone.PhoneExtension,
            }}
            // validate={validateForm(addLeadsReferenceValidation)}
          >
            {({ handleSubmit, form, dirty }: any) => {
              return (
                <form onSubmit={handleSubmit}>
                  <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
                    {({ isActive, onConfirm, onCancel }) => (
                      <ConfirmDialog
                        open={!!isActive}
                        onOk={onConfirm}
                        handleClose={onCancel}
                        notification={'Warning: Your changes haven’t been saved.'}
                      />
                    )}
                  </ReactRouterPrompt>
                  {/* <Header creditAppData={creditAppData} /> */}
                  <Box display={'flex'}>
                    <CustomBreadcrumbs
                      heading='Reference'
                      links={[
                        { name: 'List', href: '/leads' },
                        {
                          name: `${creditAppData?.ApplicantName} | Cell: ${applyPhoneMask(creditAppData?.ApplicantPhone)} | 
                    ${creditAppData?.ApplicantAddress ? creditAppData?.ApplicantAddress + ' |' : ''} ${
                            creditAppData?.Email ? creditAppData?.Email + ' |' : ''
                          } `,
                        },
                      ]}
                      sx={{
                        mb: { xs: 3, md: 5 },
                      }}
                    />
                    {/* <Label color={creditAppData?.LeadStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
                    {creditAppData?.LeadStatus}
                  </Label> */}
                  </Box>
                  <Card sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <NameForm onDropdownFormChange={() => setFormChanged(true)} />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressForm onDropdownFormChange={() => setFormChanged(true)} />
                      </Grid>
                      <Grid item xs={12}>
                        <ContactForm onDropdownFormChange={() => setFormChanged(true)} />
                      </Grid>
                      <Grid item xs={12}>
                        <SettingsForm onDropdownFormChange={() => setFormChanged(true)} />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        position: 'sticky',
                        bottom: 16,
                        right: 20,
                        p: 3,
                        width: 'fit-content',
                      }}
                    >
                      <Button variant='contained' color='error' onClick={() => navigate(appRoute?.LeadsTask.replace(':leadID', leadID as string))}>
                        Close
                      </Button>
                      <Button
                        type='submit'
                        className={classes.saveBtn}
                        variant='contained'
                        disabled={addReferencesIsSuccess || editReferencesIsSuccess || !formChanged}
                      >
                        Save & Close
                      </Button>
                    </Box>
                  </Card>
                </form>
              )
            }}
          </Form>
        </Grid>
      </Grid>
      <CustomCircular open={isLoading} />
    </Container>
  )
}

export default withPermissionsDropdown(withCreditApps(LeadsReferenceAddEdit))
