import * as yup from 'yup'
import { parseToNumber } from '../utils/general'

export const addDealResultLabels = {
  branch: 'Branch',
  lender: 'Lender',
}

export const addDealResultValidation = (dealType: string | undefined | null): yup.SchemaOf<any> =>
  yup
    .object({
      BranchID: yup.string().required().nullable().label(addDealResultLabels.branch),
      LenderID: yup.string().when([], () => {
        if (dealType === 'Financed') {
          return yup.string().required().nullable().label(addDealResultLabels.lender)
        } else {
          return yup.string().nullable().label(addDealResultLabels.lender)
        }
      }),
      DiscountRate: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : parseToNumber(originalValue)))
        .min(0, 'Discount Rate cannot be less than 0')
        .typeError('Invalid number'),
      DiscountAmount: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : parseToNumber(originalValue)))
        .min(0, 'Discount Amount cannot be less than 0')
        .typeError('Invalid number'),
    })
    .required()
