import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const InventoryForm = ({}: Props) => {
  const { values } = useFormState()

  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Inventory</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessInventory' label='Access Inventory' />
      </Grid>
      {values.AccessInventory && (
        <>
          <Grid>
            <CheckboxField name='AddInventory' label='Add Inventory' />
          </Grid>
          <Grid>
            <CheckboxField name='DeleteInventory' label='Delete Inventory' />
          </Grid>
          <Grid>
            <CheckboxField name='EditInventory' label='Edit Inventory' />
          </Grid>
          <Grid>
            <CheckboxField name='ViewInventoryCost' label='View Inventory Cost' />
          </Grid>
          <Grid>
            <CheckboxField name='ViewPurchasePrice' label='View Purchase Price' />
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

export default InventoryForm
