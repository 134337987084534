export const transformToFormValues = (modalDefaultValues?: any) => {
  return {
    Payment: modalDefaultValues?.Payment || 'Miscellaneous',
    Vendor: modalDefaultValues?.Vendor,
    ChartOfAccountBill: modalDefaultValues?.AccountNumberName,
    ExpenseType: modalDefaultValues?.CarExpenseType,
    IsFloorplan: modalDefaultValues?.IsFloorplan,
    Branch: modalDefaultValues?.BranchCode,
    Amount: modalDefaultValues?.BillAmount?.toString(),
    Note: modalDefaultValues?.Description,
    searchResults: modalDefaultValues?.SearchResult,
  }
}
