import * as Yup from 'yup'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import InputAdornment from '@mui/material/InputAdornment'
import { useRouter, useSearchParams } from '../../routes/hooks'
import { useBoolean } from '../../hooks/use-boolean'
import { RHFCode, RHFTextField } from '../../components/hook-form'
import { appRoute } from '../../constants'
import Iconify from '../../components/iconify'
import signInBg from '../../assets/signIn-bg.png'

import SentIcon from '../../assets/icons/sent-icon'
import FormProvider from '../../components/hook-form/form-provider'
import { RouterLink } from '../../routes/components'
import { AuthTemplate } from '../../components/AuthTemplate'
import { useRequest } from 'ahooks'
import { authChangePassword, authForgotPassword } from '../../services/auth'

// ----------------------------------------------------------------------

export function ChangePassword() {
  const router = useRouter()

  const { loading, runAsync } = useRequest(authChangePassword, {
    manual: true,
  })
  const searchParams = useSearchParams()

  const email = searchParams.get('email')

  const password = useBoolean()

  const VerifySchema = Yup.object().shape({
    code: Yup.string().min(6, 'Code must be at least 6 characters').required('Code is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password')], 'Passwords must match'),
  })

  const defaultValues = {
    code: '',
    email: email || '',
    password: '',
    confirmPassword: '',
  }

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(VerifySchema),
    defaultValues,
  })

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
  } = methods

  const values = watch()

  const onSubmit = handleSubmit(async (data) => {
    try {
      // await newPassword?.(data.email, data.code, data.password)

      await runAsync(data)
      router.push(appRoute.SignIn)
    } catch (error) {
      console.error(error)
    }
  })

  const renderForm = (
    <Stack spacing={3} alignItems='center'>
      <RHFTextField name='email' label='Email' placeholder='example@gmail.com' InputLabelProps={{ shrink: true }} />

      <RHFCode name='code' />

      <RHFTextField
        name='password'
        label='Password'
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={password.onToggle} edge='end'>
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name='confirmPassword'
        label='Confirm New Password'
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={password.onToggle} edge='end'>
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton fullWidth size='large' type='submit' variant='contained' loading={isSubmitting}>
        Update Password
      </LoadingButton>

      <Link
        component={RouterLink}
        href={appRoute.SignIn}
        color='inherit'
        variant='subtitle2'
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon='eva:arrow-ios-back-fill' width={16} />
        Return to sign in
      </Link>
    </Stack>
  )

  const renderHead = (
    <>
      <SentIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ mt: 3, mb: 5 }}>
        <Typography variant='h3'>Request sent successfully!</Typography>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          We&apos;ve sent a 6-digit confirmation email to your email.
          <br />
          Please enter the code in below box to verify your email.
        </Typography>
      </Stack>
    </>
  )

  return (
    <AuthTemplate
      backgroundImg={signInBg}
      title='Reset Password'
      // topRight=More{<Box sx={{ fontSize: '14px', color: theme.palette.text.secondary }}>Don’t have an account? Contact your administrator.</Box>}
    >
      {renderHead}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>
    </AuthTemplate>
  )
}
