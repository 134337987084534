import React, { useEffect, useState } from 'react'
import Header from './components/Header'
import { Box, Button, Card, Container, Grid, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { Form } from 'react-final-form'
import CriteriaCard from './components/CriteriaCard'
import InclusionsCard from './components/InclusionsCard'
import { withPermissionsDropdown } from '../../../hoc'
import { transformToFormValues, transformToRequestValues } from './transform'
import { useCollectionWorklistMutation, useWorklistCreateMutation } from '../../../services'
import { getCurrentDate } from '../../../utils/general'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import validateFormValues from '../../../utils/validateForm'
import { collectionsCreateWorklistValidation } from '../../../validation/collectionsCreateWorklistValidation'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledGrid = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 2, 2),
    display: 'flex',
    alignItems: 'flexStart',
    justifyContent: 'justify-start',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 3, 3, 3),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const CollectionsCreateWorklist = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isPersonalWorklist, setIsPersonalWorklist] = useState(true)
  const [loginWorklist, setLoginWorklist] = useState(true)
  const [initialValues, setInitialValues] = useState({})
  const [addWorklist, { isSuccess }] = useWorklistCreateMutation()
  const [getCollections, { data, isSuccess: isSuccessCollectionWorklist }] = useCollectionWorklistMutation()

  const handleSubmit = (values: any, form: any) => {
    addWorklist({
      uspCollectionWorklistCreate: transformToRequestValues(values),
    })
    form.reset()
  }

  useEffect(() => {
    setInitialValues(transformToFormValues())
  }, [])

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      if (isPersonalWorklist || loginWorklist)
        getCollections({
          uspCollectionWorklistCommon: {
            effectiveDate: getCurrentDate(),
          },
        })
    }
  }, [isSuccess, isPersonalWorklist, loginWorklist])

  useEffect(() => {
    if (isSuccessCollectionWorklist) {
      if (data?.length === 0) {
        dispatch(enqueueNotification({ message: 'No accounts found', options: { variant: 'warning' } }))
      } else {
        navigate(appRoute.CollectionsMyWorklist)
      }
    }
  }, [isSuccessCollectionWorklist, data])

  return (
    <Container>
      <Grid container>
        <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(collectionsCreateWorklistValidation)}>
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              {/* <Header /> */}
              <Box display={'flex'}>
                <CustomBreadcrumbs
                  heading='Collections'
                  links={[{ name: 'Create Worklist' }]}
                  sx={{
                    mb: { xs: 3, md: 5 },
                  }}
                />
                {/* <Label color={creditAppData?.LeadStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
                    {creditAppData?.LeadStatus}
                  </Label> */}
              </Box>
              <Grid item xs={12} md={10}>
                <Box sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
                  <Card>
                    <CriteriaCard setIsPersonalWorklist={setIsPersonalWorklist} setLoginWorklist={setLoginWorklist} />

                    <InclusionsCard />
                  </Card>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      position: 'sticky',
                      bottom: 16,
                      right: 20,
                      p: 3,
                      width: 'fit-content',
                    }}
                  >
                    <Button type='submit' className={classes.saveBtn} variant='contained'>
                      Build Worklist
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </form>
          )}
        </Form>
      </Grid>
    </Container>
  )
}

export default withPermissionsDropdown(CollectionsCreateWorklist)
