import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const CollectionsForm = ({}: Props) => {
  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Collections</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessCollections' label='Access Collections' />
      </Grid>
    </StyledGrid>
  )
}

export default CollectionsForm
