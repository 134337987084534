import * as yup from 'yup'

export const commissionLabels = {
  Salesperson: 'Salesperson',
  CommissionAmount: 'Commission Amount',
}

export const dealCommissionAddEditValidation: yup.SchemaOf<any> = yup
  .object({
    Salesperson: yup.string().label(commissionLabels.Salesperson).required(),
    CommissionAmount: yup.string().label(commissionLabels.CommissionAmount).required(),
  })
  .required()
