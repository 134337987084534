import React from 'react'
import { styled } from '@mui/system'
import { Container, Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import Typography from '@mui/material/Typography'
import { applyPhoneMask } from '../../../../../utils/general'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { UspGetDataCreditApp } from '../../../../../models'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'mainDataWrap'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    minWidth: 200,
  },
  [`& .${classes.mainDataWrap}`]: {
    margin: theme.spacing(2, 0, 0, 0),
  },
}))

interface Props {
  creditAppData: UspGetDataCreditApp
}

export const Header = ({ creditAppData }: Props) => {
  return (
    <StyledContainer className={classes.root}>
      <Grid container justifyContent='space-between'>
        <Grid className={classes.titleWrap}>
          <Typography variant='h6' className={classes.mainDataWrap}>
            {creditAppData?.ApplicantName}
          </Typography>
        </Grid>
        <Grid className={classes.titleWrap}>
          <Typography variant='h6' className={classes.mainDataWrap}>
            {creditAppData?.ApplicantAddress}
          </Typography>
        </Grid>
        <Grid className={classes.titleWrap}>
          <Typography variant='h6' className={classes.mainDataWrap}>
            {applyPhoneMask(creditAppData?.ApplicantPhone)}
          </Typography>
        </Grid>
        <Grid className={classes.titleWrap}>
          <Typography variant='h6' className={classes.mainDataWrap}>
            {creditAppData?.Email}
          </Typography>
        </Grid>
        <Grid className={classes.titleWrap}>
          <Typography variant='h6' className={classes.mainDataWrap}>
            {`App Date: ${convertDateToDisplay(creditAppData?.DateAdded)}`}
          </Typography>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default Header
