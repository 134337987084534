import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import { DateField, PercentField, TextField } from '../../../../../../../ui'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import AmountField from '../../../../../../../ui/AmountField'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'switchWrap', 'optionalText', 'rowBtn', 'printBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.switchWrap}`]: {
    margin: theme.spacing(0, 0, 0, 5),
    padding: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  dealType: string | null | undefined
}

export const ResultForm = ({ dealType }: Props) => {
  const { values } = useFormState()

  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid container>
          <Grid className={classes.row}>
            <Typography variant='subtitle1' gutterBottom>
              Deal Result
            </Typography>
          </Grid>
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='AmountPaymentAmount' label='Payment' fullWidth />
        </Grid>
        {values.FinalPaymentAmount > 0 && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='FinalPaymentAmount' label='Final Payment Amount' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <TextField variant='outlined' disabled name='calculateTerm' label='Term' fullWidth />
          </Grid>
        )}
        {Number(values.Calculate) === 0 && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountFinalPayment' label='Final Payment' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountFinanced' label='Amount Financed' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountFinanceCharges' label='Finance Charges' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='TotalOfPayments' label='Total of Payments' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <PercentField variant='standard' disabled name='APRRate' label='APR' fullWidth />
          </Grid>
        )}
        {(dealType === 'Financed' || dealType === 'Cash') && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountSalesTax' label='Sales Tax' fullWidth />
          </Grid>
        )}
        {dealType === 'Financed' && (
          <Grid className={classes.row}>
            <DateField disabled name='MaturityDate' label='Maturity Date' fullWidth />
          </Grid>
        )}
      </StyledGrid>
    </>
  )
}

export default ResultForm
