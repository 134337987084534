import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { generateUtilityClasses, IconButton } from '@mui/material'
import { DateField } from '../../../../../../../ui'
import Popover from '@mui/material/Popover'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useForm, useFormState } from 'react-final-form'
import { ReactComponent as AddIcon } from '../../../../../../../assets/icons/add.svg'
import { UspListConfigurationFeeResult } from '../../../../../../../models'
import AmountField from '../../../../../../../ui/AmountField'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import removeMask from '../../../../../../../utils/removeMask'
import _uniqueId from 'lodash/uniqueId'
import { convertDateToDisplay, resetTime } from '../../../../../../../utils/dateUtils'
import dayjs from 'dayjs'

const classesBox = generateUtilityClasses('Container', ['root', 'title', 'row', 'btnWrap', 'icon'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '418px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.title}`]: {
    marginBottom: theme.spacing(1),
  },
  [`& .${classesBox.row}`]: {
    marginBottom: theme.spacing(0),
  },
  [`& .${classesBox.btnWrap}`]: {
    justifyContent: 'flex-end',
  },
  [`& .${classesBox.icon}`]: {
    margin: theme.spacing(3, 0),
  },
}))

interface Props {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (value: HTMLButtonElement | null) => void
  listFeeData?: UspListConfigurationFeeResult[]
  paymentsForm: string[]
  computePickupPayments: (values: { [key: string]: any }) => void
  setTotalPickups?: Dispatch<SetStateAction<boolean>>
  formPayments: string[]
  setFormPayments: Dispatch<SetStateAction<string[]>>
  setIsDownPaymentDeleteCalled: Dispatch<SetStateAction<boolean>>
  setPaymentsForm: Dispatch<SetStateAction<string[]>>
}

export const PaymentsPopover = ({
  paymentsForm,
  setPaymentsForm,
  anchorEl,
  setAnchorEl,
  computePickupPayments,
  setTotalPickups,
  formPayments,
  setFormPayments,
  setIsDownPaymentDeleteCalled,
}: Props) => {
  const { values, errors } = useFormState()
  const { change } = useForm()

  const [allPayments, setAllPayments] = useState<string[]>([])
  const [temporaryPayments, setTemporaryPayments] = useState([...formPayments])
  const [permanentPayments, setPermanentPayments] = useState([...formPayments])

  const open = Boolean(anchorEl)

  const getPrevDate = (index: number) => {
    if (index === 0) {
      const minDate = dayjs(values.DealDate).add(1, 'day')
      return minDate.toDate()
    } else {
      if (values[`DuePaymentDate${temporaryPayments[index - 1]}`]) {
        const minDate = dayjs(values[`DuePaymentDate${temporaryPayments[index - 1]}`]).add(1, 'day')
        return minDate.toDate()
      }
    }
  }

  const checkDuePaymentDateErrors = (errors: { [key: string]: any } | undefined): boolean => {
    if (!errors) return false

    for (let key in errors) {
      if (key.startsWith('DuePaymentDate')) {
        return true
      }
    }
    return false
  }

  const handleClose = () => {
    setTemporaryPayments([...permanentPayments])
    setAnchorEl(null)
  }
  const handleSave = () => {
    let amount = 0
    const validIds = new Set(temporaryPayments)

    Object.keys(values)?.forEach((key) => {
      // Extract the ID from keys
      const match = key.match(/(DatePaymentAmount|DuePaymentDate)(\d+)/)
      if (match) {
        const id = match[2]
        // If an ID is not in the valid IDs set remove it from the form state
        if (!validIds?.has(id)) {
          change(`DatePaymentAmount${id}`, undefined)
          change(`DuePaymentDate${id}`, undefined)
        }
      }
    })

    // Save the valid temporary payments to the form state
    temporaryPayments?.forEach((id) => {
      const paymentAmount = values[`DatePaymentAmount${id}`]
      const paymentDate = values[`DuePaymentDate${id}`]

      if (paymentAmount && paymentDate) {
        amount += Number(removeMask(paymentAmount))
      }
    })

    change(`PickupPayments`, amount?.toString())
    setPermanentPayments([...temporaryPayments])
    setFormPayments([...temporaryPayments])
    setPaymentsForm([...temporaryPayments])
    setAnchorEl(null)
  }

  const handleDelete = (id: string) => {
    const updatedPayments = temporaryPayments?.filter((item) => item !== id)
    setTemporaryPayments(updatedPayments)
    setIsDownPaymentDeleteCalled(true)
  }

  const handleAdd = () => {
    setTemporaryPayments([...temporaryPayments, _uniqueId()])
  }

  useEffect(() => {
    setAllPayments([...paymentsForm, ...formPayments])
  }, [paymentsForm, formPayments])

  useEffect(() => {
    const amount = computePickupPayments(values)
    if (amount !== undefined && amount !== null && setTotalPickups) {
      setTotalPickups(amount)
    }
    change(`PickupPayments`, amount)
  }, [allPayments])

  useEffect(() => {
    const combinedPayments = Array?.from(new Set([...paymentsForm, ...formPayments]))
    setTemporaryPayments(combinedPayments)
    setPermanentPayments(combinedPayments)
  }, [paymentsForm, formPayments])

  useEffect(() => {
    if (anchorEl) {
      setTemporaryPayments([...permanentPayments])
    }
  }, [anchorEl, permanentPayments])

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box display='flex' flexDirection='column' gap={2} p={3}>
        <Typography variant='subtitle1' gutterBottom>
          Payments
        </Typography>
        {temporaryPayments?.map((item, index) => (
          <Box display='flex' justifyContent='center' gap={3}>
            <DateField
              validate={(selectedDate) => {
                const prevDate = getPrevDate(index)
                if (prevDate && dayjs(selectedDate).isBefore(dayjs(prevDate))) {
                  return 'Date cannot be less than ' + convertDateToDisplay(prevDate)
                }
              }}
              name={`DuePaymentDate${item}`}
              label='Due Date'
              fullWidth
              minDate={dayjs(getPrevDate(index))}
            />
            <AmountField variant='standard' name={`DatePaymentAmount${item}`} label='Payment Amount' fullWidth />
            <IconButton
              color='error'
              onClick={() => {
                handleDelete(item)
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}
        <Box display='flex' justifyContent='space-between' alignItems='center' mt={3}>
          <IconButton onClick={handleAdd}>
            <AddIcon />
          </IconButton>
          <Button variant='contained' onClick={handleSave} disabled={checkDuePaymentDateErrors(errors)}>
            Save
          </Button>
        </Box>
      </Box>
    </Popover>
  )
}

export default PaymentsPopover
