import * as yup from 'yup'
import { UspCarDealViewResult } from '../models'
import { formatNumber, parseToNumber } from '../utils/general'

export const inventoryAddDepositValidation = (
  carPaymentData: UspCarDealViewResult[],
  checkID: any,
  creditCardID: any,
  selectedPendingRecord: any,
): yup.SchemaOf<any> => {
  let amountValidation = yup
    .number()
    .transform((value, originalValue) => (originalValue ? parseToNumber(originalValue) : 0))
    .required('Amount is required')
    .min(0.01, 'Amount must be greater than 0.00')

  // additional validation if there is a remaining balance
  if (selectedPendingRecord && selectedPendingRecord.Remaining !== null && selectedPendingRecord.Remaining !== undefined) {
    amountValidation = amountValidation.max(
      parseToNumber(selectedPendingRecord.Remaining.toString()),
      `Amount cannot exceed the remaining balance of ${formatNumber(selectedPendingRecord.Remaining.toString())}`,
    )
  }

  let schemaFields = {
    ApplicantAccountType: yup.string().required('Account type is required'),
    ApplicantBusinessName: yup.string().nullable(),
    ApplicantFirstName: yup.string(),
    ApplicantLastName: yup.string(),

    Pending: yup.string().nullable(),
    Amount: amountValidation,
    AmountTendered: yup
      .number()
      .transform((value, originalValue) => (originalValue ? parseToNumber(originalValue) : 0))
      .required()
      .test('is-greater', 'Cannot be less than Total Payment', function (value) {
        const sanitizedValue = value ? parseToNumber(value.toString()) : 0
        const sanitizedTotalPayment = parseToNumber((this.parent.TotalPayment || '0').toString())
        return sanitizedValue >= sanitizedTotalPayment
      }),
    EffectiveDate: yup.date().nullable().notRequired().typeError('Invalid Date'),
    Method: yup.string().required().typeError('Select a valid method'),
    Form: yup.string().required().typeError('Select a valid form'),
    CreditType: yup.string().when('Form', {
      is: (val: string) => val === creditCardID?.toString(),
      then: yup.string().required().typeError('Select a valid credit type').label('Credit Type'),
      otherwise: yup.string().notRequired().nullable(),
    }),
    Last4ofCreditCard: yup.string().when('Form', {
      is: (val: string) => val === creditCardID?.toString(),
      then: yup.string().required('Last 4 numbers of Credit Card is required').min(4, 'Must be exactly 4 digits').max(4, 'Must be exactly 4 digits'),
      otherwise: yup.string().notRequired(),
    }),
    AuthorizationNumber: yup.string().when('Form', {
      is: (val: string) => val === creditCardID?.toString(),
      then: yup.string().required().label('Authorization Number'),
      otherwise: yup.string().notRequired(),
    }),
    CheckType: yup.string().when('Form', {
      is: (val: string) => val === checkID?.toString(),
      then: yup.string().required().typeError('Select a valid check type').label('Check Type'),
      otherwise: yup.string().notRequired(),
    }),
    CheckNumber: yup.string().when('Form', {
      is: (val: string) => val === checkID?.toString(),
      then: yup.string().required().label('Check Number'),
      otherwise: yup.string().notRequired(),
    }),
  }

  // Required 'Pending' field if carPaymentData has records
  if (carPaymentData && carPaymentData?.length > 0) {
    schemaFields.Pending = yup.string().required('Pending is required').typeError('Invalid Input')
  } else {
    // Conditional requirements based on ApplicantAccountType when carPaymentData is empty
    schemaFields.ApplicantBusinessName = yup.string().when('ApplicantAccountType', {
      is: 'business',
      then: yup.string().required('Business name is required').nullable(),
      otherwise: yup.string().notRequired().nullable(),
    })

    schemaFields.ApplicantFirstName = yup.string().when('ApplicantAccountType', {
      is: 'individual',
      then: yup.string().required('First name is required'),
      otherwise: yup.string().notRequired(),
    })

    schemaFields.ApplicantLastName = yup.string().when('ApplicantAccountType', {
      is: 'individual',
      then: yup.string().required('Last name is required'),
      otherwise: yup.string().notRequired(),
    })
  }

  return yup.object().shape(schemaFields)
}
