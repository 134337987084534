import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  generateUtilityClasses,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useForm } from 'react-final-form'
import TextField from '../../../../../../ui/TextField'
import React, { useEffect, useMemo } from 'react'
import { styled } from '@mui/system'
import { useParams } from 'react-router-dom'
import { enqueueNotification } from '../../../../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'
import { CustomCircular } from '../../../../../../ui'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const classesTable = generateUtilityClasses('Grid', ['root', 'tableHead', 'tableCellName', 'buttonWrap', 'saveBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classesTable.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    minHeight: 'calc(100vh - 80px)',
  },
  [`& .${classesTable.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classesTable.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classesTable.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 0, 0, 0),
    marginTop: 'auto',
  },
  [`& .${classesTable.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  formsPromptsData: any
  selectedRows: any
  printForm: (e: any) => void
  formsBilling: (e: any) => void
  printFormIsLoading: boolean
  printFormIsSuccess: boolean
  additionalData: any
}

const PromptsModal = ({
  open,
  onClose,
  formsPromptsData,
  selectedRows,
  formsBilling,
  printForm,
  printFormIsLoading,
  printFormIsSuccess,
  additionalData,
}: Props) => {
  const form = useForm()
  const dispatch = useDispatch()
  const { dealID } = useParams()

  const handleSubmit = () => {
    const formValues = form.getState().values

    // Filter out checked items and prepare data for printFormDeal
    const printFormDeal = selectedRows
      ?.filter((row: any) => formValues[`AmountToPayCheck${row.ID}`]) // Filter out only checked rows
      ?.map((row: any) => {
        const copies = formValues[`Amount${row.ID}`] || 0 // Get the number of copies from the form state

        // Map the prompts for each row
        const prompts = formsPromptsData?.map((item: any, index: any) => ({
          Name: item.Prompt,
          Value: formValues['value' + index] || '', // Retrieve the value from the text field
        }))

        return {
          FormID: row.ID,
          Copies: parseInt(copies, 10),
          Prompts: prompts,
          ...additionalData,
        }
      })

    printForm({
      dealId: dealID?.toString() as string,
      printFormDeal: printFormDeal,
    })
    formsBilling({
      dealId: dealID?.toString() as string,
      uspForBilling: {
        formIDData: selectedRows,
      },
    })
  }

  useEffect(() => {
    if (printFormIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      form.reset()
      onClose()
    }
  }, [printFormIsSuccess])

  const isLoading = useMemo(() => printFormIsLoading, [printFormIsLoading])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Print</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container>
            <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 160px)' }}>
              <Table aria-label='simple table' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classesTable.tableHead}>Description</TableCell>
                    <TableCell className={classesTable.tableHead}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {formsPromptsData?.map((item: any, index: number) => (
                    <TableRow key={item.Prompt} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{item.Prompt}</TableCell>
                      <TableCell>
                        <TextField name={'value' + index} label='' fullWidth />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' type='submit'>
            Next
          </Button>
        </DialogActions>
      </form>

      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default PromptsModal
