import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, CardHeader, Container, Grid, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import Header from './components/Header'
import AdminForm from './components/AdminForm'
import DealsForm from './components/DealsForm'
import { Form } from 'react-final-form'
import { useConfigurationDataMutation, useConfigurationEditDataMutation } from '../../../../services'
import { transformToFormValues, transformToRequestValues } from './transform'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { CustomCircular } from '../../../../ui'
import { validateFormValues } from '../../../../utils/validateForm'
import { adminGlobalSettingsValidation } from '../../../../validation/adminGlobalSettingsValidation'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    minHeight: '800px',
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    position: 'absolute',
    right: '20px',
    bottom: '20px',
    marginLeft: theme.spacing(2),
  },
}))

const GlobalSettings = () => {
  const [initialValues, setInitialValues] = useState({})
  const [formChanged, setFormChanged] = useState(false)
  const dispatch = useDispatch()
  const [configurationData, { data, isLoading: configurationDataIsLoading }] = useConfigurationDataMutation()
  const [configurationEdit, { isSuccess: configurationEditIsSuccess }] = useConfigurationEditDataMutation()

  useEffect(() => {
    configurationData({})
  }, [])

  useEffect(() => {
    if (data) {
      setInitialValues(transformToFormValues(data[0]))
    }
  }, [data])

  const handleSubmit = (values?: any) => {
    configurationEdit({ configurationEdit: transformToRequestValues(values) })
  }

  useEffect(() => {
    if (configurationEditIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setFormChanged(false)
    }
  }, [configurationEditIsSuccess])

  const isLoading = useMemo(() => configurationDataIsLoading, [configurationDataIsLoading])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='Global Settings'
        links={[]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card>
        <Grid container spacing={2} padding={3}>
          <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(adminGlobalSettingsValidation)}>
            {({ handleSubmit }: any) => (
              <StyledForm className={classes.root} onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
                {/* <Header /> */}

                <Grid item xs={12}>
                  <AdminForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <DealsForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>

                <Grid container item justifyContent={'flex-end'}>
                  <Button type='submit' variant='contained' disabled={!formChanged}>
                    Save
                  </Button>
                </Grid>
              </StyledForm>
            )}
          </Form>
        </Grid>
      </Card>
    </Container>
  )
}

export default GlobalSettings
