import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import TextField from '../../../../../ui/TextField'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

const SpecsForm = ({}: Props) => {
  const carDriveTrain = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarDrivetrain'))
  const carFuelType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarFuelType'))
  return (
    <>
      <CardHeader title='Specs' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <TextField disabled name='Engine' label='Engine' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled name='Cylinders' label='Cylinders' xs={1} fullWidth />
        </Grid>

        <Grid item xs={6}>
          <TextField disabled name='Transmission' label='Transmission' xs={1} fullWidth />
        </Grid>
        {carDriveTrain && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              disabled
              name='Drivetrain'
              label='Drivetrain'
              options={carDriveTrain as any}
              optionKey='ID'
              optionValue='Drivetrain'
              autoComplete='off'
            />
          </Grid>
        )}
        {carFuelType && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              disabled
              name='FuelType'
              label='Fuel Type'
              options={carFuelType}
              optionKey='ID'
              optionValue='FuelType'
              autoComplete='off'
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <TextField disabled name='CityMPG' label='City MPG' xs={1} />
        </Grid>

        <Grid item xs={3}>
          <TextField disabled name='HwyMPG' label='Hwy MPG' xs={1} />
        </Grid>
      </Grid>
    </>
  )
}

export default SpecsForm
