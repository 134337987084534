import { Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const GPSForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const { values } = useFormState()

  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          GPS
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='isGPS'
          label='Has GPS?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='isSID'
          label='Has Starter-Interrupt?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      {(values.isGPS || values.isSID) && (
        <>
          <Grid item xs={6}>
            <DropdownFieldSearch disabled={disableFields} name='gpsSidVendor' label='GPS Vendor' options={[]} autoComplete='off' />
          </Grid>
          <Grid item xs={6}>
            <TextField disabled={disableFields} name='gpsSidType' label='Device Type' fullWidth autoComplete='off' />
          </Grid>
          <Grid item xs={6}>
            <TextField disabled={disableFields} name='gpsSidSerialNumber' label='Device Serial #' fullWidth autoComplete='off' />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default GPSForm
