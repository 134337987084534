import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import CenteredModal from '../Modal/CenteredModal'
import { Form } from 'react-final-form'
import validateFormValues from '../../utils/validateForm'
import { markAsLostValidation } from '../../validation/leadsMarkAsLostValidation'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../services'
import DropdownFieldSearch from '../../ui/DropdownFieldSearch'

type Props = {
  isLostModalOpen: boolean
  setIsLostModalOpen: (isOpen: boolean) => void
  handleSubmit: (values: any) => void
  heading: string
}

const MarkAsLostModal = ({ isLostModalOpen, setIsLostModalOpen, handleSubmit, heading }: Props) => {
  const leadLostReasonDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'LeadLostReason'))

  return (
    <Dialog open={isLostModalOpen}>
      <DialogTitle>{heading}</DialogTitle>
      <Form onSubmit={handleSubmit} validate={validateFormValues(markAsLostValidation)}>
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent
              sx={{
                minWidth: 300,
              }}
            >
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  {leadLostReasonDropdown && (
                    <DropdownFieldSearch
                      name='reasonId'
                      label='Lost Reason'
                      options={leadLostReasonDropdown}
                      optionKey='ID'
                      optionValue='LostReason'
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant='contained' color='error' onClick={() => setIsLostModalOpen(false)}>
                Cancel
              </Button>
              <Button type='submit' variant='contained' disabled={!dirty}>
                Save
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default MarkAsLostModal
