import { baseApi } from '../baseApi'
import { AICopilotApiArg, AICopilotApiResponse } from './AIApiTypes'


const AIApi = baseApi.injectEndpoints({
  endpoints: (build) => ({

    sendMessage: build.mutation<AICopilotApiResponse, AICopilotApiArg>({
      query: (queryArg) => ({
        url: `/OpenAI/ai-copilot/`,
        method: 'POST',
        body: queryArg.data,
      }),
    }),
 
  }),
})

export const {
  useSendMessageMutation,
} = AIApi
