import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { UspCarDealViewResult } from '../../../../../models'
import { useForm, useFormState } from 'react-final-form'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  carPaymentData: UspCarDealViewResult[]
  carPaymentDataIsSuccess: boolean
  setSelectedPendingRecord: Dispatch<SetStateAction<any>>
}
const DealsCard = ({ carPaymentData, carPaymentDataIsSuccess, setSelectedPendingRecord }: Props) => {
  const form = useForm()
  const { values } = useFormState()

  useEffect(() => {
    const selectedItem = carPaymentData?.find((item) => item.ContactID === values.Pending)
    if (selectedItem?.ContactID !== null) {
      form.change('ApplicantFirstName', selectedItem?.FirstName)
      form.change('ApplicantMiddleName', selectedItem?.MiddleName)
      form.change('ApplicantLastName', selectedItem?.LastName)
      form.change('ApplicantSuffix', selectedItem?.Suffix)
      form.change('ApplicantNickName', selectedItem?.NickName)
      form.change('ApplicantBusinessName', selectedItem?.BusinessName)
      form.change('DBA', selectedItem?.DBA)
      form.change('DealID', selectedItem?.DealID)
      form.change('Amount', selectedItem?.Remaining)
      setSelectedPendingRecord(selectedItem)
    }
  }, [carPaymentDataIsSuccess, values.Pending])

  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.row}>
        <Typography variant='h3'>Deals</Typography>
      </Grid>
      <Grid className={classes.row}>
        <DropdownFieldSearch name='Pending' label='Pending' options={carPaymentData} optionKey='ContactID' optionValue='Buyer' autoComplete='off' />
      </Grid>
    </StyledGrid>
  )
}

export default DealsCard
