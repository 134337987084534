import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import validateForm from '../../../utils/validateForm'
import { appRoute } from '../../../constants'
import {
  useAddVendorMutation,
  useEditVendorMutation,
  useGetAccountingChartApAccountsMutation,
  useLazyGetActiveCompanyTypeQuery,
  useGetContactMutation,
  useLazyGetVendorQuery,
} from '../../../services'
import { withPermissionsDropdown } from '../../../hoc'
import VendorForm from './components/VendorForm/VendorForm'
import AddressForm from './components/AddressForm/AddressForm'
import ContactForm from './components/ContactForm/ContactForm'
import IdentityForm from './components/IdentityForm/IdentityForm'
import SettingsForm from './components/SettingsForm/SettingsForm'
import SalesForm from './components/SalesForm/SalesForm'
import FinanceForm from './components/FinanceForm/FinanceForm'
import ServiceForm from './components/ServiceForm/ServiceForm'
import Header from './components/Header/Header'
import { transformToFormValues, transformToRequestValues } from './transform'
import { addVendorValidation } from '../../../validation/addVendorValidation'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('Box', ['root', 'firstRow', 'secondRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.firstRow}`]: {
    minHeight: '300px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.secondRow}`]: {
    minHeight: '360px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    position: 'sticky',
    right: '0px',
    bottom: '0px',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const AddEditVendor = () => {
  const { vendorId } = useParams()
  const [initialValues, setInitialValues] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [getVendor, { data: vendorData }] = useLazyGetVendorQuery()
  const [getContact, { data: contactData }] = useGetContactMutation()
  const [addVendor, { isSuccess: isSuccessVendorDataAdd }] = useAddVendorMutation()
  const [editVendor, { isSuccess: isSuccessVendorDataEdit }] = useEditVendorMutation()
  const [accountingAp, { data: accountingApData }] = useGetAccountingChartApAccountsMutation()
  const [getActiveCompanyTypes, { data: activeCompanyTypesData }] = useLazyGetActiveCompanyTypeQuery()

  useEffect(() => {
    if (vendorId !== 'add') {
      getVendor({ vendorId: vendorId as string })
    }
    accountingAp({})
    getActiveCompanyTypes({})
  }, [vendorId])

  useEffect(() => {
    if (vendorData) {
      getContact({ contactId: vendorData?.ContactID.toString() as string })
    }
  }, [vendorData])

  useEffect(() => {
    setInitialValues(transformToFormValues(vendorData, contactData, vendorId))
  }, [vendorData, contactData])

  const handleSubmit = (values: any) => {
    if (vendorId === 'add') {
      addVendor({
        uspVendorAddEdit: transformToRequestValues(values) as any,
      })
    } else {
      editVendor({
        vendorId: vendorId as string,
        uspVendorAddEdit: transformToRequestValues(values) as any,
      })
    }
  }

  useEffect(() => {
    if (isSuccessVendorDataEdit || isSuccessVendorDataAdd) {
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(appRoute.VendorsList)
    }
  }, [isSuccessVendorDataEdit, isSuccessVendorDataAdd])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Vendors'
        links={[
          {
            name: 'List',
            href: appRoute.VendorsList,
          },
          { name: 'Vendor' },
        ]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateForm(addVendorValidation)}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <Card sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <VendorForm />
                </Grid>
                <Grid item xs={12}>
                  <AddressForm />
                </Grid>
                <Grid item xs={12}>
                  <ContactForm />
                </Grid>
                <Grid item xs={12}>
                  <IdentityForm />
                </Grid>
                <Grid item xs={12}>
                  <SettingsForm />
                </Grid>
                {activeCompanyTypesData && activeCompanyTypesData.find((item) => item?.CompanyType?.toLowerCase() === 'sales') && (
                  <Grid item xs={12}>
                    <SalesForm accountingApData={accountingApData} />
                  </Grid>
                )}
                {activeCompanyTypesData && activeCompanyTypesData.find((item) => item?.CompanyType?.toLowerCase() === 'finance') && (
                  <Grid item xs={12}>
                    <FinanceForm accountingApData={accountingApData} />
                  </Grid>
                )}
                {activeCompanyTypesData && activeCompanyTypesData.find((item) => item?.CompanyType?.toLowerCase() === 'service') && (
                  <Grid item xs={12}>
                    <ServiceForm accountingApData={accountingApData} />
                  </Grid>
                )}
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'sticky',
                  bottom: 16,
                  right: 20,
                  gap: 1,
                  p: 3,
                  width: 'fit-content',
                  alignSelf: 'flex-end',
                }}
              >
                <Button variant='contained' color='error' onClick={() => navigate(appRoute?.VendorsList)}>
                  Close
                </Button>
                <Button type='submit' className={classes.saveBtn} variant='contained'>
                  Save & Close
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Form>
      {/* <CustomCircular open={isLoading} /> */}
    </Container>
  )
}

export default withPermissionsDropdown(AddEditVendor)
