import * as yup from 'yup'

export const accountingInsightsPaymentsValidation = (isModalOpen: boolean) => {
  return yup.object({
    ...(isModalOpen
      ? {
          StartDate: yup.string().required().label('Start Date').typeError('Invalid Input'),
          EndDate: yup.string().required().label('End date').typeError('Invalid Input'),
        }
      : {}),
  })
}
