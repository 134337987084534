import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const NoteForm = ({}: Props) => {
  return (
    <StyledGrid className={classes.root}>
      <Grid className={classes.row}>
        <TextField name='Note' label='Comments' fullWidth multiline autoComplete='off' />
      </Grid>
    </StyledGrid>
  )
}

export default NoteForm
