import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField, PhoneTextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { getPhoneTypes, getStates } from '../../../../../redux/slices/userDropdownSlice'
import { useForm, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useLazyGetInfoByZipCodeQuery } from '../../../../../services'
import AddressField from '../../../../../ui/AddressField'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const CurrentEmployerNameForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const { values } = useFormState()
  const states = useSelector(getStates)
  const form = useForm()
  const phoneTypes = useSelector(getPhoneTypes)
  const addressValue = form.getFieldState('EmploymentCurrentAddress1')?.value || ''
  const [getInfoByZipCode, { data: infoByZipCodeData }] = useLazyGetInfoByZipCodeQuery()

  useEffect(() => {
    if (addressValue) {
      const { street, city, state, zip } = addressValue
      if (zip) {
        getInfoByZipCode({ zipCode: zip?.toString() as string })
      }

      // Only update the form fields if the parsed values are not null
      if (street) form.change('EmploymentCurrentAddress1', street)
      if (city) form.change('EmploymentCurrentCity', city)
      if (state) form.change('EmploymentCurrentState', state)
      if (zip) form.change('EmploymentCurrentPostalCode', zip)
      if (infoByZipCodeData) {
        form.change('EmploymentCurrentCounty', infoByZipCodeData?.County)
      }
    }
  }, [addressValue, form, infoByZipCodeData])

  return (
    <>
      <CardHeader title='Current Employer (optional)' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentCurrentName' label='Name' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentCurrentOccupation' label='Occupation' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <AddressField disabled={disableFields} name='EmploymentCurrentAddress1' label='Address Line 1' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentCurrentAddress2' label='Address Line 2' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField disabled={disableFields} name='EmploymentCurrentCity' label='City' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='EmploymentCurrentState'
            label='State'
            options={states}
            optionKey='State'
            optionValue='State'
            autoComplete='off'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField disabled={disableFields} name='EmploymentCurrentPostalCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={3}>
          <TextField disabled={disableFields} name='EmploymentCurrentCounty' label='County' options={states} fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='EmploymentCurrentPhoneType'
            label='Phone Type'
            options={phoneTypes}
            optionKey='PhoneType'
            optionValue='PhoneType'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <PhoneTextField disabled={disableFields} name='EmploymentCurrentPhoneNumber' label='Phone' fullWidth />
        </Grid>
        {values?.EmploymentCurrentPhoneType === 'Biz' && (
          <>
            <Grid item xs={6}>
              <TextField disabled={disableFields} name='EmploymentCurrentPhoneExtension' label='Ext' fullWidth />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextField disabled={disableFields} name='EmploymentCurrentWorkEmail' label='Work Email' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default CurrentEmployerNameForm
