import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { UspGetDataDealTaskResult } from '../../models'
import { upsert } from '../../utils/general'

type DealsState = {
  deals?: Array<UspGetDataDealTaskResult>
}

export const slice = createSlice({
  name: 'deals',
  initialState: {
    deals: [],
  } as DealsState,
  reducers: {
    setDeals: (state, { payload }) => {
      upsert(state.deals, payload, 'DealID')
    },
  },
})
export const getDeals =
  (dealId: string | undefined): any =>
  (state: RootState) => {
    if (dealId) {
      return state?.deals?.deals?.find((deals: UspGetDataDealTaskResult) => String(deals?.DealID) === String(dealId))
    }
    return undefined
  }
export const { setDeals } = slice.actions

export default slice.reducer
