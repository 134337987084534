import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { CreditAppDropdownsApiResponse, UspDealCommissionViewResult } from '../../../../../models'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/system'
import { useParams } from 'react-router-dom'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import { visuallyHidden } from '@mui/utils'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { useDeleteDealCommissionMutation } from '../../../../../services'
import DealsCommissionsAddEdit from '../DealsCommissionsAddEdit/DealsCommissionsAddEdit'

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'loading',
  'spinner',
  'tableHead',
  'tableCellName',
  'telegramIconActive',
  'telegramIconInactive',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.telegramIconActive}`]: {
    color: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

interface Props {
  //   commissionsRef: (arg: { contactId: string }) => void
  commissionsData?: UspDealCommissionViewResult[]
  loading: boolean
  onSuccessCommissionDeal: () => void
  onSuccessEditCommissionDeal: () => void
  onDeleteCommissionSuccess: () => void
}

const DealsCommissionsTableBlock = ({
  commissionsData,
  loading,
  onSuccessCommissionDeal,
  onSuccessEditCommissionDeal,
  onDeleteCommissionSuccess,
}: Props) => {
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ commissionId: string } | undefined>(undefined)
  const [deleteCommissions, { isSuccess: deleteCommissionsIsSuccess }] = useDeleteDealCommissionMutation()
  const initialOrder = (storageGet('deals_commissions_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('deals_commissions_by') || 'Salesperson') as keyof UspDealCommissionViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspDealCommissionViewResult>(initialOrderBy)
  const { dealID } = useParams()
  const [rowData, setRowData] = React.useState({})
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspDealCommissionViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('deals_commissions_order', isAsc ? 'desc' : 'asc')
    storageSet('deals_commissions_by', property)
  }

  const createSortHandler = (property: keyof UspDealCommissionViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleDelete = () => {
    if (deleteConfirmDialog?.commissionId) {
      deleteCommissions({ dealId: dealID as string, commissionId: deleteConfirmDialog?.commissionId as string })
      setDeleteConfirmDialog(undefined)
    }
  }

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>, rowData: UspDealCommissionViewResult) => {
    setRowData(rowData)
    setAnchorEl(event?.currentTarget)
  }

  useEffect(() => {
    if (deleteCommissionsIsSuccess) {
      onDeleteCommissionSuccess()
    }
  }, [deleteCommissionsIsSuccess])

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }} className={classes.root}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Salesperson' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Salesperson'}
                    direction={orderBy === 'Salesperson' ? order : 'asc'}
                    onClick={createSortHandler('Salesperson')}
                  >
                    Salesperson
                    {orderBy === 'Salesperson' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Username' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Username'}
                    direction={orderBy === 'Username' ? order : 'asc'}
                    onClick={createSortHandler('Username')}
                  >
                    Username
                    {orderBy === 'Username' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'UserCode' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'UserCode'}
                    direction={orderBy === 'UserCode' ? order : 'asc'}
                    onClick={createSortHandler('UserCode')}
                  >
                    User Code
                    {orderBy === 'UserCode' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'CommissionAmount' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'CommissionAmount'}
                    direction={orderBy === 'CommissionAmount' ? order : 'asc'}
                    onClick={createSortHandler('CommissionAmount')}
                  >
                    Commission
                    {orderBy === 'CommissionAmount' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} align={'right'}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), commissionsData as any[])?.map((item: any) => (
                <TableRow key={item.CommissionID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className={classes.tableCellName} onClick={(e: any) => handleEdit(e, item)}>
                    {item.Salesperson}
                  </TableCell>
                  <TableCell>{item?.Username}</TableCell>
                  <TableCell>{item?.UserCode}</TableCell>
                  <TableCell>{item?.CommissionAmount}</TableCell>
                  <TableCell align={'right'}>
                    <Tooltip title='Edit'>
                      <IconButton color='primary' onClick={(e) => handleEdit(e, item)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <IconButton
                        color='error'
                        onClick={() => {
                          setDeleteConfirmDialog({ commissionId: item?.CommissionID?.toString() as string })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {commissionsData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
      <DealsCommissionsAddEdit
        rowData={rowData}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        type='edit'
        onSuccessCommissionDeal={onSuccessCommissionDeal}
        onSuccessEditCommissionDeal={onSuccessEditCommissionDeal}
      />
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Commission will be removed.'}
      />
    </>
  )
}

export default DealsCommissionsTableBlock
