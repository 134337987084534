import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { SelectInventory, SelectCredit, DealStructure, DealResult } from './subpages'
import { useEditDealMutation, useLazyGetCreditAppQuery, useLazyGetDealQuery } from '../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import { CustomCircular } from '../../../ui'
import { UspGetDataDealEntryResult } from '../../../models'
import _uniqueId from 'lodash/uniqueId'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { getCreditAppID } from '../../../redux/slices/creditAppSlice'
import { getSingleCar } from '../../../redux/slices/carsSlice'

interface Props {}

export const DealsAddEdit = ({}: Props) => {
  const initialId = _uniqueId()
  const [isDownPaymentDeleteCalled, setIsDownPaymentDeleteCalled] = useState<boolean>(false)
  const [paymentsForm, setPaymentsForm] = useState<any[]>([])
  const [formPayments, setFormPayments] = useState<string[]>([])
  const creditAppID = useSelector(getCreditAppID)
  const carSelectedData = useSelector(getSingleCar)

  const [dealType, setDealType] = useState<string | undefined | null>(undefined)
  const [totalPickups, setTotalPickups] = useState<string | number>('')
  const [stage, setCurrentStage] = useState<'inventory' | 'credit' | 'dealStructure' | 'dealResult' | null>('dealStructure')
  const [carSelected, setSelectedCar] = useState<any>(undefined)
  const [creditSelected, setSelectedCreditApp] = useState<any>(undefined)
  const [dealStructureFormValues, setDealStructureFormValues] = useState<any>(undefined)
  const [getDeal, { data: dealData, isSuccess: dealDataIsSuccess, isLoading: dealDataIsLoading }] = useLazyGetDealQuery()
  const [getCreditApp, { data: creditAppData, isSuccess: creditAppIsSuccess, isLoading: creditAppIsLoading }] = useLazyGetCreditAppQuery()
  const [editDeal, { isSuccess: editDealIsSuccess }] = useEditDealMutation()
  const { dealID } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const userAccess = useSelector(getUserAccessRights)
  const disableFields = !userAccess?.CanEditDeal && dealID !== 'add'

  useEffect(() => {
    if (dealID !== 'add') {
      getDeal({ dealId: dealID as string })
    }
    if (dealID === 'add') {
      setPaymentsForm([initialId])
      // setCurrentStage('inventory')
    }
  }, [dealID, editDealIsSuccess])

  useEffect(() => {
    if (dealData?.IsDeleted) {
      dispatch(enqueueNotification({ message: 'Deal has been deleted', options: { variant: 'warning' } }))
      navigate(appRoute?.DealsList)
    }
    if (dealData?.IsFinalized) {
      dispatch(enqueueNotification({ message: 'Deal is finalized and cannot be edited.', options: { variant: 'warning' } }))
      navigate(appRoute?.DealsList)
    }
  }, [dealData])

  useEffect(() => {
    if (dealDataIsSuccess) {
      if (dealData?.CarID !== null) {
        setSelectedCar({
          CarID: dealData?.CarID,
          StockNumber: dealData?.StockNumber,
          Vehicle: dealData?.Vehicle,
          VIN: dealData?.FullVIN,
          Mileage: null,
          CarStatus: null,
          BranchCode: null,
          IsAvailable: true,
        })
        // setCurrentStage('credit')
      }
      if (dealData?.CreditAppID || dealData?.ContactID1) {
        setSelectedCreditApp({
          CreditAppID: dealData?.CreditAppID,
          Applicant: dealData?.Buyer, // TODO CHECK
          ContactID1: dealData?.ContactID1,
          ContactID2: dealData?.ContactID2,
        })
      }

      // if (dealData?.CarID !== null && (dealData?.CreditAppID !== null || dealData?.ContactID1 !== null)) {
      //   setCurrentStage('dealStructure')
      // }
    }
  }, [dealDataIsSuccess])

  useEffect(() => {
    if (state?.redirectedFrom === 'LeadsSelectCreditApp' && creditAppID) {
      setSelectedCar(carSelectedData)
      setCurrentStage('dealStructure')
      getCreditApp({ creditAppId: creditAppID as string })
    }
    console.log('creditAppData', creditAppData)
    if (creditAppIsSuccess) {
      setSelectedCreditApp(creditAppData)
    }
  }, [state, creditAppID, creditAppIsSuccess, creditAppData, carSelectedData])

  useEffect(() => {
    if (!userAccess.CanAddDeal && dealID === 'add') {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  const isLoading = useMemo(() => dealDataIsLoading, [dealDataIsLoading])
  // if (stage === 'inventory') {
  return (
    <>
      {stage === 'inventory' && (
        <SelectInventory dealData={dealData as UspGetDataDealEntryResult} setSelectedCar={setSelectedCar} setCurrentStage={setCurrentStage} />
      )}
      {stage === 'credit' && (
        <SelectCredit
          dealData={dealData as UspGetDataDealEntryResult}
          setSelectedCreditApp={setSelectedCreditApp}
          setCurrentStage={setCurrentStage}
        />
      )}
      <div style={{ display: stage === 'dealStructure' || stage === 'dealResult' ? 'block' : 'none' }}>
        <DealStructure
          disableFields={disableFields}
          isDownPaymentDeleteCalled={isDownPaymentDeleteCalled}
          setIsDownPaymentDeleteCalled={setIsDownPaymentDeleteCalled}
          paymentsForm={paymentsForm}
          setPaymentsForm={setPaymentsForm}
          formPayments={formPayments}
          setFormPayments={setFormPayments}
          dealData={dealData}
          carSelected={carSelected}
          creditSelected={creditSelected}
          setCurrentStage={setCurrentStage}
          setSelectedCar={setSelectedCar}
          setSelectedCreditApp={setSelectedCreditApp}
          setTotalPickups={setTotalPickups}
          dealStructureFormValues={dealStructureFormValues}
          setDealStructureFormValues={setDealStructureFormValues}
          setDealType={setDealType}
          dealType={dealType}
          stage={stage}
          totalPickups={totalPickups}
        />
      </div>

      <CustomCircular open={isLoading} />
    </>
  )
}

export default DealsAddEdit
