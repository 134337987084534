import React, { useState } from 'react'
import { styled } from '@mui/system'
import { Button, generateUtilityClasses, Grid, Tooltip } from '@mui/material'
import { UspAccountingLedgerViewResult } from '../../../../../models'
import FilterModal from '../FilterModal/FilterModal'
import { Form } from 'react-final-form'
import { convertDateToDisplay, convertDateToString } from '../../../../../utils/dateUtils'
import { getCurrentDate, getEarlierDate, parseToNumber } from '../../../../../utils/general'
import removeMask from '../../../../../utils/removeMask'
import validateFormValues from '../../../../../utils/validateForm'
import { accountingLedgerDetailsValidation } from '../../../../../validation/accountingLedgerDetailsFilterValidation'
import { ReactComponent as PrintIcon } from '../../../../../assets/icons/printer.svg'
import { ReactComponent as FilterIcon } from '../../../../../assets/icons/filter_icon.svg'
import { ReactComponent as DownloadIcon } from '../../../../../assets/icons/download_icon.svg'
import * as XLSX from 'xlsx'

const classes = generateUtilityClasses('Grid', ['root', 'exportBtnWrap', 'btnWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
  [`& .${classes.exportBtnWrap}`]: {
    margin: theme.spacing(0, 0, 0, 'auto'),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
  },
  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
}))

interface Props {
  accountingLedgerData?: UspAccountingLedgerViewResult[]
  getAccountingLedgers: (e: any) => void
  isSuccess: boolean
}

export const Details = ({ getAccountingLedgers, accountingLedgerData }: Props) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  const handleSubmit = (values?: any) => {
    let jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    let jsonAccountList = values?.chartOfAccount?.map((item: any) => ({ ChartOfAccountID: item?.ChartOfAccountID }))

    let startDate = convertDateToString(values?.startDate)
    let endDate = convertDateToString(values?.endDate)

    if (values?.dateRange === 'today') {
      startDate = getCurrentDate() || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'yesterday') {
      startDate = getEarlierDate(1) || null
      endDate = getEarlierDate(1) || null
    } else if (values?.dateRange === 'lastSevenDays') {
      startDate = getEarlierDate(7) || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'lastThirtyDays') {
      startDate = getEarlierDate(30) || null
      endDate = getCurrentDate() || null
    }
    getAccountingLedgers({
      uspAccountingLedgerView: {
        startDate: startDate || null,
        endDate: endDate || null,
        jsonBranchList: jsonBranchList || null,
        jsonAccountList: jsonAccountList || null,
        startAmount: removeMask(values?.AmountStart),
        endAmount: removeMask(values?.AmountEnd),
        stockNumberVIN: values?.StockNumber,
        filterByUserCode: values?.User,
      },
    })
    handleClose()
  }
  const transformDataForExport = (data: UspAccountingLedgerViewResult[]) => {
    return data?.map((item: UspAccountingLedgerViewResult) => ({
      Transaction: item.TransactionNumber,
      Date: convertDateToDisplay(item.AccountingDate),
      User: item.UserCode,
      Branch: item.BranchCode,
      Account: item.AccountName,
      Amount: parseToNumber(item.TransactionAmount),
      Name: item.Name,
      'Check/Bill #': item.BillNumber,
      'Stock #': item.StockNumber,
      Description: item.TransactionDescription,
      Note: item.Note,
    }))
  }

  const setColumnWidths = (worksheet: any) => {
    const columnWidths = [
      { width: 10 }, // Transaction
      { width: 20 }, // Accounting Date
      { width: 10 }, // User Code
      { width: 10 }, // Branch Code
      { width: 35 }, // Account Name
      { width: 15 }, // Transaction Amount
      { width: 25 }, // Name
      { width: 15 }, // Bill Number
      { width: 10 }, // Stock Number
      { width: 15 }, // Transaction Description
      { width: 15 }, // Note
    ]

    worksheet['!cols'] = columnWidths
  }

  const exportToExcel = (data: any, fileName: any) => {
    const transformedData = transformDataForExport(data)
    const worksheet = XLSX.utils.json_to_sheet(transformedData)
    setColumnWidths(worksheet)

    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
    XLSX.writeFile(workbook, `${fileName}.xlsx`)
  }

  return (
    <>
      <Form onSubmit={handleSubmit} validate={validateFormValues(accountingLedgerDetailsValidation)}>
        {({ handleSubmit, form }: any) => (
          <form
            onSubmit={(e) => {
              e.preventDefault()
              handleSubmit()
              form.reset()
            }}
          >
            <Grid container p={3} spacing={2} alignItems={'center'} justifyContent={'flex-end'}>
              <Grid item>
                <Tooltip title='Download'>
                  <Button
                    variant='text'
                    type='button'
                    className={classes.btnWrap}
                    onClick={() => exportToExcel(accountingLedgerData, 'Ledger Details')}
                  >
                    <DownloadIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title='Print'>
                  <Button variant='text' type='button' className={classes.btnWrap}>
                    <PrintIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title='Filter'>
                  <Button variant='text' type='button' onClick={() => setOpen(true)} className={classes.btnWrap}>
                    <FilterIcon />
                  </Button>
                </Tooltip>
              </Grid>
              <FilterModal open={open} handleClose={handleClose} />
            </Grid>
          </form>
        )}
      </Form>
    </>
  )
}

export default Details
