import React, { useEffect, useState } from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { useAddDealsFlagMutation, useLazyContactsReferenceGetQuery, useWorklistNextAccountMutation } from '../../../services'
import { withAccounts } from '../../../hoc/withAccounts'
import { AccountsOverviewResult, UspDealFlagViewResult } from '../../../models'
import { styled } from '@mui/material/styles'
import Header from '../Header/Header'
import AccountsReferenceTableBlock from './components/AccountsReferenceTableBlock'
import { useDispatch, useSelector } from 'react-redux'
import { getWorklistAccountID, getWorklistID } from '../../../redux/slices/collectionsSlice'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Grid', ['root', 'btnWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.btnWrap}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 3, 0, 3),
  },
}))

interface Props {
  accountsData: AccountsOverviewResult
}

const AccountsReferences = ({ accountsData }: Props) => {
  const [contactsReference, { data: contactsReferenceData, isLoading: contactsReferenceLoading }] = useLazyContactsReferenceGetQuery()
  const worklistAccountID = useSelector(getWorklistAccountID)
  const worklistID = useSelector(getWorklistID)
  const [nextAccount, { data: nextAccountData, isSuccess: nextAccountIsSuccess }] = useWorklistNextAccountMutation()
  const userAccess = useSelector(getUserAccessRights)
  const disableEditIcons = userAccess?.CanEditAccount
  const [isAddEditFlagModalOpen, setIsAddEditFlagModalOpen] = useState(false)
  const [addAccountFlags, { isSuccess: addAccountFlagIsSuccess, isLoading: addAccountFlagsIsLoading }] = useAddDealsFlagMutation()
  const [selectedFlag, setSelectedFlag] = useState<UspDealFlagViewResult | undefined>()

  const dispatch = useDispatch()

  const handleNextAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: true,
        },
      })
    }
  }
  const handlePreviousAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: false,
        },
      })
    }
  }

  useEffect(() => {
    if (accountsData?.ContactID1) contactsReference({ contactId: accountsData?.ContactID1?.toString() as string })
  }, [accountsData?.ContactID1])

  useEffect(() => {
    if (addAccountFlagIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setIsAddEditFlagModalOpen(false)
    }
  }, [addAccountFlagIsSuccess])

  return (
    <StyledGrid className={classes.root}>
      <Header
        disableEditIcons={disableEditIcons}
        model={accountsData?.CarDescription}
        vin={accountsData?.VIN}
        stockNumber={accountsData?.StockNumber}
        buyerName={accountsData?.BuyerName}
        coBuyerName={accountsData?.CoBuyerName}
        buyerPhone={accountsData?.BuyerPhoneData}
        buyerWorkPhone={accountsData?.BuyerWorkPhone}
        coBuyerPhone={accountsData?.CoBuyerPhoneData}
        buyerAddress={accountsData?.BuyerAddressData}
        coBuyerAddress={accountsData?.CoBuyerAddressData}
        contactID1={accountsData?.ContactID1}
        contactID2={accountsData?.ContactID2}
        branchID={accountsData?.BranchID}
        isAccountActive={accountsData?.IsAccountActive}
        isDeficiency={accountsData?.IsDeficiency}
        worklistAccountID={worklistAccountID}
        worklistID={worklistID}
        nextAccountData={nextAccountData}
        nextAccountIsSuccess={nextAccountIsSuccess}
        handleNextAccount={handleNextAccount}
        handlePreviousAccount={handlePreviousAccount}
        coBuyerWorkPhone={accountsData?.CoBuyerWorkPhone}
        isAddEditFlagModalOpen={isAddEditFlagModalOpen}
        setIsAddEditFlagModalOpen={setIsAddEditFlagModalOpen}
        addAccountFlags={addAccountFlags}
        selectedFlag={selectedFlag}
        setSelectedFlag={setSelectedFlag}
      />
      <Grid className={classes.btnWrap}>
        <Typography variant='h3'>References</Typography>
      </Grid>
      <AccountsReferenceTableBlock
        contactID={accountsData?.ContactID1}
        contactsReference={contactsReference}
        loading={contactsReferenceLoading}
        contactsReferenceData={contactsReferenceData}
      />
    </StyledGrid>
  )
}

export default withAccounts(AccountsReferences)
