import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import { NameForm, AddressForm, ContactForm, IdentityForm } from '../../components'
import { useFormState } from 'react-final-form'
import { checkIfObjectContainKeysWithPrefix } from '../../../../../utils/general'
import { useGetContactMutation, useSearchContactMutation } from '../../../../../services'
import { UspGetDataContact } from '../../../../../models'
import { transformToFormValues } from '../../transform'

interface Props {
  setErrorsInTabs: (value: any) => void
  onDropdownFormChange: () => void
  setInitFormValues: React.Dispatch<React.SetStateAction<any>>
  setDisableFields: React.Dispatch<React.SetStateAction<boolean>>
  disableFields: boolean
  linked: boolean
  setValue: any
  contactID?: string
}

export const Applicant = ({
  setErrorsInTabs,
  onDropdownFormChange,
  disableFields,
  setValue,
  setInitFormValues,
  setDisableFields,
  linked,
  contactID,
}: Props) => {
  const { errors, submitFailed, values } = useFormState()

  const [getContact, { isSuccess: getContactIsSuccess, data: getContactData }] = useGetContactMutation()
  const [searchConract, { isSuccess: searchConractIsSuccess, data: searchConractData }] = useSearchContactMutation()
  useEffect(() => {
    if (contactID == 'add') {
      if (values?.ApplicantAccountType == 'individual') {
        if (
          (values?.ApplicantFirstName && values?.ApplicantLastName) ||
          values?.ApplicantPhoneNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '').length == 10 ||
          values?.ApplicantEmail?.includes('@')
        )
          searchConract({
            uspContactSearch: {
              firstName: values?.ApplicantFirstName,
              lastName: values?.ApplicantLastName,
              businessName: null,
              dateOfBirth: values?.ApplicantDateOfBirth,
              last4TaxIDNumber: values?.ApplicantTaxIDNumber?.replaceAll('-', ''),
              email: values?.ApplicantEmail?.includes('@') ? values?.ApplicantEmail : null,
              phoneNumber:
                values?.ApplicantPhoneNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '').length == 10
                  ? values?.ApplicantPhoneNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '')
                  : null,
            },
          })
      } else {
        searchConract({
          uspContactSearch: {
            firstName: null,
            lastName: null,
            businessName: values?.ApplicantBusinessName,
            dateOfBirth: values?.ApplicantDateOfBirth,
            last4TaxIDNumber: values?.ApplicantTaxIDNumber?.replaceAll('-', ''),
            email: values?.ApplicantEmail?.includes('@') ? values?.ApplicantEmail : null,
            phoneNumber:
              values?.ApplicantPhoneNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '').length == 10
                ? values?.ApplicantPhoneNumber?.replaceAll('(', '')?.replaceAll(')', '')?.replaceAll('-', '')?.replaceAll(' ', '')
                : null,
          },
        })
      }
    }
  }, [values])

  function setValues(obj: any) {
    console.log(obj)
    getContact({ contactId: obj.ContactID as any })
  }

  useEffect(() => {
    if (getContactIsSuccess) {
      setInitFormValues(transformToFormValues(getContactData as unknown as UspGetDataContact))
      setDisableFields(true)
    }
  }, [getContactIsSuccess])

  function unlinkContact() {
    setValue('ContactId', undefined)
    setValue('ApplicantAddressID', undefined)
    setValue('EmploymentCurrentEmployerID', undefined)
    setDisableFields(false)
  }
  useEffect(() => {
    setErrorsInTabs((prev: any) => ({ ...prev, applicant: submitFailed && checkIfObjectContainKeysWithPrefix(errors, 'Applicant') }))
  }, [errors, submitFailed])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <NameForm
          linked={linked}
          setValue={setValues}
          searchConractData={searchConractData}
          disableFields={disableFields}
          unlinkContact={unlinkContact}
          onDropdownFormChange={onDropdownFormChange}
        />
      </Grid>
      <Grid item xs={12}>
        <AddressForm searchConractData={searchConractData} disableFields={disableFields} onDropdownFormChange={onDropdownFormChange} />
      </Grid>
      <Grid item xs={12}>
        <ContactForm
          setValue={setValues}
          searchConractData={searchConractData}
          disableFields={disableFields}
          onDropdownFormChange={onDropdownFormChange}
        />
      </Grid>
      <Grid item xs={12}>
        <IdentityForm
          setValue={setValues}
          searchConractData={searchConractData}
          disableFields={disableFields}
          onDropdownFormChange={onDropdownFormChange}
        />
      </Grid>
    </Grid>
  )
}

export default Applicant
