import utcPlugin from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import moment from 'moment'

dayjs.extend(utcPlugin)

export const convertDateToString = (value?: string | null): string | null => {
  return value ? dayjs(value)?.format('YYYY-MM-DD') : null
}

export const convertStringToDate = (value?: string | null): string | null => {
  return value ? dayjs(value)?.format('YYYY-MM-DD') : null
}

export const convertDateToDisplay = (value?: string | null | Date): string | null => {
  return value ? dayjs(value)?.format('MM/DD/YYYY') : null
}

export const convertDateTimeToDisplay = (value?: string | null): string | null => {
  return value ? dayjs(value).format('MM/DD/YYYY h:mm A') : null
}

export const utcToLocalDateTime = (value: string) => {
  return dayjs.utc(value).local().format('MM/DD/YY h:mm A')
}

export const formatDate = (inputDate: string): string => {
  const date = moment(inputDate)
  return date.isValid() ? date.format('YYYY-MM-DD') : 'Invalid Date'
}

export const formatDateTime = (inputDate: string): string => {
  const date = moment(inputDate)
  return date.isValid() ? date.format('YYYY-MM-DD  h:mm A') : 'Invalid Date'
}

export const combineDateAndTime = (date: string, time: string) => {
  if (!date || !time) return null

  const datePart = dayjs(date).format('YYYY-MM-DD')
  const timePart = dayjs(time).format('HH:mm:ss')

  return dayjs(`${datePart}T${timePart}`).toISOString()
}

export function formatTime(timeString: string, format = 'hh:mm A') {
  return dayjs(timeString, format).toDate()
}

export const resetTime = (date: Date) => {
  date.setHours(0, 0, 0, 0)
  return date
}

export function converToUtc(value?: string) {
  return value ? dayjs(value).utc().format() : null
}
