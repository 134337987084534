import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { TextField } from '../../../../../../../../../ui'
import DropdownFieldSearch from '../../../../../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../../../../../services'
const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

const SpecsForm = ({}: Props) => {
  const carDriveTrain = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarDrivetrain'))
  const carFuelType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarFuelType'))

  return (
    <>
      <CardHeader title='Specs' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <TextField name='Engine' label='Engine' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth name='Cylinders' label='Cylinders' />
        </Grid>
        <Grid item xs={6}>
          <TextField fullWidth name='Transmission' label='Transmission' />
        </Grid>
        {carDriveTrain && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='Drivetrain'
              label='Drivetrain'
              options={carDriveTrain}
              optionKey='ID'
              optionValue='Drivetrain'
              autoComplete='off'
            />
          </Grid>
        )}

        {carFuelType && (
          <Grid item xs={6}>
            <DropdownFieldSearch name='FuelType' label='Fuel Type' options={carFuelType} optionKey='ID' optionValue='FuelType' autoComplete='off' />
          </Grid>
        )}

        <Grid item xs={3}>
          <TextField fullWidth name='CityMPG' label='City MPG' />
        </Grid>

        <Grid item xs={3}>
          <TextField fullWidth name='HwyMPG' label='Hwy MPG' />
        </Grid>
      </Grid>
    </>
  )
}

export default SpecsForm
