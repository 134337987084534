import React, { useEffect, useState } from 'react'
import { InventorySearchBlock } from './components/InventorySearchBlock'
import { Form } from 'react-final-form'
import { useDashboardInventoryMutation, useSearchCarMutation } from '../../../services'
import { withPermissionsDropdown } from '../../../hoc'
import { SearchCarApiResponse } from '../../../models'
import { Card, Grid, IconButton, Paper, generateUtilityClasses, useTheme } from '@mui/material'
import { formatNumberNoDecimal, getCurrentDate } from '../../../utils/general'
import { Chart } from 'react-google-charts'
import { styled } from '@mui/system'
import InventoryAgingCard from './components/InventoryAgingCard/InventoryAgingCard'
import InventoryCardOne from './components/InventoryCardOne/InventoryCardOne'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import InventoryCardTwo from './components/InventoryCardTwo/InventoryCardTwo'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { motion } from 'framer-motion'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
  },
}))

interface DashboardDataItem {
  DashboardName: string
  Data: string
}

export const InventoryList = () => {
  const theme = useTheme()
  const [initialLoad, setInitialLoad] = useState(true)
  const [selectedMake, setSelectedMake] = useState(null)
  const [currentChart, setCurrentChart] = useState('MakeAnalysis')

  const [searchCars, { data: searchCarsData, isLoading: searchCarsIsLoading }] = useSearchCarMutation()
  const [dashboardDataRequest, { data: dashboardData }] = useDashboardInventoryMutation()

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false)
    }

    dashboardDataRequest({
      uspDashboardCommonResult: {
        effectiveDate: getCurrentDate(),
      },
    })
  }, [])

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    searchCars({
      uspCarSearch: {
        searchString: values?.searchString || null,
        jsonBranchList: jsonBranchList,
        isIncludeSoldCars: values?.isIncludeSoldCars,
        isPendingTradeOnly: values?.isPendingTradeOnly,
      },
    })
  }
  const legendMapping = {
    MakeAnalysis: 'Make',
    VendorAnalysis: 'Vendor',
    CarStatusAnalysis: 'CarStatus',
    PurchaseTypeAnalysis: 'PurchaseType',
    BodyTypeAnalysis: 'BodyType',
  }

  const mappings = {
    MakeAnalysis: {
      value: 'Units',
      title: 'Make',
    },
    VendorAnalysis: {
      value: 'Units',
      title: 'Vendor',
    },
    CarStatusAnalysis: {
      value: 'Units',
      title: 'Car Status',
    },
    PurchaseTypeAnalysis: {
      value: 'Units',
      title: 'Purchase Type',
    },
    BodyTypeAnalysis: {
      value: 'Units',
      title: 'Body Type',
    },
  }

  const allowedLegends = new Set(['Make', 'Vendor', 'CarStatus', 'PurchaseType', 'BodyType'])

  const parseAndTransformData = (dashboardData: DashboardDataItem[]) => {
    const isKeyOfLegendMapping = (key: string): key is keyof typeof legendMapping => {
      return key in legendMapping
    }

    return dashboardData
      ?.filter((item) => isKeyOfLegendMapping(item.DashboardName) && allowedLegends?.has(legendMapping[item.DashboardName]))
      ?.map((item) => {
        try {
          const parsedData = JSON?.parse(item.Data)
          if (!Array?.isArray(parsedData) || parsedData?.length === 0) {
            // Handle non-array parsedData
            return null
          }

          const mapping = mappings[item.DashboardName as keyof typeof mappings]
          const legendLabel = legendMapping[item.DashboardName as keyof typeof legendMapping] || 'Unknown'
          const valueLabel = mapping?.value || 'Units'
          const title = mapping?.title || item.DashboardName.replace('Data', '')

          const transformedData = [
            ['Label', 'Value'],
            ...parsedData.map((dataItem: any) => [dataItem[legendLabel]?.toString() || 'Unknown', Number(dataItem[valueLabel]) || 0]),
          ]

          return {
            data: transformedData,
            title: title,
          }
        } catch (error) {
          console.error('Error parsing data:', error)
          // Handle parsing errors
          return {
            data: [['Label', 'Value']],
            title: item.DashboardName,
          }
        }
      })
      ?.filter(Boolean) // Filter out null values
  }

  const parseGeneralData = (dashboardData: DashboardDataItem[]) => {
    const data = dashboardData?.find((item) => item?.DashboardName === 'GeneralData')
    if (data) {
      return JSON?.parse(data?.Data as string)
    }
  }

  const handleChartClick = (chartWrapper: any) => {
    const chart = chartWrapper.getChart()
    const selection = chart.getSelection()
    if (selection?.length === 0) return
    const selectedRow = selection[0].row
    if (selectedRow === null) return

    const dataTable = chartWrapper.getDataTable()
    const selectedMake = dataTable.getValue(selectedRow, 0)
    if (selectedMake && currentChart === 'MakeAnalysis') {
      setSelectedMake(selectedMake)
      setCurrentChart('ModelAnalysis')
    }
  }

  const handleBackArrowClick = () => {
    setCurrentChart('MakeAnalysis')
    setSelectedMake(null)
  }

  const getModelChartData = (make: any) => {
    const modelData = dashboardData?.find((item) => item?.DashboardName === 'ModelAnalysis')
    if (modelData) {
      const parsedData = JSON.parse(modelData?.Data)
      const filteredData = parsedData?.filter((dataItem: any) => dataItem?.Make === make)
      return [['Label', 'Value'], ...filteredData?.map((dataItem: any) => [dataItem['Model'].toString(), Number(dataItem['Units']) || 0])]
    }
  }

  const generalData = parseGeneralData(dashboardData as DashboardDataItem[])
  const chartData = parseAndTransformData(dashboardData as DashboardDataItem[])
  const modelChartData = currentChart === 'ModelAnalysis' ? getModelChartData(selectedMake) : null

  const renderOtherCharts = () => {
    return chartData
      ?.filter((chart) => chart && chart?.title !== 'Make') // Filter out the "Makes" chart
      ?.map((chart, index) => (
        <Grid item xs={12} md={12} lg={6} xl={3} key={index}>
          <motion.div
            className='box'
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.8,
              delay: 0.5,
              ease: [0, 0.71, 0.2, 1.01],
            }}
          >
            <Card className={classes.formItem} key={index}>
              <Chart
                chartType='PieChart'
                data={chart?.data}
                options={{
                  title: chart?.title,
                  titleTextStyle: {
                    fontSize: 15,
                    color: theme.palette?.text?.primary,
                  },
                  legend: {
                    pagingTextStyle: {
                      color: theme.palette?.text?.primary,
                    },
                    scrollArrows: {
                      activeColor: theme.palette?.text?.primary,
                    },
                    position: 'right',
                    textStyle: { color: theme.palette?.text?.primary },
                  },
                  chartArea: { width: '95%', height: '80%' },
                  backgroundColor: theme.palette.background.paper,
                }}
                width={'100%'}
                height={'350px'}
              />
            </Card>
          </motion.div>
        </Grid>
      ))
  }

  const renderCharts = () => {
    // If the current chart is "ModelAnalysis" and model chart data is available render the "Model" chart
    if (currentChart === 'ModelAnalysis' && modelChartData) {
      return (
        <>
          <Grid item xs={12} md={12} lg={6} xl={3}>
            <motion.div
              className='box'
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <Card className={classes.formItem}>
                <IconButton onClick={handleBackArrowClick}>
                  <ArrowBackIcon />
                </IconButton>
                <Chart
                  chartType='PieChart'
                  data={modelChartData}
                  options={{
                    title: selectedMake as any,
                    titleTextStyle: {
                      fontSize: 15,
                      color: theme.palette?.text?.primary,
                    },
                    legend: {
                      pagingTextStyle: {
                        color: theme.palette?.text?.primary,
                      },
                      scrollArrows: {
                        activeColor: theme.palette?.text?.primary,
                      },
                      position: 'right',
                      textStyle: { color: theme.palette?.text?.primary },
                    },
                    chartArea: { width: '95%', height: '80%' },
                    backgroundColor: theme.palette.background.paper,
                  }}
                  width={'100%'}
                  height={'350px'}
                />
              </Card>
            </motion.div>
          </Grid>

          {renderOtherCharts()}
        </>
      )
    } else {
      // If the current chart is not "ModelAnalysis" render the "Make" chart and the other charts
      const makeChart = chartData?.find((chart) => chart?.title === 'Make')
      if (!makeChart) return null

      return (
        <>
          <Grid item xs={12} md={12} lg={6} xl={3}>
            <motion.div
              className='box'
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                duration: 0.8,
                delay: 0.5,
                ease: [0, 0.71, 0.2, 1.01],
              }}
            >
              <Card className={classes.formItem}>
                <Chart
                  chartType='PieChart'
                  data={makeChart.data}
                  options={{
                    title: makeChart.title,
                    titleTextStyle: {
                      fontSize: 15,
                      color: theme.palette?.text?.primary,
                    },
                    legend: {
                      pagingTextStyle: {
                        color: theme.palette?.text?.primary,
                      },
                      scrollArrows: {
                        activeColor: theme.palette?.text?.primary,
                      },
                      position: 'right',
                      textStyle: { color: theme.palette?.text?.primary },
                    },
                    chartArea: { width: '95%', height: '80%' },
                    backgroundColor: theme.palette.background.paper,
                  }}
                  width={'100%'}
                  height={'350px'}
                  chartEvents={[
                    {
                      eventName: 'select',
                      callback: ({ chartWrapper }) => handleChartClick(chartWrapper),
                    },
                  ]}
                />
              </Card>
            </motion.div>
          </Grid>
          {renderOtherCharts()}
        </>
      )
    }
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={{ searchString: null }}>
      {({ handleSubmit, form: { submit } }: any) => (
        <StyledForm onSubmit={handleSubmit} className={classes.root}>
          <InventorySearchBlock
            initialLoad={initialLoad}
            loading={searchCarsIsLoading}
            searchCarsData={searchCarsData as SearchCarApiResponse}
            submit={submit}
          />

          <Grid container rowSpacing={1} columnSpacing={1} className={classes.pageBody}>
            {generalData?.map((item: any) => (
              <>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <InventoryCardOne
                    subTitle='Total Units'
                    title={item?.Units !== null ? item?.Units : '0'}
                    icon={<InsertChartIcon />}
                    color={theme.palette.action.active}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <InventoryCardOne
                    subTitle='Available for Sale'
                    title={item?.ReadyForSale !== null ? item?.ReadyForSale : '0'}
                    icon={<CheckIcon />}
                    color={theme.palette.action.active}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <InventoryCardOne
                    subTitle='Not Available'
                    title={item?.NotReadyForSale !== null ? item?.NotReadyForSale : '0'}
                    icon={<ClearIcon />}
                    color={theme.palette.action.active}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                  <InventoryCardOne
                    subTitle='Average Age'
                    title={item?.AverageDaysInInventory !== null ? item?.AverageDaysInInventory + ' Days' : '0 Days'}
                    icon={<CalendarMonthIcon />}
                    color={theme.palette.action.active}
                  />
                </Grid>
              </>
            ))}

            {generalData?.map((item: any) => (
              <>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <InventoryCardTwo
                    title={'$ ' + formatNumberNoDecimal(item?.AveragePurchasePrice?.toString() as string)}
                    subTitle='Average Purchase'
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <InventoryCardTwo title={'$ ' + formatNumberNoDecimal(item?.AverageRecon?.toString() as string)} subTitle='Average Recon' />
                </Grid>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <InventoryCardTwo title={'$ ' + formatNumberNoDecimal(item?.AverageCost?.toString() as string)} subTitle='Average Cost' />
                </Grid>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <InventoryCardTwo title={'$ ' + formatNumberNoDecimal(item?.AverageRetailPrice?.toString() as string)} subTitle='Average Retail' />
                </Grid>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <InventoryCardTwo
                    title={'$ ' + formatNumberNoDecimal(item?.AverageProfitPotential?.toString() as string)}
                    subTitle='Average Profit Potential'
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={2} xl={2}>
                  <InventoryCardTwo title={item?.AverageMarkupRate !== null ? item?.AverageMarkupRate + '%' : '0 %'} subTitle='Average Markup' />
                </Grid>
              </>
            ))}
            {dashboardData && (
              <Grid item xs={12} md={12} lg={12} xl={12}>
                <InventoryAgingCard dashboardData={dashboardData || []} />
              </Grid>
            )}
            {renderCharts()}
          </Grid>
        </StyledForm>
      )}
    </Form>
  )
}

export default withPermissionsDropdown(InventoryList)
