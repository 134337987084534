import React from 'react'
import { Field } from 'react-final-form'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'

import { MenuItem, Select } from '@mui/material'

interface Props {
  id?: string
  name: string
  label: string
  labelId?: string
  optionKey: string
  optionValue: string
  inputProps?: any
  options: any
  onChange?: any
  fullWidth?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  [x: string]: any
}

export const TextField = ({
  id,
  name,
  label,
  labelId,
  options = [],
  inputProps,
  optionKey = 'key',
  optionValue = 'value',
  fullWidth,
  required,
  onChange,
  ...props
}: Props) => {
  return (
    <Field name={name}>
      {({ input }) => (
        <FormControl fullWidth={fullWidth} required={required}>
          <InputLabel id='client-helper'>{label}</InputLabel>
          <Select
            labelId={labelId}
            id={id}
            label={label}
            inputProps={inputProps}
            {...input}
            {...props}
            name={name}
            onChange={(e) => {
              if (onChange) {
                const formValue = e?.target?.value
                onChange(formValue)
              }
              input.onChange(e)
            }}
          >
            {options?.map((item: any) => (
              <MenuItem key={`key_${item[optionKey]}`} value={item[optionKey]}>
                {item[optionValue]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Field>
  )
}

export default TextField
