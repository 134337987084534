import React from 'react'
import TextField from '@mui/material/TextField'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import { Field } from 'react-final-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'
import { formatDate } from '../../utils/dateUtils'
import { FormHelperText } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'

interface Props {
  name: string
  label: string
  fullWidth?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  disableFuture?: boolean
  inputFormat?: string
  defaultValue?: string
  required?: boolean
  disableManualUserInput?: boolean
  onDateChange?: () => void
  onDropdownFormChange?: () => void
  validate?: (value: any) => any
  helperText?: string
  [x: string]: any
}
const DateField = ({
  name,
  label,
  onDropdownFormChange,
  fullWidth,
  inputFormat = 'MM/DD/YYYY',
  disableFuture,
  defaultValue,
  disablePast,
  onDateChange,
  variant,
  required,
  disableManualUserInput,
  validate,
  helperText,
  ...props
}: Props) => {
  const fetchCurrentDate = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = new Date()
    const systemDate = date?.toDateString()
    // const data = await fetch('https://worldtimeapi.org/api/timezone/' + tz).then((response) => response.json())
    // if (data) {
    // return data?.datetime
    // } else {
    return systemDate
    // }
  }
  const isInvalidDate = (date: dayjs.Dayjs | null) => {
    if (!date) {
      return false
    }
    const now = dayjs().endOf('day')
    if (disablePast && date.isBefore(now, 'day')) {
      return true
    }
    if (disableFuture && date.isAfter(now, 'day')) {
      return true
    }
    return false
  }

  return (
    <Field name={name} validate={validate} defaultValue={defaultValue}>
      {({ input, meta: { error, touched } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(date) => {
                input.onChange(date?.toString())
                input.onBlur()
                if (!isInvalidDate(date as any)) {
                  if (onDropdownFormChange) {
                    onDropdownFormChange()
                  }

                  // Call the provided onDateChange prop if disableManualUserInput is passed in
                  // so user can manually select a date from datepicker
                  if (onDateChange) {
                    onDateChange()
                  }
                }
              }}
              label={label}
              // inputFormat={inputFormat}
              disableFuture={disableFuture}
              disablePast={disablePast}
              value={input?.value.length > 0 ? dayjs(input?.value) : null}
              sx={{ width: '100%' }}
              slotProps={{
                textField: {
                  helperText: helperText,
                  error: !!helperText,
                },
              }}
              {...props}

              // renderInput={(params) => (
              //   <TextField
              //     FormHelperTextProps={{
              //       style: {
              //         position: 'absolute',
              //         bottom: '-20px',
              //       },
              //     }}
              //     onBlur={(e) => {
              //       const inputValue = e?.target?.value
              //       if (!inputValue && !error) {
              //         input.onChange(null)
              //       } else {
              //         input.onChange(formatDate(inputValue))
              //       }
              //       input.onBlur()
              //     }}
              //     fullWidth={fullWidth}
              //     required={required}
              //     variant={variant}
              //     {...params}
              //     error={touched && !!error}
              //     autoComplete='off'
              //     inputProps={{
              //       ...params.inputProps,
              //       readOnly: disableManualUserInput ? true : undefined,
              //     }}
              //   />
              // )}
              // OpenPickerButtonProps={{ tabIndex: -1 }}
            />
            {touched && error && (
              <FormHelperText id={`${name}-helper-text`} error={touched && error ? true : false}>
                {error}
              </FormHelperText>
            )}
          </LocalizationProvider>
        )
      }}
    </Field>
  )
}

export default DateField
