import React, { useState } from 'react'
import { DealsListSearchBlock } from './components/DealsListSearchBlock'
import { DealsTableBlock } from './components/DealsListTableBlock'
import { Form } from 'react-final-form'
import { useSearchDealsMutation } from '../../../services'
import { withPermissionsDropdown } from '../../../hoc'
import { getCurrentDate, getEarlierDate } from '../../../utils/general'
import { convertDateToString } from '../../../utils/dateUtils'
import validateFormValues from '../../../utils/validateForm'
import { dealsSearchValidation } from '../../../validation/dealsSearchValidation'
import { Button, Card, Container, Grid, Popover } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs'
import Iconify from '../../../components/iconify'
import { generatePath, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { appRoute } from '../../../constants'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

interface Props {}

export const DealsList = ({}: Props) => {
  const [searchDeals, { data: searchDealsData, isLoading: searchDealsIsLoading }] = useSearchDealsMutation()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID })) || []
    let startDate = convertDateToString(values?.startDate)
    let endDate = convertDateToString(values?.endDate)

    if (values?.dateRange === 'today') {
      startDate = getCurrentDate() || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'yesterday') {
      startDate = getEarlierDate(1) || null
      endDate = getEarlierDate(1) || null
    } else if (values?.dateRange === 'lastSevenDays') {
      startDate = getEarlierDate(7) || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'lastThirtyDays') {
      startDate = getEarlierDate(30) || null
      endDate = getCurrentDate() || null
    }
    if (startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
      searchDeals({
        uspDealSearch: {
          searchString: values?.searchString,
          startDate: startDate || null,
          endDate: endDate || null,
          jsonBranchList: jsonBranchList || [],
          isIncludeDeadDeals: values?.isIncludeDeadDeals,
        },
      })
    }
  }

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Deals'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          userAccess?.CanAddDeal && userAccess?.IsLeasing ? (
            <>
              {' '}
              <Button
                // sx={{ position: 'absolute', right: 30 }}
                aria-describedby='add-menu-popover'
                variant='contained'
                onClick={(e) => setAnchorEl(e.currentTarget)}
                // endIcon={<KeyboardArrowDownIcon />}
                startIcon={<Iconify icon='mingcute:add-line' />}
              >
                Add New
              </Button>
              <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                  <Button
                    style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                    onClick={() => navigate(appRoute.DealsAddEdit.replace(':dealID', 'add'))}
                  >
                    Retail
                  </Button>

                  <Button
                    style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                    onClick={() => navigate(appRoute.DealsAddEditLease.replace(':dealID', 'add'))}
                  >
                    Lease
                  </Button>
                </Grid>
              </Popover>
            </>
          ) : (
            <Button
              onClick={() => navigate(appRoute.DealsAddEdit.replace(':dealID', 'add'))}
              variant='contained'
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Add new
            </Button>
          )
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          // flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            searchString: null,
            dateRange: '',
          }} // require to avoid first debounce search
          validate={validateFormValues(dealsSearchValidation)}
        >
          {({ handleSubmit, form: { submit } }: any) => (
            <form onSubmit={handleSubmit}>
              <DealsListSearchBlock submit={submit} />
              <DealsTableBlock loading={searchDealsIsLoading} searchDealsData={searchDealsData} />
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default withPermissionsDropdown(DealsList)
