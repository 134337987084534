import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const CreditAppsForm = ({}: Props) => {
  const { values } = useFormState()
  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Leads/Credit Apps</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessCreditApps' label='Access Leads/Credit Apps' />
      </Grid>
      {values.AccessCreditApps && (
        <>
          <Grid>
            <CheckboxField name='AddCreditApps' label='Add Leads/Credit Apps' />
          </Grid>
          <Grid>
            <CheckboxField name='DeleteCreditApps' label='Delete Leads/Credit Apps' />
          </Grid>
          <Grid>
            <CheckboxField name='EditCreditApps' label='Edit Leads/Credit Apps' />
          </Grid>
          <Grid>
            <CheckboxField name='PullCredit' label='Pull Credit' />
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

export default CreditAppsForm
