import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { AmountField, CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  useLazyCarPackFeeDataQuery,
  useAddCarPackFeeMutation,
  useEditCarPackFeeMutation,
  useGetAccountingChartOfAccountCurrentMutation,
} from '../../../../services'
import { UspAccountingChartOfAccountCurrentView, UspConfigurationCarPackFeeViewResult } from '../../../../models/apiTypes'
import removeMask from '../../../../utils/removeMask'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationCarPackFeeViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}

const AddEditInventoryPackFeesModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addCarPackFee, { isLoading: isLoadingCarPackFee, isSuccess: isSuccessCarPackFee }] = useAddCarPackFeeMutation()
  const [editCarPackFee, { isLoading: isLoadingEditCarPackFee, isSuccess: isSuccessEditCarPackFee }] = useEditCarPackFeeMutation()
  const [getCarPackFeeData, { isLoading: isLoadingGetCarPackFee, isSuccess: isSuccessGetCarPackFee, data }] = useLazyCarPackFeeDataQuery()
  const [searchChartOfAccounts, { data: chartData, isSuccess: isSuccessSearchChartOfAccount, isLoading: isLoadingSearchChartOfAccounts }] =
    useGetAccountingChartOfAccountCurrentMutation()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editCarPackFee({
        carPackFeeId: rowData?.ID?.toString(),
        uspConfigurationCarPackFeeAddEdit: {
          carPackFeeID: 1, // TODO: CHECK ID
          feeDescription: values.Description,
          isActive: values.IsActive,
          chartOfAccountID: values?.ChartOfAccount,
          feeForPurchase: removeMask(values?.FeeForNewPurchases) as string,
          feeForTrade: removeMask(values?.FeeForTrades) as string,
          feeForRepo: removeMask(values?.FeeForReposessions) as string,
        },
      })
    } else {
      addCarPackFee({
        uspConfigurationCarPackFeeAddEdit: {
          carPackFeeID: 1,
          feeDescription: values.Description,
          isActive: values.IsActive,
          chartOfAccountID: values?.ChartOfAccount,
          feeForPurchase: removeMask(values?.FeeForNewPurchases) as string,
          feeForTrade: removeMask(values?.FeeForTrades) as string,
          feeForRepo: removeMask(values?.FeeForReposessions) as string,
        },
      })
    }
  }

  useEffect(() => {
    if (open && type === 'add') {
      searchChartOfAccounts({
        uspChartOfAccountView: {
          currentID: null,
          isAllowedSelectControlledAccount: false,
          isIncludeAccountsThatCannotBeSelected: false,
          isReturnAllCompanyTypes: false,
        },
      })
    }
  }, [open, type])

  useEffect(() => {
    if (open && type === 'edit') {
      searchChartOfAccounts({
        uspChartOfAccountView: {
          currentID: data?.ChartOfAccountID as number,
          isAllowedSelectControlledAccount: false,
          isIncludeAccountsThatCannotBeSelected: false,
          isReturnAllCompanyTypes: false,
        },
      })
    }
  }, [data])

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getCarPackFeeData({
        carPackFeeId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
      })
    }
    if (isSuccessGetCarPackFee && type === 'edit') {
      setInitialValues({
        Description: data?.FeeDescription,
        IsActive: data?.IsActive,
        FeeForNewPurchases: data?.FeeForPurchase,
        FeeForTrades: data?.FeeForTrade,
        FeeForReposessions: data?.FeeForRepo,
        ChartOfAccount: data?.ChartOfAccountID,
      })
    }
  }, [isSuccessGetCarPackFee, data, type, isSuccessSearchChartOfAccount])

  useEffect(() => {
    if (isSuccessCarPackFee || isSuccessEditCarPackFee) {
      onClose()
      submit()
    }
  }, [isSuccessCarPackFee, isSuccessEditCarPackFee])

  const isLoading = useMemo(
    () => isLoadingCarPackFee || isLoadingEditCarPackFee || isLoadingGetCarPackFee || isLoadingSearchChartOfAccounts,
    [isLoadingCarPackFee, isLoadingEditCarPackFee, isLoadingGetCarPackFee, isLoadingSearchChartOfAccounts],
  )
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Pack Fee</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='ChartOfAccount'
                    label='Chart of Account (Offset)'
                    options={chartData as UspAccountingChartOfAccountCurrentView[]}
                    optionKey='ChartOfAccountID'
                    optionValue='AccountNumberName'
                  />
                </Grid>
                <Grid item xs={12}>
                  <AmountField variant='standard' name='FeeForNewPurchases' label='Fee For New Purchases ($)' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <AmountField variant='standard' name='FeeForTrades' label='Fee For Trades ($)' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <AmountField variant='standard' name='FeeForReposessions' label='Fee For Repossessions ($)' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditInventoryPackFeesModal
