import { Box, CardHeader, generateUtilityClasses, Grid, IconButton, Paper, Popover, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import React, { useEffect, useState } from 'react'
import { UspPaymentViewDetailResult } from '../../../../../models'
import PayHistoryRecordTable from '../PayHistoryRecordTable/PayHistoryRecordTable'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import { convertDateTimeToDisplay, convertDateToDisplay } from '../../../../../utils/dateUtils'
import { formatNumber } from '../../../../../utils/general'
import { useAccountsPaymentVoidMutation } from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import ConfirmDialog from '../../../../../components/ConfirmDialog'

const gridTemplateColumns = 'repeat(8, 1fr)'
const gridTemplateColumnsSub = 'repeat(6, 2fr)'
const gridTemplateColumnsNote = 'repeat(1, auto-fill)'

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'recordHeader',
  'recordSubHeader',
  'noteSection',
  'titleWrap',
  'fieldNameWrap',
  'fieldContainerWrapper',
  'recordSubHeaderNote',
])

const StyledPaper = styled(Paper)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '20px',
    margin: theme.spacing(3, 2, 3, 2),
    overflow: 'auto',
    scrollbarColor: 'red',
    overflowY: 'auto',
    listStyle: 'none',
    height: '100%',
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid rgba(0,0,0,0.00)',
    },
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: '#F7685B',
    borderRadius: '4px',
  },

  [`& .${classes.recordHeader}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    justifyContent: 'space-between',
    width: '100%',
  },

  [`& .${classes.recordSubHeader}`]: {
    display: 'flex',
    gap: '30px',
  },
  [`& .${classes.noteSection}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    width: '100%',
  },

  [`& .${classes.titleWrap}`]: {
    fontSize: '17px',
    width: '150px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  [`& .${classes.fieldNameWrap}`]: {
    color: theme.palette.text.disabled,
    marginRight: '10px',
  },

  [`& .${classes.fieldContainerWrapper}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`& .${classes.recordHeader},& .${classes.noteSection}`]: {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumns,
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  [` & .${classes.recordSubHeader}`]: {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumnsSub,
    alignItems: 'center',
    gap: theme.spacing(2),
  },

  [` & .${classes.recordSubHeaderNote}`]: {
    display: 'grid',
    gridTemplateColumns: gridTemplateColumnsNote,
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}))

interface Props {
  recordData: UspPaymentViewDetailResult
  expandHistoryRecords: boolean
  branchID: number
  dealID: number
  getPaymentsDetails: (e: any) => void
  includeVoidedPayments: boolean
}

type actionType = 'Chargeback/NSF' | 'Void/Reverse'

const PayHistoryRecord = ({ recordData, expandHistoryRecords, dealID, branchID, getPaymentsDetails, includeVoidedPayments }: Props) => {
  const [paymentVoid, { isSuccess: paymentVoidIsSuccess, isLoading: paymentVoidIsLoading }] = useAccountsPaymentVoidMutation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [confirmDialog, setConfirmDialog] = useState<undefined | actionType>(undefined)
  const [openPopoverId, setOpenPopoverId] = useState<null | number>(null)
  const [actionType, setActionType] = useState<actionType>()
  const dispatch = useDispatch()

  const theme = useTheme()
  const userAccess = useSelector(getUserAccessRights)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleAccountPaymentAction = (menuOption: actionType) => {
    setActionType(menuOption)
    setConfirmDialog(menuOption)
  }

  const handleMoreVertClick = (event: React.MouseEvent<HTMLElement>, item: UspPaymentViewDetailResult) => {
    setAnchorEl(event.currentTarget)
    setOpenPopoverId(item?.PaymentID)
  }

  const handleMenuOptionAction = () => {
    paymentVoid({
      paymentId: openPopoverId?.toString() as string,
      uspPaymentVoid: {
        dealID: dealID,
        branchID: branchID,
        isReversal: actionType === 'Void/Reverse',
        isChargeback: actionType === 'Chargeback/NSF',
        chargebackFee: null,
        chargebackBankCoaID: null,
        isRestrictCheckACH: true,
      },
    })
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  useEffect(() => {
    if (paymentVoidIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      getPaymentsDetails({
        dealId: dealID?.toString() as string,
        uspPaymentView: {
          isIncludeReversals: includeVoidedPayments,
        },
      })
      setConfirmDialog(undefined)
      setOpenPopoverId(null)
    }
  }, [includeVoidedPayments, paymentVoidIsSuccess])

  return (
    <>
      <Grid container className={classes.recordHeader}>
        <CardHeader title={recordData.PaymentType} />
        <Grid item className={classes.fieldContainerWrapper}>
          <Typography className={classes.fieldNameWrap}>Effective: </Typography>
          <Typography> {convertDateToDisplay(recordData.PaymentDate)}</Typography>
        </Grid>
        <Grid item className={classes.fieldContainerWrapper}>
          <Typography className={classes.fieldNameWrap}>By: </Typography>
          <Typography>{recordData.Cashier}</Typography>
        </Grid>
        <Grid item className={classes.fieldContainerWrapper}>
          <Typography className={classes.fieldNameWrap}>Receipt: </Typography>
          <Typography> {recordData.ReceiptNumber}</Typography>
        </Grid>
        <Grid item className={classes.fieldContainerWrapper}>
          <Typography className={classes.fieldNameWrap}>Form: </Typography>
          <Typography>
            {recordData.PaymentForm} / {recordData?.PaymentMethod}
          </Typography>
        </Grid>
        <Grid item className={classes.fieldContainerWrapper}>
          <Typography className={classes.fieldNameWrap}>Total: </Typography>
          <Typography>{formatNumber(recordData.TransactionAmount as string)}</Typography>
        </Grid>
        <Grid item className={classes.fieldContainerWrapper}>
          {recordData?.ProcessingFee !== '0.00' && (
            <>
              <Typography className={classes.fieldNameWrap}>Processing: </Typography>
              <Typography>{formatNumber(recordData.ProcessingFee as string)}</Typography>
            </>
          )}
        </Grid>

        <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          {recordData.PaymentStatus.toLowerCase() === 'active' ? (
            <Typography className={classes.activeItem}>{recordData.PaymentStatus}</Typography>
          ) : (
            <Typography className={classes.inactiveItem}>{recordData.PaymentStatus}</Typography>
          )}
          <IconButton color='primary' onClick={(e) => handleMoreVertClick(e, recordData)}>
            <MoreVertIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ p: 1.5, border: 'none' }}>
              <Typography>Print Receipt</Typography>
              <Typography>Send Receipt</Typography>
              {recordData.PaymentStatus === 'Active' && userAccess.CanVoidPayment && (
                <Typography sx={{ cursor: 'pointer', color: theme.palette.primary.main }} onClick={() => handleAccountPaymentAction('Void/Reverse')}>
                  Void/Reverse
                </Typography>
              )}
              {recordData.PaymentStatus === 'Active' && recordData.IsAllowChargeback && userAccess.CanChargebackPayment && (
                <Typography
                  sx={{ cursor: 'pointer', color: theme.palette.primary.main }}
                  onClick={() => handleAccountPaymentAction('Chargeback/NSF')}
                >
                  Chargeback/NSF
                </Typography>
              )}
            </Box>
          </Popover>
        </Grid>
      </Grid>
      {expandHistoryRecords && (
        <>
          <Grid container className={classes.recordSubHeader}>
            <Grid item className={classes.fieldContainerWrapper}>
              <Typography className={classes.fieldNameWrap}>Created: </Typography>
              <Typography> {convertDateTimeToDisplay(recordData.SystemDate)}</Typography>
            </Grid>
            <Grid item className={classes.fieldContainerWrapper}>
              <Typography className={classes.fieldNameWrap}>Transaction: </Typography>
              <Typography>{recordData.TransactionNumber}</Typography>
            </Grid>
            {recordData?.ReferenceNumber && (
              <Grid item className={classes.fieldContainerWrapper}>
                <Typography className={classes.fieldNameWrap}>Reference #: </Typography>
                <Typography> {recordData.ReferenceNumber}</Typography>
              </Grid>
            )}
            {recordData?.CardExpiration && (
              <Grid item className={classes.fieldContainerWrapper}>
                <Typography className={classes.fieldNameWrap}>Expiration: </Typography>
                <Typography> {convertDateToDisplay(recordData.CardExpiration)}</Typography>
              </Grid>
            )}
            {recordData.AuthorizationNumber && (
              <Grid item className={classes.fieldContainerWrapper}>
                <Typography className={classes.fieldNameWrap}>Authorization: </Typography>
                <Typography> {recordData.AuthorizationNumber}</Typography>
              </Grid>
            )}
          </Grid>
          {recordData?.Note && (
            <Grid xs={12} item className={classes.recordSubHeaderNote}>
              <Grid xs={12} item className={classes.fieldContainerWrapper}>
                <Typography className={classes.fieldNameWrap}>Note: </Typography>
                <Typography> {recordData.Note}</Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {!expandHistoryRecords ||
        (recordData?.DistributionDataInJson?.length >= 1 && (
          <>
            <PayHistoryRecordTable tableData={recordData?.DistributionDataInJson} />
          </>
        ))}
      <ConfirmDialog
        open={!!confirmDialog}
        onOk={handleMenuOptionAction}
        handleClose={() => setConfirmDialog(undefined)}
        notification={`Are you sure you want to ${actionType} this payment? This action is irreversible and will affect 
        the account balance and payment history. Please confirm to proceed.`}
      />
    </>
  )
}

export default PayHistoryRecord
