import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { useAddCarMakeMutation, useEditCarMakeMutation, useLazyGetCarMakeDataQuery } from '../../../../services'
import { UspConfigurationCarMakeTypeViewResult } from '../../../../models/apiTypes'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationCarMakeTypeViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}

const AddEditMakesModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addCarMakeType, { isLoading: isLoadingCarMakeType, isSuccess: isSuccessCarMakeType }] = useAddCarMakeMutation()
  const [editCarMakeType, { isLoading: isLoadingEditCarMakeType, isSuccess: isSuccessEditCarMakeType }] = useEditCarMakeMutation()
  const [getCarMakeTypeData, { isLoading: isLoadingGetCarMakeType, isSuccess: isSuccessGetCarMakeType, data }] = useLazyGetCarMakeDataQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editCarMakeType({
        carMakeId: rowData?.ID?.toString(),
        uspConfigurationCarMakeAddEdit: {
          make: values.Description,
          isActive: values.IsActive,
        },
      })
    } else {
      addCarMakeType({
        uspConfigurationCarMakeAddEdit: {
          make: values.Description,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getCarMakeTypeData({
        carMakeId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
      })
    }
    if (isSuccessGetCarMakeType && type === 'edit') {
      setInitialValues({
        Description: data?.Make,
        IsActive: data?.IsActive,
      })
    }
  }, [isSuccessGetCarMakeType, data, type])

  useEffect(() => {
    if (isSuccessEditCarMakeType || isSuccessCarMakeType) {
      onClose()
      submit()
    }
  }, [isSuccessEditCarMakeType, isSuccessCarMakeType])

  const isLoading = useMemo(
    () => isLoadingCarMakeType || isLoadingEditCarMakeType || isLoadingGetCarMakeType,
    [isLoadingCarMakeType, isLoadingEditCarMakeType, isLoadingGetCarMakeType],
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Car Make</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditMakesModal
