import { formatDistanceToNowStrict } from 'date-fns'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

import { useGetMessage } from './hooks'
import { IChatMessage, IChatParticipant } from './chat'
import Iconify from '../iconify'
import { useMockedUser } from '../../hooks/use-mocked-user'
import { JsonToTable } from 'react-json-to-table'

// ----------------------------------------------------------------------

type Props = {
  message: any
  participants: IChatParticipant[]
  onOpenLightbox?: (value: string) => void
}

export default function ChatMessageItem({ message, participants, onOpenLightbox }: Props) {
  const { user } = useMockedUser()

  const { me, senderDetails, hasImage } = useGetMessage({
    message,
    participants,
    currentUserId: `${user?.id}`,
  })

  const { firstName, avatarUrl } = senderDetails

  const renderInfo = (
    <Typography
      noWrap
      variant='caption'
      sx={{
        mb: 1,
        color: 'text.disabled',
        ...(!message?.prompt && {
          mr: 'auto',
        }),
      }}
    >
      {/* {!me && `${firstName},`} &nbsp; */}
      {/* {formatDistanceToNowStrict(new Date(createdAt), {
        addSuffix: true,
      })} */}
    </Typography>
  )
  //Sanitize Object to remove underscores
  function sanitizeObject(obj: any) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        const newKey = key.replaceAll('_', ' ')
        return [newKey, value]
      }),
    )
  }
  const renderBody = (
    <Stack
      sx={{
        p: 1.5,
        minWidth: 48,
        maxWidth: { xs: 350, md: 700 },
        overflowX: 'scroll',
        borderRadius: 1,
        typography: 'body2',
        bgcolor: 'background.neutral',
        ...(message?.prompt && {
          color: 'grey.800',
          bgcolor: 'primary.lighter',
        }),
        ...(hasImage && {
          p: 0,
          bgcolor: 'transparent',
        }),
      }}
    >
      {message?.start ? (
        message?.start
      ) : message.prompt ? (
        message?.prompt
      ) : Array.isArray(message?.aiResponse) ? (
        message?.aiResponse.length == 1 ? (
          <JsonToTable json={message?.aiResponse?.map((msg: any) => sanitizeObject(msg))[0]} />
        ) : (
          <JsonToTable json={message?.aiResponse?.map((msg: any) => sanitizeObject(msg))} />
        )
      ) : (
        message?.aiResponse
      )}
    </Stack>
  )

  const renderActions = (
    <Stack
      direction='row'
      className='message-actions'
      sx={{
        pt: 0.5,
        opacity: 0,
        top: '100%',
        left: 0,
        position: 'absolute',
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.shorter,
          }),
        ...(me && {
          left: 'unset',
          right: 0,
        }),
      }}
    >
      <IconButton size='small'>
        <Iconify icon='solar:reply-bold' width={16} />
      </IconButton>
      <IconButton size='small'>
        <Iconify icon='eva:smiling-face-fill' width={16} />
      </IconButton>
      <IconButton size='small'>
        <Iconify icon='solar:trash-bin-trash-bold' width={16} />
      </IconButton>
    </Stack>
  )

  return (
    <Stack direction='row' justifyContent={message.prompt ? 'flex-end' : 'unset'} sx={{ mb: 5 }}>
      {!message.prompt && <Iconify icon='mingcute:ai-fill' sx={{ width: 32, height: 32, mr: 2 }} />}

      <Stack alignItems='flex-end'>
        {/* {renderInfo} */}

        <Stack
          direction='row'
          alignItems='center'
          sx={{
            position: 'relative',
            '&:hover': {
              '& .message-actions': {
                opacity: 1,
              },
            },
          }}
        >
          {renderBody}
          {/* {renderActions} */}
        </Stack>
      </Stack>
    </Stack>
  )
}
