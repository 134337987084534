import { Branch, UspAccountingChartOfAccountView } from '../../../../../models'
import { convertDateToString } from '../../../../../utils/dateUtils'

export const transformToRequestValues = (values?: any, jsonBranchList?: Branch[] | null): UspAccountingChartOfAccountView => ({
  jsonBranchList: jsonBranchList,
  financialStatement: values?.financialStatement,
  isClosedPeriod: values?.ClosedPeriod,
  startDate: convertDateToString(values?.criteriaStartDate),
  endDate: convertDateToString(values?.criteriaEndDate),
  isExcludeZeroBalance: values?.ExcludeAccountsWithNoBalance,
  isGroupByAccount: values?.GroupByAccounts,
  isIncludeInactiveAccounts: values?.IncludeInactiveAccounts,
})
