import * as yup from 'yup'
import { formatNumber } from '../utils/general'

export const dealTradeAddEditValidation = (discrepancy: any, data: any, tradeID: any): yup.SchemaOf<any> => {
  const allowanceMax = tradeID
    ? (parseFloat(data?.Allowance) || 0) + (parseFloat(discrepancy?.TradeAllowanceDiscrepancy) || 0)
    : parseFloat(discrepancy?.TradeAllowanceDiscrepancy) || 0
  const lienPayoffMax = tradeID
    ? (parseFloat(data?.LienAmount) || 0) + (parseFloat(discrepancy?.TradeLienDiscrepancy) || 0)
    : parseFloat(discrepancy?.TradeLienDiscrepancy) || 0

  return yup
    .object({
      Year: yup.string().nullable().required(),
      Make: yup.string().nullable().required(),
      StockNumber: yup.string().nullable().optional(),
      // Mileage: yup.string().nullable().required(),
      // Odometer: yup.string().required('Odometer Type'),
      ACV: yup
        .string()
        .min(0)
        .required()
        .nullable()
        .matches(/^[\d|\.|\,]+/, 'Cannot be less than 0.'),
      Name: yup
        .string()
        .nullable()
        .when('LienPayoff', {
          is: (LienPayoff: string) => parseFloat(LienPayoff) > 0,
          then: yup.string().required('Name is required when Lien Payoff is greater than 0.'),
          otherwise: yup.string().notRequired().nullable(),
        }),
      Allowance: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : parseFloat(originalValue.replace(/,/g, ''))))
        .min(0)
        .max(allowanceMax, `Trade Allowance cannot be more than ${formatNumber(allowanceMax)}`),
      LienPayoff: yup
        .number()
        .transform((value, originalValue) => (originalValue === '' ? null : parseFloat(originalValue.replace(/,/g, ''))))
        .min(0)
        .max(lienPayoffMax, `Lien Payoff cannot be more than ${formatNumber(lienPayoffMax)}`),
    })
    .required();
}
