import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'
import Stack, { StackProps } from '@mui/material/Stack'
import Label, { LabelColor } from '../../../../components/label'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box } from '@mui/system'
import { Tooltip } from '@mui/material'
// ----------------------------------------------------------------------
type Props = PaperProps &
  StackProps & {
    name?: string
    address?: any
    phoneNumber?: string
    buyerStatus?: string
    addedBy?: string
    relationship?: string
    IsVerified?: boolean
    email?: string
    isReference?: boolean
    buyerStatusColor?: LabelColor
    stock?: string
    VIN?: string
    ACV?: string
    Allowance?: string
    LienAmount?: string
    Lienholder?: string
    action?: React.ReactNode | null
  }

type StatusMapping = {
  'Requirements Met': LabelColor
}

export default function BuyerItem({
  name,
  address,
  phoneNumber,
  buyerStatus,
  relationship,
  buyerStatusColor,
  email,
  addedBy,
  IsVerified,
  isReference,
  stock,
  VIN,
  ACV,
  Allowance,
  LienAmount,
  Lienholder,
  sx,
  action,
  ...other
}: Props) {
  const statusMapping: StatusMapping = {
    'Requirements Met': 'success',
  }
  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-end' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack flexGrow={1} spacing={1}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2'>
            {name} {relationship && `(${relationship})`}
          </Typography>
          {buyerStatus && (
            <Label color={buyerStatusColor} sx={{ ml: 1 }}>
              {buyerStatus}
            </Label>
          )}
        </Stack>

        {address && (
          <>
            {address.Address1 && <Typography variant='body2'>{address.Address1}</Typography>}
            {address.Address2 && <Typography variant='body2'>{address.Address2}</Typography>}
            <Typography variant='body2'>
              {address.City}, {address.State} {address.PostalCode}
            </Typography>
          </>
        )}

        {addedBy && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Added By {addedBy}
          </Typography>
        )}
        {phoneNumber && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {phoneNumber}
          </Typography>
        )}
        {email && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            {email}
          </Typography>
        )}
        {stock && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Stock #{stock}
          </Typography>
        )}
        {VIN && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            VIN {VIN}
          </Typography>
        )}
        {ACV && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            ACV {ACV}
          </Typography>
        )}
        {Allowance && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Allowance {Allowance}
          </Typography>
        )}
        {LienAmount && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            LienAmount {LienAmount}
          </Typography>
        )}
        {Lienholder && (
          <Typography variant='body2' sx={{ color: 'text.secondary' }}>
            Lienholder {Lienholder}
          </Typography>
        )}
      </Stack>

      <Box sx={{ position: 'absolute', top: 13, right: 45 }}>
        {isReference ? (
          IsVerified ? (
            ''
          ) : (
            <Tooltip title='Unverified'>
              <CancelIcon color='error' />
            </Tooltip>
          )
        ) : (
          ''
        )}
      </Box>
      {action && action}
    </Stack>
  )
}
