import React, { useEffect, useMemo, useState } from 'react'
import { withAccounts } from '../../../../../../../hoc/withAccounts'
import { AccountsOverviewResult, UspGetDataContact } from '../../../../../../../models'
import Header from '../../../../../Header/Header'
import { withPermissionsDropdown } from '../../../../../../../hoc'
import { Card, Container, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  useAddDealReferralMutation,
  useEditDealReferralMutation,
  useGetContactMutation,
  useLazyGetDealReferralDataQuery,
  useLazyGetTaskDealQuery,
} from '../../../../../../../services'
import { Form } from 'react-final-form'
import validateFormValues from '../../../../../../../utils/validateForm'
import { referralValidation } from '../../../../../../../validation/addDealReferralValidation'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import ReferralForm from '../../../../../../Deals/DealsReferral/components/ReferralForm/ReferralForm'
import AddressForm from '../../../../../../Deals/DealsReferral/components/AddressForm/AddressForm'
import ContactForm from '../../../../../../Deals/DealsReferral/components/ContactForm/ContactForm'
import IdentityForm from '../../../../../../Deals/DealsReferral/components/IdentityForm/IdentityForm'
import SettingsForm from '../../../../../../Deals/DealsReferral/components/SettingsForm/SettingsForm'
import Button from '@mui/material/Button'
import { appRoute } from '../../../../../../../constants'
import { CustomCircular } from '../../../../../../../ui'
import { transformToFormValues, transformToRequestValues } from '../../../../../../Deals/DealsReferral/components/DealsReferralAddEdit/transform'
import { enqueueNotification } from '../../../../../../../redux/slices/notificationsSlice'
import { getUserAccessRights } from '../../../../../../../redux/slices/authSlice'
import CustomBreadcrumbs from '../../../../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { applyPhoneMask } from '../../../../../../../utils/general'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  [`& .${classes.formItem}`]: {
    minHeight: '340px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  accountsData: AccountsOverviewResult
}

const ReferralEdit = ({ accountsData }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { dealID, referral } = useParams()
  const userAccess = useSelector(getUserAccessRights)
  const disableEditIcons = userAccess?.CanEditAccount
  const [initialValues, setInitialValues] = useState({
    AccountType: 'individual',
  })
  const [getTaskDeal, { data: taskDeal, isLoading: getTaskDealIsLoading }] = useLazyGetTaskDealQuery()
  const [addReferral, { isSuccess }] = useAddDealReferralMutation()
  const [editReferral, { isSuccess: editReferralIsSuccess }] = useEditDealReferralMutation()
  const [getDealReferrals, { data: referralData, isLoading: isLoadingReferral }] = useLazyGetDealReferralDataQuery()
  const [getContacts, { data: getContactsData, isLoading: getContactsIsLoading }] = useGetContactMutation()

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Referral added successfully.' }))
      navigate(
        generatePath(appRoute.AccountsOverview, {
          dealID,
        }),
      )
    }

    if (editReferralIsSuccess) {
      dispatch(enqueueNotification({ message: 'Referral edited successfully.' }))
      navigate(
        generatePath(appRoute.AccountsOverview, {
          dealID,
        }),
      )
    }
  }, [isSuccess, editReferralIsSuccess])

  useEffect(() => {
    getTaskDeal({ dealId: dealID as string })
    if (referral !== 'add') {
      getDealReferrals({ referralId: referral as string })
    }
  }, [dealID, referral])

  useEffect(() => {
    if (referralData?.ReferralContactID) {
      getContacts({ contactId: referralData.ReferralContactID?.toString() as string })
    }
  }, [referralData])

  useEffect(() => {
    if (referralData && getContactsData) {
      setInitialValues(transformToFormValues(referralData as any, getContactsData as UspGetDataContact))
    }
  }, [referralData, getContactsData])

  const handleSubmit = (values?: any) => {
    if (referral === 'add') {
      addReferral({
        dealId: dealID as string,
        uspDealReferralAddEdit: transformToRequestValues(values as any),
      })
    } else {
      editReferral({
        dealId: dealID as string,
        referralId: referral as string,
        uspDealReferralAddEdit: transformToRequestValues(values as any),
      })
    }
  }

  const isLoading = useMemo(
    () => getTaskDealIsLoading || isLoadingReferral || getContactsIsLoading,
    [getTaskDealIsLoading, isLoadingReferral, getContactsIsLoading],
  )

  return (
    <>
      <Container>
        <Grid container>
          <Grid item xs={12} md={10} spacing={2}>
            <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(referralValidation)}>
              {({ handleSubmit, form, dirty }: any) => (
                <form onSubmit={handleSubmit}>
                  {/* <Header taskDeal={taskDeal} /> */}
                  <CustomBreadcrumbs
                    heading='Account'
                    links={[
                      { name: 'List', href: '/accounts' },
                      {
                        name: `${taskDeal?.CarDescription} | Cell: ${applyPhoneMask(taskDeal?.PhoneNumber)} | ${
                          taskDeal?.Buyer
                        } | ${`VIN ${taskDeal?.VIN}`} | ${`Stock # ${taskDeal?.StockNumber}`}`,
                      },
                    ]}
                    sx={{
                      mb: { xs: 3, md: 5 },
                    }}
                  />
                  <Card sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', height: '100%' }}>
                    <Box>
                      <Grid item xs={12}>
                        <ReferralForm />
                      </Grid>
                      <Grid item xs={12}>
                        <AddressForm />
                      </Grid>
                      <Grid item xs={12}>
                        <ContactForm />
                      </Grid>
                      <Grid item xs={12}>
                        <IdentityForm />
                      </Grid>
                      <Grid item xs={12}>
                        <SettingsForm />
                      </Grid>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        position: 'sticky',
                        bottom: 16,
                        right: 20,
                        p: 3,
                        width: 'fit-content',
                      }}
                    >
                      <Button
                        variant='contained'
                        type='button'
                        color='error'
                        onClick={() => navigate(appRoute.AccountsOverview.replace(':dealID', dealID ?? ''))}
                      >
                        Close
                      </Button>
                      <Button type='submit' variant='contained' disabled={!dirty}>
                        Save & Close
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Form>
            <CustomCircular open={isLoading} />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default withPermissionsDropdown(withAccounts(ReferralEdit))
