import React from 'react'
import FormControl from '@mui/material/FormControl'
import { Field } from 'react-final-form'
import { generateUtilityClasses, TextField } from '@mui/material'
import { styled } from '@mui/system'
import FormHelperText from '@mui/material/FormHelperText'
import Autocomplete from '@mui/material/Autocomplete'
interface Props {
  name: string
  label?: string
  labelId?: string
  options: any[]
  required?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  customDropdownWidth?: number | string
  optionKey?: string
  optionValue?: string
  optionNone?: boolean
  disabled?: boolean
  onChange?: (value: any) => void
  initialValue?: any
  [x: string]: any
}

const classes = generateUtilityClasses('FormControl', ['formControl', 'title', 'selectedAll', 'indeterminateColor', 'selectAllText'])

const StyledFormControl = styled(FormControl)(() => ({
  [`&.${classes.formControl}`]: {},
  [`& .${classes.indeterminateColor}`]: {
    color: '#f50057',
  },
  [`& .${classes.selectAllText}`]: {
    fontWeight: 500,
  },
  [`& .${classes.selectAllText}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))
export const DropdownFieldSearch = ({
  name,
  label,
  labelId,
  options = [],
  variant,
  initialValue,
  required,
  customDropdownWidth,
  onChange,
  optionKey = 'key',
  optionValue = 'value',
  optionNone,
  disabled,
  getOptionLabel,
  ...props
}: Props) => {
  return (
    <Field name={name} initialValue={initialValue}>
      {({ input, meta: { error, touched } }) => (
        <StyledFormControl fullWidth variant={variant} required={required} disabled={disabled} className={classes.formControl}>
          <Autocomplete
            disableClearable={label === 'Phone Type' ? true : false}
            {...props}
            {...input}
            id={labelId}
            options={options}
            componentsProps={{
              paper: {
                sx: {
                  minWidth: options?.length === 0 ? 'auto' : customDropdownWidth || 'auto',
                },
              },
            }}
            autoComplete={false}
            getOptionLabel={getOptionLabel ? getOptionLabel : (option) => option[optionValue]}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (item) =>
                  item?.[optionKey]?.toString().toLowerCase()?.includes(inputValue.toLowerCase()) ||
                  item?.StockNumber?.toString()?.includes(inputValue) ||
                  item?.[optionValue]?.toString().toLowerCase()?.includes(inputValue.toLowerCase()),
              )
            }}
            value={options?.find((option) => option[optionKey] === input.value) || null}
            onChange={(e, value) => {
              if (onChange) {
                const formValue = value ? value[optionKey] : null
                onChange(formValue)
              }
              input.onChange(value ? value[optionKey] : null)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant={variant}
                required={required}
                error={touched && error ? true : false}
                autoComplete='off'
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'one-time-code',
                }}
              />
            )}
            disabled={disabled}
          />
          {touched && error && (
            <FormHelperText error={touched && error ? true : false} id='my-helper-text'>
              {error}
            </FormHelperText>
          )}
        </StyledFormControl>
      )}
    </Field>
  )
}

export default DropdownFieldSearch
