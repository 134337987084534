import dayjs from 'dayjs'

type Order = 'asc' | 'desc'

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const dateRegExp = new RegExp('^\\d{4}\\-(0[1-9]|1[012])\\-(0[1-9]|[12][0-9]|3[01])$')

  const aValue = typeof a[orderBy] === 'string' ? (a[orderBy] as string).toLowerCase() : a[orderBy]
  const bValue = typeof b[orderBy] === 'string' ? (b[orderBy] as string).toLowerCase() : b[orderBy]

  if (dateRegExp.test(a[orderBy] as any) || dateRegExp.test(b[orderBy] as any)) {
    if (dayjs(a[orderBy] as any).isBefore(dayjs(b[orderBy] as any))) {
      return -1
    }
    if (dayjs(b[orderBy] as any).isBefore(dayjs(a[orderBy] as any))) {
      return 1
    }
  } else {
    if (bValue < aValue) {
      return -1
    }
    if (bValue > aValue) {
      return 1
    }
  }
  return 0
}

export function getComparator<Key extends keyof any>(order: 'asc' | 'desc', orderBy: Key): (a: any, b: any) => number {
  return (a, b) => {
    let aValue = a[orderBy]
    let bValue = b[orderBy]

    // If values are numbers, compare as numbers
    if (!isNaN(aValue) && !isNaN(bValue)) {
      aValue = Number(aValue)
      bValue = Number(bValue)
      return order === 'desc' ? bValue - aValue : aValue - bValue
    }

    // Handling string comparison
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return order === 'desc' ? bValue.localeCompare(aValue) : aValue.localeCompare(bValue)
    }

    // Fallback comparison
    return order === 'desc' ? (aValue < bValue ? 1 : -1) : aValue > bValue ? 1 : -1
  }
}

export function stableSort<T>(comparator: (a: T, b: T) => number, array?: T[]) {
  if (Array.isArray(array)) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) {
        return order
      }
      return a[1] - b[1]
    })
    return stabilizedThis.map((el) => el[0])
  }
  return []
}
