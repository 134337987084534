import React, { useEffect } from 'react'
import {
  Box,
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspContactReferenceView } from '../../../../../models'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/system'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { useDeleteReferencesMutation } from '../../../../../services'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import { visuallyHidden } from '@mui/utils'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'

interface Props {
  contactsReference: (arg: { contactId: string }) => void
  contactsReferenceData?: UspContactReferenceView[]
  loading: boolean
}

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'loading',
  'spinner',
  'tableHead',
  'tableCellName',
  'telegramIconActive',
  'telegramIconInactive',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.telegramIconActive}`]: {
    color: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

export const LeadsReferenceTableBlock = ({ contactsReference, contactsReferenceData, loading }: Props) => {
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ referenceId: string } | undefined>(undefined)
  const [deleteReferences, { isSuccess: deleteReferencesIsSuccess }] = useDeleteReferencesMutation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { leadID, contactID } = useParams()
  const initialOrder = (storageGet('leads_references_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('leads_references_by') || 'Name') as keyof UspContactReferenceView
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspContactReferenceView>(initialOrderBy)

  const handleEdit = (id: number | string) => {
    if (id) {
      navigate(
        appRoute.LeadsReferenceAddEdit.replace(':leadID', leadID as string)
          .replace(':contactID', contactID as string)
          .replace(':referenceID', String(id) as string),
      )
    }
  }

  const handleDelete = () => {
    if (deleteConfirmDialog?.referenceId) {
      deleteReferences({ referenceId: deleteConfirmDialog?.referenceId as string })
      setDeleteConfirmDialog(undefined)
    }
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspContactReferenceView) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('leards_references_order', isAsc ? 'desc' : 'asc')
    storageSet('leads_references_by', property)
  }

  const createSortHandler = (property: keyof UspContactReferenceView) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  useEffect(() => {
    if (deleteReferencesIsSuccess) {
      contactsReference({ contactId: contactID as string })
      dispatch(enqueueNotification({ message: 'Reference removed' }))
    }
  }, [deleteReferencesIsSuccess])

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }} className={classes.root}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Name' ? order : false}>
                  <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                    Name
                    {orderBy === 'Name' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'RelationshipType' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'RelationshipType'}
                    direction={orderBy === 'RelationshipType' ? order : 'asc'}
                    onClick={createSortHandler('RelationshipType')}
                  >
                    Relationship
                    {orderBy === 'RelationshipType' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'PhoneNumber' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'PhoneNumber'}
                    direction={orderBy === 'PhoneNumber' ? order : 'asc'}
                    onClick={createSortHandler('PhoneNumber')}
                  >
                    Phone Number
                    {orderBy === 'PhoneNumber' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Comments' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Comments'}
                    direction={orderBy === 'Comments' ? order : 'asc'}
                    onClick={createSortHandler('Comments')}
                  >
                    Comments
                    {orderBy === 'Comments' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsVerified' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'IsVerified'}
                    direction={orderBy === 'IsVerified' ? order : 'asc'}
                    onClick={createSortHandler('IsVerified')}
                  >
                    Verified
                    {orderBy === 'IsVerified' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsAllowCall' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'IsAllowCall'}
                    direction={orderBy === 'IsAllowCall' ? order : 'asc'}
                    onClick={createSortHandler('IsAllowCall')}
                  >
                    Can Call
                    {orderBy === 'IsAllowCall' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} align={'right'}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), contactsReferenceData as any[])?.map((item: any) => (
                <TableRow key={item.ReferenceID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell className={classes.tableCellName} onClick={() => handleEdit(item.ReferenceID)}>
                    {item.Name}
                  </TableCell>
                  <TableCell>{item?.RelationshipType}</TableCell>
                  <TableCell>{item?.PhoneNumber}</TableCell>
                  <TableCell>{item?.Salesperson}</TableCell>
                  <TableCell>{item?.IsVerified ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{item?.IsAllowCall ? 'Yes' : 'No'}</TableCell>
                  <TableCell align={'right'}>
                    <Tooltip title='Edit'>
                      <IconButton color='primary' onClick={() => handleEdit(item.ReferenceID)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <IconButton
                        color='error'
                        onClick={() => {
                          setDeleteConfirmDialog({ referenceId: item?.ReferenceID?.toString() as string })
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {contactsReferenceData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
              {loading && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Reference will be removed'}
      />
    </>
  )
}

export default LeadsReferenceTableBlock
