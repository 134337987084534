import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Container, Grid, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { CustomCircular } from '../../../ui'
import Header from './components/Header/Header'
import { useEditRfcMutation, useSearchRfcMutation } from '../../../services'
import DealPurchase from './components/DealPurchase/DealPurchase'
import { transformToFormValues, transformToRequestValues } from './transform'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    position: 'absolute',
    right: '20px',
    bottom: '20px',
    marginLeft: theme.spacing(2),
  },
}))

const SettingsAdminRFC = () => {
  const [initialValues, setInitialValues] = useState({})
  const [formChanged, setFormChanged] = useState(false)
  const dispatch = useDispatch()
  const [searchRFC, { data, isLoading: searchRFCIsLoading }] = useSearchRfcMutation()
  const [rfcEdit, { isSuccess: rfcEditIsSuccess }] = useEditRfcMutation()

  useEffect(() => {
    searchRFC({})
  }, [])

  useEffect(() => {
    if (data) {
      setInitialValues(transformToFormValues(data[0]))
    }
  }, [data])

  const handleSubmit = (values?: any) => {
    rfcEdit({ uspConfigurationRfcEdit: transformToRequestValues(values) })
  }

  useEffect(() => {
    if (rfcEditIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setFormChanged(false)
    }
  }, [rfcEditIsSuccess])

  const isLoading = useMemo(() => searchRFCIsLoading, [searchRFCIsLoading])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='RFC'
        links={[{ name: 'RFC' }]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card>
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
              {/* <Header /> */}
              <Grid container p={3} spacing={2}>
                <Grid item xs={12}>
                  <DealPurchase onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid container item xs={12} justifyContent={'flex-end'}>
                  <Button type='submit' className={classes.saveBtn} variant='contained' disabled={!formChanged}>
                    Save
                  </Button>
                </Grid>
              </Grid>

              <CustomCircular open={isLoading} />
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default SettingsAdminRFC
