import { styled } from '@mui/system'
import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import React from 'react'
import TextField from '../../../../../ui/TextField'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

const TradeVehicleForm = ({}: Props) => {
  const carOdometerType = useSelector((state) => selectDropdownByName(state, 'CarOdometerType'))
  const carBodyType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))

  return (
    <>
      <CardHeader title='Trade Vehicle' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <TextField disabled name='StockNumber' label='Stock Number (Optional)' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled name='VIN' label='VIN' fullWidth />
        </Grid>
        <Grid item xs={2}>
          <TextField disabled name='Year' label='Year' fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextField disabled name='Make' label='Make' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled name='Model' label='Model' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled name='Trim' label='Trim' fullWidth />
        </Grid>
        {carBodyType && (
          <Grid item xs={6}>
            <DropdownFieldSearch disabled name='Body' label='Body' options={carBodyType} optionKey='ID' optionValue='BodyType' autoComplete='off' />
          </Grid>
        )}
        <Grid item xs={3}>
          <TextField disabled name='Mileage' label='Mileage' fullWidth />
        </Grid>
        {carOdometerType && (
          <Grid item xs={3}>
            <DropdownFieldSearch
              disabled
              name='Odometer'
              label='Odometer Type'
              fullWidth
              options={carOdometerType?.ReturnData}
              optionKey='ID'
              optionValue='OdometerType'
              initialValue={1}
              disableClearable
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextField disabled name='Color' label='Color' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField disabled name='InteriorColor' label='Interior Color' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default TradeVehicleForm
