import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'
import Stack, { StackProps } from '@mui/material/Stack'
import Label, { LabelColor } from '../../../../components/label'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../constants'

// ----------------------------------------------------------------------
type Props = PaperProps &
  StackProps & {
    DealID?: string
    CarDescription?: string
    StockNumber?: string
    VIN?: string
    DealDate?: string
    SalesPrice?: string
    CashDown?: string
    TradeAllowance?: string
    AmountFinanced?: string
    DealStatus?: string
    CanAccessDeal?: string
    action?: React.ReactNode
  }

export default function DealItem({
  DealID,
  CarDescription,
  StockNumber,
  VIN,
  DealDate,
  SalesPrice,
  CashDown,
  TradeAllowance,
  AmountFinanced,
  DealStatus,
  CanAccessDeal,
  sx,
  action,
  ...other
}: Props) {
  const navigate = useNavigate()

  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-end' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        position: 'relative',
        cursor: 'pointer',
        ...sx,
      }}
      onClick={() => navigate(appRoute.DealsAddEdit.replace(':dealID', String(DealID)))}
      {...other}
    >
      <Stack flexGrow={1} spacing={1}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2'>{CarDescription} </Typography>
          <Typography sx={{ ml: 1.5 }} variant='subtitle2'>
            Stock {StockNumber}
          </Typography>
          <Typography sx={{ ml: 1.5 }} variant='subtitle2'>
            VIN {VIN}
          </Typography>
          {DealStatus && <Label sx={{ ml: 2 }}>{DealStatus}</Label>}
        </Stack>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {DealDate}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Sales Price ${SalesPrice}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Cash Down ${CashDown}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Trade ${TradeAllowance}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Fincanced ${AmountFinanced}
        </Typography>

        {/* <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Status: {stipulationStatus}
        </Typography> */}
      </Stack>

      {action && action}
    </Stack>
  )
}
