import React from 'react'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { UspReportSalesTaxResult } from '../../../../../models'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { formatNumber } from '../../../../../utils/general'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    minWidth: '150px',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportSalesTaxResult[]
  isLoading: boolean
}

const SalesTaxTableBlock = ({ filterData, isLoading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('insights_sales_tax_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('insights_sales_tax_order_by') || 'StockNumber') as keyof UspReportSalesTaxResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspReportSalesTaxResult>(initialOrderBy)

  const handleEdit = (id: number | string) => {
    if (id) {
      navigate(appRoute.InventoryEdit.replace(':carID', String(id)))
    }
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportSalesTaxResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('insights_sales_tax_order', isAsc ? 'desc' : 'asc')
    storageSet('insights_sales_tax_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportSalesTaxResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock #
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Buyer' ? order : false}>
              <TableSortLabel active={orderBy === 'Buyer'} direction={orderBy === 'Buyer' ? order : 'asc'} onClick={createSortHandler('Buyer')}>
                Buyer
                {orderBy === 'Buyer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Car' ? order : false}>
              <TableSortLabel active={orderBy === 'Car'} direction={orderBy === 'Car' ? order : 'asc'} onClick={createSortHandler('Car')}>
                Car
                {orderBy === 'Car' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'VIN' ? order : false}>
              <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                VIN
                {orderBy === 'VIN' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Deal' ? order : false}>
              <TableSortLabel active={orderBy === 'Deal'} direction={orderBy === 'Deal' ? order : 'asc'} onClick={createSortHandler('Deal')}>
                Deal
                {orderBy === 'Deal' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Finalized' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Finalized'}
                direction={orderBy === 'Finalized' ? order : 'asc'}
                onClick={createSortHandler('Finalized')}
              >
                Finalized
                {orderBy === 'Finalized' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DealType' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DealType'}
                direction={orderBy === 'DealType' ? order : 'asc'}
                onClick={createSortHandler('DealType')}
              >
                Deal Type
                {orderBy === 'DealType' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'DealState' ? order : false}>
              <TableSortLabel
                active={orderBy === 'DealState'}
                direction={orderBy === 'DealState' ? order : 'asc'}
                onClick={createSortHandler('DealState')}
              >
                Deal State
                {orderBy === 'DealState' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'SalesPrice' ? order : false}>
              <TableSortLabel
                active={orderBy === 'SalesPrice'}
                direction={orderBy === 'SalesPrice' ? order : 'asc'}
                onClick={createSortHandler('SalesPrice')}
              >
                Sales Price
                {orderBy === 'SalesPrice' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TradeCredit' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TradeCredit'}
                direction={orderBy === 'TradeCredit' ? order : 'asc'}
                onClick={createSortHandler('TradeCredit')}
              >
                Trade Credit
                {orderBy === 'TradeCredit' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxableAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TaxableAmount'}
                direction={orderBy === 'TaxableAmount' ? order : 'asc'}
                onClick={createSortHandler('TaxableAmount')}
              >
                Taxable Amount
                {orderBy === 'TaxableAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxState' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TaxState'}
                direction={orderBy === 'TaxState' ? order : 'asc'}
                onClick={createSortHandler('TaxState')}
              >
                Tax State
                {orderBy === 'TaxState' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxCityName' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TaxCityName'}
                direction={orderBy === 'TaxCityName' ? order : 'asc'}
                onClick={createSortHandler('TaxCityName')}
              >
                Tax City
                {orderBy === 'TaxCityName' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'TaxCountyName' ? order : false}>
              <TableSortLabel
                active={orderBy === 'TaxCountyName'}
                direction={orderBy === 'TaxCountyName' ? order : 'asc'}
                onClick={createSortHandler('TaxCountyName')}
              >
                Tax County
                {orderBy === 'TaxCountyName' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StateTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StateTaxAmount'}
                direction={orderBy === 'StateTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('StateTaxAmount')}
              >
                State Tax
                {orderBy === 'StateTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CityTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CityTaxAmount'}
                direction={orderBy === 'CityTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('CityTaxAmount')}
              >
                City Tax
                {orderBy === 'CityTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CountyTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CountyTaxAmount'}
                direction={orderBy === 'CountyTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('CountyTaxAmount')}
              >
                County Tax
                {orderBy === 'CountyTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'MiscTaxAmount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'MiscTaxAmount'}
                direction={orderBy === 'MiscTaxAmount' ? order : 'asc'}
                onClick={createSortHandler('MiscTaxAmount')}
              >
                Misc Tax
                {orderBy === 'MiscTaxAmount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StateTaxRate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StateTaxRate'}
                direction={orderBy === 'StateTaxRate' ? order : 'asc'}
                onClick={createSortHandler('StateTaxRate')}
              >
                State Rate
                {orderBy === 'StateTaxRate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CityTaxRate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CityTaxRate'}
                direction={orderBy === 'CityTaxRate' ? order : 'asc'}
                onClick={createSortHandler('CityTaxRate')}
              >
                City Rate
                {orderBy === 'CityTaxRate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'CountyTaxRate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'CountyTaxRate'}
                direction={orderBy === 'CountyTaxRate' ? order : 'asc'}
                onClick={createSortHandler('CountyTaxRate')}
              >
                County Rate
                {orderBy === 'CountyTaxRate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'MiscTaxRate' ? order : false}>
              <TableSortLabel
                active={orderBy === 'MiscTaxRate'}
                direction={orderBy === 'MiscTaxRate' ? order : 'asc'}
                onClick={createSortHandler('MiscTaxRate')}
              >
                Misc Rate
                {orderBy === 'MiscTaxRate' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className={classes.tableCellName} onClick={() => handleEdit(item.CarID as string)}>
                {item.StockNumber}
              </TableCell>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute?.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))}
              >
                {item.Buyer}
              </TableCell>
              <TableCell>{item.Car}</TableCell>
              <TableCell>{item.VIN}</TableCell>
              <TableCell>{convertDateToDisplay(item.Deal as string)}</TableCell>
              <TableCell>{convertDateToDisplay(item.Finalized as string)}</TableCell>
              <TableCell>{item.Branch}</TableCell>
              <TableCell>{item.DealType}</TableCell>
              <TableCell>{item.DealState}</TableCell>
              <TableCell>{formatNumber(item.SalesPrice as string)}</TableCell>
              <TableCell>{formatNumber(item.TradeCredit as string)}</TableCell>
              <TableCell>{formatNumber(item.TaxableAmount as string)}</TableCell>
              <TableCell>{item.TaxState}</TableCell>
              <TableCell>{item.TaxCityName}</TableCell>
              <TableCell>{item.TaxCountyName}</TableCell>
              <TableCell>{formatNumber(item.StateTaxAmount as string)}</TableCell>
              <TableCell>{formatNumber(item.CityTaxAmount as string)}</TableCell>
              <TableCell>{formatNumber(item.CountyTaxAmount as string)}</TableCell>
              <TableCell>{formatNumber(item.MiscTaxAmount as string)}</TableCell>
              <TableCell>{item.StateTaxRate}</TableCell>
              <TableCell>{item.CityTaxRate}</TableCell>
              <TableCell>{item.CountyTaxRate}</TableCell>
              <TableCell>{item.MiscTaxRate}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default SalesTaxTableBlock
