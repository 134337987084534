import { useCallback } from 'react'

import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack, { StackProps } from '@mui/material/Stack'
import Iconify from '../../../../components/iconify'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../redux/slices/userDropdownSlice'
import { shortDateLabel } from '../../../../components/table'

// ----------------------------------------------------------------------
const DateMapping = {
  between: 'Between',
  today: 'Today',
  yesterday: 'Yesterday',
  lastSevenDays: 'Last Seven Days',
  lastThirtyDays: 'Last Thirty Days',
}
type Props = StackProps & {
  filters: any
  onFilters: (name: string, value: any) => void
  //
  onResetFilters: VoidFunction
  //
  results: number
}

export default function LeadsTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  const branchData = useSelector(getBranch)
  const shortLabel = shortDateLabel(filters.startDate, filters.endDate)

  const handleRemoveKeyword = useCallback(() => {
    onFilters('name', '')
  }, [onFilters])

  const handleRemoveAssigned = useCallback(() => {
    onFilters('assigned', '')
  }, [onFilters])

  const handleRemoveBranch = useCallback(
    (inputValue: string) => {
      const newValue = filters.branch.filter((item: any) => item !== inputValue)

      onFilters('branch', newValue)
    },
    [filters.branch, onFilters],
  )

  const handleRemoveDate = useCallback(() => {
    onFilters('startDate', null)
    onFilters('endDate', null)
    onFilters('date', null)
  }, [onFilters])

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component='span' sx={{ color: 'text.secondary', ml: 0.25 }}>
          results found
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction='row' flexWrap='wrap' alignItems='center'>
        {filters.status !== 'all' && (
          <Block label='Status:'>
            <Chip size='small' label={filters.status} onDelete={handleRemoveAssigned} />
          </Block>
        )}
        {filters.assigned !== '' && (
          <Block label='Assigned To:'>
            <Chip size='small' label={filters.assigned} onDelete={handleRemoveAssigned} />
          </Block>
        )}

        {!!filters.branch?.length && (
          <Block label='Branch:'>
            {filters.branch.map((item: any) => (
              <Chip key={item} label={branchData.find((el) => el.BranchID == item).Branch} size='small' onDelete={() => handleRemoveBranch(item)} />
            ))}
          </Block>
        )}

        {!!filters.name && (
          <Block label='Keyword:'>
            <Chip label={filters.name} size='small' onDelete={handleRemoveKeyword} />
          </Block>
        )}
        {filters.date && (
          <Block label='Date:'>
            <Chip size='small' label={DateMapping[filters.date as unknown as keyof typeof DateMapping]} onDelete={handleRemoveDate} />
          </Block>
        )}
        {filters.startDate && filters.endDate && (
          <Block label='Date Range:'>
            <Chip size='small' label={shortLabel} onDelete={handleRemoveDate} />
          </Block>
        )}

        <Button color='error' onClick={onResetFilters} startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}>
          Clear
        </Button>
      </Stack>
    </Stack>
  )
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string
}

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant='outlined'
      spacing={1}
      direction='row'
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component='span' sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction='row' flexWrap='wrap'>
        {children}
      </Stack>
    </Stack>
  )
}
