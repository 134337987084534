import { UspDealCommissionViewResult } from '../../../../../models'
import removeMask from '../../../../../utils/removeMask'

export const transformToFormValues = (values?: UspDealCommissionViewResult, salesPerson?: any): any => {
  const salesperson = values?.Salesperson
  const salespersonData = salesPerson

  const salespersonId = salespersonData?.find((sp: any) => sp.Name === salesperson)?.UserID

  return {
    Salesperson: salespersonId,
    CommissionAmount: values?.CommissionAmount,
  }
}
export const transformToRequestValues = (values?: UspDealCommissionViewResult): any => ({
  salespersonUserID: values?.Salesperson,
  commissionAmount: removeMask(values?.CommissionAmount),
})
