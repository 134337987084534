import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const BalanceForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Balance' />
      <Grid container spacing={2} p={3}>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanOriginalBalance' disabled label='Original Balance' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanPrincipalPaid' disabled label='Principal Paid' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanBalance' disabled label='Current Balance' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanBalanceInterest' disabled label='Interest Balance' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <AmountField variant='standard' name='floorplanInterestPaid' disabled label='Interest Paid' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default BalanceForm
