import { useFormContext } from 'react-hook-form'

import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useResponsive } from '../../../../hooks/use-responsive'
import { useBoolean } from '../../../../hooks/use-boolean'
import Iconify from '../../../../components/iconify'
import { AddressListDialog } from './address'
import { Field, useFormState } from 'react-final-form'
import { VendorResponseSp } from '../../../../models'

// ----------------------------------------------------------------------

export default function InvoiceNewEditAddress({ isFieldsLocked, vendorData }: { isFieldsLocked: boolean; vendorData: VendorResponseSp[] }) {
  const mdUp = useResponsive('up', 'md')
  const { values, errors } = useFormState()
  const { invoiceFrom, invoiceTo } = values

  const from = useBoolean()

  const to = useBoolean()

  return (
    <>
      <Stack spacing={{ xs: 3, md: 5 }} direction={{ xs: 'column', md: 'row' }} sx={{ p: 3 }}>
        <Divider flexItem orientation={mdUp ? 'vertical' : 'horizontal'} sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ width: 1 }}>
          <Stack direction='row' alignItems='center' sx={{ mb: 1 }}>
            <Typography variant='h6' sx={{ color: 'text.disabled', flexGrow: 1 }}>
              To:
            </Typography>

            <IconButton onClick={to.onTrue} disabled={isFieldsLocked}>
              <Iconify icon='solar:pen-bold' color={isFieldsLocked ? '' : 'primary.main'} />
            </IconButton>
          </Stack>

          {invoiceTo ? (
            <Stack spacing={1}>
              <Typography variant='subtitle2'>{invoiceTo?.Name}</Typography>
              <Typography variant='body2'>Vendor Type: {invoiceTo?.VendorType}</Typography>
              <Typography variant='body2'> Vendor Code: {invoiceTo?.VendorCode}</Typography>
            </Stack>
          ) : (
            <Typography typography='caption' sx={{ color: 'error.main' }}>
              {(errors?.invoiceTo as any)?.message}
            </Typography>
          )}
        </Stack>
        <Stack sx={{ width: 1 }}>
          {/* <Stack direction='row' alignItems='center' sx={{ mb: 1 }}>
            <Typography variant='h6' sx={{ color: 'text.disabled', flexGrow: 1 }}>
              From:
            </Typography>

            <IconButton onClick={from.onTrue}>
              <Iconify icon='solar:pen-bold' />
            </IconButton>
          </Stack>

          <Stack spacing={1}>
            <Typography variant='subtitle2'>{invoiceFrom?.name}</Typography>
            <Typography variant='body2'>{invoiceFrom?.fullAddress}</Typography>
            <Typography variant='body2'> {invoiceFrom?.phoneNumber}</Typography>
          </Stack> */}
        </Stack>
      </Stack>
      <Field name='invoiceTo'>
        {({ input }) => (
          <AddressListDialog
            title='Vendors'
            open={to.value}
            onClose={to.onFalse}
            selected={(selectedId: string) => invoiceTo?.id === selectedId}
            onSelect={(address) => input.onChange(address)}
            list={vendorData}
          />
        )}
      </Field>
    </>
  )
}
