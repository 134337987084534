import { appApi } from '../constants'

export interface IApiCompanyItem {
  CompanyType: string
  BranchID: string
  IsRestrictedToBranch: boolean
  BranchData: string
  AllowedIPAddressData: string
}

export interface IApiClientItem {
  client_name: string
  client_uid: string
  is_dealership_admin: boolean
}

export const fetchClients = () => {
  return appApi.get<IApiClientItem[]>('/user/clients/').then((res) => res.result)
}

export const fetchCompanyTypesByClient = (clientUID: string) => {
  return appApi.get<IApiCompanyItem[]>(`/clients/${clientUID}/company_types/`).then((res) => res.result)
}
