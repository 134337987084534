import {
  Box,
  generateUtilityClasses,
  Grid,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material'
import { styled } from '@mui/system'
import { UspFormPackDetailViewResult } from '../../../../../../../models'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDeleteFormPackDetailsMutation, usePackReorderMutation } from '../../../../../../../services'
import { storageGet, storageSet } from '../../../../../../../utils/storage'
import { useDispatch } from 'react-redux'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../../../utils/sorting'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { CustomCircular, DropdownField } from '../../../../../../../ui'
import ConfirmDialog from '../../../../../../../components/ConfirmDialog'
import { enqueueNotification } from '../../../../../../../redux/slices/notificationsSlice'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import CheckIcon from '@mui/icons-material/Check'
import { parseToNumber } from '../../../../../../../utils/general'
import { useForm, useFormState } from 'react-final-form'
const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'loading', 'tableHead', 'lastColumn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.lastColumn}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
}))

type TReorder = {
  label: string
  value: number
  isCurrent: boolean
}

interface Props {
  data: UspFormPackDetailViewResult[]
  loading: boolean
  setOpenAddEditModal: Dispatch<SetStateAction<boolean>>
  setType: Dispatch<SetStateAction<string>>
  setRowData: Dispatch<SetStateAction<UspFormPackDetailViewResult>>
  submit: (...args: any) => any
}

const FormListTableBlock = ({ data, loading, setOpenAddEditModal, setRowData, setType, submit }: Props) => {
  const [deleteFormPackList, { isSuccess: deleteIsSuccess }] = useDeleteFormPackDetailsMutation()
  const [parkReorderRequest, { isSuccess: packReorderRequestIsSuccess }] = usePackReorderMutation()
  const initialOrder = (storageGet('form_list_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('form_list_order_by') || 'PrintOrder') as keyof UspFormPackDetailViewResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspFormPackDetailViewResult>(initialOrderBy)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<{ formPackId: string } | undefined>(undefined)
  const dispatch = useDispatch()
  const form = useForm()

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [selectedItem, setSelectedItem] = useState<UspFormPackDetailViewResult | null>(null)
  const [printOrder, setPrintOrder] = useState<number | null>(null)
  const [dropdownOptions, setDropdownOptions] = useState<TReorder[]>([])
  const [isCurrentSelected, setIsCurrentSelected] = useState<TReorder | undefined | boolean>(false)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspFormPackDetailViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('form_list_order', isAsc ? 'desc' : 'asc')
    storageSet('form_list_order_by', property)
  }

  const createSortHandler = (property: keyof UspFormPackDetailViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleOpen = () => {
    setType('edit')
    setOpenAddEditModal(true)
  }

  const handleDelete = async (): Promise<void> => {
    if (deleteConfirmDialog?.formPackId) {
      await deleteFormPackList(deleteConfirmDialog)
    }
    setDeleteConfirmDialog(undefined)
  }

  useEffect(() => {
    if (deleteIsSuccess) {
      submit()
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [deleteIsSuccess])

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>, item: UspFormPackDetailViewResult) => {
    const updatedDropdownOptions = data?.map((_, index) => ({
      label: item.PrintOrder === index + 1 ? `${index + 1} (current)` : (index + 1)?.toString(),
      value: index + 1,
      isCurrent: item?.PrintOrder === index + 1,
    }))
    setDropdownOptions(updatedDropdownOptions)
    setAnchorEl(event.currentTarget)
    setSelectedItem(item)
    setPrintOrder(item.PrintOrder)
    setIsCurrentSelected(true)
    form.change('Position', item.PrintOrder)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
    setSelectedItem(null)
    setPrintOrder(null)
    form.change('Position', null)
  }

  const handleReorderPack = () => {
    const printOrderValue = typeof printOrder === 'string' ? printOrder : printOrder?.toString()
    const parsedPrintOrder = parseToNumber(printOrderValue as string)

    if (parsedPrintOrder && parsedPrintOrder !== 0) {
      parkReorderRequest({
        formPackId: selectedItem?.FormPackDetailID?.toString() as string,
        uspFormPackDetailReorder: {
          printOrder: parsedPrintOrder,
        },
      })
    }
  }

  useEffect(() => {
    if (packReorderRequestIsSuccess) {
      submit()
      handleClosePopover()
      dispatch(enqueueNotification({ message: 'Success' }))
    }
  }, [packReorderRequestIsSuccess])

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }} className={classes.root}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'FormState' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'FormState'}
                    direction={orderBy === 'FormState' ? order : 'asc'}
                    onClick={createSortHandler('FormState')}
                  >
                    State
                    {orderBy === 'FormState' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Name' ? order : false}>
                  <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                    Form Name
                    {orderBy === 'Name' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.lastColumn} sortDirection={orderBy === 'Version' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Version'}
                    direction={orderBy === 'Version' ? order : 'asc'}
                    onClick={createSortHandler('Version')}
                  >
                    Version
                    {orderBy === 'Version' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.lastColumn} sortDirection={orderBy === 'Quantity' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Quantity'}
                    direction={orderBy === 'Quantity' ? order : 'asc'}
                    onClick={createSortHandler('Quantity')}
                  >
                    Print Quantity
                    {orderBy === 'Quantity' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.lastColumn} sortDirection={orderBy === 'PrintOrder' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'PrintOrder'}
                    direction={orderBy === 'PrintOrder' ? order : 'asc'}
                    onClick={createSortHandler('PrintOrder')}
                  >
                    Print Order
                    {orderBy === 'PrintOrder' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.lastColumn} align={'right'}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), data as any)?.map((item) => (
                <TableRow key={item.ID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell
                    onClick={() => {
                      handleOpen()
                      setRowData(item as any)
                    }}
                  >
                    {item.FormState}
                  </TableCell>
                  <TableCell>{item?.Name}</TableCell>
                  <TableCell>{item?.Version}</TableCell>
                  <TableCell>{item?.Quantity}</TableCell>
                  <TableCell>
                    {item?.PrintOrder}
                    <IconButton sx={{ margin: 0, padding: 0 }} onClick={(e) => handleOpenPopover(e, item)}>
                      <ArrowDropUpIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell align={'right'}>
                    <Tooltip title='Edit'>
                      <span>
                        <IconButton
                          color='primary'
                          onClick={() => {
                            handleOpen()
                            setRowData(item as any)
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title='Delete'>
                      <span>
                        <IconButton
                          color='error'
                          onClick={() => {
                            setDeleteConfirmDialog({ formPackId: item.FormPackDetailID?.toString() as string })
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
      <CustomCircular open={loading} />
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Are you sure you want to delete this document from the pack?'}
      />
      <Popover
        open={open}
        id={id}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ minWidth: '150px' }}
      >
        <Box sx={{ p: 1.5, border: 'none', minWidth: '150px', display: 'flex', alignItems: 'center', gap: '5px' }}>
          <DropdownField
            name='Position'
            label='Position'
            options={dropdownOptions}
            fullWidth
            initialValue={printOrder}
            optionKey={'value'}
            optionValue={'label'}
            onChange={(e) => {
              const value = Number(e)
              const isCurrent = dropdownOptions?.find((option: TReorder) => option?.value === value && option?.isCurrent)
              setIsCurrentSelected(isCurrent)
              if (!isCurrent) {
                setPrintOrder(value)
              }
            }}
          />

          <IconButton onClick={handleReorderPack} disabled={isCurrentSelected as boolean}>
            <CheckIcon color={isCurrentSelected ? 'disabled' : 'success'} />
          </IconButton>
        </Box>
      </Popover>
    </>
  )
}

export default FormListTableBlock
