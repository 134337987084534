import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ClientBranchData, UserClients, UserPermissionsDropdown } from '../../models'

export type Auth = {
  refresh?: string | null
  access?: string | null
  clients_list?: UserClients[]
  branch?: string | null
  branchData?: ClientBranchData[]
  companyType?: string | null
  client?: string | null
  authInProgress?: boolean
}

export const slice = createSlice({
  name: 'userDropdown',
  initialState: {} as UserPermissionsDropdown,
  reducers: {
    setUserDropdown: (state, { payload }) => {
      return payload
    },
    clearUserDropdowns: () => {
      return {}
    },
  },
})

export const getBranch = (state: RootState): any[] => {
  const companyType = state.auth?.companyType
  const PermissionsList = state?.userDropdown?.PermissionsList
  if (companyType && PermissionsList) {
    return state?.userDropdown?.PermissionsList[companyType]?.Branches || []
  }
  return []
}

export const getUserDropdown = (state: RootState): any => state?.userDropdown
export const getContactSuffixes = (state: RootState): any => state?.userDropdown?.ContactSuffixes || []
export const getStates = (state: RootState): any => state?.userDropdown?.States || []
export const getPhoneTypes = (state: RootState): any => state?.userDropdown?.PhoneTypes || []
export const getPermissionsList = (state: RootState): any => state?.userDropdown?.PermissionsList || []
export const isUserDropdownEmpty = (state: RootState): boolean => Object.keys(state?.userDropdown)?.length === 0

export const getCompanyType = (state: RootState): any => state?.companyType || 'Sales'

export const { setUserDropdown, clearUserDropdowns } = slice.actions

export default slice.reducer
