import React, { useCallback, useEffect } from 'react'
import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { CheckboxField } from '../../../../ui'
import { useFormState } from 'react-final-form'
import { debounce } from 'lodash'

const classes = generateUtilityClasses('Grid', ['root', 'titleWrap', 'checkboxWrap',])

const StyledGrid = styled(Grid)(({ theme }) => ({
    [`&.${classes.root}`]: {
        padding: theme.spacing(1),
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.main,
        width: '100%',
        maxWidth: 'unset',
        position: 'sticky',
        top: 0,
        zIndex: 10,
    },
    [`& .${classes.titleWrap}`]: {
        margin: theme.spacing(2, 2, 1, 2),
    },
    [`& .${classes.checkboxWrap}`]: {
        width: theme.spacing(30),
        margin: theme.spacing(0, 4, 0, 0),
    },
}))

interface Props {
    title: string
    submit: (...args: any) => any
}
const Header = ({ title, submit, }: Props) => {
    const { values } = useFormState()

    const updateWithDebounce = useCallback(
        debounce(() => submit(values), 500),
        [],
    )

    useEffect(() => {
        // search with debounce
        if (values?.isIncludeInactive || !values?.isIncludeInactive) {
            updateWithDebounce()
        }
    }, [values?.isIncludeInactive])

    return (
        <>
            <StyledGrid className={classes.root}>
                <Grid item className={classes.titleWrap}>
                    <Typography variant='h3'>{title}</Typography>
                </Grid>
                <Grid className={classes.checkboxWrap}>
                    <CheckboxField name='isIncludeInactive' label='Include Inactive' />
                </Grid>
            </StyledGrid>
        </>
    )
}

export default Header
