import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { ReactComponent as SixangleIcon } from '../../../../../assets/icons/sixangle.svg'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Grid', [
  'root',
  'row',
  'body',
  'title',
  'mainIcon',
  'addIcon',
  'userIcon',
  'linkText',
  'titleSubtitle',
  'statusHighlighted',
  'statusHighlightedYellow',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.body}`]: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.titleSubtitle}`]: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  [`& .${classes.mainIcon}`]: {
    padding: theme.spacing(0, 2, 0, 0),
    fill: theme.palette.text.primary,
  },
  [`& .${classes.addIcon}`]: {
    width: '45px',
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.userIcon}`]: {
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.linkText}`]: {
    width: '95px',
    fontWeight: 600,
    fontSize: '1em',
    lineHeight: '1.1875em',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.statusHighlighted}`]: {
    width: '140px',
    height: '22px',
    textAlign: 'center',
    color: theme.palette.text.button,
    fontWeight: '600',
    fontSize: '0.85em',
    background: theme.palette.success.main,
    margin: theme.spacing(1, 0, 1, 1),
    borderRadius: '4px',
  },
  [`& .${classes.statusHighlightedYellow}`]: {
    background: theme.palette.warning.main,
  },
}))

interface Props {}

export const CreditinsightsForm = ({}: Props) => {
  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.row}>
        <SixangleIcon className={classes.mainIcon} />
        <Typography variant='h1' className={classes.title}>
          Credit insights
        </Typography>
        <Typography variant='h3' className={classes.titleSubtitle}>
          (Coming soon)
        </Typography>
      </Grid>
    </StyledGrid>
  )
}

export default CreditinsightsForm
