import React, { useEffect, useMemo } from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useForm, useFormState } from 'react-final-form'
import { PercentField } from '../../../../../../../ui'
import AmountField from '../../../../../../../ui/AmountField'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import { SearchLenderApiResponse, UspListLender } from '../../../../../../../models'
import { parseToNumber, roundAmount } from '../../../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  areFieldsVisible: boolean
  lenderListDate: SearchLenderApiResponse
  setSelectedLender: (lender: object) => void
  dealLenderList: UspListLender[]
  totalPickups: string | number
  disableFields: boolean
}

export const FinancingForm = ({ areFieldsVisible, lenderListDate = [], setSelectedLender, dealLenderList, totalPickups, disableFields }: Props) => {
  const { values, initialValues } = useFormState()
  const form = useForm()

  const getMatchedLender = (id: string) => dealLenderList?.find((lender: any) => lender?.ID === id)

  // Optimized dropdown options using useMemo
  const lenderDropdownOptions = useMemo(
    () =>
      lenderListDate?.map((lender) => ({
        key: lender?.ID,
        value: lender?.Name,
      })) || [],
    [lenderListDate],
  )

  const selectLender = (lenderId: any) => {
    const selectedLender = lenderListDate?.find((lender) => lender?.ID === lenderId)
    setSelectedLender(selectedLender || {})
  }

  // Handle the lender change event
  const handleLenderChange = (selectedOption: any) => {
    selectLender(selectedOption)
  }

  useEffect(() => {
    const selectedLenderID = values.LenderID || initialValues.LenderID

    if (selectedLenderID) {
      selectLender(selectedLenderID)
    }
  }, [values.LenderID, initialValues.LenderID])

  useEffect(() => {
    const matchedLender = getMatchedLender(values?.LenderID)
    if (matchedLender) {
      let totalPickupsAmount = parseToNumber((totalPickups as string) || '')
      if (!matchedLender?.IsDiscountIncludePickups) {
        totalPickupsAmount = 0
      }

      if (values?.DiscountType === 'rate') {
        // Ensure data is available and valid
        const amountFinanced = parseToNumber(values?.AmountFinanced || '')
        const discountRate = parseToNumber(values?.DiscountRate || '')
        if (!isNaN(amountFinanced) && !isNaN(discountRate) && discountRate !== undefined) {
          let discountAmount = ((amountFinanced + totalPickupsAmount) * discountRate) / 100
          form.change('DiscountAmount', roundAmount(discountAmount.toString()))
        }
      } else if (values?.DiscountType === 'amount') {
        const amountFinanced = parseToNumber(values?.AmountFinanced || '')
        const discountAmount = parseToNumber(values?.DiscountAmount || '')
        if (!isNaN(amountFinanced) && !isNaN(discountAmount) && amountFinanced + totalPickupsAmount !== 0) {
          let discountRate = (discountAmount / (amountFinanced + totalPickupsAmount)) * 100
          form.change('DiscountRate', roundAmount(discountRate.toString()))
        }
      }
    }
  }, [values?.LenderID, values?.DiscountType, values?.DiscountAmount, values?.DiscountRate, totalPickups])

  useEffect(() => {
    const matchedLender = getMatchedLender(values?.LenderID)
    if (matchedLender) {
      if (
        (matchedLender && matchedLender.DiscountAmount && parseToNumber(matchedLender.DiscountAmount || '') > 0) ||
        (matchedLender && matchedLender.DiscountRate > 0)
      ) {
        form.change('DiscountRate', matchedLender?.DiscountRate?.toString())
      }
      if (
        matchedLender &&
        matchedLender.DiscountAmount &&
        parseToNumber(matchedLender.DiscountAmount || '') > 0 &&
        matchedLender?.DiscountRate === 0
      ) {
        form.change('DiscountAmount', matchedLender?.DiscountAmount?.toString())
      }
    }
  }, [values?.LenderID, dealLenderList, values?.DiscountType])

  useEffect(() => {
    const matchedLender = getMatchedLender(values?.LenderID)
    if (matchedLender) {
      if (
        (matchedLender && matchedLender.DiscountAmount && parseToNumber(matchedLender.DiscountAmount || '') > 0) ||
        (matchedLender && matchedLender.DiscountRate > 0)
      ) {
        form.change('DiscountType', 'rate')
      }
      if (
        matchedLender &&
        matchedLender.DiscountAmount &&
        parseToNumber(matchedLender.DiscountAmount || '') > 0 &&
        matchedLender?.DiscountRate === 0
      ) {
        form.change('DiscountType', 'amount')
      }
    }
  }, [values?.LenderID, dealLenderList])

  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.row}>
        <Typography variant='subtitle1' gutterBottom>
          Financing
        </Typography>
      </Grid>
      <Grid className={classes.row}>
        <DropdownFieldSearch disabled={disableFields} name='LenderID' label='Lender' options={lenderDropdownOptions} onChange={handleLenderChange} />
      </Grid>
      {!areFieldsVisible && (
        <>
          <Grid className={classes.row}>
            <DropdownFieldSearch
              disabled={disableFields}
              name='DiscountType'
              label='Discount Type'
              options={[
                { key: 'rate', value: 'Rate' },
                { key: 'amount', value: 'Amount' },
              ]}
              onChange={handleLenderChange}
            />
          </Grid>
          {values.DiscountType === 'rate' && (
            <>
              <Grid className={classes.row}>
                <PercentField variant='standard' disabled={disableFields} name='DiscountRate' label='Discount Rate' fullWidth />
              </Grid>
              <Grid className={classes.row}>
                <AmountField variant='standard' disabled={true} name='DiscountAmount' label='Discount Amount' fullWidth />
              </Grid>
            </>
          )}
          {values.DiscountType === 'amount' && (
            <>
              <Grid className={classes.row}>
                <AmountField variant='standard' disabled={disableFields} name='DiscountAmount' label='Discount Amount' fullWidth />
              </Grid>
              <Grid className={classes.row}>
                <PercentField variant='standard' disabled={true} name='DiscountRate' label='Discount Rate' fullWidth />
              </Grid>
            </>
          )}
          <Grid className={classes.row}>
            <DropdownFieldSearch
              disabled={disableFields}
              name='SpreadType'
              label='Spread Type'
              options={[
                { key: 'rate', value: 'Rate' },
                { key: 'amount', value: 'Amount' },
              ]}
            />
          </Grid>
          {values.SpreadType === 'rate' && (
            <Grid className={classes.row}>
              <PercentField variant='standard' disabled={disableFields} name='SpreadRate' label='Spread Rate' fullWidth />
            </Grid>
          )}
          {values.SpreadType === 'amount' && (
            <Grid className={classes.row}>
              <AmountField variant='standard' disabled={disableFields} name='SpreadAmount' label='Spread Amount' fullWidth />
            </Grid>
          )}
        </>
      )}
    </StyledGrid>
  )
}

export default FinancingForm
