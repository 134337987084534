import * as yup from 'yup'
import { parseToNumber } from '../utils/general'

export const addStateCountyTaxesValidation: yup.SchemaOf<any> = yup
  .object({
    TaxCapAmount: yup
      .number()
      .transform((value, originalValue) => (originalValue === '' ? null : parseToNumber(originalValue)))
      .min(0, 'Tax Cap Amount cannot be less than 0')
      .typeError('Invalid number'),
  })
  .required()
