import { CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import React from 'react'
import { styled } from '@mui/system'
import { AmountField } from '../../../../../../../../../ui'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

const PricingForm = ({}: Props) => {
  const { values } = useFormState()
  const carCondition = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarCondition'))
  const bookValueSource = useSelector((state) => selectDropdownByName(state, 'BookValueSource'))

  return (
    <>
      <CardHeader title='Pricing' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='AutoMarkup'
            label='Auto Markup?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            initialValue={false}
          />
        </Grid>
        {values?.AutoMarkup !== true && (
          <Grid item xs={6}>
            <AmountField variant='standard' name='StickerPrice' label='Sticker Price' fullWidth />
          </Grid>
        )}
        {carCondition && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='Condition'
              label='Condition'
              options={carCondition}
              optionKey='ID'
              optionValue='Condition'
              autoComplete='off'
            />
          </Grid>
        )}

        {bookValueSource?.ReturnData &&
          bookValueSource?.ReturnData?.map((item, index) => (
            <Grid item xs={6}>
              <AmountField variant='standard' name={`BookValue${index + 1}`} label={item?.BookValueSource as string} fullWidth />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default PricingForm
