import React, { useCallback, useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { debounce } from 'lodash'
import { Grid, InputAdornment, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DropdownCheckboxes, TextField } from '../../../../../ui'
import SearchIcon from '@mui/icons-material/Search'
import Iconify from '../../../../../components/iconify'

const classes = generateUtilityClasses('Grid', ['root', 'titleWrap', 'searchWrap', 'checkboxWrap', 'searchBtn', 'popoverWrap', 'searchInputWrap'])
const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    position: 'sticky',
    top: 0,
    zIndex: 10,
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.checkboxWrap}`]: {
    margin: theme.spacing(1, 4, 0, 0),
    fontSize: '14px',
  },
  [`& .${classes.searchBtn}`]: {
    width: '120px',
    margin: theme.spacing(0, 2, 0, 'auto'),
  },
  [`& .${classes.popoverWrap}`]: {
    width: '3000px',
    padding: theme.spacing(4),
  },
  [`& .${classes.searchInputWrap}`]: {
    width: theme.spacing(38.5),
    margin: theme.spacing(0, 4, 0, 0),
  },
}))

const AccountsListSearchBlock = ({ submit }: { submit: (...args: any) => any; error: any }) => {
  const branchData = useSelector(getBranch)
  const { values } = useFormState()
  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.searchString || values?.searchString !== '') {
      updateWithDebounce()
    }
  }, [values?.searchString])

  useEffect(() => {
    // search with debounce
    if (values?.accountStatusOption) {
      updateWithDebounce()
    }
  }, [values?.accountStatusOption])

  useEffect(() => {
    if (values?.branch) {
      updateWithDebounce()
    }
  }, [values?.branch])

  return (
    <Grid container spacing={2} p={3} alignItems={'center'}>
      <Grid item xs={5}>
        <TextField
          name='searchString'
          placeholder='Search'
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {branchData?.length > 1 && (
        <Grid item xs={3}>
          <DropdownCheckboxes
            name='branch'
            label='Branch'
            labelId='BranchId'
            fullWidth
            options={branchData}
            optionKey='BranchID'
            optionValue='Branch'
          />
        </Grid>
      )}
      <Grid item xs={3}>
        <DropdownFieldSearch
          name='accountStatusOption'
          label='Status'
          fullWidth
          options={[
            { key: 1, value: 'All' },
            { key: 2, value: 'Active' },
            { key: 3, value: 'Inactive' },
          ]}
        />
      </Grid>
    </Grid>
  )
}

export default AccountsListSearchBlock
