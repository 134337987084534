import { Button, Card, Grid, Menu, MenuItem, Paper, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Chart } from 'react-google-charts'
import { motion } from 'framer-motion'
import { DashboardInventoryApiResponse, UspDashboardCommonResult } from '../../../../../models'

interface Props {
  dashboardData: DashboardInventoryApiResponse
}

export interface BranchData {
  Branch: string
  [key: `Number_${number}`]: number
  [key: `Cost_${number}`]: number
}

const InventoryAgingCard = ({ dashboardData }: Props) => {
  const [anchorElBranch, setAnchorElBranch] = useState<null | HTMLElement>(null)
  const [selectedBranch, setSelectedBranch] = useState('All')
  const [chartDataUnits, setChartDataUnits] = useState<string[][]>([])
  const [chartDataCost, setChartDataCost] = useState<string[][]>([])
  const [branches, setBranches] = useState<string[]>([])

  const theme = useTheme()

  const openBranch = Boolean(anchorElBranch)

  const handleClickBranch = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElBranch(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElBranch(null)
  }

  const handleSelectBranch = (branch: string) => {
    setSelectedBranch(branch)
    handleClose()
  }

  useEffect(() => {
    const dashboard: UspDashboardCommonResult | undefined = dashboardData?.find((d) => d.DashboardName === 'InventoryAging')
    const dashboardAll: UspDashboardCommonResult | undefined = dashboardData?.find((d) => d.DashboardName === 'InventoryAgingAll')

    const dataInventoryAging: BranchData[] = dashboard ? JSON?.parse(dashboard.Data) : []
    const dataInventoryAgingAll: BranchData[] = dashboardAll ? JSON?.parse(dashboardAll.Data) : []

    let branchData: BranchData | undefined
    if (selectedBranch === 'All') {
      branchData = dataInventoryAgingAll[0]
    } else {
      branchData = dataInventoryAging?.find((d) => d?.Branch === selectedBranch)
    }

    if (!branchData) {
      return
    }

    const branchesFromData: string[] = Array?.from(new Set(dataInventoryAging?.map((d) => d.Branch)))

    setBranches(branchesFromData)

    const newChartDataUnits = [['Range', 'Units']]
    const newChartDataCost = [['Range', 'Cost']]
    const dataRanges = ['1to30', '31to60', '61to90', '91plus']

    dataRanges?.forEach((range) => {
      newChartDataUnits?.push([range?.replace('1to', '1 - ')?.replace('plus', '+ Days'), branchData?.[`Number_${range as any}`] || (0 as any)])
      newChartDataCost?.push([range?.replace('1to', '1 - ')?.replace('plus', '+ Days'), branchData?.[`Cost_${range as any}`] || (0 as any)])
    })

    setChartDataUnits(newChartDataUnits)
    setChartDataCost(newChartDataCost)
  }, [dashboardData, selectedBranch])

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.8,
        delay: 0.5,
        ease: [0, 0.71, 0.2, 1.01],
      }}
    >
      <Card>
        <Grid container sx={{ padding: '20px' }}>
          <Grid item sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <Grid item sx={{ textAlign: 'center', width: '100%' }}>
              <Typography variant='h6'>Inventory Aging</Typography>
            </Grid>
            <Grid item>
              <Button
                id='branch-button'
                aria-controls={openBranch ? 'branch-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openBranch ? 'true' : undefined}
                onClick={handleClickBranch}
              >
                {selectedBranch}
              </Button>
              <Menu
                id='branch-menu'
                MenuListProps={{
                  'aria-labelledby': 'branch-button',
                }}
                anchorEl={anchorElBranch}
                open={openBranch}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleSelectBranch('All')}>All</MenuItem>
                {branches.map((branch) => (
                  <MenuItem key={branch} onClick={() => handleSelectBranch(branch)}>
                    {branch}
                  </MenuItem>
                ))}
              </Menu>
            </Grid>
          </Grid>

          <Grid container spacing={2} xs={12}>
            <Grid item xs={12} md={12} lg={6} xl={6}>
              <Chart
                chartType='ColumnChart'
                height='400px'
                data={chartDataUnits}
                options={{
                  titleTextStyle: {
                    fontSize: 15,
                    color: theme.palette?.text?.primary,
                  },
                  chartArea: { width: '80%', height: '80%', left: '10%' },
                  backgroundColor: theme.palette.background.paper,
                  hAxis: {
                    title: 'Days',
                    textStyle: {
                      color: theme.palette.text.primary,
                    },
                    titleTextStyle: {
                      color: theme.palette.text.primary,
                    },
                  },
                  vAxis: {
                    title: 'Units',
                    textStyle: {
                      color: theme.palette.text.primary,
                      fontSize: 12,
                    },
                    titleTextStyle: {
                      color: theme.palette.text.primary,
                    },
                  },
                  legend: { position: 'none' },
                  slantedText: true,
                }}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={6} xl={6}>
              <Chart
                chartType='ColumnChart'
                width='100%'
                height='400px'
                data={chartDataCost}
                options={{
                  titleTextStyle: {
                    color: theme.palette.text.primary,
                  },
                  chartArea: {
                    left: '15%',
                    width: '75%',
                    height: '80%',
                  },
                  backgroundColor: theme.palette.background.paper,
                  hAxis: {
                    title: 'Days',
                    textStyle: {
                      color: theme.palette.text.primary,
                    },
                    titleTextStyle: {
                      color: theme.palette.text.primary,
                    },
                  },
                  vAxis: {
                    format: 'short',
                    title: 'Cost',
                    textStyle: {
                      color: theme.palette.text.primary,
                      fontSize: 12,
                    },
                    titleTextStyle: {
                      color: theme.palette.text.primary,
                    },
                    slantedText: true,
                  },
                  legend: { position: 'none' },
                  colors: ['#885AF8'],
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </motion.div>
  )
}

export default InventoryAgingCard
