import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { FormControlLabel, Grid, IconButton, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { Field, useFormState } from 'react-final-form'
import AmountField from '../../../../../../../ui/AmountField'
import PaymentsPopover from '../PaymentsPopover'
import EditIcon from '@mui/icons-material/Edit'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import removeMask from '../../../../../../../utils/removeMask'
import Iconify from '../../../../../../../components/iconify'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  paymentsForm: string[]
  setTotalPickups?: Dispatch<SetStateAction<boolean>>
  formPayments: string[]
  setFormPayments: Dispatch<SetStateAction<string[]>>
  setPaymentsForm: Dispatch<SetStateAction<string[]>>
  setIsDownPaymentDeleteCalled: Dispatch<SetStateAction<boolean>>
  disableFields: boolean
  dealType: string | undefined
}

export const DownPaymentsForm = ({
  paymentsForm,
  setTotalPickups,
  formPayments,
  setFormPayments,
  setPaymentsForm,
  setIsDownPaymentDeleteCalled,
  disableFields,
  dealType,
}: Props) => {
  const { values } = useFormState()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget)
  }

  const computePickupPayments = (values: { [key: string]: any }) => {
    let amount = 0
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('DatePaymentAmount') && values[item]) {
        amount += Number(removeMask(values[item]))
      }
    })
    return amount?.toString()
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid container className={classes.row}>
          <Typography variant='subtitle1' gutterBottom>
            {dealType !== 'Cash' ? 'Down Payments' : 'Trade'}
          </Typography>
        </Grid>
        {dealType !== 'Cash' && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled={disableFields} name='CashDown' label='Cash Down' fullWidth />
          </Grid>
        )}
        {dealType !== 'Cash' && (
          <Grid className={classes.row}>
            <AmountField
              variant='standard'
              disabled={disableFields}
              name='PickupPayments'
              label='Pickup Payments'
              fullWidth
              readonly
              endAdornment={
                <IconButton disabled={disableFields} color='primary' onClick={handleClick}>
                  <Iconify icon='solar:pen-bold' />
                </IconButton>
              }
            />
          </Grid>
        )}
        <Grid className={classes.row}>
          {/* <DropdownFieldSearch
            disabled={disableFields}
            name='DealHasTrade'
            label='Deal Has Trade?' // TODO DON'T SHOW TradeAllowance TradeLien TradeACV IF NO, DEFUALUT 0
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
          /> */}
          <Field name='DealHasTrade'>
            {(props) => (
              <div>
                <FormControlLabel
                  // sx={{ m: 2, top: 18, position: 'absolute', left: 80 }}
                  control={
                    <Switch
                      name={props.input.name}
                      checked={props.input.value === true}
                      onChange={(e) => {
                        props.input.onChange(e.target.checked ? true : false)
                      }}
                    />
                  }
                  label='Has Trade?'
                />
              </div>
            )}
          </Field>
        </Grid>
        {values?.DealHasTrade && (
          <>
            <Grid className={classes.row}>
              <AmountField variant='standard' disabled={disableFields} name='TradeAllowance' label='Trade Allowance' fullWidth />
            </Grid>
            <Grid className={classes.row}>
              <AmountField variant='standard' disabled={disableFields} name='TradeLien' label='Trade Lien' fullWidth />
            </Grid>
            <Grid className={classes.row}>
              <AmountField variant='standard' disabled={disableFields} name='TradeACV' label='Trade ACV' fullWidth />
            </Grid>
          </>
        )}
      </StyledGrid>
      <PaymentsPopover
        setIsDownPaymentDeleteCalled={setIsDownPaymentDeleteCalled}
        setTotalPickups={setTotalPickups}
        computePickupPayments={computePickupPayments}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        paymentsForm={paymentsForm}
        formPayments={formPayments}
        setFormPayments={setFormPayments}
        setPaymentsForm={setPaymentsForm}
      />
    </>
  )
}

export default DownPaymentsForm
