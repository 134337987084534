import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { AmountField, PercentField, TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

export const FeesForm = ({ onDropdownFormChange }: Props) => {
  return (
    <>
      <CardHeader title='Fees' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={12} md={6}>
          <TextField name='LateChargeGracePeriod' label='Late Charge Grace Period (days)' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField variant='standard' name='LateChargeRate' label='Late Charge Rate (%)' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountField variant='standard' name='LateChargeMax' label='Late Charge Max ($)' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountField variant='standard' name='LateChargeMin' label='Late Charge Min ($)' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='LateChargeBased'
            label='Late Charge Based On Past Due Amount?'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountField variant='standard' name='ChargebackFee' label='Chargeback Fee ($)' fullWidth />
        </Grid>
        <Grid item xs={12} md={6}>
          <PercentField variant='standard' name='AdvanceDueDateRate' label='Advance Due Date Rate (%)' fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='ApplyFees'
            label='Apply Fees Collected Towards Advance?'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountField variant='standard' name='PaymentProcessingFee' label='Payment Processing Fee ($)' fullWidth />
        </Grid>

        <Grid item xs={12} md={6}>
          <DropdownFieldSearch
            name='ProcessingFeeOnCreditCardPayment'
            label='Processing Fee On Credit Card Payment?'
            fullWidth
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <AmountField variant='standard' name='DocStamps' label='Doc Stamps (per $100)' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default FeesForm
