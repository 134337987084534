import { convertDateToString } from '../../../utils/dateUtils'
import removeMask from '../../../utils/removeMask'

export const transformToFormValues = (values?: any) => ({
  IsOutForRepo: values?.IsOutForRepo,
  OutForRepoDate: values?.DateOutForRepo,
  RepoVendor: values?.RepoVendorID,
  BillNumber: values?.BillNumber,
  RepossessionCost: values?.RepoCost,
  RepoType: values?.RepoTypeID,
  RepoOrderedBy: values?.RepoOrderedByUserID,
  IsVehiclePickedUp: values?.IsVehiclePickedUp,
  DatePickedUp: values?.DateVehiclePickedUp,
  VehicleLocation: values?.VehicleLocation,
  CurrentMileage: values?.RepoMileage,
  BookValue: values?.RepoBookValue,
  EstimatedRepairs: values?.EstimatedRepairs,
  IsRepoRedeemed: values?.IsRepoRedeemed,
  RedemptionDate: values?.DateRepoRedeemed,
  Note: values?.Note,
})

export const transformToRequestValues = (values?: any) => ({
  isOutForRepo: values?.IsOutForRepo,
  dateOutForRepo: convertDateToString(values?.OutForRepoDate),
  repoVendorID: values?.RepoVendor,
  billNumber: values?.BillNumber,
  repoOrderedByUserID: values?.RepoOrderedBy,
  isVehiclePickedUp: values?.IsVehiclePickedUp,
  dateVehiclePickedUp: convertDateToString(values?.DatePickedUp),
  isRepoRedeemed: values?.IsRepoRedeemed,
  dateRepoRedeemed: convertDateToString(values?.RedemptionDate),
  vehicleLocation: values?.VehicleLocation,
  repoMileage: values?.CurrentMileage,
  repoBookValue: removeMask(values?.BookValue as any) || '',
  estimatedRepairs: removeMask(values?.EstimatedRepairs) || '',
  repoCost: removeMask(values?.RepossessionCost) || '',
  repoTypeID: values?.RepoType,
  note: values?.Note || null,
})
