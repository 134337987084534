import { useState, useEffect, useCallback } from 'react'

import Card from '@mui/material/Card'
import Stack from '@mui/material/Stack'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'

// import { useGetContacts, useGetConversation, useGetConversations } from 'src/api/chat'

import ChatMessageList from '../chat-message-list'
import ChatMessageInput from '../chat-message-input'
import { IChatMessage, IChatParticipant } from '../chat'
import { Box } from '@mui/material'
import { useSendMessageMutation } from '../../../services/AI/AIApi'
import ChatHeaderDetail from '../chat-header-detail'

// ----------------------------------------------------------------------

export default function ChatView() {
  const [recipients, setRecipients] = useState<IChatParticipant[]>([])
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState<any>([])
  const [sendMessage, { isSuccess: sendMessageSuccess, isLoading }] = useSendMessageMutation()
  const matches = useMediaQuery('(min-width:600px)')

  const conversation = {
    id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
    participants: [
      {
        id: '8864c717-587d-472a-929a-8e5f298024da-0',
        role: 'admin',
        status: 'online',
        name: 'Jaydon Frankie',
        email: 'demo@minimals.cc',
        phoneNumber: '+40 777666555',
        address: '90210 Broadway Blvd',
        avatarUrl: 'https://api-prod-minimal-v6.pages.dev/assets/images/avatar/avatar-25.webp',
        lastActivity: '2024-08-07T05:55:09+00:00',
      },
      {
        id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        role: 'CTO',
        email: 'ashlynn.ohara62@gmail.com',
        name: 'Lucian Obrien',
        lastActivity: '2024-08-06T04:56:09+00:00',
        address: '1147 Rohan Drive Suite 819 - Burlington, VT / 82021',
        avatarUrl: 'https://api-prod-minimal-v6.pages.dev/assets/images/avatar/avatar-2.webp',
        phoneNumber: '+1 416-555-0198',
        status: 'online',
      },
    ],
    type: 'ONE_TO_ONE',
    unreadCount: 0,
    messages: [
      {
        id: '1e654042-2382-44a8-9d8d-ea351f696269',
        senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        body: 'She eagerly opened the gift, her eyes sparkling with excitement.',
        contentType: 'text',
        attachments: [
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1',
            name: 'cover-2.jpg',
            path: 'https://api-prod-minimal-v6.pages.dev/assets/images/cover/cover-3.webp',
            preview: 'https://api-prod-minimal-v6.pages.dev/assets/images/cover/cover-3.webp',
            size: 48000000,
            createdAt: '2024-08-07T05:56:09+00:00',
            modifiedAt: '2024-08-07T05:56:09+00:00',
            type: 'jpg',
          },
        ],
        createdAt: '2024-08-07T00:56:09+00:00',
      },
      {
        id: '2be258bc-9358-486f-9c19-75cee7a7ef06',
        senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
        body: 'The old oak tree stood tall and majestic, its branches swaying gently in the breeze.',
        contentType: 'text',
        attachments: [
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
            name: 'design-suriname-2015.mp3',
            path: 'https://www.cloud.com/s/c218bo6kjuqyv66/design_suriname_2015.mp3',
            preview: 'https://www.cloud.com/s/c218bo6kjuqyv66/design_suriname_2015.mp3',
            size: 24000000,
            createdAt: '2024-08-06T04:56:09+00:00',
            modifiedAt: '2024-08-06T04:56:09+00:00',
            type: 'mp3',
          },
        ],
        createdAt: '2024-08-07T01:56:09+00:00',
      },
      {
        id: '9764685d-d474-458c-9c82-a7601401ced1',
        senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        body: 'The aroma of freshly brewed coffee filled the air, awakening my senses.',
        contentType: 'text',
        attachments: [
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b3',
            name: 'expertise-2015-conakry-sao-tome-and-principe-gender.mp4',
            path: 'https://www.cloud.com/s/c218bo6kjuqyv66/expertise_2015_conakry_sao-tome-and-principe_gender.mp4',
            preview: 'https://www.cloud.com/s/c218bo6kjuqyv66/expertise_2015_conakry_sao-tome-and-principe_gender.mp4',
            size: 16000000,
            createdAt: '2024-08-05T03:56:09+00:00',
            modifiedAt: '2024-08-05T03:56:09+00:00',
            type: 'mp4',
          },
        ],
        createdAt: '2024-08-07T02:56:09+00:00',
      },
      {
        id: '6ba332c9-9373-4e30-864d-72302f962906',
        senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
        body: 'The children giggled with joy as they ran through the sprinklers on a hot summer day.',
        contentType: 'text',
        attachments: [
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b4',
            name: 'money-popup-crack.pdf',
            path: 'https://www.cloud.com/s/c218bo6kjuqyv66/money-popup-crack.pdf',
            preview: 'https://www.cloud.com/s/c218bo6kjuqyv66/money-popup-crack.pdf',
            size: 12000000,
            createdAt: '2024-08-04T02:56:09+00:00',
            modifiedAt: '2024-08-04T02:56:09+00:00',
            type: 'pdf',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5',
            name: 'cover-4.jpg',
            path: 'https://api-prod-minimal-v6.pages.dev/assets/images/cover/cover-4.webp',
            preview: 'https://api-prod-minimal-v6.pages.dev/assets/images/cover/cover-4.webp',
            size: 9600000,
            createdAt: '2024-08-03T01:56:09+00:00',
            modifiedAt: '2024-08-03T01:56:09+00:00',
            type: 'jpg',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b6',
            name: 'cover-6.jpg',
            path: 'https://api-prod-minimal-v6.pages.dev/assets/images/cover/cover-6.webp',
            preview: 'https://api-prod-minimal-v6.pages.dev/assets/images/cover/cover-6.webp',
            size: 8000000,
            createdAt: '2024-08-02T00:56:09+00:00',
            modifiedAt: '2024-08-02T00:56:09+00:00',
            type: 'jpg',
          },
        ],
        createdAt: '2024-08-07T03:56:09+00:00',
      },
      {
        id: '4ca6022f-6471-48f3-af8a-34c13448e0b6',
        senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        body: 'He carefully crafted a beautiful sculpture out of clay, his hands skillfully shaping the intricate details.',
        contentType: 'text',
        attachments: [
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b7',
            name: 'large-news.txt',
            path: 'https://www.cloud.com/s/c218bo6kjuqyv66/large_news.txt',
            preview: 'https://www.cloud.com/s/c218bo6kjuqyv66/large_news.txt',
            size: 6857142.857142857,
            createdAt: '2024-07-31T23:56:09+00:00',
            modifiedAt: '2024-07-31T23:56:09+00:00',
            type: 'txt',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b8',
            name: 'nauru-6015-small-fighter-left-gender.psd',
            path: 'https://www.cloud.com/s/c218bo6kjuqyv66/nauru-6015-small-fighter-left-gender.psd',
            preview: 'https://www.cloud.com/s/c218bo6kjuqyv66/nauru-6015-small-fighter-left-gender.psd',
            size: 6000000,
            createdAt: '2024-07-30T22:56:09+00:00',
            modifiedAt: '2024-07-30T22:56:09+00:00',
            type: 'psd',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b9',
            name: 'tv-xs.doc',
            path: 'https://www.cloud.com/s/c218bo6kjuqyv66/tv-xs.doc',
            preview: 'https://www.cloud.com/s/c218bo6kjuqyv66/tv-xs.doc',
            size: 5333333.333333333,
            createdAt: '2024-07-29T21:56:09+00:00',
            modifiedAt: '2024-07-29T21:56:09+00:00',
            type: 'doc',
          },
          {
            id: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b10',
            name: 'gustavia-entertainment-productivity.docx',
            path: 'https://www.cloud.com/s/c218bo6kjuqyv66/gustavia-entertainment-productivity.docx',
            preview: 'https://www.cloud.com/s/c218bo6kjuqyv66/gustavia-entertainment-productivity.docx',
            size: 4800000,
            createdAt: '2024-07-28T20:56:09+00:00',
            modifiedAt: '2024-07-28T20:56:09+00:00',
            type: 'docx',
          },
        ],
        createdAt: '2024-08-07T04:56:09+00:00',
      },
      {
        id: 'f5868f41-f7f1-4651-a198-c8fcdb2c556c',
        senderId: 'e99f09a7-dd88-49d5-b1c8-1daf80c2d7b2',
        attachments: [],
        contentType: 'image',
        body: 'https://api-prod-minimal-v6.pages.dev/assets/images/cover/cover-5.webp',
        createdAt: '2024-08-07T05:41:09+00:00',
      },
      {
        id: 'b74952b4-a016-45c0-b284-3fead043aa24',
        senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
        contentType: 'text',
        attachments: [],
        body: 'The concert was a mesmerizing experience, with the music filling the venue and the crowd cheering in delight.',
        createdAt: '2024-08-07T05:55:09+00:00',
      },
      {
        id: '1182f6bf-834f-4cd2-9af8-4e3e5d23e19e',
        senderId: '8864c717-587d-472a-929a-8e5f298024da-0',
        body: 'The waves crashed against the shore, creating a soothing symphony of sound.',
        contentType: 'text',
        attachments: [],
        createdAt: '2024-08-07T05:56:09+00:00',
      },
    ],
  }
  const participants = conversation.participants

  const handleAddRecipients = useCallback((selected: IChatParticipant[]) => {
    setRecipients(selected)
  }, [])

  const renderMessages = (
    <Stack
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <ChatMessageList messages={messages} participants={participants as unknown as IChatParticipant[]} />
      <ChatMessageInput
        recipients={recipients}
        onAddRecipients={handleAddRecipients}
        sendMessage={sendMessage}
        isLoading={isLoading}
        setMessages={setMessages}
        selectedConversationId={messages.length > 0 ? messages?.[1]?.sessionData?.[0]?.ID : null}
        disabled={false}
      />
    </Stack>
  )
  const renderHead = (
    <Stack direction='row' alignItems='center' flexShrink={0} sx={{ pr: 1, pl: 2.5, py: 1, minHeight: 72 }}>
      <ChatHeaderDetail />
    </Stack>
  )

  return (
    <Box>
      <Stack component={Card} direction='row' sx={{ height: matches ? '72vh' : 'calc(100dvh - 67px)' }}>
        <Stack
          sx={{
            width: 1,
            height: 1,
            overflow: 'hidden',
          }}
        >
          {renderHead}

          <Stack
            direction='row'
            sx={{
              width: 1,
              height: 1,
              overflow: 'hidden',
            }}
          >
            {renderMessages}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}
