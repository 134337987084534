import React, { useEffect, useMemo, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { CustomCircular } from '../../../ui'
import { useCarFloorplanEditMutation } from '../../../services'
import { Form } from 'react-final-form'
import { Header } from '../components/Header'
import { Box, Card, generateUtilityClasses } from '@mui/material'
import { withCars } from '../../../hoc'
import Paper from '@mui/material/Paper'
import { FloorplanForm, BalanceForm, FeeForm, NoteForm } from './components'
import Button from '@mui/material/Button'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { objectKeysToLowerCase } from '../../../utils/general'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { appRoute } from '../../../constants'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'fieldsWrap', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

export const InventoryEditFloorplan = (props: any) => {
  const { carData, getCar } = props
  const { carID } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [editFloorplan, { isSuccess }] = useCarFloorplanEditMutation()
  const [formChanged, setFormChanged] = useState(false)

  const handleSubmit = (values: any) => {
    setFormChanged(false)
    if (carData?.FloorplanID) {
      editFloorplan({
        floorplanId: carData?.FloorplanID as string,
        uspCarEditFloorplan: {
          floorplanInterestRate: values.floorplanInterestRate,
          floorplanAdminFee: values.floorplanAdminFee,
          floorplanSetupFee: values.floorplanSetupFee,
          floorplanAdditionalFee: values.floorplanAdditionalFee,
          floorplanNote: values.floorplanNote,
        },
      })
    }
  }
  const isLoading = false

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      getCar({ carId: carID as string })
    }
  }, [isSuccess])

  const initialValues = useMemo(() => ({ ...objectKeysToLowerCase(carData) }), [carData])
  return (
    <>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
            <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
              {({ isActive, onConfirm, onCancel }) => (
                <ConfirmDialog open={!!isActive} onOk={onConfirm} handleClose={onCancel} notification={'Warning: Your changes haven’t been saved.'} />
              )}
            </ReactRouterPrompt>
            {/* <Header
              carData={carData}
              modelYear={carData?.ModelYear}
              make={carData?.Make}
              model={carData?.Model}
              vin={carData?.VIN}
              stockNumber={carData?.StockNumber}
              carIsSuccess={props?.carIsSuccess}
              getCar={props?.getCar}
              formChanged={formChanged}
              setFormChanged={setFormChanged}
              onDropdownFormChange={() => setFormChanged(true)}
            /> */}
            <Card>
              <Grid container spacing={2} p={3}>
                <Grid item xs={12}>
                  <FloorplanForm />
                </Grid>
                <Grid item xs={12}>
                  <BalanceForm />
                </Grid>
                <Grid item xs={12}>
                  <FeeForm />
                </Grid>
                <Grid item xs={12}>
                  <NoteForm />
                </Grid>
              </Grid>
            </Card>
            <Box position={'sticky'} sx={{ bottom: 20, right: 20 }} display='flex' justifyContent='flex-end' gap={2}>
              <Button variant='contained' color='error' onClick={() => navigate(appRoute.Inventory)}>
                Close
              </Button>
              <Button disabled={!formChanged} type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </Button>
            </Box>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </>
  )
}

export default withCars(InventoryEditFloorplan)
