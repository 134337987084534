import { convertDateToString } from '../../../../../utils/dateUtils'
import removeMask from '../../../../../utils/removeMask'

export const transformToFormValues = (): any => ({})

export const transformToRequestValues = (values?: any, dealID?: any, deficiency?: any): any => {
  let transformedValues: any = {
    isReversal: deficiency ? true : false,
    effectiveDate: convertDateToString(values?.EffectiveDate) || null,
    interestDue: '0.00', // LEAVE 0 FOR NOW
    lateChargeDue: '0.00', // LEAVE 0 FOR NOW
    interestRebate: '0.00', // LEAVE 0 FOR NOW
    creditCode: values?.CreditCode,
    specialComment: values?.SpecialComment,
    indicator: values?.Indicator,
  }

  // If ProcessType equals 1 (Repossession), add these values
  if (values?.ProcessType === 1) {
    transformedValues.repoAcv = removeMask(values?.RepoACV)
    transformedValues.repoStockNumber = values?.NewStock
    transformedValues.repoMileage = values?.CurrentMileage
    transformedValues.repoUnitBranchID = values?.GoingToBranch
  }

  return transformedValues
}
