import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useGetAccountingChartOfAccountCurrentMutation } from '../../../../../services'
import _ from 'lodash'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  accountingApData?: any
}
type TOptions = {
  key: number
  value: string
}

const FinanceForm = ({ accountingApData }: Props) => {
  const { values } = useFormState()
  const [accountingCurrent, { data: accountingCurrentData }] = useGetAccountingChartOfAccountCurrentMutation()

  const options: TOptions[] =
    accountingApData
      ?.filter((item: any) => item.CompanyType === 'Finance')
      .map((item: any) => ({
        key: item.ID,
        value: item.AccountNumberName,
      })) || []

  useEffect(() => {
    if (typeof values?.FinanceChartOfAccountAP === 'number') {
      accountingCurrent({
        uspChartOfAccountView: {
          currentID: values?.FinanceChartOfAccountAP,
          isAllowedSelectControlledAccount: true,
          isIncludeAccountsThatCannotBeSelected: false,
          isReturnAllCompanyTypes: false,
        },
      })
    }
  }, [values?.FinanceChartOfAccountAP])

  return (
    <>
      <CardHeader title='Finance Department' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={6}>
          <TextField name='FinanceAccountNumber' label='Account Number' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='FinanceChartOfAccountAP'
            label='Chart of Account - AP'
            options={options}
            initialValue={options?.length === 1 ? _.first(options)?.key : null}
            fullWidth
            customDropdownWidth={500}
          />
        </Grid>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='FinanceChartOfAccountBill'
            label='Chart of Account - Bill (default)'
            options={accountingCurrentData as any}
            optionKey='ChartOfAccountID'
            optionValue='AccountNumberName'
            fullWidth
            customDropdownWidth={500}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default FinanceForm
