import FinancingCard from '../../components/FinancingCard/FinancingCard'
import { Card, generateUtilityClasses, Grid } from '@mui/material'
import { styled } from '@mui/system'
import ItemizationCard from '../../components/ItemizationCard/ItemizationCard'
import TermsCard from '../../components/TermsCard/TermsCard'
import RecapCard from '../../components/RecapCard/RecapCard'
import { AccountsDealResult, UspGetDealProfitDataResult } from '../../../../../models'

const classes = generateUtilityClasses('Grid', ['root', 'formItem'])

interface Props {
  dealProfitData: UspGetDealProfitDataResult
  data: AccountsDealResult
  setErrorsInTabs: (value: any) => void
}

const TabStructure = ({ dealProfitData, data }: Props) => {
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid container item md={6} xs={12} direction={'column'}>
          <Grid item>
            <ItemizationCard itemizationData={data?.DealItemizationData} />
          </Grid>
          <Grid item>
            <FinancingCard salesDiscount={data?.SalesDiscountAmount} spreadAmount={data?.SpreadAmount} />
          </Grid>
        </Grid>
        <Grid container item md={6} xs={12} direction={'column'}>
          <Grid item>
            <TermsCard
              paySchedule={data?.PaymentSchedule}
              term={data?.OriginalTerm}
              payment={data?.PaymentAmount}
              finalPayment={data?.FinalPaymentAmount}
              interestRate={data?.InterestRate}
              APR={data?.APR}
            />
          </Grid>
          <Grid item>
            <RecapCard dealProfitData={dealProfitData} />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default TabStructure
