import React, { Dispatch, SetStateAction, useState } from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button, Popover } from '@mui/material'
import { ReactComponent as CircleIcon } from '../../../../assets/icons/circle.svg'
import { generateUtilityClasses } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { AddFormModal as AddNoteModal } from '../../InventoryEditNotes/components'
import { Form } from 'react-final-form'
import { AddFormModal as AddExpenseModal } from '../../InventoryEditExpenses/components'
import { AccountsDropdownResult, CarsDropdownResult, GetCarApiResponse } from '../../../../models'
import { TNoteRequest } from '../../InventoryEditNotes/InventoryEditNotes'
import { TExpenseRequest } from '../../InventoryEditExpenses/InventoryEditExpenses'
import AddDepositModal from '../AddDepositModal/AddDepositModal'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'mainDataWrap', 'secondaryData', 'iconSeparator'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    paddingRight: theme.spacing(2),
  },
  [`& .${classes.mainDataWrap}`]: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  [`& .${classes.secondaryData}`]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.iconSeparator}`]: {
    margin: theme.spacing(0, 1, 0, 1),
    fill: theme.palette.primary.main,
  },
}))

interface Props {
  modelYear?: number | null
  make?: string | null
  model?: string | null
  stockNumber?: string | null
  vin?: string | null
  getCarExpense?: (e: any) => void
  getCarNote?: (e: any) => void
  noteRequestData?: TNoteRequest
  expenseRequestData?: TExpenseRequest
  getAccountNotesHistory?: (e: any) => void
  getAccountDueAmount?: any
  getAccountsOverview?: any
  getCarPayment?: (e: any) => void
  disableFields?: boolean
  getCar: (e: any) => void
  carIsSuccess: boolean
  carData: GetCarApiResponse
  formChanged: boolean
  setFormChanged: Dispatch<SetStateAction<boolean>>
  onDropdownFormChange: () => void
}

export const Header = ({
  modelYear,
  make,
  model,
  stockNumber,
  vin,
  carData,
  getCarExpense,
  getCarNote,
  noteRequestData,
  expenseRequestData,
  setFormChanged,
  getCarPayment,
  disableFields,
  getCar,
  carIsSuccess,
  formChanged,
  onDropdownFormChange,
}: Props) => {
  const [anchorElAdd, setAnchorElAdd] = useState<HTMLButtonElement | null>(null)
  const [openNoteModal, setOpenNoteModal] = useState(false)
  const [openExpenseModal, setOpenExpenseModal] = useState(false)
  const [openDepositModal, setOpenDepositModal] = useState(false)

  const handleDepositModalOpen = () => setOpenDepositModal(true)
  const handleDepositModalClose = () => {
    setFormChanged && setFormChanged(false)
    setOpenDepositModal(false)
  }

  const handleNoteModalOpen = () => setOpenNoteModal(true)
  const handleNoteModalClose = () => {
    setFormChanged && setFormChanged(false)
    setOpenNoteModal(false)
  }

  const handleExpenseModalOpen = () => setOpenExpenseModal(true)
  const handleExpenseModalClose = () => {
    setFormChanged && setFormChanged(false)
    setOpenExpenseModal(false)
  }

  const openAdd = Boolean(anchorElAdd)
  const id = openAdd ? 'add-menu-popover' : undefined

  const handleOpenAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAdd(event.currentTarget)
  }

  const handleCloseAddMenu = () => {
    setAnchorElAdd(null)
  }

  const handleSubmit = () => {}

  return (
    <Form onSubmit={handleSubmit}>
      {() => (
        <StyledContainer className={classes.root}>
          <Grid container>
            <Grid className={classes.titleWrap}>
              <Grid>
                <Typography variant='h3' className={classes.mainDataWrap}>
                  {`${modelYear} ${make} ${model}`}
                </Typography>
              </Grid>
              <Grid container>
                <Grid item>
                  <Typography variant='h6' className={classes.secondaryData}>
                    {`Stock # ${stockNumber}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <CircleIcon className={classes.iconSeparator} />
                </Grid>
                <Grid item>
                  <Typography variant='h6'>{`VIN ${vin}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', flex: 1, margin: 0, padding: 0 }}>
              <Button
                aria-describedby={id}
                variant='contained'
                onClick={handleOpenAddMenu}
                endIcon={<KeyboardArrowDownIcon />}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', minWidth: '90px', margin: 0, padding: 0 }}
              >
                + Add
              </Button>
              <Popover
                id={id}
                open={openAdd}
                anchorEl={anchorElAdd}
                onClose={handleCloseAddMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Grid item style={{ display: 'flex', flexDirection: 'column', width: '150px' }}>
                  {!disableFields && (
                    <Button
                      style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                      onClick={() => {
                        handleExpenseModalOpen()
                        handleCloseAddMenu()
                      }}
                    >
                      Expense
                    </Button>
                  )}
                  <Button
                    style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                    onClick={() => {
                      handleNoteModalOpen()
                      handleCloseAddMenu()
                    }}
                  >
                    Note
                  </Button>
                  {carData?.IsAvailable && !carData?.IsDeleted && (
                    <Button
                      style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}
                      onClick={() => {
                        handleDepositModalOpen()
                        handleCloseAddMenu()
                      }}
                    >
                      Deposit
                    </Button>
                  )}
                  <Button style={{ justifyContent: 'flex-start', textTransform: 'capitalize', padding: '15px' }}>Document</Button>
                </Grid>
              </Popover>
            </Grid>
          </Grid>
          <AddDepositModal
            carIsSuccess={carIsSuccess as boolean}
            carData={carData}
            getCar={getCar as () => void}
            getCarPaymentTableData={getCarPayment as any}
            openModal={openDepositModal}
            handleClose={handleDepositModalClose}
            setFormChanged={setFormChanged}
          />
          <AddNoteModal
            noteRequestData={noteRequestData as TNoteRequest}
            getCarNote={getCarNote || (() => {})}
            open={openNoteModal}
            onClose={handleNoteModalClose}
            setFormChanged={setFormChanged}
            formChanged={formChanged as boolean}
          />
          <AddExpenseModal
            expenseRequestData={expenseRequestData as TExpenseRequest}
            getCarExpense={getCarExpense || (() => {})}
            carData={carData}
            open={openExpenseModal}
            onClose={handleExpenseModalClose}
            setFormChanged={setFormChanged}
            formChanged={formChanged as boolean}
            getCar={getCar as () => void}
            onDropdownFormChange={onDropdownFormChange as () => void}
          />
        </StyledContainer>
      )}
    </Form>
  )
}

export default Header
