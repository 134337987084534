import { createEntityAdapter, createSelector } from '@reduxjs/toolkit'
import { baseApi } from '../baseApi'
import { RootState } from '../../redux/store'

export type DropdownResponse = {
  ListName: string
  ReturnData: string
}

export type ParsedDropdownResponse = {
  ListName: string
  ReturnData: Record<any, string | any>[]
}

const dropdownsAdapter = createEntityAdapter<ParsedDropdownResponse>({
  selectId: (dropdown) => dropdown.ListName,
})

const initialState = dropdownsAdapter.getInitialState()

export const dropdownsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllDropdowns: build.query<any, void>({
      query: () => ({
        url: `/lists/all/`,
      }),
      transformResponse: (response: DropdownResponse[]) =>
        dropdownsAdapter.addMany(
          initialState,
          response.map((item) => ({
            ListName: item.ListName,
            ReturnData: JSON.parse(item.ReturnData),
          })),
        ),
    }),
  }),
})

export const selectDropdownsResult = dropdownsApi.endpoints.getAllDropdowns.select()

const selectDropdownData = createSelector(selectDropdownsResult, (dropdownsResult) => dropdownsResult.data)

export const { selectById: selectDropdownByName } = dropdownsAdapter.getSelectors((state: RootState) => selectDropdownData(state) ?? initialState)

export const selectDropdownByNameActiveOnly = createSelector(selectDropdownByName, (result) => {
  return result?.ReturnData?.filter((item) => item.IsActive)
})

export const selectDropdownByNameIsCollectorOnly = (collectorID?: number | null) =>
  createSelector(selectDropdownByName, (result) => {
    return result?.ReturnData.filter((item) => {
      return (item.IsCollector && item.IsActive) || item?.UserID === collectorID
    })
  })

export const selectDropdownByNameIsSalespersonOnly = (salesPersonID?: number | null) =>
  createSelector(selectDropdownByName, (result) => {
    return result?.ReturnData.filter((item) => {
      return (item.IsSalesperson && item.IsActive) || item?.UserID === salesPersonID
    })
  })

export const selectDropdownByNameIsManagerOnly = (managerID?: number | null) =>
  createSelector(selectDropdownByName, (result) => {
    return result?.ReturnData.filter((item) => {
      return (item.IsFiManager && item.IsActive) || item?.UserID === managerID
    })
  })
