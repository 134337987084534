import { styled } from '@mui/system'
import React, { useState } from 'react'
import EastIcon from '@mui/icons-material/East'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { HookedForm } from '../../../components/HookedForm'
import { HookedField } from '../../../components/HookedField'
import SearchIcon from '@mui/icons-material/Search'

function createData(name: string, company: string, number: string, email: string, country: string) {
  return { name, company, number, email, country }
}

const rows = [
  createData('Jane Cooper', 'Microsoft', '(225) 555-0118', 'jane@microsoft.com', 'United States'),
  createData('Jane Cooper', 'Microsoft', '(225) 555-0118', 'jane@microsoft.com', 'United States'),
  createData('Jane Cooper', 'Microsoft', '(225) 555-0118', 'jane@microsoft.com', 'United States'),
  createData('Jane Cooper', 'Microsoft', '(225) 555-0118', 'jane@microsoft.com', 'United States'),

  createData('Jane Cooper', 'Microsoft', '(225) 555-0118', 'jane@microsoft.com', 'United States'),
  createData('Jane Cooper', 'Microsoft', '(225) 555-0118', 'jane@microsoft.com', 'United States'),
  createData('Jane Cooper', 'Microsoft', '(225) 555-0118', 'jane@microsoft.com', 'United States'),
]

const StyledTop = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '24px',
})

const StyledTitle = styled('h2')({
  display: 'flex',
  alignItems: 'center',
  margin: 0,
  gap: '12px',
  fontSize: '28px',
})

const StyledFormWrap = styled('div')({
  borderRadius: '8px',
  padding: '40px',
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  backgroundColor: 'white',
  marginBottom: '24px',
})

type TFormFields = {
  name: string
  number: string
  email: string
}

const fieldLabels: TFormFields = {
  name: 'Business Name',
  number: 'Phone Number',
  email: 'Email Address',
}

const defaultV: TFormFields = {
  name: '',
  number: '',
  email: '',
}

export const ContactList = () => {
  const [radioChoice, setRadioChoice] = useState<string>('business')
  const handleSubmit = (values: TFormFields) => {}
  return (
    <>
      <StyledTop>
        <StyledTitle>
          Contacts <EastIcon /> Search
        </StyledTitle>
        <Box>
          <Button variant='contained' startIcon={<AddIcon />}>
            Add new
          </Button>
        </Box>
      </StyledTop>
      <StyledFormWrap>
        <FormControl sx={{ marginBottom: '24px' }}>
          <RadioGroup onChange={(e) => setRadioChoice(e.target.value as string)} row>
            <FormControlLabel
              value={'business'}
              checked={radioChoice === 'business'}
              control={<Radio color='primary' />}
              label={<Typography fontSize='14px'>Business</Typography>}
            />
            <FormControlLabel
              value='individual'
              checked={radioChoice === 'individual'}
              control={<Radio color='primary' />}
              label={<Typography fontSize='14px'>Individual</Typography>}
            />
          </RadioGroup>
        </FormControl>
        {radioChoice === 'business' ? (
          <HookedForm<TFormFields>
            style={{
              display: 'flex',
              gap: '16px',
            }}
            defaultValues={defaultV}
            onSubmit={handleSubmit}
          >
            <HookedField type='text' component={TextField} placeholder={fieldLabels.name} name='name' />
            <HookedField type='text' component={TextField} placeholder={fieldLabels.number} name='number' />
            <HookedField type='email' component={TextField} placeholder={fieldLabels.email} name='number' />

            <Button type='submit' sx={{ marginLeft: 'auto' }} variant='contained'>
              <SearchIcon /> Search
            </Button>
          </HookedForm>
        ) : (
          <HookedForm<TFormFields>
            style={{
              display: 'flex',
              gap: '16px',
              flexWrap: 'wrap',
            }}
            defaultValues={defaultV}
            onSubmit={handleSubmit}
          >
            <HookedField type='text' sx={{ minWidth: 250 }} component={TextField} placeholder={'First Name'} name='name' />
            <HookedField type='text' sx={{ minWidth: 250 }} component={TextField} placeholder={'Phone Number'} name='number' />{' '}
            <HookedField type='text' component={TextField} placeholder='Last 4 of SSN' name='ssn' sx={{ minWidth: 250, marginRight: '50px' }} />
            <HookedField type='text' sx={{ minWidth: 250 }} component={TextField} placeholder='Last Name' name='lastname' />
            <HookedField type='email' sx={{ minWidth: 250 }} component={TextField} placeholder='Email Address' name='email' />
            <HookedField sx={{ minWidth: 250 }} type='date' component={TextField} placeholder='Date of Birth' name='birthdate' />
            <Button type='submit' sx={{ marginLeft: 'auto' }} variant='contained'>
              <SearchIcon /> Search
            </Button>
          </HookedForm>
        )}
      </StyledFormWrap>
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead sx={{ background: '#FAFBFC', color: '#525B67' }}>
              <TableRow>
                <TableCell>Customer Name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Country</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell sx={{ color: '#2642FF' }} component='th' scope='row'>
                    {row.name}
                  </TableCell>
                  <TableCell>{row.company}</TableCell>
                  <TableCell>{row.number}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>
                    <Button
                      variant='text'
                      sx={{
                        color: '#13C982',
                        display: 'flex',
                        alignItems: 'center',
                        fontWeight: 500,
                        background: 'none',
                        gap: '10px',
                      }}
                    >
                      Select <EastIcon fill='#13C982' />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  )
}
