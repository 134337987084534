import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Header from '../components/Header/Header'
import { useSearchVendorsMutation } from '../../../services'
import { UspConfigurationVendorTypeViewResult } from '../../../models'
import VendorsTable from './components/VendorsTable'
import AddEditVendorTypes from './components/AddEditVendorTypes'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const SettingsVendors = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchVendors, { data, isLoading }] = useSearchVendorsMutation()
  const [vendorsData, setVendorsData] = useState<UspConfigurationVendorTypeViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchVendors({
      uspConfigurationVendorTypeView: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setVendorsData(data)
    }
  }, [data])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Vendor Types'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button onClick={() => handleOpenModal()} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Header submit={submit} handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <VendorsTable
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                      handleOpenModal={handleOpenModal}
                      loading={isLoading}
                      data={vendorsData}
                      setData={setVendorsData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditVendorTypes
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspConfigurationVendorTypeViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}
export default SettingsVendors
