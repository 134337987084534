import React from 'react'
import { styled } from '@mui/system'
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import RadioField from '../../../../../ui/RadioField'
import { getContactSuffixes } from '../../../../../redux/slices/userDropdownSlice'
import { userLabels } from '../../../../../validation/userValidation'
import { Field, useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  selectedPendingRecord: any
}

export const PayeeCard = ({ selectedPendingRecord }: Props) => {
  const { values } = useFormState()
  const contactSuffixes = useSelector(getContactSuffixes)

  const accountOptions: any[] = [
    { key: 'individual', value: 'Individual' },
    { key: 'business', value: 'Business' },
  ]

  let disableFields = false
  if (selectedPendingRecord !== null && selectedPendingRecord !== undefined) {
    disableFields = Object.keys(selectedPendingRecord)?.length === 0 ? false : selectedPendingRecord?.ContactID !== null
  }

  return (
    <Grid container>
      <Grid container alignItems={'center'} justifyContent={'space-between'}>
        <Grid item>
          <Typography variant='h6'>Payee</Typography>
        </Grid>
        <Grid item>
          {/* <RadioField
            disabled={disableFields}
            name={'ApplicantAccountType'}
            label={'accountType'}
            defaultValue={'business'}
            options={accountOptions}
            optionValue='value'
            optionKey='key'
          /> */}
          <Field name='ApplicantAccountType'>
            {(props) => (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      name={props.input.name}
                      checked={props.input.value === 'business'}
                      onChange={(e) => {
                        props.input.onChange(e.target.checked ? 'business' : 'individual')
                      }}
                    />
                  }
                  label='Business'
                />
              </div>
            )}
          </Field>
        </Grid>
      </Grid>
      {values?.ApplicantAccountType === 'individual' ? (
        <Grid container spacing={2} py={1}>
          <Grid item xs={12}>
            <TextField disabled={disableFields} name='ApplicantFirstName' label={userLabels.firstName} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextField disabled={disableFields} name='ApplicantMiddleName' label='Middle Name' fullWidth />
          </Grid>
          <Grid item xs={10}>
            <TextField disabled={disableFields} name='ApplicantLastName' label={userLabels.lastName} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <DropdownFieldSearch
              name='ApplicantSuffix'
              label='Suffix'
              options={contactSuffixes}
              optionKey='Suffix'
              optionValue='Suffix'
              optionNone
              disabled={disableFields}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField disabled={disableFields} name='ApplicantNickName' label={userLabels.nickName} fullWidth />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} py={1}>
          <Grid item xs={12}>
            <TextField disabled={disableFields} name='ApplicantBusinessName' label={userLabels.businessName} fullWidth />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default PayeeCard
