import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  selectDropdownByNameActiveOnly,
  selectDropdownByNameIsManagerOnly,
  selectDropdownByNameIsSalespersonOnly,
  useAccountsSettingsEditMutation,
  useGetDealersListQuery,
  useGetListDealsCollectorQuery,
} from '../../../../../services'
import { Form } from 'react-final-form'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Button, Dialog, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { transformToFormValues, transformToRequestValues } from './transform'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { UspGetDataCreditApp, UspGetDealersListResult, UspGetListLenderResult } from '../../../../../models'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'

const classesBox = generateUtilityClasses('Container', ['root', 'title', 'row', 'btnWrap', 'icon'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '287px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.title}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.row}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.btnWrap}`]: {
    justifyContent: 'space-between',
  },
  [`& .${classesBox.icon}`]: {
    margin: theme.spacing(3, 0),
  },
}))

interface Props {
  handleClose: any
  lenderID: number
  dealerID: number
  collectorID: number
  salesPersonID: number
  managerID?: number | null
  getAccountsOverview: any
}

const SettingsCardEdit = ({ handleClose, lenderID, dealerID, collectorID, salesPersonID, managerID, getAccountsOverview }: Props) => {
  const { dealID } = useParams()
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})
  const [editAccountSettings, { isSuccess }] = useAccountsSettingsEditMutation()
  const { data: dealersListData } = useGetDealersListQuery({})
  const { data: collectorData } = useGetListDealsCollectorQuery({})
  const manager = useSelector((state) => selectDropdownByNameIsManagerOnly(managerID)(state, 'UserAll'))
  const salesPerson = useSelector((state) => selectDropdownByNameIsSalespersonOnly(salesPersonID)(state, 'UserAll'))

  const lender = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Lender'))

  const handleSubmit = (values?: any) => {
    if (dealID) {
      editAccountSettings({
        dealId: dealID as string,
        uspAccountSettingEdit: transformToRequestValues(values),
      })
    }
  }

  useEffect(() => {
    if (lenderID || dealerID || collectorID || salesPersonID || managerID) {
      console.log(transformToFormValues(lenderID, dealerID, collectorID, salesPersonID, managerID))

      setInitialValues(transformToFormValues(lenderID, dealerID, collectorID, salesPersonID, managerID))
    }
  }, [lenderID, dealerID, collectorID, salesPersonID, managerID])

  useEffect(() => {
    if (isSuccess) {
      getAccountsOverview({ dealId: dealID as string })
      dispatch(enqueueNotification({ message: 'Success.' }))
      handleClose()
    }
  }, [isSuccess])
  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit }: any) => (
        <StyledBox onSubmit={handleSubmit} className={classesBox.root}>
          <Typography variant='h3' className={classesBox.title}>
            Settings
          </Typography>

          {lender && (
            <Grid className={classesBox.row}>
              <DropdownFieldSearch name='SettingsLender' label='Lender' options={lender} optionKey='ID' optionValue='LenderCode' />
            </Grid>
          )}
          <Grid className={classesBox.row}>
            <DropdownFieldSearch
              name='SettingsDealer'
              label='Dealer'
              options={dealersListData as UspGetDealersListResult[]}
              optionKey='ID'
              optionValue='DealerCode'
            />
          </Grid>
          <Grid className={classesBox.row}>
            <DropdownFieldSearch
              name='SettingsCollector'
              label='Collector'
              options={collectorData as UspGetListLenderResult[]}
              optionKey='UserID'
              optionValue='UserCode'
            />
          </Grid>
          {salesPerson && (
            <Grid className={classesBox.row}>
              <DropdownFieldSearch name='SettingsSalesperson' label='Salesperson' options={salesPerson} optionKey='UserID' optionValue='UserCode' />
            </Grid>
          )}
          {manager && (
            <Grid className={classesBox.row}>
              <DropdownFieldSearch name='SettingsFIManager' label='F&I Manager' options={manager} optionKey='UserID' optionValue='UserCode' />
            </Grid>
          )}

          <Grid className={classesBox.btnWrap} container>
            <Button variant='contained' color='error' onClick={handleClose}>
              Cancel
            </Button>
            <Button variant='contained' type='submit'>
              Save
            </Button>
          </Grid>
        </StyledBox>
      )}
    </Form>
  )
}

export default SettingsCardEdit
