import React, { useEffect, useMemo, useState } from 'react'
import Header from '../Header/Header'
import { withAccounts } from '../../../hoc/withAccounts'
import { AccountsOverviewResult, UspDealFlagViewResult } from '../../../models'
import { Box, Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import ApplicationForm from './components/ApplicationForm/ApplicationForm'
import { Form } from 'react-final-form'
import ReceivedForm from './components/ReceivedForm/ReceivedForm'
import SentForm from './components/SentForm/SentForm'
import CommentsForm from './components/CommentsForm/CommentsForm'
import Button from '@mui/material/Button'
import { appRoute } from '../../../constants'
import { useNavigate, useParams } from 'react-router-dom'
import { transformToFormValues, transformToRequestValues } from './transform'
import { useAddDealsFlagMutation, useEditAccountTitleMutation, useLazyGetAccountTitleQuery, useWorklistNextAccountMutation } from '../../../services'
import { withPermissionsDropdown } from '../../../hoc'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { CustomCircular } from '../../../ui'
import { getWorklistAccountID, getWorklistID } from '../../../redux/slices/collectionsSlice'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { getUserAccessRights } from '../../../redux/slices/authSlice'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'fieldsWrap', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledGrid = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 3, 3, 3),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  accountsData: AccountsOverviewResult
  setDirty: any
}

const AccountsTitle = ({ accountsData, setDirty }: Props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { dealID } = useParams()
  const [formChanged, setFormChanged] = useState(false)
  const worklistAccountID = useSelector(getWorklistAccountID)
  const worklistID = useSelector(getWorklistID)
  const [initialValues, setInitialValues] = useState({})
  const [getAccountTitle, { data, isLoading: getAccountsTitleIsLoading }] = useLazyGetAccountTitleQuery()
  const [editAccountTitle, { isLoading: editAccountTitleIsLoading, isSuccess: editAccountTitleIsSuccess }] = useEditAccountTitleMutation()
  const [nextAccount, { data: nextAccountData, isSuccess: nextAccountIsSuccess }] = useWorklistNextAccountMutation()
  const [isAddEditFlagModalOpen, setIsAddEditFlagModalOpen] = useState(false)
  const [addAccountFlags, { isSuccess: addAccountFlagIsSuccess, isLoading: addAccountFlagsIsLoading }] = useAddDealsFlagMutation()
  const [selectedFlag, setSelectedFlag] = useState<UspDealFlagViewResult | undefined>()

  const userAccess = useSelector(getUserAccessRights)
  const disableEditIcons = userAccess?.CanEditAccount
  const disableFields = !userAccess?.CanEditAccountTracking

  useEffect(() => {
    getAccountTitle({ dealId: dealID as string })
  }, [])

  useEffect(() => {
    setInitialValues(transformToFormValues(data))
  }, [data])

  const handleSubmit = (values?: any) => {
    if (dealID) {
      editAccountTitle({ dealId: dealID as string, editTitle: transformToRequestValues(values as any) })
    }
  }

  const handleNextAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: true,
        },
      })
    }
  }

  const handlePreviousAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: false,
        },
      })
    }
  }

  useEffect(() => {
    if (editAccountTitleIsSuccess) {
      setFormChanged(false)
      dispatch(enqueueNotification({ message: 'Success.' }))
      navigate(appRoute?.AccountsTitle.replace(':dealID', dealID as string))
      getAccountTitle({ dealId: dealID as string })
    }
  }, [editAccountTitleIsSuccess])

  useEffect(() => {
    if (addAccountFlagIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setFormChanged(false)
      setIsAddEditFlagModalOpen(false)
    }
  }, [addAccountFlagIsSuccess])

  const isLoading = useMemo(() => getAccountsTitleIsLoading || editAccountTitleIsLoading, [getAccountsTitleIsLoading, editAccountTitleIsLoading])

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={10} spacing={2}>
          <Form onSubmit={handleSubmit} initialValues={initialValues}>
            {({ handleSubmit, dirty, valid }: any) => {
              if (setDirty) setDirty(dirty)

              return (
                <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
                  <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
                    {({ isActive, onConfirm, onCancel }) => (
                      <ConfirmDialog
                        open={!!isActive}
                        onOk={onConfirm}
                        handleClose={onCancel}
                        notification={'Warning: Your changes haven’t been saved.'}
                      />
                    )}
                  </ReactRouterPrompt>
                  <Card sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Grid item xs={12}>
                      <CardHeader title='Application' />
                      <ApplicationForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Grid>
                    <Grid item xs={12}>
                      <CardHeader title='Received' />
                      <ReceivedForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Grid>
                    <Grid item xs={12}>
                      <CardHeader title='Sent' />

                      <SentForm disableFields={disableFields} onDropdownFormChange={() => setFormChanged(true)} />
                    </Grid>
                    <Grid item xs={12}>
                      <CommentsForm disableFields={disableFields} />
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        position: 'sticky',
                        bottom: 16,
                        right: 20,
                        p: 3,
                        width: 'fit-content',
                        alignSelf: 'flex-end',
                      }}
                    >
                      <Button disabled={!(valid && dirty)} type='submit' className={classes.saveBtn} variant='contained'>
                        Save
                      </Button>
                    </Box>
                  </Card>
                </form>
              )
            }}
          </Form>
          <CustomCircular open={isLoading} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default withPermissionsDropdown(withAccounts(AccountsTitle))
