import { Box, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { CustomCircular } from '../../../../../../../ui'
import { SearchFormsApiResponse, UspFormPackDetailViewResult, UspFormPackViewResult } from '../../../../../../../models'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import TextField from '../../../../../../../ui/TextField'
import {
  useAddFormPackDetailMutation,
  useEditFormPackDetailMutation,
  useLazyGetFormPackDetailQuery,
  useSearchFormsMutation,
} from '../../../../../../../services'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspFormPackDetailViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
  formPackDetail: UspFormPackViewResult
}

const AddEditFormPackModal = ({ open, onClose, rowData, type, submit, formPackDetail }: Props) => {
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})
  const [addFormPack, { isLoading: isLoadingFormPack, isSuccess: isSuccessFormPack }] = useAddFormPackDetailMutation()
  const [editFormPack, { isLoading: isLoadingEditFormPack, isSuccess: isSuccessEditFormPack }] = useEditFormPackDetailMutation()
  const [getFormPackData, { isLoading: isLoadingGetFormPack, isSuccess: isSuccessGetFormPack, data }] = useLazyGetFormPackDetailQuery()
  const [searchForms, { data: searchFormsData, isLoading: searchFormsIsLoading }] = useSearchFormsMutation()

  useEffect(() => {
    if (open) {
      searchForms({
        state: formPackDetail?.State as string,
        searchString: '',
        dataSource: 'Deal',
        isIncludeInactive: false,
      })
    }
    if (open && rowData && type === 'edit') {
      getFormPackData({
        formPackId: rowData?.FormPackDetailID?.toString() as string,
      })
    }
  }, [open, type, rowData])

  const handleSubmit = (values: any) => {
    const formPackId = formPackDetail?.FormPackID

    if (type === 'edit') {
      editFormPack({
        formPackId: rowData?.FormPackDetailID?.toString() as string,
        uspFormPackDetailAddEdit: {
          formPackID: formPackId,
          formID: values.FormName,
          quantity: values.PrintQuantity,
          isHasTrade: values.DealHasTrade,
          isHasCoBuyer: values.DealHasCoBuyer,
        },
      })
    } else {
      addFormPack({
        uspFormPackDetailAddEdit: {
          formPackID: formPackId,
          formID: values.FormName,
          quantity: values.PrintQuantity,
          isHasTrade: values.DealHasTrade,
          isHasCoBuyer: values.DealHasCoBuyer,
        },
      })
    }
  }

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        PrintQuantity: '1',
        DealHasTrade: false,
        DealHasCoBuyer: false,
      })
    }
    if (isSuccessGetFormPack && type === 'edit') {
      setInitialValues({
        FormName: data?.FormID,
        PrintQuantity: data?.Quantity,
        DealHasTrade: data?.IsHasTrade,
        DealHasCoBuyer: data?.IsHasCoBuyer,
      })
    }
  }, [isSuccessGetFormPack, data, type])

  useEffect(() => {
    if (isSuccessFormPack || isSuccessEditFormPack) {
      dispatch(enqueueNotification({ message: 'Success' }))
      onClose()
      submit()
    }
  }, [isSuccessFormPack, isSuccessEditFormPack])

  const isLoading = useMemo(
    () => isLoadingFormPack || isLoadingEditFormPack || isLoadingGetFormPack || searchFormsIsLoading,
    [isLoadingFormPack, isLoadingEditFormPack, isLoadingGetFormPack, searchFormsIsLoading],
  )

  return (
    <Modal open={open} onClose={onClose}>
      <StyledBox className={classes.root}>
        <Grid>
          <Typography variant='h3'>Form Pack Detail</Typography>
        </Grid>
        <Form onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit }: any) => (
            <form onSubmit={handleSubmit}>
              <Grid className={classes.row}>
                <DropdownFieldSearch
                  disabled={type === 'edit' ? true : false}
                  name='FormName'
                  label='Form Name'
                  options={searchFormsData as SearchFormsApiResponse}
                  optionKey='ID'
                  optionValue='Name'
                />
              </Grid>

              <Grid className={classes.row}>
                <TextField name='PrintQuantity' label='Print Quantity' fullWidth />
              </Grid>

              <Grid className={classes.row}>
                <DropdownFieldSearch
                  name='DealHasTrade'
                  label='Only Print If Deal Has Trade?'
                  options={[
                    { key: true, values: 'Yes' },
                    { key: false, values: 'No' },
                  ]}
                  optionKey='key'
                  optionValue='values'
                />
              </Grid>

              <Grid className={classes.row}>
                <DropdownFieldSearch
                  name='DealHasCoBuyer'
                  label='Only Print If Deal Has Co-Buyer?'
                  options={[
                    { key: true, values: 'Yes' },
                    { key: false, values: 'No' },
                  ]}
                  optionKey='key'
                  optionValue='values'
                />
              </Grid>
              <Grid className={classes.buttonWrap} container>
                <Grid item>
                  <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
        <CustomCircular open={isLoading} />
      </StyledBox>
    </Modal>
  )
}

export default AddEditFormPackModal
