import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Container, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'searchWrap'])

const StyledFormWrap = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1, 1, 2, 1),
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },
}))

interface Props {
  submit: (...args: any) => any
  unpaidVendorsData: any
  stage: string
  setSelectedVendor: Dispatch<SetStateAction<any>>
}
const BillPaySearchBlock = ({ submit, unpaidVendorsData, stage, setSelectedVendor }: Props) => {
  const { values } = useFormState()

  useEffect(() => {
    if (values?.vendor) {
      setSelectedVendor(values?.vendor)
      submit(values)
    }
  }, [values?.vendor])

  return stage !== 'bill-pay-summary' ? (
    <Grid container p={3}>
      <Grid item xs={4}>
        <form>
          <DropdownFieldSearch
            customDropdownWidth={250}
            onChange={(i) => setSelectedVendor(i)}
            name='vendor'
            label='Vendor'
            fullWidth
            options={unpaidVendorsData}
            optionKey='VendorID'
            optionValue='Name'
          />
        </form>
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

export default BillPaySearchBlock
