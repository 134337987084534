import React, { useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TableSortLabel,
  Box,
  Tooltip,
  ListItemText,
  MenuItem,
  Paper,
  TablePagination,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspDealSearchResult } from '../../../../../models'
import EditIcon from '@mui/icons-material/Edit'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { useDeleteDealMutation } from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-final-form'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { visuallyHidden } from '@mui/utils'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import { usePopover } from '../../../../../components/custom-popover'
import CustomPopover from '../../../../../components/custom-popover/custom-popover'
import Iconify from '../../../../../components/iconify'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'

interface Props {
  searchDealsData?: UspDealSearchResult[]
  loading: boolean
}

export const DealsTableBlock = ({ searchDealsData, loading }: Props) => {
  const initialOrder = (storageGet('deals_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('deals_order_by') || 'StockNumber') as keyof UspDealSearchResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspDealSearchResult>(initialOrderBy)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ dealId: string } | undefined>(undefined)
  const [selectedItem, setSelectedItem] = React.useState<any>(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { submit } = useForm()
  const userAccess = useSelector(getUserAccessRights)
  const popover = usePopover()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [deleteDeal, { isSuccess: deleteDealIsSuccess }] = useDeleteDealMutation()

  const handleEdit = (selected: any) => {
    if (selected) {
      if (selected.IsLease) {
        navigate(appRoute.DealsAddEditLease.replace(':dealID', String(selected.DealID)))
      } else navigate(appRoute.DealsAddEdit.replace(':dealID', String(selected.DealID)))
    }
  }

  const handleOpenTask = (id: number | string) => {
    if (id) {
      navigate(appRoute.DealsTask.replace(':dealID', String(id)))
    }
  }

  const handleDelete = (): void => {
    if (deleteConfirmDialog?.dealId) {
      deleteDeal(deleteConfirmDialog)
    }
    setDeleteConfirmDialog(undefined)
    popover.onClose()
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspDealSearchResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('deals_order', isAsc ? 'desc' : 'asc')
    storageSet('deals_order_by', property)
  }

  const createSortHandler = (property: keyof UspDealSearchResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  useEffect(() => {
    if (deleteDealIsSuccess) {
      submit()
      dispatch(enqueueNotification({ message: 'Deal removed' }))
    }
  }, [deleteDealIsSuccess])

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginatedData = searchDealsData

  return (
    <>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  sx={{ display: 'flex', flexWrap: 'nowrap', width: 80 }}
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Buyer' ? order : false}>
                <TableSortLabel active={orderBy === 'Buyer'} direction={orderBy === 'Buyer' ? order : 'asc'} onClick={createSortHandler('Buyer')}>
                  Buyer
                  {orderBy === 'Buyer' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Vehicle' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Vehicle'}
                  direction={orderBy === 'Vehicle' ? order : 'asc'}
                  onClick={createSortHandler('Vehicle')}
                >
                  Description
                  {orderBy === 'Vehicle' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'VIN' ? order : false}>
                <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                  VIN
                  {orderBy === 'VIN' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'DealType' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DealType'}
                  direction={orderBy === 'DealType' ? order : 'asc'}
                  onClick={createSortHandler('DealType')}
                >
                  Deal Type
                  {orderBy === 'DealType' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'DealStatus' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DealStatus'}
                  direction={orderBy === 'DealStatus' ? order : 'asc'}
                  onClick={createSortHandler('DealStatus')}
                >
                  Status
                  {orderBy === 'DealStatus' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'DealDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DealDate'}
                  direction={orderBy === 'DealDate' ? order : 'asc'}
                  onClick={createSortHandler('DealDate')}
                >
                  Date
                  {orderBy === 'DealDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell align={'right'}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow key={item.DealID} sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }} hover>
                  <TableCell onClick={() => handleEdit(item)}>
                    {item.StockNumber || '-'}
                    {item.StockNumber && (
                      <IconButton
                        color='primary'
                        sx={{ margin: 0, padding: '0 0 0 10px' }}
                        onClick={(event) => {
                          event?.stopPropagation() // This stops the event from bubbling up
                          window.open(
                            appRoute.InventoryEdit.replace(':carID', item?.CarID?.toString() as string),
                            '_blank', // <- This is what makes it open in a new window.
                          )
                        }}
                      >
                        <OpenInNewIcon sx={{ width: '15px', height: '15px' }} />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell onClick={() => handleEdit(item)}>
                    <ListItemText
                      primary={item?.Buyer}
                      secondary={item?.PhoneNumber}
                      primaryTypographyProps={{ typography: 'body2' }}
                      secondaryTypographyProps={{
                        component: 'span',
                        color: 'text.disabled',
                      }}
                    />
                  </TableCell>
                  <TableCell onClick={() => handleEdit(item)}>{item?.Vehicle}</TableCell>
                  <TableCell onClick={() => handleEdit(item)}>{item?.VIN}</TableCell>
                  <TableCell onClick={() => handleEdit(item)}>{item?.DealType}</TableCell>
                  <TableCell onClick={() => handleEdit(item)}>{item?.DealStatus}</TableCell>
                  <TableCell onClick={() => handleEdit(item)}>{convertDateToDisplay(item?.DealDate)}</TableCell>
                  <TableCell onClick={() => handleEdit(item)}>{item?.BranchCode}</TableCell>
                  <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
                    <IconButton
                      color={popover.open ? 'inherit' : 'default'}
                      onClick={(e) => {
                        setSelectedItem(item)
                        popover.onOpen(e)
                      }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {searchDealsData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Paper>
          <TablePagination
            component='div'
            count={searchDealsData?.length as number}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Deal will be removed'}
      />

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140, p: 0 }}>
        <MenuItem onClick={() => handleEdit(selectedItem)}>
          <Iconify icon='solar:pen-bold' />
          Edit
        </MenuItem>
        <MenuItem onClick={() => handleOpenTask(selectedItem.DealID)} sx={{ color: 'success.main' }}>
          <Iconify icon='material-symbols:task' />
          Deal Task
        </MenuItem>
        {userAccess?.CanDeleteDeal && (
          <MenuItem
            onClick={() => {
              setDeleteConfirmDialog({ dealId: selectedItem.DealID?.toString() as string })
            }}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        )}
      </CustomPopover>
    </>
  )
}
