import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { CheckboxField } from '../../../../../../ui'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},
}))

interface Props {}

export const InsightsForm = ({}: Props) => {
  const { values } = useFormState()
  return (
    <StyledGrid className={classes.root}>
      <Grid container>
        <Typography variant='h3'>Insights</Typography>
      </Grid>
      <Grid>
        <CheckboxField name='AccessInsights' label='Access Insights' />
      </Grid>
      {values.AccessInsights && (
        <>
          <Grid>
            <CheckboxField name='InventoryInsights' label='Inventory Insights' />
          </Grid>
          <Grid>
            <CheckboxField name='SalesInsights' label='Sales Insights' />
          </Grid>
          <Grid>
            <CheckboxField name='CollectionsInsights' label='Collections Insights' />
          </Grid>
          <Grid>
            <CheckboxField name='AccountInsights' label='Account Insights' />
          </Grid>
          <Grid>
            <CheckboxField name='PaymentInsights' label='Payment Insights' />
          </Grid>
          <Grid>
            <CheckboxField name='ManagementInsights' label='Management Insights' />
          </Grid>
          <Grid>
            <CheckboxField name='AccountingInsights' label='Accounting Insights' />
          </Grid>
        </>
      )}
    </StyledGrid>
  )
}

export default InsightsForm
