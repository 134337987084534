import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import { AmountField } from '../../../../../../../ui'
import { useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  paymentType: any
  accountDueAmountData: any
}
const DistributionCard = ({ paymentType, accountDueAmountData }: Props) => {
  const form = useForm()
  const { values } = useFormState()

  useEffect(() => {
    if (values?.TypeSetup?.toLowerCase() === 'downpayment') {
      form.change('TypeDistribution', values?.TypeSetup)
    }
  }, [])
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DropdownFieldSearch
          disabled={true}
          name='TypeDistribution'
          label='Type'
          options={paymentType}
          // getOptionLabel={(option: any) => {
          //   ;`${option['PaymentType']} `
          // }}
          optionKey='PaymentType'
          optionValue='PaymentType'
          autoComplete='off'
          sx={{ '& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input': { minWidth: '30px' } }}
        />
      </Grid>
      <Grid item xs={12}>
        <AmountField variant='standard' defaultValue={accountDueAmountData?.DownPaymentBalance} name='Amount' label='Amount' fullWidth />
      </Grid>
    </Grid>
  )
}

export default DistributionCard
