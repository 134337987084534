import { Box, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useState } from 'react'
import Header from './components/Header/Header'
import { useLazyReportsFavoriteQuery, useReportsFavoriteUpdateMutation } from '../../services'
import InsightsCard from './components/InsightsCard/InsightsCard'
import { insightsCardData } from './data'
import { useSelector } from 'react-redux'
import { getUserAccessRights } from '../../redux/slices/authSlice'

const classes = generateUtilityClasses('Box', ['root', 'firstRow'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(0,2,2,2),
  },
  [`& .${classes.firstRow}`]: {
    padding: theme.spacing(0, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
}))

interface Report {
  id: number
  category: string
  title: string
  description: string
  link: null | string
  isFavorite: boolean
}

interface OrganizedReports {
  [category: string]: Report[]
}

function Insights() {
  const [organizedReportsState, setOrganizedReportsState] = useState<OrganizedReports>({})
  const [getReports, { data, isSuccess }] = useLazyReportsFavoriteQuery()
  const [updateReport, { isSuccess: isSuccessUpdateReport }] = useReportsFavoriteUpdateMutation()
  const userAccess = useSelector(getUserAccessRights)

  useEffect(() => {
    getReports({})
  }, [])

  useEffect(() => {
    if (isSuccessUpdateReport) {
      getReports({})
    }
  }, [isSuccessUpdateReport])

  const organizedByCategory = insightsCardData?.reduce<OrganizedReports>((acc, report) => {
    if (!acc[report?.category]) {
      acc[report?.category] = []
    }
    acc[report?.category].push(report)
    return acc
  }, {})

  const allReports = Object.values(organizedByCategory)?.flat()

  const favoriteReports = data
    ? allReports?.filter((report) => data?.some((d: any) => d?.ReportName === report?.title))?.map((report) => ({ ...report, isFavorite: true }))
    : []

  // Function to filter reports based on user access
  const filterReportsBasedOnAccess = (report: Report) => {
    switch (report.category) {
      case 'Inventory':
        return userAccess.CanAccessInventoryInsights
      case 'Sales':
        return userAccess.CanAccessSalesInsights
      case 'Collections':
        return userAccess.CanAccessCollectionsInsights
      case 'Accounts':
        return userAccess.CanAccessAccountInsights
      case 'Payment':
        return userAccess.CanAccessPaymentInsights
      case 'Management':
        return userAccess.CanAccessManagementInsights
      case 'Accounting':
        return userAccess.CanAccessAccountingInsights
      default:
        return true
    }
  }

  useEffect(() => {
    if (isSuccess && data) {
      const favoriteReportTitles = data?.map((d: any) => d?.ReportName)

      // Filter and mark favorite reports
      const updatedReports = insightsCardData?.filter(filterReportsBasedOnAccess)?.map((report) => {
        if (favoriteReportTitles.includes(report.title)) {
          return { ...report, isFavorite: true }
        }
        return report
      })

      const organized = updatedReports?.reduce<OrganizedReports>((acc, report) => {
        if (!acc[report.category]) {
          acc[report?.category] = []
        }
        acc[report?.category]?.push(report)
        return acc
      }, {})

      setOrganizedReportsState(organized)
    }
  }, [data, isSuccess])

  return (
    <>
      <form>
        <StyledBox className={classes.root}>
        <Header />
          <Grid container>
            {isSuccess && favoriteReports?.length > 0 && (
              <Grid container   direction="column"> 
                <Typography variant='h4' sx={{ marginBottom: '20px' }}>
                  Favorites
                </Typography>
                <Grid container gap={3}>
                  {favoriteReports?.map((report, index) => (
                    <Grid key={index} item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <InsightsCard report={report} updateReport={updateReport} setOrganizedReportsState={setOrganizedReportsState} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}

            {Object.entries(organizedReportsState)?.map(([categoryName, reports]) => (
              <Grid container key={categoryName}>
                <Typography variant='h4' sx={{ margin: '20px 0' }}>
                  {categoryName}
                </Typography>
                <Grid container gap={3}>
                  {reports?.map((report: any, index: any) => (
                    <Grid key={index} item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <InsightsCard report={report} updateReport={updateReport} setOrganizedReportsState={setOrganizedReportsState} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </StyledBox>
      </form>
    </>
  )
}

export default Insights
