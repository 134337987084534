import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import DateField from '../../../../../ui/DateField'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

const RedemptionForm = ({ onDropdownFormChange, disableFields }: Props) => {
  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='IsRepoRedeemed'
          label='Is Repo Redeemed?'
          fullWidth
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField disabled={disableFields} name='RedemptionDate' label='Redemption Date' fullWidth onDropdownFormChange={onDropdownFormChange} />
      </Grid>
    </Grid>
  )
}

export default RedemptionForm
