import React from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid } from '@mui/material'
import { ReactComponent as CircleIcon } from '../../../../../assets/icons/circle.svg'
import { generateUtilityClasses } from '@mui/material'
import { applyPhoneMask } from '../../../../../utils/general'
import { UspGetDataDealTaskResult } from '../../../../../models'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'mainDataWrap', 'secondaryData', 'iconSeparator', 'iconBtn'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    // paddingRight: theme.spacing(2),
  },
  [`& .${classes.mainDataWrap}`]: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  [`& .${classes.secondaryData}`]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.iconSeparator}`]: {
    margin: theme.spacing(0, 1, 0, 1),
    fill: theme.palette.primary.main,
  },
  [`& .${classes.iconBtn}`]: {
    padding: theme.spacing(0.5),
  },
}))

interface Props {
  taskDeal?: UspGetDataDealTaskResult
}

export const Header = ({ taskDeal }: Props) => {
  return (
    <StyledContainer className={classes.root}>
      <Grid container>
        <Grid className={classes.titleWrap}>
          <Grid container>
            <Grid item>
              <Typography variant='h3' className={classes.mainDataWrap}>
                {taskDeal?.CarDescription}
              </Typography>
            </Grid>
            <Grid container>
              <Grid item>
                <Typography variant='h6' className={classes.secondaryData}>
                  {`Stock # ${taskDeal?.StockNumber}`}
                </Typography>
              </Grid>
              <Grid item>
                <CircleIcon className={classes.iconSeparator} />
              </Grid>
              <Grid item>
                <Typography variant='h6'>{`VIN ${taskDeal?.VIN}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={classes.titleWrap}>
          <Grid container>
            <Grid item>
              <Typography variant='h3' className={classes.mainDataWrap}>
                {taskDeal?.Buyer}
              </Typography>
              <Typography variant='h6' className={classes.secondaryData}>
                {taskDeal?.PhoneNumber && applyPhoneMask(taskDeal?.PhoneNumber)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledContainer>
  )
}

export default Header
