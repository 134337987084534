import { useCallback } from 'react'

import Stack from '@mui/material/Stack'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import IconButton from '@mui/material/IconButton'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputAdornment from '@mui/material/InputAdornment'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Iconify from '../../../../components/iconify'
import CustomPopover from '../../../../components/custom-popover/custom-popover'
import { usePopover } from '../../../../components/custom-popover'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../redux/slices/userDropdownSlice'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Typography, formHelperTextClasses } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// ----------------------------------------------------------------------

type Props = {
  filters: any
  onFilters: (name: string, value: any) => void
  dateError: boolean
}

export default function LeadsTableToolbar({ filters, onFilters, dateError }: Props) {
  const popover = usePopover()
  const branchData = useSelector(getBranch)

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value)
    },
    [onFilters],
  )

  const handleFilterBranch = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      onFilters('branch', typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value)
    },
    [onFilters],
  )

  const handleFilterAssigned = useCallback(
    (event: SelectChangeEvent<string>) => {
      onFilters('assigned', event.target.value)
    },
    [onFilters],
  )

  const handleFilterDate = useCallback(
    (event: SelectChangeEvent<string>) => {
      onFilters('date', event.target.value)
    },
    [onFilters],
  )

  const handleFilterStartDate = useCallback(
    (newValue: Date | null | unknown) => {
      onFilters('startDate', newValue)
    },
    [onFilters],
  )

  const handleFilterEndDate = useCallback(
    (newValue: Date | null | unknown) => {
      onFilters('endDate', newValue)
    },
    [onFilters],
  )

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction='row' alignItems='center' spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder='Search...'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Branch</InputLabel>

          <Select
            multiple
            value={filters.branch}
            onChange={handleFilterBranch}
            input={<OutlinedInput label='Branch' />}
            renderValue={(selected) => selected.map((value) => branchData.find((el) => el.BranchID == value).Branch).join(', ')}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {branchData.map((option) => (
              <MenuItem key={option.BranchID} value={option.BranchID}>
                <Checkbox disableRipple size='small' checked={filters?.branch?.includes(option.BranchID)} />
                {option.Branch}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Assigned To</InputLabel>

          <Select
            value={filters.assigned}
            onChange={handleFilterAssigned}
            input={<OutlinedInput label='Assigned To' />}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {[
              { key: 'All', value: 'All' },
              { key: 'Me', value: 'Me' },
              { key: 'Unassigned', value: 'Unassigned' },
            ].map((option) => (
              <MenuItem key={option.key} value={option.value}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl
          sx={{
            flexShrink: 0,
            width: { xs: 1, md: 200 },
          }}
        >
          <InputLabel>Date</InputLabel>

          <Select
            value={filters.date}
            onChange={handleFilterDate}
            input={<OutlinedInput label='Date' />}
            MenuProps={{
              PaperProps: {
                sx: { maxHeight: 240 },
              },
            }}
          >
            {[
              { key: 'between', value: 'Between' },
              { key: 'today', value: 'Today' },
              { key: 'yesterday', value: 'Yesterday' },
              { key: 'lastSevenDays', value: 'Last Seven Days' },
              { key: 'lastThirtyDays', value: 'Last Thirty Days' },
            ].map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        {filters.date === 'between' && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction='row' alignItems='center' spacing={2}>
              <DatePicker
                label='Start date'
                value={filters.startDate}
                onChange={handleFilterStartDate}
                disableFuture
                // renderInput={(params: any) => (
                //   <TextField
                //     error={dateError}
                //     sx={{
                //       maxWidth: { md: 200 },
                //     }}
                //     {...params}
                //   />
                // )}
              />
            </Stack>
            <Stack direction='row' alignItems='center' spacing={2}>
              <DatePicker
                label='End date'
                value={filters.endDate}
                onChange={handleFilterEndDate}
                disableFuture
                // renderInput={(params) => (
                //   <TextField
                //     error={dateError}
                //     sx={{
                //       maxWidth: { md: 200 },
                //       [`& .${formHelperTextClasses.root}`]: {
                //         position: { md: 'absolute' },
                //         bottom: { md: -40 },
                //       },
                //     }}
                //     {...params}
                //   />
                // )}
              />
            </Stack>
          </LocalizationProvider>
        )}
        {dateError && (
          <Typography variant='body2' color={'red'}>
            Start Date can not be greater than End Date
          </Typography>
        )}
      </Stack>
    </>
  )
}
