import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useSelector } from 'react-redux'
import { useField, useForm, useFormState } from 'react-final-form'
import {
  TFullResponse,
  TSelectedSearchAccount,
  UspAccountingChartOfAccountCurrentView,
  UspAccountingReturnControlDataResult,
  UspListCarExpenseTypeResult,
} from '../../../../../models'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { AmountField, TextField } from '../../../../../ui'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { formatNumber, getCurrentDate, parseToNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('form', ['root', 'row'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
    minWidth: '350px',
  },

  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

type LimitFieldKey = 'UnpaidLien' | 'LienPaid'

interface Props {
  onSave: (newData: TFullResponse) => void
  open: boolean
  requestCarID: number | null
  requestDealID: number | null
  handleClose: Dispatch<SetStateAction<boolean>>
  getAccountingControlData: (e: { chartOfAccountId: string }) => void
  getListsCarsExpense: (e: { isAddingNewExpense: string }) => void
  accountingControlData: UspAccountingReturnControlDataResult
  chartOfAccountBillData: UspAccountingChartOfAccountCurrentView[]
  setRequestCarID: Dispatch<SetStateAction<null | number>>
  setRequestDealID: Dispatch<SetStateAction<null | number>>
  listsCarsExpenseData: UspListCarExpenseTypeResult[]
  lockFields: () => void
  addEntryIsSuccess: boolean
  errors: boolean
  editingItemIndex: number | null
  setEditingItemIndex: Dispatch<SetStateAction<number | null>>
  setSelectedSearchAccount: Dispatch<SetStateAction<TSelectedSearchAccount | {}>>
  selectedSearchAccount: TSelectedSearchAccount
  totalSum: number | string
  isEdit: boolean
  setIsEdit: Dispatch<SetStateAction<boolean>>
  setModalDefaultValues: Dispatch<SetStateAction<TFullResponse | {}>>
  modalDefaultValues: any
  tableData: any
}

const JournalEntryAddEditModal = ({
  open,
  handleClose,
  setRequestCarID,
  setRequestDealID,
  chartOfAccountBillData,
  getAccountingControlData,
  accountingControlData,
  getListsCarsExpense,
  listsCarsExpenseData,
  lockFields,
  requestDealID,
  requestCarID,
  onSave,
  errors,
  setEditingItemIndex,
  addEntryIsSuccess,
  setSelectedSearchAccount,
  selectedSearchAccount,
  totalSum,
  isEdit,
  setIsEdit,
  setModalDefaultValues,
  editingItemIndex,
  modalDefaultValues,
  tableData,
}: Props) => {
  const form = useForm()
  const { values } = useFormState()
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [filteredExpenseTypes, setFilteredExpenseTypes] = useState<UspListCarExpenseTypeResult[]>([])
  const branchData = useSelector(getBranch)
  const [currentControlDetails, setCurrentControlDetails] = useState<string | null>(null)
  const [isControlled, setIsControlled] = useState(false)
  const [controlSubType, setControlSubType] = useState<string | null>(null)
  const {
    input: { value: chartOfAccountBillValue },
  } = useField('ChartOfAccountBill')
  const {
    input: { value: searchValue },
  } = useField('Search')

  const {
    input: { value: selectedExpenseType },
  } = useField('ExpenseType')

  const handleResetFormValues = () => {
    form.change('Sign', null)
    form.change('ChartOfAccountBill', null)
    form.change('searchResults', null)
    form.change('ExpenseType', null)
    form.change('IsFloorplan', false)
    form.change('Branch', null)
    form.change('Amount', null)
    if (setEditingItemIndex) {
      setEditingItemIndex(null)
    }
    if (setSelectedSearchAccount) {
      setSelectedSearchAccount({})
    }
    setRequestDealID(null)
    setRequestCarID(null)
    setControlSubType(null)
    setIsControlled(false)
    setSearchResults([])
    setFilteredExpenseTypes([])
    setCurrentControlDetails(null)
  }

  const handleSelection = (selectedItem: number) => {
    const foundItem = searchResults.find(
      (item) => item?.CarID === selectedItem || item?.DealID === selectedItem || item?.DealTradeID === selectedItem,
    )

    if (setSelectedSearchAccount) {
      setSelectedSearchAccount(foundItem)
    }
    const selectedAccount = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === chartOfAccountBillValue)

    if (!isEdit && controlSubType === 'Trade' && selectedSearchAccount && values.Sign === 'Debit') {
      form.change('Amount', selectedSearchAccount?.UnpaidLien?.toString())
    }

    if (!isEdit && controlSubType === 'Trade' && selectedSearchAccount && values.Sign === 'Credit') {
      form.change('Amount', selectedSearchAccount?.LienPaid?.toString())
    }

    if (selectedAccount && selectedItem) {
      if (values.EntryType === 'Ledger & Schedules' && selectedAccount?.ControlDetails === 'Account') {
        setRequestDealID(selectedItem)
      }
      if (values.EntryType === 'Ledger & Schedules' && selectedAccount?.ControlDetails === 'Inventory') {
        setRequestCarID(selectedItem)
      }
    } else {
      if (selectedAccount?.ControlDetails === 'Inventory') {
        setRequestCarID(selectedItem)
      }
      if (selectedAccount?.ControlDetails === 'Account') {
        setRequestDealID(selectedItem)
      }
    }
  }

  const determineOptionKeyAndValue = (results: any[]) => {
    const firstResult = results?.[0]
    if (firstResult?.DealID) {
      return { optionKey: 'DealID', optionValue: 'Customer' }
    } else if (firstResult?.CarID) {
      return { optionKey: 'CarID', optionValue: 'Vehicle' }
    } else {
      return { optionKey: 'DealTradeID', optionValue: 'TradeVehicle' }
    }
  }

  const { optionKey, optionValue } = useMemo(() => determineOptionKeyAndValue(searchResults), [searchResults])

  useEffect(() => {
    if (addEntryIsSuccess) {
      handleResetFormValues()
      // Note has to be reseted only after submitting the form
      form.change('Note', '')
    }
  }, [addEntryIsSuccess])

  useEffect(() => {
    if (open && !isEdit && tableData && tableData?.length > 0 && editingItemIndex === null) {
      // Set the Note to the last item from the table
      const lastItemDescription = tableData[tableData.length - 1]?.Description
      const absoluteTotalSum = Math.abs(totalSum as number).toString()
      const sign = (totalSum as number) < 0 ? 'Debit' : 'Credit'

      form.change('Sign', sign)
      form.change('Amount', absoluteTotalSum)
      form.change('Note', lastItemDescription)
    }
  }, [totalSum, editingItemIndex, open, tableData, isEdit])

  useEffect(() => {
    handleSelection(values?.searchResults)
  }, [values?.searchResults, values?.Sign, selectedSearchAccount, controlSubType])

  useEffect(() => {
    if (!accountingControlData || !accountingControlData.ReturnData) return
    const returnData = JSON?.parse(accountingControlData?.ReturnData)
    const matchesSearch = (value: string) => value?.toLowerCase().includes(searchValue?.toLowerCase() || '')
    const filteredResults = returnData?.filter((item: any) => {
      return matchesSearch(item.Customer) || matchesSearch(item.Vehicle) || matchesSearch(item.TradeVehicle)
    })
    setSearchResults(filteredResults)
    const [firstResult] = returnData
    if (firstResult) {
      if (firstResult.BranchID && !isEdit && values.EntryType !== 'Ledger Only') {
        form.change('Branch', firstResult.BranchID)
      }
    } else {
      setIsControlled(false)
    }
  }, [searchValue, accountingControlData, isControlled, selectedSearchAccount, values?.EntryType, isEdit])

  useEffect(() => {
    const selectedAccount = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === chartOfAccountBillValue)

    const {
      ControlDetails: controlDetails,
      ControlSubType: controlSubType,
      IsControlled: isControlledAccount,
      ChartOfAccountID: accountID,
    } = selectedAccount || {}

    setRequestCarID(null)
    setRequestDealID(null)
    setCurrentControlDetails(controlDetails || '')
    setControlSubType(controlSubType || '')

    if (values.EntryType === 'Ledger Only') {
      setIsControlled(false)
    } else {
      setIsControlled(isControlledAccount || false)
    }

    let filteredExpenses = listsCarsExpenseData // default
    if (controlSubType === 'Sold') {
      filteredExpenses = listsCarsExpenseData?.filter((item) => item?.IsAvailableForSoldInventory)
    } else if (controlSubType === 'Unsold') {
      filteredExpenses = listsCarsExpenseData?.filter((item) => item?.IsAvailableForUnsoldInventory)
    }

    setFilteredExpenseTypes(filteredExpenses)

    if ((values?.EntryType === 'Ledger & Schedules' && isControlledAccount) || controlSubType === 'Trade') {
      getAccountingControlData({
        chartOfAccountId: accountID?.toString() as string,
      })
    }

    if (values?.EntryType === 'Ledger & Schedules' && controlDetails === 'Inventory') {
      getListsCarsExpense({
        isAddingNewExpense: 'true',
      })
    }

    if (!isControlledAccount) {
      form.change('searchResults', [])
      form.change('ExpenseType', null)
      setSearchResults([]) // reset
    }
  }, [chartOfAccountBillValue, chartOfAccountBillData, listsCarsExpenseData, isControlled, values?.EntryType])

  const isExpenseTypePurchase = useMemo(() => {
    const selectedExpense = filteredExpenseTypes?.find((item) => item?.ExpenseType === selectedExpenseType)

    return selectedExpense?.ExpenseType === 'Purchase'
  }, [filteredExpenseTypes, selectedExpenseType])

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (isEdit) {
          handleResetFormValues()

          // extract EntryType and EntryDate from the current modalDefaultValues
          const { EntryType, EntryDate, Description } = modalDefaultValues as any
          setModalDefaultValues({
            EntryType: EntryType || 'Ledger & Schedules',
            EntryDate: EntryDate || getCurrentDate(),
            Note: Description || '',
          })
        }
        handleClose(true)
        setIsEdit(false)
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <DialogTitle>Line Item</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} py={1}>
          <Grid item xs={12}>
            <DropdownFieldSearch
              name='Sign'
              label='Sign'
              options={[
                { key: 'Debit', value: 'Debit' },
                { key: 'Credit', value: 'Credit' },
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <DropdownFieldSearch
              name='ChartOfAccountBill'
              label='Chart Of Account (Bill)'
              options={chartOfAccountBillData}
              optionKey={'ChartOfAccountID'}
              optionValue={'AccountNumberName'}
            />
          </Grid>
          {isControlled && values?.EntryType === 'Ledger & Schedules' && (
            <Grid item xs={12}>
              <DropdownFieldSearch
                // customDropdownWidth={600}
                id='search-results'
                name='searchResults'
                label='Search Results'
                options={searchResults}
                optionKey={optionKey}
                optionValue={optionValue}
                getOptionLabel={(option: any) => option[optionKey] || ''}
                renderInput={(params: any) => <TextField {...params} label='Search Results' />}
                renderOption={(props: any, option: any) => (
                  <li {...props} key={optionKey}>
                    <Grid container justifyContent={'space-between'}>
                      <Grid item>
                        <Typography variant='body2'>{option.Customer || option.Vehicle}</Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            color: 'primary.main',
                          }}
                        >
                          {option.StockNumber} | {option.BranchCode}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                        <Typography variant='caption'>${formatNumber((option?.Balance as number) || (option?.InventoryCost as number))}</Typography>
                        <Typography
                          variant='body2'
                          sx={{
                            color: option?.AccountStatus === 'Active' ? 'green' : 'red',
                            fontWeight: option?.AccountStatus === 'Active' ? 'bold' : 'regular',
                          }}
                        >
                          {option.AccountStatus || option.CarStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
              />
            </Grid>
          )}

          {currentControlDetails === 'Inventory' && values?.EntryType === 'Ledger & Schedules' && (
            <Grid item xs={12} container>
              {isControlled && (
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='ExpenseType'
                    label='Expense Type'
                    options={filteredExpenseTypes}
                    optionKey='ExpenseType'
                    optionValue='ExpenseType'
                  />
                </Grid>
              )}

              {isExpenseTypePurchase && (
                <>
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='IsFloorplan'
                      label='Is Floorplan?'
                      options={[
                        { key: true, value: 'Yes' },
                        { key: false, value: 'No' },
                      ]}
                      optionKey='key'
                      optionValue='value'
                    />
                  </Grid>
                </>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <DropdownFieldSearch
              disabled={isControlled}
              name='Branch'
              label='Branch'
              options={branchData}
              optionKey='BranchID'
              optionValue='Branch'
            />
          </Grid>
          <Grid item xs={12}>
            <AmountField
              variant='standard'
              validate={() => {
                if (controlSubType !== 'Trade' || values.EntryType === 'Ledger Only') return
                const amountValue = parseToNumber(values.Amount?.toString() as string)
                let limitValue: number | undefined
                let limitField: LimitFieldKey | undefined
                if (values?.EntryType === 'Ledger & Schedules') {
                  if (values?.Sign === 'Debit') {
                    limitField = 'UnpaidLien'
                  } else if (values?.Sign === 'Credit') {
                    limitField = 'LienPaid'
                  }
                } else {
                  limitField = 'UnpaidLien'
                }
                if (limitField) {
                  limitValue = parseToNumber(selectedSearchAccount?.[limitField]?.toString() as string)
                }
                if (limitValue !== undefined && amountValue > limitValue) {
                  return `Amount cannot be greater than ${formatNumber(limitValue.toString())}`
                }
              }}
              name='Amount'
              label='Amount'
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField name='Note' label='Comments' fullWidth multiline autoComplete='off' />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          // color='primary'
          type='submit'
          disabled={errors}
          onClick={() => {
            if (lockFields) {
              lockFields()
            }
            if (onSave) {
              const matchedItem = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === form.getState()?.values?.ChartOfAccountBill)
              const matchedBranch = branchData?.find((item) => item?.BranchID === form.getState()?.values?.Branch)
              const foundItem = chartOfAccountBillData.find((item) => item?.ChartOfAccountID === form.getState()?.values?.ChartOfAccountBill)
              const coaAccountNumberValue = matchedItem?.AccountNumberName
              const matchedBranchName = matchedBranch?.Branch
              const accountSubType = foundItem

              const newData: any = {
                AccountNumberNameValue: coaAccountNumberValue,
                SelectedSearchAccount: selectedSearchAccount,
                BranchName: matchedBranchName,
                BillAmount:
                  form.getState().values.Sign === 'Debit'
                    ? parseToNumber(form.getState().values.Amount)
                    : parseToNumber(form.getState().values.Amount) * -1,
                Description: form.getState().values.Note,
                EntryType: form.getState().values.EntryType,
                EntryDate: form.getState().values.EntryDate,
                BranchCode: form.getState().values.Branch,
                CarID: requestCarID,
                DealID: requestDealID,
                CarExpenseType: form.getState().values.ExpenseType,
                AccountNumberName: form.getState().values.ChartOfAccountBill,
                BranchID: form.getState().values.Branch,
                StockNumber: selectedSearchAccount?.StockNumber,
                ID: Math.floor(Math.random() * 100),

                Sign: form.getState().values.Sign,
                SelectedCoaItem: accountSubType,
                SearchResult: form.getState().values.searchResults,
                IsFloorplan: form.getState().values.IsFloorplan,
              }
              onSave(newData)
              handleClose(true)
              handleResetFormValues()
              form.resetFieldState('ChartOfAccountBill')
              form.resetFieldState('Branch')
              form.resetFieldState('Amount')
              form.resetFieldState('EntryType')
              form.resetFieldState('EntryDate')
            }
          }}
        >
          {isEdit ? 'Update' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default JournalEntryAddEditModal
