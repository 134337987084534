import React, { useEffect } from 'react'
import { Header } from './components/Header'
import { LeadsReferenceTableBlock } from './components/LeadsReferenceTableBlock'
import { useLazyContactsReferenceGetQuery } from '../../../services'
import { withCreditApps } from '../../../hoc'
import { UspGetDataCreditApp } from '../../../models'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, generateUtilityClasses, Grid, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/system'
import { appRoute } from '../../../constants'

const classes = generateUtilityClasses('Grid', ['root', 'btnWrap', 'btnCancelWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.btnWrap}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 3, 0, 3),
  },
  [`& .${classes.btnCancelWrap}`]: {
    marginTop: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(3, 3, 3, 3),
  },
}))

interface Props {
  creditAppData: UspGetDataCreditApp
  creditAppIsLoading: boolean
}

export const LeadsReference = ({ creditAppData }: Props) => {
  const [contactsReference, { data: contactsReferenceData, isLoading: contactsReferenceLoading }] = useLazyContactsReferenceGetQuery()
  const navigate = useNavigate()
  const { leadID, contactID } = useParams()

  useEffect(() => {
    contactsReference({ contactId: contactID?.toString() as string })
  }, [])

  return (
    <StyledGrid className={classes.root}>
      <Header creditAppData={creditAppData} />
      <Grid className={classes.btnWrap}>
        <Typography variant='h3'>References</Typography>
        <Button
          variant='contained'
          startIcon={<AddIcon />}
          onClick={() => {
            navigate(
              appRoute.LeadsReferenceAddEdit.replace(':leadID', String(leadID))
                .replace(':contactID', String(contactID))
                .replace(':referenceID', 'add'),
            )
          }}
        >
          Add new
        </Button>
      </Grid>
      <LeadsReferenceTableBlock
        loading={contactsReferenceLoading}
        contactsReferenceData={contactsReferenceData}
        contactsReference={contactsReference}
      />
      <Grid item xs={12} className={classes.btnCancelWrap}>
        <Button variant='contained' color='error' onClick={() => navigate(appRoute.LeadsTask.replace(':leadID', leadID as string))}>
          Close
        </Button>
      </Grid>
    </StyledGrid>
  )
}

export default withCreditApps(LeadsReference)
