import { Box, BoxProps, Grid, generateUtilityClasses, Typography, Dialog, DialogContent, Card } from '@mui/material'
import { styled } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { DropdownCheckboxes, TextField } from '../../../../../ui'
import { useDispatch, useSelector } from 'react-redux'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { Form } from 'react-final-form'
import { SearchCarApiResponse } from '../../../../../models'
import SearchIcon from '@mui/icons-material/Search'
import { InventoryTableBlock } from '../../../../Inventory/InventoryList/components/InventoryTableBlock'
import { useCreditAppLookingAtMutation, useLazyGetCreditAppQuery, useSearchCarMutation } from '../../../../../services'
import { withPermissionsDropdown } from '../../../../../hoc'
import CenteredModal from '../../../../../components/Modal/CenteredModal'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'

const classes = generateUtilityClasses('Box', ['root', 'searchWrap', 'searchWrapContainer', 'tableWrapContainer', 'checkboxWrap'])

interface StyledBoxProps extends BoxProps {
  searchCarsData?: SearchCarApiResponse
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, searchCarsData }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '30px',
    maxHeight: searchCarsData ? '600px' : 'auto',
  },
  [`& .${classes.searchWrapContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    padding: theme.spacing(0.5),
  },
  [`& .${classes.tableWrapContainer}`]: {
    overflow: 'auto',
    maxWidth: '100%',
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.checkboxWrap}`]: {
    margin: theme.spacing(0, 4, 0, 0),
    fontSize: '14px',
  },
}))

interface Props {
  open: boolean
  onClose: () => void
}

const SelectInventoryModal = ({ open, onClose }: Props) => {
  const [searchCars, { data: searchCarsData, isLoading: searchCarsIsLoading }] = useSearchCarMutation()
  const [lookingAtRequest, { isSuccess }] = useCreditAppLookingAtMutation()
  const [getCreditApp] = useLazyGetCreditAppQuery()

  const branchData = useSelector(getBranch)
  const dispatch = useDispatch()
  const { leadID } = useParams()
  const debouncedSearch = useCallback(
    debounce((values) => {
      const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))
      searchCars({
        uspCarSearch: {
          searchString: values?.searchString || null,
          jsonBranchList: jsonBranchList,
          isIncludeSoldCars: false,
          isPendingTradeOnly: false,
        },
      })
    }, 500),
    [searchCars],
  )

  const handleSubmit = (values: any) => {
    debouncedSearch(values)
  }

  useEffect(() => {
    if (isSuccess) {
      getCreditApp({ creditAppId: leadID as string })
      dispatch(enqueueNotification({ message: 'Success' }))
      onClose()
    }
  }, [isSuccess])

  return (
    <Dialog maxWidth={false} open={open} onClose={() => onClose()}>
      <DialogContent sx={{ p: 3 }}>
        <Form onSubmit={handleSubmit} initialValues={{ searchString: null }}>
          {({ handleSubmit, values }: any) => (
            <Grid container xs={12} spacing={2}>
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h5'>Select Inventory</Typography>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    variant='outlined'
                    onChange={() => handleSubmit(values)}
                    name='searchString'
                    placeholder='Search'
                    fullWidth
                    InputProps={{
                      endAdornment: <SearchIcon />,
                    }}
                  />
                </Grid>
                {branchData?.length > 1 && (
                  <Grid item xs={3}>
                    <DropdownCheckboxes
                      onChange={() => handleSubmit(values)}
                      name='branch'
                      variant='outlined'
                      label='Branch'
                      labelId='BranchId'
                      fullWidth
                      options={branchData}
                      optionKey='BranchID'
                      optionValue='Branch'
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12}>
                <InventoryTableBlock lookingAtRequest={lookingAtRequest} loading={searchCarsIsLoading} searchCarsData={searchCarsData} />
              </Grid>
            </Grid>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default withPermissionsDropdown(SelectInventoryModal)
