import { Form } from 'react-final-form'
import Grid from '@mui/material/Grid'
import { Card, generateUtilityClasses, Tooltip, Typography } from '@mui/material'
import { CheckboxField } from '../../../ui'
import Button from '@mui/material/Button'
import { ReactComponent as PrintIcon } from '../../../assets/icons/printer.svg'
import { UspCarPaymentViewResult } from '../../../models'
import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Header } from '../components/Header'
import { useParams } from 'react-router-dom'
import { useCarNoteGetMutation, useGetCarPaymentMutation } from '../../../services'
import { withCars } from '../../../hoc'
import { TNoteRequest } from '../InventoryEditNotes/InventoryEditNotes'
import InventoryPaymentsTableBlock from './components/InventoryPaymentsTableBlock/InventoryPaymentsTableBlock'

const classes = generateUtilityClasses('Grid', ['root', 'subHeaderWrap', 'titleWrap', 'btnWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.subHeaderWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },
  [`& .${classes.btnWrap}`]: {
    width: '30px',
    height: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
}))

type TFormFields = {
  isIncludeAllHistoryByCar: boolean
  isIncludeCancelledNotes: boolean
}

const InventoryEditPayments = (props: any) => {
  const { carID } = useParams()
  const { carData } = props
  const [formChanged, setFormChanged] = useState(false)
  const [getCarPayment, { data, isLoading }] = useGetCarPaymentMutation()
  const [includeVoidedPayments, setIncludeVoidedPayments] = useState(false)
  const [noteRequestData, setNoteRequestData] = useState<TNoteRequest>({
    isIncludeAllHistoryByCar: true,
    isIncludeCancelledNotes: true,
  })
  const [getCarNote, { data: carNoteData, isLoading: carNoteIsLoading }] = useCarNoteGetMutation()

  const handleSubmit = (values: TFormFields) => {
    setFormChanged(false)
    getCarNote({
      carId: carID as string,
      uspCarNoteView: {
        isIncludeAllHistoryByCar: values.isIncludeAllHistoryByCar,
        isIncludeCancelledNotes: values.isIncludeCancelledNotes,
      },
    })
  }

  useEffect(() => {
    getCarPayment({
      carId: carID as string,
      uspCarPaymentView: {
        isIncludeReversals: includeVoidedPayments,
      },
    })
  }, [includeVoidedPayments])
  return (
    <>
      <Form onSubmit={handleSubmit}>
        {({}: any) => (
          <>
            <StyledGrid className={classes.root}>
              {/* <Header
                getCarPayment={getCarPayment}
                carData={carData}
                modelYear={carData?.ModelYear}
                make={carData?.Make}
                model={carData?.Model}
                vin={carData?.VIN}
                stockNumber={carData?.StockNumber}
                getCarNote={getCarNote}
                noteRequestData={noteRequestData}
                carIsSuccess={props?.carIsSuccess}
                getCar={props?.getCar}
                formChanged={formChanged}
                setFormChanged={setFormChanged}
                onDropdownFormChange={() => setFormChanged(true)}
              /> */}
              <Card
                sx={{
                  height: { xs: 800, md: 2 },
                  flexGrow: { md: 1 },
                  display: { md: 'flex' },
                  flexDirection: { md: 'column' },
                }}
              >
                <Grid container className={classes.subHeaderWrap}>
                  <Grid item className={classes.titleWrap}>
                    <CheckboxField
                      name='includeVoidedPayments'
                      label='Include Voided Payments'
                      onChange={(e) => setIncludeVoidedPayments(e?.target?.checked)}
                    />
                  </Grid>
                  <Grid item>
                    <Tooltip title='Print'>
                      <Button color='success' variant='text' className={classes.btnWrap}>
                        <PrintIcon />
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
                <InventoryPaymentsTableBlock
                  includeVoidedPayments={includeVoidedPayments}
                  getCarPayment={getCarPayment}
                  loading={isLoading}
                  inventoryCarPaymentData={data as UspCarPaymentViewResult[]}
                  carData={props?.carData}
                />
              </Card>
            </StyledGrid>
          </>
        )}
      </Form>
    </>
  )
}

export default withCars(InventoryEditPayments)
