import React, { useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, generateUtilityClasses } from '@mui/material'
import Grid from '@mui/material/Grid'
import { Form } from 'react-final-form'
import Typography from '@mui/material/Typography'
import { AmountField, DateField, TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useEditDealProductMutation, useLazyGetDealProductDataQuery, useVendorListsMutation } from '../../../../../services'
import { transformToFormValues, transformToRequestValues } from './transform'
import { UspDealProductDataViewResult } from '../../../../../models'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import validateFormValues from '../../../../../utils/validateForm'
import { productValidation } from '../../../../../validation/editDealProductValidation'

const classesBox = generateUtilityClasses('Container', ['root', 'title', 'row', 'btnWrap', 'icon'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classesBox.root}`]: {
    width: '287px',
    padding: theme.spacing(4),
  },
  [`& .${classesBox.title}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.row}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classesBox.btnWrap}`]: {
    justifyContent: 'space-between',
  },
  [`& .${classesBox.icon}`]: {
    margin: theme.spacing(3, 0),
  },
}))

interface Props {
  anchorEl: HTMLButtonElement | null
  setAnchorEl: (value: HTMLButtonElement | null) => void
  rowData: any
  onEditSuccess: (isSuccess: boolean) => void
}

const DealsProductsEdit = ({ anchorEl, setAnchorEl, rowData, onEditSuccess }: Props) => {
  const { dealID } = useParams()
  const [initialValues, setInitialValues] = useState({})
  const [vendorList, { data: vendorListData }] = useVendorListsMutation()
  const [editProductDeal, { isSuccess: editDealProductIsSuccess }] = useEditDealProductMutation()
  const [getDealProductData, { data }] = useLazyGetDealProductDataQuery()
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  useEffect(() => {
    vendorList({
      uspVendorLists: {
        currentID: null,
        vendorType: null,
      },
    })
  }, [])

  useEffect(() => {
    if (open && rowData?.DealProductID) {
      getDealProductData({ productId: rowData?.DealProductID?.toString() as string })
    }
  }, [open])

  useEffect(() => {
    if (editDealProductIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      onEditSuccess(editDealProductIsSuccess)
    }
  }, [editDealProductIsSuccess])

  useEffect(() => {
    setInitialValues(transformToFormValues(data as UspDealProductDataViewResult))
  }, [data])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSubmit = (values: any) => {
    editProductDeal({
      uspDealProductEdit: transformToRequestValues(values) as any,
      productId: rowData?.DealProductID as string,
      dealId: dealID as string,
    })
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(productValidation)}>
        {({ handleSubmit }: any) => (
          <StyledBox onSubmit={handleSubmit} className={classesBox.root}>
            <Typography variant='h3' className={classesBox.title}>
              {data?.ProductLabel}
            </Typography>
            <Grid className={classesBox.row}>
              <DropdownFieldSearch
                name='Vendors'
                label='Vendors'
                options={vendorListData as any}
                optionKey='ID'
                optionValue='Name'
                autoComplete='off'
              />
            </Grid>
            <Grid className={classesBox.row}>
              <AmountField variant='standard' disabled name='ProductPrice' label='Selling Price' fullWidth autoComplete='off' />
            </Grid>
            <Grid className={classesBox.row}>
              <AmountField variant='standard' name='ProductCost' label='Product Cost' fullWidth autoComplete='off' />
            </Grid>
            <Grid className={classesBox.row}>
              <TextField name='ExpirationMileage' label='Expiration Mileage' fullWidth autoComplete='off' />
            </Grid>
            <Grid className={classesBox.row}>
              <TextField name='Term' label='Product Term (in Months)' fullWidth autoComplete='off' />
            </Grid>
            <Grid item className={classesBox.row}>
              <DateField name='Expires' label='Expiration Date' fullWidth />
            </Grid>
            <Grid className={classesBox.btnWrap} container>
              <Button variant='contained' color='error' onClick={handleClose}>
                Cancel
              </Button>
              <Button variant='contained' type='submit'>
                Save
              </Button>
            </Grid>
          </StyledBox>
        )}
      </Form>
    </Dialog>
  )
}

export default DealsProductsEdit
