import { Grid, Typography } from '@mui/material'
import AmountField from '../../../../../ui/AmountField'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const PricingForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const { values } = useFormState()
  return (
    <Grid container spacing={{ xs: 2, md: 3 }}>
      <Grid item xs={12}>
        <Typography variant='h6' gutterBottom>
          Pricing
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='isAutoMarkup'
          label='Auto Markup?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <AmountField
          variant='standard'
          name='stickerPrice'
          disabled={values.isAutoMarkup || disableFields}
          label='Sticker Price'
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <AmountField variant='standard' disabled={disableFields} name='onlinePrice' label='Online Price' fullWidth required />
      </Grid>
      <Grid item xs={6}>
        <AmountField variant='standard' disabled={disableFields} name='wholesalePrice' label='Wholesale Price' fullWidth required />
      </Grid>
      <Grid item xs={6}>
        <AmountField variant='standard' disabled={disableFields} name='downPayment' label='Required Down' fullWidth required />
      </Grid>
    </Grid>
  )
}

export default PricingForm
