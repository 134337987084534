import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'
import Stack, { StackProps } from '@mui/material/Stack'
import Label, { LabelColor } from '../../../../components/label'
import { Box, Tooltip } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'

// ----------------------------------------------------------------------
type Props = PaperProps &
  StackProps & {
    name?: string
    type?: string
    buyer?: string
    IsVerified?: boolean
    addedBy?: string
    stipulationStatus?: string
    action?: React.ReactNode
  }

export default function StipulationItem({ name, type, stipulationStatus, buyer, addedBy, IsVerified, sx, action, ...other }: Props) {
  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-end' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack flexGrow={1} spacing={1}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2'>{name}</Typography>
        </Stack>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Added By {addedBy}
        </Typography>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {type}
        </Typography>
        <Box sx={{ position: 'absolute', top: 13, right: 45 }}>
          {IsVerified ? (
            ''
          ) : (
            <Tooltip title='Unverified'>
              <CancelIcon color='error' />
            </Tooltip>
          )}
        </Box>
      </Stack>
      {action && action}
    </Stack>
  )
}
