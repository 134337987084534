import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { CustomCircular, PercentField, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import { UspStateCityTaxRateViewResult } from '../../../../models/apiTypes'
import { useAddCityTaxesMutation, useEditCityTaxesMutation, useLazyGetCityTaxesQuery } from '../../../../services'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../redux/slices/userDropdownSlice'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspStateCityTaxRateViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditCityTaxes = ({ open, onClose, rowData, type, submit }: Props) => {
  const [formChanged, setFormChanged] = useState(false)
  const states = useSelector(getStates)
  const [initialValues, setInitialValues] = useState({})
  const [addCityTaxes, { isLoading: isLoadingCityTaxes, isSuccess: isSuccessCityTaxes }] = useAddCityTaxesMutation()
  const [editCityTaxes, { isLoading: isLoadingEditCityTaxes, isSuccess: isSuccessEditCityTaxes }] = useEditCityTaxesMutation()
  const [getCityTaxesData, { isLoading: isLoadingGetCityTaxes, isSuccess: isSuccessGetCityTaxes, data }] = useLazyGetCityTaxesQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editCityTaxes({
        cityTaxRateId: rowData?.ID?.toString(),
        uspStateCityTaxRateView: {
          state: values.State,
          city: values.City,
          taxRate: values.TaxRate,
        },
      })
    } else {
      addCityTaxes({
        uspStateCityTaxRateView: {
          state: values.State,
          city: values.City,
          taxRate: values.TaxRate,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getCityTaxesData({
        cityTaxRateId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        State: '',
        City: '',
        TaxRate: '0',
      })
    }
    if (isSuccessGetCityTaxes && type === 'edit') {
      setInitialValues({
        State: data?.State,
        City: data?.City,
        TaxRate: data?.TaxRate?.toString(),
      })
    }
  }, [isSuccessGetCityTaxes, data, type])

  useEffect(() => {
    if (isSuccessCityTaxes || isSuccessEditCityTaxes) {
      onClose()
      setFormChanged(false)
      submit()
    }
  }, [isSuccessCityTaxes, isSuccessEditCityTaxes])

  const isLoading = useMemo(
    () => isLoadingCityTaxes || isLoadingEditCityTaxes || isLoadingGetCityTaxes,
    [isLoadingCityTaxes, isLoadingEditCityTaxes, isLoadingGetCityTaxes],
  )
  return (
    <Dialog
      open={open}
      onClose={() => {
        setFormChanged(false)
        onClose()
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <DialogTitle>City Tax</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    required
                    name='State'
                    label='State'
                    options={states}
                    optionKey='State'
                    optionValue='State'
                    autoComplete='off'
                    onChange={() => setFormChanged(true)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField required name='City' label='City' fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <PercentField variant='standard' required name='TaxRate' label='Tax Rate (%)' fullWidth />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <LoadingButton disabled={!formChanged} type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditCityTaxes
