import { Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { TextField } from '../../../../../../../ui'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import { ParsedDropdownResponse } from '../../../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  checkType: ParsedDropdownResponse[]
}

const CheckCard = ({ checkType }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DropdownFieldSearch name='CheckType' label='Check Type' options={checkType} optionKey='ID' optionValue='CheckType' autoComplete='off' />
      </Grid>
      <Grid item xs={12}>
        <TextField name='CheckNumber' label='Check Number' fullWidth />
      </Grid>
    </Grid>
  )
}

export default CheckCard
