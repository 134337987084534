import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { Button, FormControlLabel, Grid, IconButton, Switch, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { Field, useForm, useFormState } from 'react-final-form'
import AmountField from '../../../../../../../ui/AmountField'
import PaymentsPopover from '../PaymentsPopover'
import EditIcon from '@mui/icons-material/Edit'
import DropdownFieldSearch from '../../../../../../../ui/DropdownFieldSearch'
import removeMask from '../../../../../../../utils/removeMask'
import Iconify from '../../../../../../../components/iconify'
import { round } from 'lodash'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  paymentsForm: string[]
  setTotalPickups?: Dispatch<SetStateAction<boolean>>
  formPayments: string[]
  setFormPayments: Dispatch<SetStateAction<string[]>>
  setPaymentsForm: Dispatch<SetStateAction<string[]>>
  setIsDownPaymentDeleteCalled: Dispatch<SetStateAction<boolean>>
  disableFields: boolean
}

export const CapCostForm = ({
  paymentsForm,
  setTotalPickups,
  formPayments,
  setFormPayments,
  setPaymentsForm,
  setIsDownPaymentDeleteCalled,
  disableFields,
}: Props) => {
  const { values } = useFormState()
  const { change } = useForm()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event?.currentTarget)
  }

  const computePickupPayments = (values: { [key: string]: any }) => {
    let amount = 0
    Object?.keys(values)?.forEach((item: string) => {
      if (item?.includes('DatePaymentAmount') && values[item]) {
        amount += Number(removeMask(values[item]))
      }
    })
    return amount?.toString()
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid container className={classes.row}>
          <Typography variant='subtitle1' gutterBottom>
            Cap Cost Reduction
          </Typography>
        </Grid>
        <Grid className={classes.row}>
          <AmountField
            variant='standard'
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              change('taxOnCashDown', round((Number(e.target.value) * 6) / 100, 2).toString())
            }}
            disabled={disableFields}
            name='CashDown'
            label='Cash Down'
            startAdornment={<Iconify icon='iconoir:dollar' style={{ color: '#c4c0c0' }} />}
            endAdornment={
              Number(removeMask(values.total_tax)) > 0 &&
              Number(removeMask(values.CashDown)) > 0 && (
                <Button
                  sx={{ width: '150px' }}
                  onClick={() => {
                    let val = round(Number(removeMask(values.CashDown)) / (1 + Number(removeMask(values.total_tax)) / 100), 2)
                    change('CashDown', val.toString())
                  }}
                >
                  <Typography variant='caption' noWrap>
                    Including Tax
                  </Typography>
                </Button>
              )
            }
            fullWidth
          />
        </Grid>
        <Grid className={classes.row}>
          <Field name='DealHasTrade'>
            {(props) => (
              <div>
                <FormControlLabel
                  // sx={{ m: 2, top: 18, position: 'absolute', left: 80 }}
                  control={
                    <Switch
                      name={props.input.name}
                      checked={props.input.value === true}
                      onChange={(e) => {
                        props.input.onChange(e.target.checked ? true : false)
                      }}
                    />
                  }
                  label='Has Trade?'
                />
              </div>
            )}
          </Field>
        </Grid>
        {values?.DealHasTrade && (
          <>
            <Grid className={classes.row}>
              <AmountField variant='standard' disabled={disableFields} name='TradeAllowance' label='Trade Allowance' fullWidth />
            </Grid>
            <Grid className={classes.row}>
              <AmountField variant='standard' disabled={disableFields} name='TradeLien' label='Trade Lien' fullWidth />
            </Grid>
            <Grid className={classes.row}>
              <AmountField variant='standard' disabled={disableFields} name='TradeACV' label='Trade ACV' fullWidth />
            </Grid>
          </>
        )}
      </StyledGrid>
      <PaymentsPopover
        setIsDownPaymentDeleteCalled={setIsDownPaymentDeleteCalled}
        setTotalPickups={setTotalPickups}
        computePickupPayments={computePickupPayments}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        paymentsForm={paymentsForm}
        formPayments={formPayments}
        setFormPayments={setFormPayments}
        setPaymentsForm={setPaymentsForm}
      />
    </>
  )
}

export default CapCostForm
