import { Button, Card, Container, Grid, Paper, Stack, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { CustomCircular } from '../../../../../ui'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import Header from '../components/Header/Header'
import {
  useGetAccountingChartOfAccountCurrentMutation,
  useLazyGetAccountingControlDataQuery,
  useLazyListsCarsExpenseTypeQuery,
  useVendorListsMutation,
  useGetAccountingChartApAccountsMutation,
} from '../../../../../services'
import SettingsForm from '../components/SettingsForm/SettingsForm'
import ChecksTable from '../components/ChecksTable/ChecksTable'
import {
  TFullResponse,
  TSelectedSearchAccount,
  UspAccountingChartApAccountsView,
  UspAccountingChartOfAccountCurrentView,
  UspAccountingReturnControlDataResult,
  UspListCarExpenseTypeResult,
  VendorResponseSp,
} from '../../../../../models'
import { formatNumber, parseToNumber } from '../../../../../utils/general'
import { withPermissionsDropdown } from '../../../../../hoc'
import validateFormValues from '../../../../../utils/validateForm'
import { accountingChecksAddValidation } from '../../../../../validation/accountingChecksValidation'
import { transformToFormValues } from '../AccountingChecksAdd/transform'
import ChecksAddEditModal from '../../components/ChecksAddEditModal/ChecksAddEditModal'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../../../components/ConfirmDialog'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import InvoiceNewEditDetails from '../AccountingChecksAdd/MultilineNewEditDetails'
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import InvoiceNewEditAddress from '../AccountingChecksAdd/MultilineNewEditAddress'
import InvoiceNewEditStatusDate from '../AccountingChecksAdd/MultilineNewEditStatusDate'
import { LoadingButton } from '@mui/lab'
import { paths } from '../../../../../routes/paths'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'formItem', 'buttonWrap', 'closeBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 2, 2),
    display: 'flex',
    alignItems: 'flexStart',
    justifyContent: 'justify-start',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 3),
    marginTop: 'auto',
  },
  [`& .${classes.closeBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  setCurrentStage: (stage: 'settings' | 'paymentDetails' | null) => void
  setTotalSum: Dispatch<SetStateAction<string | number>>
  totalSum: string
  setTableData: Dispatch<SetStateAction<TFullResponse[]>>
  tableData: TFullResponse[]
  setSelectedVendor: Dispatch<SetStateAction<number | null>>
  setSelectedSearchAccount: Dispatch<SetStateAction<TSelectedSearchAccount | {}>>
  selectedSearchAccount: TSelectedSearchAccount
  addCheckIsSuccess: boolean
  setTableHasData: Dispatch<SetStateAction<boolean>>
  tableHasData: boolean
}

const AccountingChecksSettings = ({
  setSelectedVendor,
  setCurrentStage,
  totalSum,
  setTotalSum,
  setTableData,
  tableData,
  setSelectedSearchAccount,
  selectedSearchAccount,
  addCheckIsSuccess,
  setTableHasData,
  tableHasData,
}: Props) => {
  const navigate = useNavigate()
  const [initialValues, setInitialValues] = useState({})
  const [requestDealID, setRequestDealID] = useState<null | number>(null)
  const [requestCarID, setRequestCarID] = useState<null | number>(null)
  const [isFieldsLocked, setIsFieldsLocked] = useState(false)
  const [checkToDelete, setCheckToDelete] = useState<null | TFullResponse>(null)
  const [openChecksModal, setOpenChecksModal] = useState(false)
  const [modalDefaultValues, setModalDefaultValues] = useState<{}>({})
  const [editingItemIndex, setEditingItemIndex] = useState<null | number>(null)
  const [isEdit, setIsEdit] = useState(false)

  const [vendorList, { data: vendorListData, isLoading: vendorListIsLoading }] = useVendorListsMutation()
  const [chartOfAccountAp, { data: chartOfAccountApData, isLoading: chartOfAccountApIsLoading }] = useGetAccountingChartApAccountsMutation()
  const [getListsCarsExpense, { data: listsCarsExpenseData, isLoading: listsCarsExpenseIsLoading }] = useLazyListsCarsExpenseTypeQuery()
  const [getAccountingControlData, { data: accountingControlData, isLoading: accountingControlDataIsLoading }] =
    useLazyGetAccountingControlDataQuery()
  const [chartOfAccountBill, { data: chartOfAccountBillData, isLoading: chartOfAccountBillIsLoading }] =
    useGetAccountingChartOfAccountCurrentMutation()

  const handleOpenAddChecksModal = () => setOpenChecksModal(true)
  const handleCloseAddChecksModal = () => setOpenChecksModal(false)

  const handleDeleteCheck = (item: TFullResponse) => {
    setCheckToDelete(item)
  }

  const handleSave = (newData: TFullResponse) => {
    const itemWithIndex = {
      ...newData,
    }
    setTableData((prevData) => [...prevData, itemWithIndex])
  }

  // Function to update a specific item in the tables data
  const updateTableItem = (newItem: TFullResponse) => {
    setTableData((prevData) => {
      const updatedData = [...prevData]
      if (editingItemIndex !== null && editingItemIndex >= 0) {
        updatedData[editingItemIndex] = newItem
      }
      return updatedData
    })
  }

  const handleModalSave = (newData: TFullResponse) => {
    if (editingItemIndex !== null) {
      updateTableItem(newData)
    } else {
      handleSave(newData)
    }
    handleCloseAddChecksModal()
    setEditingItemIndex(null) // Reset the editing index
  }

  useEffect(() => {
    setInitialValues(transformToFormValues(modalDefaultValues))
  }, [modalDefaultValues, editingItemIndex, isEdit, openChecksModal])

  useEffect(() => {
    vendorList({
      uspVendorLists: {
        currentID: null,
        vendorType: null,
      },
    })
    chartOfAccountAp({})
    chartOfAccountBill({
      uspChartOfAccountView: {
        currentID: null,
        isAllowedSelectControlledAccount: true,
        isIncludeAccountsThatCannotBeSelected: true,
        isReturnAllCompanyTypes: false,
      },
    })
  }, [])

  useEffect(() => {
    const updatedTableData = tableData?.filter((item: TFullResponse) => item?.ID !== checkToDelete?.ID)
    setTableData(updatedTableData)
    handleCloseAddChecksModal()
    setCheckToDelete(null)
  }, [checkToDelete])

  useEffect(() => {
    // Calculate total sum from the table
    const total = tableData?.reduce((acc: number, item: TFullResponse) => {
      const billAmount = item?.BillAmount ? parseToNumber(item?.BillAmount?.toString()) : 0
      return acc + billAmount
    }, 0)
    setTotalSum(total)
  }, [tableData])

  useEffect(() => {
    if (tableData?.length === 0) {
      setTableHasData(false)
      setIsFieldsLocked(false)
    }
    if (tableData?.length !== 0) {
      setTableHasData(true)
    }
  }, [tableData])

  useEffect(() => {
    if (addCheckIsSuccess) {
      setTotalSum('0')
      setTableData([])
      setIsFieldsLocked(false)
      setCheckToDelete(null)
      setRequestDealID(null)
      setRequestCarID(null)
      setModalDefaultValues({})
      setSelectedSearchAccount({})
      setTableHasData(false)
      setSelectedVendor(null)
    }
  }, [addCheckIsSuccess])

  const isLoading = useMemo(
    () =>
      vendorListIsLoading || chartOfAccountApIsLoading || listsCarsExpenseIsLoading || accountingControlDataIsLoading || chartOfAccountBillIsLoading,
    [vendorListIsLoading, chartOfAccountApIsLoading, listsCarsExpenseIsLoading, accountingControlDataIsLoading, chartOfAccountBillIsLoading],
  )

  const handleSubmit = () => {}

  return (
    <Container>
      <CustomBreadcrumbs
        heading='Create a new check'
        links={[
          {
            name: 'List',
            href: appRoute.AccountingChecks,
          },
          {
            name: 'New',
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {/* <MultilineEditForm /> */}
      <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(accountingChecksAddValidation(openChecksModal))}>
        {({ handleSubmit, invalid }: any) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <InvoiceNewEditAddress isFieldsLocked={isFieldsLocked} setSelectedVendor={setSelectedVendor} vendorData={vendorListData as any} />

              {/* <InvoiceNewEditStatusDate
                isFieldsLocked={isFieldsLocked}
                chartOfAccountApData={chartOfAccountApData as UspAccountingChartApAccountsView[]}
              /> */}

              <InvoiceNewEditDetails
                setIsEdit={setIsEdit}
                handleOpenAddChecksModal={handleOpenAddChecksModal}
                handleDeleteCheck={handleDeleteCheck}
                tableData={tableData}
                chartOfAccountBillData={chartOfAccountBillData as UspAccountingChartOfAccountCurrentView[]}
              />
              <Stack justifyContent={'flex-end'} p={3} gap={2} direction={'row'}>
                <Button className={classes.closeBtn} variant='contained' color='error' onClick={() => navigate(appRoute?.AccountingChecks)}>
                  Cancel
                </Button>
                <LoadingButton
                  variant='contained'
                  onClick={() => setCurrentStage('paymentDetails')}
                  disabled={tableData?.length <= 0}
                  endIcon={<ArrowForwardIosIcon />}
                >
                  Next
                </LoadingButton>
              </Stack>
            </Card>

            <ChecksAddEditModal
              modalDefaultValues={modalDefaultValues}
              setModalDefaultValues={setModalDefaultValues}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              selectedSearchAccount={selectedSearchAccount as TSelectedSearchAccount}
              setSelectedSearchAccount={setSelectedSearchAccount}
              addCheckIsSuccess={addCheckIsSuccess}
              setEditingItemIndex={setEditingItemIndex}
              errors={invalid}
              onSave={handleModalSave}
              requestDealID={requestDealID}
              requestCarID={requestCarID}
              lockFields={() => setIsFieldsLocked(true)}
              open={openChecksModal}
              handleClose={handleCloseAddChecksModal}
              listsCarsExpenseData={listsCarsExpenseData as UspListCarExpenseTypeResult[]}
              setRequestCarID={setRequestCarID}
              setRequestDealID={setRequestDealID}
              getListsCarsExpense={getListsCarsExpense}
              getAccountingControlData={getAccountingControlData}
              accountingControlData={accountingControlData as UspAccountingReturnControlDataResult}
              chartOfAccountBillData={chartOfAccountBillData as UspAccountingChartOfAccountCurrentView[]}
            />
          </form>
        )}
      </Form>
    </Container>
    //   {({ invalid }: any) => (

    //       </ReactRouterPrompt>
    //       <Grid item xs={12} md={8}>
    //         <ChecksTable

    //         />
    //       </Grid>
    //       <Grid item xs={12} md={4}>
    //         <SettingsForm
    //           setSelectedVendor={setSelectedVendor}
    //           isFieldsLocked={isFieldsLocked}
    //           handleOpenAddChecksModal={handleOpenAddChecksModal}
    //           vendorData={vendorListData as VendorResponseSp[]}
    //           chartOfAccountApData={chartOfAccountApData as UspAccountingChartApAccountsView[]}
    //         />
    //       </Grid>

    //       <Grid item xs={10} justifyContent='space-between'>
    //         <Button
    //           color='success'
    //           className={classes.closeBtn}
    //           variant='contained'
    //           onClick={() => setCurrentStage('paymentDetails')}
    //           disabled={tableData?.length <= 0}
    //           endIcon={<ArrowForwardIosIcon />}
    //         >
    //           Next
    //         </Button>
    //         <Button className={classes.closeBtn} variant='contained' color='error' onClick={() => navigate(appRoute?.AccountingChecks)}>
    //           Cancel
    //         </Button>
    //         <Paper sx={{ padding: '10px' }}>
    //           <Typography variant='h3'>Total: {formatNumber(totalSum)}</Typography>
    //         </Paper>
    //       </Grid>
    //       <ChecksAddEditModal
    //         modalDefaultValues={modalDefaultValues}
    //         setModalDefaultValues={setModalDefaultValues}
    //         isEdit={isEdit}
    //         setIsEdit={setIsEdit}
    //         selectedSearchAccount={selectedSearchAccount as TSelectedSearchAccount}
    //         setSelectedSearchAccount={setSelectedSearchAccount}
    //         addCheckIsSuccess={addCheckIsSuccess}
    //         setEditingItemIndex={setEditingItemIndex}
    //         errors={invalid}
    //         onSave={handleModalSave}
    //         requestDealID={requestDealID}
    //         requestCarID={requestCarID}
    //         lockFields={() => setIsFieldsLocked(true)}
    //         open={openChecksModal}
    //         handleClose={handleCloseAddChecksModal}
    //         listsCarsExpenseData={listsCarsExpenseData as UspListCarExpenseTypeResult[]}
    //         setRequestCarID={setRequestCarID}
    //         setRequestDealID={setRequestDealID}
    //         getListsCarsExpense={getListsCarsExpense}
    //         getAccountingControlData={getAccountingControlData}
    //         accountingControlData={accountingControlData as UspAccountingReturnControlDataResult}
    //         chartOfAccountBillData={chartOfAccountBillData as UspAccountingChartOfAccountCurrentView[]}
    //       />
    //       <CustomCircular open={isLoading} />
    //     </Grid>
    //   )}
    // </Form>
  )
}

export default withPermissionsDropdown(AccountingChecksSettings)
