import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Collapse from '@mui/material/Collapse'
import MenuItem from '@mui/material/MenuItem'
import TableRow from '@mui/material/TableRow'
import Checkbox from '@mui/material/Checkbox'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'
import { usePopover } from '../../../../components/custom-popover'
import { useBoolean } from '../../../../hooks/use-boolean'
import { fDate } from '../../../../utils/format-time'
import Label from '../../../../components/label'
import { formatNumber } from '../../../../utils/general'
import { convertDateTimeToDisplay, convertDateToDisplay, formatDateTime } from '../../../../utils/dateUtils'
import Iconify from '../../../../components/iconify'
import CustomPopover from '../../../../components/custom-popover/custom-popover'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import { Table, TableBody, Tooltip } from '@mui/material'
import { TableEmptyRows, TableHeadCustom, TableNoData, emptyRows, useTable } from '../../../../components/table'
import React, { ReactElement, JSXElementConstructor, ReactNode, ReactPortal, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../../redux/slices/authSlice'
import { useAccountsPaymentVoidMutation } from '../../../../services'
import { useParams } from 'react-router-dom'

// ----------------------------------------------------------------------

type Props = {
  row: any
  selected?: boolean
  data?: any
  getPaymentsDetails?: any
  onViewRow?: VoidFunction
  onSelectRow?: VoidFunction
  onDeleteRow?: VoidFunction
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>
  getAccountsOverview: any
  getAccountDueAmount: any
}
type actionType = 'Chargeback/NSF' | 'Void/Reverse'

export default function OrderTableRow({
  row,
  data,
  getPaymentsDetails,
  selected,
  onViewRow,
  onSelectRow,
  onDeleteRow,
  setRefresh,
  getAccountDueAmount,
  getAccountsOverview,
}: Props) {
  const {
    PaymentType,
    PaymentDate,
    Cashier,
    PaymentForm,
    PaymentMethod,
    TransactionAmount,
    ProcessingFee,
    PaymentStatus,
    DistributionDataInJson,
    SystemDate,
    TransactionNumber,
    PaymentID,
  } = row
  const [paymentVoid, { isSuccess: paymentVoidIsSuccess, isLoading: paymentVoidIsLoading }] = useAccountsPaymentVoidMutation()
  const [actionType, setActionType] = useState<actionType>()
  const [openPopoverId, setOpenPopoverId] = useState<null | number>(null)
  const { dealID } = useParams()
  const table = useTable()
  const userAccess = useSelector(getUserAccessRights)
  const confirm = useBoolean()
  const [confirmDialog, setConfirmDialog] = useState<undefined | actionType>(undefined)

  const collapse = useBoolean()

  const popover = usePopover()

  const handleAccountPaymentAction = (menuOption: actionType) => {
    setActionType(menuOption)
    setConfirmDialog(menuOption)
  }

  const handleMenuOptionAction = () => {
    paymentVoid({
      paymentId: openPopoverId?.toString() as string,
      uspPaymentVoid: {
        dealID: dealID as unknown as number,
        branchID: data.BranchID,
        isReversal: actionType === 'Void/Reverse',
        isChargeback: actionType === 'Chargeback/NSF',
        chargebackFee: null,
        chargebackBankCoaID: null,
        isRestrictCheckACH: true,
      },
    })
    // .then(() => setRefresh((prevValue: boolean) => !prevValue))
    setConfirmDialog(undefined)
    if (getPaymentsDetails)
      getPaymentsDetails({
        dealId: dealID as string,
        uspPaymentView: {
          isIncludeReversals: false,
        },
      }).then(() => {
        getAccountsOverview({ dealId: dealID as string })
        setRefresh((prevValue: boolean) => !prevValue)
      })
  }

  console.log(data)

  const renderPrimary = (
    <TableRow hover>
      <TableCell>
        <Box>{PaymentType}</Box>
      </TableCell>

      <TableCell sx={{ alignItems: 'center' }}>
        <ListItemText
          primary={convertDateToDisplay(PaymentDate)}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
          }}
        />
      </TableCell>
      <TableCell>
        <ListItemText
          primary={Cashier}
          primaryTypographyProps={{ typography: 'body2', noWrap: true }}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
          }}
        />
      </TableCell>
      <TableCell sx={{ alignItems: 'center' }}>
        <ListItemText
          primary={PaymentForm}
          secondary={PaymentMethod}
          primaryTypographyProps={{ typography: 'body2' }}
          secondaryTypographyProps={{
            component: 'span',
            color: 'text.disabled',
          }}
        />
      </TableCell>

      <TableCell> {formatNumber(TransactionAmount)} </TableCell>

      <TableCell> {formatNumber(ProcessingFee)} </TableCell>

      <TableCell> {convertDateTimeToDisplay(SystemDate)} </TableCell>
      <TableCell> {TransactionNumber} </TableCell>

      <TableCell>
        <Label variant='soft' color={PaymentStatus == 'Active' ? 'success' : 'warning'}>
          {PaymentStatus}
        </Label>
      </TableCell>

      <TableCell align='right' sx={{ px: 1, whiteSpace: 'nowrap' }}>
        {row.Note && (
          <Tooltip title={row.Note}>
            <IconButton>
              <Iconify icon='material-symbols:info-outline' />
            </IconButton>
          </Tooltip>
        )}
        {DistributionDataInJson.length > 0 && (
          <IconButton
            color={collapse.value ? 'inherit' : 'default'}
            onClick={collapse.onToggle}
            sx={{
              ...(collapse.value && {
                bgcolor: 'action.hover',
              }),
            }}
          >
            <Iconify icon='eva:arrow-ios-downward-fill' />
          </IconButton>
        )}

        {((row.PaymentStatus === 'Active' && userAccess.CanVoidPayment) ||
          (row.PaymentStatus === 'Active' && row.IsAllowChargeback && userAccess.CanChargebackPayment)) && (
          <IconButton
            color={popover.open ? 'inherit' : 'default'}
            onClick={(e) => {
              setOpenPopoverId(PaymentID)
              popover.onOpen(e)
            }}
          >
            <Iconify icon='eva:more-vertical-fill' />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  )

  const renderSecondary = () => {
    console.log(DistributionDataInJson[0])
    const TABLE_HEAD = [
      { id: 'DistributionType', label: 'Distribution' },
      { id: 'DueDate', label: 'Due Date' },
      { id: 'LateDays', label: 'Late Days' },
      { id: 'Amount', label: 'Amount' },
      { id: 'Principal', label: 'Principal' },
      { id: 'Interest', label: data.DealType == 'Lease' ? 'Rent Charge' : 'Interest' },
      Number(DistributionDataInJson[0]?.LateChargeCollected) !== 0 ? { id: 'LateFee', label: 'Late Fee' } : null,
      Number(DistributionDataInJson[0]?.ChargebackCollected) !== 0 ? { id: 'Chargeback', label: 'Chargeback' } : null,
      Number(DistributionDataInJson[0]?.SalesTax) !== 0 ? { id: 'SalesTax', label: 'Sales Tax' } : null,
    ]
    console.log(TABLE_HEAD)
    return (
      <TableRow>
        <TableCell sx={{ p: 0, border: 'none' }} colSpan={10}>
          <Collapse in={collapse.value} timeout='auto' unmountOnExit sx={{ bgcolor: 'background.neutral' }}>
            <Stack component={Paper} sx={{ m: 1.5 }}>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD.filter((el) => el !== null)}
                  rowCount={DistributionDataInJson.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                />
                <TableBody>
                  {DistributionDataInJson.slice(table.page * table.rowsPerPage, table.page * table.rowsPerPage + table.rowsPerPage).map(
                    (row: {
                      DistributionType:
                        | string
                        | number
                        | boolean
                        | ReactElement<any, string | JSXElementConstructor<any>>
                        | Iterable<ReactNode>
                        | ReactPortal
                        | null
                        | undefined
                      DueDate: unknown
                      DaysLate:
                        | string
                        | number
                        | boolean
                        | ReactElement<any, string | JSXElementConstructor<any>>
                        | Iterable<ReactNode>
                        | ReactPortal
                        | null
                        | undefined
                      IsShowLateFee: any
                      AppliedAmount: string | number | undefined
                      Principal: string | number | undefined
                      InterestCollected: string | number | undefined
                      LateChargeCollected: string | number | undefined
                      ChargebackCollected: string | number | undefined
                      SalesTax: string | number | undefined
                    }) => (
                      <TableRow>
                        <TableCell> {row.DistributionType} </TableCell>
                        <TableCell> {convertDateToDisplay(row.DueDate as unknown as string)} </TableCell>
                        <TableCell> {row.DaysLate} </TableCell>
                        <TableCell> {formatNumber(row.AppliedAmount)} </TableCell>
                        <TableCell> {formatNumber(row.Principal)} </TableCell>
                        <TableCell> {formatNumber(row.InterestCollected)} </TableCell>
                        {Number(row.LateChargeCollected) !== 0 && <TableCell> {formatNumber(row.LateChargeCollected)} </TableCell>}
                        {Number(row.ChargebackCollected) !== 0 && <TableCell> {formatNumber(row.ChargebackCollected)} </TableCell>}
                        {Number(row.SalesTax) !== 0 && <TableCell> {formatNumber(row.SalesTax)} </TableCell>}
                      </TableRow>
                    ),
                  )}

                  <TableEmptyRows emptyRows={emptyRows(table.page, table.rowsPerPage, DistributionDataInJson.length)} />
                </TableBody>
              </Table>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {renderPrimary}

      {renderSecondary()}

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='right-top' sx={{ width: 140 }}>
        {row.PaymentStatus === 'Active' && userAccess.CanVoidPayment && (
          <MenuItem
            onClick={() => {
              handleAccountPaymentAction('Void/Reverse')
              popover.onClose()
            }}
            sx={{ color: 'error.main' }}
          >
            Void/Reverse
          </MenuItem>
        )}
        {row.PaymentStatus === 'Active' && row.IsAllowChargeback && userAccess.CanChargebackPayment && (
          <MenuItem
            onClick={() => {
              handleAccountPaymentAction('Chargeback/NSF')
              popover.onClose()
            }}
          >
            Chargeback/NSF
          </MenuItem>
        )}
      </CustomPopover>
      <ConfirmDialog
        open={!!confirmDialog}
        onOk={handleMenuOptionAction}
        handleClose={() => setConfirmDialog(undefined)}
        notification={`Are you sure you want to ${actionType} this payment? This action is irreversible and will affect 
        the account balance and payment history. Please confirm to proceed.`}
      />
      <ConfirmDialog
        open={confirm.value}
        notificationTitle='Delete'
        notification='Are you sure want to delete?'
        onOk={() => {}}
        handleClose={confirm.onFalse}
        // action={
        //   <Button
        //     variant='contained'
        //     color='error'
        //     //    onClick={onDeleteRow}
        //   >
        //     Delete
        //   </Button>
        // }
      />
    </>
  )
}
