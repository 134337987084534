import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { PercentField, TextField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap', 'titleWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(5, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
}

const DealsForm = ({ onDropdownFormChange }: Props) => {
  return (
    <>
      <CardHeader title='Deals' />
      <Grid container item spacing={2} p={3}>
        <Grid item md={6} xs={12}>
          <TextField name='DaysUntilDealDead' label='Days Until Deal Considered Dead' fullWidth required />
        </Grid>

        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='AccrueCommissions'
            label='Accrue Commissions when Deal Finalized?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='CreateAccount'
            label='Create Account # when Deal Saved?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='RequireAllowance'
            label='Require Allowance Per Trade-in Vehicle?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='ReduceNetProfit'
            label='Reduce Net Profit by Trade Lien?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='ReduceGrossProfit'
            label='Reduce Gross Profit by Floorplan Interest?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='RebatePC'
            label='Rebate PC Interest in Months?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='EarnPC'
            label='Earn PC Interest using IB Method?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='CapPC'
            label='Cap PC Interest at Payment Collected?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PercentField variant='standard' name='SatisfyPTPRate' label='Satisfy PTP Rate (%)' optionNone fullWidth required />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='ApllySideNotePayment'
            label='Apply Side Note Payment Towards PTP?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DropdownFieldSearch
            name='ApplyPickup'
            label='Apply Pickup Payment Towards PTP?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            optionKey='key'
            optionValue='value'
            optionNone
            fullWidth
            onChange={() => {
              onDropdownFormChange()
            }}
            required
          />
        </Grid>
      </Grid>
    </>
  )
}

export default DealsForm
