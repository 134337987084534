import * as yup from 'yup'

export const addInventoryLabels = {
  stockNumber: 'Stock Number',
  vin: 'VIN',
  modelYear: 'Year',
  make: 'Make',
  purchasePrice: 'Purchase Price',
  vendor: 'Vendor',
  purchaseWith: 'Purchase With',
  checkNumber: 'Check Number',
  branch: 'Branch',
}

export const addInventoryValidation: yup.SchemaOf<any> = yup
  .object({
    vin: yup.string().required().nullable().label(addInventoryLabels.vin),
    modelYear: yup.string().required().nullable().label(addInventoryLabels.modelYear),
    make: yup.string().required().nullable().label(addInventoryLabels.make),
    purchasePrice: yup
      .string()
      .min(0)
      .required()
      .nullable()
      .label(addInventoryLabels.purchasePrice)
      .matches(/^[\d|\.|\,]+/, 'Purchase Price cannot be less than 0.'),
    vendor: yup.string().when('purchasePrice', {
      is: (val:any) => Number(val?.replaceAll(',','')?.replace('.','')) >0,
      then: yup.string().required().typeError('Vendor is required'),
      otherwise: yup.string().nullable()

  }),
    purchaseWith: yup.string().when('purchasePrice', {
      is: (val:any) => Number(val) >0,
      then: yup.string().required().typeError('This field is required'),
      otherwise: yup.string()
  })
    ,
    checkNumber: yup.string().when('purchaseWith', {
      is: 'check',
      then: yup.string().required().nullable().label(addInventoryLabels.checkNumber),
    }),
    branch: yup.string().required().nullable().label(addInventoryLabels.branch),
    odometer: yup.string().required('Odometer Type'),
  })
  .required()
