import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {}

export const NoteForm = ({}: Props) => {
  return (
    <>
      <CardHeader title='Note' />
      <Grid container spacing={2} p={3}>
        <Grid item xs={6}>
          <TextField name='floorplanNote' label='Note' fullWidth multiline autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default NoteForm
