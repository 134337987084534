import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import { UspDealProductViewResult } from '../../../../../models'
import EditIcon from '@mui/icons-material/Edit'
import { CircularProgress } from '@mui/material'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import DealsProductsEdit from '../DealsProductsEdit/DealsProductsEdit'
import { useLazyGetDealProductQuery } from '../../../../../services'
import { useParams } from 'react-router-dom'

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'loading',
  'spinner',
  'tableHead',
  'tableCellName',
  'telegramIconActive',
  'telegramIconInactive',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.telegramIconActive}`]: {
    color: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

interface Props {}

const DealsProductsTableBlock = ({}: Props) => {
  const { dealID } = useParams()
  const [getDealProduct, { data, isLoading }] = useLazyGetDealProductQuery()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const initialOrder = (storageGet('deals_products_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('deals_products_by') || 'ProductLabel') as keyof UspDealProductViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspDealProductViewResult>(initialOrderBy)
  const [rowData, setRowData] = React.useState({})

  useEffect(() => {
    getDealProduct({ dealId: dealID as string })
  }, [])

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspDealProductViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('deals_products_order', isAsc ? 'desc' : 'asc')
    storageSet('deals_products_by', property)
  }

  const createSortHandler = (property: keyof UspDealProductViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleEdit = (event: React.MouseEvent<HTMLButtonElement>, rowData: UspDealProductViewResult) => {
    setRowData(rowData)
    setAnchorEl(event?.currentTarget)
  }

  const onEditSuccess = (isSuccess: boolean) => {
    if (isSuccess) {
      getDealProduct({ dealId: dealID as string })
      setAnchorEl(null)
    }
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 118px)' }} className={classes.root}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'ProductLabel' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'ProductLabel'}
                    direction={orderBy === 'ProductLabel' ? order : 'asc'}
                    onClick={createSortHandler('ProductLabel')}
                  >
                    Products
                    {orderBy === 'ProductLabel' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'VendorCode' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'VendorCode'}
                    direction={orderBy === 'VendorCode' ? order : 'asc'}
                    onClick={createSortHandler('VendorCode')}
                  >
                    Vendor
                    {orderBy === 'VendorCode' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'ProductPrice' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'ProductPrice'}
                    direction={orderBy === 'ProductPrice' ? order : 'asc'}
                    onClick={createSortHandler('ProductPrice')}
                  >
                    Selling Price
                    {orderBy === 'ProductPrice' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'ProductCost' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'ProductCost'}
                    direction={orderBy === 'ProductCost' ? order : 'asc'}
                    onClick={createSortHandler('ProductCost')}
                  >
                    Cost
                    {orderBy === 'ProductCost' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'DealProductID' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'DealProductID'}
                    direction={orderBy === 'DealProductID' ? order : 'asc'}
                    onClick={createSortHandler('DealProductID')}
                  >
                    Term
                    {orderBy === 'DealProductID' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'ExpirationDate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'ExpirationDate'}
                    direction={orderBy === 'ExpirationDate' ? order : 'asc'}
                    onClick={createSortHandler('ExpirationDate')}
                  >
                    Expires
                    {orderBy === 'ExpirationDate' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} align={'right'}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), data as any[])?.map((item: any) => (
                <>
                  <TableRow key={item?.DealProductID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>{item?.ProductLabel}</TableCell>
                    <TableCell>{item?.VendorCode}</TableCell>
                    <TableCell>{item?.ProductPrice}</TableCell>
                    <TableCell>{item?.ProductCost}</TableCell>
                    <TableCell>{item?.ProductTerm}</TableCell>
                    <TableCell>{item?.Expires}</TableCell>
                    <TableCell align={'right'}>
                      <Tooltip title='Edit'>
                        <IconButton color='primary' onClick={(e) => handleEdit(e, item)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </>
              ))}
              {data?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
      <DealsProductsEdit
        rowData={rowData}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        onEditSuccess={(isSuccess) => {
          onEditSuccess(isSuccess)
        }}
      />
    </>
  )
}

export default DealsProductsTableBlock
