import { Box, Button, Card, generateUtilityClasses, Grid, Paper } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Header from '../../../Header/Header'
import TradeVehicleForm from './components/TradeVehicleForm/TradeVehicleForm'
import SpecsForm from './components/SpecsForm/SpecsForm'
import PricingForm from './components/PricingForm/PricingForm'
import ValuesForm from './components/ValuesForm/ValuesForm'
import LienHolderForm from './components/LienholderForm/LienHolderForm'
import LienForm from './components/LienForm/LienForm'
import { useForm, useFormState } from 'react-final-form'

import { useLazyCarsVinReadQuery, useLazyGetTaskDealQuery } from '../../../../../../../services'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../../../constants'
import { debounce } from 'lodash'
import { withPermissionsDropdown } from '../../../../../../../hoc'

const classes = generateUtilityClasses('div', ['root', 'pageBody', 'formItem', 'formItemHalf', 'formItemValues', 'formItemLien', 'buttonWrap'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.formItemHalf}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    width: '200px',
  },
  [`& .${classes.formItemValues}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    height: '287px',
  },
  [`& .${classes.formItemLien}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    height: '287px',
  },

  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
}))

interface Props {
  onSubmit: any
}

const DealTradeStructure = ({ onSubmit }: Props) => {
  const { vin, tradeID, dealID } = useParams()
  const navigate = useNavigate()
  const { values } = useFormState()
  const [getTaskDeal, { data: taskDeal }] = useLazyGetTaskDealQuery()
  const [carsVin, { data: carsVinData, isSuccess: carsVinIsSuccess }] = useLazyCarsVinReadQuery()
  const { change, batch } = useForm()
  const [currentVIN, setCurrentVIN] = useState('')

  useEffect(() => {
    if (carsVinIsSuccess) {
      batch(() => {
        change('Make', carsVinData?.Make)
        change('Model', carsVinData?.Model)
        change('Year', carsVinData?.ModelYear)
        change('Trim', carsVinData?.ModelTrim)
        change('Body', carsVinData?.BodyTypeID)
        change('Engine', carsVinData?.Engine)
        change('Drivetrain', carsVinData?.DriveTrainID)
        change('FuelType', carsVinData?.FuelTypeID)
        change('Cylinders', carsVinData?.Cylinders)
      })
    }
    if (vin) {
      change('VIN', vin)
    }
  }, [carsVinIsSuccess])

  useEffect(() => {
    if (values?.VIN) {
      setCurrentVIN(values?.VIN)
      if (!tradeID) {
        navigate(appRoute?.DealTradeAddVin.replace(':dealID', dealID as string).replace(':vin', values?.VIN))
      }
    }
    // return () => setCurrentVIN('')
  }, [values?.VIN, currentVIN, carsVin])

  useEffect(() => {
    if (currentVIN.length > 16) {
      carsVin({ vin: currentVIN })
    }
  }, [currentVIN, carsVin])

  useEffect(() => {
    getTaskDeal({ dealId: dealID as string })
  }, [])

  const handleClick = () => {
    onSubmit() // Calling the handleSubmit function passed as props
  }
  return (
    // <StyledForm className={classes.root}>
    //   <Header taskDeal={taskDeal} />
    //   <Grid
    //     container
    //     spacing={{ xs: 2, md: 3 }}
    //     columns={{ xs: 2, sm: 4, md: 6, lg: 10, xl: 10 }}
    //     // columns={{ xs: 12, sm: 12, md: 12, lg: 5, xl: 12 }}
    //     rowSpacing={1}
    //     columnSpacing={1}
    //     className={classes.pageBody}
    //   >
    //     <Grid item xs={2}>
    //       <Paper className={classes.formItem}>
    //       </Paper>
    //     </Grid>
    //     <Grid item>
    //       <Grid item xs={2}>
    //         <Paper className={classes.formItemHalf}>
    //         </Paper>
    //       </Grid>

    //       <br />

    //       <Grid item xs={2}>
    //         <Paper className={classes.formItemHalf}>
    //         </Paper>
    //       </Grid>
    //     </Grid>
    //     <Grid item xs={2}>
    //       <Paper className={classes.formItemValues}>
    //       </Paper>
    //     </Grid>
    //     {parseFloat(values?.LienPayoff) > 0 && (
    //       <>
    //         <Grid item xs={2}>
    //           <Paper className={classes.formItem}>
    //             <LienHolderForm formValidation={values} />
    //           </Paper>
    //         </Grid>
    //         <Grid item xs={2}>
    //           <Paper className={classes.formItemLien}>
    //             <LienForm />
    //           </Paper>
    //         </Grid>
    //       </>
    //     )}
    //   </Grid>
    //   <Grid item xs={10} className={classes.buttonWrap} justifyContent='space-between'>
    //     <Grid sx={{ display: 'flex', gap: '15px', flexDirection: 'row-reverse' }}>
    //       <Button variant='contained' color='primary' type='button' onClick={handleClick}>
    //         Save
    //       </Button>
    //       <Button variant='contained' color='error' onClick={() => navigate(appRoute.DealsTrade.replace(':dealID', dealID as string))}>
    //         Cancel
    //       </Button>
    //     </Grid>
    //   </Grid>
    // </StyledForm>
    <Card sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TradeVehicleForm />
        </Grid>
        <Grid item xs={12}>
          <SpecsForm />
        </Grid>
        <Grid item xs={12}>
          <PricingForm />
        </Grid>
        <Grid item xs={12}>
          <ValuesForm />
        </Grid>
        {parseFloat(values?.LienPayoff) > 0 && (
          <>
            <Grid item xs={12}>
              <LienHolderForm formValidation={values} />
            </Grid>
            <Grid item xs={12}>
              <LienForm />
            </Grid>
          </>
        )}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          position: 'sticky',
          bottom: 16,
          right: 20,
          p: 3,
          width: 'fit-content',
        }}
      >
        <Button
          variant='contained'
          color='error'
          onClick={() =>
            navigate(
              generatePath(appRoute.DealsTask, {
                dealID,
              }),
            )
          }
        >
          Close
        </Button>
        <Button variant='contained' type='button' onClick={handleClick}>
          Save & Close
        </Button>
      </Box>
    </Card>
  )
}

export default withPermissionsDropdown(DealTradeStructure)
