import { useAddDealsFlagMutation, usePaymentsDetailsMutation, useWorklistNextAccountMutation } from '../../../services'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getWorklistAccountID, getWorklistID } from '../../../redux/slices/collectionsSlice'
import { AccountsOverviewResult, UspDealFlagViewResult, UspPaymentViewDetailResult } from '../../../models'
import { withAccounts } from '../../../hoc/withAccounts'
import { Form } from 'react-final-form'
import Header from '../Header/Header'
import Grid from '@mui/material/Grid'
import { generateUtilityClasses, Paper, Tooltip, Typography } from '@mui/material'
import { CheckboxField, CustomCircular } from '../../../ui'
import Button from '@mui/material/Button'
import { styled } from '@mui/system'
import { ReactComponent as PrintIcon } from '../../../assets/icons/printer.svg'
import { ReactComponent as CollapseAllIcon } from '../../../assets/icons/collapse-all-icon.svg'
import { ReactComponent as ExpandAllIcon } from '../../../assets/icons/expand-all-icon.svg'
import PayHistoryRecord from './components/PayHistoryRecord/PayHistoryRecord'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Grid', ['root', 'subHeaderWrap', 'printBtn', 'titleWrap', 'btnWrap', 'recordPaper'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.subHeaderWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing(3, 3, 3, 3),
  },
  // [`& .${classes.printBtn}`]: {
  //   backgroundColor: theme.palette.background.purple,
  // },
  [`& .${classes.titleWrap}`]: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
  },

  [`& .${classes.btnWrap}`]: {
    maxWidth: '30px',
    maxHeight: '42px',
    minWidth: '30px',
    minHeight: '42px',
    padding: '3px',
    marginLeft: '10px',
    color: theme.palette.text.primary,
  },
  [`& .${classes.recordPaper}`]: {
    overflow: 'auto',
    maxHeight: 'calc(100vh - 220px)',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0, 3, 1, 3),
  },
}))

interface Props {
  accountsData: AccountsOverviewResult
  accountsIsLoading: boolean
  accountsIsSuccess: boolean
}

const AccountsPayHistory = ({ accountsData }: Props) => {
  const { dealID } = useParams()
  const dispatch = useDispatch()
  const worklistAccountID = useSelector(getWorklistAccountID)
  const worklistID = useSelector(getWorklistID)
  const [includeVoidedPayments, setIncludeVoidedPayments] = useState(false)
  const [expandHistoryRecords, setExpandHistoryRecords] = useState(true)
  const [getPaymentsDetails, { data: paymentDetailsData, isLoading: paymentDetailsIsLoading, isSuccess: paymentDetailsIsSuccess }] =
    usePaymentsDetailsMutation()
  const [nextAccount, { data: nextAccountData, isSuccess: nextAccountIsSuccess }] = useWorklistNextAccountMutation()
  const userAccess = useSelector(getUserAccessRights)
  const [isAddEditFlagModalOpen, setIsAddEditFlagModalOpen] = useState(false)
  const [addAccountFlags, { isSuccess: addAccountFlagIsSuccess, isLoading: addAccountFlagsIsLoading }] = useAddDealsFlagMutation()
  const [selectedFlag, setSelectedFlag] = useState<UspDealFlagViewResult | undefined>()

  const disableEditIcons = userAccess?.CanEditAccount

  useEffect(() => {
    getPaymentsDetails({
      dealId: dealID as string,
      uspPaymentView: {
        isIncludeReversals: includeVoidedPayments,
      },
    })
  }, [includeVoidedPayments])

  useEffect(() => {
    if (addAccountFlagIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      setIsAddEditFlagModalOpen(false)
    }
  }, [addAccountFlagIsSuccess])

  const handleNextAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: true,
        },
      })
    }
  }

  const handlePreviousAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: false,
        },
      })
    }
  }

  const sortedPaymentDetailsData = useMemo(() => {
    return (paymentDetailsData || [])?.slice()?.sort((a, b) => b.PaymentDetailID - a.PaymentDetailID)
  }, [paymentDetailsData])

  const handleSubmit = () => {}

  const isLoading = useMemo(() => paymentDetailsIsLoading, [paymentDetailsIsLoading])

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {({}: any) => (
          <>
            <StyledGrid className={classes.root}>
              <Header
                disableEditIcons={disableEditIcons}
                model={accountsData?.CarDescription}
                vin={accountsData?.VIN}
                stockNumber={accountsData?.StockNumber}
                buyerName={accountsData?.BuyerName}
                coBuyerName={accountsData?.CoBuyerName}
                getAccountsPayHistory={getPaymentsDetails}
                branchID={accountsData?.BranchID}
                isAccountActive={accountsData?.IsAccountActive}
                isDeficiency={accountsData?.IsDeficiency}
                worklistAccountID={worklistAccountID}
                worklistID={worklistID}
                nextAccountData={nextAccountData}
                nextAccountIsSuccess={nextAccountIsSuccess}
                handleNextAccount={handleNextAccount}
                handlePreviousAccount={handlePreviousAccount}
                contactID1={accountsData?.ContactID1}
                contactID2={accountsData?.ContactID2}
                buyerAddress={accountsData?.BuyerAddressData}
                coBuyerAddress={accountsData?.CoBuyerAddressData}
                buyerPhone={accountsData?.BuyerPhoneData}
                coBuyerPhone={accountsData?.CoBuyerPhoneData}
                coBuyerWorkPhone={accountsData?.CoBuyerWorkPhone}
                isAddEditFlagModalOpen={isAddEditFlagModalOpen}
                setIsAddEditFlagModalOpen={setIsAddEditFlagModalOpen}
                addAccountFlags={addAccountFlags}
                selectedFlag={selectedFlag}
                setSelectedFlag={setSelectedFlag}
              />
              <Grid container className={classes.subHeaderWrap}>
                <Grid item className={classes.titleWrap}>
                  <Typography variant='h3'>Pay History</Typography>
                  <CheckboxField
                    name='includeVoidedPayments'
                    label='Include Voided Payments'
                    onChange={(e) => setIncludeVoidedPayments(e?.target?.checked)}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title={expandHistoryRecords ? 'Collapse All' : 'Expand All'}>
                    <Button color='success' variant='text' className={classes.btnWrap} onClick={() => setExpandHistoryRecords(!expandHistoryRecords)}>
                      {expandHistoryRecords ? <CollapseAllIcon /> : <ExpandAllIcon />}
                    </Button>
                  </Tooltip>
                  <Tooltip title='Print'>
                    <Button color='success' variant='text' className={classes.btnWrap}>
                      <PrintIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid>
                {sortedPaymentDetailsData?.length >= 1 ? (
                  <Paper className={classes.recordPaper}>
                    {sortedPaymentDetailsData?.map((item) => (
                      <PayHistoryRecord
                        includeVoidedPayments={includeVoidedPayments}
                        getPaymentsDetails={getPaymentsDetails}
                        dealID={accountsData?.DealID}
                        branchID={accountsData?.BranchID}
                        recordData={item as UspPaymentViewDetailResult}
                        expandHistoryRecords={expandHistoryRecords}
                      />
                    ))}
                  </Paper>
                ) : (
                  <Paper className={classes.recordPaper}>
                    <Typography textAlign='center'>No Results</Typography>
                  </Paper>
                )}
              </Grid>
            </StyledGrid>
            <CustomCircular open={isLoading} />
          </>
        )}
      </Form>
    </>
  )
}

export default withAccounts(AccountsPayHistory)
