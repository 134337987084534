import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { storageSet } from '../../utils/storage'

export const slice = createSlice({
  name: 'financialsIncomeStatement',
  initialState: {
    financialsIncomeStatement: [],
  },
  reducers: {
    setFinancialsIncomeStatement: (state, { payload }) => {
      state.financialsIncomeStatement = payload
      storageSet('financialsIncomeStatement', JSON.stringify(payload))
    },
  },
})

export const getFinancialsStatement = (state: RootState) => {
  return state?.financialsIncomeStatement
}
export const { setFinancialsIncomeStatement } = slice.actions
export default slice.reducer
