import { appApi, HeaderKey, StorageKey } from '../constants'
import { CURRENT_USER } from '../states/app'
import { fetchCompanyTypesByClient, IApiClientItem } from './client'

export interface ISignInReq {
  email: string
  password: string
}

export interface ISignInRes {
  access: string
  refresh: string
  clients_list: IApiClientItem[]
}

export const authSignUp = (v: ISignInReq) => {
  return appApi.post('/login/', v)
}

export const authSignIn = async (v: ISignInReq) => {
  try {
    const {
      result: { access, refresh, clients_list },
    } = await appApi.post<ISignInRes>('/login/', v)
    localStorage.setItem(StorageKey.access, access)
    localStorage.setItem(StorageKey.refresh, refresh)
    localStorage.setItem(StorageKey.email, v.email)
    appApi.setHeader(HeaderKey.Authorization, `Bearer ${access}`)

    CURRENT_USER.set({ email: v.email, access, refresh })

    if (clients_list?.length === 1) {
      const client = clients_list[0]
      const companies = await fetchCompanyTypesByClient(client.client_uid)
      if (companies?.length === 1) {
        const company = companies[0]
        await authEnterSession({
          companyType: company.CompanyType,
          branchID: company.BranchID,
          clientID: client.client_uid,
        })
      }
    }
  } catch (e) {}
}

export const authSignOut = async () => {
  await appApi.post('/logout/')
  appApi.removeHeader(HeaderKey.Authorization)
  appApi.removeHeader(HeaderKey.ClientID)
  appApi.removeHeader(HeaderKey.CompanyType)
  appApi.removeHeader(HeaderKey.CompanyBranch)
  localStorage.removeItem(StorageKey.refresh)
  localStorage.removeItem(StorageKey.access)
  localStorage.removeItem(StorageKey.email)
  localStorage.removeItem(StorageKey.clientID)
  localStorage.removeItem(StorageKey.companyType)
  localStorage.removeItem(StorageKey.branchID)
  CURRENT_USER.set(null)
}

interface IForgotPasswordReq {
  email: string
}

export const authForgotPassword = (data: IForgotPasswordReq) => {
  return appApi.post('/forgot_password/', data)
}

export const authForgotPasswordResend = (data: IForgotPasswordReq) => {
  return appApi.post('/forgot_password/resend/', data)
}

interface IChangePasswordReq {
  code: string
  email: string
  password: string
}

export const authChangePassword = (data: IChangePasswordReq) => {
  return appApi.post('/forgot_password/change_password/', data)
}

interface IRefreshReq {
  refresh: string
}

interface IRefreshRes {
  access: string
  refresh: string
}

export const authRefresh = async (data: IRefreshReq) => {
  const {
    result: { access, refresh },
  } = await appApi.post<IRefreshRes>('/login/refresh/', data)
  appApi.setHeader(HeaderKey.Authorization, `Bearer ${access}`)
  localStorage.setItem(StorageKey.access, access)
  localStorage.setItem(StorageKey.refresh, refresh)
  return { access, refresh }
}

interface IEnterSessionReq {
  clientID: string
  companyType: string
  branchID: string
}

export const authEnterSession = async ({ clientID, companyType, branchID }: IEnterSessionReq) => {
  appApi.setHeader(HeaderKey.ClientID, clientID)
  appApi.setHeader(HeaderKey.CompanyType, companyType)
  appApi.setHeader(HeaderKey.CompanyBranch, branchID)
  try {
    const { response } = await appApi.post<IRefreshRes>('/clients/user_logged_in/')
    localStorage.setItem(StorageKey.clientID, clientID)
    localStorage.setItem(StorageKey.companyType, companyType)
    localStorage.setItem(StorageKey.branchID, branchID)
    CURRENT_USER.merge({ clientID, companyType, branchID })
  } catch (e) {
    appApi.removeHeader(HeaderKey.ClientID)
    appApi.removeHeader(HeaderKey.CompanyType)
    appApi.removeHeader(HeaderKey.CompanyBranch)
  }
}
