import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { Card, generateUtilityClasses } from '@mui/material'
import { Form, useForm } from 'react-final-form'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { TextField } from '../../../../../ui'
import { useLocation, useParams } from 'react-router-dom'
import AmountField from '../../../../../ui/AmountField'
import Button from '@mui/material/Button'
import { selectDropdownByNameActiveOnly, useAddCarCostMutation } from '../../../../../services'
import { CarsDropdownResult, GetCarApiResponse } from '../../../../../models'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import removeMask from '../../../../../utils/removeMask'
import { useDispatch, useSelector } from 'react-redux'
import validateForm from '../../../../../utils/validateForm'
import { addExpensesValidation } from '../../../../../validation'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { TExpenseRequest } from '../../InventoryEditExpenses'

interface Props {
  open: boolean
  onClose: () => void
  carData?: GetCarApiResponse
  getCarExpense: (e: any) => void
  expenseRequestData: TExpenseRequest
  getCar: (e: any) => void
  formChanged: boolean
  setFormChanged: Dispatch<SetStateAction<boolean>>
  onDropdownFormChange: () => void
}

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(6, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

export const AddFormModal = ({
  open,
  onClose,
  carData,
  getCarExpense,
  expenseRequestData,
  setFormChanged,
  getCar,
  formChanged,
  onDropdownFormChange,
}: Props) => {
  const [addCarCost, { isSuccess: carCostIsSuccess }] = useAddCarCostMutation()
  const { carID } = useParams()
  const { submit } = useForm()
  const dispatch = useDispatch()
  const location = useLocation()
  const vendor = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Vendor'))
  const carExpenseType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarExpenseType'))

  const handleSubmit = (values: any) => {
    setFormChanged(false)
    addCarCost({
      carId: carID as string,
      uspCarCostAdd: {
        vendorID: values.vendorID,
        costAmount: removeMask(values.costAmount) as string,
        sourceNumber: values.sourceNumber,
        carExpenseType: values.carExpenseType,
        description: values.description || null,
        isCheck: values?.payWith === 'check',
      },
    })
  }

  useEffect(() => {
    if (carCostIsSuccess) {
      dispatch(enqueueNotification({ message: 'Expense added' }))
      submit()
      // if (location.pathname.includes(`/inventory/edit/${carID}/expenses`)) {
      getCarExpense({
        carId: carID as string,
        uspCarExpenseView: {
          isIncludeAllHistoryByCar: expenseRequestData?.isIncludeAllHistoryByCar,
          isIncludeReversals: expenseRequestData?.isIncludeReversals,
          expenseType: null,
        },
      })
      // }

      if (location.pathname.includes(`/inventory/edit/${carID}/general`)) {
        getCar({ carId: carID as string })
      }
      onClose()
    }
  }, [carCostIsSuccess])

  const CarExpenseTypeMemorized = useMemo(
    () =>
      carExpenseType?.filter((item) => {
        if (carData?.IsAvailable) {
          if (item?.IsAvailableForUnsoldInventory) {
            return item?.IsDisplayToEndUser
          }
        } else {
          if (item?.IsAvailableForSoldInventory) {
            return item?.IsDisplayToEndUser
          }
        }
      }),
    [carExpenseType, carData?.IsAvailable],
  )

  return (
    <Modal open={open} onClose={onClose}>
      <StyledBox className={classes.root}>
        <Grid>
          <Typography variant='h3'>Add Expense</Typography>
        </Grid>
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            payWith: 'bill',
          }}
          validate={validateForm(addExpensesValidation)} // TODO FIX VALIDATION FUNCTIONALITY
        >
          {({ handleSubmit, values }: any) => (
            <form onSubmit={handleSubmit} onChange={() => setFormChanged && setFormChanged(true)}>
              {vendor && (
                <Grid className={classes.row}>
                  <DropdownFieldSearch
                    name='vendorID'
                    label='Vendor'
                    options={vendor}
                    optionKey='ID'
                    optionValue='Name'
                    onChange={onDropdownFormChange}
                  />
                </Grid>
              )}
              <Grid className={classes.row}>
                <DropdownFieldSearch
                  name='carExpenseType'
                  label='Expense Type'
                  options={CarExpenseTypeMemorized as any[]}
                  optionKey='ExpenseType'
                  optionValue='ExpenseType'
                  onChange={onDropdownFormChange}
                />
              </Grid>
              <Grid className={classes.row}>
                <AmountField variant='standard' name='costAmount' label='Amount' fullWidth />
              </Grid>
              <Grid className={classes.row}>
                <DropdownFieldSearch
                  name='payWith'
                  label='Pay With'
                  options={[
                    { key: 'bill', value: 'Bill' },
                    { key: 'check', value: 'Check' },
                  ]}
                  optionKey='key'
                  optionValue='value'
                  onChange={onDropdownFormChange}
                />
              </Grid>
              <Grid className={classes.row}>
                <TextField name='sourceNumber' label={values?.payWith === 'check' ? 'Check Number' : 'Bill Number'} fullWidth />
              </Grid>
              <Grid className={classes.row}>
                <TextField
                  name='description'
                  label={values?.carExpenseType === 'Purchase' ? 'Description (optional)' : 'Description'}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid className={classes.buttonWrap} container>
                <Grid item>
                  <Button disabled={!formChanged} type='submit' className={classes.saveBtn} variant='contained'>
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant='contained' color='error' onClick={onClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </StyledBox>
    </Modal>
  )
}

export default AddFormModal
