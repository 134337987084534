import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../ui/DropdownFieldSearch'
import { AmountField, CustomCircular, TextField } from '../../../../ui'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  useGetAccountingChartOfAccountCurrentMutation,
  useEditPaymentFormMutation,
  useAddPaymentFormMutation,
  useLazyPaymentFormDataQuery,
  useLazyGetActiveCompanyTypeQuery,
} from '../../../../services'
import { UspAccountingChartOfAccountCurrentView, UspActiveCompanyTypes, UspConfigurationPaymentFormViewResult } from '../../../../models/apiTypes'
import removeMask from '../../../../utils/removeMask'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspConfigurationPaymentFormViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditPaymentFormModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const [addPaymentForm, { isLoading: isLoadingPaymentForm, isSuccess: isSuccessPaymentForm }] = useAddPaymentFormMutation()
  const [editPaymentForm, { isLoading: isLoadingEditPaymentForm, isSuccess: isSuccessEditPaymentForm }] = useEditPaymentFormMutation()
  const [getPaymentFormData, { isLoading: isLoadingGetPaymentForm, isSuccess: isSuccessGetPaymentForm, data }] = useLazyPaymentFormDataQuery()
  const [getActiveCompanyTypes, { data: activeCompanyTypes, isLoading: isLoadingActiveCompanyTypes }] = useLazyGetActiveCompanyTypeQuery()

  const [searchChartOfAccounts, { data: chartData, isLoading: isLoadingSearchChartOfAccounts }] = useGetAccountingChartOfAccountCurrentMutation()

  // Filter chartData for Sales and Finance
  const chartDataSales = chartData?.filter((item: UspActiveCompanyTypes) => item?.CompanyType === 'Sales') || []
  const chartDataFinance = chartData?.filter((item: UspActiveCompanyTypes) => item?.CompanyType === 'Finance') || []

  // Check if CompanyTypes include 'Sales' and 'Finance'
  const hasFinanceType = activeCompanyTypes?.some((item: UspActiveCompanyTypes) => item.CompanyType === 'Finance')
  const hasSalesType = activeCompanyTypes?.some((item: UspActiveCompanyTypes) => item.CompanyType === 'Sales')

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editPaymentForm({
        paymentFormId: rowData?.ID?.toString(),
        uspConfigurationPaymentFormAddEdit: {
          paymentForm: values.Description,
          processingFee: removeMask(values.ProcessingFee) as string,
          isCollected: values.IndicatesFunds,
          isCreateIntercompanyEntry: values.IntercompanyEntry,
          isAllowChargeback: values.Chargeback,
          salesChartOfAccountID: values.ChartOfAccountSales,
          financeChartOfAccountID: values.ChartOfAccountFinance,
          isIRS8300: values.IncludeIRS,
          isActive: values.IsActive,
        },
      })
    } else {
      addPaymentForm({
        uspConfigurationPaymentFormAddEdit: {
          paymentForm: values.Description,
          processingFee: removeMask(values.ProcessingFee) as string,
          isCollected: values.IndicatesFunds,
          isCreateIntercompanyEntry: values.IntercompanyEntry,
          isAllowChargeback: values.Chargeback,
          salesChartOfAccountID: values.ChartOfAccountSales,
          financeChartOfAccountID: values.ChartOfAccountFinance,
          isIRS8300: values.IncludeIRS,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open) {
      getActiveCompanyTypes({})
      searchChartOfAccounts({
        uspChartOfAccountView: {
          currentID: null,
          isAllowedSelectControlledAccount: false,
          isIncludeAccountsThatCannotBeSelected: false,
          isReturnAllCompanyTypes: true,
        },
      })
    }
  }, [open])

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getPaymentFormData({
        paymentFormId: rowData?.ID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        Description: '',
        IsActive: true,
        ChartOfAccountSales: '',
        ChartOfAccountFinance: '',
        ProcessingFee: '',
        IndicatesFunds: true,
        IndicatedsWriteOff: false,
        IntercompanyEntry: true,
        Chargeback: false,
        IncludeIRS: true,
      })
    }
    if (isSuccessGetPaymentForm && type === 'edit') {
      setInitialValues({
        Description: data?.PaymentForm,
        IsActive: data?.IsActive,
        ProcessingFee: data?.ProcessingFee,
        ChartOfAccountSales: data?.SalesChartOfAccountID,
        ChartOfAccountFinance: data?.FinanceChartOfAccountID,
        IndicatesFunds: data?.IsCollected,
        IndicatedsWriteOff: data?.IsWriteOff,
        IntercompanyEntry: data?.IsCreateIntercompanyEntry,
        Chargeback: data?.IsAllowChargeback,
        IncludeIRS: data?.IsIRS8300,
      })
    }
  }, [isSuccessGetPaymentForm, data, type])

  useEffect(() => {
    if (isSuccessPaymentForm || isSuccessEditPaymentForm) {
      onClose()
      submit()
    }
  }, [isSuccessPaymentForm, isSuccessEditPaymentForm])

  const isLoading = useMemo(
    () =>
      isLoadingPaymentForm || isLoadingEditPaymentForm || isLoadingGetPaymentForm || isLoadingSearchChartOfAccounts || isLoadingActiveCompanyTypes,
    [isLoadingPaymentForm, isLoadingEditPaymentForm, isLoadingGetPaymentForm, isLoadingSearchChartOfAccounts, isLoadingActiveCompanyTypes],
  )

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Payment Form</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Description' label='Description' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <AmountField variant='standard' required name='ProcessingFee' label='Processing Fee' fullWidth />
                </Grid>
                {hasSalesType && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='ChartOfAccountSales'
                      label='Chart of Account - Sales (debit)'
                      options={chartDataSales as UspAccountingChartOfAccountCurrentView[]}
                      optionKey='ChartOfAccountID'
                      optionValue='AccountNumberName'
                    />
                  </Grid>
                )}

                {hasFinanceType && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      name='ChartOfAccountFinance'
                      label='Chart of Account - Finance (debit)'
                      options={chartDataFinance as UspAccountingChartOfAccountCurrentView[]}
                      optionKey='ChartOfAccountID'
                      optionValue='AccountNumberName'
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IndicatesFunds'
                    label='Indicates Funds are Collected?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IndicatedsWriteOff'
                    label='Indicates a Write Off/Credit Issued?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IntercompanyEntry'
                    label='Create Intercompany Entry to User Branch?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='Chargeback'
                    label='Eligible for Chargebacks?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IncludeIRS'
                    label='Include on IRS Form 8300?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
              <Button variant='contained' color='error' onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default AddEditPaymentFormModal
