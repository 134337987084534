import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { Button, CardHeader, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { CheckboxField } from '../../../../../ui'
import { ReactComponent as SixangleIcon } from '../../../../../assets/icons/sixangle.svg'
import { generateUtilityClasses } from '@mui/material'
import { UspCreditAppAddEdit, UspGetDataCreditApp } from '../../../../../models'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useStatusColorStyles } from '../../../../../hooks/useStatusColorStyles'
import { Form } from 'react-final-form'
import {
  selectDropdownByNameActiveOnly,
  selectDropdownByNameIsSalespersonOnly,
  useEditCreditAppMutation,
  useLazyGetCreditAppQuery,
} from '../../../../../services'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useParams } from 'react-router-dom'
import { getCurrentDate } from '../../../../../utils/general'
import Label, { LabelColor } from '../../../../../components/label'

const classes = generateUtilityClasses('Grid', [
  'root',
  'row',
  'body',
  'title',
  'titleSubtitle',
  'mainIcon',
  'addIcon',
  'userIcon',
  'linkText',
  'statusHighlighted',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.body}`]: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.titleSubtitle}`]: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  [`& .${classes.mainIcon}`]: {
    padding: theme.spacing(0, 2, 0, 0),
    fill: theme.palette.text.primary,
  },
  [`& .${classes.addIcon}`]: {
    width: '45px',
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.userIcon}`]: {
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.linkText}`]: {
    width: '95px',
    fontWeight: 600,
    fontSize: '1em',
    lineHeight: '1.1875em',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.statusHighlighted}`]: {
    height: '22px',
    textAlign: 'center',
    width: '175px',
    color: theme.palette.text.button,
    padding: theme.spacing(0.2, 1),
    fontWeight: '600',
    fontSize: '0.85em',
    margin: theme.spacing(1, 0, 1, 3),
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

interface Props {
  creditAppData?: UspGetDataCreditApp
  closeModal: () => void
}

export const SettingsForm = ({ creditAppData, closeModal }: Props) => {
  const userAccess = useSelector(getUserAccessRights)
  const StatusColorDiv = useStatusColorStyles(creditAppData)
  const [saveSettings, { isSuccess }] = useEditCreditAppMutation()
  const dispatch = useDispatch()
  const [formChanged, setFormChanged] = useState(false)
  const { leadID } = useParams()
  const [getCreditAppData] = useLazyGetCreditAppQuery()
  const salesPerson = useSelector((state) => selectDropdownByNameIsSalespersonOnly(creditAppData?.SalespersonUserID)(state, 'UserAll'))
  const advertisement = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Advertisement'))
  const creditAppStatus = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CreditAppStatus'))
  const creditAppInterestLevel = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CreditAppInterestLevel'))
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  const disableFields = !userAccess?.CanEditCreditApp

  const initialValues = useMemo(
    () => ({
      branchID: creditAppData?.BranchID,
      salespersonUserID: creditAppData?.SalespersonUserID,
      advertisementID: creditAppData?.AdvertisementID,
      creditAppStatusID: creditAppData?.CreditAppStatusID,
      creditAppInterestLevelID: creditAppData?.CreditAppInterestLevelID,
      isPaperApplicationSigned: creditAppData?.IsPaperApplicationSigned,
      isPrivacyDisclosureProvided: creditAppData?.IsPrivacyDisclosureProvided,
      actionDate: getCurrentDate(),
    }),
    [creditAppData],
  )

  const handleSubmit = (settings: Record<string, any>) => {
    saveSettings({
      creditAppId: leadID as string,
      uspCreditAppAddEdit: settings as UspCreditAppAddEdit,
    })
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Settings updated' }))
      getCreditAppData({ creditAppId: leadID as string })
      closeModal()
    }
  }, [isSuccess])

  return (
    <>
      <DialogTitle>Settings</DialogTitle>
      <Label color={creditAppData?.SettingsStatusColor as LabelColor} sx={{ mt: 3, mr: 5, position: 'absolute', top: 10, right: 0 }}>
        {creditAppData?.SettingsStatus}
      </Label>
      <DialogContent>
        <Form onSubmit={(settings) => handleSubmit(settings)} initialValues={initialValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} paddingY={1}>
                {branch && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      required
                      disabled={disableFields}
                      name='branchID'
                      label='Branch'
                      options={branch}
                      optionKey='BranchID'
                      optionValue='Branch'
                      onChange={() => {
                        setFormChanged(true)
                      }}
                    />
                  </Grid>
                )}
                {salesPerson && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='salespersonUserID'
                      label='Salesperson'
                      options={salesPerson}
                      optionKey='UserID'
                      optionValue='Name'
                      onChange={() => {
                        setFormChanged(true)
                      }}
                    />
                  </Grid>
                )}
                {advertisement && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='advertisementID'
                      label='Lead Source'
                      options={advertisement}
                      optionKey='ID'
                      optionValue='Advertisement'
                      onChange={() => {
                        setFormChanged(true)
                      }}
                    />
                  </Grid>
                )}

                {creditAppStatus && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='creditAppStatusID'
                      label='Status (Optional)'
                      options={creditAppStatus}
                      optionKey='ID'
                      optionValue='Status'
                      onChange={() => {
                        setFormChanged(true)
                      }}
                    />
                  </Grid>
                )}

                {creditAppInterestLevel && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='creditAppInterestLevelID'
                      label='Interest Level (Optional)'
                      options={creditAppInterestLevel}
                      optionKey='ID'
                      optionValue='InterestLevel'
                      onChange={() => {
                        setFormChanged(true)
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <CheckboxField
                    onChange={() => {
                      setFormChanged(true)
                    }}
                    disabled={disableFields}
                    name='isPaperApplicationSigned'
                    label='Paper Credit Application Signed?'
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxField
                    onChange={() => {
                      setFormChanged(true)
                    }}
                    disabled={disableFields}
                    name='isPrivacyDisclosureProvided'
                    label='Privacy Notice Provided?'
                  />
                </Grid>
              </Grid>
              <DialogActions>
                <Button variant='contained' color='error' onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button variant='contained' type='submit' disabled={!formChanged}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Form>
      </DialogContent>
    </>
  )
}

export default SettingsForm
