import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { visuallyHidden } from '@mui/utils'
import { AmountField, CheckboxField } from '../../../../ui'
import { getComparator, stableSort } from '../../../../utils/sorting'
import { storageGet, storageSet } from '../../../../utils/storage'
import { formatNumber, parseToNumber } from '../../../../utils/general'
import { useForm, useFormState } from 'react-final-form'
import removeMask from '../../../../utils/removeMask'
import { convertDateToDisplay } from '../../../../utils/dateUtils'
import { AccountingUnpaidBillsData } from '../../../../models'

const classes = generateUtilityClasses('Grid', ['root', 'tableHead', 'tableCellName', 'buttonWrap', 'saveBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    height: 'calc(100vh - 200px)',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 0, 0, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  loading: boolean
  unpaidBillsData: AccountingUnpaidBillsData[]
  unpaidBillsIsSuccess: boolean
  totalSum: number
  setTotalSum: Dispatch<SetStateAction<number>>
  setCurrentStage: Dispatch<SetStateAction<any>>
  setSummaryData: Dispatch<SetStateAction<AccountingUnpaidBillsData[]>>
  summaryData: AccountingUnpaidBillsData[]
  errors: any
}

const BillPayTableBlock = ({ loading, unpaidBillsData, totalSum, setTotalSum, setCurrentStage, setSummaryData, summaryData, errors }: Props) => {
  const initialOrder = (storageGet('bills_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('bills_order_by') || 'BillNumber') as keyof AccountingUnpaidBillsData
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof AccountingUnpaidBillsData>(initialOrderBy)
  const { values } = useFormState()
  const form = useForm()

  useEffect(() => {
    unpaidBillsData?.forEach((item: any, index: number) => {
      if (values[`AmountToPayCheck${index}`]) {
        form.change(`Amount${index}`, item?.BillRemaining)
      } else {
        form.change(`Amount${index}`, '0')
      }
    })
  }, [])

  // Update total sum whenever Amount fields are updated
  useEffect(() => {
    const total = unpaidBillsData?.reduce((sum: number, item: any) => {
      if (values[`AmountToPayCheck${item?.BillID}`]) {
        return sum + parseToNumber(values[`Amount${item?.BillID}`] || '0')
      }
      return sum
    }, 0)
    setTotalSum(total || 0)
  }, [values])

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountingUnpaidBillsData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('bills_order', isAsc ? 'desc' : 'asc')
    storageSet('bills_order_by', property)
  }

  const createSortHandler = (property: keyof AccountingUnpaidBillsData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const validateAmountToPay = (amountToPay: any, remaining: any) => {
    const cleanedAmountToPay = amountToPay

    const parsedAmountToPay = cleanedAmountToPay ? parseToNumber(cleanedAmountToPay) : 0
    const parsedRemaining = typeof remaining === 'string' ? parseToNumber(remaining) : remaining

    if (parsedRemaining > 0 && (parsedAmountToPay < 0 || parsedAmountToPay > parsedRemaining)) {
      return `Amount to pay must be between 0 and ${parsedRemaining}.`
    }
    if (parsedRemaining < 0 && (parsedAmountToPay > 0 || parsedAmountToPay < parsedRemaining)) {
      return `Amount to pay must be between ${parsedRemaining} and 0.`
    }

    return undefined
  }

  useEffect(() => {
    // Initialize new summary data
    let newSummaryData: any = [...summaryData]

    // Loop through each unpaid bill data
    unpaidBillsData?.forEach((item: any, index: number) => {
      // If the checkbox for this bill is checked
      if (values[`AmountToPayCheck${item?.BillID}`]) {
        // Get the amount value
        const amountValue = parseFloat(removeMask(values[`Amount${item?.BillID}`]) || '0')

        // Find the bill in the summary data using BillID and index
        const existingBillIndex = newSummaryData.findIndex((bill: any) => bill.BillNumber === item?.BillNumber && bill.index === index)

        if (existingBillIndex !== -1) {
          // If bill is already in the summary data, update the amount value
          newSummaryData[existingBillIndex].Amount = amountValue
        } else {
          // If bill is not in the summary data yet add it
          newSummaryData.push({
            BillID: item?.BillID,
            BillNumber: item?.BillNumber,
            Amount: amountValue,
            index,
          })
        }
      } else {
        // If the checkbox for this bill is not checked remove it from the summary data
        newSummaryData = newSummaryData?.filter((bill: any) => !(bill.BillNumber === item?.BillNumber && bill.index === index))
      }
    })

    // Update the summary data state
    setSummaryData(newSummaryData)
  }, [values])

  return (
    <>
      {unpaidBillsData?.length !== 0 && (
        <>
          <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 400px)' }}>
            <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead} align={'right'}></TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillTotal' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'BillTotal'}
                      direction={orderBy === 'BillTotal' ? order : 'asc'}
                      onClick={createSortHandler('BillTotal')}
                    >
                      Amount To Pay
                      {orderBy === 'BillTotal' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillNumber' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'BillNumber'}
                      direction={orderBy === 'BillNumber' ? order : 'asc'}
                      onClick={createSortHandler('BillNumber')}
                    >
                      Bill Number
                      {orderBy === 'BillNumber' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillDate' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'BillDate'}
                      direction={orderBy === 'BillDate' ? order : 'asc'}
                      onClick={createSortHandler('BillDate')}
                    >
                      Date
                      {orderBy === 'BillDate' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'DueDate' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'DueDate'}
                      direction={orderBy === 'DueDate' ? order : 'asc'}
                      onClick={createSortHandler('DueDate')}
                    >
                      Due
                      {orderBy === 'DueDate' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillTotal' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'BillTotal'}
                      direction={orderBy === 'BillTotal' ? order : 'asc'}
                      onClick={createSortHandler('BillTotal')}
                    >
                      Total
                      {orderBy === 'BillTotal' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>

                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillRemaining' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'BillRemaining'}
                      direction={orderBy === 'BillRemaining' ? order : 'asc'}
                      onClick={createSortHandler('BillRemaining')}
                    >
                      Remaining
                      {orderBy === 'BillRemaining' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsApprovedToPay' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'IsApprovedToPay'}
                      direction={orderBy === 'IsApprovedToPay' ? order : 'asc'}
                      onClick={createSortHandler('IsApprovedToPay')}
                    >
                      Approved
                      {orderBy === 'IsApprovedToPay' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'BranchCode' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'BranchCode'}
                      direction={orderBy === 'BranchCode' ? order : 'asc'}
                      onClick={createSortHandler('BranchCode')}
                    >
                      Branch
                      {orderBy === 'BranchCode' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillDescription' ? order : false}>
                    <TableSortLabel
                      active={orderBy === 'BillDescription'}
                      direction={orderBy === 'BillDescription' ? order : 'asc'}
                      onClick={createSortHandler('BillDescription')}
                    >
                      Description
                      {orderBy === 'BillDescription' ? (
                        <Box component='span' sx={visuallyHidden}>
                          {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(getComparator(order, orderBy), unpaidBillsData as any[])?.map((item: any, index: number) => (
                  <TableRow hover key={item.BillID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell className={classes.tableCellName}>
                      <CheckboxField
                        onChange={(event) => {
                          const value = event.target.checked ? item?.BillRemaining : '0'
                          const numericValue = parseFloat(value || '0')
                          if (event.target.checked) {
                            form.change(`Amount${item?.BillID}`, value)
                            setTotalSum((total: any) => total + numericValue)
                          } else {
                            form.change(`Amount${item?.BillID}`, '0')
                            setTotalSum((total: any) => total - numericValue)
                          }
                        }}
                        name={'AmountToPayCheck' + item?.BillID}
                      />
                    </TableCell>
                    <TableCell>
                      <AmountField
                        variant='standard'
                        validate={(value: any) => validateAmountToPay(value, parseFloat(item?.BillRemaining as string))}
                        defaultValue={values?.AmountToPayCheck + index ? removeMask(item?.BillRemaining) : '0'}
                        name={`Amount${item?.BillID}`}
                        label='Amount To Pay'
                        fullWidth
                        sx={{ width: 150 }}
                        disabled={!values[`AmountToPayCheck${item?.BillID}`]}
                        onChange={(event: any) => {
                          const newValue = event.target.value
                          if (newValue === '0') {
                            form.change(`AmountToPayCheck${item?.BillID}`, false)
                          }
                          form.change(`Amount${item?.BillID}`, newValue)
                        }}
                      />
                    </TableCell>
                    <TableCell>{item?.BillNumber}</TableCell>
                    <TableCell>{convertDateToDisplay(item?.BillDate)}</TableCell>
                    <TableCell>{convertDateToDisplay(item?.DueDate)}</TableCell>
                    <TableCell>{formatNumber(item?.BillTotal)}</TableCell>
                    <TableCell>{formatNumber(item?.BillRemaining)}</TableCell>
                    <TableCell>{item?.IsApprovedToPay ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{item?.BranchCode}</TableCell>
                    <TableCell>{item?.BillDescription}</TableCell>
                  </TableRow>
                ))}
                {unpaidBillsData?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={10} align='center'>
                      No results
                    </TableCell>
                  </TableRow>
                )}
                {loading && (
                  <TableRow>
                    <TableCell colSpan={10} align='center'>
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {/* <Grid container spacing={2} justifyContent={'flex-end'} alignItems={'center'} p={3}>
        <Grid item>
          <Typography variant='h6'>Total: {formatNumber(totalSum)}</Typography>
        </Grid>
        <Grid item>
          <Button
            type='button'
            variant='contained'
            color='success'
            disabled={totalSum <= 0 || Object.keys(errors).length > 0}
            onClick={() => {
              setCurrentStage('bill-pay-summary')
            }}
          >
            Next
          </Button>
        </Grid>
      </Grid> */}
    </>
  )
}

export default BillPayTableBlock
