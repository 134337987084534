import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Header } from '../components/Header'
import { Card, generateUtilityClasses } from '@mui/material'
import { useCarNoteGetMutation } from '../../../services'
import { withCars } from '../../../hoc'
import { Form } from 'react-final-form'
import { appRoute } from '../../../constants'
import { NoteHistoryHeader, NoteCommentsHeader, InventoryTableNotes } from './components'
import Button from '@mui/material/Button'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../components/ConfirmDialog'

type TFormFields = {
  isIncludeAllHistoryByCar: boolean
  isIncludeCancelledNotes: boolean
}

export type TNoteRequest = {
  isIncludeAllHistoryByCar: boolean
  isIncludeCancelledNotes: boolean
}

const classes = generateUtilityClasses('Grid', ['root', 'bodyWrap', 'loading', 'buttonWrap', 'saveBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.bodyWrap}`]: {
    backgroundColor: theme.palette.background.secondary,
    padding: theme.spacing(0, 3, 2, 3),
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 3, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

export const InventoryEditNotes = (props: any) => {
  const formRef = React.useRef<HTMLFormElement>(null)
  const navigate = useNavigate()
  const { carID } = useParams()
  const { getCarNote, carNoteData, carNoteIsLoading } = props
  const [formChanged, setFormChanged] = useState(false)

  const handleSubmit = (values: TFormFields) => {
    setFormChanged(false)
    getCarNote({
      carId: carID as string,
      uspCarNoteView: {
        isIncludeAllHistoryByCar: values.isIncludeAllHistoryByCar,
        isIncludeCancelledNotes: values.isIncludeCancelledNotes,
      },
    })
  }
  const handleFormChange = (newState: boolean) => {
    setFormChanged(newState)
  }

  return (
    <Card>
      <ReactRouterPrompt when={formChanged} beforeConfirm={async () => {}}>
        {({ isActive, onConfirm, onCancel }) => (
          <ConfirmDialog open={!!isActive} onOk={onConfirm} handleClose={onCancel} notification={'Warning: Your changes haven’t been saved.'} />
        )}
      </ReactRouterPrompt>
      <Grid className={classes.bodyWrap}>
        {/* <NoteCommentsHeader formRef={formRef} handleFormChange={handleFormChange} /> */}
        <Form onSubmit={handleSubmit} initialValues={{ isIncludeAllHistoryByCar: true, isIncludeCancelledNotes: true }}>
          {({ handleSubmit, form: { submit } }: any) => (
            <form onSubmit={handleSubmit} onChange={() => setFormChanged(true)}>
              <NoteHistoryHeader submit={submit} />
              <InventoryTableNotes carNoteData={carNoteData} carNoteIsLoading={carNoteIsLoading} />
            </form>
          )}
        </Form>
      </Grid>
    </Card>
  )
}

export default withCars(InventoryEditNotes)
