import React from 'react'
import {
  Box,
  Grid,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material'
import { CircularProgress } from '@mui/material'
import { UspCarSearchResult } from '../../../../../models'
import EditIcon from '@mui/icons-material/Edit'
import { styled } from '@mui/system'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'

interface Props {
  searchCarsData?: UspCarSearchResult[]
  loading: boolean
  lookingAtRequest?: (request: any) => void
}

const classes = generateUtilityClasses('Grid', [
  'root',
  'activeItem',
  'inactiveItem',
  'loading',
  'spinner',
  'tableHead',
  'tableCellName',
  'telegramIconActive',
  'telegramIconInactive',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(0.5),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },
  [`& .${classes.inactiveItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.error.light,
    borderRadius: '4px',
  },
  [`& .${classes.tableHead}`]: {
    minWidth: '90px',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.telegramIconActive}`]: {
    color: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.text.disabled,
    },
  },
}))

export const InventoryTableBlock = ({ searchCarsData, loading, lookingAtRequest }: Props) => {
  const { leadID } = useParams()
  const navigate = useNavigate()
  const initialOrder = (storageGet('inventory_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('inventory_order_by') || 'StockNumber') as keyof UspCarSearchResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspCarSearchResult>(initialOrderBy)

  const handleEdit = (id: number | string) => {
    // if the user is redirected from Leads-Task->Interested In
    if (id && leadID && lookingAtRequest) {
      lookingAtRequest({
        creditAppId: leadID?.toString() as string,
        carId: id?.toString() as string,
      })
    } else {
      navigate(appRoute.InventoryEdit.replace(':carID', String(id)))
    }
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCarSearchResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('inventory_order', isAsc ? 'desc' : 'asc')
    storageSet('inventory_order_by', property)
  }

  const createSortHandler = (property: keyof UspCarSearchResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <StyledGrid className={classes.root}>
      <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: '500px' }}>
        <Table sx={{ minWidth: 400 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Vehicle' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Vehicle'}
                  direction={orderBy === 'Vehicle' ? order : 'asc'}
                  onClick={createSortHandler('Vehicle')}
                >
                  Description
                  {orderBy === 'Vehicle' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'VIN' ? order : false}>
                <TableSortLabel active={orderBy === 'VIN'} direction={orderBy === 'VIN' ? order : 'asc'} onClick={createSortHandler('VIN')}>
                  VIN
                  {orderBy === 'VIN' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Color' ? order : false}>
                <TableSortLabel active={orderBy === 'Color'} direction={orderBy === 'Color' ? order : 'asc'} onClick={createSortHandler('Color')}>
                  Color
                  {orderBy === 'Color' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Mileage' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Mileage'}
                  direction={orderBy === 'Mileage' ? order : 'asc'}
                  onClick={createSortHandler('Mileage')}
                >
                  Mileage
                  {orderBy === 'Mileage' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'CarStatus' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'CarStatus'}
                  direction={orderBy === 'CarStatus' ? order : 'asc'}
                  onClick={createSortHandler('CarStatus')}
                >
                  Status
                  {orderBy === 'CarStatus' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockDate'}
                  direction={orderBy === 'StockDate' ? order : 'asc'}
                  onClick={createSortHandler('StockDate')}
                >
                  Stocked
                  {orderBy === 'StockDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsAvailable' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsAvailable'}
                  direction={orderBy === 'IsAvailable' ? order : 'asc'}
                  onClick={createSortHandler('IsAvailable')}
                >
                  Available
                  {orderBy === 'IsAvailable' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), searchCarsData as any[]).map((item) => (
              <TableRow key={item.CarID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell className={classes.tableCellName} onClick={() => handleEdit(item.CarID as string)}>
                  {item.StockNumber}
                </TableCell>
                <TableCell>{item?.Vehicle}</TableCell>
                <TableCell>{item?.VIN}</TableCell>
                <TableCell>{item?.Color}</TableCell>
                <TableCell>{item?.Mileage}</TableCell>
                <TableCell>{item?.CarStatus}</TableCell>
                <TableCell>{convertDateToDisplay(item?.StockDate as string)}</TableCell>
                <TableCell>{item?.BranchCode}</TableCell>
                <TableCell>
                  {item.IsAvailable ? (
                    <Typography className={classes.activeItem}>Yes</Typography>
                  ) : (
                    <Typography className={classes.inactiveItem}>No</Typography>
                  )}
                </TableCell>
                <TableCell align={'right'}>
                  <Tooltip title='Edit'>
                    <IconButton color='primary' onClick={() => handleEdit(item.CarID as string)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
            {searchCarsData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledGrid>
  )
}
