import React, { useEffect } from 'react'
import { appRoute } from '../../../../../constants'
import { InventorySearchBlock } from './components/InventorySearchBlock'
import { InventoryTableBlock } from './components/InventoryTableBlock'
import { Form } from 'react-final-form'
import { UspCarSearchResult, UspGetDataDealEntryResult } from '../../../../../models'
import { useDispatch, useSelector } from 'react-redux'
import { useGetUserPermissionsDropdownMutation, useSearchCarMutation } from '../../../../../services'
import { isUserDropdownEmpty, setUserDropdown } from '../../../../../redux/slices/userDropdownSlice'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Container } from '@mui/system'
import CustomBreadcrumbs from '../../../../../components/custom-breadcrumbs'
import { Card } from '@mui/material'

interface Props {
  dealData: UspGetDataDealEntryResult
  setSelectedCar: (car?: UspCarSearchResult) => void
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
}

export const SelectInventory = ({ setSelectedCar, setCurrentStage, dealData }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isDropdownEmpty = useSelector(isUserDropdownEmpty)
  const [searchCars, { data: searchCarsData, isLoading: searchCarsIsLoading }] = useSearchCarMutation()
  const [userPermissionsDropdown, { data: userPermissionsDropdownData, isSuccess: userPermissionsDropdownIsSuccess }] =
    useGetUserPermissionsDropdownMutation()
  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    searchCars({
      uspCarSearch: {
        searchString: values.searchString,
        jsonBranchList: jsonBranchList,
        isIncludeSoldCars: false,
        isPendingTradeOnly: false,
      },
    })
  }

  // userPermissionsDropdown
  useEffect(() => {
    if (isDropdownEmpty) {
      // check if branch API request required
      userPermissionsDropdown({ userPermissionsDropdown: {} })
    }
  }, [])

  useEffect(() => {
    if (userPermissionsDropdownIsSuccess) {
      dispatch(setUserDropdown(userPermissionsDropdownData))
    }
  }, [userPermissionsDropdownIsSuccess])

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Deals'
        links={[
          {
            name: 'Select Inventory',
          },
        ]}
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ searchString: null }} // require to avoid first debounce search
      >
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit}>
            <Card
              sx={{
                // height: { xs: 800, md: 2 },
                flexGrow: { md: 1 },
                display: { md: 'flex' },
                flexDirection: { md: 'column' },
              }}
            >
              <InventorySearchBlock submit={submit} setCurrentStage={setCurrentStage} />
              <InventoryTableBlock
                loading={searchCarsIsLoading}
                searchCarsData={searchCarsData}
                setSelectedCar={setSelectedCar}
                setCurrentStage={setCurrentStage}
                dealData={dealData}
              />
            </Card>
            <Box display='flex' gap={3} py={3} justifyContent='flex-end' alignItems='center'>
              {/* <Button startIcon={<ArrowBackIosIcon />} onClick={() => navigate(appRoute.DealsList)} variant='outlined'>
                Back
              </Button>
              <Button variant='outlined' onClick={() => navigate(appRoute.DealsList)}>
                Cancel
              </Button> */}
              <Button variant='contained' onClick={() => setCurrentStage('dealStructure')}>
                Skip
              </Button>
            </Box>
          </form>
        )}
      </Form>
    </Container>
  )
}

export default SelectInventory
