import React, { useEffect } from 'react'
import { Box, Button, Card, Grid, Modal, Tab, Tabs, generateUtilityClasses, useTheme } from '@mui/material'
import { styled } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { AmountField, CheckboxField, DateField, TextField } from '../../../../../ui'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useAddNotesMutation, selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'
import { transformToRequestValues } from './transform'
import { useLocation, useParams } from 'react-router-dom'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import MessageIcon from '@mui/icons-material/Message'
import validateFormValues from '../../../../../utils/validateForm'
import { accountsAddNoteValidation } from '../../../../../validation/accountsAddNote'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import DateTimeField from '../../../../../ui/DateTimeField'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    width: '700px',
    boxShadow: 24,
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(0, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    margin: theme.spacing(0, 2, 0, 2),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  getAccountNotesHistory: (e: any) => void
  includeDeletedNotes: boolean
  isAccountActive?: boolean
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const AddNoteModal = ({ open, onClose, getAccountNotesHistory, includeDeletedNotes, isAccountActive }: Props) => {
  const { dealID, leadID } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const theme = useTheme()
  const [tabValue, setTabValue] = React.useState(1)
  const [addNotes, { isSuccess }] = useAddNotesMutation()
  const followUpTypeDropdown = useSelector((state) => selectDropdownByName(state, 'FollowUpType'))
  const assignedToDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'UserAll'))
  const callOutcomeDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CallOutcome'))
  const { UserID } = useSelector(getUserAccessRights)

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }
  const handleSubmit = (values: any) => {
    console.log(values)
    addNotes({
      addNotes: transformToRequestValues(values, dealID, tabValue, leadID),
    })
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      if (location.pathname.includes(`/accounts/${dealID}/note-history`)) {
        getAccountNotesHistory({
          accountNotesHistory: {
            dealID: parseInt(dealID as string),
            isIncludeDeletedNotes: includeDeletedNotes,
            isImportantMessagesOnly: false,
            creditAppID: null,
            contactID: null,
          },
        })
      }

      onClose()
    }
  }, [isSuccess])

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose()
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledBox className={classes.root}>
        <Form
          onSubmit={handleSubmit}
          validate={validateFormValues(accountsAddNoteValidation(tabValue))}
          initialValues={{ CallCallType: 'Outbound', assignedToUserID: UserID }}
        >
          {({ handleSubmit, values, form }: any) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={tabValue}
                    onChange={(e, val) => {
                      form.restart()
                      handleChange(e, val)
                    }}
                    aria-label='basic tabs example'
                  >
                    <Tab value={1} label='Note' icon={<TextSnippetIcon />} iconPosition='start' {...a11yProps(1)} />
                    <Tab value={2} label='Call' icon={<LocalPhoneIcon />} iconPosition='start' {...a11yProps(2)} />
                    <Tab value={3} label='Email' icon={<MailOutlineIcon />} iconPosition='start' {...a11yProps(3)} disabled />
                    <Tab value={4} label='Text' icon={<MessageIcon />} iconPosition='start' {...a11yProps(4)} disabled />
                  </Tabs>
                </Box>
                <TabPanel value={tabValue} index={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sx={{ marginBottom: '30px' }}>
                      <TextField multiline name='Note' label='Note' fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      {isAccountActive && <CheckboxField name='isCreatePTP' label='Create Promise-To-Pay' />}
                    </Grid>

                    <Box display='flex' gap={2}>
                      {values.isCreatePTP && (
                        <>
                          <AmountField variant='standard' name='PTPAmount' label='PTP Amount' />
                          <Box
                            sx={{
                              marginLeft: 2,
                              maxWidth: 300,
                            }}
                          >
                            <DateField name='PTPDueDate' label='PTP Due Date' disablePast />
                          </Box>
                        </>
                      )}
                    </Box>

                    <Grid item xs={12}>
                      <CheckboxField name='isCreateFollowUp' label='Create Follow-Up' />
                    </Grid>

                    {values.isCreateFollowUp && (
                      <Grid item container xs={12} columnSpacing={2}>
                        <Grid item xs={3}>
                          {followUpTypeDropdown?.ReturnData && (
                            <DropdownFieldSearch
                              name='followUpTypeID'
                              label='Follow-up Type'
                              options={followUpTypeDropdown?.ReturnData}
                              optionKey='ID'
                              optionValue='FollowUpType'
                              fullWidth
                            />
                          )}
                        </Grid>
                        {assignedToDropdown && (
                          <Grid item xs={3}>
                            <DropdownFieldSearch
                              name='assignedToUserID'
                              label='Assign To'
                              options={assignedToDropdown}
                              optionKey='UserID'
                              optionValue='Name'
                              fullWidth
                            />
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <DateTimeField name='followUpDateTime' label='Follow-Up Date Time' disablePast />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={values.CallCallType === 'Outbound' ? 6 : 3} sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                      <DropdownFieldSearch
                        name='CallCallType'
                        label='Call Type'
                        options={[
                          { key: 'Outbound', value: 'Outbound' },
                          { key: 'Inbound', value: 'Inbound' },
                        ]}
                        optionKey='key'
                        optionValue='value'
                        fullWidth
                      />
                      {values.CallCallType === 'Outbound' && callOutcomeDropdown && (
                        <DropdownFieldSearch
                          name='CallOutcome'
                          label='Outcome'
                          options={callOutcomeDropdown}
                          optionKey='ID'
                          optionValue='Outcome'
                          fullWidth
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ marginBottom: '30px' }}>
                      <TextField multiline name='CallNote' label='Note' fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                      {isAccountActive && <CheckboxField name='isCreatePTP' label='Create Promise-To-Pay' />}
                    </Grid>

                    <Box display='flex' gap={2}>
                      {values.isCreatePTP && (
                        <>
                          <AmountField variant='standard' name='PTPAmount' label='PTP Amount' />
                          <Box
                            sx={{
                              marginLeft: 2,
                              maxWidth: 300,
                            }}
                          >
                            <DateField name='PTPDueDate' label='PTP Due Date' disablePast />
                          </Box>
                        </>
                      )}
                    </Box>

                    <Grid item xs={12}>
                      <CheckboxField name='isCreateFollowUp' label='Create Follow-Up' />
                    </Grid>

                    {values.isCreateFollowUp && (
                      <Grid item container xs={12} columnSpacing={2}>
                        <Grid item xs={3}>
                          {followUpTypeDropdown?.ReturnData && (
                            <DropdownFieldSearch
                              name='followUpTypeID'
                              label='Follow-up Type'
                              options={followUpTypeDropdown?.ReturnData}
                              optionKey='ID'
                              optionValue='FollowUpType'
                              fullWidth
                            />
                          )}
                        </Grid>
                        {assignedToDropdown && (
                          <Grid item xs={3}>
                            <DropdownFieldSearch
                              name='assignedToUserID'
                              label='Assign To'
                              options={assignedToDropdown}
                              optionKey='UserID'
                              optionValue='Name'
                              fullWidth
                            />
                          </Grid>
                        )}

                        <Grid item xs={6}>
                          <DateTimeField name='followUpDateTime' label='Follow-Up Date Time' disablePast />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
              </Box>
              <Grid className={classes.buttonWrap} container>
                <Grid item>
                  <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                    Save
                  </LoadingButton>
                </Grid>
                <Grid item>
                  <Button variant='contained' color='error' onClick={onClose}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
      </StyledBox>
    </Modal>
  )
}
export default AddNoteModal
