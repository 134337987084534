import React, { useMemo } from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, IconButton } from '@mui/material'
import { ReactComponent as CircleIcon } from '../../../../../../../assets/icons/circle.svg'
import { generateUtilityClasses } from '@mui/material'
import { UspCarSearchResult, UspCreditAppSearchResult, UspGetDataCreditApp, UspGetDataDealEntryResult } from '../../../../../../../models'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { applyPhoneMask } from '../../../../../../../utils/general'
import Iconify from '../../../../../../../components/iconify'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'mainDataWrap', 'secondaryData', 'iconSeparator', 'iconBtn'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '60px',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    paddingRight: theme.spacing(2),
  },
  [`& .${classes.mainDataWrap}`]: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  [`& .${classes.secondaryData}`]: {
    margin: theme.spacing(0, 0, 0, 0),
  },
  [`& .${classes.iconSeparator}`]: {
    margin: theme.spacing(0, 1, 0, 1),
    fill: theme.palette.primary.main,
  },
  [`& .${classes.iconBtn}`]: {
    padding: theme.spacing(0.5),
  },
}))

interface Props {
  dealData: UspGetDataDealEntryResult | undefined
  setCurrentStage: (stage: 'inventory' | 'credit' | 'dealStructure' | 'dealResult') => void
  setSelectedCar: (car?: UspCarSearchResult) => void
  setSelectedCreditApp: (creditApp?: UspCreditAppSearchResult | UspGetDataCreditApp) => void
  carSelected?: any // UspGetDataCar | UspCarSearchResult
  creditSelected?: UspCreditAppSearchResult | UspGetDataCreditApp
}

export const Header = ({ carSelected, creditSelected, setSelectedCar, setSelectedCreditApp, setCurrentStage, dealData }: Props) => {
  const Vehicle = useMemo(() => carSelected?.Vehicle || `${carSelected?.ModelYear} ${carSelected?.Make} ${carSelected?.Model}`, [carSelected])

  return (
    <StyledContainer className={classes.root}>
      <Grid container>
        {carSelected && (
          <>
            <Grid className={classes.titleWrap}>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Grid item>
                  <Typography variant='h3' className={classes.mainDataWrap}>
                    {Vehicle}
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    color='primary'
                    className={classes.iconBtn}
                    onClick={() => {
                      setCurrentStage('inventory')
                    }}
                  >
                    <Iconify icon='solar:pen-bold' />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color='error'
                    className={classes.iconBtn}
                    onClick={() => {
                      setSelectedCar(undefined)
                    }}
                  >
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Grid item>
                  <Typography variant='h6' className={classes.secondaryData}>
                    {`Stock # ${carSelected?.StockNumber}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <CircleIcon className={classes.iconSeparator} />
                </Grid>
                <Grid item>
                  <Typography variant='h6'>{`VIN ${carSelected?.VIN}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
        {creditSelected && (
          <Grid className={classes.titleWrap}>
            <Grid
              container
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Grid item>
                <Typography variant='h3' className={classes.mainDataWrap}>
                  {creditSelected
                    ? (creditSelected as UspCreditAppSearchResult).Applicant || (creditSelected as UspGetDataCreditApp).ApplicantName
                    : ''}
                </Typography>
              </Grid>
              {/*<Grid container>*/}
              {/*  <Grid item>*/}
              {/*    <Typography variant='h6' className={classes.secondaryData}>*/}
              {/*      {`Stock # ${carSelected?.StockNumber}`}*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*  <Grid item>*/}
              {/*    <CircleIcon className={classes.iconSeparator} />*/}
              {/*  </Grid>*/}
              {/*  <Grid item>*/}
              {/*    <Typography variant='h6'>{`VIN ${carSelected?.VIN}`}</Typography>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}
              <Grid item>
                <IconButton
                  color='primary'
                  className={classes.iconBtn}
                  onClick={() => {
                    setCurrentStage('credit')
                  }}
                >
                  <Iconify icon='solar:pen-bold' />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  color='error'
                  className={classes.iconBtn}
                  onClick={() => {
                    setSelectedCreditApp(undefined)
                  }}
                >
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Typography variant='h6' className={classes.secondaryData}>
                  {(creditSelected as UspCreditAppSearchResult)?.PhoneNumber || (creditSelected as UspGetDataCreditApp)?.ApplicantPhone
                    ? applyPhoneMask((creditSelected as UspCreditAppSearchResult)?.PhoneNumber) ||
                      applyPhoneMask((creditSelected as UspGetDataCreditApp)?.ApplicantPhone)
                    : dealData?.PhoneNumber}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </StyledContainer>
  )
}

export default Header
