import { forwardRef } from 'react'

import Link from '@mui/material/Link'
import { useTheme } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

import { RouterLink } from '../../routes/components'

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme()

  const PRIMARY_TEXT = theme.palette.text.primary

  const PRIMARY_MAIN = theme.palette.primary.main

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component='div'
      sx={{
        width: 87,
        height: 27,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg width='100%' height='100%' viewBox='0 0 87 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M3.249 23.8275V18.6874H11.6999V15.8364H3.249V10.9887H12.776V8.08594H0V26.7334H13.1262V23.8275H3.249Z' fill={PRIMARY_TEXT} />
        <path d='M21.9922 23.8009V8.08594H18.7432V26.7334H31.1441V23.8017H21.9922V23.8009Z' fill={PRIMARY_TEXT} />
        <path
          d='M38.9844 23.8275V18.6874H47.4323V15.8364H38.9844V10.9887H48.5085V8.08594H35.7324V26.7334H48.8586V23.8275H38.9844Z'
          fill={PRIMARY_TEXT}
        />
        <path d='M70.3909 0L61.1235 22.4702L56.4732 10.9894H52.9639L59.4229 26.7326H62.6233L73.6421 0H70.3909Z' fill={PRIMARY_MAIN} />
        <path
          d='M86.2672 18.7267C85.7736 17.9736 85.0697 17.3725 84.1554 16.9281C84.8718 16.5026 85.4198 15.9603 85.8038 15.3044C86.1878 14.6484 86.3798 13.8851 86.3798 13.0153C86.3798 11.9667 86.1054 11.0506 85.5537 10.2716C85.005 9.48875 84.237 8.88532 83.2529 8.45979C82.2716 8.03112 81.1286 7.82031 79.8273 7.82031C78.5445 7.82031 77.4103 8.03112 76.429 8.45979C75.4448 8.88532 74.6828 9.48875 74.1399 10.2716C73.5978 11.0506 73.3264 11.9667 73.3264 13.0153C73.3264 13.8851 73.5154 14.6516 73.8898 15.3177C74.265 15.983 74.8012 16.5222 75.5022 16.9281C74.6033 17.3733 73.9112 17.9736 73.4264 18.7267C72.9446 19.4829 72.7012 20.3661 72.7012 21.3794C72.7012 22.5157 72.9939 23.5094 73.5758 24.3597C74.1605 25.2139 74.9895 25.8659 76.0656 26.3173C77.1381 26.7718 78.3937 26.9991 79.8266 26.9991C81.2617 26.9991 82.521 26.7718 83.6023 26.3173C84.6872 25.8659 85.5221 25.2131 86.1164 24.3597C86.7078 23.5086 87.0035 22.5157 87.0035 21.3794C87.005 20.3661 86.7578 19.4829 86.2672 18.7267ZM77.4169 11.2293C78.0083 10.7685 78.813 10.5373 79.8273 10.5373C80.8454 10.5373 81.6589 10.7677 82.2657 11.2293C82.8755 11.6901 83.18 12.3397 83.18 13.1744C83.18 13.9729 82.8785 14.6061 82.2782 15.0771C81.678 15.5481 80.8608 15.7848 79.8281 15.7848C78.813 15.7848 78.0083 15.5481 77.4176 15.0771C76.8233 14.6061 76.5276 13.9729 76.5276 13.1744C76.5268 12.3397 76.8225 11.6901 77.4169 11.2293ZM82.6806 23.4828C81.9796 24.0149 81.0285 24.2813 79.8281 24.2813C78.6306 24.2813 77.6883 24.0149 77.0028 23.4828C76.3202 22.9507 75.9788 22.2132 75.9788 21.2712C75.9788 20.3457 76.3202 19.6185 77.0028 19.0864C77.6883 18.5543 78.6298 18.2878 79.8281 18.2878C81.0285 18.2878 81.9796 18.5543 82.6806 19.0864C83.3786 19.6185 83.7288 20.3465 83.7288 21.2712C83.7288 22.2132 83.3779 22.9507 82.6806 23.4828Z'
          fill={PRIMARY_MAIN}
        />
      </svg>
    </Box>
  )

  if (disabledLink) {
    return logo
  }

  return (
    <Link component={RouterLink} href='/' sx={{ display: 'contents' }}>
      {logo}
    </Link>
  )
})

export default Logo
