import { UspContactFullAddEdit, UspGetDataContact } from '../../../models'
import _ from 'lodash'
import { getCurrentDate, roundAmount } from '../../../utils/general'
import { convertDateToString } from '../../../utils/dateUtils'
import removeMask from '../../../utils/removeMask'

export const transformToFormValues = (values?: UspGetDataContact): any => {
  // Initialize fields with default values
  let ApplicantContactPhoneID, ApplicantPhoneNumber, ApplicantPhoneExtension
  let ApplicantSecondaryContactPhoneID, ApplicantSecondaryPhoneNumber, ApplicantSecondaryPhoneExtension
  let ApplicantPhoneType = 'Cell'
  let ApplicantSecondaryPhoneType = 'Home'
  // Iterate over the ContactPhoneData array
  if (values?.ContactPhoneData) {
    for (const phoneData of values.ContactPhoneData) {
      if (phoneData.OrderOfPreference === 1) {
        ApplicantContactPhoneID = phoneData.ContactPhoneID
        ApplicantPhoneNumber = phoneData.PhoneNumber
        ApplicantPhoneType = phoneData.PhoneType || 'Cell'
        ApplicantPhoneExtension = phoneData.PhoneExtension
      } else if (phoneData.OrderOfPreference === 2) {
        ApplicantSecondaryContactPhoneID = phoneData.ContactPhoneID
        ApplicantSecondaryPhoneNumber = phoneData.PhoneNumber
        ApplicantSecondaryPhoneType = phoneData.PhoneType || 'Home'
        ApplicantSecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }

  return {
    ContactID: values?.ContactID,
    ApplicantAccountType: values?.IsBusiness ? 'business' : 'individual',
    ApplicantFirstName: values?.FirstName,
    ApplicantMiddleName: values?.MiddleName,
    ApplicantLastName: values?.LastName,
    Suffix: values?.Suffix,
    ApplicantNickName: values?.NickName,
    ApplicantBusinessName: values?.BusinessName,
    ApplicantDateOfBirth: values?.DateOfBirth,
    ApplicantAddressID: _.first(values?.ContactAddressData)?.AddressID,
    ApplicantAddress1: _.first(values?.ContactAddressData)?.Address1,
    ApplicantAddress2: _.first(values?.ContactAddressData)?.Address2,
    ApplicantCity: _.first(values?.ContactAddressData)?.City,
    ApplicantState: _.first(values?.ContactAddressData)?.State,
    ApplicantPostalCode: _.first(values?.ContactAddressData)?.PostalCode,
    ApplicantCounty: _.first(values?.ContactAddressData)?.County,
    ApplicantEmail: values?.Email,
    ApplicantContactPhoneID,
    ApplicantPhoneNumber,
    ApplicantPhoneType,
    ApplicantPhoneExtension,
    ApplicantSecondaryContactPhoneID,
    ApplicantSecondaryPhoneNumber,
    ApplicantSecondaryPhoneType,
    ApplicantSecondaryPhoneExtension,
    ApplicantTaxIDNumber: values?.TaxIDNumber,
    ApplicantLicenseNumber: values?.DLNumber,
    ApplicantLicenseState: values?.DLState,
    ApplicantLicenseIssued: values?.DLIssuedDate,
    ApplicantLicenseExpiration: values?.DLExpireDate,
    EmploymentCurrentEmployerID: values?.EmployerID,
    EmploymentCurrentEmployerContactID: values?.EmployerContactID,
    EmploymentCurrentName: values?.EmployerName,
    EmploymentCurrentOccupation: values?.EmployerOccupation,
    EmploymentCurrentIsSalaried: values?.EmployerIsSalaried,
    EmploymentCurrentWorkEmail: values?.EmployerEmail,
    EmploymentCurrentAddressID: _.first(values?.EmployerAddressData)?.AddressID,
    EmploymentCurrentAddress1: _.first(values?.EmployerAddressData)?.Address1,
    EmploymentCurrentAddress2: _.first(values?.EmployerAddressData)?.Address2,
    EmploymentCurrentCity: _.first(values?.EmployerAddressData)?.City,
    EmploymentCurrentState: _.first(values?.EmployerAddressData)?.State,
    EmploymentCurrentPostalCode: _.first(values?.EmployerAddressData)?.PostalCode,
    EmploymentCurrentCounty: _.first(values?.EmployerAddressData)?.County,
    EmploymentCurrentContactPhoneID: _.first(values?.EmployerPhoneData)?.ContactPhoneID,
    EmploymentCurrentPhoneNumber: _.first(values?.EmployerPhoneData)?.PhoneNumber,
    EmploymentCurrentPhoneType: _.first(values?.EmployerPhoneData)?.PhoneType || 'Biz',
    EmploymentCurrentPhoneExtension: values?.EmployerPhoneExtension,
    EmploymentCurrentDateStarted: values?.EmployerDateStarted,
    EmploymentCurrentYearlySalary: roundAmount(values?.EmployerSalary),
    EmploymentCurrentHoursPerWeek: values?.EmployerHoursPerWeek,
    EmploymentCurrentHourlyWage: roundAmount(values?.EmployerHourlyWage),
    EmploymentCurrentPaySchedule: values?.EmployerPaySchedule,
    EmploymentCurrentComment: values?.EmployerNote,
    EmploymentPreviousIsSalaried: values?.PreviousEmployerIsSalaried,
    EmploymentPreviousID: values?.PreviousEmployerID,
    EmploymentPreviousContactID: values?.PreviousEmployerContactID,
    EmploymentPreviousAddressID: _.first(values?.PreviousEmployerAddressData)?.AddressID,
    EmploymentPreviousName: values?.PreviousEmployerName,
    EmploymentPreviousOccupation: values?.PreviousEmployerOccupation,
    EmploymentPreviousAddress1: _.first(values?.PreviousEmployerAddressData)?.Address1,
    EmploymentPreviousAddress2: _.first(values?.PreviousEmployerAddressData)?.Address2,
    EmploymentPreviousCity: _.first(values?.PreviousEmployerAddressData)?.City,
    EmploymentPreviousState: _.first(values?.PreviousEmployerAddressData)?.State,
    EmploymentPreviousPostalCode: _.first(values?.PreviousEmployerAddressData)?.PostalCode,
    EmploymentPreviousCounty: _.first(values?.PreviousEmployerAddressData)?.County,
    EmploymentPreviousPhoneNumber: _.first(values?.PreviousEmployerPhoneData)?.PhoneNumber,
    EmploymentPreviousContactPhoneID: _.first(values?.PreviousEmployerPhoneData)?.ContactPhoneID,
    EmploymentPreviousPhoneExtension: values?.PreviousEmployerPhoneExtension,
    EmploymentPreviousPhoneType: _.first(values?.PreviousEmployerPhoneData)?.PhoneType || 'Biz',
    EmploymentPreviousWorkEmail: values?.PreviousEmployerEmail,
    EmploymentPreviousDateStarted: values?.PreviousEmployerDateStarted,
    EmploymentPreviousDateEnded: values?.EmployerDateEnded || values?.EmployerDateStarted,
    EmploymentPreviousYearlySalary: roundAmount(values?.PreviousEmployerSalary),
    EmploymentPreviousHoursPerWeek: values?.PreviousEmployerHoursPerWeek,
    EmploymentPreviousHourlyWage: roundAmount(values?.PreviousEmployerHourlyWage),
    EmploymentPreviousPaySchedule: values?.PreviousEmployerPaySchedule,
    EmploymentPreviousComment: values?.PreviousEmployerNote,
    IncomeMonthlyIncome: roundAmount(values?.MonthlyIncome),
    IncomeAlimony: roundAmount(values?.MonthlyChildSupportAlimony),
    IncomeOtherIncome: roundAmount(values?.MonthlyOtherIncome),
    IncomeOtherIncomeDescription: values?.OtherIncomeDescription,
    IncomeMonthlyHousingExpense: roundAmount(values?.MonthlyHousingExpense),
    IncomeCustomExpense1: roundAmount(values?.MonthlyExpense1),
    IncomeCustomExpense2: roundAmount(values?.MonthlyExpense2),
    IncomeCustomExpense3: roundAmount(values?.MonthlyExpense3),
    IncomeCustomExpense4: roundAmount(values?.MonthlyExpense4),
    IncomeCustomExpense5: roundAmount(values?.MonthlyExpense5),
    IncomeOtherExpenses: roundAmount(values?.MonthlyExpenseOther),
    HistoryNumberOfDependents: values?.NumberOfDependents,
    HistoryHasHadBankruptcy: values?.IsHadBankruptcy || false,
    HistoryBankruptcyType: values?.BankruptcyType,
    HistoryBankruptcyDate: values?.BankruptcyDate,
    HistoryHasHadRepossession: values?.IsHadRepo || false,
    HistoryRepossessionDate: values?.RepoDate,
  }
}

export const transformToRequestValues = (
  values: any,
  leadsId?: string | null | undefined,
  dealID?: number | null,
  contactType?: string | null | undefined,
): UspContactFullAddEdit => ({
  isAccount: true,
  isCreditApp: false,
  contactOrder: contactType === 'buyer' ? 1 : contactType === 'co-buyer' ? 2 : null,
  creditAppID: leadsId === 'add' ? undefined : Number(leadsId),
  dealID: dealID,
  firstName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantFirstName?.trim() : null,
  middleName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantMiddleName?.trim() : null,
  lastName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantLastName?.trim() : null,
  suffix: values?.ApplicantAccountType === 'individual' ? values?.Suffix : null,
  nickName: values?.ApplicantAccountType === 'individual' ? values?.ApplicantNickName?.trim() : null,
  businessName: values?.ApplicantAccountType === 'business' ? values?.ApplicantBusinessName?.trim() : null,
  addressID: values?.ApplicantAddressID,
  address1: values?.ApplicantAddress1?.trim(),
  address2: values?.ApplicantAddress2?.trim(),
  city: values?.ApplicantCity?.trim(),
  state: values?.ApplicantState,
  postalCode: values?.ApplicantPostalCode?.trim(),
  county: values?.ApplicantCounty?.trim(),
  email: values?.ApplicantEmail?.trim(),
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.ApplicantContactPhoneID,
      PhoneNumber: values?.ApplicantPhoneNumber,
      PhoneType: values?.ApplicantPhoneType,
      PhoneExtension: values?.ApplicantPhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
    },
    {
      ContactPhoneID: values?.ApplicantSecondaryContactPhoneID,
      PhoneNumber: values?.ApplicantSecondaryPhoneNumber,
      PhoneType: values?.ApplicantSecondaryPhoneType,
      PhoneExtension: values?.ApplicantSecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
    },
  ],
  appDate: getCurrentDate(),
  dateOfBirth: values?.ApplicantAccountType === 'individual' ? convertDateToString(values?.ApplicantDateOfBirth) : null,
  taxIDNumber: removeMask(values?.ApplicantTaxIDNumber?.trim()),
  dlState: values?.ApplicantLicenseState,
  dlNumber: values?.ApplicantLicenseNumber?.trim(),
  dlExpireDate: convertDateToString(values?.ApplicantLicenseExpiration),
  dlIssuedDate: convertDateToString(values?.ApplicantLicenseIssued),

  currentEmployerID: values?.EmploymentCurrentEmployerID, // TODO ADD ID ON EDIT
  currentEmployerBusinessName: values?.EmploymentCurrentName?.trim(),
  currentEmployerOccupation: values?.EmploymentCurrentOccupation?.trim(),
  currentEmployerAddressID: values?.EmploymentCurrentAddressID,
  currentEmployerAddress1: values?.EmploymentCurrentAddress1?.trim(),
  currentEmployerAddress2: values?.EmploymentCurrentAddress2?.trim(),
  currentEmployerCity: values?.EmploymentCurrentCity?.trim(),
  currentEmployerState: values?.EmploymentCurrentState,
  currentEmployerPostalCode: values?.EmploymentCurrentPostalCode?.trim(),
  currentEmployerCounty: values?.EmploymentCurrentCounty?.trim(),
  currentEmployerPhoneExtension: values?.EmploymentCurrentPhoneExtension?.trim(),
  currentEmployerJsonPhoneData: [
    {
      ContactPhoneID: values?.EmploymentCurrentContactPhoneID,
      PhoneNumber: values?.EmploymentCurrentPhoneNumber,
      PhoneType: values?.EmploymentCurrentPhoneType,
      PhoneExtension: null,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
  currentEmployerDateStarted: convertDateToString(values?.EmploymentCurrentDateStarted),
  currentEmployerEmail: values?.EmploymentCurrentWorkEmail?.trim(),
  currentEmployerSalary: values?.EmploymentCurrentIsSalaried ? removeMask(values?.EmploymentCurrentYearlySalary) : null,
  currentEmployerHourlyWage: values?.EmploymentCurrentIsSalaried ? null : removeMask(values?.EmploymentCurrentHourlyWage),
  currentEmployerHoursPerWeek: values?.EmploymentCurrentIsSalaried ? '0' : values?.EmploymentCurrentHoursPerWeek,
  currentEmployerPaySchedule: values?.EmploymentCurrentPaySchedule,
  currentEmployerNote: values?.EmploymentCurrentComment?.trim(),
  currentEmployerIsPrimary: true,
  currentEmployerIsAllowedCall: true,
  currentEmployerIsActive: true,
  currentEmployerContactID: values?.EmploymentCurrentEmployerContactID,
  previousEmployerID: values?.EmploymentPreviousID,
  previousEmployerContactID: values?.EmploymentPreviousContactID,
  previousEmployerAddressID: values?.EmploymentPreviousAddressID,
  previousEmployerBusinessName: values?.EmploymentPreviousName?.trim(),
  previousEmployerOccupation: values?.EmploymentPreviousOccupation?.trim(),
  previousEmployerAddress1: values?.EmploymentPreviousAddress1?.trim(),
  previousEmployerAddress2: values?.EmploymentPreviousAddress2?.trim(),
  previousEmployerCity: values?.EmploymentPreviousCity?.trim(),
  previousEmployerState: values?.EmploymentPreviousState,
  previousEmployerPostalCode: values?.EmploymentPreviousPostalCode?.trim(),
  previousEmployerCounty: values?.EmploymentPreviousCounty?.trim(),
  previousEmployerPhoneExtension: values?.EmploymentPreviousPhoneExtension?.trim(),
  previousEmployerJsonPhoneData: [
    {
      ContactPhoneID: values?.EmploymentPreviousContactPhoneID,
      PhoneNumber: values?.EmploymentPreviousPhoneNumber,
      PhoneType: values?.EmploymentPreviousPhoneType,
      PhoneExtension: null,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
  previousEmployerDateStarted: convertDateToString(values?.EmploymentPreviousDateStarted),
  previousEmployerDateEnded: convertDateToString(values?.EmploymentPreviousDateEnded),
  previousEmployerEmail: values?.EmploymentPreviousWorkEmail?.trim(),
  previousEmployerSalary: values?.EmploymentPreviousIsSalaried ? removeMask(values?.EmploymentPreviousYearlySalary) : null,
  previousEmployerHourlyWage: values?.EmploymentPreviousIsSalaried ? null : removeMask(values?.EmploymentPreviousHourlyWage),
  previousEmployerHoursPerWeek: values?.EmploymentPreviousIsSalaried ? '0' : values?.EmploymentPreviousHoursPerWeek,
  previousEmployerPaySchedule: values?.EmploymentPreviousPaySchedule,
  previousEmployerNote: values?.EmploymentPreviousComment?.trim(),
  previousEmployerIsPrimary: false,
  previousEmployerIsAllowedCall: true,
  previousEmployerIsActive: true,
  monthlyIncome: removeMask(values?.IncomeMonthlyIncome),
  monthlyChildSupportAlimony: removeMask(values?.IncomeAlimony),
  monthlyOtherIncome: removeMask(values?.IncomeOtherIncome),
  otherIncomeDescription: values?.IncomeOtherIncomeDescription?.trim(),
  monthlyHousingExpense: removeMask(values?.IncomeMonthlyHousingExpense),
  monthlyExpense1: removeMask(values?.IncomeCustomExpense1),
  monthlyExpense2: removeMask(values?.IncomeCustomExpense2),
  monthlyExpense3: removeMask(values?.IncomeCustomExpense3),
  monthlyExpense4: removeMask(values?.IncomeCustomExpense4),
  monthlyExpense5: removeMask(values?.IncomeCustomExpense5),
  monthlyExpenseOther: removeMask(values?.IncomeOtherExpenses),
  numberOfDependents: Number(values?.HistoryNumberOfDependents) || 0,
  isHadBankruptcy: values?.HistoryHasHadBankruptcy,
  bankruptcyType: values?.HistoryBankruptcyType,
  bankruptcyDate: convertDateToString(values?.HistoryBankruptcyDate),
  isHadRepo: values?.HistoryHasHadRepossession,
  repoDate: convertDateToString(values?.HistoryRepossessionDate),

  // constants for later optimization
  isContactUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isAddressUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isCurrentEmployerAddressUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isPreviousEmployerContactUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isPreviousEmployerAddressUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isPreviousEmployerPhoneUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isIncomeHistoryUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isCurrentEmployerPhoneUpdated: true, // TODO IMPROVE OPTIMIZATION BY ADDING FUNCTION
  isPhoneUpdated: true,
  isCurrentEmployerContactUpdated: true,
  isCurrentEmployerUpdated: true,
  isPreviousEmployerUpdated: true,
})
