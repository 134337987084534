import { Box, Button, Grid, Paper, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../constants'
import { Form } from 'react-final-form'
import Header from './components/Header/Header'
import NameForm from './components/NameForm/NameForm'
import AdministrativeForm from './components/AdministrativeForm/AdministrativeForm'
import InventoryForm from './components/InventoryForm/InventoryForm'
import CreditAppsForm from './components/CreditAppsForm/CreditAppsForm'
import DealsForm from './components/DealsForm/DealsForm'
import AccountsForm from './components/AccountsForm/AccountsForm'
import CollectionsForm from './components/CollectionsForm/CollectionsForm'
import InsightsForm from './components/InsightsForm/InsightsForm'
import TitleForm from './components/TitleForm/TitleForm'
import AccountingForm from './components/AccountingForm/AccountingForm'
import { useDispatch } from 'react-redux'
import { useAddUserRoleMutation, useEditUserRoleMutation, useLazyGetUserRoleQuery } from '../../../../services'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { transformToFormValues, transformToRequestValues } from './transform'
import { CustomCircular } from '../../../../ui'
import SettingsForm from './components/SettingsForm/SettingsForm'

const classes = generateUtilityClasses('Box', ['root', 'cardRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.cardRow}`]: {
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const AddEditAdminUserRoles = () => {
  const navigate = useNavigate()
  const { userRoleId } = useParams()
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})

  const [getUserRole, { data: userRoleData, isLoading: getUserRoleIsLoading, isSuccess: getUserRoleIsSuccess }] = useLazyGetUserRoleQuery()
  const [addUserRole, { isSuccess: isSuccessUserRoleDataAdd, isLoading: addUserRoleIsLoading }] = useAddUserRoleMutation()
  const [editUserRole, { isSuccess: isSuccessUserRoleDataEdit, isLoading: editUserRoleIsLoading }] = useEditUserRoleMutation()

  useEffect(() => {
    if (userRoleId !== 'add') {
      getUserRole({ userRoleId: userRoleId as string })
    }
  }, [userRoleId])

  useEffect(() => {
    if (getUserRoleIsSuccess) {
      setInitialValues(transformToFormValues(userRoleData))
    }
  }, [userRoleData, getUserRoleIsSuccess, userRoleId])

  const handleSubmit = (values: any) => {
    if (userRoleId === 'add') {
      addUserRole({
        uspConfigurationUserRoleAddEdit: transformToRequestValues(values) as any,
      })
    } else {
      editUserRole({
        userRoleId: userRoleId as string,
        uspConfigurationUserRoleAddEdit: transformToRequestValues(values) as any,
      })
    }
  }

  useEffect(() => {
    if (isSuccessUserRoleDataEdit || isSuccessUserRoleDataAdd) {
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(appRoute.SettingsAdminUserRoles)
    }
  }, [isSuccessUserRoleDataEdit, isSuccessUserRoleDataAdd])

  const isLoading = useMemo(
    () => getUserRoleIsLoading || addUserRoleIsLoading || editUserRoleIsLoading,
    [getUserRoleIsLoading, addUserRoleIsLoading, editUserRoleIsLoading],
  )

  return (
    <>
      <Form onSubmit={handleSubmit} initialValues={{ IsActive: true, ...initialValues }}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <StyledBox className={classes.root}>
              <Header />
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 2, sm: 2, md: 6, lg: 7.5, xl: 10.5 }}
                rowSpacing={1}
                columnSpacing={1}
                className={classes.pageBody}
              >
                <Grid item xs={12} md={2} lg={2} xl={2}>
                  <Grid container direction='column' rowSpacing={1}>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <NameForm />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <SettingsForm />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <InventoryForm />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <CreditAppsForm />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} lg={2} xl={2}>
                  <Grid container direction='column' rowSpacing={1} columnSpacing={1}>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <DealsForm />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <AccountsForm />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <CollectionsForm />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={2} lg={2} xl={2}>
                  <Grid container direction='column' rowSpacing={1} columnSpacing={1} columnGap={2}>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <AccountingForm />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={1.5} xl={2.5}>
                  <Grid container direction='column' rowSpacing={1} columnSpacing={1}>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <InsightsForm />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <TitleForm />
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={classes.cardRow}>
                        <AdministrativeForm />
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonWrap}>
                <Button type='submit' className={classes.saveBtn} variant='contained'>
                  Save
                </Button>
                <Button variant='contained' color='error' onClick={() => navigate(appRoute?.SettingsAdminUserRoles)}>
                  Close
                </Button>
              </Grid>
            </StyledBox>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </>
  )
}

export default AddEditAdminUserRoles
