import {
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useState } from 'react'
import { TDistribution } from '../../../../../../../models'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: '300px',
  },
  [`& .${classes.tableHead}`]: {
    background: theme.palette?.background?.main,
  },
}))

interface Props {
  distributions: TDistribution[]
  onDelete: (type: string) => void
  setTotalAmount: (total: number) => void
}

const ItemizationCard = ({ distributions, onDelete, setTotalAmount }: Props) => {
  const [total, setTotal] = useState(0)

  // TODO: CREATE A HELPER FUNCTION
  const formatNumber = (num: number | string) => {
    const numString = typeof num === 'string' ? num.replace(/,/g, '') : num

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(numString))
  }

  useEffect(() => {
    let total = distributions.reduce((sum, distribution) => {
      let amount = typeof distribution.AmountAdd === 'string' ? (distribution?.AmountAdd as string).replace(/,/g, '') : distribution?.AmountAdd
      amount = Number(amount)
      return sum + (isNaN(amount) ? 0 : amount)
    }, 0)
    setTotal(total)
    setTotalAmount(total) //  this function to set the total amount in the parent component
  }, [distributions, setTotalAmount])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: '40vh' }}>
          <Table sx={{ minWidth: 550 }} aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>Type</TableCell>
                <TableCell className={classes.tableHead}>Late Fee</TableCell>
                <TableCell className={classes.tableHead}>Amount</TableCell>
                <TableCell className={classes.tableHead}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {distributions.map((distribution, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{distribution.Type}</TableCell>
                  <TableCell>{formatNumber(distribution.LateFee)}</TableCell>
                  <TableCell>{formatNumber(distribution.AmountAdd)}</TableCell>
                  <TableCell>
                    <Tooltip title='Delete'>
                      <IconButton color='error' onClick={() => onDelete(distribution.Type)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
              {distributions?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid container item xs={12} justifyContent={'flex-end'}>
        <Typography variant='h6'>Total: {formatNumber(total)}</Typography>
      </Grid>
    </Grid>
  )
}

export default ItemizationCard
