import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export const slice = createSlice({
  name: 'formPacks',
  initialState: null,
  reducers: {
    setFormsPacks: (state, { payload }) => {
      return payload
    },
  },
})
export const getFormPacks = (state: RootState) => {
  return state?.formPacks
}
export const { setFormsPacks } = slice.actions

export default slice.reducer
