import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { UspGetDataCar } from '../../models'
import { upsert } from '../../utils/general'

type CarState = {
  cars?: Array<UspGetDataCar>
  car?: any // for now
}

export const slice = createSlice({
  name: 'cars',
  initialState: {
    cars: [],
    car: undefined,
  } as CarState,
  reducers: {
    setCars: (state, { payload }) => {
      upsert(state.cars, payload, 'CarID')
    },
    setCar: (state, { payload }) => {
      state.car = payload
    },
  },
})
export const getCar =
  (carId: string | undefined): any =>
  (state: RootState) => {
    if (carId) {
      return state?.cars?.cars?.find((car: UspGetDataCar) => String(car?.CarID) === String(carId))
    }
    return undefined
  }

export const getSingleCar = (state: RootState) => {
  return state?.cars?.car
}

export const { setCars, setCar } = slice.actions

export default slice.reducer
