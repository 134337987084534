import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '../../../../../../ui/TextField'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { UspFormPackViewResult } from '../../../../../../models'
import { useAddFormPackMutation, useEditFormPackMutation, useLazyGetFormPackDataQuery } from '../../../../../../services'
import { CustomCircular } from '../../../../../../ui'
import { useDispatch, useSelector } from 'react-redux'
import { getStates } from '../../../../../../redux/slices/userDropdownSlice'
import { withPermissionsDropdown } from '../../../../../../hoc'
import { enqueueNotification } from '../../../../../../redux/slices/notificationsSlice'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '390px',
    backgroundColor: theme.palette.background.main,
    boxShadow: 24,
    padding: theme.spacing(3),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  rowData: UspFormPackViewResult
  open: boolean
  onClose: () => void
  type: string
  submit: (...args: any) => any
}
const AddEditFormsPacksModal = ({ open, onClose, rowData, type, submit }: Props) => {
  const states = useSelector(getStates)
  const dispatch = useDispatch()
  const [initialValues, setInitialValues] = useState({})
  const [addFormPack, { isLoading: isLoadingFormPack, isSuccess: isSuccessFormPack }] = useAddFormPackMutation()
  const [editFormPack, { isLoading: isLoadingEditFormPack, isSuccess: isSuccessEditFormPack }] = useEditFormPackMutation()
  const [getFormPackData, { isLoading: isLoadingGetFormPack, isSuccess: isSuccessGetFormPack, data }] = useLazyGetFormPackDataQuery()

  const handleSubmit = (values: any) => {
    if (type === 'edit') {
      editFormPack({
        formPackId: rowData?.FormPackID?.toString(),
        uspFormPackAddEdit: {
          name: values.Name,
          state: values.FormPackState,
          isESignaturePack: values.ESignature,
          isActive: values.IsActive,
        },
      })
    } else {
      addFormPack({
        uspFormPackAddEdit: {
          name: values.Name,
          state: values.FormPackState,
          isESignaturePack: values.ESignature,
          isActive: values.IsActive,
        },
      })
    }
  }

  useEffect(() => {
    if (open && rowData && type === 'edit')
      getFormPackData({
        formPackId: rowData?.FormPackID?.toString() as string,
      })
  }, [open, type, rowData])

  useEffect(() => {
    if (type === 'add') {
      setInitialValues({
        IsActive: true,
        ESignature: false,
      })
    }
    if (isSuccessGetFormPack && type === 'edit') {
      setInitialValues({
        Name: data?.Name,
        FormPackState: data?.State,
        ESignature: data?.IsESignaturePack,
        IsActive: data?.IsActive,
      })
    }
  }, [isSuccessGetFormPack, data, type])

  useEffect(() => {
    if (isSuccessEditFormPack || isSuccessFormPack) {
      dispatch(enqueueNotification({ message: 'Success' }))
      onClose()
      submit()
    }
  }, [isSuccessEditFormPack, isSuccessFormPack])

  const isLoading = useMemo(
    () => isLoadingFormPack || isLoadingEditFormPack || isLoadingGetFormPack,
    [isLoadingFormPack, isLoadingEditFormPack, isLoadingGetFormPack],
  )
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Form Pack</DialogTitle>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ handleSubmit }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={1}>
                <Grid item xs={12}>
                  <TextField required name='Name' label='Name' fullWidth />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='FormPackState'
                    label='State'
                    options={states}
                    optionKey='State'
                    optionValue='State'
                    autoComplete='off'
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='ESignature'
                    label='Is E-Signature Pack?'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
                <Grid item xs={12}>
                  <DropdownFieldSearch
                    name='IsActive'
                    label='Is Active'
                    options={[
                      { key: true, values: 'Yes' },
                      { key: false, values: 'No' },
                    ]}
                    optionKey='key'
                    optionValue='values'
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <LoadingButton type='submit' className={classes.saveBtn} variant='contained'>
                Save
              </LoadingButton>
            </DialogActions>
          </form>
        )}
      </Form>
      <CustomCircular open={isLoading} />
    </Dialog>
  )
}

export default withPermissionsDropdown(AddEditFormsPacksModal)
