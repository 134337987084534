import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Header } from '../components/Header'
import {
  Box,
  Card,
  CircularProgress,
  generateUtilityClasses,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material'
import { useCarExpenseGetMutation } from '../../../services'
import { convertDateToDisplay } from '../../../utils/dateUtils'
import { withCars, withPermissionsDropdown } from '../../../hoc'
import { Form } from 'react-final-form'
import { ExpenseHistoryHeader } from './components'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { roundAmount } from '../../../utils/general'
import { UspCarExpenseViewResult } from '../../../models'
import { storageGet, storageSet } from '../../../../src/utils/storage'
import { getComparator, stableSort } from '../../../utils/sorting'
import { visuallyHidden } from '@mui/utils'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../constants'
import { useDispatch, useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../redux/slices/authSlice'

type TFormFields = {
  isIncludeAllHistoryByCar: boolean
  isIncludeReversals: boolean
}

export type TExpenseRequest = {
  isIncludeAllHistoryByCar: boolean
  isIncludeReversals: boolean
}

const classes = generateUtilityClasses('Grid', ['root', 'bodyWrap', 'loading', 'spinner', 'tableHead', 'buttonWrap', 'formStyle'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.bodyWrap}`]: {
    backgroundColor: theme.palette.background.secondary,
    padding: theme.spacing(0, 3, 2, 3),
    overflow: 'auto',
  },
  [`& .${classes.tableHead}`]: {
    whiteSpace: 'nowrap',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.loading}`]: {
    verticalAlign: 'top',
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 3, 3, 0),
    marginTop: 'auto',
  },

  [`& .${classes.formStyle}`]: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
}))

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.main,
    maxWidth: '400px',
    padding: theme.spacing(4),
    color: theme.palette?.text?.primary,
    border: `1px solid ${theme.palette.background.secondary}`,
  },
}))

export const InventoryEditExpenses = ({ getCarExpense, carExpenseData, carExpenseIsLoading, carData, getCar, carIsSuccess }: any) => {
  const { carID } = useParams()
  const userAccess = useSelector(getUserAccessRights)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const initialOrder = (storageGet('inventory_expenses_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('inventory_expenses_by') || 'StockNumber') as keyof UspCarExpenseViewResult
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [formChanged, setFormChanged] = useState(false)
  const [orderBy, setOrderBy] = useState<keyof UspCarExpenseViewResult>(initialOrderBy)
  const [expenseRequestData, setExpenseRequestData] = useState<TExpenseRequest>({
    isIncludeAllHistoryByCar: true,
    isIncludeReversals: true,
  })

  const handleSubmit = (values: TFormFields) => {
    setFormChanged(false)
    getCarExpense({
      carId: carID as string,
      uspCarExpenseView: {
        isIncludeAllHistoryByCar: values.isIncludeAllHistoryByCar,
        isIncludeReversals: values.isIncludeReversals,
        expenseType: null,
      },
    })
    setExpenseRequestData({
      isIncludeAllHistoryByCar: values.isIncludeAllHistoryByCar,
      isIncludeReversals: values.isIncludeReversals,
    })
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCarExpenseViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('inventory_expenses_order', isAsc ? 'desc' : 'asc')
    storageSet('inventory_expenses_by', property)
  }

  const createSortHandler = (property: keyof UspCarExpenseViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  useEffect(() => {
    if (!userAccess.CanViewCarCost) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Card>
      <Form onSubmit={handleSubmit} initialValues={{ isIncludeAllHistoryByCar: false, isIncludeReversals: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit} className={classes.formStyle} onChange={() => setFormChanged(true)}>
            <Grid className={classes.bodyWrap}>
              <ExpenseHistoryHeader submit={submit} />

              <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 200px)' }} className={classes.root}>
                <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'StockNumber'}
                          direction={orderBy === 'StockNumber' ? order : 'asc'}
                          onClick={createSortHandler('StockNumber')}
                        >
                          Stock #
                          {orderBy === 'StockNumber' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'DateAdded' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'DateAdded'}
                          direction={orderBy === 'DateAdded' ? order : 'asc'}
                          onClick={createSortHandler('DateAdded')}
                        >
                          Date
                          {orderBy === 'DateAdded' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'ExpenseType' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'VendorCode'}
                          direction={orderBy === 'VendorCode' ? order : 'asc'}
                          onClick={createSortHandler('VendorCode')}
                        >
                          Vendor
                          {orderBy === 'VendorCode' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'ExpenseType' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'ExpenseType'}
                          direction={orderBy === 'ExpenseType' ? order : 'asc'}
                          onClick={createSortHandler('ExpenseType')}
                        >
                          Type
                          {orderBy === 'ExpenseType' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'ExpenseAmount' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'ExpenseAmount'}
                          direction={orderBy === 'ExpenseAmount' ? order : 'asc'}
                          onClick={createSortHandler('ExpenseAmount')}
                        >
                          Amount
                          {orderBy === 'ExpenseAmount' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillNumber' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'BillNumber'}
                          direction={orderBy === 'BillNumber' ? order : 'asc'}
                          onClick={createSortHandler('BillNumber')}
                        >
                          Bill #
                          {orderBy === 'BillNumber' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'CheckNumber' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'CheckNumber'}
                          direction={orderBy === 'CheckNumber' ? order : 'asc'}
                          onClick={createSortHandler('CheckNumber')}
                        >
                          Check #
                          {orderBy === 'CheckNumber' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsCapitalized' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'IsCapitalized'}
                          direction={orderBy === 'IsCapitalized' ? order : 'asc'}
                          onClick={createSortHandler('IsCapitalized')}
                        >
                          Capitalized
                          {orderBy === 'IsCapitalized' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'TransactionNumber' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'TransactionNumber'}
                          direction={orderBy === 'TransactionNumber' ? order : 'asc'}
                          onClick={createSortHandler('TransactionNumber')}
                        >
                          Trans #
                          {orderBy === 'TransactionNumber' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      <TableCell className={classes.tableHead} sortDirection={orderBy === 'Note' ? order : false}>
                        <TableSortLabel
                          active={orderBy === 'Note'}
                          direction={orderBy === 'Note' ? order : 'asc'}
                          onClick={createSortHandler('Note')}
                        >
                          Description
                          {orderBy === 'Note' ? (
                            <Box component='span' sx={visuallyHidden}>
                              {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell className={classes.tableHead} align={'right'}>
                        Action
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(getComparator(order, orderBy), carExpenseData as any[])?.map((item, index) => (
                      <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell>{item?.StockNumber}</TableCell>
                        <TableCell>{convertDateToDisplay(item?.DateAdded as string)}</TableCell>
                        <TableCell>{item?.VendorCode}</TableCell>
                        <TableCell>{item?.ExpenseType}</TableCell>
                        <TableCell>{roundAmount(item?.ExpenseAmount as string)}</TableCell>
                        <TableCell>{item?.BillNumber}</TableCell>
                        <TableCell>{item?.CheckNumber}</TableCell>
                        <TableCell>{item.IsCapitalized ? <Typography>Yes</Typography> : <Typography>No</Typography>}</TableCell>
                        <TableCell>{item?.TransactionNumber}</TableCell>
                        <TableCell>{item?.ExpenseDescription}</TableCell>
                        {/* <TableCell align={'right'}>
                          {item?.Note && (
                            <HtmlTooltip placement='left-start' title={<Typography>{item?.Note}</Typography>}>
                              <InfoOutlinedIcon color='primary' />
                            </HtmlTooltip>
                          )}
                        </TableCell> */}
                      </TableRow>
                    ))}
                    {carExpenseData?.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={10} align='center'>
                          No results
                        </TableCell>
                      </TableRow>
                    )}
                    {carExpenseIsLoading && (
                      <TableRow>
                        <TableCell colSpan={10} align='center'>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </form>
        )}
      </Form>
    </Card>
  )
}

export default withPermissionsDropdown(withCars(InventoryEditExpenses))
