import React, { useEffect } from 'react'
import { useLazyGetCarQuery } from '../../services'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getCar as getCarSelector, setCars } from '../../redux/slices/carsSlice'

export function withCars(Component: any) {
  // GET CAR DATA AND ADD TO REDUX
  return function (props: any) {
    const { carID } = useParams()
    const dispatch = useDispatch()
    const car = useSelector(getCarSelector(carID))
    const [getCar, { data: carData, isSuccess: carIsSuccess, isLoading: carIsLoading }] = useLazyGetCarQuery()

    useEffect(() => {
      if (!car) {
        getCar({ carId: carID as string })
      }
    }, [])

    useEffect(() => {
      if (carIsSuccess) {
        dispatch(setCars(carData))
      }
    }, [carIsSuccess])

    return <Component getCar={getCar} carData={carData || car} carIsSuccess={carIsSuccess} carIsLoading={carIsLoading} {...props} />
  }
}
