import React from 'react'
import { styled } from '@mui/system'
import { Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import { DateField, PercentField, TextField } from '../../../../../../../ui'
import { RecapPopover } from '../index'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import AmountField from '../../../../../../../ui/AmountField'
import Button from '@mui/material/Button'
import { useGetDealProfitMutation } from '../../../../../../../services'
import {
  GetDealProfitApiResponse,
  TFees_List,
  TProducts_List,
  UspGetDataCar,
  UspGetDataDealEntryResult,
  UspGetDataStateDealSettingsResult,
} from '../../../../../../../models'
import { parseToNumber } from '../../../../../../../utils/general'
import { useParams } from 'react-router-dom'
import removeMask from '../../../../../../../utils/removeMask'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText', 'rowBtn', 'printBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
  [`& .${classes.rowBtn}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  // [`& .${classes.printBtn}`]: {
  //   backgroundColor: theme.palette.background.purple,
  // },
}))

interface Props {
  carData: UspGetDataCar
  dealData: UspGetDataDealEntryResult
  stateDealSettingsData: UspGetDataStateDealSettingsResult
  disableFields: boolean
}

export const ResultForm = ({ carData, dealData }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const { values } = useFormState()
  const { dealID } = useParams()
  const [getDealProfit, { data: dealProfitData, isLoading: dealProfitIsLoading }] = useGetDealProfitMutation()

  const generateProductListFromValues = (values: any): TProducts_List[] => {
    const productsList: TProducts_List[] = []

    // loop through the keys in the values object form
    for (const key in values) {
      if (key.startsWith('ProductFieldIsActive')) {
        const index = key?.replace('ProductFieldIsActive', '')

        // check if the ProductFieldIsActive is true
        if (values[`ProductFieldIsActive${index}`] === true) {
          productsList?.push({
            ProductLabel: values[`ProductFieldProfitLabel${index}`] as string,
            Amount: removeMask(values[`ProductFieldValue${index}`]) as string,
            IsTaxable: values[`ProductIsTaxableValue${index}`] as boolean,
            CostRate: values[`ProductFieldProfitCostRate${index}`] as number,
            CostAmount: removeMask(values[`ProductFieldProfitCostAmount${index}`]) as string,
            ProfitType: values[`ProductFieldProfitType${index}`] as string,
          })
        }
      }
    }

    return productsList
  }
  const productsList = generateProductListFromValues(values)

  const generateFeesListFromValues = (values: any): TFees_List[] => {
    const feesList: TFees_List[] = []

    // loop through the keys in the values object form
    for (const key in values) {
      if (key.startsWith('FeeFieldIsActive')) {
        const index = key.replace('FeeFieldIsActive', '')

        // check if the FeeFieldIsActive is true
        if (values[`FeeFieldIsActive${index}`] === true) {
          feesList.push({
            FeeLabel: values[`FeeFieldFeeLabel${index}`] as string,
            Amount: removeMask(values[`FeeFieldValue${index}`]) as string,
            IsTaxable: values[`FeeIsTaxableValue${index}`] as boolean,
            ProfitType: values[`FeeFieldProfitType${index}`] as string,
          })
        }
      }
    }

    return feesList
  }
  const feesList = generateFeesListFromValues(values)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    getDealProfit({
      body: [
        {
          Sales_Price: parseToNumber(removeMask(values?.SalesPrice) as string),
          Trade_Allowance: parseToNumber(removeMask(values?.TradeAllowance) as string),
          Trade_Acv: parseToNumber(removeMask(values?.TradeACV) as string),
          Sales_Discount: parseToNumber(removeMask(values?.DiscountAmount) as string),
          Spread_Amount: parseToNumber(removeMask(values?.SpreadAmount) as string),
          Trade_Lien: parseToNumber(removeMask(values?.TradeLien) as string),
          Inventory_Cost: parseToNumber(removeMask(carData?.TotalCost?.toString()) as string),
          Sales_Commission: dealID !== 'add' ? parseToNumber(dealData?.SalesCommission?.toString() as string) : 0,
          Referral_Fee: dealID !== 'add' ? parseToNumber(dealData?.ReferralFee?.toString() as string) : 0,
          Products_List: productsList,
          Fees_List: feesList,
        },
      ],
    })
    setAnchorEl(event?.currentTarget)
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <Grid container className={classes.rowBtn}>
          <Typography variant='subtitle1' gutterBottom>
            Deal Result
          </Typography>
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='AmountPaymentAmount' label='Payment' fullWidth />
        </Grid>
        {values.Calculate === 'term' && (
          <Grid className={classes.row}>
            <TextField disabled name='AmountFinalTerm' label='Term' fullWidth />
          </Grid>
        )}
        {Number(values.AmountFinalPayment) !== 0 && (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='AmountFinalPayment' label='Final Payment' fullWidth />
          </Grid>
        )}
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='AmountFinanced' label='Amount Financed' fullWidth />
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='AmountFinanceCharges' label='Finance Charges' fullWidth />
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='TotalOfPayments' label='Total of Payments' fullWidth />
        </Grid>
        <Grid className={classes.row}>
          <PercentField variant='standard' disabled name='APRRate' label='APR' fullWidth />
        </Grid>
        <Grid className={classes.row}>
          <AmountField variant='standard' disabled name='AmountSalesTax' label='Sales Tax' fullWidth />
        </Grid>
        {values?.DocStampsAmount > 0 ? (
          <Grid className={classes.row}>
            <AmountField variant='standard' disabled name='DocStampsAmount' label='Doc Stamps' fullWidth />
          </Grid>
        ) : null}
        <Grid className={classes.row}>
          <DateField disabled name='MaturityDate' label='Maturity Date' fullWidth />
        </Grid>
      </StyledGrid>
      <RecapPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} data={dealProfitData as GetDealProfitApiResponse} isLoading={dealProfitIsLoading} />
    </>
  )
}

export default ResultForm
