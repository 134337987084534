import React, { LegacyRef, useState } from 'react'
import { m } from 'framer-motion'

//import the css here
import './index.css'
import Iconify from '../iconify/iconify'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useSendMessageMutation } from '../../services/AI/AIApi'
import { ChatView } from '../chat/view'
import { varHover } from '../animate'
export const PopChat = (props: any) => {
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState<any>([])
  const [sendMessage, { isSuccess: sendMessageSuccess, isLoading }] = useSendMessageMutation()
  let hide = {
    display: 'none',
  }
  let show = {
    display: 'block',
  }

  const [chatopen, setChatopen] = useState(false)
  const toggle = (e: any) => {
    setChatopen(!chatopen)
  }

  const handleSend = async (e: any) => {
    const res: any = await sendMessage({
      data: {
        prompt: message,
        sessionID: null,
      },
    })
    if (!res.error) {
      if (res?.data && res?.data?.aiResponse.length > 0) {
        setMessages((prevVal: any) => [...prevVal, { prompt: message }, res?.data])
        setMessage('')
      }
    }
  }

  return (
    <div id='chatCon'>
      <div className='overlay' style={chatopen ? show : hide} onClick={toggle}></div>

      <div className='chat-box' style={chatopen ? show : hide}>
        {/* <div className='header'>Ask AI</div>
        <div className='msg-area'>
          {messages.map((msg, i) =>
             msg.prompt ? (
              <p className='right'>
                <span>{msg.prompt}</span>
              </p>
            ) : (
              <p className='left'>
                <span>{JSON.stringify(msg?.aiResponse[0])}</span>
              </p>
            ),
          )}
        </div>
        <div className='footer'>
          <textarea value={message} rows={3} onChange={(e) => setMessage(e.target.value)} />
          <button disabled={isLoading} onClick={handleSend}>
            <Iconify icon={'streamline:send-email'} />
          </button>
        </div> */}
        <ChatView />
      </div>

      <Box
        component={m.div}
        animate={{
          rotate: [0, chatopen ? 0 : 360],
        }}
        transition={{
          duration: 12,
          ease: 'linear',
          repeat: Infinity,
        }}
      >
        <Tooltip title='AI Copilot'>
          <IconButton
            component={m.button}
            whileTap='tap'
            whileHover='hover'
            variants={varHover(1.05)}
            aria-label='settings'
            onClick={toggle}
            sx={{
              width: 40,
              height: 40,
            }}
          >
            <Iconify icon='mingcute:ai-fill' width={24} />
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  )
}

export default PopChat
