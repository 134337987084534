export const transformToFormValues = (values?: any) => {
  return {
    CreateNewAccountNumber: values?.IsCreateNewAccountNumber,
    TransferSalesperson: values?.IsTransferSalesperson,
    TransferFIManager: values?.IsTransferFiManager,
    TransferManager: values?.IsTransferManager,
    TranferCollector: values?.IsTransferCollector,
    LeaseType: values?.LeaseType,
  }
}

export const transformToRequestValues = (values?: any) => {
  return {
    isCreateNewAccountNumber: values.CreateNewAccountNumber,
    isTransferSalesperson: values.TransferSalesperson,
    isTransferFiManager: values.TransferFIManager,
    isTransferCollector: values.TranferCollector,
    isTransferManager: values.TransferManager,
    leaseType: values.LeaseType,
  }
}
