import { baseApi } from '../baseApi'
import { FetchTemplateDealApiArg, FetchTemplateLeadApiArg, TemplateApiArg, TemplateApiResponse } from './TemplateApiTypes'


const TemplateApi = baseApi.injectEndpoints({
  endpoints: (build) => ({

    searchTemplate: build.mutation<TemplateApiResponse, TemplateApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/search/`,
        method: 'POST',
        body: queryArg.data,
      }),
    }),

    fetchTemplateLead: build.mutation<TemplateApiResponse, FetchTemplateLeadApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/credit_app/${queryArg.sms_template_id}/`,
        method: 'POST',
        body: queryArg.data,
      }),
    }),

    fetchTemplateDeal: build.mutation<TemplateApiResponse, FetchTemplateDealApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/deal/${queryArg.sms_template_id}/`,
        method: 'POST',
        body: queryArg.data,
      }),
    }),
 
  }),
})

export const {
  useSearchTemplateMutation,
  useFetchTemplateLeadMutation,
  useFetchTemplateDealMutation,
} = TemplateApi
