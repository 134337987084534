import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/store'
import {
  useLazyAddDealTradeQuery,
  useLazyEditDealTradeQuery,
  useLazyGetCarQuery,
  useGetContactMutation,
  useLazyGetDealTradeDataQuery,
} from '../../../../../../../services'
import {
  transformToRequestValues,
  transformToFormValues,
} from '../../../../../../Deals/Trade/components/DealsTradeAddEdit/subpages/DealTradeStructure/transform'
import { UspDealTradeAddEdit } from '../../../../../../../models'
import { enqueueNotification } from '../../../../../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../../../../../constants'
import validateFormValues from '../../../../../../../utils/validateForm'
import { dealTradeAddEditValidation } from '../../../../../../../validation/dealTradeAddEditValidation'
import TradeStructure from './TradeStructure'
import { Form } from 'react-final-form'
import { Box, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { applyPhoneMask } from '../../../../../../../utils/general'
import { CustomCircular } from '../../../../../../../ui'

interface Props {}

const TradeEdit = ({}: Props) => {
  const { dealID, tradeID } = useParams()
  const params = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const discrepancy = useSelector((state: RootState) => state.dealsTradeData.discrepancy)
  const [initFormValues, setInitFormValues] = useState<any>({})
  const [addDealTrade, { isLoading: isLoadingAdd, isSuccess: dealTradeDataIsSuccess }] = useLazyAddDealTradeQuery()
  const [editDealTrade, { isLoading: isLoadingEdit, isSuccess: isSuccessDealTrade }] = useLazyEditDealTradeQuery()
  const [getDealTrade, { data, isSuccess }] = useLazyGetDealTradeDataQuery()
  const [getCar, { data: getCarData, isSuccess: getCarDataIsSuccess }] = useLazyGetCarQuery()
  const [getContacts, { data: getContactsData, isSuccess: getContactsIsSuccess }] = useGetContactMutation()

  useEffect(() => {
    if (tradeID) {
      getDealTrade({ dealId: tradeID as string })
    }
  }, [tradeID, getDealTrade])

  useEffect(() => {
    if (data) {
      getCar({ carId: data?.CarID as any })
      getContacts({ contactId: data?.LienholderContactID as any })
    }
  }, [data, getCar, getContacts])

  useEffect(() => {
    setInitFormValues(transformToFormValues(data, getCarData, getContactsData, params, discrepancy))
  }, [isSuccess, getCarDataIsSuccess, getContactsIsSuccess, data, getCarData, getContactsData, params, discrepancy])

  useEffect(() => {
    if (dealTradeDataIsSuccess || isSuccessDealTrade) {
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(appRoute.AccountsDeal.replace(':dealID', dealID as string), { state: { tabValue: 'trades' } })
    }
  }, [dealTradeDataIsSuccess, isSuccessDealTrade, data])

  const handleSubmit = (values: any) => {
    if (tradeID) {
      editDealTrade({
        dealId: dealID as string,
        tradeId: tradeID as string,
        uspDealTradeAddEdit: transformToRequestValues(values as UspDealTradeAddEdit, data, getContactsData),
      })
    } else {
      addDealTrade({
        uspDealTradeAddEdit: transformToRequestValues(values as UspDealTradeAddEdit),
        dealId: dealID as string,
      })
    }
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={10} spacing={2}>
          <Form
            onSubmit={handleSubmit}
            initialValues={initFormValues}
            validate={validateFormValues(dealTradeAddEditValidation(discrepancy, data, tradeID))}
          >
            {({ handleSubmit }: any) => (
              <>
                <Box display={'flex'}>
                  <CustomBreadcrumbs
                    heading='Account'
                    links={[
                      { name: 'List', href: '/deals' },
                      {
                        name: `${getCarData?.CarDescription} | ${`VIN ${getCarData?.VIN}`} | ${`Stock # ${getCarData?.StockNumber}`}`,
                      },
                    ]}
                    sx={{
                      mb: { xs: 3, md: 5 },
                    }}
                  />
                  {/* <Label color={creditAppData?.LeadStatusColor as LabelColor} sx={{ ml: 2, alignSelf: 'center' }}>
                    {creditAppData?.LeadStatus}
                  </Label> */}
                </Box>
                <TradeStructure onSubmit={handleSubmit} />
              </>
            )}
          </Form>
        </Grid>
      </Grid>
      <CustomCircular open={isLoadingAdd || isLoadingEdit} />
    </Container>
  )
}

export default TradeEdit
