import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Header from '../components/Header/Header'
import { useSearchSideNoteMutation } from '../../../services'
import { UspConfigurationSideNoteViewResult } from '../../../models'
import PaymentSideNoteTable from './components/PaymentSideNoteTable'
import AddEditPaymentSideNoteModal from './components/AddEditPaymentSideNoteModal'
import { Button, Card, Container, Grid } from '@mui/material'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Iconify from '../../../components/iconify'

const SettingsPaymentSideNote = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchSideNote, { data, isLoading }] = useSearchSideNoteMutation()
  const [sideNoteData, setSideNoteData] = useState<UspConfigurationSideNoteViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchSideNote({
      simpleSearch: {
        searchString: values?.searhString,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setSideNoteData(data)
    }
  }, [data])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='Side Notes'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <>
            <Button onClick={handleOpenModal} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
              Add new
            </Button>
          </>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ searhString: null, isIncludeInactive: false }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <Header submit={submit} title='Side Notes' handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <PaymentSideNoteTable
                      loading={isLoading}
                      data={sideNoteData}
                      setData={setSideNoteData}
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditPaymentSideNoteModal
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspConfigurationSideNoteViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default SettingsPaymentSideNote
