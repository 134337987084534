import React from 'react'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'firstNameWrap'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.firstNameWrap}`]: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

const ReceivedForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const states = useSelector(getStates)
  const carTitleType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarTitleType'))

  return (
    <Grid container item xs={12} spacing={2} p={3}>
      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='TitleReceived'
          label='Title Received?'
          options={[
            { key: true, value: 'Yes' },
            { key: false, value: 'No' },
          ]}
          optionKey='key'
          optionValue='value'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <DateField disabled={disableFields} onDropdownFormChange={onDropdownFormChange} name='DateReceived' label='Date Received' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <DropdownFieldSearch
          disabled={disableFields}
          name='TitleState'
          label='Title State'
          options={states}
          optionKey='State'
          optionValue='State'
          autoComplete='off'
          onChange={() => {
            onDropdownFormChange()
          }}
        />
      </Grid>
      {carTitleType && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='TitleType'
            label='Title Type'
            options={carTitleType}
            optionKey='ID'
            optionValue='TitleType'
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

export default ReceivedForm
