import { Button, CardHeader, generateUtilityClasses, Grid, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { DateField, DropdownCheckboxes } from '../../../../../ui'
import { Form } from 'react-final-form'
import validateForm from '../../../../../utils/validateForm'
import { AddCriteriaValidation } from '../../../../../validation/generateFinancialsCriteriaValidation'
import { selectDropdownByNameActiveOnly, useAddAccountingChartOfAccountMutation } from '../../../../../services'
import { transformToRequestValues } from './transform'
import { UspAccountingChartOfAccountView } from '../../../../../models'
import { getCurrentDate } from '../../../../../utils/general'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { setFinancialsIncomeStatement } from '../../../../../redux/slices/financialsIncomeStatementSlice'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('form', ['root', 'title', 'row', 'btnRow'])

const StyledCard = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: '27px',
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.btnRow}`]: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

export type TInitValues = {
  financialStatement: string
  ClosedPeriod: boolean
  criteriaStartDate: string | null
  criteriaEndDate: string | null
  ExcludeAccountsWithNoBalance: boolean
  GroupByAccounts: boolean
  IncludeInactiveAccounts: boolean
}

const Criteria = () => {
  const [addFinancialsCriteria, { data, isSuccess, isError }] = useAddAccountingChartOfAccountMutation()
  const [initFormValues, setInitFormValues] = useState<TInitValues>({
    financialStatement: 'IS',
    ClosedPeriod: false,
    criteriaStartDate: null,
    criteriaEndDate: getCurrentDate(),
    ExcludeAccountsWithNoBalance: false,
    GroupByAccounts: false,
    IncludeInactiveAccounts: false,
  })
  const [financialStatement, setFinancialStatement] = useState<string | undefined>()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  const handleSubmit = (values: UspAccountingChartOfAccountView) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))

    addFinancialsCriteria({
      uspAccountingChartOfAccountingView: transformToRequestValues(values, jsonBranchList) as UspAccountingChartOfAccountView,
    })

    setFinancialStatement(values.financialStatement)
  }

  const financialStatementList = useMemo(
    () => [
      { key: 'IS', value: 'Income Statement', link: appRoute.AccountingFinancialsIncomeStatement },
      { key: 'BS', value: 'Balance Sheet', link: appRoute.AccountingFinancialsBalanceSheet },
      { key: 'TB', value: 'Trial Balance', link: appRoute.AccountingFinancialsTrialBalance },
    ],
    [],
  )

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      dispatch(setFinancialsIncomeStatement(data))

      const financialStatementLink = financialStatementList.find((statement) => statement.key === financialStatement)?.link
      navigate(financialStatementLink || appRoute.AccountingFinancialsIncomeStatement)
    }
  }, [isSuccess, isError])

  return (
    <Form onSubmit={handleSubmit} initialValues={initFormValues} validate={validateForm(AddCriteriaValidation)}>
      {({ handleSubmit, values }: any) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} p={3}>
            {branch && (
              <Grid item xs={12} md={6}>
                <DropdownCheckboxes
                  name='branch'
                  label='Branch'
                  labelId='BranchId'
                  fullWidth
                  options={branch}
                  optionKey='BranchID'
                  optionValue='Branch'
                />
              </Grid>
            )}

            <Grid item xs={12} md={6}>
              <DropdownFieldSearch
                name='financialStatement'
                label='Financial Statement'
                options={financialStatementList}
                autoComplete='off'
                optionKey='key'
                optionValue='value'
                initialValue='IS'
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownFieldSearch
                name='ClosedPeriod'
                label='Closed Period?'
                options={[
                  { key: true, value: 'Yes' },
                  { key: false, value: 'No' },
                ]}
                autoComplete='off'
                optionKey='key'
                optionValue='value'
                initialValue={false}
              />
            </Grid>
            {values?.financialStatement !== 'BS' && !values?.ClosedPeriod && (
              <Grid item xs={12} md={6}>
                <DateField name='criteriaStartDate' label='Start Date' disableFuture fullWidth />
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <DateField name='criteriaEndDate' label='End Date' fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownFieldSearch
                name='ExcludeAccountsWithNoBalance'
                label='Exclude Accounts With No Balance?'
                options={[
                  { key: true, value: 'Yes' },
                  { key: false, value: 'No' },
                ]}
                autoComplete='off'
                optionKey='key'
                optionValue='value'
                initialValue={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownFieldSearch
                name='GroupByAccounts'
                label='Group By Accounts?'
                options={[
                  { key: true, value: 'Yes' },
                  { key: false, value: 'No' },
                ]}
                autoComplete='off'
                optionKey='key'
                optionValue='value'
                initialValue={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DropdownFieldSearch
                name='IncludeInactiveAccounts'
                label='Include Inactive accounts?'
                options={[
                  { key: true, value: 'Yes' },
                  { key: false, value: 'No' },
                ]}
                autoComplete='off'
                optionKey='key'
                optionValue='value'
                initialValue={false}
              />
            </Grid>
            <Grid container item justifyContent={'flex-end'}>
              <Button variant='contained' type='submit'>
                Generate
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Form>
  )
}

export default Criteria
