import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import { useGetDealProfitMutation } from '../../../../../../services'
import { parseToNumber } from '../../../../../../utils/general'
import removeMask from '../../../../../../utils/removeMask'
import { useFormState } from 'react-final-form'
import { GetDealProfitApiResponse, TFees_List, TProducts_List, UspGetDataCar, UspGetDataDealEntryResult } from '../../../../../../models'
import { useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { useState } from 'react'
import RecapPopover from './RecapPopover'

interface Props {
  carData?: UspGetDataCar
  dealData?: UspGetDataDealEntryResult
  //   stateDealSettingsData: UspGetDataStateDealSettingsResult
  //   disableFields: boolean
}

const Recap = ({ carData, dealData }: Props) => {
  const [getDealProfit, { data: dealProfitData, isLoading: dealProfitIsLoading }] = useGetDealProfitMutation()
  const { values } = useFormState()
  const { dealID } = useParams()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const generateProductListFromValues = (values: any): TProducts_List[] => {
    const productsList: TProducts_List[] = []

    // loop through the keys in the values object form
    for (const key in values) {
      if (key.startsWith('ProductFieldIsActive')) {
        const index = key?.replace('ProductFieldIsActive', '')

        // check if the ProductFieldIsActive is true
        if (values[`ProductFieldIsActive${index}`] === true) {
          productsList?.push({
            ProductLabel: values[`ProductFieldProfitLabel${index}`] as string,
            Amount: removeMask(values[`ProductFieldValue${index}`]) as string,
            IsTaxable: values[`ProductIsTaxableValue${index}`] as boolean,
            CostRate: values[`ProductFieldProfitCostRate${index}`] as number,
            CostAmount: removeMask(values[`ProductFieldProfitCostAmount${index}`]) as string,
            ProfitType: values[`ProductFieldProfitType${index}`] as string,
          })
        }
      }
    }

    return productsList
  }
  const productsList = generateProductListFromValues(values)

  const generateFeesListFromValues = (values: any): TFees_List[] => {
    const feesList: TFees_List[] = []

    // loop through the keys in the values object form
    for (const key in values) {
      if (key.startsWith('FeeFieldIsActive')) {
        const index = key.replace('FeeFieldIsActive', '')

        // check if the FeeFieldIsActive is true
        if (values[`FeeFieldIsActive${index}`] === true) {
          feesList.push({
            FeeLabel: values[`FeeFieldFeeLabel${index}`] as string,
            Amount: removeMask(values[`FeeFieldValue${index}`]) as string,
            IsTaxable: values[`FeeIsTaxableValue${index}`] as boolean,
            ProfitType: values[`FeeFieldProfitType${index}`] as string,
          })
        }
      }
    }

    return feesList
  }
  const feesList = generateFeesListFromValues(values)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    getDealProfit({
      body: [
        {
          Sales_Price: parseToNumber(removeMask(values?.SalesPrice) as string),
          Trade_Allowance: parseToNumber(removeMask(values?.TradeAllowance) as string),
          Trade_Acv: parseToNumber(removeMask(values?.TradeACV) as string),
          Sales_Discount: parseToNumber(removeMask(values?.DiscountAmount) as string),
          Spread_Amount: parseToNumber(removeMask(values?.SpreadAmount) as string),
          Trade_Lien: parseToNumber(removeMask(values?.TradeLien) as string),
          Inventory_Cost: parseToNumber(removeMask(carData?.TotalCost?.toString()) as string),
          Sales_Commission: dealID !== 'add' ? parseToNumber(dealData?.SalesCommission?.toString() as string) : 0,
          Referral_Fee: dealID !== 'add' ? parseToNumber(dealData?.ReferralFee?.toString() as string) : 0,
          Products_List: productsList,
          Fees_List: feesList,
        },
      ],
    })
    setAnchorEl(event?.currentTarget)
  }

  return (
    <>
      <Button variant='outlined' onClick={handleClick} startIcon={<RemoveRedEyeOutlinedIcon />}>
        Deal Recap
      </Button>
      <RecapPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} data={dealProfitData as GetDealProfitApiResponse} isLoading={dealProfitIsLoading} />
    </>
  )
}

export default Recap
