import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { generateUtilityClasses, Grid, Typography } from '@mui/material'
import { useFormState } from 'react-final-form'
import { CheckboxField } from '../../../../../ui'

interface Props {
  submit: (...args: any) => any
}

export const ExpenseHistoryHeader = ({ submit }: Props) => {
  const { values } = useFormState()

  useEffect(() => {
    submit(values)
  }, [values])

  return (
    <Grid container p={3}>
      <Grid item>
        <CheckboxField name='isIncludeAllHistoryByCar' label='Include History By VIN' />
      </Grid>
      <Grid item>
        <CheckboxField name='isIncludeReversals' label='Include Reversals' />
      </Grid>
    </Grid>
  )
}

export default ExpenseHistoryHeader
