import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import { DateField, TextField } from '../../../../../../ui'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { useForm, useFormState } from 'react-final-form'
import { UspListAccountingCashAccountsResult } from '../../../../../../models'
import { useSelector } from 'react-redux'
import { ParsedDropdownResponse, selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  cashAccountsData: UspListAccountingCashAccountsResult[]
  tableData: any
  stage: string
  checkID: number
  billPayType: ParsedDropdownResponse | undefined
}

const SettingsFormDetails = ({ cashAccountsData, tableData, stage, checkID, billPayType }: Props) => {
  const { values } = useFormState()
  const form = useForm()
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  useEffect(() => {
    if (stage === 'paymentDetails') {
      // Check if all records in tableData have the same BranchID and if they do than set the default value for BankBranch
      const uniqueBranchIDs = new Set(tableData.map((record: any) => record.BranchID))
      if (uniqueBranchIDs.size === 1 && !values?.BankBranch) {
        form.change('BankBranch', Array.from(uniqueBranchIDs)[0])
      }
    }

    if (values?.BankAccount !== null && values?.BankBranch !== null) {
      const selectedBankAccount = cashAccountsData?.find((account) => account?.ID === values?.BankAccount)
      if (selectedBankAccount) {
        const { NextCheckNumberData } = selectedBankAccount
        const matchingBranchData = NextCheckNumberData?.find((branch) => branch?.BranchID === values?.BankBranch)
        if (matchingBranchData) {
          form.change('CheckNumber', matchingBranchData.NextCheckNumber)
        }
      }
    }
  }, [branch, billPayType, cashAccountsData, values.BankBranch, tableData, stage])

  return (
    <>
      <CardHeader title='Settings' />
      <Grid container xs={12} spacing={2} p={3}>
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='BankAccount'
            label='Bank Account (credit)'
            options={cashAccountsData}
            optionKey={'ID'}
            optionValue={'ChartOfAccount'}
          />
        </Grid>
        {branch && (
          <Grid item xs={6}>
            <DropdownFieldSearch name='BankBranch' label='Bank Branch' options={branch} optionKey={'BranchID'} optionValue={'Branch'} />
          </Grid>
        )}

        {billPayType && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='BillPayType'
              label='Bill Pay Type'
              options={billPayType?.ReturnData}
              optionKey={'ID'}
              optionValue={'BillPayType'}
            />
          </Grid>
        )}

        {values?.BillPayType === checkID && (
          <Grid item xs={6}>
            <TextField name='CheckNumber' label='Check Number' fullWidth />
          </Grid>
        )}

        <Grid item xs={6}>
          <DateField name='CheckDate' label='Check Date' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField multiline name='Memo' label='Memo' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsFormDetails
