import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { AccountsOverviewResult } from '../../models'
import { upsert } from '../../utils/general'

type AccountsState = {
  accounts?: Array<AccountsOverviewResult>
  accountsRepoChargeOff?: boolean | undefined
  accountsRepoReverseChargeOff?: boolean | undefined
}

export const slice = createSlice({
  name: 'accounts',
  initialState: {
    accounts: [],
    accountsRepoChargeOff: undefined,
    accountsRepoReverseChargeOff: undefined,
  } as AccountsState,
  reducers: {
    setAccounts: (state, { payload }) => {
      upsert(state.accounts, payload, 'DealID')
    },
    setRepoChargeOff: (state, { payload }) => {
      state.accountsRepoChargeOff = payload
    },
    setRepoReverseChargeOff: (state, { payload }) => {
      state.accountsRepoReverseChargeOff = payload
    },
  },
})
export const getAccounts =
  (dealId: string | undefined): any =>
  (state: RootState) => {
    if (dealId) {
      return state?.accounts?.accounts?.find((accounts: AccountsOverviewResult) => String(accounts?.DealID) === String(dealId))
    }
    return undefined
  }

export const getRepoChargeOff = (state: RootState) => state?.accounts?.accountsRepoChargeOff
export const getRepoReverseChargeOff = (state: RootState) => state?.accounts?.accountsRepoReverseChargeOff
export const { setAccounts, setRepoChargeOff, setRepoReverseChargeOff } = slice.actions

export default slice.reducer
