import { baseApi } from './baseApi'
import type {
  AddBranchApiResponse,
  AddBranchApiArg,
  SearchBranchApiResponse,
  SearchBranchApiArg,
  EditBranchApiResponse,
  EditBranchApiArg,
  DeleteBranchApiResponse,
  DeleteBranchApiArg,
  AddCarFinderApiResponse,
  AddCarFinderApiArg,
  SearchCarFinderApiResponse,
  SearchCarFinderApiArg,
  EditCarFinderApiResponse,
  EditCarFinderApiArg,
  DeleteCarFinderApiResponse,
  DeleteCarFinderApiArg,
  AddCarApiResponse,
  AddCarApiArg,
  SearchCarApiResponse,
  SearchCarApiArg,
  CarsVinReadApiResponse,
  CarsVinReadApiArg,
  GetCarApiResponse,
  GetCarApiArg,
  EditCarApiResponse,
  EditCarApiArg,
  GetCreditAppNoteApiResponse,
  GetCreditAppNoteApiArg,
  GetCarCostApiResponse,
  GetCarCostApiArg,
  AddCarCostApiResponse,
  AddCarCostApiArg,
  CarExpenseGetApiResponse,
  CarExpenseGetApiArg,
  CarNoteGetApiResponse,
  CarNoteGetApiArg,
  AddCarNoteApiResponse,
  AddCarNoteApiArg,
  UserLoggedInApiResponse,
  UserLoggedInApiArg,
  CompanyTypesApiResponse,
  CompanyTypesApiArg,
  AddCollectionApiResponse,
  AddCollectionApiArg,
  AddCollectionResultApiResponse,
  AddCollectionResultApiArg,
  SearchCollectionResultApiResponse,
  SearchCollectionResultApiArg,
  EditCollectionResultApiResponse,
  EditCollectionResultApiArg,
  DeleteCollectionResultApiResponse,
  DeleteCollectionResultApiArg,
  SearchCollectionApiResponse,
  SearchCollectionApiArg,
  EditCollectionApiResponse,
  EditCollectionApiArg,
  DeleteCollectionApiResponse,
  DeleteCollectionApiArg,
  AddCompanyApiResponse,
  AddCompanyApiArg,
  SearchCompanyApiResponse,
  SearchCompanyApiArg,
  EditCompanyApiResponse,
  EditCompanyApiArg,
  DeleteCompanyApiResponse,
  DeleteCompanyApiArg,
  SearchConfigurationApiResponse,
  SearchConfigurationApiArg,
  EditConfigurationApiResponse,
  EditConfigurationApiArg,
  AddContactApiResponse,
  AddContactApiArg,
  AddContactAddressApiResponse,
  AddContactAddressApiArg,
  EditContactAddressApiResponse,
  EditContactAddressApiArg,
  AddEditContactsEmployerApiResponse,
  AddEditContactsEmployerApiArg,
  EditContactsEmployerApiResponse,
  EditContactsEmployerApiArg,
  AddEditContactsIncomeHistoryApiResponse,
  AddEditContactsIncomeHistoryApiArg,
  EditContactsIncomeHistoryApiResponse,
  EditContactsIncomeHistoryApiArg,
  AddContactFullApiArg,
  EditContactFullApiArg,
  AddEditContactFullApiResponse,
  UpdateContactPhoneApiResponse,
  UpdateContactPhoneApiArg,
  ContactsReferenceGetApiResponse,
  ContactsReferenceGetApiArg,
  SearchContactApiResponse,
  SearchContactApiArg,
  GetContactApiResponse,
  GetContactApiArg,
  EditContactApiResponse,
  EditContactApiArg,
  GetContactCreditApiResponse,
  GetContactCreditApiArg,
  AddCreditAppApiResponse,
  AddCreditAppApiArg,
  DeleteCreditAppNoteApiResponse,
  DeleteCreditAppNoteApiArg,
  AddCreditAppNoteApiResponse,
  AddCreditAppNoteApiArg,
  SearchCreditAppApiResponse,
  SearchCreditAppApiArg,
  AddCreditAppStatusApiResponse,
  AddCreditAppStatusApiArg,
  SearchCreditAppStatusApiResponse,
  SearchCreditAppStatusApiArg,
  EditDealStatusCreditApiResponse,
  EditDealStatusCreditApiArg,
  DeleteCreditAppStatusApiResponse,
  DeleteCreditAppStatusApiArg,
  GetCreditAppStipulationApiResponse,
  GetCreditAppStipulationApiArg,
  AddCreditAppStipulationApiResponse,
  AddCreditAppStipulationApiArg,
  EditCreditAppStipulationApiResponse,
  EditCreditAppStipulationApiArg,
  DeleteCreditAppStipulationApiResponse,
  DeleteCreditAppStipulationApiArg,
  GetCreditAppApiResponse,
  GetCreditAppApiArg,
  EditCreditAppApiResponse,
  EditCreditAppApiArg,
  AddDealApiResponse,
  AddDealApiArg,
  GetCalculationForDealApiResponse,
  GetCalculationForDealApiArg,
  GetDealCommissionApiResponse,
  GetDealCommissionApiArg,
  AddDealCommissionApiResponse,
  AddDealCommissionApiArg,
  EditDealCommissionApiResponse,
  EditDealCommissionApiArg,
  DeleteDealCommissionApiResponse,
  DeleteDealCommissionApiArg,
  ModifyDealFinalizeUnwindSalesApiResponse,
  ModifyDealFinalizeUnwindSalesApiArg,
  GetDealInsuranceApiResponse,
  GetDealInsuranceApiArg,
  GetMaturityDateForDealApiResponse,
  GetMaturityDateForDealApiArg,
  GetDealProductApiResponse,
  GetDealProductApiArg,
  EditDealProductApiResponse,
  EditDealProductApiArg,
  GetDealProfitApiResponse,
  GetDealProfitApiArg,
  GetDealProfitDataApiResponse,
  GetDealProfitDataApiArg,
  GetDealReferralsApiResponse,
  GetDealReferralsApiArg,
  AddDealReferralApiResponse,
  AddDealReferralApiArg,
  EditDealReferralApiResponse,
  EditDealReferralApiArg,
  DeleteDealReferralApiResponse,
  DeleteDealReferralApiArg,
  SearchDealsApiResponse,
  SearchDealsApiArg,
  EditDealSettingApiResponse,
  EditDealSettingApiArg,
  AddDealStatusApiResponse,
  AddDealStatusApiArg,
  SearchDealStatusApiResponse,
  SearchDealStatusApiArg,
  EditDealStatusApiResponse,
  EditDealStatusApiArg,
  DeleteDealStatusApiResponse,
  DeleteDealStatusApiArg,
  GetTaskDealApiResponse,
  GetTaskDealApiArg,
  GetTaxesApiResponse,
  GetTaxesApiArg,
  GetDealTradeApiResponse,
  GetDealTradeApiArg,
  GetDealApiResponse,
  GetDealApiArg,
  EditDealApiResponse,
  EditDealApiArg,
  DeleteDealApiResponse,
  DeleteDealApiArg,
  CarsDropdownsApiResponse,
  CarsDropdownsApiArg,
  CreditAppDropdownsApiResponse,
  CreditAppDropdownsApiArg,
  GetUserPermissionsDropdownApiResponse,
  GetUserPermissionsDropdownApiArg,
  GetAccountingLedgerApiResponse,
  GetAccountingLedgerApiArg,
  ForgotPasswordCreateApiResponse,
  ForgotPasswordCreateApiArg,
  ForgotPasswordChangePasswordCreateApiResponse,
  ForgotPasswordChangePasswordCreateApiArg,
  ForgotPasswordResendCreateApiResponse,
  ForgotPasswordResendCreateApiArg,
  AddInsuranceApiResponse,
  AddInsuranceApiArg,
  SearchInsuranceApiResponse,
  SearchInsuranceApiArg,
  EditInsuranceApiResponse,
  EditInsuranceApiArg,
  DeleteInsuranceApiResponse,
  DeleteInsuranceApiArg,
  UserInvitationsSentListApiResponse,
  UserInvitationsSentListApiArg,
  CreateInvitationApiResponse,
  CreateInvitationApiArg,
  GetInvitationRegistrationApiResponse,
  GetInvitationRegistrationApiArg,
  AcceptInvitationRegistrationApiResponse,
  AcceptInvitationRegistrationApiArg,
  AddIpAddressApiResponse,
  AddIpAddressApiArg,
  SearchIpAddressApiResponse,
  SearchIpAddressApiArg,
  EditIpAddressApiResponse,
  EditIpAddressApiArg,
  DeleteIpAddressApiResponse,
  DeleteIpAddressApiArg,
  SearchLenderApiResponse,
  SearchLenderApiArg,
  CarPackFeeChartOfAccountApiResponse,
  CarPackFeeChartOfAccountApiArg,
  CreditAppListActivityApiResponse,
  CreditAppListActivityApiArg,
  CreditAppListApplicantApiResponse,
  CreditAppListApplicantApiArg,
  CreditAppListResultApiResponse,
  CreditAppListResultApiArg,
  CreditAppListStipulationApiResponse,
  CreditAppListStipulationApiArg,
  GetListDealsCollectorApiArg,
  GetListDealsCollectorApiResponse,
  GetListDealApiArg,
  GetListDealStatusApiResponse,
  GetStateDealSettingsApiResponse,
  GetStateDealSettingsApiArg,
  GetStateDealCompanySettingsApiResponse,
  GetStateDealCompanySettingsApiArg,
  GetListBranchesApiResponse,
  GetListBranchesApiArg,
  GetPhonesTypesApiResponse,
  GetPhonesTypesApiArg,
  GetInfoByZipCodeApiResponse,
  GetInfoByZipCodeApiArg,
  LoginCreateApiResponse,
  LoginCreateApiArg,
  LoginRefreshCreateApiResponse,
  LoginRefreshCreateApiArg,
  LogoutCreateApiResponse,
  LogoutCreateApiArg,
  AddLotsApiResponse,
  AddLotsApiArg,
  SearchLotsApiResponse,
  SearchLotsApiArg,
  EditLotsApiResponse,
  EditLotsApiArg,
  DeleteLotsApiResponse,
  DeleteLotsApiArg,
  AddPaymentCheckApiResponse,
  AddPaymentCheckApiArg,
  SearchPaymentCheckApiResponse,
  SearchPaymentCheckApiArg,
  EditPaymentCheckApiResponse,
  EditPaymentCheckApiArg,
  DeletePaymentCheckApiResponse,
  DeletePaymentCheckApiArg,
  AddPaymentFormApiResponse,
  AddPaymentFormApiArg,
  SearchPaymentFormApiResponse,
  SearchPaymentFormApiArg,
  EditPaymentFormApiResponse,
  EditPaymentFormApiArg,
  DeletePaymentFormApiResponse,
  DeletePaymentFormApiArg,
  AddPaymentMethodApiResponse,
  AddPaymentMethodApiArg,
  SearchPaymentMethodApiResponse,
  SearchPaymentMethodApiArg,
  EditPaymentMethodApiResponse,
  EditPaymentMethodApiArg,
  DeletePaymentMethodApiResponse,
  DeletePaymentMethodApiArg,
  AddReferencesApiResponse,
  AddReferencesApiArg,
  GetReferencesApiResponse,
  GetReferencesApiArg,
  EditReferencesApiResponse,
  EditReferencesApiArg,
  DeleteReferencesApiResponse,
  DeleteReferencesApiArg,
  AddRepoTypeApiResponse,
  AddRepoTypeApiArg,
  SearchRepoTypeApiResponse,
  SearchRepoTypeApiArg,
  EditRepoTypeApiResponse,
  EditRepoTypeApiArg,
  DeleteRepoTypeApiResponse,
  DeleteRepoTypeApiArg,
  EditRfcApiResponse,
  EditRfcApiArg,
  SearchRfcApiResponse,
  SearchRfcApiArg,
  AddSideNoteApiResponse,
  AddSideNoteApiArg,
  SearchSideNoteApiResponse,
  SearchSideNoteApiArg,
  EditSideNoteApiResponse,
  EditSideNoteApiArg,
  DeleteSideNoteApiResponse,
  DeleteSideNoteApiArg,
  AddSmsTemplateApiResponse,
  AddSmsTemplateApiArg,
  AddSmsTemplateCategoryApiResponse,
  AddSmsTemplateCategoryApiArg,
  SearchSmsTemplateCategoryApiResponse,
  SearchSmsTemplateCategoryApiArg,
  EditSmsTemplateCategoryApiResponse,
  EditSmsTemplateCategoryApiArg,
  DeleteSmsTemplateCategoryApiResponse,
  DeleteSmsTemplateCategoryApiArg,
  SearchSmsTemplateApiResponse,
  SearchSmsTemplateApiArg,
  EditSmsTemplateApiResponse,
  EditSmsTemplateApiArg,
  DeleteSmsTemplateApiResponse,
  DeleteSmsTemplateApiArg,
  SearchStateApiResponse,
  SearchStateApiArg,
  EditStateApiResponse,
  EditStateApiArg,
  GetUserClientsApiResponse,
  GetUserClientsApiArg,
  GetMyProfileApiResponse,
  GetMyProfileApiArg,
  UpdateMyProfileApiResponse,
  UpdateMyProfileApiArg,
  UpdateMyPasswordApiResponse,
  UpdateMyPasswordApiArg,
  ConfirmMyEmailApiResponse,
  ConfirmMyEmailApiArg,
  UpdateMyEmailApiResponse,
  UpdateMyEmailApiArg,
  ResendMyEmailConfirmationApiResponse,
  ResendMyEmailConfirmationApiArg,
  AddUserRoleApiResponse,
  AddUserRoleApiArg,
  SearchUserRoleApiResponse,
  SearchUserRoleApiArg,
  GetUserRoleApiResponse,
  GetUserRoleApiArg,
  EditUserRoleApiResponse,
  EditUserRoleApiArg,
  DeleteUserRoleApiResponse,
  DeleteUserRoleApiArg,
  AddUserApiResponse,
  AddUserApiArg,
  SearchUserApiResponse,
  SearchUserApiArg,
  GetUserApiResponse,
  GetUserApiArg,
  EditUserApiResponse,
  EditUserApiArg,
  DeleteUserApiResponse,
  DeleteUserApiArg,
  AddVendorApiResponse,
  AddVendorApiArg,
  SearchVendorApiResponse,
  SearchVendorApiArg,
  EditVendorApiResponse,
  EditVendorApiArg,
  DeleteVendorApiResponse,
  DeleteVendorApiArg,
  DeleteCarNoteApiResponse,
  DeleteCarNoteApiArg,
  GetListFeeApiArg,
  GetListFeeApiResponse,
  GetListProductApiArg,
  GetListProductApiResponse,
  DeleteCreditAppApiResponse,
  DeleteCreditAppApiArg,
  AddAccountingChartOfAccountApiArg,
  AddAccountingChartOfAccountResponse,
  AddDealInsuranceApiResponse,
  AddDealInsuranceApiArg,
  GetAccountingBranchDropdownetApiResponse,
  GetAccountingBranchDropdownetApiArg,
  AddDealTradeApiArg,
  AddDealTradeApiResponse,
  DeleteDealTradeApiResponse,
  DeleteDealTradeApiArg,
  EditCarFloorplanApiResponse,
  EditCarFloorplanApiArg,
  EditCarNoteApiResponse,
  EditCarNoteApiArg,
  AddVendorListsApiResponse,
  AddVendorListsApiArg,
  GetDealReferralApiArg,
  GetDealReferralApiResponse,
  GetDealProductDataApiArg,
  GetDealProductDataApiResponse,
  GetDealReferralDataApiResponse,
  GetDealReferralDataApiArg,
  GetLenderApiArg,
  GetLenderApiResponse,
  AddLenderApiResponse,
  AddLenderApiArg,
  EditLenderApiResponse,
  EditLenderApiArg,
  DeleteLenderApiResponse,
  DeleteLenderApiArg,
  GetVendorListApiResponse,
  GetVendorListApiArg,
  GetVendorTypeApiResponse,
  GetVendorTypeApiArg,
  GetVendorIRSApiResponse,
  GetVendorIRSApiArg,
  GetLenderTypeApiResponse,
  GetLenderTypeApiArg,
  AddAccountingChartApAccountsApiArg,
  AddAccountingChartApAccountsResponse,
  AddAccountingChartOfAccountCurrentResponse,
  AddAccountingChartOfAccountCurrentApiArg,
  GetActiveCompanyTypesApiArg,
  GetActiveCompanyTypesApiResponse,
  SearchAccountApiArg,
  SearchAccountApiResponse,
  GetAccountOverviewApiResponse,
  GetAccountOverviewApiArg,
  GetAccountPayHistoryApiResponse,
  GetAccountPayHistoryApiArg,
  GetAccountDueAmountApiResponse,
  GetAccountDueAmountApiArg,
  GetAccountNotesApiResponse,
  GetAccountNotesApiArg,
  GetAccountBankruptcyApiResponse,
  GetAccountBankruptcyApiArg,
  EditAccountBankruptcyApiArg,
  EditAccountBankruptcyApiResponse,
  GetAccountTitleApiArg,
  GetAccountTitleApiResponse,
  EditAccountTitleApiArg,
  EditAccountTitleApiResponse,
  GetAccountRepoApiArg,
  GetAccountRepoApiResponse,
  GetDealAmortizationApiArg,
  GetDealAmortizationApiResponse,
  AccountsSettingsDealApiResponse,
  AccountsSettingsDealApiArg,
  AccountsChargeOffApiResponse,
  AccountsChargeOffApiArg,
  AddNotesApiArg,
  AddNotesApiResponse,
  GetAccountNotesHistoryApiResponse,
  GetAccountNotesHistoryApiArg,
  NotesPinApiResponse,
  NotePinApiArg,
  PaymentTransactionApiResponse,
  PaymentTransactionApiArg,
  AddAccountingChartOfAccountItemApiArg,
  AddAccountingChartOfAccountItemResponse,
  AccountSubTypeApiResponse,
  AccountSubTypeApiArg,
  DeleteAccountingChartOfAccountResponse,
  DeleteAccountingChartOfAccountApiArg,
  AccountSideNoteApiResponse,
  AccountSideNoteApiArg,
  AddAccountingChartOfAccountCurrentDataResponse,
  AddAccountingChartOfAccountCurrentDataApiArg,
  AccountingBankDepositApiArg,
  AccountingBankDepositApiResponse,
  EditAccountingChartOfAccountResponse,
  EditAccountingChartOfAccountApiArg,
  AccountingBillApiResponse,
  AccountingBillApiArg,
  AccountingBillApproveToPayApiResponse,
  AccountingBillApproveToPayApiArg,
  AccountingBillDeleteApiResponse,
  AccountingBillDeleteApiArg,
  GetAccountingCashAccountsDataResponse,
  GetAccountingCashAccountsApiArg,
  GetAccountingUnpaidVendorsApiResponse,
  GetAccountingUnpaidVendorsApiArg,
  GetAccountingUnpaidBillsApiResponse,
  GetAccountingUnpaidBillsApiArg,
  AccountingCheckBillApiResponse,
  AccountingCheckBillApiArg,
  AccountingCheckSearchApiResponse,
  AccountingCheckSearchApiArg,
  AccountingVoidCheckApiResponse,
  AccountingVoidCheckApiArg,
  ConfigurationDataApiResponse,
  ConfigurationDataApiArg,
  ConfigurationEditDataApiResponse,
  ConfigurationEditDataApiArg,
  CarExpenseTypeSearchApiArg,
  CarExpenseTypeSearchApiResponse,
  CarExpenseTypeDeleteApiResponse,
  CarExpenseTypeDeleteApiArg,
  GetDealLenderListApiArg,
  GetDealLenderListApiResponse,
  SearchAdvertisementsApiResponse,
  SearchAdvertisementsApiArg,
  DeleteAdvertisementsApiResponse,
  DeleteAdvertisementsApiArg,
  SearchtSipulationsApiResponse,
  SearchStipulationsApiArg,
  DeleteStipulationsApiResponse,
  DeleteStipulationsApiArg,
  SearchVendorsApiResponse,
  SearchVendorsApiArg,
  SearchAInterestLevelsApiResponse,
  SearchAInterestLevelsApiArg,
  DeleteInterestLevelApiResponse,
  DeleteInterestLevelApiArg,
  SearchReferencesRelationshipsTypesApiResponse,
  SearchReferencesRelationshipsTypesApiArg,
  DeleteReferenceRelationshipApiResponse,
  DeleteReferenceRelationshipApiArg,
  SearchCarPurchaseTypesApiResponse,
  SearchCarPurchaseTypesApiArg,
  DeleteCarPurchaseTypeApiArg,
  DeleteCarPurchaseTypeApiResponse,
  SearchCarMakesApiResponse,
  SearchCarMakesApiArg,
  DeleteCarMakeApiArg,
  DeleteCarMakeApiResponse,
  SearchCarDrivetrainApiArg,
  SearchCarDrivetrainApiResponse,
  DeleteCarDrivetrainApiArg,
  DeleteCarDrivetrainApiResponse,
  SearchFuelTypesApiResponse,
  SearchFuelTypesApiArg,
  DeleteCarFuelTypeApiResponse,
  DeleteCarFuelTypeApiArg,
  SearchCarTitleTypeApiArg,
  SearchCarTitleTypeApiResponse,
  DeleteTitleTypeApiResponse,
  DeleteTitleTypeApiArg,
  SearchCarConditionsApiResponse,
  SearchCarConditionsApiArg,
  DeleteCarConditionApiResponse,
  DeleteCarConditionApiArg,
  SearchCarBodyTypeApiResponse,
  SearchCarBodyTypeApiArg,
  DeleteCarBodyTypeApiResponse,
  DeleteCarBodyTypeApiArg,
  AddEditCarPurchaseTypeApiArg,
  AddEditCarPurchaseTypeApiResponse,
  GetCarPurchaseTypeDataApiResponse,
  GetCarPurchaseTypeDataApiArg,
  AddEditCarFuelTypeApiResponse,
  AddEditCarFuelTypeApiArg,
  GetCarFuelTypeDataApiResponse,
  GetCarFuelTypeDataApiArg,
  StipulationAddEditApiArg,
  StipulationAddEditApiResponse,
  StipulationDataApiResponse,
  StipulationDataApiArg,
  ReferenceRelationshipTypeAddEditApiResponse,
  ReferenceRelationshipTypeAddEditApiArg,
  ReferenceRelationshipDataApiArg,
  ReferenceRelationshipDataApiResponse,
  CarMakeAddEditApiResponse,
  CarMakeAddEditApiArg,
  CarMakeDataApiArg,
  CarMakeDataApiResponse,
  InterestLevelAddEditApiResponse,
  InterestLevelAddEditApiArg,
  InterestLevelDataApiResponse,
  InterestLevelDataApiArg,
  VendorTypeAddEditApiResponse,
  VendorTypeAddEditApiArg,
  VendorTypeDataApiArg,
  VendorTypeDataApiResponse,
  CarDrivetrainAddEditApiResponse,
  CarDrivetrainAddEditApiArg,
  CarDrivetrainApiResponse,
  CarDrivetrainApiArg,
  CarTitleTypeApiResponse,
  CarTitleTypeApiArg,
  CarTitleTypeAddEditApiResponse,
  CarTitleTypeAddEditApiArg,
  GetCarConditionsApiResponse,
  GetCarConditionsApiArg,
  CarConditionAddEditApiResponse,
  CarConditionAddEditApiArg,
  CarBodyTypeApiResponse,
  CarBodyTypeApiArg,
  CarBodyTypeAddEditApiResponse,
  CarBodyTypeAddEditApiArg,
  AdvertisementApiResponse,
  AdvertisementApiArg,
  AdvertisementAddEditApiResponse,
  AdvertisementAddEditApiArg,
  SearchCreditCardTypeApiResponse,
  SearchCreditCardTypeApiArg,
  DeleteCreditCardTypeApiResponse,
  DeleteCreditCardTypeApiArg,
  SearchCreditAppExpenseApiResponse,
  SearchCreditAppExpenseApiArg,
  DeleteCreditAppExepnseApiResponse,
  DeleteCreditAppExepnseApiArg,
  SearchCarPackFeeApiResponse,
  SearchCarPackFeeApiArg,
  DeleteCarPackFeeApiResponse,
  DeleteCarPackFeeApiArg,
  DeleteCarStatusApiResponse,
  DeleteCarStatusApiArg,
  SearchCarStatusApiResponse,
  SearchCarStatusApiArg,
  IpAddressDataApiResponse,
  IpAddressDataApiArg,
  RepoTypeDataApiResponse,
  RepoTypeDataApiArg,
  GetCreditAppExpenseDataApiResponse,
  GetCreditAppExpenseDataApiArg,
  CreditAppExpenseAddEditApiResponse,
  CreditAppExpenseAddEditApiArg,
  StatusCreditAppDataApiResponse,
  StatusCreditAppDataApiArg,
  DealStatusDataApiResponse,
  DealStatusDataApiArg,
  CarExpenseAddEditApiResponse,
  CarExpenseAddEditApiArg,
  CarExpenseDataApiResponse,
  CarExpenseDataApiArg,
  CarStatusAddEditApiResponse,
  CarStatusAddEditApiArg,
  CarStatusDataApiResponse,
  CarStatusDataApiArg,
  CarPackFeeAddEditApiResponse,
  CarPackFeeAddEditApiArg,
  CarPackFeeDataApiResponse,
  CarPackFeeDataApiArg,
  PaymentCheckDataApiResponse,
  PaymentCheckDataApiArg,
  CreditCardTypeAddEditApiResponse,
  CreditCardTypeAddEditApiArg,
  CreditCardTypeDataApiResponse,
  CreditCardTypeDataApiArg,
  PaymentMethodDataApiResponse,
  PaymentMethodDataApiArg,
  SideNoteDataApiResponse,
  SideNoteDataApiArg,
  PaymentFormDataApiResponse,
  PaymentFormDataApiArg,
  SearchWorklistApiResponse,
  SearchWorklistApiArg,
  WorklistCreateApiResponse,
  WorklistCreateApiArg,
  CollectionsWorklistCommonApiResponse,
  CollectionsWorklistCommonApiArg,
  GetAccountStatusApiResponse,
  GetAccountStatusApiArg,
  WorklistLoginApiResponse,
  WorklistLoginApiArg,
  WorklistNextAccountApiResponse,
  WorklistNextAccountApiArg,
  GetAccountingControlDataApiResponse,
  GetAccountingControlDataApiArg,
  ListsCarsExpenseTypeApiResponse,
  ListsCarsExpenseTypeApiArg,
  ReportsFavoriteApiResponse,
  ReportsFavoriteApiArg,
  ReportsFavoriteUpdateApiResponse,
  ReportsFavoriteUpdateApiArg,
  ReportsInventoryApiResponse,
  ReportsInventoryApiArg,
  AccountingCheckAddApiArg,
  AccountingCheckAddApiResponse,
  AccountingAdjutingEntryApiResponse,
  AccountingAdjutingEntryApiArg,
  DealsDropdownApiResponse,
  DealsDropdownApiArg,
  ReportsSalesTradeLiensApiResponse,
  ReportsSalesTradeLiensApiArg,
  ReportsAccountingAPAgingApiResponse,
  ReportsAccountingAPAgingApiArg,
  ReportsSalesTaxApiResponse,
  ReportsSalesTaxApiArg,
  ReportsAccountsReceivablesApiResponse,
  ReportsAccountsReceivablesApiArg,
  ReportsAccountsARAgingApiResponse,
  ReportsAccountsARAgingApiArg,
  ReportsAccountsARRecencyApiResponse,
  ReportsAccountsARRecencyApiArg,
  ReportsSalesCommissionsApiResponse,
  ReportsSalesCommissionsApiArg,
  ReportsSalesApiResponse,
  ReportsSalesApiArg,
  DashboardInventoryApiResponse,
  DashboardInventoryApiArg,
  DealerSearchApiResponse,
  DealerSearchApiArg,
  DealerDeleteApiResponse,
  DealerDeleteApiArg,
  DealerGetApiResponse,
  DealerGetApiArg,
  DealerAddEditApiResponse,
  DealerAddEditApiArg,
  SearchOutcomeApiResponse,
  SearchOutcomeApiArg,
  DeleteOutcomeApiArg,
  DeleteOutcomeApiResponse,
  AddEditOutcomeApiResponse,
  AddEditOutcomeApiArg,
  GetOutcomeApiResponse,
  GetOutcomeApiArg,
  SearchCityTaxesApiResponse,
  SearchCityTaxesApiArg,
  AddEditCityTaxesApiArg,
  AddEditCityTaxesApiResponse,
  GetCityTaxesApiResponse,
  GetCityTaxesApiArg,
  GetCollectionResultDataApiResponse,
  GetCollectionResultDataApiArg,
  GetSettingsDropdownApiResponse,
  GetSettingsDropdownApiArg,
  SearchCountyTaxesApiResponse,
  SearchCountyTaxesApiArg,
  GetCountyTaxesApiResponse,
  GetCountyTaxesApiArg,
  AddEditCountyTaxesApiResponse,
  AddEditCountyTaxesApiArg,
  GetLotsApiResponse,
  GetLotsApiArg,
  GetDealerReserveApiResponse,
  GetDealerReserveApiArg,
  SearchDealerReserveApiResponse,
  SearchDealerReserveApiArg,
  EditDealerReserveApiArg,
  EditDealerReserveApiResponse,
  DeleteDealerReserveApiResponse,
  DeleteDealerReserveApiArg,
  GetInsuranceDataApiResponse,
  GetInsuranceDataApiArg,
  GetCarPaymentDetailsApiResponse,
  AddCarPaymentApiResponse,
  AddCarPaymentApiArg,
  UploadFormPDFApiArg,
  UploadFormPDFApiResponse,
  GetFormDataApiArg,
  GetFormDataApiResponse,
  GetFormTokenDataApiArg,
  GetFormTokenDataApiResponse,
  AddFormMappingApiArg,
  AddFormMappingApiResponse,
  GetFormMappingDataApiArg,
  SearchFormsApiArg,
  SearchFormsApiResponse,
  DeleteFormMappingApiResponse,
  DeleteFormMappingApiArg,
  DeleteFormApiArg,
  DeleteFormApiResponse,
  AccountsPaymentsVoidApiResponse,
  AccountsPaymentsVoidApiArg,
  SearchFormsPacksApiArg,
  SearchFormsPacksApiResponse,
  DeleteFormPackDetailsApiResponse,
  DeleteFormPackDetailsApiArg,
  AddEditFormPackDetailApiArg,
  AddEditFormPackDetailApiResponse,
  GetFormPackDetailApiArg,
  GetFormPackDetailApiResponse,
  FormsPromptsApiResponse,
  FormsPromptsApiArg,
  PaymentsDetailsApiResponse,
  PaymentsDetailsApiArg,
  GetCarImagesApiResponse,
  GetCarImagesApiArg,
  DeleteCreditAppCoApplicantApiResponse,
  DeleteCreditAppCoApplicantApiArg,
  ReportsAccountingSubsidiaryReconApiResponse,
  ReportsAccountingSubsidiaryReconApiArg,
  CreditAppLookingForApiResponse,
  CreditAppLookingForApiArg,
  GetCreditAppLookingForApiResponse,
  GetCreditAppLookingForApiArg,
  GetDealsFlagApiResponse,
  GetDealsFlagApiArg,
  DeleteDealsFlagApiResponse,
  DeleteDealsFlagApiArg,
  AddEditDealsFlagApiResponse,
  AddEditDealsFlagApiArg,
  GetDealsFlagDataApiResponse,
  GetDealsFlagDataApiArg,
  PackReorderApiArg,
  PackReorderApiResponse,
  ReportsAccountingPaymentsApiResponse,
  ReportsAccountingPaymentsApiArg,
  AddExtensionApiResponse,
  AddExtensionApiArg,
  ReportsAccountsChargeOffsApiResponse,
  ReportsAccountsChargeOffsApiArg,
  FollowupCompleteApiResponse,
  FollowupCompleteApiArg,
} from '../models'
import {
  AccountsSettingsApiArg,
  AccountsSettingsApiResponse,
  AddEditFormPackApiArg,
  AddEditFormPackApiResponse,
  CreditAppLookingAtApiArg,
  CreditAppLookingAtApiResponse,
  CreditAppLookingAtDeleteApiArg,
  CreditAppLookingAtDeleteApiResponse,
  CreditAppLookingForDeleteApiArg,
  CreditAppLookingForDeleteApiResponse,
  DeleteCarImagesApiArg,
  DeleteCarImagesApiResponse,
  DeleteFormPackApiArg,
  DeleteFormPackApiResponse,
  EditAccountRepoApiArg,
  EditAccountRepoApiResponse,
  FormsBillingApiArg,
  FormsBillingApiResponse,
  GetAccountDealApiArg,
  GetAccountDealApiResponse,
  GetAccountsDropdownDataApiArg,
  GetAccountsDropdownDataApiResponse,
  GetCarpaymentApiArg,
  GetCarPaymentApiResponse,
  GetCarPaymentDetailsApiArg,
  GetDealersListApiArg,
  GetDealersListApiResponse,
  GetFormListApiArg,
  GetFormListApiResponse,
  GetFormMappingDataApiResponse,
  GetFormPackDataApiArg,
  GetFormPackDataApiResponse,
  GetUserRoleAccessRightsApiArg,
  GetUserRoleAccessRightsApiResponse,
  PrintFormDealApiArg,
  PrintFormDealApiResponse,
  UploadCarImagesApiArg,
  UploadCarImagesApiResponse,
} from '../models'

export const injectedRtkApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addBranch: build.mutation<AddBranchApiResponse, AddBranchApiArg>({
      query: (queryArg) => ({
        url: `/branch/`,
        method: 'PUT',
        body: queryArg.uspBranchAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchBranch: build.mutation<SearchBranchApiResponse, SearchBranchApiArg>({
      query: (queryArg) => ({
        url: `/branch/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editBranch: build.mutation<EditBranchApiResponse, EditBranchApiArg>({
      query: (queryArg) => ({
        url: `/branch/${queryArg.branchId}/`,
        method: 'PATCH',
        body: queryArg.uspBranchAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteBranch: build.mutation<DeleteBranchApiResponse, DeleteBranchApiArg>({
      query: (queryArg) => ({
        url: `/branch/${queryArg.branchId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCarFinder: build.mutation<AddCarFinderApiResponse, AddCarFinderApiArg>({
      query: (queryArg) => ({
        url: `/car_finder/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarFinderAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarExpense: build.mutation<CarExpenseAddEditApiResponse, CarExpenseAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_expense_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarExpenseTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarExpense: build.mutation<CarExpenseAddEditApiResponse, CarExpenseAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_expense_type/${queryArg.carExpenseId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarExpenseTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    carExpenseData: build.query<CarExpenseDataApiResponse, CarExpenseDataApiArg>({
      query: (queryArg) => ({
        url: `/car_expense_type/data/${queryArg.carExpenseId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    carExpenseSearch: build.mutation<CarExpenseTypeSearchApiResponse, CarExpenseTypeSearchApiArg>({
      query: (queryArg) => ({
        url: `/car_expense_type/search/`,
        method: 'POST',
        body: queryArg.carExpenseTypeSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    carExpenseDelete: build.mutation<CarExpenseTypeDeleteApiResponse, CarExpenseTypeDeleteApiArg>({
      query: (queryArg) => ({
        url: `/car_expense_type/${queryArg.carExpenseId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCarFinder: build.mutation<SearchCarFinderApiResponse, SearchCarFinderApiArg>({
      query: (queryArg) => ({
        url: `/car_finder/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editCarFinder: build.mutation<EditCarFinderApiResponse, EditCarFinderApiArg>({
      query: (queryArg) => ({
        url: `/car_finder/${queryArg.carPackFeeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarFinderAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCarFinder: build.mutation<DeleteCarFinderApiResponse, DeleteCarFinderApiArg>({
      query: (queryArg) => ({
        url: `/car_finder/${queryArg.carPackFeeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCar: build.mutation<AddCarApiResponse, AddCarApiArg>({
      query: (queryArg) => ({
        url: `/cars/`,
        method: 'PUT',
        body: queryArg.uspCarAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchCar: build.mutation<SearchCarApiResponse, SearchCarApiArg>({
      query: (queryArg) => ({
        url: `/cars/search/`,
        method: 'POST',
        body: queryArg.uspCarSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    carsVinRead: build.query<CarsVinReadApiResponse, CarsVinReadApiArg>({
      query: (queryArg) => ({
        url: `/cars/vin/${queryArg.vin}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getCar: build.query<GetCarApiResponse, GetCarApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editCar: build.mutation<EditCarApiResponse, EditCarApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/`,
        method: 'PATCH',
        body: queryArg.uspCarAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getCarPayment: build.mutation<GetCarPaymentApiResponse, GetCarpaymentApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/payments/`,
        method: 'POST',
        body: queryArg.uspCarPaymentView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarPaymentDetails: build.query<GetCarPaymentDetailsApiResponse, GetCarPaymentDetailsApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/payments/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarPayment: build.mutation<AddCarPaymentApiResponse, AddCarPaymentApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/payments/`,
        method: 'PUT',
        body: queryArg.uspCarPayment,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarCost: build.mutation<GetCarCostApiResponse, GetCarCostApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/cost/`,
        method: 'POST',
        body: queryArg.uspGetCarCostByType,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCarCost: build.mutation<AddCarCostApiResponse, AddCarCostApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/cost/`,
        method: 'PUT',
        body: queryArg.uspCarCostAdd,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    carFloorplanEdit: build.mutation<EditCarFloorplanApiResponse, EditCarFloorplanApiArg>({
      query: (queryArg) => ({
        url: `/cars/floorplan/${queryArg.floorplanId}/`,
        method: 'PATCH',
        body: queryArg.uspCarEditFloorplan,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    carExpenseGet: build.mutation<CarExpenseGetApiResponse, CarExpenseGetApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/expense/`,
        method: 'POST',
        body: queryArg.uspCarExpenseView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    carNoteGet: build.mutation<CarNoteGetApiResponse, CarNoteGetApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/note/`,
        method: 'POST',
        body: queryArg.uspCarNoteView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCarNote: build.mutation<AddCarNoteApiResponse, AddCarNoteApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/note/`,
        method: 'PUT',
        body: queryArg.uspCarNoteInsert,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editCarNote: build.mutation<EditCarNoteApiResponse, EditCarNoteApiArg>({
      query: (queryArg) => ({
        url: `/cars/note/${queryArg.carId}/`,
        method: 'PATCH',
        body: queryArg.uspCarNoteEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCarNote: build.mutation<DeleteCarNoteApiResponse, DeleteCarNoteApiArg>({
      query: (queryArg) => ({
        url: `/cars/${queryArg.carId}/note/${queryArg.noteId}`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    userLoggedIn: build.mutation<UserLoggedInApiResponse, UserLoggedInApiArg>({
      query: (queryArg) => ({
        url: `/clients/user_logged_in/`,
        method: 'POST',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    companyTypes: build.query<CompanyTypesApiResponse, CompanyTypesApiArg>({
      query: (queryArg) => ({
        url: `/clients/${queryArg.clientUid}/company_types/`,
      }),
    }),
    addCollection: build.mutation<AddCollectionApiResponse, AddCollectionApiArg>({
      query: (queryArg) => ({
        url: `/collection/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCollectionActivityAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCollectionResult: build.mutation<AddCollectionResultApiResponse, AddCollectionResultApiArg>({
      query: (queryArg) => ({
        url: `/result/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCollectionResultAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchCollectionResult: build.mutation<SearchCollectionResultApiResponse, SearchCollectionResultApiArg>({
      query: (queryArg) => ({
        url: `/result/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editCollectionResult: build.mutation<EditCollectionResultApiResponse, EditCollectionResultApiArg>({
      query: (queryArg) => ({
        url: `/result/${queryArg.collectionResultId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCollectionResultAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCollectionResult: build.mutation<DeleteCollectionResultApiResponse, DeleteCollectionResultApiArg>({
      query: (queryArg) => ({
        url: `/result/${queryArg.collectionResultId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCollectionResultData: build.query<GetCollectionResultDataApiResponse, GetCollectionResultDataApiArg>({
      query: (queryArg) => ({
        url: `/result/data/${queryArg.collectionResultId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCollection: build.mutation<SearchCollectionApiResponse, SearchCollectionApiArg>({
      query: (queryArg) => ({
        url: `/collection/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editCollection: build.mutation<EditCollectionApiResponse, EditCollectionApiArg>({
      query: (queryArg) => ({
        url: `/collection/${queryArg.collectionId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCollectionActivityAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCollection: build.mutation<DeleteCollectionApiResponse, DeleteCollectionApiArg>({
      query: (queryArg) => ({
        url: `/collection/${queryArg.collectionId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCompany: build.mutation<AddCompanyApiResponse, AddCompanyApiArg>({
      query: (queryArg) => ({
        url: `/company/`,
        method: 'PUT',
        body: queryArg.uspCompanyAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchCompany: build.mutation<SearchCompanyApiResponse, SearchCompanyApiArg>({
      query: (queryArg) => ({
        url: `/company/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editCompany: build.mutation<EditCompanyApiResponse, EditCompanyApiArg>({
      query: (queryArg) => ({
        url: `/company/${queryArg.companyId}/`,
        method: 'PATCH',
        body: queryArg.uspCompanyAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCompany: build.mutation<DeleteCompanyApiResponse, DeleteCompanyApiArg>({
      query: (queryArg) => ({
        url: `/company/${queryArg.companyId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    configurationEditData: build.mutation<ConfigurationEditDataApiResponse, ConfigurationEditDataApiArg>({
      query: (queryArg) => ({
        url: `/configuration/edit/`,
        method: 'PATCH',
        body: queryArg.configurationEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    configurationData: build.mutation<ConfigurationDataApiResponse, ConfigurationDataApiArg>({
      query: (queryArg) => ({
        url: `/configuration/data/`,
        method: 'POST',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchConfiguration: build.mutation<SearchConfigurationApiResponse, SearchConfigurationApiArg>({
      query: (queryArg) => ({
        url: `/configuration/search/`,
        method: 'POST',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editConfiguration: build.mutation<EditConfigurationApiResponse, EditConfigurationApiArg>({
      query: (queryArg) => ({
        url: `/configuration/${queryArg.configurationId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationSettingEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addContact: build.mutation<AddContactApiResponse, AddContactApiArg>({
      query: (queryArg) => ({
        url: `/contacts/`,
        method: 'PUT',
        body: queryArg.uspContactAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addContactAddress: build.mutation<AddContactAddressApiResponse, AddContactAddressApiArg>({
      query: (queryArg) => ({
        url: `/contacts/address/${queryArg.contactId}/`,
        method: 'PUT',
        body: queryArg.uspContactAddressAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editContactAddress: build.mutation<EditContactAddressApiResponse, EditContactAddressApiArg>({
      query: (queryArg) => ({
        url: `/contacts/address/${queryArg.contactId}/${queryArg.addressId}/`,
        method: 'PATCH',
        body: queryArg.uspContactAddressAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addEditContactsEmployer: build.mutation<AddEditContactsEmployerApiResponse, AddEditContactsEmployerApiArg>({
      query: (queryArg) => ({
        url: `/contacts/employer/${queryArg.contactId}/`,
        method: 'PUT',
        body: queryArg.uspContactEmployerAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editContactsEmployer: build.mutation<EditContactsEmployerApiResponse, EditContactsEmployerApiArg>({
      query: (queryArg) => ({
        url: `/contacts/employer/${queryArg.contactId}/${queryArg.employerId}/`,
        method: 'PATCH',
        body: queryArg.uspContactEmployerAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addEditContactsIncomeHistory: build.mutation<AddEditContactsIncomeHistoryApiResponse, AddEditContactsIncomeHistoryApiArg>({
      query: (queryArg) => ({
        url: `/contacts/income_history/${queryArg.contactId}/`,
        method: 'PUT',
        body: queryArg.uspCreditAppApplicantAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editContactsIncomeHistory: build.mutation<EditContactsIncomeHistoryApiResponse, EditContactsIncomeHistoryApiArg>({
      query: (queryArg) => ({
        url: `/contacts/income_history/${queryArg.contactId}/${queryArg.creditAppApplicantId}/`,
        method: 'PATCH',
        body: queryArg.uspCreditAppApplicantAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    updateContactPhone: build.mutation<UpdateContactPhoneApiResponse, UpdateContactPhoneApiArg>({
      query: (queryArg) => ({
        url: `/contacts/phones/`,
        method: 'PATCH',
        body: queryArg.uspContactPhone,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    contactsReferenceGet: build.query<ContactsReferenceGetApiResponse, ContactsReferenceGetApiArg>({
      query: (queryArg) => ({
        url: `/contacts/reference/${queryArg.contactId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchContact: build.mutation<SearchContactApiResponse, SearchContactApiArg>({
      query: (queryArg) => ({
        url: `/contacts/search/`,
        method: 'POST',
        body: queryArg.uspContactSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getContact: build.mutation<GetContactApiResponse, GetContactApiArg>({
      query: (queryArg) => ({
        url: `/contacts/${queryArg.contactId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editContact: build.mutation<EditContactApiResponse, EditContactApiArg>({
      query: (queryArg) => ({
        url: `/contacts/${queryArg.contactId}/`,
        method: 'PATCH',
        body: queryArg.uspContactAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getContactCredit: build.query<GetContactCreditApiResponse, GetContactCreditApiArg>({
      query: (queryArg) => ({
        url: `/contacts/${queryArg.contactId}/${queryArg.creditAppId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addContactFull: build.mutation<AddEditContactFullApiResponse, AddContactFullApiArg>({
      query: (queryArg) => ({
        url: `/contacts/full/`,
        method: 'PUT',
        body: queryArg.uspContactFullAddEdit,
      }),
      invalidatesTags: ['Deals'],
    }),
    editContactFull: build.mutation<AddEditContactFullApiResponse, EditContactFullApiArg>({
      query: (queryArg) => ({
        url: `/contacts/full/${queryArg.contactId}/`,
        method: 'PATCH',
        body: queryArg.uspContactFullAddEdit,
      }),
      invalidatesTags: ['Deals'],
    }),
    addCreditApp: build.mutation<AddCreditAppApiResponse, AddCreditAppApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/`,
        method: 'PUT',
        body: queryArg.uspCreditAppAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCreditApp: build.mutation<DeleteCreditAppApiResponse, DeleteCreditAppApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/${queryArg.leadId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCreditAppCoAplicant: build.mutation<DeleteCreditAppCoApplicantApiResponse, DeleteCreditAppCoApplicantApiArg>({
      query: (queryArg) => ({
        url: `credit_app/co_applicant/remove/${queryArg.leadId}/${queryArg.coApplicantId}`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCreditAppNote: build.mutation<DeleteCreditAppNoteApiResponse, DeleteCreditAppNoteApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/note/clear/${queryArg.noteId}/`,
        method: 'DELETE',
        body: queryArg.uspCreditAppNoteClear,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getCreditAppNote: build.mutation<GetCreditAppNoteApiResponse, GetCreditAppNoteApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/note/${queryArg.creditAppId}/`,
        method: 'POST',
        body: queryArg.uspCreditAppNoteView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCreditAppNote: build.mutation<AddCreditAppNoteApiResponse, AddCreditAppNoteApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/note/${queryArg.creditAppId}/`,
        method: 'PUT',
        body: queryArg.uspCreditAppNoteInsert,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchCreditApp: build.mutation<SearchCreditAppApiResponse, SearchCreditAppApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/search/`,
        method: 'POST',
        body: queryArg.uspCreditAppSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    creditAppLookingAt: build.mutation<CreditAppLookingAtApiResponse, CreditAppLookingAtApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/vehicle/${queryArg.creditAppId}/looking_at/${queryArg.carId}/`,
        method: 'PUT',
      }),
    }),

    deleteCreditAppLookingAt: build.mutation<CreditAppLookingAtDeleteApiResponse, CreditAppLookingAtDeleteApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/vehicle/delete/looking_at/${queryArg.lookingAtId}/`,
        method: 'DELETE',
      }),
    }),

    deleteCreditAppLookingFor: build.mutation<CreditAppLookingForDeleteApiResponse, CreditAppLookingForDeleteApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/vehicle/delete/looking_for/${queryArg.lookingForId}/`,
        method: 'DELETE',
      }),
    }),

    addCreditAppLookingFor: build.mutation<CreditAppLookingForApiResponse, CreditAppLookingForApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/vehicle/${queryArg.creditAppId}/looking_for/`,
        method: 'PUT',
        body: queryArg.uspCreditAppLookingForAddEdit,
      }),
    }),

    editCreditAppLookingFor: build.mutation<CreditAppLookingForApiResponse, CreditAppLookingForApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/vehicle/${queryArg.creditAppId}/looking_for/${queryArg.lookingForId}/`,
        method: 'PATCH',
        body: queryArg.uspCreditAppLookingForAddEdit,
      }),
    }),

    getCreditAppLookingFor: build.query<GetCreditAppLookingForApiResponse, GetCreditAppLookingForApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/vehicle/${queryArg.creditAppId}/looking_for/${queryArg.lookingForId}/`,
        method: 'GET',
      }),
    }),
    addCreditAppStatus: build.mutation<AddCreditAppStatusApiResponse, AddCreditAppStatusApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/status/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCreditAppStatusAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchCreditAppStatus: build.mutation<SearchCreditAppStatusApiResponse, SearchCreditAppStatusApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/status/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editDealStatusCredit: build.mutation<EditDealStatusCreditApiResponse, EditDealStatusCreditApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/status/${queryArg.creditAppStatusId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCreditAppStatusAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    statusCreditAppData: build.query<StatusCreditAppDataApiResponse, StatusCreditAppDataApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/status/data/${queryArg.creditAppStatusId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteCreditAppStatus: build.mutation<DeleteCreditAppStatusApiResponse, DeleteCreditAppStatusApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/status/${queryArg.creditAppStatusId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getCreditAppStipulation: build.query<GetCreditAppStipulationApiResponse, GetCreditAppStipulationApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/stipulation/${queryArg.creditAppId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addCreditAppStipulation: build.mutation<AddCreditAppStipulationApiResponse, AddCreditAppStipulationApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/stipulation/${queryArg.creditAppId}/`,
        method: 'PUT',
        body: queryArg.uspCreditAppStipulationAddEdit,
      }),
      invalidatesTags: ['Leads'],
    }),
    editCreditAppStipulation: build.mutation<EditCreditAppStipulationApiResponse, EditCreditAppStipulationApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/stipulation/${queryArg.creditAppId}/${queryArg.creditAppStipulationId}/`,
        method: 'PATCH',
        body: queryArg.uspCreditAppStipulationAddEdit,
      }),
      invalidatesTags: ['Leads'],
    }),
    deleteCreditAppStipulation: build.mutation<DeleteCreditAppStipulationApiResponse, DeleteCreditAppStipulationApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/stipulation/${queryArg.creditAppId}/${queryArg.creditAppStipulationId}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Leads'],
    }),
    editCreditApp: build.mutation<EditCreditAppApiResponse, EditCreditAppApiArg>({
      query: (queryArg) => ({
        url: `/credit_app/${queryArg.creditAppId}/`,
        method: 'PATCH',
        body: queryArg.uspCreditAppAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addDeal: build.mutation<AddDealApiResponse, AddDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/`,
        method: 'PUT',
        body: queryArg.uspDealAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getCalculationForDeal: build.mutation<GetCalculationForDealApiResponse, GetCalculationForDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/calculate/`,
        method: 'POST',
        body: queryArg.calculateDeal,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealAmortization: build.mutation<GetDealAmortizationApiResponse, GetDealAmortizationApiArg>({
      query: (queryArg) => ({
        url: `/deals/amortization/${queryArg.dealId}/`,
        method: 'POST',
        body: queryArg.uspDealAmortization,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getDealCommission: build.query<GetDealCommissionApiResponse, GetDealCommissionApiArg>({
      query: (queryArg) => ({
        url: `/deals/commission/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addDealCommission: build.mutation<AddDealCommissionApiResponse, AddDealCommissionApiArg>({
      query: (queryArg) => ({
        url: `/deals/commission/${queryArg.dealId}/`,
        method: 'PUT',
        body: queryArg.uspDealCommissionAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    editDealCommission: build.mutation<EditDealCommissionApiResponse, EditDealCommissionApiArg>({
      query: (queryArg) => ({
        url: `/deals/commission/${queryArg.dealId}/${queryArg.commissionId}/`,
        method: 'PATCH',
        body: queryArg.uspDealCommissionAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    deleteDealCommission: build.mutation<DeleteDealCommissionApiResponse, DeleteDealCommissionApiArg>({
      query: (queryArg) => ({
        url: `/deals/commission/${queryArg.dealId}/${queryArg.commissionId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    modifyDealFinalizeUnwindSales: build.mutation<ModifyDealFinalizeUnwindSalesApiResponse, ModifyDealFinalizeUnwindSalesApiArg>({
      query: (queryArg) => ({
        url: `/deals/finalize_unwind/${queryArg.dealId}/`,
        method: 'POST',
        body: queryArg.uspDealFinalizeUnwindSales,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealInsurance: build.query<GetDealInsuranceApiResponse, GetDealInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/deals/insurance/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addDealInsurance: build.mutation<AddDealInsuranceApiResponse, AddDealInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/deals/insurance/${queryArg.dealId}/`,
        method: 'PUT',
        body: queryArg.uspAddDataDealInsuranceResult,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    getMaturityDateForDeal: build.mutation<GetMaturityDateForDealApiResponse, GetMaturityDateForDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/maturity_date/`,
        method: 'POST',
        body: queryArg.maturityDate,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealProductData: build.query<GetDealProductDataApiResponse, GetDealProductDataApiArg>({
      query: (queryArg) => ({
        url: `/deals/product/data/${queryArg.productId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealProduct: build.query<GetDealProductApiResponse, GetDealProductApiArg>({
      query: (queryArg) => ({
        url: `/deals/product/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editDealProduct: build.mutation<EditDealProductApiResponse, EditDealProductApiArg>({
      query: (queryArg) => ({
        url: `/deals/product/${queryArg.dealId}/${queryArg.productId}/`,
        method: 'PATCH',
        body: queryArg.uspDealProductEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    getDealProfit: build.mutation<GetDealProfitApiResponse, GetDealProfitApiArg>({
      query: (queryArg) => ({
        url: `/deals/profit/`,
        method: 'POST',
        body: queryArg.body,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealProfitData: build.query<GetDealProfitDataApiResponse, GetDealProfitDataApiArg>({
      query: (queryArg) => ({
        url: `/deals/profit_data/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealReferralData: build.query<GetDealReferralDataApiResponse, GetDealReferralDataApiArg>({
      query: (queryArg) => ({
        url: `/deals/referral/data/${queryArg.referralId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealReferral: build.query<GetDealReferralApiResponse, GetDealReferralApiArg>({
      query: (queryArg) => ({
        url: `/deals/referral/${queryArg.referralId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealReferrals: build.query<GetDealReferralsApiResponse, GetDealReferralsApiArg>({
      query: (queryArg) => ({
        url: `/deals/referral/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addDealReferral: build.mutation<AddDealReferralApiResponse, AddDealReferralApiArg>({
      query: (queryArg) => ({
        url: `/deals/referral/${queryArg.dealId}/`,
        method: 'PUT',
        body: queryArg.uspDealReferralAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    editDealReferral: build.mutation<EditDealReferralApiResponse, EditDealReferralApiArg>({
      query: (queryArg) => ({
        url: `/deals/referral/${queryArg.dealId}/${queryArg.referralId}/`,
        method: 'PATCH',
        body: queryArg.uspDealReferralAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    deleteDealReferral: build.mutation<DeleteDealReferralApiResponse, DeleteDealReferralApiArg>({
      query: (queryArg) => ({
        url: `/deals/referral/${queryArg.dealId}/${queryArg.referralId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    searchDeals: build.mutation<SearchDealsApiResponse, SearchDealsApiArg>({
      query: (queryArg) => ({
        url: `/deals/search/`,
        method: 'POST',
        body: queryArg.uspDealSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addDealStatus: build.mutation<AddDealStatusApiResponse, AddDealStatusApiArg>({
      query: (queryArg) => ({
        url: `/deals/status/`,
        method: 'PUT',
        body: queryArg.uspConfigurationDealStatusMainAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchDealStatus: build.mutation<SearchDealStatusApiResponse, SearchDealStatusApiArg>({
      query: (queryArg) => ({
        url: `/deals/status/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editDealStatus: build.mutation<EditDealStatusApiResponse, EditDealStatusApiArg>({
      query: (queryArg) => ({
        url: `/deals/status/${queryArg.dealStatusId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationDealStatusMainAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    dealStatusData: build.query<DealStatusDataApiResponse, DealStatusDataApiArg>({
      query: (queryArg) => ({
        url: `/deals/status/${queryArg.dealStatusId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteDealStatus: build.mutation<DeleteDealStatusApiResponse, DeleteDealStatusApiArg>({
      query: (queryArg) => ({
        url: `/deals/status/${queryArg.dealStatusId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getTaxes: build.mutation<GetTaxesApiResponse, GetTaxesApiArg>({
      query: (queryArg) => ({
        url: `/deals/taxes/`,
        method: 'POST',
        body: queryArg.taxes,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealTradeData: build.query<GetDealTradeApiResponse, GetDealTradeApiArg>({
      query: (queryArg) => ({
        url: `/deals/trade/data/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealTrade: build.query<GetDealTradeApiResponse, GetDealTradeApiArg>({
      query: (queryArg) => ({
        url: `/deals/trade/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addDealTrade: build.query<AddDealTradeApiResponse, AddDealTradeApiArg>({
      query: (queryArg) => ({
        url: `/deals/trade/${queryArg.dealId}/`,
        method: 'PUT',
        body: queryArg.uspDealTradeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    EditDealTrade: build.query<AddDealTradeApiResponse, AddDealTradeApiArg>({
      query: (queryArg) => ({
        url: `/deals/trade/${queryArg.dealId}/${queryArg.tradeId}/`,
        method: 'PATCH',
        body: queryArg.uspDealTradeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteDealTrade: build.mutation<DeleteDealTradeApiResponse, DeleteDealTradeApiArg>({
      query: (queryArg) => ({
        url: `/deals/trade/delete/${queryArg.tradeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    getDeal: build.query<GetDealApiResponse, GetDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/${queryArg.dealId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editDeal: build.mutation<EditDealApiResponse, EditDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/${queryArg.dealId}/`,
        method: 'PATCH',
        body: queryArg.uspDealAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteDeal: build.mutation<DeleteDealApiResponse, DeleteDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/${queryArg.dealId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getDealsFlag: build.mutation<GetDealsFlagApiResponse, GetDealsFlagApiArg>({
      query: (queryArg) => ({
        url: `/deals/flag/${queryArg.dealId}/`,
        method: 'POST',
        body: queryArg.uspDealFlag,
      }),
    }),

    deleteDealsFlag: build.mutation<DeleteDealsFlagApiResponse, DeleteDealsFlagApiArg>({
      query: (queryArg) => ({
        url: `/deals/flag/${queryArg.dealId}/${queryArg.flagId}/`,
        method: 'DELETE',
      }),
    }),

    editDealsFlag: build.mutation<AddEditDealsFlagApiResponse, AddEditDealsFlagApiArg>({
      query: (queryArg) => ({
        url: `/deals/flag/${queryArg.dealId}/${queryArg.flagId}/`,
        method: 'PATCH',
        body: queryArg.uspEditDealFlag,
      }),
      invalidatesTags: ['Deals'],
    }),

    addDealsFlag: build.mutation<AddEditDealsFlagApiResponse, AddEditDealsFlagApiArg>({
      query: (queryArg) => ({
        url: `/deals/flag/${queryArg.dealId}/`,
        method: 'PUT',
        body: queryArg.uspEditDealFlag,
      }),
      invalidatesTags: ['Deals'],
    }),

    getDealFlagData: build.query<GetDealsFlagDataApiResponse, GetDealsFlagDataApiArg>({
      query: (queryArg) => ({
        url: `/deals/flag/data/${queryArg.flagId}/`,
      }),
    }),

    getUserPermissionsDropdown: build.mutation<GetUserPermissionsDropdownApiResponse, GetUserPermissionsDropdownApiArg>({
      query: (queryArg) => ({
        url: `/dropdowns/users/`,
        method: 'POST',
        body: queryArg.userPermissionsDropdown,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountNotes: build.mutation<GetAccountNotesApiResponse, GetAccountNotesApiArg>({
      query: (queryArg) => ({
        url: `/accounts/note/${queryArg?.dealId}/`,
        method: 'POST',
        body: queryArg.accountNotes,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    notePin: build.mutation<NotesPinApiResponse, NotePinApiArg>({
      query: (queryArg) => ({
        url: `notes/pin/${queryArg.noteId}/`,
        method: 'PATCH',
        body: queryArg.notePin,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    followupComplete: build.mutation<FollowupCompleteApiResponse, FollowupCompleteApiArg>({
      query: (queryArg) => ({
        url: `follow_up/${queryArg.follow_up_id}/`,
        method: 'POST',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountNotesHistory: build.mutation<GetAccountNotesHistoryApiResponse, GetAccountNotesHistoryApiArg>({
      query: (queryArg) => ({
        url: `notes/view/`,
        method: 'POST',
        body: queryArg.accountNotesHistory,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountNotesHistoryList: build.query<GetAccountNotesHistoryApiResponse, GetAccountNotesHistoryApiArg>({
      query: (queryArg) => ({
        url: `notes/view/`,
        method: 'POST',
        body: queryArg.accountNotesHistory,
      }),
      providesTags: ['Notes'],
    }),

    getAccountDueAmount: build.mutation<GetAccountDueAmountApiResponse, GetAccountDueAmountApiArg>({
      query: (queryArg) => ({
        url: `/accounts/due_amounts/${queryArg?.dealId}/`,
        method: 'POST',
        body: queryArg.accountDueAmount,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountDeal: build.query<GetAccountDealApiResponse, GetAccountDealApiArg>({
      query: (queryArg) => ({
        url: `/accounts/deal/data/${queryArg.dealId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountOverview: build.query<GetAccountOverviewApiResponse, GetAccountOverviewApiArg>({
      query: (queryArg) => ({
        url: `/accounts/overview/data/${queryArg?.dealId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountTitle: build.query<GetAccountTitleApiResponse, GetAccountTitleApiArg>({
      query: (queryArg) => ({
        url: `/accounts/title/data/${queryArg.dealId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editAccountTitle: build.mutation<EditAccountTitleApiResponse, EditAccountTitleApiArg>({
      query: (queryArg) => ({
        url: `/accounts/title/${queryArg.dealId}/`,
        method: 'PATCH',
        body: queryArg?.editTitle,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountRepo: build.query<GetAccountRepoApiResponse, GetAccountRepoApiArg>({
      query: (queryArg) => ({
        url: `/accounts/repo/data/${queryArg.dealId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editAccountRepo: build.mutation<EditAccountRepoApiResponse, EditAccountRepoApiArg>({
      query: (queryArg) => ({
        url: `/accounts/repo/${queryArg.dealId}/`,
        method: 'PATCH',
        body: queryArg?.editRepo,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountingControlData: build.query<GetAccountingControlDataApiResponse, GetAccountingControlDataApiArg>({
      query: (queryArg) => ({
        url: `/accounting/control_data/${queryArg.chartOfAccountId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountingUnpaidBills: build.query<GetAccountingUnpaidBillsApiResponse, GetAccountingUnpaidBillsApiArg>({
      query: (queryArg) => ({
        url: `/accounting/bill/unpaid_bills/${queryArg.vendorId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountingUnpaidVendors: build.query<GetAccountingUnpaidVendorsApiResponse, GetAccountingUnpaidVendorsApiArg>({
      query: (queryArg) => ({
        url: `/accounting/bill/unpaid_vendors/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    accountingBillApproveToPay: build.mutation<AccountingBillApproveToPayApiResponse, AccountingBillApproveToPayApiArg>({
      query: (queryArg) => ({
        url: `/accounting/bill/approve_to_pay/${queryArg.billId}/`,
        method: 'PATCH',
        body: queryArg?.accountingBillApproveToPay,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchAccountingBill: build.mutation<AccountingBillApiResponse, AccountingBillApiArg>({
      query: (queryArg) => ({
        url: `/accounting/bill/search/`,
        method: 'POST',
        body: queryArg?.accountingBill,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addAccountingAdjustingEntry: build.mutation<AccountingAdjutingEntryApiResponse, AccountingAdjutingEntryApiArg>({
      query: (queryArg) => ({
        url: `/accounting/adjusting_entry/`,
        method: 'PUT',
        body: queryArg?.uspAccountingAdjustingEntry,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addAccountingCheck: build.mutation<AccountingCheckAddApiResponse, AccountingCheckAddApiArg>({
      query: (queryArg) => ({
        url: `/accounting/check/`,
        method: 'PUT',
        body: queryArg?.uspAccountingCheck,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    accountingCheckSearch: build.mutation<AccountingCheckSearchApiResponse, AccountingCheckSearchApiArg>({
      query: (queryArg) => ({
        url: `/accounting/check/search/`,
        method: 'POST',
        body: queryArg?.accountingCheckSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    accountingCheckBill: build.mutation<AccountingCheckBillApiResponse, AccountingCheckBillApiArg>({
      query: (queryArg) => ({
        url: `/accounting/check/check_bill/`,
        method: 'PUT',
        body: queryArg?.accountingCheckBill,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteAccountingBill: build.mutation<AccountingBillDeleteApiResponse, AccountingBillDeleteApiArg>({
      query: (queryArg) => ({
        url: `/accounting/bill/`,
        method: 'PUT',
        body: queryArg?.accountingBillDelete,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addAccountingBankDeposit: build.mutation<AccountingBankDepositApiResponse, AccountingBankDepositApiArg>({
      query: (queryArg) => ({
        url: `/accounting/bank_deposit/`,
        method: 'PUT',
        body: queryArg?.bankDeposit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountBankruptcy: build.query<GetAccountBankruptcyApiResponse, GetAccountBankruptcyApiArg>({
      query: (queryArg) => ({
        url: `/accounts/bankruptcy/data/${queryArg.dealId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editAccountBankruptcy: build.mutation<EditAccountBankruptcyApiResponse, EditAccountBankruptcyApiArg>({
      query: (queryArg) => ({
        url: `/accounts/bankruptcy/${queryArg.dealId}/`,
        method: 'PATCH',
        body: queryArg?.editBankruptcy,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    accountsPaymentVoid: build.mutation<AccountsPaymentsVoidApiResponse, AccountsPaymentsVoidApiArg>({
      query: (queryArg) => ({
        url: `/payments/void/${queryArg.paymentId}/`,
        method: 'POST',
        body: queryArg.uspPaymentVoid,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountPayHistory: build.mutation<GetAccountPayHistoryApiResponse, GetAccountPayHistoryApiArg>({
      query: (queryArg) => ({
        url: `/payments/view/${queryArg.dealId}/`,
        method: 'POST',
        body: queryArg.accountPayHistory,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addPaymentsTransaction: build.mutation<PaymentTransactionApiResponse, PaymentTransactionApiArg>({
      query: (queryArg) => ({
        url: `/payments/transaction/`,
        method: 'POST',
        body: queryArg.paymentsTransaction,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    accountsChargeOff: build.mutation<AccountsChargeOffApiResponse, AccountsChargeOffApiArg>({
      query: (queryArg) => ({
        url: `/accounts/charge_off/${queryArg?.dealId}/`,
        method: 'POST',
        body: queryArg.accountsChargeOff,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    accountingVoidCheck: build.mutation<AccountingVoidCheckApiResponse, AccountingVoidCheckApiArg>({
      query: (queryArg) => ({
        url: `/accounting/check/void_check/${queryArg.checkId}/`,
        method: 'PUT',
        body: queryArg.accountsVoidCheck,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountingLedger: build.mutation<GetAccountingLedgerApiResponse, GetAccountingLedgerApiArg>({
      query: (queryArg) => ({
        url: `/accounting/ledger/`,
        method: 'POST',
        body: queryArg.uspAccountingLedgerView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountStatus: build.query<GetAccountStatusApiResponse, GetAccountStatusApiArg>({
      query: (queryArg) => ({
        url: `/lists/accounts/account_status/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountingCashAccounts: build.query<GetAccountingCashAccountsDataResponse, GetAccountingCashAccountsApiArg>({
      query: (queryArg) => ({
        url: `/lists/accounting/cash_accounts/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountingChartOfAccountCurrentData: build.query<AddAccountingChartOfAccountCurrentDataResponse, AddAccountingChartOfAccountCurrentDataApiArg>(
      {
        query: (queryArg) => ({
          url: `/accounting/chart/data/${queryArg.chartOfAccountId}/`,
          method: 'GET',
          headers: {
            'CLIENT-UID': queryArg['CLIENT-UID'],
            'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
            'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
          },
        }),
      },
    ),

    getAccountingChartOfAccountCurrent: build.mutation<AddAccountingChartOfAccountCurrentResponse, AddAccountingChartOfAccountCurrentApiArg>({
      query: (queryArg) => ({
        url: `/lists/accounting/chart_of_account/`,
        method: 'POST',
        body: queryArg?.uspChartOfAccountView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountingChartApAccounts: build.mutation<AddAccountingChartApAccountsResponse, AddAccountingChartApAccountsApiArg>({
      query: (queryArg) => ({
        url: '/accounting/chart/ap_accounts/',
        method: 'POST',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAccountSubType: build.query<AccountSubTypeApiResponse, AccountSubTypeApiArg>({
      query: (queryArg) => ({
        url: `/lists/accounting/account_subtype/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addAccountingChartOfAccount: build.mutation<AddAccountingChartOfAccountResponse, AddAccountingChartOfAccountApiArg>({
      query: (queryArg) => ({
        url: `/accounting/chart/search/`,
        method: 'POST',
        body: queryArg.uspAccountingChartOfAccountingView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editAccountingChart: build.mutation<EditAccountingChartOfAccountResponse, EditAccountingChartOfAccountApiArg>({
      query: (queryArg) => ({
        url: `/accounting/chart/${queryArg.chart_of_account_id}/`,
        method: 'PATCH',
        body: queryArg.editChartOfAccount,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteAccountingChart: build.mutation<DeleteAccountingChartOfAccountResponse, DeleteAccountingChartOfAccountApiArg>({
      query: (queryArg) => ({
        url: `/accounting/chart/${queryArg.chart_of_account_id}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addAccountingChartOfAccountItem: build.mutation<AddAccountingChartOfAccountItemResponse, AddAccountingChartOfAccountItemApiArg>({
      query: (queryArg) => ({
        url: `/accounting/chart/`,
        method: 'PUT',
        body: queryArg.uspAccountingChartOfAccountingItemView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    forgotPasswordCreate: build.mutation<ForgotPasswordCreateApiResponse, ForgotPasswordCreateApiArg>({
      query: (queryArg) => ({
        url: `/forgot_password/`,
        method: 'POST',
        body: queryArg.sendRestorePasswordByEmail,
      }),
    }),
    forgotPasswordChangePasswordCreate: build.mutation<ForgotPasswordChangePasswordCreateApiResponse, ForgotPasswordChangePasswordCreateApiArg>({
      query: (queryArg) => ({
        url: `/forgot_password/change_password/`,
        method: 'POST',
        body: queryArg.changePasswordByEmail,
      }),
    }),
    forgotPasswordResendCreate: build.mutation<ForgotPasswordResendCreateApiResponse, ForgotPasswordResendCreateApiArg>({
      query: (queryArg) => ({
        url: `/forgot_password/resend/`,
        method: 'POST',
        body: queryArg.restorePasswordByEmailResend,
      }),
    }),

    addNotes: build.mutation<AddNotesApiResponse, AddNotesApiArg>({
      query: (queryArg) => ({
        url: `/notes/add/`,
        method: 'PUT',
        body: queryArg.addNotes,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Notes'],
    }),

    addExtension: build.mutation<AddExtensionApiResponse, AddExtensionApiArg>({
      query: (queryArg) => ({
        url: `/payments/extension/${queryArg.deal_id}/`,
        method: 'POST',
        body: queryArg.addExtension,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Extensions'],
    }),

    getInsuranceData: build.query<GetInsuranceDataApiResponse, GetInsuranceDataApiArg>({
      query: (queryArg) => ({
        url: `/insurance/data/${queryArg.insuranceId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addInsurance: build.mutation<AddInsuranceApiResponse, AddInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/insurance/`,
        method: 'PUT',
        body: queryArg.uspConfigurationInsuranceAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchInsurance: build.mutation<SearchInsuranceApiResponse, SearchInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/insurance/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editInsurance: build.mutation<EditInsuranceApiResponse, EditInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/insurance/${queryArg.insuranceId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationInsuranceAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteInsurance: build.mutation<DeleteInsuranceApiResponse, DeleteInsuranceApiArg>({
      query: (queryArg) => ({
        url: `/insurance/${queryArg.insuranceId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    userInvitationsSentList: build.query<UserInvitationsSentListApiResponse, UserInvitationsSentListApiArg>({
      query: (queryArg) => ({
        url: `/invitations/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
        params: { page: queryArg.page },
      }),
    }),
    createInvitation: build.mutation<CreateInvitationApiResponse, CreateInvitationApiArg>({
      query: (queryArg) => ({
        url: `/invitations/`,
        method: 'POST',
        body: queryArg.sendInvitation,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getInvitationRegistration: build.query<GetInvitationRegistrationApiResponse, GetInvitationRegistrationApiArg>({
      query: (queryArg) => ({
        url: `/invitations/${queryArg.invitationId}/registration/`,
      }),
    }),
    acceptInvitationRegistration: build.mutation<AcceptInvitationRegistrationApiResponse, AcceptInvitationRegistrationApiArg>({
      query: (queryArg) => ({
        url: `/invitations/${queryArg.invitationId}/registration/`,
        method: 'POST',
        body: queryArg.invitationRegistration,
      }),
    }),
    addIpAddress: build.mutation<AddIpAddressApiResponse, AddIpAddressApiArg>({
      query: (queryArg) => ({
        url: `/ip_addresses/`,
        method: 'PUT',
        body: queryArg.uspConfigurationIpAddressAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    ipAddressData: build.query<IpAddressDataApiResponse, IpAddressDataApiArg>({
      query: (queryArg) => ({
        url: `/ip_addresses/data/${queryArg.ipAddressId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchIpAddress: build.mutation<SearchIpAddressApiResponse, SearchIpAddressApiArg>({
      query: (queryArg) => ({
        url: `/ip_addresses/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editIpAddress: build.mutation<EditIpAddressApiResponse, EditIpAddressApiArg>({
      query: (queryArg) => ({
        url: `/ip_addresses/${queryArg.ipAddressId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationIpAddressAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteIpAddress: build.mutation<DeleteIpAddressApiResponse, DeleteIpAddressApiArg>({
      query: (queryArg) => ({
        url: `/ip_addresses/${queryArg.ipAddressId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getLenderType: build.query<GetLenderTypeApiResponse, GetLenderTypeApiArg>({
      query: (queryArg) => ({
        url: 'lists/lenders/lender_type/',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteLender: build.mutation<DeleteLenderApiResponse, DeleteLenderApiArg>({
      query: (queryArg) => ({
        url: `/lender/${queryArg.lenderId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addLender: build.mutation<AddLenderApiResponse, AddLenderApiArg>({
      query: (queryArg) => ({
        url: '/lender/',
        method: 'PUT',
        body: queryArg.uspLenderAdd,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editLender: build.mutation<EditLenderApiResponse, EditLenderApiArg>({
      query: (queryArg) => ({
        url: `/lender/${queryArg.lenderId}/`,
        method: 'PATCH',
        body: queryArg.uspLenderEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getLender: build.query<GetLenderApiResponse, GetLenderApiArg>({
      query: (queryArg) => ({
        url: `/lender/${queryArg.lenderId}`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchLender: build.mutation<SearchLenderApiResponse, SearchLenderApiArg>({
      query: (queryArg) => ({
        url: `/lender/search/`,
        method: 'POST',
        body: queryArg.data,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    listsCarsExpenseType: build.query<ListsCarsExpenseTypeApiResponse, ListsCarsExpenseTypeApiArg>({
      query: (queryArg) => ({
        url: `/lists/cars/expense_type/${queryArg.isAddingNewExpense}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    carPackFeeChartOfAccount: build.query<CarPackFeeChartOfAccountApiResponse, CarPackFeeChartOfAccountApiArg>({
      query: (queryArg) => ({
        url: `/lists/car_pack_fee/account/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    creditAppListActivity: build.query<CreditAppListActivityApiResponse, CreditAppListActivityApiArg>({
      query: (queryArg) => ({
        url: `/lists/credit_app/activity/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    creditAppListApplicant: build.query<CreditAppListApplicantApiResponse, CreditAppListApplicantApiArg>({
      query: (queryArg) => ({
        url: `/lists/credit_app/applicant/${queryArg.creditAppId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    creditAppListResult: build.query<CreditAppListResultApiResponse, CreditAppListResultApiArg>({
      query: (queryArg) => ({
        url: `/lists/credit_app/result/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    creditAppListStipulation: build.query<CreditAppListStipulationApiResponse, CreditAppListStipulationApiArg>({
      query: (queryArg) => ({
        url: `/lists/credit_app/stipulation/${queryArg.stipulationId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealersList: build.query<GetDealersListApiResponse, GetDealersListApiArg>({
      query: (queryArg) => ({
        url: `/lists/deals/deal_dealer_list/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getDealLenderList: build.query<GetDealLenderListApiResponse, GetDealLenderListApiArg>({
      query: (queryArg) => ({
        url: `/lists/deals/deal_lender_list/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getListDealsCollector: build.query<GetListDealsCollectorApiResponse, GetListDealsCollectorApiArg>({
      query: (queryArg) => ({
        url: `/lists/deals/deal_collector_list/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getListDealStatus: build.query<GetListDealStatusApiResponse, GetListDealApiArg>({
      query: (queryArg) => ({
        url: `/lists/deals/status_list/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getStateDealSettings: build.query<GetStateDealSettingsApiResponse, GetStateDealSettingsApiArg>({
      query: (queryArg) => ({
        url: `/lists/deals/state_deal/${queryArg.state}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getStateDealCompanySettings: build.query<GetStateDealCompanySettingsApiResponse, GetStateDealCompanySettingsApiArg>({
      query: (queryArg) => ({
        url: `/lists/deals/state_deal/${queryArg.state}/${queryArg.companyType}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getActiveCompanyType: build.query<GetActiveCompanyTypesApiResponse, GetActiveCompanyTypesApiArg>({
      query: (queryArg) => ({
        url: '/lists/users/active_company_types/',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getListBranches: build.query<GetListBranchesApiResponse, GetListBranchesApiArg>({
      query: (queryArg) => ({
        url: `/lists/users/branches/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getPhonesTypes: build.query<GetPhonesTypesApiResponse, GetPhonesTypesApiArg>({
      query: (queryArg) => ({
        url: `/lists/users/phone_type/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getInfoByZipCode: build.query<GetInfoByZipCodeApiResponse, GetInfoByZipCodeApiArg>({
      query: (queryArg) => ({
        url: `/lists/users/zip_code_lookup/${queryArg.zipCode}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getListFee: build.query<GetListFeeApiResponse, GetListFeeApiArg>({
      query: (queryArg) => ({
        url: `/lists/fee/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getListProduct: build.query<GetListProductApiResponse, GetListProductApiArg>({
      query: (queryArg) => ({
        url: `/lists/product/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    loginCreate: build.mutation<LoginCreateApiResponse, LoginCreateApiArg>({
      query: (queryArg) => ({
        url: `/login/`,
        method: 'POST',
        body: queryArg.loginToken,
      }),
    }),
    loginRefreshCreate: build.mutation<LoginRefreshCreateApiResponse, LoginRefreshCreateApiArg>({
      query: (queryArg) => ({
        url: `/login/refresh/`,
        method: 'POST',
        body: queryArg.tokenRefresh,
      }),
    }),
    logoutCreate: build.mutation<LogoutCreateApiResponse, LogoutCreateApiArg>({
      query: (queryArg) => ({
        url: `/logout/`,
        method: 'POST',
        body: queryArg.body,
      }),
    }),
    addLots: build.mutation<AddLotsApiResponse, AddLotsApiArg>({
      query: (queryArg) => ({
        url: `/lots/`,
        method: 'PUT',
        body: queryArg.uspConfigurationLotAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getLot: build.query<GetLotsApiResponse, GetLotsApiArg>({
      query: (queryArg) => ({
        url: `/lots/data/${queryArg.lotId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchLots: build.mutation<SearchLotsApiResponse, SearchLotsApiArg>({
      query: (queryArg) => ({
        url: `/lots/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editLots: build.mutation<EditLotsApiResponse, EditLotsApiArg>({
      query: (queryArg) => ({
        url: `/lots/${queryArg.lotId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationLotAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteLots: build.mutation<DeleteLotsApiResponse, DeleteLotsApiArg>({
      query: (queryArg) => ({
        url: `/lots/${queryArg.lotId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addPaymentCheck: build.mutation<AddPaymentCheckApiResponse, AddPaymentCheckApiArg>({
      query: (queryArg) => ({
        url: `/payment_check/`,
        method: 'PUT',
        body: queryArg.uspConfigurationPaymentCheckTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    paymentCheckData: build.query<PaymentCheckDataApiResponse, PaymentCheckDataApiArg>({
      query: (queryArg) => ({
        url: `/payment_check/data/${queryArg.paymentCheckTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchPaymentCheck: build.mutation<SearchPaymentCheckApiResponse, SearchPaymentCheckApiArg>({
      query: (queryArg) => ({
        url: `/payment_check/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editPaymentCheck: build.mutation<EditPaymentCheckApiResponse, EditPaymentCheckApiArg>({
      query: (queryArg) => ({
        url: `/payment_check/${queryArg.paymentCheckTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationPaymentCheckTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deletePaymentCheck: build.mutation<DeletePaymentCheckApiResponse, DeletePaymentCheckApiArg>({
      query: (queryArg) => ({
        url: `/payment_check/${queryArg.paymentCheckTypeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addPaymentForm: build.mutation<AddPaymentFormApiResponse, AddPaymentFormApiArg>({
      query: (queryArg) => ({
        url: `/payment_form/`,
        method: 'PUT',
        body: queryArg.uspConfigurationPaymentFormAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    paymentFormData: build.query<PaymentFormDataApiResponse, PaymentFormDataApiArg>({
      query: (queryArg) => ({
        url: `/payment_form/data/${queryArg.paymentFormId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchPaymentForm: build.mutation<SearchPaymentFormApiResponse, SearchPaymentFormApiArg>({
      query: (queryArg) => ({
        url: `/payment_form/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editPaymentForm: build.mutation<EditPaymentFormApiResponse, EditPaymentFormApiArg>({
      query: (queryArg) => ({
        url: `/payment_form/${queryArg.paymentFormId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationPaymentFormAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deletePaymentForm: build.mutation<DeletePaymentFormApiResponse, DeletePaymentFormApiArg>({
      query: (queryArg) => ({
        url: `/payment_form/${queryArg.paymentFormId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addPaymentMethod: build.mutation<AddPaymentMethodApiResponse, AddPaymentMethodApiArg>({
      query: (queryArg) => ({
        url: `/payment_method/`,
        method: 'PUT',
        body: queryArg.uspConfigurationPaymentMethodAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    paymentMethodData: build.query<PaymentMethodDataApiResponse, PaymentMethodDataApiArg>({
      query: (queryArg) => ({
        url: `/payment_method/data/${queryArg.paymentMethodId}`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchPaymentMethod: build.mutation<SearchPaymentMethodApiResponse, SearchPaymentMethodApiArg>({
      query: (queryArg) => ({
        url: `/payment_method/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editPaymentMethod: build.mutation<EditPaymentMethodApiResponse, EditPaymentMethodApiArg>({
      query: (queryArg) => ({
        url: `/payment_method/${queryArg.paymentMethodId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationPaymentMethodAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deletePaymentMethod: build.mutation<DeletePaymentMethodApiResponse, DeletePaymentMethodApiArg>({
      query: (queryArg) => ({
        url: `/payment_method/${queryArg.paymentMethodId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addReferences: build.mutation<AddReferencesApiResponse, AddReferencesApiArg>({
      query: (queryArg) => ({
        url: `/references/`,
        method: 'PUT',
        body: queryArg.uspContactReferenceAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getReferences: build.query<GetReferencesApiResponse, GetReferencesApiArg>({
      query: (queryArg) => ({
        url: `/references/${queryArg.referenceId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editReferences: build.mutation<EditReferencesApiResponse, EditReferencesApiArg>({
      query: (queryArg) => ({
        url: `/references/${queryArg.referenceId}/`,
        method: 'PATCH',
        body: queryArg.uspContactReferenceAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteReferences: build.mutation<DeleteReferencesApiResponse, DeleteReferencesApiArg>({
      query: (queryArg) => ({
        url: `/references/${queryArg.referenceId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
      invalidatesTags: ['Deals'],
    }),
    addRepoType: build.mutation<AddRepoTypeApiResponse, AddRepoTypeApiArg>({
      query: (queryArg) => ({
        url: `/repo_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationRepoTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarStatus: build.mutation<CarStatusAddEditApiResponse, CarStatusAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_status/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarStatusAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarStatus: build.mutation<CarStatusAddEditApiResponse, CarStatusAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_status/${queryArg.carStatusId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarStatusAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    carStatusData: build.query<CarStatusDataApiResponse, CarStatusDataApiArg>({
      query: (queryArg) => ({
        url: `/car_status/data/${queryArg.carStatusId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCarStatus: build.mutation<SearchCarStatusApiResponse, SearchCarStatusApiArg>({
      query: (queryArg) => ({
        url: `/car_status/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCarStatus: build.mutation<DeleteCarStatusApiResponse, DeleteCarStatusApiArg>({
      query: (queryArg) => ({
        url: `/car_status/${queryArg.carStatusId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarPackFee: build.mutation<CarPackFeeAddEditApiResponse, CarPackFeeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_pack_fee/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarPackFeeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarPackFee: build.mutation<CarPackFeeAddEditApiResponse, CarPackFeeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_pack_fee/${queryArg.carPackFeeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarPackFeeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    carPackFeeData: build.query<CarPackFeeDataApiResponse, CarPackFeeDataApiArg>({
      query: (queryArg) => ({
        url: `/car_pack_fee/data/${queryArg.carPackFeeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCarPackFee: build.mutation<SearchCarPackFeeApiResponse, SearchCarPackFeeApiArg>({
      query: (queryArg) => ({
        url: `/car_pack_fee/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCarPackFee: build.mutation<DeleteCarPackFeeApiResponse, DeleteCarPackFeeApiArg>({
      query: (queryArg) => ({
        url: `/car_pack_fee/${queryArg.carPackFeeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCreditAppExpense: build.mutation<SearchCreditAppExpenseApiResponse, SearchCreditAppExpenseApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_expense/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCreditAppExpenseData: build.query<GetCreditAppExpenseDataApiResponse, GetCreditAppExpenseDataApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_expense/${queryArg.creditAppExpenseId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCreditAppExpenseData: build.mutation<CreditAppExpenseAddEditApiResponse, CreditAppExpenseAddEditApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_expense/${queryArg.creditAppExpenseId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCreditAppExpenseEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCreditAppExpense: build.mutation<DeleteCreditAppExepnseApiResponse, DeleteCreditAppExepnseApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_expense/${queryArg.creditAppExpenseId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCreditCardType: build.mutation<CreditCardTypeAddEditApiResponse, CreditCardTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/credit_card_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCreditCardTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCreditCardType: build.mutation<CreditCardTypeAddEditApiResponse, CreditCardTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/credit_card_type/${queryArg.creditCardTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCreditCardTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    creditCardTypeData: build.query<CreditCardTypeDataApiResponse, CreditCardTypeDataApiArg>({
      query: (queryArg) => ({
        url: `/credit_card_type/data/${queryArg.creditCardTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCreditCardType: build.mutation<SearchCreditCardTypeApiResponse, SearchCreditCardTypeApiArg>({
      query: (queryArg) => ({
        url: `/credit_card_type/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCreditCardType: build.mutation<DeleteCreditCardTypeApiResponse, DeleteCreditCardTypeApiArg>({
      query: (queryArg) => ({
        url: `/credit_card_type/${queryArg.creditCardTypeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    repoTypeData: build.query<RepoTypeDataApiResponse, RepoTypeDataApiArg>({
      query: (queryArg) => ({
        url: `/repo_type/data/${queryArg.repoTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchRepoType: build.mutation<SearchRepoTypeApiResponse, SearchRepoTypeApiArg>({
      query: (queryArg) => ({
        url: `/repo_type/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editRepoType: build.mutation<EditRepoTypeApiResponse, EditRepoTypeApiArg>({
      query: (queryArg) => ({
        url: `/repo_type/${queryArg.repoTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationRepoTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteRepoType: build.mutation<DeleteRepoTypeApiResponse, DeleteRepoTypeApiArg>({
      query: (queryArg) => ({
        url: `/repo_type/${queryArg.repoTypeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editRfc: build.mutation<EditRfcApiResponse, EditRfcApiArg>({
      query: (queryArg) => ({
        url: `/rfc/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationRfcEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchRfc: build.mutation<SearchRfcApiResponse, SearchRfcApiArg>({
      query: (queryArg) => ({
        url: `/rfc/search/`,
        method: 'POST',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addSideNote: build.mutation<AddSideNoteApiResponse, AddSideNoteApiArg>({
      query: (queryArg) => ({
        url: `/side_note/`,
        method: 'PUT',
        body: queryArg.uspConfigurationSideNoteAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    sideNoteData: build.query<SideNoteDataApiResponse, SideNoteDataApiArg>({
      query: (queryArg) => ({
        url: `/side_note/data/${queryArg.sideNoteId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchSideNote: build.mutation<SearchSideNoteApiResponse, SearchSideNoteApiArg>({
      query: (queryArg) => ({
        url: `/side_note/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editSideNote: build.mutation<EditSideNoteApiResponse, EditSideNoteApiArg>({
      query: (queryArg) => ({
        url: `/side_note/${queryArg.sideNoteId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationSideNoteAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteSideNote: build.mutation<DeleteSideNoteApiResponse, DeleteSideNoteApiArg>({
      query: (queryArg) => ({
        url: `/side_note/${queryArg.sideNoteId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addSmsTemplate: build.mutation<AddSmsTemplateApiResponse, AddSmsTemplateApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/`,
        method: 'PUT',
        body: queryArg.uspConfigurationSmsTemplateAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addSmsTemplateCategory: build.mutation<AddSmsTemplateCategoryApiResponse, AddSmsTemplateCategoryApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/category/`,
        method: 'PUT',
        body: queryArg.uspConfigurationSmsTemplateCategoryAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchSmsTemplateCategory: build.mutation<SearchSmsTemplateCategoryApiResponse, SearchSmsTemplateCategoryApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/category/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editSmsTemplateCategory: build.mutation<EditSmsTemplateCategoryApiResponse, EditSmsTemplateCategoryApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/category/${queryArg.smsTemplateCategoryId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationSmsTemplateCategoryAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteSmsTemplateCategory: build.mutation<DeleteSmsTemplateCategoryApiResponse, DeleteSmsTemplateCategoryApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/category/${queryArg.smsTemplateCategoryId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchSmsTemplate: build.mutation<SearchSmsTemplateApiResponse, SearchSmsTemplateApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editSmsTemplate: build.mutation<EditSmsTemplateApiResponse, EditSmsTemplateApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/${queryArg.smsTemplateId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationSmsTemplateAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteSmsTemplate: build.mutation<DeleteSmsTemplateApiResponse, DeleteSmsTemplateApiArg>({
      query: (queryArg) => ({
        url: `/sms_template/${queryArg.smsTemplateId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchState: build.mutation<SearchStateApiResponse, SearchStateApiArg>({
      query: (queryArg) => ({
        url: `/state/search/`,
        method: 'POST',
        body: queryArg.uspStateSettingView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editState: build.mutation<EditStateApiResponse, EditStateApiArg>({
      query: (queryArg) => ({
        url: `/state/${queryArg.stateId}/`,
        method: 'PATCH',
        body: queryArg.uspStateSettingEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getUserClients: build.query<GetUserClientsApiResponse, GetUserClientsApiArg>({
      query: () => ({ url: `/user/clients/` }),
    }),
    getMyProfile: build.query<GetMyProfileApiResponse, GetMyProfileApiArg>({
      query: () => ({ url: `/user/me/` }),
    }),
    updateMyProfile: build.mutation<UpdateMyProfileApiResponse, UpdateMyProfileApiArg>({
      query: (queryArg) => ({
        url: `/user/me/`,
        method: 'PATCH',
        body: queryArg.userProfileUpdate,
      }),
    }),
    updateMyPassword: build.mutation<UpdateMyPasswordApiResponse, UpdateMyPasswordApiArg>({
      query: (queryArg) => ({
        url: `/user/me/change_password/`,
        method: 'PATCH',
        body: queryArg.changeUserPassword,
      }),
    }),
    confirmMyEmail: build.mutation<ConfirmMyEmailApiResponse, ConfirmMyEmailApiArg>({
      query: (queryArg) => ({
        url: `/user/me/email/`,
        method: 'POST',
        body: queryArg.changeUserEmailConfirmation,
      }),
    }),
    updateMyEmail: build.mutation<UpdateMyEmailApiResponse, UpdateMyEmailApiArg>({
      query: (queryArg) => ({
        url: `/user/me/email/`,
        method: 'PATCH',
        body: queryArg.changeUserEmail,
      }),
    }),
    resendMyEmailConfirmation: build.mutation<ResendMyEmailConfirmationApiResponse, ResendMyEmailConfirmationApiArg>({
      query: (queryArg) => ({
        url: `/user/me/email/resend/`,
        method: 'PATCH',
        body: queryArg.changeUserEmailResend,
      }),
    }),

    getUserRoleAccessRights: build.query<GetUserRoleAccessRightsApiResponse, GetUserRoleAccessRightsApiArg>({
      query: (queryArg) => ({
        url: `/user_role/data/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addUserRole: build.mutation<AddUserRoleApiResponse, AddUserRoleApiArg>({
      query: (queryArg) => ({
        url: `/user_role/`,
        method: 'PUT',
        body: queryArg.uspConfigurationUserRoleAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchUserRole: build.mutation<SearchUserRoleApiResponse, SearchUserRoleApiArg>({
      query: (queryArg) => ({
        url: `/user_role/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getUserRole: build.query<GetUserRoleApiResponse, GetUserRoleApiArg>({
      query: (queryArg) => ({
        url: `/user_role/settings/data/${queryArg.userRoleId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editUserRole: build.mutation<EditUserRoleApiResponse, EditUserRoleApiArg>({
      query: (queryArg) => ({
        url: `/user_role/${queryArg.userRoleId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationUserRoleAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteUserRole: build.mutation<DeleteUserRoleApiResponse, DeleteUserRoleApiArg>({
      query: (queryArg) => ({
        url: `/user_role/${queryArg.userRoleId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addUser: build.mutation<AddUserApiResponse, AddUserApiArg>({
      query: (queryArg) => ({
        url: `/users/`,
        method: 'PUT',
        body: queryArg.uspUserAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarMake: build.mutation<CarMakeAddEditApiResponse, CarMakeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_make/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarMakeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarMake: build.mutation<CarMakeAddEditApiResponse, CarMakeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_make/${queryArg.carMakeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarMakeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarMakeData: build.query<CarMakeDataApiResponse, CarMakeDataApiArg>({
      query: (queryArg) => ({
        url: `/car_make/data/${queryArg.carMakeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addReferenceRelationshipType: build.mutation<ReferenceRelationshipTypeAddEditApiResponse, ReferenceRelationshipTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/reference_relationship_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationReferenceRelationshipTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editReferenceRelationshipType: build.mutation<ReferenceRelationshipTypeAddEditApiResponse, ReferenceRelationshipTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/reference_relationship_type/${queryArg.referenceRelationshipId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationReferenceRelationshipTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getReferenceRelationshipData: build.query<ReferenceRelationshipDataApiResponse, ReferenceRelationshipDataApiArg>({
      query: (queryArg) => ({
        url: `/reference_relationship_type/data/${queryArg.referenceRelationshipId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarTitleType: build.mutation<CarTitleTypeAddEditApiResponse, CarTitleTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_title_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarTitleTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarTitleType: build.mutation<CarTitleTypeAddEditApiResponse, CarTitleTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_title_type/${queryArg.carTitleTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarTitleTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarTitleTypeData: build.query<CarTitleTypeApiResponse, CarTitleTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_title_type/data/${queryArg.carTitleTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarDrivetrain: build.mutation<CarDrivetrainAddEditApiResponse, CarDrivetrainAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_drivetrain/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarDrivetrainAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarDrivetrain: build.mutation<CarDrivetrainAddEditApiResponse, CarDrivetrainAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_drivetrain/${queryArg.carDrivetrainId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarDrivetrainAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarDrivetrainData: build.query<CarDrivetrainApiResponse, CarDrivetrainApiArg>({
      query: (queryArg) => ({
        url: `/car_drivetrain/data/${queryArg.carDrivetrainId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addVentorType: build.mutation<VendorTypeAddEditApiResponse, VendorTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/vendor_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationVendorTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editVentorType: build.mutation<VendorTypeAddEditApiResponse, VendorTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/vendor_type/${queryArg.vendorTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationVendorTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getVendorTypeData: build.query<VendorTypeDataApiResponse, VendorTypeDataApiArg>({
      query: (queryArg) => ({
        url: `/vendor_type/data/${queryArg.vendorTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addInterestLevel: build.mutation<InterestLevelAddEditApiResponse, InterestLevelAddEditApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_interest_level/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCreditAppInterestLevelAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editInterestLevel: build.mutation<InterestLevelAddEditApiResponse, InterestLevelAddEditApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_interest_level/${queryArg.interestLevelId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCreditAppInterestLevelAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getInterestLevel: build.query<InterestLevelDataApiResponse, InterestLevelDataApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_interest_level/data/${queryArg.interestLevelId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addStipulation: build.mutation<StipulationAddEditApiResponse, StipulationAddEditApiArg>({
      query: (queryArg) => ({
        url: `/stipulation/`,
        method: 'PUT',
        body: queryArg.uspConfigurationStipulationAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editStipulation: build.mutation<StipulationAddEditApiResponse, StipulationAddEditApiArg>({
      query: (queryArg) => ({
        url: `/stipulation/${queryArg.stipulationId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationStipulationAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getStipulationData: build.query<StipulationDataApiResponse, StipulationDataApiArg>({
      query: (queryArg) => ({
        url: `/stipulation/data/${queryArg.stipulationId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchStipulations: build.mutation<SearchtSipulationsApiResponse, SearchStipulationsApiArg>({
      query: (queryArg) => ({
        url: `/stipulation/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationStipulationView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteStipulation: build.mutation<DeleteStipulationsApiResponse, DeleteStipulationsApiArg>({
      query: (queryArg) => ({
        url: `/stipulation/${queryArg.stipulationId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchVendors: build.mutation<SearchVendorsApiResponse, SearchVendorsApiArg>({
      query: (queryArg) => ({
        url: `/vendor_type/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationVendorTypeView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteVendors: build.mutation<DeleteVendorApiResponse, DeleteVendorApiArg>({
      query: (queryArg) => ({
        url: `/vendor_type/${queryArg.vendorId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchInterestLevels: build.mutation<SearchAInterestLevelsApiResponse, SearchAInterestLevelsApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_interest_level/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCreditAppInterestLevelView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteInterestLevel: build.mutation<DeleteInterestLevelApiResponse, DeleteInterestLevelApiArg>({
      query: (queryArg) => ({
        url: `/credit_app_interest_level/${queryArg.interestLevelId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchReferencesRelationshipsTypes: build.mutation<SearchReferencesRelationshipsTypesApiResponse, SearchReferencesRelationshipsTypesApiArg>({
      query: (queryArg) => ({
        url: `/reference_relationship_type/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationReferenceRelationshipTypeView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteReferenceRelationshipType: build.mutation<DeleteReferenceRelationshipApiResponse, DeleteReferenceRelationshipApiArg>({
      query: (queryArg) => ({
        url: `/reference_relationship_type/${queryArg.referenceRelationId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getAdvertisement: build.query<AdvertisementApiResponse, AdvertisementApiArg>({
      query: (queryArg) => ({
        url: `/advertisement/data/${queryArg.advertisementId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addAdvertisement: build.mutation<AdvertisementAddEditApiResponse, AdvertisementAddEditApiArg>({
      query: (queryArg) => ({
        url: `/advertisement/`,
        method: 'PUT',
        body: queryArg.uspConfigurationAdvertisementAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editAdvertisement: build.mutation<AdvertisementAddEditApiResponse, AdvertisementAddEditApiArg>({
      query: (queryArg) => ({
        url: `/advertisement/${queryArg.advertisementId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationAdvertisementAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarBodyType: build.query<CarBodyTypeApiResponse, CarBodyTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_body_type/data/${queryArg.carBodyTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarBodyType: build.mutation<CarBodyTypeAddEditApiResponse, CarBodyTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_body_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarBodyTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarBodyType: build.mutation<CarBodyTypeAddEditApiResponse, CarBodyTypeAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_body_type/${queryArg.carBodyTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarBodyTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarConditions: build.query<GetCarConditionsApiResponse, GetCarConditionsApiArg>({
      query: (queryArg) => ({
        url: `/car_condition/data/${queryArg.carConditionId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarCondition: build.mutation<CarConditionAddEditApiResponse, CarConditionAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_condition/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarConditionAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarCondition: build.mutation<CarConditionAddEditApiResponse, CarConditionAddEditApiArg>({
      query: (queryArg) => ({
        url: `/car_condition/${queryArg.carConditionId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarConditionAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarPurchaseTypeData: build.query<GetCarPurchaseTypeDataApiResponse, GetCarPurchaseTypeDataApiArg>({
      query: (queryArg) => ({
        url: `/car_purchase_type/data/${queryArg.carPurchaseTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarPurchaseType: build.mutation<AddEditCarPurchaseTypeApiResponse, AddEditCarPurchaseTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_purchase_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarPurchaseTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarFuelTypeData: build.query<GetCarFuelTypeDataApiResponse, GetCarFuelTypeDataApiArg>({
      query: (queryArg) => ({
        url: `/car_fuel_type/data/${queryArg.carPurchaseTypeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCarFuelType: build.mutation<AddEditCarFuelTypeApiResponse, AddEditCarFuelTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_fuel_type/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCarFuelTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarFuelType: build.mutation<AddEditCarFuelTypeApiResponse, AddEditCarFuelTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_fuel_type/${queryArg.carFuelTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarFuelTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCarPurchaseType: build.mutation<AddEditCarPurchaseTypeApiResponse, AddEditCarPurchaseTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_purchase_type/${queryArg.carPurchaseTypeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCarPurchaseTypeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCarPurchaseTypes: build.mutation<SearchCarPurchaseTypesApiResponse, SearchCarPurchaseTypesApiArg>({
      query: (queryArg) => ({
        url: `/car_purchase_type/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCarPurchaseTypeView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCarPurchaseType: build.mutation<DeleteCarPurchaseTypeApiResponse, DeleteCarPurchaseTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_purchase_type/${queryArg.carPurchaseTypeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchFuelTypes: build.mutation<SearchFuelTypesApiResponse, SearchFuelTypesApiArg>({
      query: (queryArg) => ({
        url: `/car_fuel_type/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCarFuelTypeView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteFuelType: build.mutation<DeleteCarFuelTypeApiResponse, DeleteCarFuelTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_fuel_type/${queryArg.carFueTypeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchBodyType: build.mutation<SearchCarBodyTypeApiResponse, SearchCarBodyTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_body_type/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCarBodyTypeView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteBodyType: build.mutation<DeleteCarBodyTypeApiResponse, DeleteCarBodyTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_body_type/${queryArg.carBodyTypeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCarMakes: build.mutation<SearchCarMakesApiResponse, SearchCarMakesApiArg>({
      query: (queryArg) => ({
        url: `/car_make/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCarMakeTypeView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCarMake: build.mutation<DeleteCarMakeApiResponse, DeleteCarMakeApiArg>({
      query: (queryArg) => ({
        url: `/car_make/${queryArg.carMakeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCarDrivetrain: build.mutation<SearchCarDrivetrainApiResponse, SearchCarDrivetrainApiArg>({
      query: (queryArg) => ({
        url: `/car_drivetrain/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCarDrivetrainView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCarDrivetrain: build.mutation<DeleteCarDrivetrainApiResponse, DeleteCarDrivetrainApiArg>({
      query: (queryArg) => ({
        url: `/car_drivetrain/${queryArg.carDrivetrainId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchConditions: build.mutation<SearchCarConditionsApiResponse, SearchCarConditionsApiArg>({
      query: (queryArg) => ({
        url: `/car_condition/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCarConditionView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCondition: build.mutation<DeleteCarConditionApiResponse, DeleteCarConditionApiArg>({
      query: (queryArg) => ({
        url: `/car_condition/${queryArg.carConditionId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchAdvertisements: build.mutation<SearchAdvertisementsApiResponse, SearchAdvertisementsApiArg>({
      query: (queryArg) => ({
        url: `/advertisement/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationAdvertisementView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchTitleType: build.mutation<SearchCarTitleTypeApiResponse, SearchCarTitleTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_title_type/search/`,
        method: 'POST',
        body: queryArg.uspConfigurationCarTitleTypeView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteTitleType: build.mutation<DeleteTitleTypeApiResponse, DeleteTitleTypeApiArg>({
      query: (queryArg) => ({
        url: `/car_title_type/${queryArg.titleTypeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteAdvertisement: build.mutation<DeleteAdvertisementsApiResponse, DeleteAdvertisementsApiArg>({
      query: (queryArg) => ({
        url: `/advertisement/${queryArg.advertisementId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    worklistLogin: build.mutation<WorklistLoginApiResponse, WorklistLoginApiArg>({
      query: (queryArg) => ({
        url: `/worklist/login/${queryArg.worklistId}/`,
        method: 'PATCH',
        body: queryArg.uspCollectionWorklistCommon,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    worklistNextAccount: build.mutation<WorklistNextAccountApiResponse, WorklistNextAccountApiArg>({
      query: (queryArg) => ({
        url: `/worklist/next_account/${queryArg.worklistId}/`,
        method: 'POST',
        body: queryArg.uspCollectionWorklistGetNextAccount,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    collectionWorklistCommon: build.mutation<CollectionsWorklistCommonApiResponse, CollectionsWorklistCommonApiArg>({
      query: (queryArg) => ({
        url: `/worklist/view/`,
        method: 'POST',
        body: queryArg.uspCollectionWorklistCommon,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    collectionWorklist: build.mutation<SearchWorklistApiResponse, SearchWorklistApiArg>({
      query: (queryArg) => ({
        url: `/worklist/view_accounts/`,
        method: 'POST',
        body: queryArg.uspCollectionWorklistCommon,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    worklistCreate: build.mutation<WorklistCreateApiResponse, WorklistCreateApiArg>({
      query: (queryArg) => ({
        url: `/worklist/create/`,
        method: 'PUT',
        body: queryArg.uspCollectionWorklistCreate,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchAccounts: build.mutation<SearchAccountApiResponse, SearchAccountApiArg>({
      query: (queryArg) => ({
        url: `/accounts/search/`,
        method: 'POST',
        body: queryArg.uspAccountSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addAccountSideNote: build.mutation<AccountSideNoteApiResponse, AccountSideNoteApiArg>({
      query: (queryArg) => ({
        url: `/accounts/side_note/${queryArg.dealId}/`,
        method: 'POST',
        body: queryArg.uspAccountSideNoteAdd,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    accountsSettingsEdit: build.mutation<AccountsSettingsApiResponse, AccountsSettingsApiArg>({
      query: (queryArg) => ({
        url: `/accounts/settings/${queryArg.dealId}/`,
        method: 'PATCH',
        body: queryArg.uspAccountSettingEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    accountsSettingsDealEdit: build.mutation<AccountsSettingsDealApiResponse, AccountsSettingsDealApiArg>({
      query: (queryArg) => ({
        url: `/accounts/settings/deal/${queryArg.dealId}/`,
        method: 'PATCH',
        body: queryArg.uspAccountSettingDealEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchUser: build.mutation<SearchUserApiResponse, SearchUserApiArg>({
      query: (queryArg) => ({
        url: `/users/search/`,
        method: 'POST',
        body: queryArg.uspUserSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editUser: build.mutation<EditUserApiResponse, EditUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/`,
        method: 'PATCH',
        body: queryArg.uspUserAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.userId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getVendorType: build.mutation<GetVendorTypeApiResponse, GetVendorTypeApiArg>({
      query: (queryArg) => ({
        url: `/lists/vendors/vendor_type/`,
        method: 'POST',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getVendorIRS: build.query<GetVendorIRSApiResponse, GetVendorIRSApiArg>({
      query: (queryArg) => ({
        url: `/lists/vendors/irs1099_type/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsFavoriteUpdate: build.mutation<ReportsFavoriteUpdateApiResponse, ReportsFavoriteUpdateApiArg>({
      query: (queryArg) => ({
        url: `/reports/favorite/update/`,
        method: 'PATCH',
        body: queryArg.uspReportFavoriteUpdate,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsInventory: build.mutation<ReportsInventoryApiResponse, ReportsInventoryApiArg>({
      query: (queryArg) => ({
        url: `/reports/inventory/inventory_aging/`,
        method: 'POST',
        body: queryArg.uspReportInventoryAging,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsAccountingAPAging: build.mutation<ReportsAccountingAPAgingApiResponse, ReportsAccountingAPAgingApiArg>({
      query: (queryArg) => ({
        url: `/reports/accounting/ap_aging/`,
        method: 'POST',
        body: queryArg.uspReportAccountsPayableAging,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsAccountingSubsidiaryReconciliation: build.mutation<ReportsAccountingSubsidiaryReconApiResponse, ReportsAccountingSubsidiaryReconApiArg>({
      query: (queryArg) => ({
        url: `/reports/accounting/subsidiary_recon/`,
        method: 'POST',
        body: queryArg.uspReportSubsidiaryReconciliation,
      }),
    }),

    reportsAccountingPayments: build.mutation<ReportsAccountingPaymentsApiResponse, ReportsAccountingPaymentsApiArg>({
      query: (queryArg) => ({
        url: `/reports/accounting/payments/`,
        method: 'POST',
        body: queryArg.uspReportPayment,
      }),
    }),

    reportsAccountsARAging: build.mutation<ReportsAccountsARAgingApiResponse, ReportsAccountsARAgingApiArg>({
      query: (queryArg) => ({
        url: `/reports/accounts/ar_aging/`,
        method: 'POST',
        body: queryArg.uspReportAccountsReceivableCommon,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsAccountsARRecency: build.mutation<ReportsAccountsARRecencyApiResponse, ReportsAccountsARRecencyApiArg>({
      query: (queryArg) => ({
        url: `/reports/accounts/ar_recency/`,
        method: 'POST',
        body: queryArg.uspReportAccountsReceivableCommon,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    reportsAccountsChargeOffs: build.mutation<ReportsAccountsChargeOffsApiResponse, ReportsAccountsChargeOffsApiArg>({
      query: (queryArg) => ({
        url: `/reports/accounts/charge_off/`,
        method: 'POST',
        body: queryArg.uspReportAccountsChargeOffs,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsAccountsReceivables: build.mutation<ReportsAccountsReceivablesApiResponse, ReportsAccountsReceivablesApiArg>({
      query: (queryArg) => ({
        url: `/reports/accounts/receivables/`,
        method: 'POST',
        body: queryArg.uspReportReceivables,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportSalesTradeLiens: build.mutation<ReportsSalesTradeLiensApiResponse, ReportsSalesTradeLiensApiArg>({
      query: (queryArg) => ({
        url: `/reports/sales/trade_liens/`,
        method: 'POST',
        body: queryArg.uspReportTradeLien,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsSales: build.mutation<ReportsSalesApiResponse, ReportsSalesApiArg>({
      query: (queryArg) => ({
        url: `/reports/sales/sales/`,
        method: 'POST',
        body: queryArg.uspReportSales,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsSalesTax: build.mutation<ReportsSalesTaxApiResponse, ReportsSalesTaxApiArg>({
      query: (queryArg) => ({
        url: `/reports/sales/sales_tax/`,
        method: 'POST',
        body: queryArg.uspReportSalesTax,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsSalesCommissions: build.mutation<ReportsSalesCommissionsApiResponse, ReportsSalesCommissionsApiArg>({
      query: (queryArg) => ({
        url: `/reports/sales/sales_commissions/`,
        method: 'POST',
        body: queryArg.uspReportSalesCommissions,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    reportsFavorite: build.query<ReportsFavoriteApiResponse, ReportsFavoriteApiArg>({
      query: (queryArg) => ({
        url: `/reports/favorite/data/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getVendor: build.query<GetVendorListApiResponse, GetVendorListApiArg>({
      query: (queryArg) => ({
        url: `/vendor/${queryArg.vendorId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    vendorLists: build.mutation<AddVendorListsApiResponse, AddVendorListsApiArg>({
      query: (queryArg) => ({
        url: `/lists/vendors/`,
        method: 'POST',
        body: queryArg.uspVendorLists,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    addVendor: build.mutation<AddVendorApiResponse, AddVendorApiArg>({
      query: (queryArg) => ({
        url: `/vendor/`,
        method: 'PUT',
        body: queryArg.uspVendorAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    searchVendor: build.mutation<SearchVendorApiResponse, SearchVendorApiArg>({
      query: (queryArg) => ({
        url: `/vendor/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    editVendor: build.mutation<EditVendorApiResponse, EditVendorApiArg>({
      query: (queryArg) => ({
        url: `/vendor/${queryArg.vendorId}/`,
        method: 'PATCH',
        body: queryArg.uspVendorAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    deleteVendor: build.mutation<DeleteVendorApiResponse, DeleteVendorApiArg>({
      query: (queryArg) => ({
        url: `/vendor/${queryArg.vendorId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    dashboardInventory: build.mutation<DashboardInventoryApiResponse, DashboardInventoryApiArg>({
      query: (queryArg) => ({
        url: `/dashboard/inventory/`,
        method: 'POST',
        body: queryArg.uspDashboardCommonResult,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),
    dealerSearch: build.mutation<DealerSearchApiResponse, DealerSearchApiArg>({
      query: (queryArg) => ({
        url: `/dealer/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    dealerDelete: build.mutation<DealerDeleteApiResponse, DealerDeleteApiArg>({
      query: (queryArg) => ({
        url: `/dealer/${queryArg?.dealerId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getDealer: build.query<DealerGetApiResponse, DealerGetApiArg>({
      query: (queryArg) => ({
        url: `/dealer/${queryArg?.dealerId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addDealer: build.mutation<DealerAddEditApiResponse, DealerAddEditApiArg>({
      query: (queryArg) => ({
        url: `/dealer/`,
        method: 'PUT',
        body: queryArg.uspDealerAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editDealer: build.mutation<DealerAddEditApiResponse, DealerAddEditApiArg>({
      query: (queryArg) => ({
        url: `/dealer/${queryArg.dealerId}/`,
        method: 'PATCH',
        body: queryArg.uspDealerAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCallOutcome: build.mutation<SearchOutcomeApiResponse, SearchOutcomeApiArg>({
      query: (queryArg) => ({
        url: `/call_outcome/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCallOutcome: build.mutation<DeleteOutcomeApiResponse, DeleteOutcomeApiArg>({
      query: (queryArg) => ({
        url: `/call_outcome/${queryArg.callOutcomeId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCallOutcome: build.mutation<AddEditOutcomeApiResponse, AddEditOutcomeApiArg>({
      query: (queryArg) => ({
        url: `/call_outcome/`,
        method: 'PUT',
        body: queryArg.uspConfigurationCallOutcomeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCallOutcome: build.mutation<AddEditOutcomeApiResponse, AddEditOutcomeApiArg>({
      query: (queryArg) => ({
        url: `/call_outcome/${queryArg.callOutcomeId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationCallOutcomeAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCallOutcome: build.query<GetOutcomeApiResponse, GetOutcomeApiArg>({
      query: (queryArg) => ({
        url: `/call_outcome/data/${queryArg.callOutcomeId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCityTaxes: build.mutation<SearchCityTaxesApiResponse, SearchCityTaxesApiArg>({
      query: (queryArg) => ({
        url: `/city_tax_rate/search/`,
        method: 'POST',
        body: queryArg.uspStateCityTaxRateView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCityTaxes: build.mutation<AddEditCityTaxesApiResponse, AddEditCityTaxesApiArg>({
      query: (queryArg) => ({
        url: `/city_tax_rate/`,
        method: 'PUT',
        body: queryArg.uspStateCityTaxRateView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCityTaxes: build.mutation<AddEditCityTaxesApiResponse, AddEditCityTaxesApiArg>({
      query: (queryArg) => ({
        url: `/city_tax_rate/${queryArg.cityTaxRateId}/`,
        method: 'PATCH',
        body: queryArg.uspStateCityTaxRateView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCityTaxes: build.query<GetCityTaxesApiResponse, GetCityTaxesApiArg>({
      query: (queryArg) => ({
        url: `/city_tax_rate/data/${queryArg.cityTaxRateId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchCountyTaxRate: build.mutation<SearchCountyTaxesApiResponse, SearchCountyTaxesApiArg>({
      query: (queryArg) => ({
        url: `/county_tax_rate/search/`,
        method: 'POST',
        body: queryArg.uspStateCountyTaxRateView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCountyTaxRate: build.query<GetCountyTaxesApiResponse, GetCountyTaxesApiArg>({
      query: (queryArg) => ({
        url: `/county_tax_rate/data/${queryArg.countyTaxRateId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addCountyTaxRate: build.mutation<AddEditCountyTaxesApiResponse, AddEditCountyTaxesApiArg>({
      query: (queryArg) => ({
        url: `/county_tax_rate/`,
        method: 'PUT',
        body: queryArg.uspStateCountyTaxRateAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editCountyTaxRate: build.mutation<AddEditCountyTaxesApiResponse, AddEditCountyTaxesApiArg>({
      query: (queryArg) => ({
        url: `/county_tax_rate/${queryArg.countyTaxRateId}/`,
        method: 'PATCH',
        body: queryArg.uspStateCountyTaxRateAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getDealerReserve: build.query<GetDealerReserveApiResponse, GetDealerReserveApiArg>({
      query: (queryArg) => ({
        url: `/dealer_reserve/data/${queryArg.dealerReserveId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchDealerReserve: build.mutation<SearchDealerReserveApiResponse, SearchDealerReserveApiArg>({
      query: (queryArg) => ({
        url: `/dealer_reserve/search/`,
        method: 'POST',
        body: queryArg.simpleSearch,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editDealerReserve: build.mutation<EditDealerReserveApiResponse, EditDealerReserveApiArg>({
      query: (queryArg) => ({
        url: `/dealer_reserve/${queryArg.dealerReserveId}/`,
        method: 'PATCH',
        body: queryArg.uspConfigurationDealerReserveEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteDealerReserve: build.mutation<DeleteDealerReserveApiResponse, DeleteDealerReserveApiArg>({
      query: (queryArg) => ({
        url: `/dealer_reserve/${queryArg.dealerReserveId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    uploadFormPDF: build.mutation<UploadFormPDFApiResponse, UploadFormPDFApiArg>({
      query: (queryArg) => {
        // Create a FormData object to handle file upload
        const formData = new FormData()
        formData.append('file', queryArg.file as File)
        formData.append('dataSource', queryArg.dataSource)
        formData.append('formState', queryArg.formState)
        formData.append('name', queryArg.name)

        // Optional fields
        if (queryArg.version) {
          formData.append('version', queryArg.version)
        }
        if (queryArg.isActive !== undefined) {
          formData.append('isActive', String(queryArg.isActive))
        }

        return {
          url: `/forms/`,
          method: 'PUT',
          body: formData,
          headers: {
            'CLIENT-UID': queryArg['CLIENT-UID'],
            'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
            'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
          },
        }
      },
    }),

    formsBilling: build.mutation<FormsBillingApiResponse, FormsBillingApiArg>({
      query: (queryArg) => ({
        url: `/forms/billing/${queryArg.dealId}/`,
        method: 'PUT',
        body: queryArg.uspForBilling,
      }),
    }),

    getFormData: build.query<GetFormDataApiResponse, GetFormDataApiArg>({
      query: (queryArg) => ({
        url: `/forms/${queryArg.formId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getFormTokenData: build.query<GetFormTokenDataApiResponse, GetFormTokenDataApiArg>({
      query: (queryArg) => ({
        url: `/forms/tokens/${queryArg.dataSource}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addFormMapping: build.mutation<AddFormMappingApiResponse, AddFormMappingApiArg>({
      query: (queryArg) => ({
        url: `/forms/mapping/`,
        method: 'PUT',
        body: queryArg.uspFormMappingAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editFormMapping: build.mutation<AddFormMappingApiResponse, AddFormMappingApiArg>({
      query: (queryArg) => ({
        url: `/forms/mapping/${queryArg.formMappingId}/`,
        method: 'PATCH',
        body: queryArg.uspFormMappingAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getFormMappingData: build.query<GetFormMappingDataApiResponse, GetFormMappingDataApiArg>({
      query: (queryArg) => ({
        url: `/forms/mapping/${queryArg.formMappingId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchForms: build.mutation<SearchFormsApiResponse, SearchFormsApiArg>({
      query: (queryArg) => {
        const formData = new FormData()
        formData.append('state', queryArg.state)
        formData.append('dataSource', queryArg.dataSource)
        formData.append('searchString', queryArg.searchString)
        formData.append('isIncludeInactive', String(queryArg.isIncludeInactive))
        return {
          url: `/forms/`,
          method: 'POST',
          body: formData,
          headers: {
            'CLIENT-UID': queryArg['CLIENT-UID'],
            'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
            'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
          },
        }
      },
    }),

    searchFormsPacks: build.mutation<SearchFormsPacksApiResponse, SearchFormsPacksApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/`,
        method: 'POST',
        body: queryArg.uspFormPackView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteFormMapping: build.mutation<DeleteFormMappingApiResponse, DeleteFormMappingApiArg>({
      query: (queryArg) => ({
        url: `/forms/mapping/${queryArg.formMappingId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteForm: build.mutation<DeleteFormApiResponse, DeleteFormApiArg>({
      query: (queryArg) => ({
        url: `/forms/${queryArg.formId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addFormPack: build.mutation<AddEditFormPackApiResponse, AddEditFormPackApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/`,
        method: 'PUT',
        body: queryArg.uspFormPackAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editFormPack: build.mutation<AddEditFormPackApiResponse, AddEditFormPackApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/${queryArg.formPackId}/`,
        method: 'PATCH',
        body: queryArg.uspFormPackAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getFormPackData: build.query<GetFormPackDataApiResponse, GetFormPackDataApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/${queryArg.formPackId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteFormPack: build.mutation<DeleteFormPackApiResponse, DeleteFormPackApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/${queryArg.formPackId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    searchFormList: build.query<GetFormListApiResponse, GetFormListApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/detail/document_list/${queryArg.formPackId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    packReorder: build.mutation<PackReorderApiResponse, PackReorderApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/detail/reorder/${queryArg.formPackId}/`,
        method: 'POST',
        body: queryArg.uspFormPackDetailReorder,
      }),
    }),

    deleteFormPackDetails: build.mutation<DeleteFormPackDetailsApiResponse, DeleteFormPackDetailsApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/detail/${queryArg.formPackId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    addFormPackDetail: build.mutation<AddEditFormPackDetailApiResponse, AddEditFormPackDetailApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/detail/`,
        method: 'PUT',
        body: queryArg.uspFormPackDetailAddEdit,

        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    editFormPackDetail: build.mutation<AddEditFormPackDetailApiResponse, AddEditFormPackDetailApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/detail/${queryArg.formPackId}/`,
        method: 'PATCH',
        body: queryArg.uspFormPackDetailAddEdit,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getFormPackDetail: build.query<GetFormPackDetailApiResponse, GetFormPackDetailApiArg>({
      query: (queryArg) => ({
        url: `/forms/packs/detail/${queryArg.formPackId}/`,
        method: 'GET',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    formsPrompts: build.mutation<FormsPromptsApiResponse, FormsPromptsApiArg>({
      query: (queryArg) => ({
        url: `/forms/prompts/`,
        method: 'POST',
        body: queryArg.uspGetDataFormPrompts,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    printFormDeal: build.mutation<PrintFormDealApiResponse, PrintFormDealApiArg>({
      query: (queryArg) => ({
        url: `/print/deal/${queryArg.dealId}/`,
        method: 'POST',
        body: queryArg.printFormDeal,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
        responseHandler: async (response) => {
          if(queryArg.printFormDeal[0]?.IsDocusign)
            return response
          else
         { const blob = await response.blob()
          const url = window.URL.createObjectURL(blob)
          window.open(url, '_blank')}
        },
      }),
    }),

    paymentsDetails: build.mutation<PaymentsDetailsApiResponse, PaymentsDetailsApiArg>({
      query: (queryArg) => ({
        url: `/payments/view/detail/${queryArg.dealId}/`,
        method: 'POST',
        body: queryArg.uspPaymentView,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    getCarImages: build.query<GetCarImagesApiResponse, GetCarImagesApiArg>({
      query: (queryArg) => ({
        url: `/cars/images/${queryArg.carId}/`,
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    deleteCarImage: build.mutation<DeleteCarImagesApiResponse, DeleteCarImagesApiArg>({
      query: (queryArg) => ({
        url: `/cars/images/${queryArg.carId}/${queryArg.carImageId}/`,
        method: 'DELETE',
        headers: {
          'CLIENT-UID': queryArg['CLIENT-UID'],
          'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
          'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
        },
      }),
    }),

    uploadCarImage: build.mutation<UploadCarImagesApiResponse, UploadCarImagesApiArg>({
      query: (queryArg) => {
        const formData = new FormData()
        for (const file of queryArg.Files) {
          formData.append('Files', file.file, file.name)
        }

        return {
          url: `/cars/images/upload/${queryArg.carId}/`,
          method: 'POST',
          body: formData,
          headers: {
            'CLIENT-UID': queryArg['CLIENT-UID'],
            'COMPANY-TYPE': queryArg['COMPANY-TYPE'],
            'COMPANY-BRANCH': queryArg['COMPANY-BRANCH'],
          },
        }
      },
    }),
  }),
})

export const {
  usePackReorderMutation,
  useGetDealFlagDataQuery,
  useLazyGetDealFlagDataQuery,
  useAddDealsFlagMutation,
  useEditDealsFlagMutation,
  useDeleteDealsFlagMutation,
  useGetDealsFlagMutation,
  useFormsBillingMutation,
  useDeleteCreditAppLookingAtMutation,
  useDeleteCreditAppLookingForMutation,
  useCreditAppLookingAtMutation,
  useGetCreditAppLookingForQuery,
  useLazyGetCreditAppLookingForQuery,
  useAddCreditAppLookingForMutation,
  useEditCreditAppLookingForMutation,
  useReportsAccountingPaymentsMutation,
  useReportsAccountingSubsidiaryReconciliationMutation,
  useLazyGetCarImagesQuery,
  useDeleteCarImageMutation,
  useUploadCarImageMutation,
  usePaymentsDetailsMutation,
  usePrintFormDealMutation,
  useFormsPromptsMutation,
  useLazyGetFormPackDetailQuery,
  useAddFormPackDetailMutation,
  useEditFormPackDetailMutation,
  useDeleteFormPackDetailsMutation,
  useLazySearchFormListQuery,
  useAccountsPaymentVoidMutation,
  useSearchFormsPacksMutation,
  useAddFormPackMutation,
  useEditFormPackMutation,
  useLazyGetFormPackDataQuery,
  useDeleteFormPackMutation,
  useSearchFormsMutation,
  useUploadFormPDFMutation,
  useLazyGetFormDataQuery,
  useLazyGetFormTokenDataQuery,
  useAddFormMappingMutation,
  useEditFormMappingMutation,
  useLazyGetFormMappingDataQuery,
  useDeleteFormMappingMutation,
  useDeleteFormMutation,
  useLazyGetDealerReserveQuery,
  useEditDealerReserveMutation,
  useDeleteDealerReserveMutation,
  useSearchDealerReserveMutation,
  useSearchCountyTaxRateMutation,
  useLazyGetCountyTaxRateQuery,
  useAddCountyTaxRateMutation,
  useEditCountyTaxRateMutation,
  useLazyGetCityTaxesQuery,
  useAddCityTaxesMutation,
  useEditCityTaxesMutation,
  useSearchCityTaxesMutation,
  useLazyGetCallOutcomeQuery,
  useAddCallOutcomeMutation,
  useEditCallOutcomeMutation,
  useDeleteCallOutcomeMutation,
  useSearchCallOutcomeMutation,
  useEditDealerMutation,
  useAddDealerMutation,
  useLazyGetDealerQuery,
  useDealerSearchMutation,
  useDealerDeleteMutation,
  useAcceptInvitationRegistrationMutation,
  useAddCarCostMutation,
  useCarFloorplanEditMutation,
  useAddCarMutation,
  useLazyGetCarPaymentDetailsQuery,
  useGetCarPaymentMutation,
  useEditCarNoteMutation,
  useAddCarNoteMutation,
  useAddCollectionResultMutation,
  useAddCreditAppStatusMutation,
  useAddCreditAppStipulationMutation,
  useAddDealCommissionMutation,
  useAddDealMutation,
  useAddDealReferralMutation,
  useAddDealStatusMutation,
  useAddNotesMutation,
  useAddExtensionMutation,
  useNotePinMutation,
  useFollowupCompleteMutation,
  useLazyGetInsuranceDataQuery,
  useAddInsuranceMutation,
  useAddIpAddressMutation,
  useAddLotsMutation,
  useAddPaymentCheckMutation,
  useAddPaymentFormMutation,
  useLazyPaymentMethodDataQuery,
  useAddPaymentMethodMutation,
  useAddReferencesMutation,
  useAddRepoTypeMutation,
  useAddSideNoteMutation,
  useLazySideNoteDataQuery,
  useAddReferenceRelationshipTypeMutation,
  useEditReferenceRelationshipTypeMutation,
  useLazyGetReferenceRelationshipDataQuery,
  useAddCarMakeMutation,
  useEditCarMakeMutation,
  useLazyGetCarMakeDataQuery,
  useAddUserMutation,
  useLazyGetUserRoleAccessRightsQuery,
  useAddUserRoleMutation,
  useLazyReportsFavoriteQuery,
  useReportsAccountsReceivablesMutation,
  useReportsAccountsARRecencyMutation,
  useReportsAccountsChargeOffsMutation,
  useReportsAccountsARAgingMutation,
  useReportsAccountingAPAgingMutation,
  useReportsSalesCommissionsMutation,
  useReportsSalesMutation,
  useReportsSalesTaxMutation,
  useReportsFavoriteUpdateMutation,
  useReportSalesTradeLiensMutation,
  useReportsInventoryMutation,
  useGetVendorIRSQuery,
  useLazyGetAccountTitleQuery,
  useGetVendorTypeMutation,
  useLazyGetVendorQuery,
  useVendorListsMutation,
  useAddVendorMutation,
  useCarExpenseGetMutation,
  useCarNoteGetMutation,
  useCreateInvitationMutation,
  useEditAccountBankruptcyMutation,
  useEditAccountTitleMutation,
  useGetAccountPayHistoryMutation,
  useGetAccountNotesHistoryMutation,
  useLazyGetAccountNotesHistoryListQuery,
  useGetAccountDueAmountMutation,
  useLazyGetAccountBankruptcyQuery,
  useLazyGetAccountOverviewQuery,
  useAccountsChargeOffMutation,
  useLazyGetAccountDealQuery,
  useAddPaymentsTransactionMutation,
  useCreditAppListApplicantQuery,
  useGetLenderTypeQuery,
  useDeleteLenderMutation,
  useEditLenderMutation,
  useAddLenderMutation,
  useGetListDealStatusQuery,
  useGetDealersListQuery,
  useGetDealLenderListQuery,
  useGetListDealsCollectorQuery,
  useDeleteCarNoteMutation,
  useDeleteCollectionResultMutation,
  useDeleteCreditAppMutation,
  useDeleteCreditAppCoAplicantMutation,
  useDeleteCreditAppStatusMutation,
  useDeleteCreditAppStipulationMutation,
  useDeleteDealCommissionMutation,
  useDeleteDealMutation,
  useDeleteDealReferralMutation,
  useDeleteDealStatusMutation,
  useDeleteInsuranceMutation,
  useDeleteIpAddressMutation,
  useLazyGetLenderQuery,
  useSearchLenderMutation,
  useLazyListsCarsExpenseTypeQuery,
  useDeleteLotsMutation,
  useDeletePaymentCheckMutation,
  useDeletePaymentFormMutation,
  useDeletePaymentMethodMutation,
  useDeleteReferencesMutation,
  useDeleteRepoTypeMutation,
  useDeleteSideNoteMutation,
  useDeleteUserMutation,
  useDeleteUserRoleMutation,
  useDeleteVendorMutation,
  useDeleteDealTradeMutation,
  useEditCarMutation,
  useLazyGetCollectionResultDataQuery,
  useEditCollectionResultMutation,
  useAddContactFullMutation,
  useEditContactFullMutation,
  useEditCreditAppMutation,
  useAddStipulationMutation,
  useEditStipulationMutation,
  useLazyGetStipulationDataQuery,
  useEditCreditAppStipulationMutation,
  useEditDealCommissionMutation,
  useEditDealMutation,
  useEditDealProductMutation,
  useEditDealReferralMutation,
  useLazyStatusCreditAppDataQuery,
  useLazyDealStatusDataQuery,
  useEditDealStatusCreditMutation,
  useEditDealStatusMutation,
  useEditInsuranceMutation,
  useEditIpAddressMutation,
  useEditLotsMutation,
  useEditPaymentCheckMutation,
  useEditPaymentFormMutation,
  useEditPaymentMethodMutation,
  useEditReferencesMutation,
  useEditRepoTypeMutation,
  useEditRfcMutation,
  useEditSideNoteMutation,
  useEditStateMutation,
  useEditUserMutation,
  useEditUserRoleMutation,
  useEditVendorMutation,
  useGetCalculationForDealMutation,
  useAddInterestLevelMutation,
  useEditInterestLevelMutation,
  useLazyGetInterestLevelQuery,
  useGetDealAmortizationMutation,
  useAddDealInsuranceMutation,
  useLazyGetDealProductDataQuery,
  useGetDealProfitMutation,
  useLazyGetDealReferralDataQuery,
  useGetDealTradeQuery,
  useLazyGetDealTradeDataQuery,
  useLazyEditDealTradeQuery,
  useLazyAddDealTradeQuery,
  useGetMaturityDateForDealMutation,
  useLazyGetActiveCompanyTypeQuery,
  useGetTaxesMutation,
  useGetUserPermissionsDropdownMutation,
  useAccountingVoidCheckMutation,
  useGetAccountingLedgerMutation,
  useGetAccountingChartApAccountsMutation,
  useLazyGetAccountingCashAccountsQuery,
  useGetAccountStatusQuery,
  useLazyGetAccountingChartOfAccountCurrentDataQuery,
  useGetAccountingChartOfAccountCurrentMutation,
  useLazyGetAccountSubTypeQuery,
  useAddAccountingChartOfAccountMutation,
  useAddAccountingChartOfAccountItemMutation,
  useEditAccountingChartMutation,
  useDeleteAccountingChartMutation,
  useLazyCarsVinReadQuery,
  useLazyCompanyTypesQuery,
  useLazyContactsReferenceGetQuery,
  useLazyGetCarQuery,
  useAddCarTitleTypeMutation,
  useEditCarTitleTypeMutation,
  useLazyGetCarTitleTypeDataQuery,
  useAddCarDrivetrainMutation,
  useEditCarDrivetrainMutation,
  useLazyGetCarDrivetrainDataQuery,
  useLazyGetContactCreditQuery,
  useGetContactMutation,
  useLazyGetCreditAppStipulationQuery,
  useLazyGetDealCommissionQuery,
  useLazyGetDealInsuranceQuery,
  useLazyGetDealProductQuery,
  useLazyGetDealProfitDataQuery,
  useLazyGetDealQuery,
  useLazyGetDealReferralsQuery,
  useLazyGetDealTradeQuery,
  useLazyGetInfoByZipCodeQuery,
  useLazyGetListFeeQuery,
  useLazyGetListProductQuery,
  useLazyGetInvitationRegistrationQuery,
  useLazyCreditCardTypeDataQuery,
  useEditCreditCardTypeMutation,
  useAddCreditCardTypeMutation,
  useLazyCarPackFeeDataQuery,
  useAddCarPackFeeMutation,
  useEditCarPackFeeMutation,
  useLazyCarStatusDataQuery,
  useEditCarStatusMutation,
  useAddCarStatusMutation,
  useSearchCarStatusMutation,
  useDeleteCarStatusMutation,
  useSearchCarPackFeeMutation,
  useDeleteCarPackFeeMutation,
  useLazyGetCreditAppExpenseDataQuery,
  useEditCreditAppExpenseDataMutation,
  useSearchCreditAppExpenseMutation,
  useDeleteCreditAppExpenseMutation,
  useSearchCreditCardTypeMutation,
  useDeleteCreditCardTypeMutation,
  useLazyGetReferencesQuery,
  useLazyGetStateDealSettingsQuery,
  useLazyGetUserQuery,
  useLazyGetUserRoleQuery,
  useLoginCreateMutation,
  useModifyDealFinalizeUnwindSalesMutation,
  useCarExpenseDeleteMutation,
  useAddCarExpenseMutation,
  useEditCarExpenseMutation,
  useLazyCarExpenseDataQuery,
  useCarExpenseSearchMutation,
  useSearchCarMutation,
  useSearchCollectionResultMutation,
  useConfigurationEditDataMutation,
  useConfigurationDataMutation,
  useSearchCreditAppMutation,
  useSearchCreditAppStatusMutation,
  useSearchDealStatusMutation,
  useSearchDealsMutation,
  useSearchInsuranceMutation,
  useLazyIpAddressDataQuery,
  useSearchIpAddressMutation,
  useLazyGetLotQuery,
  useSearchLotsMutation,
  useLazyPaymentCheckDataQuery,
  useSearchPaymentCheckMutation,
  useLazyPaymentFormDataQuery,
  useSearchPaymentFormMutation,
  useSearchPaymentMethodMutation,
  useLazyRepoTypeDataQuery,
  useSearchRepoTypeMutation,
  useSearchRfcMutation,
  useSearchSideNoteMutation,
  useSearchStateMutation,
  useDeleteStipulationMutation,
  useDeleteAdvertisementMutation,
  useSearchStipulationsMutation,
  useSearchVendorsMutation,
  useAddVentorTypeMutation,
  useEditVentorTypeMutation,
  useLazyGetVendorTypeDataQuery,
  useDeleteVendorsMutation,
  useSearchInterestLevelsMutation,
  useDeleteInterestLevelMutation,
  useSearchReferencesRelationshipsTypesMutation,
  useDeleteReferenceRelationshipTypeMutation,
  useLazyGetCarPurchaseTypeDataQuery,
  useLazyGetAdvertisementQuery,
  useAddAdvertisementMutation,
  useEditAdvertisementMutation,
  useLazyGetCarBodyTypeQuery,
  useAddCarBodyTypeMutation,
  useEditCarBodyTypeMutation,
  useLazyGetCarConditionsQuery,
  useAddCarConditionMutation,
  useEditCarConditionMutation,
  useAddCarFuelTypeMutation,
  useEditCarFuelTypeMutation,
  useLazyGetCarFuelTypeDataQuery,
  useAddCarPurchaseTypeMutation,
  useEditCarPurchaseTypeMutation,
  useSearchCarPurchaseTypesMutation,
  useDeleteCarPurchaseTypeMutation,
  useSearchFuelTypesMutation,
  useDeleteFuelTypeMutation,
  useSearchCarMakesMutation,
  useSearchBodyTypeMutation,
  useDeleteBodyTypeMutation,
  useDeleteCarMakeMutation,
  useSearchCarDrivetrainMutation,
  useDeleteCarDrivetrainMutation,
  useSearchConditionsMutation,
  useDeleteConditionMutation,
  useSearchAdvertisementsMutation,
  useSearchTitleTypeMutation,
  useDeleteTitleTypeMutation,
  useWorklistLoginMutation,
  useWorklistNextAccountMutation,
  useCollectionWorklistCommonMutation,
  useCollectionWorklistMutation,
  useWorklistCreateMutation,
  useSearchAccountsMutation,
  useAddAccountSideNoteMutation,
  useSearchUserMutation,
  useAccountsSettingsDealEditMutation,
  useAccountsSettingsEditMutation,
  useLazyGetAccountRepoQuery,
  useLazyGetAccountingControlDataQuery,
  useLazyGetAccountingUnpaidBillsQuery,
  useLazyGetAccountingUnpaidVendorsQuery,
  useAccountingBillApproveToPayMutation,
  useAddAccountingAdjustingEntryMutation,
  useAddAccountingCheckMutation,
  useAccountingCheckSearchMutation,
  useAccountingCheckBillMutation,
  useSearchAccountingBillMutation,
  useDeleteAccountingBillMutation,
  useAddAccountingBankDepositMutation,
  useEditAccountRepoMutation,
  useSearchUserRoleMutation,
  useSearchVendorMutation,
  useUserLoggedInMutation,
  useDashboardInventoryMutation,
  useAddCarPaymentMutation,
  useSearchContactMutation,
} = injectedRtkApi
