import { convertStringToDate } from '../../../../../utils/dateUtils'
import removeMask from '../../../../../utils/removeMask'

export const transformToFormValues = (values?: any) => ({
  Status: values?.DealStatusID,
  Advertisement: values?.AdvertisementID,
  Mileage: values?.DeliveryMileage,
  LateGraceDays: values?.LateGraceDays?.toString(),
  LateChargeRate: values?.LateChargeRate?.toString(),
  LateChargeMax: values?.LateChargeMax?.toString(),
  LateChargeMin: values?.LateChargeMin?.toString(),
  StopAccruingLateFees: values?.IsStopAccruingLateCharge,
  StopAccruingLateFeeAsOfDate: values?.StopAccruingLateChargeDate,
  ChargebackFee: values?.ChargebackFee,
  InterestDaysPerYear: values?.DaysPerYear,
  StopAccruingInterest: values?.IsStopAccruingInterest,
  StopAccruingInterestDate: values?.StopAccruingInterestDate,
})

export const transformToRequestValues = (values?: any) => ({
  lateGraceDays: values?.LateGraceDays,
  lateChargeRate: values?.LateChargeRate,
  lateChargeMax: removeMask(values?.LateChargeMax as string),
  lateChargeMin: removeMask(values?.LateChargeMin as string),
  chargebackFee: removeMask(values?.ChargebackFee as string),
  daysPerYear: values?.InterestDaysPerYear,
  advertisementID: values?.Advertisement,
  dealStatusID: values?.Status,
  isStopAccruingInterest: values?.StopAccruingInterest,
  stopAccruingInterestDate: convertStringToDate(values?.StopAccruingInterestDate) || null,
  isStopAccruingLateCharge: values?.StopAccruingLateFees,
  stopAccruingLateChargeDate: convertStringToDate(values?.StopAccruingLateFeeAsOfDate) || null,
  mileage: removeMask(values?.Mileage),
})
