import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

type CollectionState = {
  worklistName: string
  worklistAccountID: number | null
  worklistID: number | null
}

export const slice = createSlice({
  name: 'worklistName',
  initialState: {
    worklistName: '',
    worklistAccountID: null,
    worklistID: null,
  } as CollectionState,
  reducers: {
    setWorklistName: (state, { payload }) => {
      state.worklistName = payload
    },
    setWorklistAccountID: (state, { payload }) => {
      state.worklistAccountID = payload
    },
    setWorklistID: (state, { payload }) => {
      state.worklistID = payload
    },
  },
})

export const getWorklistName = (state: RootState) => state.collections.worklistName
export const getWorklistAccountID = (state: RootState) => state.collections.worklistAccountID
export const getWorklistID = (state: RootState) => state.collections.worklistID

export const { setWorklistName, setWorklistAccountID, setWorklistID } = slice.actions

export default slice.reducer
