import { Box, Card, Modal, SxProps, Theme } from '@mui/material'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.main',
  boxShadow: 24,
  p: 4,
  minWidth: '80%',
}

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  children: JSX.Element
  sx?: SxProps<Theme>
}

const CenteredModal = ({ open, setOpen, children, sx }: Props) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Card sx={{ ...style, ...sx }}>{children}</Card>
    </Modal>
  )
}

export default CenteredModal
