import React, { useEffect, useMemo, useState } from 'react'
import * as Yup from 'yup'
import Header from '../components/Header/Header'
import { Box, Button, Card, Container, Grid, Paper, Stack, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { SettingsForm } from '../components/SettingsForm/SettingsForm'
import {
  useDeleteAccountingBillMutation,
  useGetAccountingChartApAccountsMutation,
  useGetAccountingChartOfAccountCurrentMutation,
  useLazyGetAccountingControlDataQuery,
  useLazyListsCarsExpenseTypeQuery,
  useVendorListsMutation,
} from '../../../../services'
import {
  TFullResponse,
  UspAccountingChartApAccountsView,
  UspAccountingChartOfAccountCurrentView,
  UspAccountingReturnControlDataResult,
  UspListCarExpenseTypeResult,
  VendorResponseSp,
} from '../../../../models'
import MultilineBillTable from '../components/MultilineBillTable/MultilineBillTable'
import { getCurrentDate } from '../../../../utils/general'
import AddMultilineBillingModal from '../components/AddMultilineBillingModal'
import { convertStringToDate } from '../../../../utils/dateUtils'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { withPermissionsDropdown } from '../../../../hoc'
import { CustomCircular } from '../../../../ui'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../constants'
import validateFormValues from '../../../../utils/validateForm'
import { accountingMultilineBillingValidation } from '../../../../validation/accountingMultilineBillingValidation'
import { getUserAccessRights } from '../../../../redux/slices/authSlice'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import FormProvider from '../../../../components/hook-form/form-provider'
import InvoiceNewEditAddress from '../components/MultilineNewEditAddress'
import InvoiceNewEditStatusDate from '../components/MultilineNewEditStatusDate'
import InvoiceNewEditDetails from '../components/MultilineNewEditDetails'
import { Form } from 'react-final-form'
import { useBoolean } from '../../../../hooks/use-boolean'
import { useRouter } from '../../../../routes/hooks'

const classes = generateUtilityClasses('form', ['root', 'pageBody', 'formItem', 'buttonWrap', 'closeBtn'])

const StyledGrid = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 2, 2),
    display: 'flex',
    alignItems: 'flexStart',
    justifyContent: 'justify-start',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(3, 3, 3, 3),
    marginTop: 'auto',
  },
  [`& .${classes.closeBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

const AccountingMultilineBilling = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const [initialValues, setInitialValues] = useState({})
  const [operationType, setOperationType] = useState<'add' | 'delete'>('add')
  const [requestDealID, setRequestDealID] = useState<null | number>(null)
  const [requestCarID, setRequestCarID] = useState<null | number>(null)
  const [openAddMultilineLineBillModal, setOpenAddMultilineLineBillModal] = useState(false)
  const [tableData, setTableData] = useState<TFullResponse[]>([])
  const [isFieldsLocked, setIsFieldsLocked] = useState(false)
  const [billToDelete, setBillToDelete] = useState<null | TFullResponse>(null)
  const [isEdit, setIsEdit] = useState(false)

  const [vendorList, { data: vendorListData, isLoading: vendorListIsLoading }] = useVendorListsMutation()
  const [chartOfAccountAp, { data: chartOfAccountApData, isLoading: chartOfAccountApIsLoading }] = useGetAccountingChartApAccountsMutation()
  const [getListsCarsExpense, { data: listsCarsExpenseData, isLoading: listsCarsExpenseIsLoading }] = useLazyListsCarsExpenseTypeQuery()
  const [getAccountingControlData, { data: accountingControlData, isLoading: accountingControlDataIsLoading }] =
    useLazyGetAccountingControlDataQuery()
  const [chartOfAccountBill, { data: chartOfAccountBillData, isLoading: chartOfAccountBillIsLoading }] =
    useGetAccountingChartOfAccountCurrentMutation()
  const [addBill, { data: addBillData, isSuccess: addBillIsSuccess, isLoading: addBillIsLoading }] = useDeleteAccountingBillMutation()

  const handleOpenAddMultilineLineBillModal = () => setOpenAddMultilineLineBillModal(true)
  const handleCloseAddMultilineLineBillModal = () => setOpenAddMultilineLineBillModal(false)

  const handleSubmit = (values: any) => {
    console.log('hello')
    setOperationType('add')
    addBill({
      accountingBillDelete: {
        vendorID: values?.invoiceTo?.ID || null,
        billDate: values?.BillDate === 'Invalid Date' ? null : convertStringToDate(values?.BillDate),
        billDueDate: values?.DueDate === 'Invalid Date' ? null : convertStringToDate(values?.DueDate),
        billNumber: values?.BillNumber || null,
        carExpenseType: values?.ExpenseType || null,
        isFloorplan: values?.IsFloorplan ? values?.IsFloorplan : false,
        billCoaID: values?.ChartOfAccountBill || null,
        carID: requestCarID || null,
        dealID: requestDealID || null,
        billAmount: values?.Amount?.replaceAll(',', '') || null,
        branchID: values?.Branch || null,
        description: values?.Note || null,
      },
    })
  }

  useEffect(() => {
    vendorList({
      uspVendorLists: {
        currentID: null,
        vendorType: null,
      },
    })
    chartOfAccountAp({})
    chartOfAccountBill({
      uspChartOfAccountView: {
        currentID: null,
        isAllowedSelectControlledAccount: true,
        isIncludeAccountsThatCannotBeSelected: true,
        isReturnAllCompanyTypes: false,
      },
    })
  }, [])

  useEffect(() => {
    setInitialValues({
      BillDate: getCurrentDate(),
      IsFloorplan: false,
    })
  }, [])

  useEffect(() => {
    if (addBillIsSuccess) {
      if (operationType === 'add' && addBillData?.FullResponse) {
        dispatch(enqueueNotification({ message: 'Success' }))
        setTableData([...tableData, addBillData.FullResponse])
      } else if (operationType === 'delete' && billToDelete?.BillID != null) {
        dispatch(enqueueNotification({ message: 'Item Deleted Successfully' }))
        const updatedTableData = tableData.filter((item) => item.BillID !== billToDelete.BillID)
        setTableData(updatedTableData)
      }

      handleCloseAddMultilineLineBillModal()
      setBillToDelete(null)
    }
  }, [addBillIsSuccess, addBillData, operationType, billToDelete, dispatch])

  const handleDeleteBill = (item: TFullResponse) => {
    setOperationType('delete')
    setBillToDelete(item)
    addBill({
      accountingBillDelete: {
        billID: item?.BillID,
        billDate: convertStringToDate(item?.BillDate),
        isDelete: true,
      },
    })
  }

  useEffect(() => {
    if (!userAccess.CanAddBill) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  const isLoading = useMemo(
    () =>
      vendorListIsLoading ||
      chartOfAccountApIsLoading ||
      listsCarsExpenseIsLoading ||
      accountingControlDataIsLoading ||
      chartOfAccountBillIsLoading ||
      addBillIsLoading,
    [
      vendorListIsLoading,
      chartOfAccountApIsLoading,
      listsCarsExpenseIsLoading,
      accountingControlDataIsLoading,
      chartOfAccountBillIsLoading,
      addBillIsLoading,
    ],
  )

  return (
    // <Container>
    //   <Form
    //     onSubmit={handleSubmit}
    //     initialValues={initialValues}
    //     validate={validateFormValues(accountingMultilineBillingValidation(openAddMultilineLineBillModal))}
    //   >
    //     {({ handleSubmit, invalid }: any) => (
    //       <form onSubmit={handleSubmit} className={classes.root}>
    //         <Grid container spacing={2} p={3}>
    //           <Grid item md={8} xs={12}>
    //             <MultilineBillTable
    //               setIsEdit={setIsEdit}
    //               handleDeleteBill={handleDeleteBill}
    //               tableData={tableData}
    //               addBill={addBill}
    //               setOperationType={setOperationType}
    //             />
    //           </Grid>
    //           <Grid item md={4} xs={12}>
    //             <SettingsForm
    //               isFieldsLocked={isFieldsLocked}
    //               handleOpenAddMultilineLineBillModal={handleOpenAddMultilineLineBillModal}
    //               vendorData={vendorListData as any}
    //               chartOfAccountApData={chartOfAccountApData as UspAccountingChartApAccountsView[]}
    //             />
    //           </Grid>
    //           <Grid item xs={12}>
    //             <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
    //               <Button variant='contained' onClick={() => navigate(appRoute?.AccountingBilling)}>
    //                 Close
    //               </Button>
    //             </Box>
    //           </Grid>
    //           {/* <Button color='primary' variant='contained' style={{ maxWidth: '30px', maxHeight: '42px', minWidth: '30px', minHeight: '42px' }}>
    //         </Button> */}
    //           <AddMultilineBillingModal
    //             setIsEdit={setIsEdit}
    //             isEdit={isEdit}
    //             errors={invalid}
    //             addBillIsSuccess={addBillIsSuccess}
    //             lockFields={() => setIsFieldsLocked(true)}
    //             open={openAddMultilineLineBillModal}
    //             handleClose={handleCloseAddMultilineLineBillModal}
    //             vendorListData={vendorListData as VendorResponseSp[]}
    //             listsCarsExpenseData={listsCarsExpenseData as UspListCarExpenseTypeResult[]}
    //             setRequestCarID={setRequestCarID}
    //             setRequestDealID={setRequestDealID}
    //             getListsCarsExpense={getListsCarsExpense}
    //             getAccountingControlData={getAccountingControlData}
    //             accountingControlData={accountingControlData as UspAccountingReturnControlDataResult}
    //             chartOfAccountBillData={chartOfAccountBillData as UspAccountingChartOfAccountCurrentView[]}
    //           />

    //           <CustomCircular open={isLoading} />
    //         </Grid>
    //       </form>
    //     )}
    //   </Form>
    // </Container>
    <Container>
      <CustomBreadcrumbs
        heading='Create a new invoice'
        links={[
          {
            name: 'Dashboard',
            // href: paths.dashboard.root,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      {/* <MultilineEditForm /> */}
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={validateFormValues(accountingMultilineBillingValidation(openAddMultilineLineBillModal))}
      >
        {({ handleSubmit, invalid }: any) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <InvoiceNewEditAddress isFieldsLocked={isFieldsLocked} vendorData={vendorListData as any} />

              <InvoiceNewEditStatusDate
                isFieldsLocked={isFieldsLocked}
                chartOfAccountApData={chartOfAccountApData as UspAccountingChartApAccountsView[]}
              />

              <InvoiceNewEditDetails
                handleOpenAddMultilineLineBillModal={handleOpenAddMultilineLineBillModal}
                setIsEdit={setIsEdit}
                handleDeleteBill={handleDeleteBill}
                tableData={tableData}
                addBill={addBill}
                setOperationType={setOperationType}
              />
            </Card>

            <AddMultilineBillingModal
              setIsEdit={setIsEdit}
              isEdit={isEdit}
              errors={invalid}
              addBillIsSuccess={addBillIsSuccess}
              lockFields={() => setIsFieldsLocked(true)}
              open={openAddMultilineLineBillModal}
              handleClose={handleCloseAddMultilineLineBillModal}
              vendorListData={vendorListData as VendorResponseSp[]}
              listsCarsExpenseData={listsCarsExpenseData as UspListCarExpenseTypeResult[]}
              setRequestCarID={setRequestCarID}
              setRequestDealID={setRequestDealID}
              getListsCarsExpense={getListsCarsExpense}
              getAccountingControlData={getAccountingControlData}
              accountingControlData={accountingControlData as UspAccountingReturnControlDataResult}
              chartOfAccountBillData={chartOfAccountBillData as UspAccountingChartOfAccountCurrentView[]}
            />
          </form>
        )}
      </Form>
    </Container>
  )
}

export default withPermissionsDropdown(AccountingMultilineBilling)
