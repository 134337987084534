import _ from 'lodash'
import { convertDateToString } from '../../../../../utils/dateUtils'
import { UspDealReferralResult, UspGetDataContact } from '../../../../../models'
import removeMask from '../../../../../utils/removeMask'

export const transformToRequestValues = (values?: any) => ({

  contactID: values?.ContactID || null,
  addressID: values?.AddressID,
  referralFee: removeMask(values?.ReferralFee),
  isCompletedW9: values?.IsW9Completed,
  note: values?.Note || null,
  title: values?.title,
  firstName: values?.AccountType === 'individual' ? values?.FirstName:null,
  middleName: values?.AccountType === 'individual' ? values?.MiddleName:null,
  lastName: values?.AccountType === 'individual' ? values?.LastName:null,
  suffix: values?.AccountType === 'individual' ? values?.Suffix:null,
  nickName: values?.AccountType === 'individual' ? values?.NickName:null,
  businessName: values?.AccountType === 'business' ? values?.BusinessName:null,
  dba: values?.dba,
  dateOfBirth: convertDateToString(values?.DateOfBirth) as string,
  taxIDNumber: values?.TaxIDNumber,
  dlState: values?.LicenseState,
  dlNumber: values?.LicenseNumber,
  dlIssuedDate: convertDateToString(values?.LicenseIssued) as string,
  dlExpireDate: convertDateToString(values?.LicenseExpiration) as string,
  email: values?.Email,
  isAllowSMS: values?.isAllowSMS,
  isAllowEmail: values?.isAllowEmail,
  isDoNotSell: values?.isDoNotSell,
  isRestrictCheckAch: values?.isRestrictCheckAch,
  address1: values?.Address1,
  address2: values?.Address2,
  city: values?.City,
  state: values?.State,
  postalCode: values?.PostalCode,
  county: values?.County,
  addressType: values?.addressType,
  isPrimaryAddress: values?.isPrimaryAddress,
  isAddressActive: true,
  isAddressUpdated: true,
  isContactUpdated: true,
  isPhoneUpdated: true,
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.PrimaryContactPhoneID,
      PhoneNumber: values?.PrimaryPhoneNumber,
      PhoneType: values?.PrimaryPhoneType || 'Cell',
      PhoneExtension: values?.PrimaryPhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
    },
    {
      ContactPhoneID: values?.SecondaryContactPhoneID,
      PhoneNumber: values?.SecondaryPhoneNumber,
      PhoneType: values?.SecondaryPhoneType || 'Home',
      PhoneExtension: values?.SecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
    },
  ],
})

export const transformToFormValues = (referralData?: UspDealReferralResult, getContactsData?: UspGetDataContact) => {
  // Initialize fields with default values
  let PrimaryContactPhoneID, PrimaryPhoneNumber, PrimaryPhoneExtension
  let SecondaryContactPhoneID, SecondaryPhoneNumber, SecondaryPhoneExtension
  let PrimaryPhoneType = 'Cell'
  let SecondaryPhoneType = 'Home'
  // Iterate over the ContactPhoneData array
  if (getContactsData?.ContactPhoneData) {
    for (const phoneData of getContactsData.ContactPhoneData) {
      if (phoneData.OrderOfPreference === 1) {
        PrimaryContactPhoneID = phoneData.ContactPhoneID
        PrimaryPhoneNumber = phoneData.PhoneNumber
        PrimaryPhoneType = phoneData.PhoneType || 'Cell'
        PrimaryPhoneExtension = phoneData.PhoneExtension
      } else if (phoneData.OrderOfPreference === 2) {
        SecondaryContactPhoneID = phoneData.ContactPhoneID
        SecondaryPhoneNumber = phoneData.PhoneNumber
        SecondaryPhoneType = phoneData.PhoneType || 'Home'
        SecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }
  return {
    ContactID: getContactsData?.ContactID,
    AccountType: getContactsData?.IsBusiness ? 'business' : 'individual',
    ReferralFee: referralData?.ReferralFee,
    IsW9Completed: referralData?.IsCompletedW9,
    Note: referralData?.Note,
    DateOfBirth: getContactsData?.DateOfBirth,
    TaxIDNumber: getContactsData?.TaxIDNumber,
    LicenseNumber: getContactsData?.DLNumber,
    LicenseState: getContactsData?.DLState,
    LicenseIssued: getContactsData?.DLIssuedDate,
    LicenseExpiration: getContactsData?.DLExpireDate,
    AddressID: _.first(getContactsData?.ContactAddressData)?.AddressID,
    Address1: _.first(getContactsData?.ContactAddressData)?.Address1,
    Address2: _.first(getContactsData?.ContactAddressData)?.Address2,
    City: _.first(getContactsData?.ContactAddressData)?.City,
    State: _.first(getContactsData?.ContactAddressData)?.State,
    PostalCode: _.first(getContactsData?.ContactAddressData)?.PostalCode,
    County: _.first(getContactsData?.ContactAddressData)?.County,
    BusinessName: getContactsData?.BusinessName,
    FirstName: getContactsData?.FirstName,
    MiddleName: getContactsData?.MiddleName,
    LastName: getContactsData?.LastName,
    Suffix: getContactsData?.Suffix,
    NickName: getContactsData?.NickName,
    PrimaryContactPhoneID,
    PrimaryPhoneType,
    PrimaryPhoneNumber,
    PrimaryPhoneExtension,
    SecondaryContactPhoneID,
    SecondaryPhoneNumber,
    SecondaryPhoneExtension,
    SecondaryPhoneType,
    Email: getContactsData?.Email,
  }
}
