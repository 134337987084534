import React from 'react'
import { styled } from '@mui/system'
import { CardHeader, Grid, Typography } from '@mui/material'
import { DateField, SSNTextField, TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

const IdentityForm = () => {
  const states = useSelector(getStates)

  return (
    <>
      <CardHeader title='Identity (Optional)' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={6}>
          <DateField name='DateOfBirth' label='Date Of Birth' disableFuture fullWidth />
        </Grid>
        <Grid item xs={6}>
          <SSNTextField name='TaxIDNumber' label='SSN' fullWidth />
        </Grid>
        <Grid item xs={4}>
          {/* TODO No Backend value*/}
          <TextField name='LicenseNumber' label='License Number' fullWidth autoComplete='off' /> {/*dlState*/}
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch // {/*dlState*/}
            name='LicenseState'
            label='State'
            options={states}
            optionKey='State'
            optionValue='State'
            autoComplete='off'
            // optionNone
          />
        </Grid>
        <Grid item xs={3}>
          {/* TODO No Backend value*/}
          <DateField name='LicenseIssued' label='License Issued' disableFuture fullWidth />
        </Grid>
        <Grid item xs={3}>
          {/* TODO No Backend value*/}
          <DateField name='LicenseExpiration' label='License Expiration' fullWidth />
        </Grid>
      </Grid>
    </>
  )
}

export default IdentityForm
