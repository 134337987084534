import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/system'
import { Grid, TextField, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { AmountField } from '../../../../../ui'
import { UspAccountingChartOfAccountCurrentView, UspAccountingReturnControlDataResult, UspListCarExpenseTypeResult } from '../../../../../models'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { useField, useForm } from 'react-final-form'
import { formatNumber } from '../../../../../utils/general'
const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },

  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  getAccountingControlData: (e: any) => void
  accountingControlData: UspAccountingReturnControlDataResult
  chartOfAccountBillData: UspAccountingChartOfAccountCurrentView[]
  getListsCarsExpense: (e: any) => void
  setRequestCarID: Dispatch<SetStateAction<null | number>>
  setRequestDealID: Dispatch<SetStateAction<null | number>>
  listsCarsExpenseData: UspListCarExpenseTypeResult[]
}

const DetailsForm = ({
  setRequestCarID,
  setRequestDealID,
  chartOfAccountBillData,
  getAccountingControlData,
  accountingControlData,
  getListsCarsExpense,
  listsCarsExpenseData,
}: Props) => {
  const form = useForm()
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [filteredExpenseTypes, setFilteredExpenseTypes] = useState<any[]>([])
  const [defaultBranchID, setDefaultBranchID] = useState('')
  const branchData = useSelector(getBranch)
  const [currentControlDetails, setCurrentControlDetails] = useState<string | null>(null)
  const [isControlled, setIsControlled] = useState(false)

  const {
    input: { value: chartOfAccountBillValue },
  } = useField('ChartOfAccountBill')
  const {
    input: { value: searchValue },
  } = useField('Search')

  const {
    input: { value: selectedExpenseType },
  } = useField('ExpenseType')

  useEffect(() => {
    const selectedAccount = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === chartOfAccountBillValue)
    const controlDetails = selectedAccount?.ControlDetails
    const controlSubType = selectedAccount?.ControlSubType

    let filteredExpenses
    setCurrentControlDetails(controlDetails as string)
    setIsControlled(selectedAccount?.IsControlled || false)

    if (controlSubType === 'Sold') {
      filteredExpenses = listsCarsExpenseData?.filter((item) => item?.IsAvailableForSoldInventory)
    } else if (controlSubType === 'Unsold') {
      filteredExpenses = listsCarsExpenseData?.filter((item) => item?.IsAvailableForUnsoldInventory)
    } else {
      filteredExpenses = listsCarsExpenseData
    }

    setFilteredExpenseTypes(filteredExpenses)

    if (selectedAccount && selectedAccount?.IsControlled) {
      getAccountingControlData({
        chartOfAccountId: selectedAccount?.ChartOfAccountID,
      })
    }
    if (selectedAccount && selectedAccount?.ControlDetails === 'Inventory') {
      getListsCarsExpense({
        isAddingNewExpense: 'true',
      })
    }
    if (!selectedAccount?.IsControlled) {
      form.change('searchResults', [])
      form.change('ExpenseType', null)
      setSearchResults([]) // reset
    }
  }, [chartOfAccountBillValue, chartOfAccountBillData, listsCarsExpenseData, isControlled])

  useEffect(() => {
    if (accountingControlData && accountingControlData?.ReturnData) {
      const returnData = JSON.parse(accountingControlData?.ReturnData)
      const filteredResults = returnData?.filter((item: any) => {
        const customerMatch = item?.Customer?.toLowerCase().includes(searchValue?.toLowerCase())
        const vehicleMatch = item?.Vehicle?.toLowerCase().includes(searchValue?.toLowerCase())
        return customerMatch || vehicleMatch
      })
      setDefaultBranchID(returnData?.[0]?.BranchID)
      setSearchResults(filteredResults)
    }
  }, [searchValue, accountingControlData, isControlled])

  const isExpenseTypePurchase = useMemo(() => {
    const selectedExpense = filteredExpenseTypes?.find((item) => item.ID === selectedExpenseType)
    return selectedExpense?.ExpenseType === 'Purchase'
  }, [filteredExpenseTypes, selectedExpenseType])

  const handleSelection = (selectedItem: any) => {
    const selectedAccount = chartOfAccountBillData?.find((item) => item?.ChartOfAccountID === chartOfAccountBillValue)
    if (selectedAccount && selectedItem) {
      if (selectedAccount?.ControlDetails === 'Inventory') {
        setRequestCarID(selectedItem)
      }
      if (selectedAccount?.ControlDetails === 'Account') {
        setRequestDealID(selectedItem)
      }
    }
  }

  const determineOptionKeyAndValue = (results: any[]) => {
    if (results[0]?.DealID) {
      return { optionKey: 'DealID', optionValue: 'Customer' }
    } else {
      return { optionKey: 'CarID', optionValue: 'Vehicle' }
    }
  }

  const { optionKey, optionValue } = useMemo(() => determineOptionKeyAndValue(searchResults), [searchResults])

  return (
    <Grid container item spacing={2} pt={1}>
      <Grid item xs={12}>
        <DropdownFieldSearch
          name='ChartOfAccountBill'
          label='Chart Of Account (Bill)'
          options={chartOfAccountBillData}
          optionKey={'ChartOfAccountID'}
          optionValue={'AccountNumberName'}
        />
      </Grid>
      {isControlled && (
        <Grid item xs={12}>
          <DropdownFieldSearch
            customDropdownWidth={500}
            onChange={(selectedItem: any) => handleSelection(selectedItem)}
            id='search-results'
            name='searchResults'
            label='Search Results'
            options={searchResults}
            optionKey={optionKey}
            optionValue={optionValue}
            getOptionLabel={(option: any) => option[optionKey] || ''}
            renderInput={(params: any) => <TextField {...params} label='Search Results' />}
            renderOption={(props: any, option: any) => (
              <li {...props} key={optionKey}>
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Grid item>
                    <Typography variant='body2'>{option.Customer || option.Vehicle}</Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: 'primary.main',
                      }}
                    >
                      {option.StockNumber} | {option.BranchCode}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <Typography variant='caption'>${formatNumber((option?.Balance as number) || (option?.InventoryCost as number))}</Typography>
                    <Typography
                      variant='body2'
                      sx={{
                        color: option?.AccountStatus === 'Active' ? 'green' : 'red',
                        fontWeight: option?.AccountStatus === 'Active' ? 'bold' : 'regular',
                      }}
                    >
                      {option.AccountStatus || option.CarStatus}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )}
          />
        </Grid>
      )}

      {currentControlDetails === 'Inventory' && (
        <Grid item xs={12} container>
          {isControlled && (
            <Grid item xs={!isExpenseTypePurchase ? 12 : 6}>
              <DropdownFieldSearch
                name='ExpenseType'
                label='Expense Type'
                options={filteredExpenseTypes}
                optionKey='ExpenseType'
                optionValue='ExpenseType'
              />
            </Grid>
          )}

          {isExpenseTypePurchase && (
            <>
              <Grid item xs={6}>
                <DropdownFieldSearch
                  name='IsFloorplan'
                  label='Is Floorplan?'
                  options={[
                    { key: true, value: 'Yes' },
                    { key: false, value: 'No' },
                  ]}
                  optionKey='key'
                  optionValue='value'
                />
              </Grid>
            </>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <DropdownFieldSearch
          initialValue={defaultBranchID}
          disabled={isControlled}
          name='Branch'
          label='Branch'
          options={branchData}
          optionKey='BranchID'
          optionValue='Branch'
        />
      </Grid>
      <Grid item xs={12}>
        <AmountField variant='standard' name='Amount' label='Amount' fullWidth />
      </Grid>
    </Grid>
  )
}

export default DetailsForm
