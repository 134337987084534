import {
  Box,
  CircularProgress,
  generateUtilityClasses,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material'
import { styled } from '@mui/system'
import { UspFormViewResult } from '../../../../../../models'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { storageGet, storageSet } from '../../../../../../utils/storage'
import { useForm, useFormState } from 'react-final-form'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../../utils/sorting'
import { CheckboxField } from '../../../../../../ui'
import TextField from '../../../../../../ui/TextField'

const classes = generateUtilityClasses('Grid', ['root', 'tableHead', 'tableCellName', 'buttonWrap', 'saveBtn'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    minHeight: 'calc(100vh - 80px)',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 0, 0, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  loading: boolean
  printFormsData: any
  isFormPackSelected: boolean
  setIsAnyRecordChecked: Dispatch<SetStateAction<boolean>>
  setSelectedRows: Dispatch<SetStateAction<any[]>>
  selectedRows: any[]
  searchFormsIsSuccess: boolean
  searchFormsPacksIsSuccess: boolean
}

const PrintFormsTableBlock = ({
  searchFormsIsSuccess,
  searchFormsPacksIsSuccess,
  loading,
  printFormsData,
  isFormPackSelected,
  setIsAnyRecordChecked,
  setSelectedRows,
  selectedRows,
}: Props) => {
  const initialOrder = (storageGet('forms_printing_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('forms_printing_order_by') || 'ID') as keyof UspFormViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspFormViewResult>(initialOrderBy)
  const form = useForm()
  const { resetFieldState } = useForm()
  const { values: formValues } = useFormState()

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspFormViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('forms_printing_order', isAsc ? 'desc' : 'asc')
    storageSet('forms_printing_order_by', property)
  }

  const createSortHandler = (property: keyof UspFormViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleAmountChange = (id: any, event: any) => {
    const value = event.target.value
    form.change(`Amount${id}`, value)

    // Uncheck the record if Copies is set to 0
    if (value === '0') {
      form.change(`AmountToPayCheck${id}`, false)
    }
  }

  const handleCheckboxChange = (itemId: number, isChecked: boolean) => {
    if (isChecked) {
      setSelectedRows([...selectedRows, { ID: itemId }])
    } else {
      setSelectedRows(selectedRows?.filter((row: any) => row.ID !== itemId))
    }
  }

  useEffect(() => {
    // Check if any record is checked
    const anyChecked = Object.keys(formValues)?.some((key) => key.startsWith('AmountToPayCheck') && formValues[key])
    setIsAnyRecordChecked(anyChecked)
  }, [formValues, setIsAnyRecordChecked])

  useEffect(() => {
    if (searchFormsPacksIsSuccess || searchFormsIsSuccess) {
      // Reset selected rows state
      setSelectedRows([])

      if (isFormPackSelected && printFormsData && printFormsData.length > 0) {
        const newSelectedRows: any[] = [] // Create a new array to store the selected rows

        form.batch(() => {
          printFormsData?.forEach((formItem: any) => {
            const fieldName = `AmountToPayCheck${formItem.ID}`
            form.change(fieldName, true) // Set the checkbox to checked
            const copiesField = `Amount${formItem.ID}`
            form.change(copiesField, formItem?.Quantity)
            newSelectedRows.push({ ID: formItem.ID }) // Add the item to the new array
          })
        })

        setSelectedRows(newSelectedRows) // Update the state once after the loop
      } else {
        form.batch(() => {
          printFormsData?.forEach((formItem: any) => {
            const fieldName = `AmountToPayCheck${formItem.ID}`
            form.change(fieldName, false) // Set the checkbox to unchecked
            const copiesField = `Amount${formItem.ID}`
            form.change(copiesField, '1')
            resetFieldState(fieldName) // Reset the field state
          })
        })
      }
    }
  }, [
    searchFormsIsSuccess,
    searchFormsPacksIsSuccess,
    printFormsData,
    form,
    resetFieldState,
    isFormPackSelected,
    formValues?.State,
    formValues?.FormPack,
  ])

  // Function to toggle select all
  const toggleSelectAll = (selectAll: boolean) => {
    const changes: any = {}
    printFormsData?.forEach((formItem: any) => {
      changes[`AmountToPayCheck${formItem.ID}`] = selectAll
    })
    form.batch(() => {
      Object.entries(changes)?.forEach(([name, value]) => {
        form.change(name, value)
      })
    })
    setSelectedRows(selectAll ? printFormsData?.map((formItem: any) => ({ ID: formItem.ID })) : [])
  }

  return (
    <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 160px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>
              <CheckboxField name='CopiesCheckbox' onChange={(e) => toggleSelectAll(e.target.checked)} />
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'ID' ? order : false}>
              <TableSortLabel active={orderBy === 'ID'} direction={orderBy === 'ID' ? order : 'asc'} onClick={createSortHandler('ID')}>
                Copies
                {orderBy === 'ID' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Name' ? order : false}>
              <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                Description
                {orderBy === 'Name' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Version' ? order : false}>
              <TableSortLabel active={orderBy === 'Version'} direction={orderBy === 'Version' ? order : 'asc'} onClick={createSortHandler('Version')}>
                Version
                {orderBy === 'Version' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), printFormsData as any[])?.map((item: any, index: number) => (
            <TableRow key={item.ID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell className={classes.tableCellName}>
                <CheckboxField name={'AmountToPayCheck' + item?.ID} onChange={(e) => handleCheckboxChange(item.ID, e.target.checked)} />
              </TableCell>
              <TableCell>
                <TextField
                  type='number'
                  name={`Amount${item?.ID}`}
                  label='Copies'
                  fullWidth
                  disabled={!formValues[`AmountToPayCheck${item?.ID}`]}
                  onChange={(e: any) => {
                    if (e && item?.ID) {
                      handleAmountChange(item?.ID, e)
                    }
                  }}
                />
              </TableCell>
              <TableCell>{item?.Name}</TableCell>
              <TableCell>{item?.Version}</TableCell>
            </TableRow>
          ))}
          {printFormsData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {loading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PrintFormsTableBlock
