import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Card, generateUtilityClasses } from '@mui/material'
import Paper from '@mui/material/Paper'
import { CurrentEmployerNameForm, CurrentEmployerDateForm, PreviousEmployerNameForm, PreviousEmployerDateForm } from '../../components'
import { useFormState } from 'react-final-form'
import { checkIfObjectContainKeysWithPrefix } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'pageBody', 'fieldsWrap', 'formItem'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.formItem}`]: {
    minHeight: '525px',
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
}))

interface Props {
  setErrorsInTabs: (value: any) => void
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const Employment = ({ setErrorsInTabs, onDropdownFormChange, disableFields }: Props) => {
  const { errors, submitFailed } = useFormState()

  useEffect(() => {
    setErrorsInTabs((prev: any) => ({ ...prev, employment: submitFailed && checkIfObjectContainKeysWithPrefix(errors, 'Employment') }))
  }, [errors, submitFailed])

  // TODO USE METHOD FOR THIS PAGE /contacts/employer/{contact_id}/ contact-Id form applicant for both employer previus an current

  // ID USED as employer contact ID
  return (
    <>
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CurrentEmployerNameForm disableFields={disableFields} onDropdownFormChange={onDropdownFormChange} />
          </Grid>
          <Grid item xs={12}>
            <CurrentEmployerDateForm disableFields={disableFields} onDropdownFormChange={onDropdownFormChange} />
          </Grid>
        </Grid>
      </Card>
      <Card sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PreviousEmployerNameForm disableFields={disableFields} onDropdownFormChange={onDropdownFormChange} />
          </Grid>
          <Grid item xs={12}>
            <PreviousEmployerDateForm disableFields={disableFields} onDropdownFormChange={onDropdownFormChange} />
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default Employment
