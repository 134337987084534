import React from 'react'
import { UspAccountSearchResult } from '../../../../../models'
import { storageGet, storageSet } from '../../../../../utils/storage'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  generateUtilityClasses,
} from '@mui/material'
import { styled } from '@mui/system'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { appRoute } from '../../../../../constants'
import { useNavigate } from 'react-router-dom'

interface Props {
  searchAccountsData?: UspAccountSearchResult[]
  loading: boolean
}
const AccountsListTableBlock = ({ searchAccountsData, loading }: Props) => {
  const initialOrder = (storageGet('accounts_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('accounts_order_by') || 'StockNumber') as keyof UspAccountSearchResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspAccountSearchResult>(initialOrderBy)
  const navigate = useNavigate()

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const paginatedData = searchAccountsData

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleEdit = (dealID: string) => {
    navigate(appRoute?.AccountsOverview.replace(':dealID', dealID as string))
  }

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspAccountSearchResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('accounts_order', isAsc ? 'desc' : 'asc')
    storageSet('accounts_order_by', property)
  }

  const createSortHandler = (property: keyof UspAccountSearchResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Name' ? order : false}>
                <TableSortLabel active={orderBy === 'Name'} direction={orderBy === 'Name' ? order : 'asc'} onClick={createSortHandler('Name')}>
                  Name
                  {orderBy === 'Name' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'PhoneNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'PhoneNumber'}
                  direction={orderBy === 'PhoneNumber' ? order : 'asc'}
                  onClick={createSortHandler('PhoneNumber')}
                >
                  Phone Number
                  {orderBy === 'PhoneNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'Vehicle' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'Vehicle'}
                  direction={orderBy === 'Vehicle' ? order : 'asc'}
                  onClick={createSortHandler('Vehicle')}
                >
                  Description
                  {orderBy === 'Vehicle' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'AccountStatus' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'AccountStatus'}
                  direction={orderBy === 'AccountStatus' ? order : 'asc'}
                  onClick={createSortHandler('AccountStatus')}
                >
                  Status
                  {orderBy === 'AccountStatus' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell sortDirection={orderBy === 'DealDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DealDate'}
                  direction={orderBy === 'DealDate' ? order : 'asc'}
                  onClick={createSortHandler('DealDate')}
                >
                  Deal
                  {orderBy === 'DealDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === 'NextDueDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'NextDueDate'}
                  direction={orderBy === 'NextDueDate' ? order : 'asc'}
                  onClick={createSortHandler('NextDueDate')}
                >
                  Next Due
                  {orderBy === 'NextDueDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === 'DaysLate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DaysLate'}
                  direction={orderBy === 'DaysLate' ? order : 'asc'}
                  onClick={createSortHandler('DaysLate')}
                >
                  Days Late
                  {orderBy === 'DaysLate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>

              <TableCell sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData)
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item) => (
                <TableRow
                  hover
                  onClick={() => handleEdit(item?.DealID?.toString() as string)}
                  key={item.AccountID}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                >
                  <TableCell>{item.StockNumber}</TableCell>
                  <TableCell>{item?.Name}</TableCell>
                  <TableCell>{item?.PhoneNumber}</TableCell>
                  <TableCell>{item?.Vehicle}</TableCell>
                  <TableCell>{item?.AccountStatus}</TableCell>
                  <TableCell>{convertDateToDisplay(item?.DealDate)}</TableCell>
                  <TableCell>{convertDateToDisplay(item?.NextDueDate)}</TableCell>
                  <TableCell>{item?.DaysLate}</TableCell>
                  <TableCell>{item?.BranchCode}</TableCell>
                </TableRow>
              ))}
            {searchAccountsData && searchAccountsData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
        <Paper>
          <TablePagination
            component='div'
            count={searchAccountsData?.length as number}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  )
}

export default AccountsListTableBlock
