import React from 'react'
import { Box, CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', [
  'root',
  'title',
  'titleContainer',
  'leftItemTypography',
  'rightItemTypography',
  'rightItemContainer',
  'leftItemContainer',
])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.titleContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  [`& .${classes.title}`]: {
    textAlign: 'center',
  },
  [`& .${classes.leftItemContainer}`]: {
    width: '45%',
  },
  [`& .${classes.rightItemContainer}`]: {
    width: '45%',
    marginLeft: '15px',
  },

  [`& .${classes.leftItemTypography}`]: {
    textAlign: 'right',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
  },
  [`& .${classes.rightItemTypography}`]: {
    textAlign: 'left',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
}))

interface Props {
  paySchedule: any
  term: any
  payment: any
  finalPayment: any
  interestRate: any
  APR: any
}

const TermsCard = ({ paySchedule, term, payment, finalPayment, interestRate, APR }: Props) => {
  return (
    <>
      <Grid container item>
        <CardHeader title='Terms' />
        <Grid item xs={12}>
          <Box sx={{ p: 3, pb: 0 }} display={'flex'} flexDirection={'column'}>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Pay Schedule:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {paySchedule}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Term:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {formatNumber(term)}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Payment:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {formatNumber(payment)}
              </Typography>
            </Box>

            {parseInt(finalPayment) > 0 && (
              <Box display={'flex'} alignItems={'baseline'}>
                <Typography sx={{ py: 1 }} variant='body2'>
                  Final Payment:
                </Typography>
                <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                  {formatNumber(finalPayment)}
                </Typography>
              </Box>
            )}
            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                Interest Rate:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {formatNumber(interestRate)}
              </Typography>
            </Box>

            <Box display={'flex'} alignItems={'baseline'}>
              <Typography sx={{ py: 1 }} variant='body2'>
                APR:
              </Typography>
              <Typography sx={{ ml: 1, py: 1 }} variant='body2'>
                {formatNumber(APR)}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default TermsCard
