import Typography from '@mui/material/Typography'
import Paper, { PaperProps } from '@mui/material/Paper'
import Stack, { StackProps } from '@mui/material/Stack'
import Label, { LabelColor } from '../../../../components/label'
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box } from '@mui/system'
import { formatNumber } from '../../../../utils/general'
// ----------------------------------------------------------------------
type Props = PaperProps &
  StackProps & {
    name?: string
    commissionStatus?: string
    AddedBy?: string
    Amount?: string
    commissionStatusColor?: LabelColor
    action?: React.ReactNode
  }

type StatusMapping = {
  'Requirements Met': LabelColor
}

export default function CommissionItem({ name, commissionStatus, AddedBy, commissionStatusColor, Amount, sx, action, ...other }: Props) {
  const statusMapping: StatusMapping = {
    'Requirements Met': 'success',
  }
  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-end' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack flexGrow={1} spacing={1}>
        <Stack direction='row' alignItems='center'>
          <Typography variant='subtitle2'>{name}</Typography>
          {/* {commissionStatus && (
            <Label color={commissionStatusColor} sx={{ ml: 1 }}>
              {commissionStatus}
            </Label>
          )} */}
        </Stack>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {AddedBy && `Added By ${AddedBy}`}
        </Typography>

        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          Amount: ${formatNumber(Amount)}
        </Typography>
      </Stack>

      {action && action}
    </Stack>
  )
}
