import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Tab,
  Tabs,
  generateUtilityClasses,
  useTheme,
} from '@mui/material'
import { styled } from '@mui/system'
import { LoadingButton } from '@mui/lab'
import { AmountField, CheckboxField, DateField, TextField } from '../../../../../ui'
import { Form } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useAddNotesMutation, selectDropdownByName, selectDropdownByNameActiveOnly, useAddExtensionMutation } from '../../../../../services'
import { transformToRequestValues } from './transform'
import { useLocation, useParams } from 'react-router-dom'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import MessageIcon from '@mui/icons-material/Message'
import validateFormValues from '../../../../../utils/validateForm'
import { accountsAddNoteValidation } from '../../../../../validation/accountsAddNote'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import DateTimeField from '../../../../../ui/DateTimeField'
import { accountsAddExtensionValidation } from '../../../../../validation/accountsAddExtension'
import dayjs from 'dayjs'
import { getCurrentDate } from '../../../../../utils/general'
import { convertDateToString } from '../../../../../utils/dateUtils'

const classes = generateUtilityClasses('Box', ['root', 'row', 'buttonWrap', 'saveBtn'])

const StyledBox = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 10,
    width: '700px',
    boxShadow: 24,
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(0, 0, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    margin: theme.spacing(0, 2, 0, 2),
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  getAccountDueAmount: any
  getAccountsOverview: any
  accountData: any
  isAccountActive?: boolean
}

const AddExtensionModal = ({ open, onClose, getAccountsOverview, accountData, isAccountActive, getAccountDueAmount }: Props) => {
  const { dealID, leadID } = useParams()
  const dispatch = useDispatch()
  const location = useLocation()
  const theme = useTheme()
  const [currentDate, setCurrentDate] = useState<string | null>(null)
  const [addExtnesion, { isSuccess }] = useAddExtensionMutation()
  const followUpTypeDropdown = useSelector((state) => selectDropdownByName(state, 'FollowUpType'))
  const assignedToDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'UserAll'))
  const callOutcomeDropdown = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CallOutcome'))
  const { UserID } = useSelector(getUserAccessRights)

  const handleSubmit = (values: any) => {
    addExtnesion({
      deal_id: accountData.DealID,
      addExtension: transformToRequestValues(values),
    })
  }
  const fetchCurrentDate = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = new Date()
    const systemDate = date?.toDateString()

    fetch('https://worldtimeapi.org/api/timezone/' + tz)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCurrentDate(convertDateToString(data?.datetime))
        } else {
          setCurrentDate(convertDateToString(systemDate))
        }
      })
      .catch((err) => {
        console.log(err)
        setCurrentDate(convertDateToString(systemDate))
      })
  }

  useEffect(() => {
    fetchCurrentDate()
  }, [open])

  useEffect(() => {
    if (isSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      getAccountsOverview({ dealId: dealID as string })
      getAccountDueAmount({
        dealId: dealID as string,
        accountDueAmount: {
          effectiveDate: currentDate?.toString(),
          isQuote: false,
          isLeaseReturn: false,
        },
      })
      onClose()
    }
  }, [isSuccess])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Extension</DialogTitle>
      <Form
        onSubmit={handleSubmit}
        validate={validateFormValues(accountsAddExtensionValidation())}
        initialValues={{
          effectiveDate: getCurrentDate(),
        }}
      >
        {({ handleSubmit, values, form }: any) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container spacing={2} py={3}>
                <Grid item xs={12}>
                  <DateField name='effectiveDate' label='Effective Date' />
                </Grid>
                <Grid item xs={12}>
                  <DateField name='extensionDate' label='Extension Date' />
                </Grid>
                <Grid item xs={12}>
                  <TextField multiline name='extensionNote' label='Note' fullWidth />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button variant='contained' type='button' color='error' onClick={onClose}>
                Cancel
              </Button>
              <Button variant='contained' type='submit'>
                Submit
              </Button>
            </DialogActions>
          </form>
        )}
      </Form>
    </Dialog>
  )
}

export default AddExtensionModal
