import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useEffect } from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField, TextField } from '../../../../../ui'
import { useForm, useFormState } from 'react-final-form'
import { UspListAccountingCashAccountsResult } from '../../../../../models'
import { useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'titleRow', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    minHeight: '400px',
  },
  [`& .${classes.titleRow}`]: {
    margin: theme.spacing(0.5, 0, 1, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))
interface Props {
  cashAccountsData: UspListAccountingCashAccountsResult[] | undefined
  checkID: number
}
const SettingsForm = ({ cashAccountsData, checkID }: Props) => {
  const { values } = useFormState()
  const form = useForm()
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))
  const billPayType = useSelector((state) => selectDropdownByName(state, 'BillPayType'))

  useEffect(() => {
    // Checking the conditions provided
    if (values?.BankAccount !== null && values?.BankBranch !== null) {
      // Find the selected bank account from cashAccountsData based on the ID
      const selectedBankAccount = cashAccountsData?.find((account: any) => account?.ID === values?.BankAccount)

      if (selectedBankAccount) {
        // Extract the NextCheckNumberData from the selected bank account
        const { NextCheckNumberData } = selectedBankAccount

        // Find the matching BranchID from NextCheckNumberData
        const matchingBranchData = NextCheckNumberData?.find((branch: any) => branch?.BranchID === values?.BankBranch)

        // If found, update the CheckNumber in the form with the NextCheckNumber
        if (matchingBranchData) {
          form.change('CheckNumber', matchingBranchData.NextCheckNumber)
        }
      }
    }
  }, [branch, billPayType, cashAccountsData, values.BankBranch])

  return (
    <>
      <CardHeader title='Settings' />
      <Grid container spacing={2} p={3}>
        {cashAccountsData && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='BankAccount'
              label='Bank Account (credit)'
              options={cashAccountsData}
              optionKey='ID'
              optionValue='ChartOfAccount'
              autoComplete='off'
            />
          </Grid>
        )}
        {branch && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='BankBranch'
              label='Bank Branch'
              options={branch}
              optionKey='BranchID'
              optionValue='Branch'
              autoComplete='off'
            />
          </Grid>
        )}
        {billPayType && (
          <Grid item xs={6}>
            <DropdownFieldSearch
              name='BillPayType'
              label='Bill Pay Type'
              options={billPayType?.ReturnData}
              optionKey='ID'
              optionValue='BillPayType'
              autoComplete='off'
            />
          </Grid>
        )}
        {values?.BillPayType === checkID && (
          <Grid item xs={6}>
            <TextField name='CheckNumber' label='Check Number' fullWidth autoComplete='off' />
          </Grid>
        )}
        <Grid item xs={6}>
          <DateField name='PaymentDate' label='Payment Date' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Memo' label='Memo' fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default SettingsForm
