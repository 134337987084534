import React, { useCallback, useEffect } from 'react'
import { styled } from '@mui/system'
import { Grid } from '@mui/material'
import { TextField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'
import { useField, useFormState } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { appRoute } from '../../../../../constants'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByName, selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onVINChange: (vin: string) => void
  setCurrentVin: (arg: string) => void
}

export const FirstForm = ({ onVINChange, setCurrentVin }: Props) => {
  const { vin } = useParams()
  const { values } = useFormState({ subscription: { values: true } })
  const carOdometerType = useSelector((state) => selectDropdownByName(state, 'CarOdometerType'))
  const carBodyType = useSelector((state) => selectDropdownByNameActiveOnly(state, 'CarBodyType'))

  const navigate = useNavigate()
  const {
    input: { value: vinValue },
  } = useField('vin')

  const debouncedOnVINChange = useCallback(debounce(onVINChange, 500), [onVINChange])

  useEffect(() => {
    setCurrentVin(values.vin)
  }, [values.vin, setCurrentVin])

  useEffect(() => {
    if (vinValue) {
      debouncedOnVINChange(vinValue)
      // If VIN in the url it will change the url with the new vin
      if (vin) {
        navigate(appRoute?.InventoryAddVin.replace(':vin', vinValue as string))
      }
    }
  }, [vinValue])

  return (
    <Grid container spacing={2} p={3}>
      <Grid item xs={6}>
        <TextField name='stockNumber' label='Stock Number (Optional)' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField name='vin' label='VIN' fullWidth required />
      </Grid>
      <Grid item xs={2}>
        <TextField name='modelYear' label='Year' fullWidth required />
      </Grid>
      <Grid item xs={4}>
        <TextField name='make' label='Make' fullWidth required />
      </Grid>
      <Grid item xs={6}>
        <TextField name='model' label='Model (Optional)' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField name='modelTrim' label='Trim (Optional)' fullWidth />
      </Grid>
      {carBodyType && (
        <Grid item xs={6}>
          <DropdownFieldSearch name='bodyType' label='Body (Optional)' options={carBodyType} optionKey='ID' optionValue='BodyType' />
        </Grid>
      )}
      <Grid item xs={3}>
        <TextField name='mileage' label='Mileage (Optional)' fullWidth />
      </Grid>
      {carOdometerType && (
        <Grid item xs={3}>
          <DropdownFieldSearch
            name='odometer'
            label='Odometer Type'
            fullWidth
            options={carOdometerType?.ReturnData}
            optionKey='ID'
            optionValue='OdometerType'
            disableClearable
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <TextField name='color' label='Color (Optional)' fullWidth />
      </Grid>
    </Grid>
  )
}

export default FirstForm
