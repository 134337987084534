import React, { useEffect, useState } from 'react'
import { Button, DialogActions, generateUtilityClasses, Grid } from '@mui/material'
import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import DealSettingsCard from '../../components/DealSettingsCard/DealSettingsCard'
import InterestSettingsCard from '../../components/InterestSettingsCard/InterestSettingsCard'
import FeeSettingsCard from '../../components/FeeSettingsCard/FeeSettingsCard'
import { AccountsDealResult } from '../../../../../models'
import { Form } from 'react-final-form'
import { transformToFormValues, transformToRequestValues } from './transform'
import { useAccountsSettingsDealEditMutation } from '../../../../../services'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { accountsDealSettingsValidation } from '../../../../../validation/accountsDealSettingsValidation'
import validateForm from '../../../../../utils/validateForm'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'

const classes = generateUtilityClasses('form', ['root', 'formItem', 'buttonWrap'])

const StyledForm = styled('form')(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    position: 'fixed',
    bottom: '0px',
    right: '0px',
    padding: theme.spacing(3, 3, 3, 3),
  },
}))

interface Props {
  data: AccountsDealResult
  getDealData: (params: { dealId: string }) => void
  onClose: () => void
}

const TabSettings = ({ data, getDealData, onClose }: Props) => {
  const { dealID } = useParams()
  const [editDealSettings, { isSuccess }] = useAccountsSettingsDealEditMutation()
  const [initFormValues, setInitFormValues] = useState({})
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)

  const disableFields = !userAccess?.CanEditAccount

  const handleSubmit = (values: any) => {
    editDealSettings({ dealId: dealID as string, uspAccountSettingDealEdit: transformToRequestValues(values as any) })
  }

  useEffect(() => {
    setInitFormValues(transformToFormValues(data))
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      getDealData({ dealId: dealID as string })
      dispatch(enqueueNotification({ message: 'Updated' }))
      onClose()
    }
  }, [isSuccess])

  return (
    <Form onSubmit={handleSubmit} initialValues={initFormValues} validate={validateForm(accountsDealSettingsValidation)}>
      {({ handleSubmit, valid }: any) => (
        <form onSubmit={handleSubmit} className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DealSettingsCard disableFields={disableFields} />
            </Grid>
            <Grid item xs={12}>
              <FeeSettingsCard disableFields={disableFields} />
            </Grid>
            <Grid item xs={12}>
              <InterestSettingsCard disableFields={disableFields} />
            </Grid>
          </Grid>

          <DialogActions>
            <Button variant='contained' color='error' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' type='submit' disabled={!valid}>
              Save
            </Button>
          </DialogActions>
        </form>
      )}
    </Form>
  )
}

export default TabSettings
