import { formatNumber, getCurrentDate } from '../../../../utils/general'
import { convertStringToDate } from '../../../../utils/dateUtils'
import removeMask from '../../../../utils/removeMask'

export const transformToFormValues = (carPaymentData?: any) => {
  const currentDate = getCurrentDate()
  return {
    ApplicantAccountType: 'individual',
    EffectiveDate: currentDate,
    ProcessingFee: '0',
    Amount: '0',
  }
}

export const transformToRequestValues = (values?: any, checkID?: any, creditCardID?: any) => {
  return {
    dealID: values?.DealID,
    paymentDate: values?.EffectiveDate ? convertStringToDate(values?.EffectiveDate) : '' || null,
    paymentMethodID: values?.Method,
    paymentFormID: values?.Form,
    checkTypeID: values?.CheckType,
    referenceNumber:
      values?.Form === checkID?.toString() ? values?.CheckNumber : values?.Form === creditCardID?.toString() ? values?.Last4ofCreditCard : null,
    creditCardTypeID: values?.CreditType,
    cardExpiration: null,
    authorizationNumber: values?.Form === creditCardID.toString() ? values?.AuthorizationNumber : null,
    transactionAmount: removeMask(values?.TotalPayment as string) as string,
    paymentTendered: removeMask(values?.AmountTendered) as string,
    changeDue: removeMask(formatNumber(values?.Change as string)) as string,
    writeOffAmount: '0' || null,
    writeOffChartOfAccountID: null,
    processingFee: removeMask(values?.ProcessingFee) as string,
    processingFeeExpense: '0',
    paymentNote: values?.Comment,

    contactID: values.Pending,
    firstName: values.ApplicantFirstName,
    middleName: values.ApplicantMiddleName,
    lastName: values.ApplicantLastName,
    suffix: values.ApplicantSuffix,
    nickName: values.ApplicantNickName,
    businessName: values.ApplicantBusinessName,
    dba: values?.DBA,
  }
}
