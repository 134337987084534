import { Card, generateUtilityClasses, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import React from 'react'
import { TextField } from '../../../../../ui'

import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Card', ['root', 'titleRow', 'row', 'label'])

const StyledGrid = styled(Card)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: '20px',
  },
  [`& .${classes.titleRow}`]: {
    margin: theme.spacing(0.5, 0, 3, 0),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.label}`]: {
    fontSize: '12px',
    marginBottom: '-15px',
  },
}))

interface Props {
  cardTitle: string
  dealTradeAllowance: string | number
  dealTradeLien: string | number
  dealTradeACV: string | number
}

const TradesCardDetails = ({ cardTitle, dealTradeACV, dealTradeAllowance, dealTradeLien }: Props) => {
  return (
    <StyledGrid className={classes.root}>
      <Grid container className={classes.titleRow}>
        <Typography variant='h3'>{cardTitle}</Typography>
      </Grid>
      <Grid className={classes.row} container columns={{ xs: 13 }}>
        <Typography className={classes.label}>Trade Allowance</Typography>
        <TextField name='TradeAllowanceResult' label={formatNumber(dealTradeAllowance as string)} disabled fullWidth autoComplete='off' />
      </Grid>
      <Grid className={classes.row}>
        <Typography className={classes.label}>Trade Lien</Typography>
        <TextField name='TradeLienResult' label={formatNumber(dealTradeLien as string)} disabled fullWidth autoComplete='off' />
      </Grid>
      <Grid className={classes.row}>
        <Typography className={classes.label}>Trade ACV</Typography>
        <TextField name='TradeACVResult' label={formatNumber(dealTradeACV as string)} disabled fullWidth autoComplete='off' />
      </Grid>
    </StyledGrid>
  )
}

export default TradesCardDetails
