import React, { ReactNode } from 'react'

import { SchemaOf } from 'yup'
import { DeepPartial, FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { DefaultValues, Mode, UseFormReturn } from 'react-hook-form/dist/types/form'
import { yupResolver } from '@hookform/resolvers/yup'

interface FormProps<T extends FieldValues> {
  onSubmit?: SubmitHandler<T>
  onChange?: (v: T) => void
  schema?: SchemaOf<T>
  defaultValues?: DeepPartial<T>
  children: ReactNode
  formHook?: UseFormReturn<T>
  style?: React.CSSProperties
  mode?: Mode
}

export const HookedForm = <T extends FieldValues = FieldValues>({
  formHook,
  onSubmit = () => {},
  onChange,
  schema,
  defaultValues,
  children,
  mode,
  style,
}: FormProps<T>) => {
  const localHook = useForm<T>({
    defaultValues: defaultValues as DefaultValues<T>,
    resolver: schema && yupResolver(schema),
    mode,
  })
  const hook = formHook || localHook

  React.useEffect(() => {
    if (!onChange) return
    const subscription = hook.watch((v) => onChange(v as T))
    return () => subscription.unsubscribe()
  }, [hook.watch, onChange])

  return (
    <FormProvider<T> {...hook}>
      <form onSubmit={hook.handleSubmit(onSubmit)} style={style} autoComplete='off'>
        {children}
      </form>
    </FormProvider>
  )
}
