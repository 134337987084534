import { convertDateToString } from '../../../utils/dateUtils'

export const transformToFormValues = (values?: any) => ({
  IsBankruptcy: values?.IsBankruptcy,
  Type: values?.BankruptcyChapter,
  FillingDate: values?.DateFiled,
  HearingDate: values?.HearingDate,
  CaseNumber: values?.CaseNumber,
  Attorney: values?.AttorneyName,
  Trustee: values?.TrusteeName,
  DischargeDate: values?.DischargeDate,
  DismissalDate: values?.DismissalDate,
  AccountNote: values?.Note,
})

export const transformToRequestValues = (values?: any) => ({
  isBankruptcy: values?.IsBankruptcy,
  bankruptcyChapter: values?.Type,
  dateFiled: values?.FillingDate === 'Invalid Date' ? null : convertDateToString(values?.FillingDate),
  hearingDate: values?.HearingDate === 'Invalid Date' ? null : convertDateToString(values?.HearingDate),
  caseNumber: values?.CaseNumber,
  attorneyName: values?.Attorney,
  trusteeName: values?.Trustee,
  dischargeDate: values?.DischargeDate === 'Invalid Date' ? null : convertDateToString(values?.DischargeDate),
  dismissalDate: values?.DismissalDate === 'Invalid Date' ? null : convertDateToString(values?.DismissalDate),
  note: values?.AccountNote,
})
