import { styled } from '@mui/system'
import { Button, CardHeader, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { CheckboxField, TextField } from '../../../../../ui'
import { ReactComponent as SixangleIcon } from '../../../../../assets/icons/sixangle.svg'
import { generateUtilityClasses } from '@mui/material'
import { UspGetDataDealTaskResult, UspLisDealStatusResult } from '../../../../../models'
import {
  useEditDealSettingMutation,
  useGetListDealStatusQuery,
  useGetListDealsCollectorQuery,
  selectDropdownByNameIsSalespersonOnly,
  selectDropdownByNameIsManagerOnly,
  selectDropdownByNameActiveOnly,
} from '../../../../../services'
import { getUserAccessRights } from '../../../../../redux/slices/authSlice'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { Form } from 'react-final-form'
import { useParams } from 'react-router-dom'
import Label, { LabelColor } from '../../../../../components/label'

const classes = generateUtilityClasses('Grid', [
  'root',
  'row',
  'body',
  'title',
  'titleSubtitle',
  'mainIcon',
  'addIcon',
  'userIcon',
  'linkText',
  'statusHighlighted',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.body}`]: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  [`& .${classes.title}`]: {
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.titleSubtitle}`]: {
    padding: theme.spacing(1, 0, 1, 1),
  },
  [`& .${classes.mainIcon}`]: {
    padding: theme.spacing(0, 2, 0, 0),
    fill: theme.palette.text.primary,
  },
  [`& .${classes.addIcon}`]: {
    width: '45px',
    padding: theme.spacing(0.5, 0, 0.5, 0),
  },
  [`& .${classes.userIcon}`]: {
    padding: theme.spacing(1.5, 1, 1.5, 1),
    fill: '#ff00ff', // theme.palette.text.primary,
  },
  [`& .${classes.linkText}`]: {
    width: '95px',
    fontWeight: 600,
    fontSize: '1em',
    lineHeight: '1.1875em',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
    padding: theme.spacing(1.5, 1, 1.5, 1),
  },
  [`& .${classes.statusHighlighted}`]: {
    padding: theme.spacing(0.2, 1),
    width: '175px',
    height: '22px',
    textAlign: 'center',
    color: theme.palette.text.button,
    fontWeight: '600',
    fontSize: '0.85em',
    margin: theme.spacing(1, 0, 1, 3),
    borderRadius: '4px',
  },
}))

interface Props {
  taskDeal?: UspGetDataDealTaskResult
  closeModal: () => void
  managerID?: number | null
  salesPersonID?: number | null
}

export const SettingsForm = ({ taskDeal, closeModal, managerID, salesPersonID }: Props) => {
  const { data: listDealsCollectorData } = useGetListDealsCollectorQuery({})
  const { data: listDealStatus } = useGetListDealStatusQuery({})
  const [patchSettings] = useEditDealSettingMutation()
  const { dealID } = useParams()
  const salesPerson = useSelector((state) => selectDropdownByNameIsSalespersonOnly(salesPersonID)(state, 'UserAll'))
  const manager = useSelector((state) => selectDropdownByNameIsManagerOnly(managerID)(state, 'UserAll'))
  const advertisement = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Advertisement'))
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  const userAccess = useSelector(getUserAccessRights)

  const disableFields = !userAccess?.CanEditDeal

  const handleSubmit = (settings: UspGetDataDealTaskResult) => {
    patchSettings({
      uspDealSettingEdit: {
        branchID: settings.BranchID,
        salespersonUserID: settings.SalespersonID,
        fiUserID: settings.FiUserID,
        collectorUserID: settings.CollectorUserID,
        advertisementID: settings.AdvertisementID,
        dealStatusID: settings.DealStatusID,
        currentMileage: settings.CurrentMileage,
        isDelivered: !!settings.IsDelivered,
        isHasCoBuyer: !!settings.IsHasCoBuyer,
      },
      dealId: dealID as string,
    })
    closeModal()
  }

  return (
    <>
      <DialogTitle>Settings</DialogTitle>
      {taskDeal?.SettingsStatus && (
        <Label color={taskDeal?.SettingsStatusColor as LabelColor} sx={{ mt: 3, mr: 5, position: 'absolute', top: 10, right: 0 }}>
          {taskDeal?.SettingsStatus}
        </Label>
      )}
      <DialogContent>
        <Form onSubmit={handleSubmit} initialValues={taskDeal}>
          {({ handleSubmit, dirty }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} paddingY={1}>
                {branch && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='BranchID'
                      label='Branch'
                      options={branch}
                      optionKey='BranchID'
                      optionValue='Branch'
                    />
                  </Grid>
                )}
                {salesPerson && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='SalespersonID'
                      label='Salesperson'
                      options={salesPerson}
                      optionKey='UserID'
                      optionValue='Name'
                    />
                  </Grid>
                )}

                {manager && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='FiUserID'
                      label='F&I Manager'
                      options={manager}
                      optionKey={'UserID'}
                      optionValue={'Name'}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    disabled={disableFields}
                    name='CollectorUserID'
                    label='Collector'
                    options={listDealsCollectorData as any[]}
                    optionKey='UserID'
                    optionValue='Name'
                  />
                </Grid>
                {advertisement && (
                  <Grid item xs={12}>
                    <DropdownFieldSearch
                      disabled={disableFields}
                      name='AdvertisementID'
                      label='Lead Source'
                      options={advertisement}
                      optionKey='ID'
                      optionValue='Advertisement'
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <DropdownFieldSearch
                    disabled={disableFields}
                    name='DealStatusID'
                    label='Status'
                    options={listDealStatus as UspLisDealStatusResult[]}
                    optionKey='ID'
                    optionValue='Status'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField disabled={disableFields} name='CurrentMileage' label='Current Mileage' fullWidth autoComplete='off' />
                </Grid>
                <Grid item xs={12}>
                  <CheckboxField disabled={disableFields} name='IsDelivered' label='Is Delivered?' />
                </Grid>
              </Grid>
              <DialogActions>
                <Button variant='contained' color='error' onClick={() => closeModal()}>
                  Cancel
                </Button>
                <Button variant='contained' type='submit' disabled={!dirty}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Form>
      </DialogContent>
    </>
  )
}

export default SettingsForm
