import React from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, Button, CardHeader } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'btnWrap'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },

  [`& .${classes.btnWrap}`]: {
    margin: theme.spacing(0, 2, 0, 'auto'),
    padding: theme.spacing(2),
  },
}))

export const Header = () => {
  return (
    <Container>
      <Button sx={{ position: 'absolute', top: 0, right: 30 }} variant='contained'>
        Floorplan Settings
      </Button>
    </Container>
  )
}

export default Header
