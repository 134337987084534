export const transformToFormValues = (
  lenderID?: number,
  dealerID?: number,
  collectorID?: number,
  salesPersonID?: number,
  managerID?: number | null,
): any => ({
  SettingsLender: lenderID,
  SettingsDealer: dealerID,
  SettingsCollector: collectorID,
  SettingsSalesperson: salesPersonID,
  SettingsFIManager: managerID,
})
export const transformToRequestValues = (values?: any): any => ({
  salespersonUserID: values?.SettingsSalesperson,
  fiUserID: values?.SettingsFIManager,
  collectorUserID: values?.SettingsCollector,
  lenderID: values?.SettingsLender,
  dealerID: values?.SettingsDealer,
})
