import React from 'react'
import { IMaskInput } from 'react-imask'
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText,
  FilledInput,
  OutlinedInput,
  Popper,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material'
import { Field } from 'react-final-form'
import { default as MuiTextField } from '@mui/material/TextField'
import { convertDateToDisplay } from '../../utils/dateUtils'

interface Props {
  name: string
  label: string
  variant?: 'standard' | 'outlined' | 'filled'
  [x: string]: any
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

const validatePhoneNumber = (value: string) => {
  if (!value || value.trim() === '') {
    return undefined
  }
  const numericValue = value?.replace(/[()\-\s]/g, '')
  if (numericValue?.length !== 10) {
    return 'Phone number must be exactly 10 digits'
  }
}

export const TextMaskCustom = React.forwardRef<HTMLElement, CustomProps>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return <IMaskInput {...other} mask='(000) 000-0000' onAccept={(value: any) => onChange({ target: { name: props.name, value } })} overwrite />
})

export const PhoneTextField = ({ name, label, mask, maskChar, variant, fullWidth, className, data, setValue, onChange, ...props }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined

  return (
    <>
      <Field name={name} validate={validatePhoneNumber}>
        {({ input, meta: { error, touched } }) => (
          <FormControl variant={variant} fullWidth={fullWidth} error={touched && error} className={className}>
            <InputLabel
              htmlFor='formatted-text-mask-input'
              sx={{
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              {label}
            </InputLabel>
            <OutlinedInput
              {...input}
              {...props}
              onChange={(event) => {
                setAnchorEl(event.currentTarget)
                input.onChange(event)
                onChange && onChange(event)
              }}
              onFocus={(event) => {
                setAnchorEl(event.currentTarget)

                event.target.select()
              }}
              onBlur={(event) => {
                input.onBlur(event)
                setTimeout(() => {
                  setAnchorEl(null)
                }, 400)
              }}
              id='formatted-text-mask-input'
              inputComponent={TextMaskCustom as any}
              autoComplete='one-time-code'
            />
            {variant === 'filled' && (
              <FilledInput {...input} {...props} id='formatted-text-mask-input' inputComponent={TextMaskCustom as any} autoComplete='off' />
            )}
            {touched && error && <FormHelperText id='my-helper-text'>{error}</FormHelperText>}
          </FormControl>

          // <MuiTextField
          //   {...props}
          //   {...input}
          //   variant={variant}
          //   error={touched && error ? true : false}
          //   helperText={touched && error}
          //   onBlur={(event) => {
          //     input.onBlur(event)
          //     setTimeout(() => {
          //       setAnchorEl(null)
          //     }, 400)
          //   }}
          //   InputProps={{
          //     inputComponent: { TextMaskCustom },

          //     ...props.InputProps,
          //     onChange: (event) => {
          //       setAnchorEl(event.currentTarget)
          //       input.onChange(event)
          //       // onChange && onChange(event)
          //     },
          //     onFocus: (event) => {
          //       setAnchorEl(event.currentTarget)

          //       event.target.select()
          //     },
          //     autoComplete: 'one-time-code',
          //   }}
          // />
        )}
      </Field>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={'bottom-start'}>
        <Card>
          <Table>
            <TableBody>
              {data?.map((el: any) => (
                <TableRow
                  hover
                  onClick={() => {
                    setValue(el)
                    setAnchorEl(null)
                  }}
                >
                  <TableCell>{el?.Name}</TableCell>
                  <TableCell>{convertDateToDisplay(el?.DateOfBirth)}</TableCell>
                  <TableCell>{el?.phoneNumber}</TableCell>
                  <TableCell>{el?.AddressData?.[0]?.Address1 ?? el?.AddressData?.[0]?.Address1}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Popper>
    </>
  )
}

export default PhoneTextField
