import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import BillingSearchBlock from './BillingSearchBlock/BillingSearchBlock'
import { useSearchAccountingBillMutation, useVendorListsMutation } from '../../../services'
import { convertDateToString } from '../../../utils/dateUtils'
import { getCurrentDate, getEarlierDate } from '../../../utils/general'
import BillingTableBlock from './BillingTableBlock/BillingTableBlock'
import validateFormValues from '../../../utils/validateForm'
import { accountingBillingValidation } from '../../../validation/accountingBillingValidation'
import { Button, Card, Container } from '@mui/material'
import Iconify from '../../../components/iconify'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useSelector } from 'react-redux'
import { getUserAccessRights } from '../../../redux/slices/authSlice'

const AccountingBilling = () => {
  const [vendorList, { data: vendorListData }] = useVendorListsMutation()
  const [searchBilling, { data: billingData, isLoading: billingIsLoading }] = useSearchAccountingBillMutation()
  const navigate = useNavigate()
  const userAccess = useSelector(getUserAccessRights)
  const [anchorElAdd, setAnchorElAdd] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorElAdd)
  const [openAddSingleLineBillModal, setOpenAddSingleLineBillModal] = React.useState(false)

  const id = open ? 'add-menu-popover' : undefined

  const handleOpenAddMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElAdd(event.currentTarget)
  }

  const handleCloseAddMenu = () => {
    setAnchorElAdd(null)
  }
  const handleSubmit = (values: any) => {
    const jsonBranchList = values?.branch?.map((item: any) => ({ BranchID: item?.BranchID }))
    const jsonVendorList = values?.searchVendor?.map((item: any) => ({ VendorID: item?.ID }))

    let startDate = convertDateToString(values?.startDate)
    let endDate = convertDateToString(values?.endDate)

    if (values?.dateRange === 'today') {
      startDate = getCurrentDate() || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'yesterday') {
      startDate = getEarlierDate(1) || null
      endDate = getEarlierDate(1) || null
    } else if (values?.dateRange === 'lastSevenDays') {
      startDate = getEarlierDate(7) || null
      endDate = getCurrentDate() || null
    } else if (values?.dateRange === 'lastThirtyDays') {
      startDate = getEarlierDate(30) || null
      endDate = getCurrentDate() || null
    }
    if (startDate !== 'Invalid Date' && endDate !== 'Invalid Date') {
      searchBilling({
        accountingBill: {
          startDate: startDate || null,
          endDate: endDate || null,
          jsonBranchList: jsonBranchList || null,
          jsonVendorList: jsonVendorList || null,
          status: values?.searchStatus || 'Unpaid',
        },
      })
    }
  }

  useEffect(() => {
    vendorList({
      uspVendorLists: {
        currentID: null,
        vendorType: null,
      },
    })
  }, [])

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Bills'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          userAccess?.CanAddBill && (
            <Button endIcon={<KeyboardArrowDownIcon />} variant='contained' startIcon={<AddIcon />} onClick={handleOpenAddMenu}>
              Add
            </Button>
          )
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Card
        sx={{
          flexGrow: { md: 1 },
          display: { md: 'flex' },
          flexDirection: { md: 'column' },
        }}
      >
        <Form initialValues={{ searchStatus: 'Unpaid' }} onSubmit={handleSubmit} validate={validateFormValues(accountingBillingValidation)}>
          {({ handleSubmit, form: { submit } }: any) => (
            <form onSubmit={handleSubmit}>
              <BillingSearchBlock
                submit={submit}
                vendorListData={vendorListData}
                handleCloseAddMenu={handleCloseAddMenu}
                open={open}
                anchorElAdd={anchorElAdd}
              />
              <BillingTableBlock submit={submit} loading={billingIsLoading} searchBillingData={billingData} />
            </form>
          )}
        </Form>
      </Card>
    </Container>
  )
}

export default AccountingBilling
