import { Box, Button, Card, generateUtilityClasses, Grid, Paper } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useForm, useFormState } from 'react-final-form'
import { useLazyCarsVinReadQuery, useLazyGetTaskDealQuery } from '../../../../../../../services'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { appRoute } from '../../../../../../../constants'
import { debounce } from 'lodash'
import { AccountsOverviewResult, CarsDropdownResult } from '../../../../../../../models'
import { withPermissionsDropdown } from '../../../../../../../hoc'
import TradeVehicleForm from '../../../../components/TradeVehicleForm/TradeVehicleForm'
import SpecsForm from '../../../../components/SpecsForm/SpecsForm'
import PricingForm from '../../../../components/PricingForm/PricingForm'
import ValuesForm from '../../../../components/ValuesForm/ValuesForm'
import LienHolderForm from '../../../../components/LienholderForm/LienHolderForm'
import LienForm from '../../../../components/LienForm/LienForm'
import Header from '../../../../../Header/Header'
import { withAccounts } from '../../../../../../../hoc/withAccounts'
import { CustomCircular } from '../../../../../../../ui'

const classes = generateUtilityClasses('div', ['root', 'pageBody', 'formItem', 'formItemHalf', 'formItemValues', 'formItemLien', 'buttonWrap'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.formItemHalf}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    width: '200px',
  },
  [`& .${classes.formItemValues}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    height: '287px',
  },
  [`& .${classes.formItemLien}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    height: '287px',
  },

  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
}))

interface Props {
  onSubmit: any
  accountsData: AccountsOverviewResult
}

const TradeStructure = ({ onSubmit, accountsData }: Props) => {
  const { vin, tradeID, dealID } = useParams()
  const navigate = useNavigate()
  const { values } = useFormState()
  const [getTaskDeal, { isLoading: getTaskDealIsLoading }] = useLazyGetTaskDealQuery()
  const [carsVin, { data: carsVinData, isSuccess: carsVinIsSuccess, isLoading: carsVinIsLoading }] = useLazyCarsVinReadQuery()
  const { change } = useForm()
  const [currentVIN, setCurrentVIN] = useState(vin || '')
  const [initialPageLoad, setInitialPageLoad] = useState(tradeID ? true : false)

  const updateWithDebounce = useCallback(
    debounce((vin: string) => {
      setCurrentVIN(vin)
      setInitialPageLoad(false)
    }, 500),
    [],
  )

  useEffect(() => {
    if (carsVinIsSuccess) {
      change('Make', carsVinData?.Make)
      change('Model', carsVinData?.Model)
      change('Year', carsVinData?.ModelYear)
      change('VIN', vin || currentVIN)
      change('Trim', carsVinData?.ModelTrim)
      change('Body', carsVinData?.BodyTypeID)
      change('Engine', carsVinData?.Engine)
      change('Drivetrain', carsVinData?.DriveTrainID)
      change('FuelType', carsVinData?.FuelTypeID)
      change('Cylinders', carsVinData?.Cylinders)
    }
  }, [carsVinIsSuccess, carsVinData, change, vin])

  useEffect(() => {
    const inputValue = vin || values?.VIN
    if (inputValue) {
      updateWithDebounce(inputValue)
    }
  }, [vin, values?.VIN, updateWithDebounce])

  useEffect(() => {
    if (values?.VIN && values?.VIN !== currentVIN) {
      setCurrentVIN(values?.VIN)
      if (!tradeID) {
        navigate(appRoute?.DealTradeAddVin.replace(':dealID', dealID as string).replace(':vin', values?.VIN))
      }
    }
  }, [values?.VIN, currentVIN, carsVin])

  useEffect(() => {
    if (currentVIN.length > 16 && !initialPageLoad) {
      carsVin({ vin: currentVIN })
    }
  }, [currentVIN, carsVin])

  useEffect(() => {
    getTaskDeal({ dealId: dealID as string })
  }, [])

  const handleClick = () => {
    onSubmit() // Calling the handleSubmit function passed as props
  }

  const isLoading = useMemo(() => carsVinIsLoading || getTaskDealIsLoading, [carsVinIsLoading, getTaskDealIsLoading])

  return (
    <Card sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TradeVehicleForm />
        </Grid>
        <Grid item xs={12}>
          <SpecsForm />
        </Grid>
        <Grid item xs={12}>
          <PricingForm />
        </Grid>
        <Grid item xs={12}>
          <ValuesForm />
        </Grid>
        {parseFloat(values?.LienPayoff) > 0 && (
          <>
            <Grid item xs={2}>
              <LienHolderForm formValidation={values} />
            </Grid>
            <Grid item xs={2}>
              <LienForm />
            </Grid>
          </>
        )}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          position: 'sticky',
          bottom: 16,
          right: 20,
          p: 3,
          width: 'fit-content',
        }}
      >
        <Button
          variant='contained'
          color='error'
          onClick={() =>
            navigate(
              generatePath(appRoute.AccountsOverview, {
                dealID,
              }),
            )
          }
        >
          Close
        </Button>
        <Button variant='contained' type='button' onClick={handleClick}>
          Save & Close
        </Button>
      </Box>
      <CustomCircular open={isLoading} />
    </Card>
  )
}

export default withAccounts(withPermissionsDropdown(TradeStructure))
