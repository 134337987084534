import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Header from '../Header/Header'
import SettingsCard from './components/SettingsCard/SettingsCard'
import { Box, Button, Container, Grid, Paper, Tab, Tabs, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import StandingCard from './components/StandingCard/StandingCard'
import BalancesCard from './components/BalancesCard/BalancesCard'
import InsuranceCard from './components/InsuranceCard/InsuranceCard'
import {
  useAddDealsFlagMutation,
  useDeleteDealsFlagMutation,
  useEditDealsFlagMutation,
  useGetAccountDueAmountMutation,
  useGetAccountNotesHistoryMutation,
  useGetDealsFlagMutation,
  usePaymentsDetailsMutation,
  useWorklistNextAccountMutation,
} from '../../../services'
import { useLocation, useParams } from 'react-router-dom'
import { withAccounts } from '../../../hoc/withAccounts'
import { AccountDueAmountResult, AccountsOverviewResult, GetDealsFlagApiResponse, UspDealFlagViewResult } from '../../../models'
import { CustomCircular } from '../../../ui'
import { convertDateToString } from '../../../utils/dateUtils'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import NoteHistoryCard from '../AccountsNoteHistory/components/NoteHistoryCard'
import DueAmountsModal from './components/DueAmountsModal/DueAmountsModal'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getWorklistAccountID, getWorklistID, setWorklistAccountID, setWorklistID } from '../../../redux/slices/collectionsSlice'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import FlagCard from './components/FlagCard/FlagCard'
import { getCurrentDate } from '../../../utils/general'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import Label from '../../../components/label'
import Iconify from '../../../components/iconify'
import AccountsGeneral from './accounts-general'
import AccountsPayHistory from '../AccountsPayHistoryNew/AccountsPayHistory'
import AccountsHistory from './accounts-history'
import AccountNotes from './accounts-notes'
import AccountsDeals from './accounts-deals'
import { InsuranceForm } from '../../Deals/DealsInsurance/components'
import { DealsInsurance } from '../../Deals'
import AccountsAmorization from './accounts-amorization'
import AccountsRepo from '../AccountsRepo/AccountsRepo'
import AccountsBankruptcy from '../AccountsBankruptcy/AccountsBankruptcy'
import AccountsTitle from '../AccountsTitle/AccountsTitle'
import ConfirmDialog from '../../../components/ConfirmDialog'

const classes = generateUtilityClasses('Grid', [
  'root',
  'pageBody',
  'formItem',
  'notesTypography',
  'showMoreBtnWrapper',
  'formItemChart',
  'scrollContainerNotes',
  'scrollContainerFlags',
])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(3, 3, 3, 3),
    display: 'flex',
    alignItems: 'flexStart',
    justifyContent: 'justify-start',
  },
  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.formItemChart}`]: {
    padding: theme.spacing(2, 2, 8, 2),
    borderRadius: 0,
    height: '400px',
  },
  [`& .${classes.notesTypography}`]: {
    textAlign: 'center',
  },
  [`& .${classes.showMoreBtnWrapper}`]: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.scrollContainerNotes}`]: {
    maxHeight: '65vh',
    overflow: 'auto',
  },
}))

interface Props {
  accountsData: AccountsOverviewResult
  accountsIsLoading: boolean
  accountsIsSuccess: boolean
  getAccountsOverview: (e: any) => void
}

const AccountsOverview = ({ accountsData, getAccountsOverview, accountsIsSuccess, accountsIsLoading }: Props) => {
  const { dealID } = useParams()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const worklistAccountID = useSelector(getWorklistAccountID)
  const worklistID = useSelector(getWorklistID)
  const [dirty, setDirty] = useState(false)
  const [currentDate, setCurrentDate] = useState<string | null>(null)
  const [getAccountDueAmount, { data, isSuccess, isLoading: accountDueAmountIsLoading }] = useGetAccountDueAmountMutation()
  const [getAccountNotesHistory, { data: accountNotesHistoryData, isLoading: accountNotesHistoryIsLoading }] = useGetAccountNotesHistoryMutation()
  const [openDueAmountsModal, setOpenDueAmountsModal] = useState(false)
  const [nextAccount, { data: nextAccountData, isSuccess: nextAccountIsSuccess, isLoading: nextAccountIsLoading }] = useWorklistNextAccountMutation()
  const disableEditIcons = userAccess?.CanEditAccount

  const [getAccountFlags, { data: accountFlagsData, isLoading: accountFlagsIsLoading, isSuccess: accountsFlagsIsSuccess }] = useGetDealsFlagMutation()
  const [deleteAccountFlags, { reset, isSuccess: deleteAccountFlagIsSuccess, isLoading: deleteAccountFlagsIsLoading }] = useDeleteDealsFlagMutation()
  const [editAccountFlags, { isSuccess: editAccountFlagIsSuccess, isLoading: editAccountFlagsIsLoading }] = useEditDealsFlagMutation()
  const [addAccountFlags, { isSuccess: addAccountFlagIsSuccess, isLoading: addAccountFlagsIsLoading }] = useAddDealsFlagMutation()
  const [flagList, setFlagList] = useState<GetDealsFlagApiResponse | undefined>([])
  const [isAddEditFlagModalOpen, setIsAddEditFlagModalOpen] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [selectedFlag, setSelectedFlag] = useState<UspDealFlagViewResult | undefined>()
  const [currentTab, setCurrentTab] = useState('general')
  const [newTabValue, setNewTabValue] = useState('')
  const [confirmSwitchModal, setConfirmSwitchModal] = useState(false)
  const [getPaymentsDetails, { data: paymentDetailsData, isLoading: paymentDetailsIsLoading, isSuccess: paymentDetailsIsSuccess }] =
    usePaymentsDetailsMutation()
  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      if (dirty) {
        setNewTabValue(newValue)
        setConfirmSwitchModal(true)
      } else {
        setCurrentTab(newValue)
      }
    },
    [dirty],
  )

  const TABS = [
    {
      value: 'general',
      label: 'General',
      icon: <Iconify icon='solar:user-id-bold' width={24} />,
      disabled: false,
    },
    {
      value: 'history',
      label: 'Pay History',
      icon: <Iconify icon='mdi:account-payment-outline' width={24} />,
      disabled: false,
    },
    {
      value: 'notes',
      label: 'Notes',
      icon: <Iconify icon='solar:notes-bold' width={24} />,
      disabled: false,
    },
    {
      value: 'deal',
      label: 'Deal',
      icon: <Iconify icon='mdi:handshake' width={24} />,
      disabled: false,
    },
    {
      value: 'insurance',
      label: 'Insurance',
      icon: <Iconify icon='mdi:car-insurance' width={24} />,
      disabled: false,
    },
    {
      value: 'amorization',
      label: 'Amortization',
      icon: <Iconify icon='f7:list-number' width={24} />,
      disabled: false,
    },
    {
      value: 'repo',
      label: 'Repo',
      icon: <Iconify icon='mdi:car-door-lock' width={24} />,
      disabled: false,
    },
    {
      value: 'bankruptcy',
      label: 'Bankruptcy',
      icon: <Iconify icon='mdi:bank-off' width={24} />,
      disabled: false,
    },
    {
      value: 'title',
      label: 'Title',
      icon: <Iconify icon='mingcute:paper-fill' width={24} />,
      disabled: false,
    },
  ]
  const handleCloseDueAmountsModal = () => {
    setOpenDueAmountsModal(false)
  }

  useEffect(() => {
    if (state?.isContactUpdated) {
      getAccountsOverview({ dealId: dealID as string })
    }
  }, [])

  useEffect(() => {
    getAccountsOverview({ dealId: dealID as string })
  }, [nextAccountIsLoading, dealID])

  const fetchCurrentDate = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
    const date = new Date()
    const systemDate = date?.toDateString()
    fetch('https://worldtimeapi.org/api/timezone/' + tz)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCurrentDate(convertDateToString(data?.datetime))
        } else {
          setCurrentDate(convertDateToString(systemDate))
        }
      })
  }

  useEffect(() => {
    fetchCurrentDate()
  }, [])

  useEffect(() => {
    if (dealID && currentDate) {
      getAccountDueAmount({
        dealId: dealID as string,
        accountDueAmount: {
          effectiveDate: currentDate?.toString(),
          isQuote: false,
          isLeaseReturn: false,
        },
      })
    }
  }, [currentDate, dealID])

  useEffect(() => {
    if (dealID) {
      getAccountNotesHistory({
        accountNotesHistory: {
          dealID: parseInt(dealID as string),
          isIncludeDeletedNotes: false,
          isImportantMessagesOnly: true,
          creditAppID: null,
          contactID: null,
        },
      })
      getAccountFlags({
        dealId: dealID?.toString() as string,
        uspDealFlag: { effectiveDate: getCurrentDate() },
      })
    }
  }, [dealID])

  const handleNextAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: true,
        },
      })
    }
  }

  const handlePreviousAccount = () => {
    if (worklistID && worklistAccountID) {
      nextAccount({
        worklistId: worklistID.toString(),
        uspCollectionWorklistGetNextAccount: {
          worklistAccountID: worklistAccountID,
          isNext: false,
        },
      })
    }
  }

  useEffect(() => {}, [data, accountsData, accountNotesHistoryData, worklistAccountID, worklistID])

  const isLoading = useMemo(
    () =>
      accountDueAmountIsLoading ||
      accountNotesHistoryIsLoading ||
      accountsIsLoading ||
      nextAccountIsLoading ||
      accountFlagsIsLoading ||
      deleteAccountFlagsIsLoading ||
      addAccountFlagsIsLoading ||
      editAccountFlagsIsLoading,
    [
      accountDueAmountIsLoading,
      accountNotesHistoryIsLoading,
      accountsIsLoading,
      nextAccountIsLoading,
      accountFlagsIsLoading,
      deleteAccountFlagsIsLoading,
      addAccountFlagsIsLoading,
      editAccountFlagsIsLoading,
    ],
  )

  useEffect(() => {
    // Get URL parameters
    const urlParams = new URLSearchParams(window.location.search)
    const worklistAccountID = urlParams?.get('worklistAccountID')
    const worklistID = urlParams?.get('worklistID')

    if (worklistAccountID && worklistID) {
      dispatch(setWorklistAccountID(Number(worklistAccountID)))
      dispatch(setWorklistID(Number(worklistID)))
    }

    return () => {
      dispatch(setWorklistID(null))
    }
  }, [dispatch])

  const handleDeleteFlag = (flag: UspDealFlagViewResult | undefined) => {
    if (flag?.FlagID) {
      deleteAccountFlags({
        dealId: dealID?.toString() as string,
        flagId: flag.FlagID.toString(),
      })
    }
  }

  useEffect(() => {
    if (accountsFlagsIsSuccess) {
      setFlagList(accountFlagsData)
    }
  }, [accountsFlagsIsSuccess])

  useEffect(() => {
    if (addAccountFlagIsSuccess || editAccountFlagIsSuccess) {
      getAccountFlags({
        dealId: dealID?.toString() as string,
        uspDealFlag: { effectiveDate: getCurrentDate() },
      })
      setSelectedFlag(undefined)
      setIsAddEditFlagModalOpen(false)
    }
  }, [addAccountFlagIsSuccess, editAccountFlagIsSuccess])

  useEffect(() => {
    if (deleteAccountFlagIsSuccess && selectedFlag) {
      setFlagList((prevFlagList = []) => {
        return prevFlagList?.filter((flag) => flag.FlagID !== selectedFlag.FlagID)
      })
      dispatch(enqueueNotification({ message: 'Flag removed' }))
      setSelectedFlag(undefined)
      reset()
    }
  }, [deleteAccountFlagIsSuccess, selectedFlag, dispatch])

  return (
    <>
      <Container sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', position: 'relative' }}>
        <Header
          getAccountsPayHistory={getPaymentsDetails}
          handleNextAccount={handleNextAccount}
          handlePreviousAccount={handlePreviousAccount}
          accountsData={accountsData}
          getAccountsOverview={getAccountsOverview}
          getAccountDueAmount={getAccountDueAmount}
          nextAccountData={nextAccountData}
          nextAccountIsSuccess={nextAccountIsSuccess}
          worklistID={worklistID}
          isAccountActive={accountsData?.IsAccountActive}
          isDeficiency={accountsData?.IsDeficiency}
          accountsIsSuccess={accountsIsSuccess}
          branchID={accountsData?.BranchID}
        />
        <Box display={'flex'} sx={{ position: 'relative' }}>
          <CustomBreadcrumbs
            heading='Account'
            links={[
              { name: 'List', href: '/accounts' },
              {
                name: `${accountsData?.BuyerName} | ${accountsData?.BuyerPhoneData?.[0]?.PhoneNumber} | ${accountsData?.CarDescription} | Stock # ${accountsData?.StockNumber} | VIN : ${accountsData?.VIN}`,
              },
            ]}
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        </Box>

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} icon={tab.icon} disabled={tab.disabled} value={tab.value} />
          ))}
        </Tabs>
        {currentTab === 'general' && (
          <AccountsGeneral
            pastDue={data?.PastDueAmount as string}
            payOff={data?.PayoffAmount as string}
            setOpenDueAmountsModal={setOpenDueAmountsModal}
            getAccountsOverview={getAccountsOverview}
            setRefetch={setRefetch}
            data={accountsData}
          />
        )}
        {currentTab === 'history' && (
          <AccountsHistory
            getAccountsOverview={getAccountsOverview}
            getAccountDueAmount={getAccountDueAmount}
            getPaymentsDetails={getPaymentsDetails}
            paymentDetailsData={paymentDetailsData}
            data={accountsData}
          />
        )}
        {currentTab === 'notes' && <AccountNotes data={accountsData} />}
        {currentTab === 'deal' && <AccountsDeals accountsData={accountsData} />}
        {currentTab === 'insurance' && <DealsInsurance hideBreadcrumbs={true} setDirty={setDirty} />}
        {currentTab === 'amorization' && <AccountsAmorization />}
        {currentTab === 'repo' && <AccountsRepo setDirty={setDirty} />}
        {currentTab === 'bankruptcy' && <AccountsBankruptcy setDirty={setDirty} />}
        {currentTab === 'title' && <AccountsTitle setDirty={setDirty} />}
        <DueAmountsModal
          dueAmountDataIsSuccess={isSuccess}
          dueAmountsData={data as AccountDueAmountResult}
          open={openDueAmountsModal}
          accountsData={accountsData}
          onClose={handleCloseDueAmountsModal}
        />
      </Container>
      <ConfirmDialog
        open={confirmSwitchModal}
        onOk={() => {
          setConfirmSwitchModal(false)
          setDirty(false)
          setCurrentTab(newTabValue)
        }}
        handleClose={() => setConfirmSwitchModal(false)}
        notification={'All the changes made would be lost'}
      />
    </>
    //  <Grid container>
    // <Header
    //       disableEditIcons={disableEditIcons}
    //       model={accountsData?.CarDescription}
    //       vin={accountsData?.VIN}
    //       stockNumber={accountsData?.StockNumber}
    //       buyerName={accountsData?.BuyerName}
    //       coBuyerName={accountsData?.CoBuyerName}
    //       buyerWorkPhone={accountsData?.BuyerWorkPhone}
    //       coBuyerWorkPhone={accountsData?.CoBuyerWorkPhone}
    //       buyerPhone={accountsData?.BuyerPhoneData}
    //       coBuyerPhone={accountsData?.CoBuyerPhoneData}
    //       buyerAddress={accountsData?.BuyerAddressData}
    //       coBuyerAddress={accountsData?.CoBuyerAddressData}
    //       contactID1={accountsData?.ContactID1}
    //       contactID2={accountsData?.ContactID2}
    //       getAccountsOverview={getAccountsOverview}
    //       getAccountDueAmount={getAccountDueAmount}
    //       branchID={accountsData?.BranchID}
    //       carID={accountsData?.CarID}
    //       isAccountActive={accountsData?.IsAccountActive}
    //       isDeficiency={accountsData?.IsDeficiency}
    //       worklistAccountID={worklistAccountID}
    //       worklistID={worklistID}
    //       nextAccountData={nextAccountData}
    //       nextAccountIsSuccess={nextAccountIsSuccess}
    //       handleNextAccount={handleNextAccount}
    //       handlePreviousAccount={handlePreviousAccount}
    //       addAccountFlags={addAccountFlags}
    //       editAccountFlags={editAccountFlags}
    //       selectedFlag={selectedFlag}
    //       setSelectedFlag={setSelectedFlag}
    //       isAddEditFlagModalOpen={isAddEditFlagModalOpen}
    //       setIsAddEditFlagModalOpen={setIsAddEditFlagModalOpen}
    //     />
    //     <Grid container rowSpacing={1} columnSpacing={1} className={classes.pageBody}>
    //       <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
    //         <Grid container direction='column' rowSpacing={1}>
    //           <Grid item>
    //             <Paper className={classes.formItem}>
    //               <SettingsCard
    //                 disableEditIcons={disableEditIcons}
    //                 lenderID={accountsData?.LenderID}
    //                 lender={accountsData?.LenderCode}
    //                 branch={accountsData?.BranchCode}
    //                 dealerID={accountsData?.DealerID}
    //                 dealer={accountsData?.DealerCode}
    //                 collectorID={accountsData?.CollectorUserID}
    //                 collector={accountsData?.CollectorUserCode}
    //                 salesPersonID={accountsData?.SalespersonUserID}
    //                 salesPerson={accountsData?.SalespersonUserCode}
    //                 managerID={accountsData?.FiUserID}
    //                 manager={accountsData?.FiUserCode}
    //                 getAccountsOverview={getAccountsOverview}
    //               />
    //             </Paper>
    //           </Grid>
    //           <Grid item>
    //             <Paper className={classes.formItem}>
    //               <StandingCard
    //                 daysLate={accountsData?.DaysLate}
    //                 pickupDaysLate={accountsData?.PickupDaysLate}
    //                 dealDate={accountsData?.DealDate}
    //                 pickupDueDate={accountsData?.PickupDueDate}
    //                 paymentAmount={accountsData?.PaymentAmount}
    //                 status={accountsData?.AccountStatus}
    //                 nextDue={accountsData?.NextDueDate}
    //                 lateDate={accountsData?.LateChargeDate}
    //                 lastPaid={accountsData?.LastPaidDate}
    //                 pastDue={data?.PastDueAmount as string}
    //                 payOff={data?.PayoffAmount as string}
    //                 setOpenDueAmountsModal={setOpenDueAmountsModal}
    //               />
    //             </Paper>
    //           </Grid>
    //           <Grid item>
    //             <Paper className={classes.formItem}>
    //               <BalancesCard balancesData={accountsData?.BalanceData} />
    //             </Paper>
    //           </Grid>
    //           <Grid item>
    //             <Paper className={classes.formItem}>
    //               <InsuranceCard
    //                 disableEditIcons={disableEditIcons}
    //                 name={accountsData?.InsuranceName}
    //                 agent={accountsData?.InsuranceAgent}
    //                 expires={accountsData?.ExpirationDate}
    //                 policy={accountsData?.PolicyNumber}
    //                 cpi={accountsData?.IsCPI}
    //                 phone={accountsData?.InsurancePhone}
    //                 insuranceID={accountsData?.InsuranceID}
    //               />
    //             </Paper>
    //           </Grid>
    //         </Grid>
    //       </Grid>

    //       <Grid item xs={12} sm={6} md={8} lg={6} xl={6}>
    //         <Grid container direction='column' rowSpacing={1}>
    //           {flagList && flagList?.length > 0 && (
    //             <Grid item>
    //               <Paper className={classes.formItem}>
    //                 <Typography variant='h3' className={classes.notesTypography}>
    //                   Flags
    //                 </Typography>
    //                 {flagList?.map((item, index) => (
    //                   <Grid item key={item?.FlagID || index} sx={{ margin: '20px 0' }}>
    //                     <FlagCard
    //                       setIsAddEditFlagModalOpen={setIsAddEditFlagModalOpen}
    //                       handleDeleteFlag={handleDeleteFlag}
    //                       selectedFlag={selectedFlag}
    //                       setSelectedFlag={setSelectedFlag}
    //                       data={item}
    //                       deleteAccountFlags={deleteAccountFlags}
    //                     />
    //                   </Grid>
    //                 ))}
    //               </Paper>
    //             </Grid>
    //           )}

    //           <Grid item>
    //             <Paper className={`${classes.formItem} ${classes.scrollContainerNotes}`}>
    //               <Typography variant='h3' className={classes.notesTypography}>
    //                 Important Notes
    //               </Typography>
    //               {accountNotesHistoryData ? (
    //                 accountNotesHistoryData?.slice(0, notesToShow)?.map((item) => <NoteHistoryCard data={item} key={item?.NoteID} />)
    //               ) : (
    //                 <Typography variant='h6' textAlign='center' sx={{ margin: '20px 0' }}>
    //                   No Data
    //                 </Typography>
    //               )}
    //               {accountNotesHistoryData && accountNotesHistoryData.length > notesToShow && (
    //                 <div className={classes.showMoreBtnWrapper}>
    //                   <Button onClick={() => setNotesToShow((prev) => prev + 3)}>Show more</Button>
    //                 </div>
    //               )}
    //             </Paper>
    //           </Grid>
    //         </Grid>
    //       </Grid>
    //       {_.first(accountsData?.AccountPtpAnalysis)?.TotalPtpCount !== 0 && (
    //         <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
    //           {accountsData?.AccountPtpAnalysis.length !== 0 && (
    //             <Paper className={classes.formItemChart}>
    //               <Typography variant='h3' sx={{ margin: '10px' }}>
    //                 PTP Analysis
    //               </Typography>
    //               <ResponsiveContainer width='100%' height='100%'>
    //                 <BarChart
    //                   data={accountsData?.AccountPtpAnalysis}
    //                   margin={{
    //                     top: 10,
    //                     right: 30,
    //                     left: 0,
    //                     bottom: 5,
    //                   }}
    //                 >
    //                   <CartesianGrid strokeDasharray='3 3' />
    //                   <XAxis dataKey='name' />
    //                   <YAxis />
    //                   <Tooltip cursor={{ fill: 'transparent' }} />
    //                   <Legend />
    //                   <Bar dataKey='TotalPtpCount' fill='#1570EF' name='Total' />
    //                   <Bar dataKey='SatisfiedPtpCount' fill='#52B0FD' name='Satisfied' />
    //                   <Bar dataKey='BrokenPtpCount' fill='#0870a3' name='Broken' />
    //                   <Bar dataKey='FuturePtpCount' fill='#03A9FC' name='Future' />
    //                 </BarChart>
    //               </ResponsiveContainer>
    //             </Paper>
    //           )}
    //         </Grid>
    //       )}
    //     </Grid>
    //     <CustomCircular open={isLoading} />
    //
    //   </Grid>
    // </Container>
    // <Container>
  )
}

export default withAccounts(AccountsOverview)
