import { EditDealSettingApiArg, EditDealSettingApiResponse, GetTaskDealApiArg, GetTaskDealApiResponse } from '../../models'
import { baseApi } from '../baseApi'
import {
  DeleteDealCoBuyerApiArg,
  DeleteDealCoBuyerApiResponse,
  MarkLeadAsLostApiArg,
  MarkLeadAsLostApiResponse,
  ReopenDealApiArg,
  ReopenDealApiResponse,
} from './dealsApiTypes'

const dealsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTaskDeal: build.query<GetTaskDealApiResponse, GetTaskDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/task/${queryArg.dealId}/`,
      }),
      providesTags: ['Deals'],
    }),
    editDealSetting: build.mutation<EditDealSettingApiResponse, EditDealSettingApiArg>({
      query: (queryArg) => ({
        url: `/deals/settings/${queryArg.dealId}/`,
        method: 'PATCH',
        body: queryArg.uspDealSettingEdit,
      }),
      invalidatesTags: ['Deals'],
    }),
    deleteCoBuyer: build.mutation<DeleteDealCoBuyerApiResponse, DeleteDealCoBuyerApiArg>({
      query: (queryArg) => ({
        url: `deals/co_buyer/remove/${queryArg.dealId}/${queryArg.coBuyerId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Deals'],
    }),
    markDealAsLost: build.mutation<MarkLeadAsLostApiResponse, MarkLeadAsLostApiArg>({
      query: (queryArg) => ({
        url: `/deals/lost/${queryArg.dealId}/${queryArg.reasonId}/`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Deals'],
    }),
    dealReopen: build.mutation<ReopenDealApiResponse, ReopenDealApiArg>({
      query: (queryArg) => ({
        url: `/deals/reopen/${queryArg.dealId}/`,
        method: 'PATCH',
      }),
      invalidatesTags: ['Deals'],
    }),
  }),
})

export const {
  useGetTaskDealQuery,
  useLazyGetTaskDealQuery,
  useEditDealSettingMutation,
  useDeleteCoBuyerMutation,
  useDealReopenMutation,
  useMarkDealAsLostMutation,
} = dealsApi
