import React from 'react'
import { styled } from '@mui/system'
import { Grid } from '@mui/material'
import { TextField } from '../../../../../ui'
import AmountField from '../../../../../ui/AmountField'
import { useFormState } from 'react-final-form'
import { parseToNumber } from '../../../../../utils/general'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

interface Props {}

export const SecondForm = ({}: Props) => {
  const { values } = useFormState()
  const vendor = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Vendor'))

  return (
    <Grid container spacing={2} p={3}>
      <Grid item xs={6}>
        <AmountField variant='standard' name='purchasePrice' label='Purchase Price' fullWidth required />
      </Grid>
      {vendor && parseToNumber(values.purchasePrice || '') > 0 && (
        <Grid item xs={6}>
          <DropdownFieldSearch name='vendor' label='Vendor' options={vendor} optionKey='ID' optionValue='Name' required />
        </Grid>
      )}
      {values.purchasePrice && parseToNumber(values.purchasePrice || '') > 0 && (
        <Grid item xs={6}>
          <DropdownFieldSearch
            name='purchaseWith'
            label='Purchase With'
            options={[
              { key: 'bill', value: 'Bill' },
              { key: 'check', value: 'Check' },
              { key: 'floorplan', value: 'Floorplan' },
            ]}
            optionKey='key'
            optionValue='value'
            required
          />
        </Grid>
      )}
      {values?.purchaseWith === 'check' && (
        <Grid item xs={6}>
          <TextField name='checkNumber' label='Check Number' fullWidth required />
        </Grid>
      )}
    </Grid>
  )
}

export default SecondForm
