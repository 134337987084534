import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { Container, Typography, Grid, CardHeader } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'

const classes = generateUtilityClasses('Container', ['root', 'titleWrap', 'btnWrap', 'dropdownWrap'])

const StyledContainer = styled(Container)(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
    margin: 0,
    display: 'flex',
    backgroundColor: theme.palette.background.main,
    width: '100%',
    maxWidth: 'unset',
    alignItems: 'center',
  },
  [`& .${classes.titleWrap}`]: {
    margin: theme.spacing(2, 2, 1, 2),
  },

  [`& .${classes.btnWrap}`]: {
    margin: theme.spacing(0, 2, 0, 'auto'),
    padding: theme.spacing(2),
  },
  [`& .${classes.dropdownWrap}`]: {
    minWidth: '100px',
  },
}))

interface Props {
  searchState: any
}
export const Header = ({ searchState }: Props) => {
  const states = useSelector(getStates)
  const { values } = useFormState()

  useEffect(() => {
    searchState({
      uspStateSettingView: {
        state: values.State || 'XX',
      },
    })
  }, [values.State])

  return (
    <>
      <CardHeader title='State' />
      <Grid container spacing={2} px={3} pt={3}>
        <Grid item xs={6}>
          <DropdownFieldSearch name='State' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default Header
