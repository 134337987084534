import React from 'react'
import { styled } from '@mui/system'
import { Card, CardHeader, Grid, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import { useFormState } from 'react-final-form'
import TextField from '../../../../../ui/TextField'
import { DateField } from '../../../../../ui'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { selectDropdownByName } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {
  onDropdownFormChange: () => void
  disableFields: boolean
}

export const HistoryForm = ({ onDropdownFormChange, disableFields }: Props) => {
  const { values } = useFormState()
  const bankruptcyType = useSelector((state) => selectDropdownByName(state, 'BankruptcyType'))

  return (
    <Card>
      <CardHeader title='History (Optional)' />
      <Grid container spacing={2} padding={3}>
        <Grid item xs={12}>
          <TextField disabled={disableFields} name='HistoryNumberOfDependents' label='Number of Dependents' fullWidth />
        </Grid>
        <Grid item xs={12}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='HistoryHasHadBankruptcy'
            label='Has Had Bankruptcy?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        {values?.HistoryHasHadBankruptcy && bankruptcyType && (
          <>
            <Grid item xs={12}>
              <DropdownFieldSearch
                disabled={disableFields}
                name='HistoryBankruptcyType'
                label='Bankruptcy Type'
                options={bankruptcyType?.ReturnData}
                optionKey='Chapter'
                optionValue='Description'
                onChange={() => {
                  onDropdownFormChange()
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DateField
                disabled={disableFields}
                onChange={() => {
                  onDropdownFormChange()
                }}
                name='HistoryBankruptcyDate'
                label='Bankruptcy Date'
                fullWidth
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <DropdownFieldSearch
            disabled={disableFields}
            name='HistoryHasHadRepossession'
            label='Has Had Repossession?'
            options={[
              { key: true, value: 'Yes' },
              { key: false, value: 'No' },
            ]}
            onChange={() => {
              onDropdownFormChange()
            }}
          />
        </Grid>
        {values?.HistoryHasHadRepossession && (
          <Grid item xs={12}>
            <DateField
              disabled={disableFields}
              onChange={() => {
                onDropdownFormChange()
              }}
              name='HistoryRepossessionDate'
              label='Repossession Date'
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

export default HistoryForm
