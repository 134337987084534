import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import App from './App'
import store from './redux/store'
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux'
import Notifier from './components/Notifier/Notifier'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <App />
    <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
      <Notifier />
    </SnackbarProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
