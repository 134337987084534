import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { Form } from 'react-final-form'
import { Header } from './components/Header'
import { InsuranceForm, SettingsForm } from './components'
import { useDispatch } from 'react-redux'
import { Card, Container, generateUtilityClasses } from '@mui/material'
import { CustomCircular } from '../../../ui'
import { withPermissionsDropdown } from '../../../hoc'
import { appRoute } from '../../../constants'
import { useAddDealInsuranceMutation, useGetContactMutation, useLazyGetDealInsuranceQuery, useLazyGetTaskDealQuery } from '../../../services'
import { transformDealsInsuranceToFormValues, transformDealsInsuranceToRequestValues } from './transform'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import validateFormValues from '../../../utils/validateForm'
import { DealInsuranceValidation } from '../../../validation/dealInsuranceValidation'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { applyPhoneMask } from '../../../utils/general'

const classes = generateUtilityClasses('form', ['root', 'body', 'formItem', 'buttonWrap', 'saveBtn'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.body}`]: {
    minHeight: '100%',
    padding: theme.spacing(2),
  },
  [`& .${classes.formItem}`]: {
    minHeight: '360px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 0),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

export const DealsInsurance = ({ hideBreadcrumbs, setDirty }: { hideBreadcrumbs?: boolean; setDirty?: any }) => {
  const [initFormValues, setInitFormValues] = useState<any>({})
  const { dealID } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const [getTaskDeal, { data: taskDeal, isLoading: getTaskDealIsLoading }] = useLazyGetTaskDealQuery()
  const [getDealInsurance, { data: dealInsuranceData, isLoading: dealInsuranceIsLoading }] = useLazyGetDealInsuranceQuery()
  const [addInsurance, { isSuccess: dealInsuranceDataAddIsSuccess }] = useAddDealInsuranceMutation()
  const [getContacts, { data: getContactsData, isLoading: getContactsDataIsLoading }] = useGetContactMutation()

  const handleSubmit = (values: any, form: any) => {
    if (dealID) {
      addInsurance({
        dealId: dealID as string,
        uspAddDataDealInsuranceResult: transformDealsInsuranceToRequestValues(values, getContactsData, dealInsuranceData) as any,
      })
      // const res = getDealInsurance({ dealId: dealID as string }).unwrap()
      // form.reset(transformDealsInsuranceToFormValues(getContactsData, dealInsuranceData, res))
    }
  }

  useEffect(() => {
    getTaskDeal({ dealId: dealID as string })
  }, [taskDeal])

  useEffect(() => {
    if (dealInsuranceDataAddIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      if (!location.pathname.includes('overview')) {
        navigate(appRoute.DealsTask.replace(':dealID', dealID as string))
      } else {
        getTaskDeal({ dealId: dealID as string })
      }
    }
  }, [dealInsuranceDataAddIsSuccess, dealID, dispatch, navigate])

  useEffect(() => {
    getDealInsurance({ dealId: dealID as string })
  }, [dealID, getDealInsurance, dealInsuranceDataAddIsSuccess])

  useEffect(() => {
    if (dealInsuranceData?.ContactID) {
      getContacts({ contactId: (dealInsuranceData?.ContactID).toString() as string })
    }
  }, [dealInsuranceData, getContacts])

  useEffect(() => {
    if (taskDeal) {
      setInitFormValues(transformDealsInsuranceToFormValues(getContactsData, dealInsuranceData, taskDeal))
    }
  }, [dealInsuranceData, getContactsData, taskDeal])

  const isLoading = useMemo(
    () => dealInsuranceIsLoading || getContactsDataIsLoading || getTaskDealIsLoading,
    [dealInsuranceIsLoading, getContactsDataIsLoading, getTaskDealIsLoading],
  )

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={10} spacing={2}>
          <Form onSubmit={handleSubmit} initialValues={initFormValues} validate={validateFormValues(DealInsuranceValidation)}>
            {({ handleSubmit, valid, dirty, form }: any) => {
              if (setDirty) setDirty(dirty)
              return (
                <form onSubmit={handleSubmit} className={classes.root}>
                  {!hideBreadcrumbs && (
                    <Box display={'flex'}>
                      <CustomBreadcrumbs
                        heading='Deal'
                        links={[
                          { name: 'List', href: '/deals' },
                          {
                            name: `${taskDeal?.CarDescription} | Cell: ${applyPhoneMask(taskDeal?.PhoneNumber)} | ${
                              taskDeal?.Buyer
                            } | ${`VIN ${taskDeal?.VIN}`} | ${`Stock # ${taskDeal?.StockNumber}`}`,
                          },
                        ]}
                        sx={{
                          mb: { xs: 3, md: 5 },
                        }}
                      />
                    </Box>
                  )}
                  <Card sx={{ overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InsuranceForm />
                      </Grid>
                      <Grid item xs={12}>
                        <SettingsForm />
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        position: 'sticky',
                        bottom: 16,
                        right: 20,
                        p: 3,
                        width: 'fit-content',
                      }}
                    >
                      <Button type='submit' disabled={!(valid && dirty)} className={classes.saveBtn} variant='contained'>
                        Save
                      </Button>
                    </Box>
                    {/* <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        position: 'sticky',
                        bottom: 16,
                        right: 20,
                        p: 3,
                        width: 'fit-content',
                      }}
                    >
                      <Button variant='contained' color='error' onClick={() => navigate(-1)}>
                        Close
                      </Button>
                      <Button type='submit' disabled={!valid} className={classes.saveBtn} variant='contained'>
                        Save & Close
                      </Button>
                    </Box> */}
                  </Card>
                </form>
              )
            }}
          </Form>
        </Grid>
      </Grid>
      <CustomCircular open={isLoading} />
    </Container>
  )
}

export default withPermissionsDropdown(DealsInsurance)
