import {
  Box,
  generateUtilityClasses,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { UspPaymentViewDistributionData } from '../../../../../models'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { visuallyHidden } from '@mui/utils'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { convertDateToDisplay } from '../../../../../utils/dateUtils'
import { formatNumber } from '../../../../../utils/general'

const classes = generateUtilityClasses('Grid', ['root', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'column',
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.main,
    maxWidth: '400px',
    padding: theme.spacing(4),
    color: theme.palette?.text?.primary,
    border: `1px solid ${theme.palette.background.secondary}`,
  },
}))

interface Props {
  tableData: UspPaymentViewDistributionData[]
}

const PayHistoryRecordTable = ({ tableData }: Props) => {
  const initialOrder = (storageGet('pay_history_distribution_data') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('pay_history_distribution_data_by') || 'DistributionType') as keyof UspPaymentViewDistributionData
  const [order, setOrder] = useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = useState<keyof UspPaymentViewDistributionData>(initialOrderBy)
  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspPaymentViewDistributionData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('pay_history_distribution_data', isAsc ? 'desc' : 'asc')
    storageSet('pay_history_distribution_data_by', property)
  }

  const createSortHandler = (property: keyof UspPaymentViewDistributionData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const hasNonZeroValues = (item: UspPaymentViewDistributionData) => {
    const fields: (keyof UspPaymentViewDistributionData)[] = ['SalesTax', 'CPI1', 'CPI2', 'DiscountEarned', 'InterestRebate', 'InsuranceRebate']
    return fields.some((field) => item[field] !== '0.00')
  }

  return (
    <>
      <StyledGrid className={classes.root}>
        <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 170px)' }} className={classes.root}>
          <Table aria-label='simple table' stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'DistributionType' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'DistributionType'}
                    direction={orderBy === 'DistributionType' ? order : 'asc'}
                    onClick={createSortHandler('DistributionType')}
                  >
                    Distribution
                    {orderBy === 'DistributionType' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'DueDate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'DueDate'}
                    direction={orderBy === 'DueDate' ? order : 'asc'}
                    onClick={createSortHandler('DueDate')}
                  >
                    Due Date
                    {orderBy === 'DueDate' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'DaysLate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'DaysLate'}
                    direction={orderBy === 'DaysLate' ? order : 'asc'}
                    onClick={createSortHandler('DaysLate')}
                  >
                    Late Days
                    {orderBy === 'DaysLate' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'AppliedAmount' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'AppliedAmount'}
                    direction={orderBy === 'AppliedAmount' ? order : 'asc'}
                    onClick={createSortHandler('AppliedAmount')}
                  >
                    Amount
                    {orderBy === 'AppliedAmount' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'Principal' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'Principal'}
                    direction={orderBy === 'Principal' ? order : 'asc'}
                    onClick={createSortHandler('Principal')}
                  >
                    Principal
                    {orderBy === 'Principal' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'InterestRebate' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'InterestRebate'}
                    direction={orderBy === 'InterestRebate' ? order : 'asc'}
                    onClick={createSortHandler('InterestRebate')}
                  >
                    Interest
                    {orderBy === 'InterestRebate' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'LateChargeDue' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'LateChargeDue'}
                    direction={orderBy === 'LateChargeDue' ? order : 'asc'}
                    onClick={createSortHandler('LateChargeDue')}
                  >
                    Late Fee
                    {orderBy === 'LateChargeDue' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.tableHead} sortDirection={orderBy === 'ChargebackCollected' ? order : false}>
                  <TableSortLabel
                    active={orderBy === 'ChargebackCollected'}
                    direction={orderBy === 'ChargebackCollected' ? order : 'asc'}
                    onClick={createSortHandler('ChargebackCollected')}
                  >
                    Chargeback
                    {orderBy === 'ChargebackCollected' ? (
                      <Box component='span' sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>

                <TableCell className={classes.tableHead} align={'right'}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(getComparator(order, orderBy), tableData as any[])?.map((item: any, index: number) => (
                <TableRow key={item.index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{item.DistributionType}</TableCell>
                  <TableCell>{convertDateToDisplay(item?.DueDate)}</TableCell>
                  <TableCell>{item?.DaysLate}</TableCell>
                  <TableCell>{formatNumber(item?.AppliedAmount)}</TableCell>
                  <TableCell>{formatNumber(item?.Principal)}</TableCell>
                  <TableCell>{formatNumber(item?.InterestCollected)}</TableCell>
                  <TableCell>{formatNumber(item?.LateChargeCollected)}</TableCell>
                  <TableCell>{formatNumber(item?.ChargebackCollected)}</TableCell>
                  {hasNonZeroValues(item) && (
                    <TableCell align={'right'}>
                      <IconButton>
                        <HtmlTooltip
                          placement='left-start'
                          title={
                            <>
                              {item?.SalesTax !== '0.00' && <Typography>Sales Tax: {item?.SalesTax}</Typography>}
                              {item?.CPI1 !== '0.00' && <Typography>CPI1: {item?.CPI1}</Typography>}
                              {item?.CPI2 !== '0.00' && <Typography>CPI2: {item?.CPI2}</Typography>}
                              {item?.DiscountEarned !== '0.00' && <Typography>Discount Earned: {item?.DiscountEarned}</Typography>}
                              {item?.InterestRebate !== '0.00' && <Typography>Interest Rebate: {item?.InterestRebate}</Typography>}
                              {item?.InsuranceRebate !== '0.00' && <Typography>Insurance Rebate: {item?.InsuranceRebate}</Typography>}
                            </>
                          }
                        >
                          <InfoOutlinedIcon color='primary' />
                        </HtmlTooltip>
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {tableData?.length === 0 && (
                <TableRow>
                  <TableCell colSpan={10} align='center'>
                    No results
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledGrid>
    </>
  )
}

export default PayHistoryRecordTable
