export const storageGet = (key: string) => {
  const getItem = localStorage.getItem(key);
  if (getItem !== null || getItem !== 'undefined') {
    return localStorage.getItem(key);

  }
  return undefined;
};

export const storageSet = (key: string, value: any) => {
  return localStorage.setItem(key, value);
};

export const storageRemove = (key: string) => {
  return localStorage.removeItem(key);
};
