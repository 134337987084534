import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'

export const slice = createSlice({
  name: 'inventoryAddType',
  initialState: null,
  reducers: {
    setAddInventoryType: (state, { payload }) => {
      return payload
    },
  },
})

export const getAddInventoryType = (state: RootState) => {
  return state?.inventoryAddType
}
export const { setAddInventoryType } = slice.actions

export default slice.reducer
