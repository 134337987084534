import _ from 'lodash'
import { UspDealTradeAddEdit, UspGetDataContact } from '../../../../../../../models'
import { convertDateToString } from '../../../../../../../utils/dateUtils'
import removeMask from '../../../../../../../utils/removeMask'
//  TODO: ADD TRIM
export const transformToRequestValues = (values?: any, data?: any, getContactsData?: UspGetDataContact): UspDealTradeAddEdit => ({
  stockNumber: values?.StockNumber,
  acv: removeMask(values?.ACV) as string,
  vin: values?.VIN,
  modelYear: values?.Year,
  make: values?.Make,
  model: values?.Model,
  bodyTypeID: values?.Body,
  modelTrim: values?.Trim,
  mileage: values?.Mileage,
  odometerTypeID: values?.Odometer,
  fuelTypeID: values?.FuelType,
  cylinders: values?.Cylinders,
  isAutoMarkup: values?.AutoMarkup,
  stickerPrice: removeMask(values?.StickerPrice) as string,
  conditionID: values?.Condition,
  bookValue1: removeMask(values?.BookValue1) as string,
  bookValue2: removeMask(values?.BookValue2) as string,
  engine: values?.Engine,
  transmission: values?.Transmission,
  exteriorColor: values?.Color,
  interiorColor: values?.InteriorColor,
  drivetrainID: values?.Drivetrain,
  cityMPG: values?.CityMPG,
  hwyMPG: values?.HwyMPG,
  lienAccountNumber: values.AccountNumber,
  lienAmount: removeMask(values?.LienPayoff) as string,
  tradeAllowance: removeMask(values?.Allowance) as string,
  lienAccount: removeMask(values?.lienAccount) as string,
  lienholderContactID: values?.LienholderContactID,
  perDiem: removeMask(values?.PerDiem) as string,
  payoffGoodUntilDate: convertDateToString(values?.PayoffGoodUntil) as string,
  note: values?.Note,
  attention: values?.ContactName,
  businessName: values?.Name,
  dba: values?.DBA,
  taxIDNumber: values?.taxIDNumber,
  email: values?.Email,
  isAllowSMS: values?.IsAllowSMS,
  isAllowEmail: values?.IsAllowEmail,
  isDoNotSell: values?.IsDoNotSell,
  isRestrictCheckAch: values?.IsRestrictCheckAch,
  addressID: _.first(getContactsData?.ContactAddressData)?.AddressID as number,
  address1: values?.AddressLine1,
  address2: values?.AddressLine2,
  city: values?.City,
  state: values?.State,
  postalCode: values?.PostalCode,
  county: values?.County,
  addressType: values?.AddressType,
  isPrimaryAddress: values?.IsPrimaryAddress,
  isAddressActive: values?.IsAddressActive,
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.ContactPhoneID,
      PhoneNumber: values?.PhoneNumber,
      PhoneType: values?.PhoneType || null,
      PhoneExtension: values?.PhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
    },
    {
      ContactPhoneID: values?.SecondaryContactPhoneID,
      PhoneNumber: values?.SecondaryPhoneNumber,
      PhoneType: values?.SecondaryPhoneType || null,
      PhoneExtension: values?.SecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
    },
  ],
  isContactUpdated: true,
  isAddressUpdated: true,
  isPhoneUpdated: true,
})

export const transformToFormValues = (values?: any, carData?: any, getContactsData?: UspGetDataContact, url?: any, discrepancy?: any) => {

  // Initialize fields with default values
  let ContactPhoneID, PhoneNumber, PhoneExtension
  let SecondaryContactPhoneID, SecondaryPhoneNumber, SecondaryPhoneExtension
  let PhoneType = 'Cell'
  let SecondaryPhoneType = 'Home'
  // Iterate over the ContactPhoneData array
  if (getContactsData?.ContactPhoneData) {
    for (const phoneData of getContactsData.ContactPhoneData) {
      if (phoneData.OrderOfPreference === 1) {
        ContactPhoneID = phoneData.ContactPhoneID
        PhoneNumber = phoneData.PhoneNumber
        PhoneType = phoneData.PhoneType || 'Cell'
        PhoneExtension = phoneData.PhoneExtension
      } else if (phoneData.OrderOfPreference === 2) {
        SecondaryContactPhoneID = phoneData.ContactPhoneID
        SecondaryPhoneNumber = phoneData.PhoneNumber
        SecondaryPhoneType = phoneData.PhoneType || 'Home'
        SecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }

  return {
    ACV: values?.ACV ?? discrepancy?.TradeACVDiscrepancy,
    Allowance: values?.Allowance ?? discrepancy?.TradeAllowanceDiscrepancy,
    LienPayoff: values?.LienAmount ?? discrepancy?.TradeLienDiscrepancy,
    PerDiem: values?.PerDiem,
    PayoffGoodUntil: values?.PayoffGoodUntilDate,
    Note: values?.Note,
    AccountNumber: values?.LienAccountNumber,
    Mileage: values?.Mileage,
    Odometer: carData?.OdometerTypeID,
    StockNumber: carData?.StockNumber,
    VIN: carData?.VIN,
    Make: carData?.Make,
    Model: carData?.Model,
    Year: carData?.ModelYear,
    Trim: carData?.ModelTrim,
    Body: carData?.BodyTypeID,
    Engine: carData?.Engine,
    Color: carData?.ExteriorColor,
    InteriorColor: carData?.InteriorColor,
    Transmission: carData && carData?.Transmission,
    Drivetrain: carData?.DrivetrainID,
    FuelType: carData?.FuelTypeID,
    Cylinders: carData?.Cylinders,
    CityMPG: carData?.CityMPG,
    HwyMPG: carData?.HwyMPG,
    AutoMarkup: carData?.IsAutoMarkup || false,
    StickerPrice: carData?.StickerPrice,
    Condition: carData?.ConditionID,
    BookValue1: carData?.BookValue1,
    BookValue2: carData?.BookValue2,
    Name: getContactsData?.BusinessName,
    LienholderContactID: values?.LienholderContactID,
    ContactName: values?.Attention,
    ContactPhoneID,
    SecondaryContactPhoneID,
    PhoneType,
    PhoneNumber,
    PhoneExtension,
    SecondaryPhoneNumber,
    SecondaryPhoneExtension,
    SecondaryPhoneType,
    AddressLine1: _.nth(getContactsData?.ContactAddressData, 0)?.Address1,
    AddressLine2: _.nth(getContactsData?.ContactAddressData, 0)?.Address2,
    City: _.nth(getContactsData?.ContactAddressData, 0)?.City,
    County: _.nth(getContactsData?.ContactAddressData, 0)?.County,
    State: _.nth(getContactsData?.ContactAddressData, 0)?.State,
    PostalCode: _.nth(getContactsData?.ContactAddressData, 0)?.PostalCode,
  }
}
