import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { withPermissionsDropdown } from '../../../hoc'
import Header from './components/Header/Header'
import CountyTaxesTable from './components/CountyTaxesTable/CountyTaxesTable'
import AddEditCountyTaxes from './components/AddEditCountyTaxes/AddEditCountyTaxes'
import { UspStateCountyTaxRateViewResult } from '../../../models'
import { useSearchCountyTaxRateMutation } from '../../../services'
import { Button, Card, Container, Grid } from '@mui/material'
import Iconify from '../../../components/iconify'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

const SettingsStateCountyTaxes = () => {
  const [rowData, setRowData] = useState({})
  const [openAddEditModal, setOpenAddEditModal] = useState(false)
  const [type, setType] = useState('')
  const [searchCountyTaxes, { data, isLoading }] = useSearchCountyTaxRateMutation()
  const [countyTaxesData, setCountyTaxesData] = useState<UspStateCountyTaxRateViewResult[]>([])

  const handleSubmit = (values?: any) => {
    searchCountyTaxes({
      uspStateCountyTaxRateView: {
        state: values?.State || null,
        county: values?.County || null,
      },
    })
  }

  const handleOpenModal = () => {
    setOpenAddEditModal(true)
    setType('add')
  }

  const handleCloseModal = () => {
    setOpenAddEditModal(false)
  }

  useEffect(() => {
    if (data) {
      setCountyTaxesData(data)
    }
  }, [data])
  return (
    <Container>
      <CustomBreadcrumbs
        heading='County Taxes'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          <Button onClick={() => handleOpenModal()} variant='contained' startIcon={<Iconify icon='mingcute:add-line' />}>
            Add new
          </Button>
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ State: '', County: '' }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <>
            <form onSubmit={handleSubmit}>
              <Card>
                <Grid container spacing={2} p={3}>
                  <Grid item xs={12}>
                    <Header submit={submit} handleOpenModal={handleOpenModal} />
                  </Grid>
                  <Grid item xs={12}>
                    <CountyTaxesTable
                      setOpenAddEditModal={setOpenAddEditModal}
                      setType={setType}
                      setRowData={setRowData}
                      handleOpenModal={handleOpenModal}
                      loading={isLoading}
                      data={countyTaxesData}
                      setData={setCountyTaxesData}
                    />
                  </Grid>
                </Grid>
              </Card>
            </form>
            <AddEditCountyTaxes
              submit={submit}
              open={openAddEditModal}
              onClose={handleCloseModal}
              type={type}
              rowData={rowData as UspStateCountyTaxRateViewResult}
            />
          </>
        )}
      </Form>
    </Container>
  )
}

export default withPermissionsDropdown(SettingsStateCountyTaxes)
