import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import PrintFormsSearchBlock from './components/PrintFormsSearchBlock/PrintFormsSearchBlock'
import {
  selectDropdownByName,
  useFormsBillingMutation,
  useFormsPromptsMutation,
  useLazyGetAccountOverviewQuery,
  useLazyGetTaskDealQuery,
  useLazySearchFormListQuery,
  usePrintFormDealMutation,
  useSearchFormsMutation,
  useSearchFormsPacksMutation,
} from '../../../../services'
import { useLocation, useParams } from 'react-router-dom'
import PrintFormsTableBlock from './components/PrintFormsTableBlock/PrintFormsTableBlock'
import { AccountsOverviewResult, UspFormPackViewResult, UspFormViewResult } from '../../../../models'
import { useSelector } from 'react-redux'
import { getAccounts as getAccountsSelector } from '../../../../redux/slices/accountsSlice'
import SearchForms from './components/SearchForms/SearchForms'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'
import { Box, Button, Card, Container, Stack } from '@mui/material'
import Iconify from '../../../../components/iconify'

interface Props {}
const PrintForms = ({}: Props) => {
  const { dealID } = useParams()
  const { state } = useLocation()
  const [isAnyRecordChecked, setIsAnyRecordChecked] = useState(false)
  const [isFormPackSelected, setIsFormPackSelected] = useState(false)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const accounts: AccountsOverviewResult = useSelector(getAccountsSelector(dealID))
  const [searchForms, { data: searchFormsData, isLoading: searchFormsIsLoading, isSuccess: searchFormsIsSuccess }] = useSearchFormsMutation()
  const [searchFormsPacks, { data: searchFormsPacksData, isLoading: searchFormsPacksIsLoading, isSuccess: searchFormsPacksIsSuccess }] =
    useSearchFormsPacksMutation()
  const [searchFormList, { data: searchFormListData, isLoading: searchFormListIsLoading }] = useLazySearchFormListQuery()
  const [formsPrompts, { data: formsPromptsData, isLoading: formsPromptsIsLoading, isSuccess: formsPromptsIsSuccess }] = useFormsPromptsMutation()
  const [getAccountsOverview, { data: accountsData, isSuccess: accountsIsSuccess, isLoading: accountsIsLoading }] = useLazyGetAccountOverviewQuery()
  const [getTaskDeal, { data: taskDealData, isLoading: getTaskDealIsLoading, isSuccess: taskDealIsSuccess }] = useLazyGetTaskDealQuery()
  const [printForm, { data: printFormData, isSuccess: printFormIsSuccess, isLoading: printFormIsLoading }] = usePrintFormDealMutation()
  const [formsBilling, { isSuccess: formsBillingIsSuccess, isLoading: formsBillingIsLoading }] = useFormsBillingMutation()
  const Docusign = useSelector((state) => selectDropdownByName(state, 'Docusign'))
  const headerData = state?.redirectedFrom === 'Accounts' ? accountsData : taskDealData
  const [additionalData, setAdditionalData] = useState({})
  const handleSubmit = (values: any) => {
    if (values?.State) {
      searchForms({
        state: values?.State,
        searchString: '',
        dataSource: 'Deal',
        isIncludeInactive: false,
      })

      searchFormsPacks({
        uspFormPackView: {
          state: values?.State,
          searchString: null,
          isIncludeInactive: true,
        },
      })
    }
  }

  useEffect(() => {
    if (state?.redirectedFrom === 'Accounts') {
      getAccountsOverview({ dealId: dealID as string })
    } else {
      getTaskDeal({ dealId: dealID as string })
    }
  }, [])

  useEffect(() => {
    // reset
    if (printFormIsSuccess) {
      setIsAnyRecordChecked(false)
      setIsFormPackSelected(false)
      setSelectedRows([])
    }
  }, [printFormIsSuccess])

  console.log(Docusign?.ReturnData?.find((returnData) => returnData.BranchID == taskDealData?.BranchID))

  return (
    <Container
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CustomBreadcrumbs
        heading='Forms'
        links={[
          { name: 'List' },
          {
            name: `${headerData?.Buyer} | ${headerData?.BuyerPhoneData?.[0]?.PhoneNumber} | ${headerData?.CarDescription} | Stock # ${headerData?.StockNumber} | VIN : ${headerData?.VIN}`,
          },
        ]}
        action={
          <Stack direction={'row'} spacing={2}>
            <Button
              variant='contained'
              disabled={
                !(
                  Docusign?.ReturnData.find((returnData) => returnData.BranchID == taskDealData?.BranchID)?.IsActive &&
                  (isFormPackSelected ? searchFormListData : (searchFormsData as any[]))
                    ?.filter((form: any) => !!selectedRows?.find((row: any) => row.ID == form.ID))
                    ?.find((el: any) => el.IsDocusignForm == true)
                )
              }
              startIcon={<Iconify icon='material-symbols:signature' />}
              onClick={() => {
                formsPrompts({
                  uspGetDataFormPrompts: {
                    formIDData: selectedRows as unknown as [ID: number],
                  },
                })
                if (Docusign?.ReturnData?.find((returnData) => returnData.BranchID == taskDealData?.BranchID))
                  setAdditionalData({
                    IsDocusign: true,
                    IsInPersonSigning: false,
                    DocusignUserID: Docusign?.ReturnData?.find((returnData) => returnData.BranchID == taskDealData?.BranchID)?.DocusignUserID,
                    DocusignUsername: Docusign?.ReturnData?.find((returnData) => returnData.BranchID == taskDealData?.BranchID)?.DocusignUsername,
                    DocusignHostName: Docusign?.ReturnData?.find((returnData) => returnData.BranchID == taskDealData?.BranchID)?.HostName,
                  })
              }}
            >
              Docusign
            </Button>
            <Button
              variant='contained'
              disabled={!isAnyRecordChecked}
              startIcon={<Iconify icon='material-symbols:print-outline' />}
              onClick={() => {
                formsPrompts({
                  uspGetDataFormPrompts: {
                    formIDData: selectedRows as unknown as [ID: number],
                  },
                })
                setAdditionalData({})
              }}
            >
              Print
            </Button>
          </Stack>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Form onSubmit={handleSubmit} initialValues={{ State: state?.redirectedFrom === 'Accounts' ? accounts?.DealState : state?.dealState }}>
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit}>
            <PrintFormsSearchBlock
              submit={submit}
              isAnyRecordChecked={isAnyRecordChecked}
              formsPrompts={formsPrompts}
              selectedRows={selectedRows}
              formsPromptsIsSuccess={formsPromptsIsSuccess}
              formsPromptsData={formsPromptsData}
              headerData={headerData}
              printForm={printForm}
              additionalData={additionalData}
              printFormIsLoading={printFormIsLoading}
              printFormIsSuccess={printFormIsSuccess}
              formsBilling={formsBilling}
            />
            <Card>
              <SearchForms
                searchFormsPacksData={searchFormsPacksData as UspFormPackViewResult}
                searchFormList={searchFormList}
                setIsFormPackSelected={setIsFormPackSelected}
              />
              <PrintFormsTableBlock
                setSelectedRows={setSelectedRows}
                selectedRows={selectedRows}
                setIsAnyRecordChecked={setIsAnyRecordChecked}
                isFormPackSelected={isFormPackSelected}
                loading={searchFormsIsLoading}
                printFormsData={isFormPackSelected ? searchFormListData : (searchFormsData as UspFormViewResult[])}
                searchFormsPacksIsSuccess={searchFormsPacksIsSuccess}
                searchFormsIsSuccess={searchFormsIsSuccess}
              />
            </Card>
          </form>
        )}
      </Form>
    </Container>
  )
}

export default PrintForms
