import React from 'react'
import { styled } from '@mui/system'
import { Grid } from '@mui/material'
import { TextField, AmountField } from '../../../../../ui'
import { generateUtilityClasses } from '@mui/material'

const classes = generateUtilityClasses('Grid', ['root', 'row', 'optionalText'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.optionalText}`]: {
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: 400,
    Size: '0.8750em',
    fontStyle: 'italic',
    lineHeight: '1.1875em',
    marginLeft: theme.spacing(1),
  },
}))

interface Props {}

export const SecondForm = ({}: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField disabled name='Note' label='Note' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='ProgramDescription' label='Program Description' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='StockNumber' label='Stock Number' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <AmountField disabled variant='standard' name='TransactionAmount' label='Transaction Amount' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='TransactionDescription' label='Transaction Description' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='Transaction Number' label='Transaction Number' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='TransactionType' label='Transaction Type' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='UserCode' label='User Code' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='VIN' label='VIN' fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField disabled name='VendorCode' label='Vendor Code' fullWidth />
      </Grid>
    </Grid>
  )
}

export default SecondForm
