import React from 'react'
import { Box, Grid, generateUtilityClasses, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { appRoute } from '../../../../../constants'
import { stableSort, getComparator } from '../../../../../utils/sorting'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { formatNumber } from '../../../../../utils/general'
import { UspReportReceivablesResult } from '../../../../../models'

const classes = generateUtilityClasses('Grid', ['root', 'activeItem', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    margin: theme.spacing(3),
  },
  [`& .${classes.activeItem}`]: {
    padding: theme.spacing(0.5, 0, 0, 0),
    width: '84px',
    height: '22px',
    fontSize: '0.8em',
    fontWeight: 500,
    color: '#FFFFFF',
    textAlign: 'center',
    backgroundColor: theme.palette.success.main,
    borderRadius: '4px',
  },

  [`& .${classes.tableHead}`]: {
    minWidth: '170px',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

interface Props {
  filterData: UspReportReceivablesResult[]
  isLoading: boolean
}

const ReceivablesTableBlock = ({ filterData, isLoading }: Props) => {
  const navigate = useNavigate()
  const initialOrder = (storageGet('reports_receivables_aging_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('reports_receivables_aging_order_by') || 'StockNumber') as keyof UspReportReceivablesResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspReportReceivablesResult>(initialOrderBy)

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspReportReceivablesResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('reports_receivables_aging_order', isAsc ? 'desc' : 'asc')
    storageSet('reports_receivables_aging_order_by', property)
  }

  const createSortHandler = (property: keyof UspReportReceivablesResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }
  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 118px)' }}>
      <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'StockNumber'}
                direction={orderBy === 'StockNumber' ? order : 'asc'}
                onClick={createSortHandler('StockNumber')}
              >
                Stock #
                {orderBy === 'StockNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Customer' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Customer'}
                direction={orderBy === 'Customer' ? order : 'asc'}
                onClick={createSortHandler('Customer')}
              >
                Customer
                {orderBy === 'Customer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'AccountNumber' ? order : false}>
              <TableSortLabel
                active={orderBy === 'AccountNumber'}
                direction={orderBy === 'AccountNumber' ? order : 'asc'}
                onClick={createSortHandler('AccountNumber')}
              >
                Account Number
                {orderBy === 'AccountNumber' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Branch' ? order : false}>
              <TableSortLabel active={orderBy === 'Branch'} direction={orderBy === 'Branch' ? order : 'asc'} onClick={createSortHandler('Branch')}>
                Branch
                {orderBy === 'Branch' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Dealer' ? order : false}>
              <TableSortLabel active={orderBy === 'Dealer'} direction={orderBy === 'Dealer' ? order : 'asc'} onClick={createSortHandler('Dealer')}>
                Dealer
                {orderBy === 'Dealer' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Lender' ? order : false}>
              <TableSortLabel active={orderBy === 'Lender'} direction={orderBy === 'Lender' ? order : 'asc'} onClick={createSortHandler('Lender')}>
                Lender
                {orderBy === 'Lender' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Collector' ? order : false}>
              <TableSortLabel
                active={orderBy === 'Collector'}
                direction={orderBy === 'Collector' ? order : 'asc'}
                onClick={createSortHandler('Collector')}
              >
                Collector
                {orderBy === 'Collector' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Balance' ? order : false}>
              <TableSortLabel active={orderBy === 'Balance'} direction={orderBy === 'Balance' ? order : 'asc'} onClick={createSortHandler('Balance')}>
                Account Balance
                {orderBy === 'Balance' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BalancePickups' ? order : false}>
              <TableSortLabel
                active={orderBy === 'BalancePickups'}
                direction={orderBy === 'BalancePickups' ? order : 'asc'}
                onClick={createSortHandler('BalancePickups')}
              >
                Pickups
                {orderBy === 'BalancePickups' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BalanceSideNotes' ? order : false}>
              <TableSortLabel
                active={orderBy === 'BalanceSideNotes'}
                direction={orderBy === 'BalanceSideNotes' ? order : 'asc'}
                onClick={createSortHandler('BalanceSideNotes')}
              >
                Side Notes
                {orderBy === 'BalanceSideNotes' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BalanceChargeback' ? order : false}>
              <TableSortLabel
                active={orderBy === 'BalanceChargeback'}
                direction={orderBy === 'BalanceChargeback' ? order : 'asc'}
                onClick={createSortHandler('BalanceChargeback')}
              >
                Chargebacks
                {orderBy === 'BalanceChargeback' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'BalanceLateCharge' ? order : false}>
              <TableSortLabel
                active={orderBy === 'BalanceLateCharge'}
                direction={orderBy === 'BalanceLateCharge' ? order : 'asc'}
                onClick={createSortHandler('BalanceLateCharge')}
              >
                Late Charges
                {orderBy === 'BalanceLateCharge' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'UnearnedInterest' ? order : false}>
              <TableSortLabel
                active={orderBy === 'UnearnedInterest'}
                direction={orderBy === 'UnearnedInterest' ? order : 'asc'}
                onClick={createSortHandler('UnearnedInterest')}
              >
                Unearned Interest
                {orderBy === 'UnearnedInterest' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'UnearnedDiscount' ? order : false}>
              <TableSortLabel
                active={orderBy === 'UnearnedDiscount'}
                direction={orderBy === 'UnearnedDiscount' ? order : 'asc'}
                onClick={createSortHandler('UnearnedDiscount')}
              >
                Unearned Discount
                {orderBy === 'UnearnedDiscount' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHead} sortDirection={orderBy === 'Total' ? order : false}>
              <TableSortLabel active={orderBy === 'Total'} direction={orderBy === 'Total' ? order : 'asc'} onClick={createSortHandler('Total')}>
                Total
                {orderBy === 'Total' ? (
                  <Box component='span' sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stableSort(getComparator(order, orderBy), filterData as any[]).map((item, index) => (
            <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.InventoryEdit.replace(':carID', item?.CarID?.toString() as string))}
              >
                {item.StockNumber}
              </TableCell>
              <TableCell
                className={classes.tableCellName}
                onClick={() => navigate(appRoute.AccountsOverview.replace(':dealID', item?.DealID?.toString() as string))}
              >
                {item?.Customer}
              </TableCell>
              <TableCell>{item?.AccountNumber}</TableCell>
              <TableCell>{item?.Branch}</TableCell>
              <TableCell>{item?.Dealer}</TableCell>
              <TableCell>{item?.Lender}</TableCell>
              <TableCell>{item?.Collector}</TableCell>
              <TableCell>{formatNumber(item?.Balance?.toString())}</TableCell>
              <TableCell>{formatNumber(item?.BalancePickups?.toString())}</TableCell>
              <TableCell>{formatNumber(item?.BalanceSideNotes?.toString())}</TableCell>
              <TableCell>{formatNumber(item?.BalanceChargeback?.toString())}</TableCell>
              <TableCell>{formatNumber(item?.BalanceLateCharge?.toString())}</TableCell>
              <TableCell>{formatNumber(item?.UnearnedInterest?.toString())}</TableCell>
              <TableCell>{formatNumber(item?.UnearnedDiscount?.toString())}</TableCell>
              <TableCell>{formatNumber(item?.Total?.toString())}</TableCell>
            </TableRow>
          ))}
          {filterData?.length === 0 && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                No results
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10} align='center'>
                <CircularProgress />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ReceivablesTableBlock
