import { CardHeader, Grid } from '@mui/material'
import React from 'react'
import { PhoneTextField, TextField } from '../../../../../../ui'
import AddressField from '../../../../../../ui/AddressField'
import DropdownFieldSearch from '../../../../../../ui/DropdownFieldSearch'
import { useSelector } from 'react-redux'
import { getStates } from '../../../../../../redux/slices/userDropdownSlice'

function AddressForm() {
  const states = useSelector(getStates)

  return (
    <>
      <CardHeader title='Address' />
      <Grid container item spacing={2} px={3} pt={3}>
        <Grid item xs={6}>
          <AddressField name='Address1' label='Address Line 1' fullWidth />
        </Grid>
        <Grid item xs={6}>
          <TextField name='Address2' label='Address Line 2' fullWidth />
        </Grid>
        <Grid item xs={4}>
          <TextField name='City' label='City' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <DropdownFieldSearch name='State' label='State' options={states} optionKey='State' optionValue='State' autoComplete='off' />
        </Grid>
        <Grid item xs={2}>
          <TextField name='PostalCode' label='Zip Code' fullWidth autoComplete='off' />
        </Grid>
        <Grid item xs={4}>
          <TextField name='County' label='County' options={states} fullWidth autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default AddressForm
