import dayjs from 'dayjs'
import * as yup from 'yup'

const today = dayjs().endOf('day')

export const dealsSearchValidation: yup.SchemaOf<any> = yup
  .object({
    startDate: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed').label('Start Date'),
    endDate: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed').label('End Date'),
  })
  .required()
