import dayjs from 'dayjs'
import * as yup from 'yup'

export const referralLabels = {
  Note: 'Comment',
  ReferralFee: 'Referral Fee',
}

const today = dayjs().endOf('day')

export const referralValidation: yup.SchemaOf<any> = yup
  .object({
    Note: yup.string().nullable().label(referralLabels.Note),
    ReferralFee: yup.string().required().nullable().label(referralLabels.ReferralFee),
    DateOfBirth: yup.date().notRequired().nullable().max(today, 'Future date not allowed').typeError('Invalid Date').label('Date of Birth'),
    LicenseIssued: yup.date().nullable().notRequired().typeError('Invalid Date').max(today, 'Future date not allowed').label('License Issued'),
  })
  .required()
