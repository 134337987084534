import { Grid, Skeleton } from '@mui/material'

const InfoCardLoading = () => {
  return (
    <>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Skeleton height={24} width={24} variant='circular' />
        <Skeleton height={40} width={140} />
        <Skeleton height={24} width={24} variant='circular' />
      </Grid>
      {Array.from({ length: 7 }, (_, index) => index).map((i) => (
        <Grid item container key={i} columnSpacing={2} justifyContent='center'>
          <Grid item>
            <Skeleton height={40} />
          </Grid>
          <Grid item>
            <Skeleton height={40} />
          </Grid>
        </Grid>
      ))}
    </>
  )
}

export default InfoCardLoading
