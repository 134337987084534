import React from 'react'
import { UspDealAmortizationViewResult } from '../../../../../models'
import { generateUtilityClasses, Grid } from '@mui/material'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import AmortizationTable from '../../components/AmortizationTable/AmortizationTable'

const classes = generateUtilityClasses('Grid', ['root'])

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
}))

interface Props {
  dealAmortizationData: UspDealAmortizationViewResult[]
  loading: boolean
}

const TabAmortization = ({ dealAmortizationData, loading }: Props) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <AmortizationTable dealAmortizationData={dealAmortizationData} loading={loading} />
      </Grid>
    </Grid>
  )
}

export default TabAmortization
