import React, { Dispatch, SetStateAction } from 'react'
import { styled } from '@mui/system'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Modal, Typography } from '@mui/material'
import { generateUtilityClasses } from '@mui/material'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { DateField, DropdownCheckboxes } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { selectDropdownByNameActiveOnly } from '../../../../../services'

const classes = generateUtilityClasses('form', ['root', 'row', 'btnWrap'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
    minWidth: '350px',
    maxWidth: '350px',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  [`& .${classes.btnWrap}`]: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
}))

interface Props {
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  formValues: any
}

const FilteringModal = ({ open, handleClose, formValues }: Props) => {
  const branch = useSelector((state) => selectDropdownByNameActiveOnly(state, 'Branch'))

  return (
    <Dialog open={open} onClose={handleClose}>
      <form>
        <DialogTitle>Trade Liens</DialogTitle>

        <DialogContent>
          <Grid container spacing={2} py={1}>
            {branch && (
              <Grid item xs={12}>
                <DropdownCheckboxes
                  name='Branch'
                  label='Branch'
                  options={branch}
                  optionKey={'BranchID'}
                  optionValue={'Branch'}
                  fullWidth
                  defaultValue={formValues?.Branch}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <DateField name='StartDate' label='Start Date' fullWidth />
            </Grid>

            <Grid item xs={12}>
              <DateField name='EndDate' label='End Date' fullWidth />
            </Grid>

            <Grid item xs={12}>
              <DropdownFieldSearch
                name='IsUnpaid'
                label='Is Unpaid?'
                options={[
                  { key: true, value: 'Yes' },
                  { key: false, value: 'No' },
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <DropdownFieldSearch
                name='IsBased'
                label='Is Based On Finalized Date?'
                options={[
                  { key: true, value: 'Yes' },
                  { key: false, value: 'No' },
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' type='submit'>
            Run
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default FilteringModal
