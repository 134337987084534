import React, { useEffect, useState } from 'react'
import { UserSearchBlock } from './components/UserSearchBlock'
import { UserTableBlock } from './components/UserTableBlock'
import { Form } from 'react-final-form'
import { DeleteUserApiArg } from '../../../models'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchUserMutation, useDeleteUserMutation, useGetUserPermissionsDropdownMutation } from '../../../services'
import { enqueueNotification } from '../../../redux/slices/notificationsSlice'
import ConfirmDialog from '../../../components/ConfirmDialog'
import { isUserDropdownEmpty, setUserDropdown } from '../../../redux/slices/userDropdownSlice'
import { appRoute } from '../../../constants'
import { getUserAccessRights } from '../../../redux/slices/authSlice'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Container, Grid } from '@mui/material'
import Iconify from '../../../components/iconify'
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs/custom-breadcrumbs'

type TFormFields = {
  searchString: string
  isIncludeInactive: boolean
}

export const UserList = () => {
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState<TFormFields>()
  const isDropdownEmpty = useSelector(isUserDropdownEmpty)
  const [deleteConfirmDialog, setDeleteConfirmDialog] = React.useState<{ userId: string } | undefined>(undefined)
  const [searchUser, { data: searchUserData, isLoading: searchUserIsLoading }] = useSearchUserMutation()
  const [deleteUser, { isSuccess: deleteUserIsSuccess }] = useDeleteUserMutation()
  const [userPermissionsDropdown, { data: userPermissionsDropdownData, isSuccess: userPermissionsDropdownIsSuccess }] =
    useGetUserPermissionsDropdownMutation()

  const handleSubmit = (values: TFormFields) => {
    setFormValues(values)
    searchUser({
      uspUserSearch: {
        searchString: values?.searchString || '',
        jsonBranchList: null,
        isIncludeInactive: values?.isIncludeInactive,
      },
    })
  }

  const handleDelete = () => {
    deleteUser(deleteConfirmDialog as DeleteUserApiArg)
    setDeleteConfirmDialog(undefined)
  }

  // userPermissionsDropdown
  useEffect(() => {
    if (isDropdownEmpty) {
      // check if branch API request required
      userPermissionsDropdown({ userPermissionsDropdown: {} })
    }
  }, [])

  useEffect(() => {
    if (userPermissionsDropdownIsSuccess) {
      dispatch(setUserDropdown(userPermissionsDropdownData))
    }
  }, [userPermissionsDropdownIsSuccess])

  useEffect(() => {
    if (deleteUserIsSuccess) {
      dispatch(enqueueNotification({ message: 'Delete Successful' }))
      searchUser({
        uspUserSearch: {
          searchString: formValues?.searchString || '',
          jsonBranchList: null,
          isIncludeInactive: formValues?.isIncludeInactive,
        },
      })
    }
  }, [deleteUserIsSuccess])

  useEffect(() => {
    if (!userAccess.CanAccessUsers) {
      dispatch(
        enqueueNotification({
          message:
            'Access Denied: Sorry, your current access rights do not permit you to enter this section. If you believe you should have access, please contact your system administrator or help desk for assistance.',
          options: { variant: 'error' },
        }),
      )
      navigate(appRoute.Home)
    }
  }, [userAccess, appRoute])

  return (
    <Container>
      <CustomBreadcrumbs
        heading='User'
        links={[
          {
            name: 'List',
          },
        ]}
        action={
          userAccess?.CanAddDeal && (
            <Button
              onClick={() => navigate(appRoute.UserAddEdit.replace(':userId', 'add'))}
              variant='contained'
              startIcon={<Iconify icon='mingcute:add-line' />}
            >
              Add new
            </Button>
          )
        }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ searchString: '', isIncludeInactive: false }} // require to avoid first debounce search
      >
        {({ handleSubmit, form: { submit } }: any) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <UserSearchBlock submit={submit} />
                </Grid>
                <Grid item xs={12}>
                  <UserTableBlock deleteUser={setDeleteConfirmDialog} loading={searchUserIsLoading} searchUserData={searchUserData} />
                </Grid>
              </Grid>
            </Card>
            <ConfirmDialog
              open={!!deleteConfirmDialog}
              onOk={handleDelete}
              handleClose={() => setDeleteConfirmDialog(undefined)}
              notification={'User will be removed'}
            />
          </form>
        )}
      </Form>
    </Container>
  )
}
