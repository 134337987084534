import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import {
  CircularProgress,
  generateUtilityClasses,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  TableSortLabel,
  Box,
  Tooltip,
} from '@mui/material'
import { useForm } from 'react-final-form'
import { useDeleteCarNoteMutation } from '../../../../../services'
import { useParams } from 'react-router-dom'
import { enqueueNotification } from '../../../../../redux/slices/notificationsSlice'
import { useDispatch } from 'react-redux'
import { convertDateTimeToDisplay } from '../../../../../utils/dateUtils'
import DeleteIcon from '@mui/icons-material/Delete'
import { UspCarNoteViewResult } from '../../../../../models'
import { storageGet, storageSet } from '../../../../../utils/storage'
import { getComparator, stableSort } from '../../../../../utils/sorting'
import { visuallyHidden } from '@mui/utils'

const classes = generateUtilityClasses('Grid', ['root', 'tableHead'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
  },
  [`& .${classes.tableHead}`]: {
    whiteSpace: 'nowrap',
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
}))

interface Props {
  carNoteData?: UspCarNoteViewResult[]
  carNoteIsLoading: boolean
}

export const InventoryTableNotes = ({ carNoteData, carNoteIsLoading }: Props) => {
  const [deleteCarNote, { data: deleteCarNoteData, isSuccess: deleteNoteIsSuccess }] = useDeleteCarNoteMutation()
  const initialOrder = (storageGet('inventory_notes_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('inventory_notes_by') || 'StockNumber') as keyof UspCarNoteViewResult
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof UspCarNoteViewResult>(initialOrderBy)
  const dispatch = useDispatch()
  const { carID } = useParams()
  const { submit } = useForm()

  const handleDelete = (id: number) => {
    deleteCarNote({ carId: carID as string, noteId: id?.toString() })
  }

  useEffect(() => {
    if (deleteNoteIsSuccess) {
      dispatch(enqueueNotification({ message: deleteCarNoteData?.Response || 'Note has been deleted' }))
      submit()
    }
  }, [deleteNoteIsSuccess])

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof UspCarNoteViewResult) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('inventory_notes_order', isAsc ? 'desc' : 'asc')
    storageSet('inventory_notes_by', property)
  }

  const createSortHandler = (property: keyof UspCarNoteViewResult) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(60vh - 10px)' }} className={classes.root}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'StockNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'StockNumber'}
                  direction={orderBy === 'StockNumber' ? order : 'asc'}
                  onClick={createSortHandler('StockNumber')}
                >
                  Stock #
                  {orderBy === 'StockNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'UserCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'UserCode'}
                  direction={orderBy === 'UserCode' ? order : 'asc'}
                  onClick={createSortHandler('UserCode')}
                >
                  User
                  {orderBy === 'UserCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'SystemDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'SystemDate'}
                  direction={orderBy === 'SystemDate' ? order : 'asc'}
                  onClick={createSortHandler('SystemDate')}
                >
                  Date
                  {orderBy === 'SystemDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Note' ? order : false}>
                <TableSortLabel active={orderBy === 'Note'} direction={orderBy === 'Note' ? order : 'asc'} onClick={createSortHandler('Note')}>
                  Note
                  {orderBy === 'Note' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), carNoteData as any[])?.map((item) => (
              <TableRow key={item.CarNoteID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{item?.StockNumber}</TableCell>
                <TableCell>{item?.UserCode}</TableCell>
                <TableCell>{convertDateTimeToDisplay(item?.SystemDate as string)}</TableCell>
                <TableCell
                  style={{
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                  }}
                >
                  {item?.Note}
                </TableCell>
                <TableCell align={'right'}>
                  {!item?.IsCancelled && (
                    <Tooltip title='Delete'>
                      <IconButton
                        color='error'
                        onClick={() => {
                          handleDelete(item?.CarNoteID as number)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {carNoteData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {carNoteIsLoading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default InventoryTableNotes
