import React, { useEffect, useRef } from 'react'
import { default as MuiTextField } from '@mui/material/TextField'
import { Field } from 'react-final-form'
import { FieldRenderProps } from 'react-final-form'
import { TextFieldProps } from '@mui/material'

type PlaceAutocompleteProps = TextFieldProps & {
  name: string
}

const AddressField: React.FC<PlaceAutocompleteProps> = (props) => {
  const { name, label, variant } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const autoComplete = useRef<google.maps.places.Autocomplete>()

  useEffect(() => {
    if (inputRef.current) {
      autoComplete.current = new google.maps.places.Autocomplete(inputRef.current, {
        types: ['address'],
        fields: ['formatted_address', 'address_component'],
        componentRestrictions: { country: 'US' },
      })
    }
  }, [])
  const handlePlaceChanged = (input: any) => {
    if (autoComplete.current && input.onChange) {
      autoComplete.current.addListener('place_changed', () => {
        const place = autoComplete.current?.getPlace()

        if (place?.address_components) {
          let addressObj: { [key: string]: string } = {} // Define type here
          for (let component of place.address_components) {
            for (let type of component.types) {
              if (
                type === 'street_number' ||
                type === 'route' ||
                type === 'locality' ||
                type === 'administrative_area_level_1' ||
                type === 'postal_code'
              ) {
                addressObj[type] = component.short_name
              }
            }
          }

          const streetNumber = addressObj['street_number'] || ''
          const route = addressObj['route'] || ''
          const city = addressObj['locality'] || ''
          const state = addressObj['administrative_area_level_1'] || ''
          const zip = addressObj['postal_code'] || ''

          // Only add space if streetNumber is not empty
          const street = streetNumber ? `${streetNumber} ${route}` : route

          input.onChange({
            street,
            city,
            state,
            zip,
          })
        }
      })
    }
  }

  return (
    <Field name={name}>
      {({ input, meta: { error, touched } }: FieldRenderProps<string, HTMLElement>) => {
        const textField = (
          <MuiTextField
            label={label}
            {...props}
            {...input}
            variant={variant}
            error={touched && !!error}
            helperText={touched && error}
            InputProps={{
              ...props.InputProps,
              inputRef,
            }}
            inputProps={{
              autoComplete: 'one-time-code',
              form: {
                autoComplete: 'off',
              },
            }}
          />
        )

        handlePlaceChanged(input)
        return textField
      }}
    </Field>
  )
}

export default AddressField
