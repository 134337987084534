import React, { useEffect, useState } from 'react'
import { AccountBillingData } from '../../../../models'
import {
  Grid,
  Paper,
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  generateUtilityClasses,
  TableBody,
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
  TablePagination,
  MenuItem,
} from '@mui/material'
import { styled } from '@mui/system'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import { storageGet, storageSet } from '../../../../utils/storage'
import { getComparator, stableSort } from '../../../../utils/sorting'
import DeleteIcon from '@mui/icons-material/Delete'
import { convertDateToDisplay } from '../../../../utils/dateUtils'
import { visuallyHidden } from '@mui/utils'
import { CheckboxField } from '../../../../ui'
import { formatNumber, getCurrentDate } from '../../../../utils/general'
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useAccountingBillApproveToPayMutation, useDeleteAccountingBillMutation } from '../../../../services'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { getUserAccessRights } from '../../../../redux/slices/authSlice'
import CustomPopover from '../../../../components/custom-popover/custom-popover'
import Iconify from '../../../../components/iconify'
import { usePopover } from '../../../../components/custom-popover'

const classes = generateUtilityClasses('Grid', ['root', 'tableHead', 'tableCellName'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(3, 3, 3, 3),
  },
  [`& .${classes.tableHead}`]: {
    fontWeight: 600,
    background: theme.palette?.background?.main,
  },
  [`& .${classes.tableCellName}`]: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
}))

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.main,
    maxWidth: '400px',
    padding: theme.spacing(4),
    color: theme.palette?.text?.primary,
    border: `1px solid ${theme.palette.background.secondary}`,
  },
}))

interface Props {
  searchBillingData?: AccountBillingData[]
  loading: boolean
  submit: (...args: any) => any
}

const BillingTableBlock = ({ loading, searchBillingData }: Props) => {
  const [deleteBill, { isSuccess: deleteBillIsSuccess, isError }] = useDeleteAccountingBillMutation()
  const [approve] = useAccountingBillApproveToPayMutation()
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState<{ billId: string } | undefined>(undefined)
  const [billList, setBillList] = useState<AccountBillingData[]>([])
  const initialOrder = (storageGet('bills_order') || 'asc') as 'asc' | 'desc'
  const initialOrderBy = (storageGet('bills_order_by') || 'Vendor') as keyof AccountBillingData
  const [order, setOrder] = React.useState<'asc' | 'desc'>(initialOrder)
  const [orderBy, setOrderBy] = React.useState<keyof AccountBillingData>(initialOrderBy)
  const dispatch = useDispatch()
  const userAccess = useSelector(getUserAccessRights)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const [selectedItem, setSelectedItem] = useState<any>()
  const popover = usePopover()

  const onRequestSort = (event: React.MouseEvent<unknown>, property: keyof AccountBillingData) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    storageSet('bills_order', isAsc ? 'desc' : 'asc')
    storageSet('bills_order_by', property)
  }

  const createSortHandler = (property: keyof AccountBillingData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property)
  }

  const handleDelete = () => {
    if (deleteConfirmDialog?.billId) {
      deleteBill({
        accountingBillDelete: {
          billDate: getCurrentDate(),
          billID: Number(deleteConfirmDialog?.billId),
          isDelete: true,
        },
      })
    }
  }

  const handleApproveToPay = (event: any, item: any) => {
    const checked = event?.target?.checked
    const updatedBills = billList.map((bill) => (bill.BillID === item.BillID ? { ...bill, IsApprovedToPay: checked, isLoading: true } : bill))
    setBillList(updatedBills)
    approve({
      billId: item?.BillID as string,
      accountingBillApproveToPay: {
        isApprovedToPay: checked,
      },
    })
      .unwrap()
      .then((response) => {
        // On success, confirm the update
        const updatedBills = billList.map((bill) => (bill.BillID === item.BillID ? { ...bill, IsApprovedToPay: checked, isLoading: false } : bill))
        setBillList(updatedBills)
      })
      .catch((error) => {
        // On error, revert the update
        const updatedBills = billList.map((bill) => (bill.BillID === item.BillID ? { ...bill, IsApprovedToPay: !checked, isLoading: false } : bill))
        setBillList(updatedBills)
      })
  }

  useEffect(() => {
    setBillList(searchBillingData || [])
  }, [searchBillingData, deleteBillIsSuccess])

  useEffect(() => {
    if (deleteBillIsSuccess) {
      setBillList(billList?.filter((bill) => bill?.BillID !== Number(deleteConfirmDialog?.billId)))
      dispatch(enqueueNotification({ message: 'Bill removed' }))
      setDeleteConfirmDialog(undefined)
    }
  }, [deleteBillIsSuccess])

  useEffect(() => {
    if (isError) {
      setDeleteConfirmDialog(undefined)
    }
  }, [isError])

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const paginatedData = billList

  return (
    <>
      <TableContainer sx={{ overflow: 'auto', maxHeight: 'calc(100vh - 370px)' }}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table' stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'Vendor' ? order : false}>
                <TableSortLabel active={orderBy === 'Vendor'} direction={orderBy === 'Vendor' ? order : 'asc'} onClick={createSortHandler('Vendor')}>
                  Vendor
                  {orderBy === 'Vendor' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'VendorCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'VendorCode'}
                  direction={orderBy === 'VendorCode' ? order : 'asc'}
                  onClick={createSortHandler('VendorCode')}
                >
                  Code
                  {orderBy === 'VendorCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillNumber' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BillNumber'}
                  direction={orderBy === 'BillNumber' ? order : 'asc'}
                  onClick={createSortHandler('BillNumber')}
                >
                  Bill Number
                  {orderBy === 'BillNumber' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BillDate'}
                  direction={orderBy === 'BillDate' ? order : 'asc'}
                  onClick={createSortHandler('BillDate')}
                >
                  Date
                  {orderBy === 'BillDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'DueDate' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'DueDate'}
                  direction={orderBy === 'DueDate' ? order : 'asc'}
                  onClick={createSortHandler('DueDate')}
                >
                  Due
                  {orderBy === 'DueDate' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillTotal' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BillTotal'}
                  direction={orderBy === 'BillTotal' ? order : 'asc'}
                  onClick={createSortHandler('BillTotal')}
                >
                  Total
                  {orderBy === 'BillTotal' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BillRemaining' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BillRemaining'}
                  direction={orderBy === 'BillRemaining' ? order : 'asc'}
                  onClick={createSortHandler('BillRemaining')}
                >
                  Remaining
                  {orderBy === 'BillRemaining' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'BranchCode' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'BranchCode'}
                  direction={orderBy === 'BranchCode' ? order : 'asc'}
                  onClick={createSortHandler('BranchCode')}
                >
                  Branch
                  {orderBy === 'BranchCode' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} sortDirection={orderBy === 'IsApprovedToPay' ? order : false}>
                <TableSortLabel
                  active={orderBy === 'IsApprovedToPay'}
                  direction={orderBy === 'IsApprovedToPay' ? order : 'asc'}
                  onClick={createSortHandler('IsApprovedToPay')}
                >
                  Approved To Pay
                  {orderBy === 'IsApprovedToPay' ? (
                    <Box component='span' sx={visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
              <TableCell className={classes.tableHead} align={'right'}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(getComparator(order, orderBy), paginatedData as any[])
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((item: any, index: number) => (
                <TableRow hover key={item.CreditAppID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell>{item.Vendor}</TableCell>
                  <TableCell>{item?.VendorCode}</TableCell>
                  <TableCell>{item?.BillNumber}</TableCell>
                  <TableCell>{convertDateToDisplay(item?.BillDate)}</TableCell>
                  <TableCell>{convertDateToDisplay(item?.DueDate)}</TableCell>
                  <TableCell>{formatNumber(item?.BillTotal)}</TableCell>
                  <TableCell>{formatNumber(item?.BillRemaining)}</TableCell>
                  <TableCell>{item?.BranchCode}</TableCell>
                  <TableCell>
                    <CheckboxField
                      onChange={(event) => handleApproveToPay(event, item)}
                      defaultChecked={item?.IsApprovedToPay}
                      disabled={!userAccess?.CanApproveBillToPay}
                      name={'IsApprovedToPay' + index}
                    />
                  </TableCell>
                  <TableCell align={'right'}>
                    <IconButton
                      color={popover.open ? 'inherit' : 'default'}
                      onClick={(e) => {
                        popover.onOpen(e)
                        setSelectedItem(item)
                      }}
                    >
                      <Iconify icon='eva:more-vertical-fill' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            {searchBillingData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  No results
                </TableCell>
              </TableRow>
            )}
            {loading && (
              <TableRow>
                <TableCell colSpan={10} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {searchBillingData && searchBillingData?.length > 50 && (
        <Box sx={{ width: '100%', borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
          <Paper>
            <TablePagination
              component='div'
              count={searchBillingData?.length as number}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
      <ConfirmDialog
        open={!!deleteConfirmDialog}
        onOk={handleDelete}
        handleClose={() => setDeleteConfirmDialog(undefined)}
        notification={'Bill will be removed'}
      />
      <CustomPopover open={popover.open} onClose={popover.onClose} arrow='top-right' sx={{ width: 140 }}>
        {selectedItem?.BillDescription && (
          <MenuItem>
            <Iconify icon='material-symbols:info-outline' />
            {selectedItem?.BillDescription}
          </MenuItem>
        )}
        {userAccess?.CanDeleteBill && (
          <MenuItem
            disabled={selectedItem?.BillRemaining === '0.00'}
            onClick={() => setDeleteConfirmDialog({ billId: selectedItem?.BillID?.toString() as string })}
            sx={{ color: 'error.main' }}
          >
            <Iconify icon='solar:trash-bin-trash-bold' />
            Delete
          </MenuItem>
        )}
      </CustomPopover>
    </>
  )
}

export default BillingTableBlock
