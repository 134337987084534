import { Box, BoxProps, Grid, Modal, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import React, { useCallback, useEffect } from 'react'
import { CheckboxField, DropdownCheckboxes, TextField } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import { debounce } from 'lodash'
import { InventoryTableBlock } from '../InventoryTableBlock'
import { SearchCarApiResponse } from '../../../../../models'
import SearchIcon from '@mui/icons-material/Search'

const classes = generateUtilityClasses('Box', ['root', 'searchWrap', 'searchWrapContainer', 'tableWrapContainer', 'checkboxWrap'])

interface StyledBoxProps extends BoxProps {
  searchCarsData?: SearchCarApiResponse
}

const StyledBox = styled(Box)<StyledBoxProps>(({ theme, searchCarsData }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '30px',
    maxHeight: searchCarsData ? '600px' : 'auto',
  },
  [`& .${classes.searchWrapContainer}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    width: '100%',
    padding: theme.spacing(0.5),
  },
  [`& .${classes.tableWrapContainer}`]: {
    overflow: 'auto',
    maxWidth: '100%',
  },
  [`& .${classes.searchWrap}`]: {
    width: theme.spacing(20),
    margin: theme.spacing(0, 4, 0, 0),
  },
  [`& .${classes.checkboxWrap}`]: {
    margin: theme.spacing(0, 4, 0, 0),
    fontSize: '14px',
  },
}))

interface Props {
  open: boolean
  onClose: () => void
  submit: (...args: any) => any
  searchCarsData: SearchCarApiResponse
  loading: boolean
  initialLoad: boolean
}

const InventorySearchModal = ({ open, onClose, submit, loading, searchCarsData, initialLoad }: Props) => {
  const branchData = useSelector(getBranch)
  const { values } = useFormState()

  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.searchString || (values?.searchString !== null && !initialLoad)) {
      updateWithDebounce()
    }
  }, [values?.searchString])

  useEffect(() => {
    if (!initialLoad) {
      submit(values)
    }
  }, [values?.branch, values?.isIncludeSoldCars, values.isPendingTradeOnly])

  return (
    <Modal open={open} onClose={onClose} aria-labelledby='modal-inventory-search' aria-describedby='modal-inventory-describedby'>
      <StyledBox className={classes.root} searchCarsData={searchCarsData}>
        <Grid container className={classes.searchWrapContainer} xs={12}>
          <Grid item className={classes.searchWrap} xs={3}>
            <TextField
              name='searchString'
              placeholder='Search'
              fullWidth
              InputProps={{
                endAdornment: <SearchIcon />,
              }}
            />
          </Grid>
          {branchData?.length > 1 && (
            <Grid className={classes.searchWrap}>
              <DropdownCheckboxes
                name='branch'
                label='Branch'
                labelId='BranchId'
                fullWidth
                options={branchData}
                optionKey='BranchID'
                optionValue='Branch'
              />
            </Grid>
          )}
          <Grid className={classes.checkboxWrap}>
            <CheckboxField name='isIncludeSoldCars' label='Include Sold Cars' />
          </Grid>
          <Grid className={classes.checkboxWrap}>
            <CheckboxField name='isPendingTradeOnly' label='Pending Trades Only' />
          </Grid>
        </Grid>
        <Grid item className={classes.tableWrapContainer}>
          <InventoryTableBlock loading={loading} searchCarsData={searchCarsData} />
        </Grid>
      </StyledBox>
    </Modal>
  )
}

export default InventorySearchModal
