import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Box, Card, CardHeader, Container, generateUtilityClasses } from '@mui/material'
import {
  useAddDealerMutation,
  useEditDealerMutation,
  useGetContactMutation,
  useLazyGetDealerQuery,
  useVendorListsMutation,
} from '../../../../services'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { appRoute } from '../../../../constants'
import { transformToFormValues, transformToRequestValues } from './transform'
import Header from './components/Header/Header'
import DealerForm from './components/DealerForm/DealerForm'
import AddressForm from './components/AddressForm/AddressForm'
import ContactForm from './components/ContactForm/ContactForm'
import { withPermissionsDropdown } from '../../../../hoc'
import IdentityForm from './components/IdentityForm/IdentityForm'
import SettingsOneForm from './components/SettingsOneForm/SettingsOneForm'
import SettingsTwoForm from './components/SettingsTwoForm/SettingsTwoForm'
import FeesForm from './components/FeesForm/FeesForm'
import ReservesForm from './components/ReservesForm/ReservesForm'
import validateFormValues from '../../../../utils/validateForm'
import { addDealerValidation } from '../../../../validation/addDealerValidation'
import { CustomCircular } from '../../../../ui'
import { AddVendorListsApiResponse, UserPermissionsDropdownResult } from '../../../../models'
import ReactRouterPrompt from 'react-router-prompt'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs/custom-breadcrumbs'

const classes = generateUtilityClasses('form', ['root', 'firstRow', 'secondRow', 'buttonWrap', 'saveBtn', 'pageBody'])

const StyledForm = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  [`& .${classes.pageBody}`]: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  [`& .${classes.firstRow}`]: {
    minHeight: '300px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.secondRow}`]: {
    minHeight: '360px',
    padding: theme.spacing(2, 3, 3, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
  },
  [`& .${classes.buttonWrap}`]: {
    display: 'flex',
    position: 'sticky',
    right: '0px',
    bottom: '0px',
    justifyContent: 'flex-between',
    flexDirection: 'row-reverse',
    padding: theme.spacing(2, 2, 2, 2),
    marginTop: 'auto',
  },
  [`& .${classes.saveBtn}`]: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  userPermissionsDropdownData: UserPermissionsDropdownResult
}

const AddEditDealer = ({ userPermissionsDropdownData }: Props) => {
  const { dealerId } = useParams()
  const [initialValues, setInitialValues] = useState({})
  const [formChanged, setFormChanged] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [vendorList, { data: vendorListData, isLoading: vendorListIsLoading }] = useVendorListsMutation()
  const [getContact, { data: contactData, isLoading: getContactIsLoading }] = useGetContactMutation()
  const [getDealer, { data: dealerData, isLoading: getDealerIsLoading }] = useLazyGetDealerQuery()
  const [addDealer, { isSuccess: isSuccessDealerDataAdd }] = useAddDealerMutation()
  const [editDealer, { isSuccess: isSuccessDealerDataEdit }] = useEditDealerMutation()

  useEffect(() => {
    if (dealerId !== 'add') {
      getDealer({ dealerId: dealerId as string })
    }
    vendorList({
      uspVendorLists: {
        currentID: null,
        vendorType: null,
      },
    })
  }, [dealerId])

  useEffect(() => {
    if (dealerData) {
      getContact({ contactId: dealerData?.ContactID.toString() as string })
    }
  }, [dealerData])

  useEffect(() => {
    setInitialValues(transformToFormValues(dealerData, contactData, dealerId))
  }, [dealerData, contactData])

  const handleSubmit = (values: any) => {
    if (dealerId === 'add') {
      addDealer({
        uspDealerAddEdit: transformToRequestValues(values) as any,
      })
    } else {
      editDealer({
        dealerId: dealerId as string,
        uspDealerAddEdit: transformToRequestValues(values) as any,
      })
    }
  }

  useEffect(() => {
    if (isSuccessDealerDataAdd || isSuccessDealerDataEdit) {
      setFormChanged(false)
      dispatch(enqueueNotification({ message: 'Success' }))
      navigate(appRoute.SettingsAdminDealers)
    }
  }, [isSuccessDealerDataEdit, isSuccessDealerDataAdd])

  const isLoading = useMemo(
    () => vendorListIsLoading || getContactIsLoading || getDealerIsLoading,
    [vendorListIsLoading, getDealerIsLoading, getContactIsLoading],
  )

  return (
    <Container sx={{ position: 'relative' }}>
      <CustomBreadcrumbs
        heading='Dealer'
        links={[
          {
            name: 'List',
            href: appRoute.SettingsAdminDealers,
          },
          { name: 'Dealer' },
        ]}
        // action={
        //   <Button
        //     onClick={() => navigate(appRoute?.VendorAddEdit.replace(':vendorId', 'add'))}
        //     variant='contained'
        //     startIcon={<Iconify icon='mingcute:add-line' />}
        //   >
        //     Add new
        //   </Button>
        // }
        sx={{
          mb: {
            xs: 3,
            md: 5,
          },
        }}
      />
      <Header />
      <Card sx={{ position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Grid container spacing={2} padding={3}>
          <Form onSubmit={handleSubmit} initialValues={initialValues} validate={validateFormValues(addDealerValidation)}>
            {({ handleSubmit }: any) => (
              <StyledForm onSubmit={handleSubmit} className={classes.root} onChange={() => setFormChanged(true)}>
                <ReactRouterPrompt when={formChanged}>
                  {({ isActive, onConfirm, onCancel }) => (
                    <ConfirmDialog
                      open={!!isActive}
                      onOk={onConfirm}
                      handleClose={onCancel}
                      notification={'Warning: Your changes haven’t been saved.'}
                    />
                  )}
                </ReactRouterPrompt>
                <Grid item xs={12}>
                  <DealerForm />
                </Grid>
                <Grid item xs={12}>
                  <AddressForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <ContactForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <IdentityForm />
                </Grid>
                <Grid item xs={12}>
                  <SettingsOneForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <SettingsTwoForm
                    onDropdownFormChange={() => setFormChanged(true)}
                    vendorListData={vendorListData as AddVendorListsApiResponse}
                    userPermissionsDropdownData={userPermissionsDropdownData}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FeesForm onDropdownFormChange={() => setFormChanged(true)} />
                </Grid>
                <Grid item xs={12}>
                  <ReservesForm />
                </Grid>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    position: 'sticky',
                    bottom: 16,
                    right: 20,
                    px: 3,
                    width: 'fit-content',
                    gap: 2,
                    alignSelf: 'flex-end',
                  }}
                >
                  <Button variant='contained' color='error' onClick={() => navigate(appRoute?.SettingsAdminDealers)}>
                    Close
                  </Button>
                  <Button disabled={!formChanged} type='submit' variant='contained'>
                    Save & Close
                  </Button>
                </Box>
              </StyledForm>
            )}
          </Form>
        </Grid>
      </Card>
      <CustomCircular open={isLoading} />
    </Container>
  )
}

export default withPermissionsDropdown(AddEditDealer)
