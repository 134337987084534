import React, { Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react'
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, generateUtilityClasses, Grid, Modal, Paper } from '@mui/material'
import { styled } from '@mui/system'
import { Form } from 'react-final-form'
import {
  UspAccountingChartApAccountsView,
  UspAccountingChartOfAccountCurrentView,
  UspAccountingReturnControlDataResult,
  UspListCarExpenseTypeResult,
  VendorResponseSp,
} from '../../../../models'
import {
  useDeleteAccountingBillMutation,
  useGetAccountingChartApAccountsMutation,
  useGetAccountingChartOfAccountCurrentMutation,
  useLazyGetAccountingControlDataQuery,
  useLazyListsCarsExpenseTypeQuery,
} from '../../../../services'
import { getCurrentDate } from '../../../../utils/general'
import DetailsForm from './DetailsForm/DetailsForm'
import NoteForm from './NoteForm/NoteForm'
import SettingsForm from './SettingsForm/SettingsForm'
import { CustomCircular } from '../../../../ui'
import { convertStringToDate } from '../../../../utils/dateUtils'
import { useDispatch } from 'react-redux'
import { enqueueNotification } from '../../../../redux/slices/notificationsSlice'
import { LoadingButton } from '@mui/lab'

const classes = generateUtilityClasses('form', ['root', 'row', 'formItem', 'gridContainer'])

const StyledBox = styled('form')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.secondary,
    boxShadow: 24,
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },

  [`& .${classes.formItem}`]: {
    padding: theme.spacing(2, 3, 1, 3),
    margin: theme.spacing(0),
    borderRadius: 0,
    minWidth: '250px',
  },
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  open: boolean
  handleClose: Dispatch<SetStateAction<boolean>>
  vendorListData: VendorResponseSp[]
}

const AddSingleLineBillModal = ({ open, handleClose, vendorListData }: Props) => {
  const [initialValues, setInitialValues] = useState({})
  const dispatch = useDispatch()
  const [requestDealID, setRequestDealID] = useState<null | number>(null)
  const [requestCarID, setRequestCarID] = useState<null | number>(null)

  const [chartOfAccountBill, { data: chartOfAccountBillData, isLoading: chartOfAccountBillIsLoading }] =
    useGetAccountingChartOfAccountCurrentMutation()
  const [chartOfAccountAp, { data: chartOfAccountApData, isLoading: chartOfAccountApIsLoading }] = useGetAccountingChartApAccountsMutation()

  const [getAccountingControlData, { data: accountingControlData }] = useLazyGetAccountingControlDataQuery()

  const [getListsCarsExpense, { data: listsCarsExpenseData }] = useLazyListsCarsExpenseTypeQuery()

  const [addBill, { isSuccess: addBillIsSuccess }] = useDeleteAccountingBillMutation()

  useEffect(() => {
    if (open) {
      chartOfAccountAp({})
      chartOfAccountBill({
        uspChartOfAccountView: {
          currentID: null,
          isAllowedSelectControlledAccount: true,
          isIncludeAccountsThatCannotBeSelected: true,
          isReturnAllCompanyTypes: false,
        },
      })
    }
  }, [open])

  useEffect(() => {
    setInitialValues({
      BillDate: getCurrentDate(),
      IsFloorplan: false,
    })
  }, [open])

  const handleSubmit = (values: any) => {
    addBill({
      accountingBillDelete: {
        vendorID: values?.Vendor || null,
        billDate: values?.BillDate === 'Invalid Date' ? null : convertStringToDate(values?.BillDate),
        billDueDate: values?.DueDate === 'Invalid Date' ? null : convertStringToDate(values?.DueDate),
        billNumber: values?.BillNumber || null,
        carExpenseType: values?.ExpenseType || null,
        isFloorplan: values?.IsFloorplan ? values?.IsFloorplan : false,
        billCoaID: values?.ChartOfAccountBill || null,
        carID: requestCarID || null,
        dealID: requestDealID || null,
        billAmount: values?.Amount.replaceAll(',', '') || null,
        branchID: values?.Branch || null,
        description: values?.Note || null,
      },
    })
  }

  useEffect(() => {
    if (addBillIsSuccess) {
      dispatch(enqueueNotification({ message: 'Success' }))
      handleClose(true)
    }
  }, [addBillIsSuccess])

  const isLoading = useMemo(() => chartOfAccountBillIsLoading || chartOfAccountApIsLoading, [chartOfAccountBillIsLoading, chartOfAccountApIsLoading])

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, form }: any) => (
        <>
          <Dialog open={open} onClose={handleClose}>
            <form
              onSubmit={() => {
                handleSubmit()
                form.reset()
              }}
            >
              <DialogTitle>Single-Line Bill</DialogTitle>
              <DialogContent>
                <Grid container py={1} spacing={1}>
                  <Grid item xs={12}>
                    <SettingsForm vendorData={vendorListData} chartOfAccountApData={chartOfAccountApData as UspAccountingChartApAccountsView[]} />
                  </Grid>

                  <Grid item xs={12}>
                    <DetailsForm
                      listsCarsExpenseData={listsCarsExpenseData as UspListCarExpenseTypeResult[]}
                      setRequestCarID={setRequestCarID}
                      setRequestDealID={setRequestDealID}
                      getListsCarsExpense={getListsCarsExpense}
                      getAccountingControlData={getAccountingControlData}
                      accountingControlData={accountingControlData as UspAccountingReturnControlDataResult}
                      chartOfAccountBillData={chartOfAccountBillData as UspAccountingChartOfAccountCurrentView[]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <NoteForm />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <LoadingButton variant='contained' type='submit'>
                  Save
                </LoadingButton>
              </DialogActions>
            </form>
          </Dialog>
          <CustomCircular open={isLoading} />
        </>
      )}
    </Form>
  )
}

export default AddSingleLineBillModal
