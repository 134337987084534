import _ from 'lodash'
import { UspGetDataContact } from '../../../models'

export const transformToRequestValues = (values?: any) => ({
  vendorCode: values?.ShortCode || null,
  contactID: values?.ContactID || null,
  vendorTypeID: values?.VendorType,
  website: values?.Website || null,
  note: values?.Note || null,
  contact: values?.Contact || null,
  attention: values?.Attention || null,
  irs1099MiscType: values?.Type1099 || null,
  netDaysDue: values?.NetDaysDue || null,
  isStopPayment: values?.IsStopPayment,
  manager: values?.Manager || null,

  salesAccountNumber: values?.SalesAccountNumber || null,
  salesChartOfAccountID: values?.SalesChartOfAccountBill || null,
  salesApChartOfAccountID: values?.SalesChartOfAccountAP || null,

  financeAccountNumber: values?.FinanceAccountNumber || null,
  financeChartOfAccountID: values?.FinanceChartOfAccountBill || null,
  financeApChartOfAccountID: values?.FinanceChartOfAccountAP || null,

  serviceAccountNumber: values?.ServiceAccountNumber || null,
  serviceChartOfAccountID: values?.ServiceChartOfAccountBill || null,
  serviceApChartOfAccountID: values?.ServiceChartOfAccountAP || null,

  isActive: values?.IsActive,
  title: null,
  firstName: values?.FirstName,
  middleName: values?.MiddleName,
  lastName: values?.LastName,
  suffix: values?.Suffix,
  nickName: values?.Nickname,
  businessName: values?.Name,
  dba: values?.DBA,
  taxIDNumber: values?.TaxIDNumber,
  email: values?.Email,
  isContactUpdated: true,
  addressID: values?.AddressID,
  address1: values?.Address1,
  address2: values?.Address2,
  city: values?.City,
  state: values?.State,
  postalCode: values?.PostalCode,
  county: values?.County,
  isPrimaryAddress: true,
  isAddressActive: true,
  isAddressUpdated: true,
  addressType: values?.AddressType,
  isPhoneUpdated: true,
  jsonPhoneData: [
    {
      // Contact Info
      ContactPhoneID: values?.ContactPhoneID || null,
      PhoneNumber: values?.PhoneNumber,
      PhoneType: values?.PhoneType,
      PhoneExtension: values?.PhoneExtension,
      OrderOfPreference: 1,
      IsPreferred: true,
      IsPrimary: true,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
    {
      ContactPhoneID: values?.SecondaryContactPhoneID || null,
      PhoneNumber: values?.SecondaryPhoneNumber,
      PhoneType: values?.SecondaryPhoneType,
      PhoneExtension: values?.SecondaryPhoneExtension,
      OrderOfPreference: 2,
      IsPreferred: false,
      IsPrimary: false,
      IsAllowCall: true,
      IsAllowSMS: true,
      IsActive: true,
    },
  ],
})

export const transformToFormValues = (vendorData?: any, contactData?: UspGetDataContact, vendorId?: any) => {
  // Initialize fields with default values
  let ContactPhoneID, PhoneNumber, PhoneExtension
  let SecondaryContactPhoneID, SecondaryPhoneNumber, SecondaryPhoneExtension
  let PhoneType = 'Biz'
  let SecondaryPhoneType = 'Fax'
  // Iterate over the ContactPhoneData array
  if (contactData?.ContactPhoneData) {
    for (const phoneData of contactData?.ContactPhoneData) {
      if (phoneData?.OrderOfPreference === 1) {
        ContactPhoneID = phoneData.ContactPhoneID
        PhoneNumber = phoneData.PhoneNumber
        PhoneType = phoneData.PhoneType || 'Biz'
        PhoneExtension = phoneData.PhoneExtension
      } else if (phoneData?.OrderOfPreference === 2) {
        SecondaryContactPhoneID = phoneData.ContactPhoneID
        SecondaryPhoneNumber = phoneData.PhoneNumber
        SecondaryPhoneType = phoneData.PhoneType || 'Fax'
        SecondaryPhoneExtension = phoneData.PhoneExtension
      }
    }
  }

  let SalesAccountNumber, SalesChartOfAccountAP, SalesChartOfAccountBill
  let FinanceAccountNumber, FinanceChartOfAccountAP, FinanceChartOfAccountBill
  let ServiceAccountNumber, ServiceChartOfAccountAP, ServiceChartOfAccountBill
  // Iterate over the VendorCompanyDetailData array
  if (vendorData?.VendorCompanyDetailData) {
    for (const companyData of vendorData?.VendorCompanyDetailData) {
      switch (companyData?.CompanyType) {
        case 'Sales':
          SalesAccountNumber = companyData.AccountNumber
          SalesChartOfAccountAP = companyData.ApChartOfAccountID
          SalesChartOfAccountBill = companyData.ChartOfAccountID
          break
        case 'Finance':
          FinanceAccountNumber = companyData.AccountNumber
          FinanceChartOfAccountAP = companyData.ApChartOfAccountID
          FinanceChartOfAccountBill = companyData.ChartOfAccountID
          break
        case 'Service':
          ServiceAccountNumber = companyData.AccountNumber
          ServiceChartOfAccountAP = companyData.ApChartOfAccountID
          ServiceChartOfAccountBill = companyData.ChartOfAccountID
          break
      }
    }
  }

  return {
    AccountType: 'business',
    Name: contactData?.BusinessName,
    DBA: contactData?.DBA,
    FirstName: contactData?.FirstName,
    MiddleName: contactData?.MiddleName,
    LastName: contactData?.LastName,
    Suffix: contactData?.Suffix,
    Nickname: contactData?.NickName,
    AddressID: _.first(contactData?.ContactAddressData)?.AddressID,
    Address1: _.first(contactData?.ContactAddressData)?.Address1,
    Address2: _.first(contactData?.ContactAddressData)?.Address2,
    City: _.first(contactData?.ContactAddressData)?.City,
    County: _.first(contactData?.ContactAddressData)?.County,
    State: _.first(contactData?.ContactAddressData)?.State,
    PostalCode: _.first(contactData?.ContactAddressData)?.PostalCode,
    Email: contactData?.Email,
    TaxIDNumber: contactData?.TaxIDNumber,
    ContactPhoneID,
    SecondaryContactPhoneID,
    PhoneType,
    PhoneNumber,
    PhoneExtension,
    SecondaryPhoneNumber,
    SecondaryPhoneExtension,
    SecondaryPhoneType,
    Attention: vendorData?.Attention,
    VendorType: vendorData?.VendorTypeID,
    NetDaysDue: vendorData?.NetDaysDue,
    IsStopPayment: vendorData?.IsStopPayment,
    Vendor1099: vendorId === 'add' ? null : vendorData?.IRS1099MiscType === null ? false : true,
    Type1099: vendorData?.IRS1099MiscType,
    Manager: vendorData?.Manager,
    SalesAccountNumber,
    SalesChartOfAccountAP,
    SalesChartOfAccountBill,
    FinanceAccountNumber,
    FinanceChartOfAccountAP,
    FinanceChartOfAccountBill,
    ServiceAccountNumber,
    ServiceChartOfAccountAP,
    ServiceChartOfAccountBill,
    ContactID: vendorData?.ContactID,
    ShortCode: vendorData?.VendorCode,
    IsActive: vendorId === 'add' ? true : vendorData?.IsActive,
    Contact: vendorData?.Contact,
    Website: vendorData?.Website,
    Note: vendorData?.Note,
  }
}
