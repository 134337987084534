import * as yup from 'yup'
export const addInventoryNoteLabels = {
  note: 'Note',
}

export const addInventoryNoteValidation: yup.SchemaOf<any> = yup
  .object({
    note: yup.string().required().nullable().label(addInventoryNoteLabels.note),
  })
  .required()
