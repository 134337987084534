import { CardHeader, Grid, Typography, generateUtilityClasses } from '@mui/material'
import { styled } from '@mui/system'
import { useFormState } from 'react-final-form'
import { useEffect } from 'react'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import { PaymentForm, TPaymentMethod } from '../../../../../models'
import { DateField } from '../../../../../ui'
import { ParsedDropdownResponse } from '../../../../../services'

const classes = generateUtilityClasses('Grid', ['root', 'row'])

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.row}`]: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}))

interface Props {
  paymentMethod: ParsedDropdownResponse[]
  paymentForm: ParsedDropdownResponse[]
}
const SetupCard = ({ paymentMethod, paymentForm }: Props) => {
  const { values } = useFormState<{ TypeSetup?: string; EffectiveDate?: string }>()

  return (
    <>
      <Grid container spacing={2} py={1}>
        <Grid item>
          <Typography variant='h6'>Setup</Typography>
        </Grid>
        <Grid item xs={12}>
          <DateField name='EffectiveDate' label='Effective Date' fullWidth />
        </Grid>
        <Grid item xs={12}>
          <DropdownFieldSearch
            name='Method'
            label='Method'
            options={paymentMethod || []}
            optionKey='ID'
            optionValue='PaymentMethod'
            autoComplete='off'
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownFieldSearch name='Form' label='Form' options={paymentForm} optionKey='ID' optionValue='PaymentForm' autoComplete='off' />
        </Grid>
      </Grid>
    </>
  )
}

export default SetupCard
