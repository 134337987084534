import React, { useEffect, useCallback } from 'react'
import { debounce } from 'lodash'
import { Box, Grid, InputAdornment } from '@mui/material'
import { TextField, DropdownCheckboxes, DateField, CheckboxField } from '../../../../../ui'
import { useSelector } from 'react-redux'
import { getBranch } from '../../../../../redux/slices/userDropdownSlice'
import { useFormState } from 'react-final-form'
import DropdownFieldSearch from '../../../../../ui/DropdownFieldSearch'
import SearchIcon from '@mui/icons-material/Search'
import Iconify from '../../../../../components/iconify'

export const DealsListSearchBlock = ({ submit }: { submit: (...args: any) => any }) => {
  const branchData = useSelector(getBranch)
  const { values } = useFormState()

  const updateWithDebounce = useCallback(
    debounce(() => submit(values), 500),
    [],
  )

  useEffect(() => {
    // search with debounce
    if (values?.searchString || values?.searchString !== '') {
      updateWithDebounce()
    }
  }, [values?.searchString])

  useEffect(() => {
    // search with debounce
    if (values?.dateRange || values?.dateRange !== '') {
      submit(values)
    }
  }, [values?.dateRange])

  useEffect(() => {
    // search with debounce
    if (values?.startDate?.length > 1 || values?.endDate) {
      updateWithDebounce()
    }
  }, [values?.startDate])

  useEffect(() => {
    // search with debounce
    if (values?.endDate?.length > 1 || values?.startDate) {
      updateWithDebounce()
    }
  }, [values?.endDate])

  useEffect(() => {
    // if (values?.branch || values?.isIncludeDeadDeals) {
    submit(values)
    // }
  }, [values?.branch, values?.isIncludeDeadDeals])

  return (
    <Box display='flex' justifyContent='space-between' p={3} gap={3}>
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={5}>
          <TextField
            name='searchString'
            placeholder='Search'
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        {branchData?.length > 1 && (
          <Grid item xs={2}>
            <DropdownCheckboxes
              name='branch'
              label='Branch'
              labelId='BranchId'
              fullWidth
              options={branchData}
              optionKey='BranchID'
              optionValue='Branch'
              variant='outlined'
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <DropdownFieldSearch
            name='dateRange'
            label='Date'
            options={[
              { key: 'between', value: 'Between' },
              { key: 'today', value: 'Today' },
              { key: 'yesterday', value: 'Yesterday' },
              { key: 'lastSevenDays', value: 'Last Seven Days' },
              { key: 'lastThirtyDays', value: 'Last Thirty Days' },
            ]}
          />
        </Grid>
        {values?.dateRange === 'between' && (
          <>
            <Grid item xs={2}>
              <DateField name='startDate' label='Start Date' fullWidth disableFuture />
            </Grid>
            <Grid item xs={2}>
              <DateField name='endDate' label='End Date' fullWidth disableFuture />
            </Grid>
          </>
        )}
        <Grid item xs={3}>
          <Box>
            <CheckboxField name='isIncludeDeadDeals' label='Include Dead Deals' />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default DealsListSearchBlock
